import React, { Component } from 'react';
import { connect } from 'react-redux';
import ActionTypes from '../../../inc/ActionsTypes';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import DropDownInput from '../../Forms/Dropdown';
import Alert from '../../inc/Alert';
import FileUpload from './FileUpload';
import FileUploaderList from './FileUploaderList';
import { Tab, Tabs } from 'react-bootstrap';

class UploaderWithList extends Component {
    constructor(props){
        super(props);
        this.state = {
            source_id: this.props.source_id,
            isLoading:false,
            attachmentsource_id: null,
            attachmenttype_id:null,
            hideVendorPortal: this.props.hideVendorPortal ? true : false,
            activeKey:this.props.auth?.user?.usertype===1?"vendor-portal":"internal",
            isTemplateBasedAttachment:false
        }
    }
    componentDidMount(){
        if(this.props.attachmentTypes.types.length<=0){
            this.loadAttachmentTypes();
        }
        
    }
    onChnageHanlder(e){
        this.setState({
            attachmenttype_id:e.target.value
        })
        this.getIsTemplateExist(e.target.value)
    }
    getIsTemplateExist(id){
       let templateExist = this.props?.attachmentTypes?.types?.find( item => {
        if(parseInt(item.attachmenttype_id) === parseInt(id)){
            return item?.is_template_based_attachment;
        }
        return false;
        })
        this.setState({
            isTemplateBasedAttachment:templateExist
        })
    }
    getAttachmentTypeId(){
        if(this.state.attachmenttype_id){
            return this.state.attachmenttype_id;
        }
        return null;
        /*
        let attachmentTypes = this.props.attachmentTypes.types;
        if(attachmentTypes.length >=1){
            return attachmentTypes[0].attachmenttype_id;
        }
        return 0;
        */
    }
    fileUploaded (files) {
        if(this.props.onFileUpload && typeof this.props.onFileUpload ==='function'){
            this.props.onFileUpload(files)
        }
        this.setState({
            isLoading:true
        },function(){
            this.setState({
                isLoading:false
            })
        })
    }
    loadAttachmentTypes(viewType){
        let api = Api;
        api.setUserToken();
        let that = this;
        this.setState({
            isLoading:true
        })
        api.axios().get( Settings.apiUrl + '/attachmenttype').then(function(res){ 
            if(res.data.status ==='success'){
                if(viewType=='vendor-portal'){
                    that.props.setAttachmentTypes(res.data.data.filter(item => item.external_allowed !== false))
                    that.setState({
                        isLoading:false,
                    })
                    return;
                } 
                that.props.setAttachmentTypes(res.data.data)
                that.setState({
                    isLoading:false,
                })
            }else{
                that.setState({
                    isLoading:false
                })
            }
        })
    }
    onFilesLoadedHandler(files){
        if(files.length >= 1){
            this.setState({
                attachmentsource_id: files[0].attachmentsource_id
            })
        }
    }
    renderAttachments(AttachmentTypeId,props){
        return <>
        { !this.state.isLoading ? <FileUploaderList onFilesLoaded={this.onFilesLoadedHandler.bind(this)}  attachmentsource_integrator = {this.props.attachmentsource_integrator} source_id = {props.source_id} attachmenttype_id = {AttachmentTypeId} activeKey={this.state.activeKey} condition={this.props.condition}/> : '' }
        </>
    }
    onChangeTabSelection(selectedView) { 
        if(!selectedView) return;
        
        this.loadAttachmentTypes(selectedView);
        this.setState({ activeKey: selectedView });
    }
    render() {
        let props = this.props;
        let sourceAttachmentTypes = this.props.attachmentTypes.types.filter( item => {
            if(item.source_id === props.source_id){
                return item;
            }
            return false;
        })
        let fileCategoryNames = sourceAttachmentTypes.map( item => {
            return ( {
                label:item.name , value: item.attachmenttype_id
            })
        });
        if(!this.state.source_id){
            return <Alert type="danger" message="source_id props are required"/>
        }
        let AttachmentTypeId = this.getAttachmentTypeId();
        return (
            <div className='file_uploader_with_list'>
            <div className='note-inner-tab'>
              {this.props.auth?.user?.usertype===0&&
              <Tabs
              id='attachment_tabs'
              activeKey={this.state.activeKey}
              onSelect={this.onChangeTabSelection.bind(this)}
              className='mb-3 note-inner-tab'
            >
              <Tab eventKey='internal' title='Internal'/>
              {!this.state.hideVendorPortal && <Tab eventKey='vendor-portal' title='Vendor portal' /> } 
             </Tabs>}
             </div>
             <div className='file_dropdown_category'>
                <DropDownInput id="file_uploder_tupe" value={ AttachmentTypeId } onChange={ this.onChnageHanlder.bind(this)} name="file_category" options={fileCategoryNames} />
            </div>
            { AttachmentTypeId && !this.props.condition ? <FileUpload id="uploader_1" attachmentsource_id = { this.state.attachmentsource_id } attachmentsource_integrator = {this.props.attachmentsource_integrator} source_id = {this.props.source_id} attachmenttype_id = {AttachmentTypeId } isTemplate={this?.state?.isTemplateBasedAttachment} onFileUpload= { this.fileUploaded.bind(this) } userType={this.props?.auth?.user?.usertype} activeKey={this.state.activeKey}/> : ''}
            {this.renderAttachments(AttachmentTypeId,props)}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        language:state.language,
        attachmentTypes:state.attachmentTypes,
        sources:state.allSources,
    }
}
const mapDispatchToProps = (dispatch) => {
    return({
        setAttachmentTypes:(AttachmentTypes) => { dispatch({type:ActionTypes.SET_ATTACHMENT_TYPES, payload:AttachmentTypes})},
    })
}
export default   connect (mapStateToProps,mapDispatchToProps)  (UploaderWithList);