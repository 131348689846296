import ActionTypes from "../inc/ActionsTypes";

const defaultState = {
    logoUrl:'',
}
const LogoUrlReducer = (state = defaultState, actions) =>{
    if(actions.type === ActionTypes.SET_LOGO_URL){
        state = {
            ...state,
            logoUrl:actions.url,
        }
    }
    return state;
}
export default LogoUrlReducer;