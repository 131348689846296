import React, { useState, useEffect, useCallback, Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import FormValidator from '../../../inc/FormValidator';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import Input from '../../Forms/Input'; 
import Button from '../../inc/Button';
import Collapse from '../../inc/Collapse';
import LinkBtn from '../../inc/LinkBtn';
import RsWithRouter from '../../inc/RsWithRouter'; 
import NavigationHeder from '../../Navigations/NavigationHeder'; 
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import { useParams } from 'react-router-dom';
import SimpleDropdown from '../../grid/ag/cellEditor/SimpleDropdown';
import { getLabelFromDropdown, getOptions, optionsKeyCreator } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import AgGridNew from '../../grid/ag/ag-grid-new';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';

let gridApi = null;
let AddBtn;
let transaction;

const EditTaxGroupWithItem = ({ language }) => {
    const { id } = useParams(); 
    const [isLoading, setIsLoading] = useState(false); 
 
    const [taxCodes, setTaxCodes] = useState([]);  
    const [taxGroup, setTaxGroup] = useState({}); 

    const wrapperDiv = useRef();
    const [height, setHeight] = useState(0);

    let loadedTaxGroup = false;
    let loadedTaxCodes = false;  

    useEffect(() => { 
        Helper.resizeWindowHandle(wrapperDiv, setHeight, window);
    }, [wrapperDiv?.current?.clientHeight, isLoading]);

    useEffect(() => {
        loadTaxGroup(); 
        loadTaxCodes();  
    }, [id]);

    let validator; 

    const validationConfigure = () => {
        const fieldConfig = [
            { name: 'name', displayName: Helper.getLabel(language, 'name', 'Name'), types: ['Required'], max: 80 },
        ];

        if(!validator) {
            validator = new FormValidator(fieldConfig, language)
        }
    };

    validationConfigure();

    const loadTaxGroup = () => {
        if (loadedTaxGroup) return;
        loadedTaxGroup = true;

        setIsLoading(true);
        Api.setUserToken();
        Api.axios().get(Settings.apiUrl + '/taxgroupitem/' + id)
            .then(res => {
                setTaxGroup(res?.data?.data);
                setIsLoading(false);
            }).catch(error => {
                setIsLoading(false);
            })
    };
  
    const loadTaxCodes = () => {
        if (loadedTaxCodes) return;
        loadedTaxCodes = true;
 
        Api.setUserToken();
        Api.axios().get(Settings.apiUrl + '/taxcodes')
            .then(res => {
                if (res.data.status === 'success') {
                    setTaxCodes(res?.data?.data?.map(item => {
                        return { label: item.name, value: item.taxcodes_id }
                    }));
                }
            });
    };
  
    const onInputHander = (e) => {
        setTaxGroup({
            ...taxGroup,
            [e.target.name]: e.target.value
        });
    };

    const onSaveHandler = (e) => {
        const validData = { ...taxGroup };

        if(!validator.isValid(validData)){
            validator.displayMessage();
            validator.reset() 
            return;
        };
 
        setIsLoading(true);
        Api.setUserToken();
        Api.axios().put(Settings.apiUrl + '/taxgroupitem/' + taxGroup?.taxgroupitem_id, taxGroup)
            .then(res => {
                if (res.data.status === 'success') {
                    loadTaxGroup();
                    Helper.alert(res.data.message);
                } else {
                    Helper.alert(res.data.message, 'error');
                }
                setIsLoading(false);
            })
            .catch(error => {
                getPopupMessageBasedOnStatus(error);
                setIsLoading(false);
            });
    };
 
    const handleBtn = (value, transactionData) => {
        AddBtn = value
        transaction = transactionData
    }

    const onRowValueChanged = (event) => {
        let apiPayload = {
            tax_group_item_id: id,
            tax_codes_id: event?.data?.taxcodes_id
        };

        if (!event?.data?.taxgroupitem_id) {
            addTaxGroupItemCode(apiPayload)
        }
    }
 
    const addTaxGroupItemCode = async (payload) => {
        try { 
            let api = Api;
            api.setUserToken();
            const res = await api.axios().post(Settings.apiUrl + '/taxgroupitemcode', payload);
            if (res.data.status === "success") { 
                setTimeout(() => {
                  gridApi?.refreshServerSide({ purge: true });
                  gridApi?.deselectAll();
                }, 100);

                setTimeout(() => {
                    if (AddBtn) {
                        gridApi?.applyServerSideTransaction(transaction);
                    }
                }, 1000);

                Helper.alert(res?.data?.message);
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error);
        }
    };
  
    const deleteCodeHanlder = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();

        try {
            const res = await api
                .axios()
                .delete(Settings.apiUrl + '/taxgroupitemcode/' + element?.data?.taxgroupitem_id + '/' + element?.data?.taxcodes_id)
            const rowNode = gridApi?.getRowNode(element?.data?.taxgroupitem_id)

            if (rowNode) {
                rowNode.setSelected(false)
            }

            setTimeout(() => {
                const transaction = { remove: [element.data] }
                gridApi?.applyServerSideTransaction(transaction)
            }, 200);
 
            gridApi?.refreshServerSide({ purge: true });
            gridApi?.deselectAll();

            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);
 
    /* Grid Column Headers */

    const gridColumnHeaders = [
        {
            field: 'taxcodes_id',
            headerName: Helper.getLabel(language, "code", 'Code'), 
            cellEditor: SimpleDropdown,
            isAllowZero: true,
            minWidth: 180, 
            cellEditorParams: {
              values: taxCodes
            },
            editable: (event) => event?.data?.isNew,
            cellDataType: false,
            valueFormatter: (params)=> getLabelFromDropdown(params),
            filter: 'agSetColumnFilter',
            filterParams: {
                values: (params) => getOptions(params, taxCodes),
                keyCreator: (params) => optionsKeyCreator(params, 'value'),
                valueFormatter: params => params.value.label
            }
        }
    ];

    return (
        <Fragment>
            { isLoading ? <OverlayLoader /> : null } 
            <div className='container-fluid taxcodes_page taxgroup_with_items'>
                <div ref={wrapperDiv}>
                    <NavigationHeder hideMoreBtn={true} backUrl="/tax/tax-group-with-items" title={Helper.getLabel(language, 'edit_groups', 'Edit Tax Group Items')}>
                        <LinkBtn to="/tax/tax-group-with-items" isActive={false} title={Helper.getLabel(language, 'cancel', 'Cancel')} />
                        <Button onClick={onSaveHandler} className="rfq_save_btn" title={Helper.getLabel(language, 'save', 'Save')} />
                    </NavigationHeder>
                </div>

                {isLoading ? null : 
                    <div className='common_scrollbar_styles' style={{ height: `${height - 30}px`, overflowY: 'auto'}}>
                        <Collapse title={Helper.getLabel(language, 'general', 'General')} open={true}>
                            <div className='rs_form_container'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Input name="name" hasError={validator.hasError('name')} value={taxGroup.name} label={Helper.getLabel(language, 'name', 'Name')} required={true} onChange={onInputHander} />
                                    </div>
                                </div>
                            </div>
                        </Collapse>

                        <Collapse title={Helper.getLabel(language, 'tax_codes', 'Tax Codes')} open={true}>
                            <AgGridNew
                                apiUrl={Settings.apiUrl + '/taxgroupitemcode/' + id}
                                pagination={false}
                                columnDefs={gridColumnHeaders} 
                                onGridReady={(params) => gridApi = params?.api}
                                onRowValueChanged={onRowValueChanged}
                                handleDeleteSelectedRows={deleteCodeHanlder}
                                height={500} 
                                handleAddButton={handleBtn}
                                uniqueField={`taxgroupitem_id`}
                                gridId={'taxitemgroup_grid'}
                            /> 
                        </Collapse>
                    </div>
                } 
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        language: state.language
    };
};

export default connect(mapStateToProps)(RsWithRouter(EditTaxGroupWithItem));