import React, { useState, useEffect, Fragment, useCallback } from 'react' 
import '../Organisation Unit.css' 
import NavigationHeder from '../../Navigations/NavigationHeder'
import Settings from '../../../inc/Settings'
import Api from '../../../inc/Api'
import Helper from '../../../inc/Helper'
import { connect } from 'react-redux'
import Gui_id_list from '../../../inc/Gui_id_list'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import Alert from '../../inc/Alert'
import ButtonNew from '../../inc/ButtonNew'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import AgGridNew from '../../grid/ag/ag-grid-new'
import { getDisplayNamesFromArray, getOptions, gridLinkValueFormat, optionsKeyCreator, statusLabelValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import OrgModal from './OrgModal'

let gridApi = null;

const OrgArchive = (props) => { 
  const [orgId, setOrgId] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [organisationTypeList, setOrganisationTypeList] = useState([])
  const [parentOrg, setParentOrg] = useState([])
  const [owner, setOwner] = useState([]);
  
  let orgListCalled = false;
  let orgTypeListCalled = false; 
  let employeeCalled = false;

  useEffect(() => {
    getOrganisations()
    getOrganisationsTypes()
    getEmployees() 
  }, []);
  
  const getOrganisations = () => {
    if(orgListCalled) return;

    let api = Api
    orgListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiOrgUrl+'/organization',{}).then(function(res){
      if(res.data.status==="success"){
        setParentOrg(res?.data?.data?.map(item => {
          return { 
            value: item.organization_id, 
            label: item.shortname
          }
        })) 
      }  
    }).catch((res) => {  
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const getEmployees = () => {
    if(employeeCalled) return;

    let api = Api
    employeeCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiOrgUrl+'/employee',{}).then(function(res){
      if(res.data.status==="success"){  
        setOwner(res?.data?.data?.map(item => {
          return {
            value: item.employee_id,
            label: item.display_name 
          }
        })) 
      }
    })
  }

  const getOrganisationsTypes = () => {
    if(orgTypeListCalled) return;

    let api = Api
    orgTypeListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiOrgUrl+'/organizationType',{}).then(function(res){
      if(res.data.status==="success"){
        setOrganisationTypeList(res?.data?.data?.map(item => {
          return {
            value: item.organizationtype_id,
            label: item.name
          }
        }))
      }
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const onCellHandler = (event) => {   
    if(event.colDef.field === 'name') { 
      setOrgId(event?.data?.organization_id);
      setShowCreateModal(true);
    }
  }

  const onDeleteOrgHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    let delOrg_ID = element?.data?.organization_id;
    try {
      const res = await api
        .axios()
        .delete(Settings.apiOrgUrl+`/organization/${delOrg_ID}`)
      const rowNode = gridApi?.getRowNode(delOrg_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi.applyServerSideTransaction(transaction)
      }, 200)
      gridApi?.refreshServerSide({ purge: true })
      gridApi?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);

  const reloadGrid = () => {
    gridApi?.refreshServerSide({ purge: true });
    gridApi?.deselectAll();
  };
     
  const onClickNewButton = () => {
    setShowCreateModal(true);
    setOrgId(null);
  };

  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.setupForms.unit_organization.unit_organization)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  const gridColHeaders = [
    {field:'name', headerName:Helper.getLabel(props.language,'org_name','Name'), filter:'agTextColumnFilter', cellRenderer: (params) => gridLinkValueFormat(params)},
    {field:'organizationtype_id', cellRenderer: (params) => getDisplayNamesFromArray(params, organisationTypeList, 'value', 'label'), filterParams: { values: (params) => getOptions(params, organisationTypeList), keyCreator: (params) => optionsKeyCreator(params, 'value'), valueFormatter: params => params.value.label }, filter: 'agSetColumnFilter', headerName:Helper.getLabel(props.language,'org_type','Organization Type')},
    {field:'owner', cellRenderer: (params) => getDisplayNamesFromArray(params, owner, 'value', 'label'), filterParams: { values: (params) => getOptions(params, owner), keyCreator: (params) => optionsKeyCreator(params, 'value'), valueFormatter: params => params.value.label }, filter: 'agSetColumnFilter', headerName:Helper.getLabel(props.language,'org_owner','Owner')},
    {field:'status', headerName:Helper.getLabel(props.language,'org_status','Status'), filterParams: { values: [true, false], keyCreator: (params) => params.value, valueFormatter: (params) => params.value ? 'Active' : 'Inactive' }, cellRenderer: (params) => statusLabelValueFormat(params)},
    {field:'organization_id', cellRenderer: (params) => getDisplayNamesFromArray(params, parentOrg, 'value', 'label'), filterParams: { values: (params) => getOptions(params, parentOrg), keyCreator: (params) => optionsKeyCreator(params, 'value'), valueFormatter: params => params.value.label }, filter: 'agSetColumnFilter', headerName:Helper.getLabel(props.language,'org_parent_organization','Parent Organization')} 
  ];

  return ( 
    <div className='organisation_archive'>
      <div className='container-fluid'>
        <NavigationHeder hideMoreBtn={true} backUrl='/setup' title={Helper.getLabel(props.language,'org_unit_orgs_SETUP','Organizations Unit')}>
          <ButtonNew onClick={onClickNewButton} isDisable={!security.canCreate(frontendIds.setupForms.unit_organization.unit_organization_main_create_button)} title='New' />
        </NavigationHeder> 
        
        <AgGridNew
          apiUrl={Settings.apiOrgUrl+'/organization'} 
          hideDeleteBtn={!security.canDelete(frontendIds.setupForms.unit_organization.unit_organization_main_delete_button)}
          pagination={false}
          columnDefs={gridColHeaders} 
          hideAddBtn={true}
          height={500}
          uniqueField={'organization_id'}
          onCellClicked={onCellHandler}
          handleDeleteSelectedRows={onDeleteOrgHandler}
          onGridReady={(params) => gridApi = params?.api} 
          gridId={"rs_organization_listings"}
        /> 
      </div>
      { showCreateModal && <OrgModal reloadParentGrid={reloadGrid} organization_id={orgId} setShowCreateModal={setShowCreateModal} /> }
    </div> 
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.setupForms.unit_organization.unit_organization
}
 
export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(OrgArchive, SecurityOptions))))