import React, { Component } from 'react';

class SuccessStep extends Component {
    render() {
        return (
            <div className='success_step'>
                <img src="/images/success-icon.svg" alt="Success" />
                <h3>Successfully submitted your application for approval</h3>
                <p>Thank you for your submission. Your profile approval process is anticipated to be completed within the next 24-48 hours. We appreciate your patience.</p>
                {/* <LinkBtn to={`/edit-vendor/${this.props?.data?.vendor_id}`} title="Go to profile"/> */}
            </div>
        );
    }
}

export default SuccessStep;