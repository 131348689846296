import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import FormValidator from '../../../inc/FormValidator';
import axios from 'axios';
import $ from 'jquery';
import Settings from '../../../inc/Settings';
import Api from '../../../inc/Api';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import Helper from '../../../inc/Helper';
import Popup from '../../Popup/Popup';
import Collapse from '../../inc/Collapse';
import { Form } from 'react-bootstrap';
import FieldsRenderer from '../../Purchase Orders Internal/Edit PO/FieldsRenderer';
import Button from '../../inc/Button';

let cancelTokenSource = axios.CancelToken.source();
const TaxCodesModal = ({ setShowCreateModal, language }) => {
    const [formData, setFormData] = useState({}); 

    const [settlementPeriods, setSettlementPeriods] = useState([]);
    const [taxPostingGroup, setTaxPostingGroup] = useState([]);
    const [itemGroup, setItemGroup] = useState([]);

    const [apiCallBtn, setApiCallBtn] = useState(false);

    const navigate = useNavigate();

    let isLoadSettlementPeriods = false;
    let isLoadedGroup = false; 
    let allItemGroups = false;

    useEffect(() => {
        loadSettlementPeriods();
        loadTaxPostingGroup();
        loadAllItemGroups();
    }, [])

    let formValidatory;   

    const validationConfigure = () => {
        let formFieldConfig = [
            {name:'code',displayName:Helper.getLabel(language,'code','Code'),types:['Required'] },
            {name:'name',displayName:Helper.getLabel(language,'name','Name'),types:['Required'] },
            {name:'settlementperiodsheader_id',displayName:Helper.getLabel(language,'settlement_period','Settlement period'),types:['Required']},
            {name:'taxpostinggroup_id',displayName:Helper.getLabel(language,'taxpostinggroup','Tax posting group'),types:['Required']},
            {name:'itemgroup_id',displayName:Helper.getLabel(language,'item_group','Item group'),types:['Required']},
        ];

        if(!formValidatory) {
            formValidatory = new FormValidator(formFieldConfig, language)
        }; 
    };

    validationConfigure();

    const loadSettlementPeriods = () => {
        if (isLoadSettlementPeriods) return;
        isLoadSettlementPeriods = true;
 
        Api.setUserToken();
        Api.axios().get(Settings.apiUrl + '/settlementheader').then(res => {
            setSettlementPeriods(res?.data?.data?.map((item) => {
                return {
                    value: item.settlementperiodheader_id,
                    label: item.code
                }
            }));
        });
    };

    const loadTaxPostingGroup = () => {
        if (isLoadedGroup) return;
        isLoadedGroup = true;
         
        Api.setUserToken();
        Api.axios().get(Settings.apiUrl + '/taxpostinggroup').then(res => {
            if (res.data.status === 'success') {
                setTaxPostingGroup(res?.data?.data?.map((item) => {
                    return {
                        value: item.taxpostinggroup_id,
                        label: item.name
                    }
                }));
            }
        });
    };
 
    const loadAllItemGroups = () => {
        if(allItemGroups) return;
        allItemGroups = true;

        Api.setUserToken();
        Api.axios().get(Settings.apiItemUrl + '/item_group').then(res => {
            setItemGroup(res?.data?.data?.map((item) => {
                return {
                    value: item.itemgroup_id,
                    label: item.name
                }
            }));
        });
    };

    const fieldsArrayTaxCodes = [ 
        {className:'col-lg-4', required: true, disable: apiCallBtn, maxLength: 10, label: Helper.getLabel(language,'code',"Code"), inputType: "text", id:'code', name:'code'},
        {className:'col-lg-8', required: true, disable: apiCallBtn, maxLength: 80, label: Helper.getLabel(language,'name',"Name"), inputType: "text", id:'name', name:'name'},
        {className:'col-lg-4', required: true, disable: apiCallBtn, placeHolder: 'Select settlement periods', options: settlementPeriods, label: Helper.getLabel(language,'settlementperiods','Settlement periods'), inputType: "dropdown", id:'settlementperiodsheader_id', name:'settlementperiodsheader_id'},
        {className:'col-lg-4', required: true, disable: apiCallBtn, placeHolder: 'Select tax posting group', options: taxPostingGroup, label: Helper.getLabel(language,'taxpostinggroup_id','Tax posting group'), inputType: "dropdown", id:'taxpostinggroup_id', name:'taxpostinggroup_id'},
        {className:'col-lg-4', required: true, disable: apiCallBtn, placeHolder: 'Select item group', options: itemGroup, label: Helper.getLabel(language,'item_group','Item group'), inputType: "dropdown", id:'itemgroup_id', name:'itemgroup_id'},
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'price_include_tax',"Price include tax"), inputType: "checkbox", id:'price_include_tax', name:'price_include_tax'}
    ];

    const onPopupClose = () => {
        $("html").removeClass("rs_popup_open");
        setShowCreateModal(false);
    }
 
    const executePOSTAPI = async () => {
        try { 
            let api = Api;
            api.setUserToken();

            const res = await api.axios().post(Settings.apiUrl + '/taxcodes', {
                ...formData,
                codevalues: [],
                price_include_tax: formData?.price_include_tax == 'on'
            },
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res?.data?.message); 
                onPopupClose();
                navigate(`/tax/edit-taxcode/${res?.data?.taxcodes_id}`); 
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        }
    };

    const handleSubmit = () => { 
        if(!formValidatory.isValid({...formData})){
            formValidatory.displayMessage();
            formValidatory.reset(); 
            return;
        };

        Helper.createDebouncedAPIFunction( 
            [executePOSTAPI], 
            setApiCallBtn,
            cancelTokenSource
        )();
    };

    return (
        <Fragment> 
            { apiCallBtn ? <OverlayLoader /> : null }
            <Popup
                autoOpen={true} width="900px"
                onClose={onPopupClose} 
            >
                <>
                    <h5 className="create_rfq_modal__title">Create Tax Codes</h5>

                    <Collapse
                        className="mt-4 mb-4" open={true}
                        title={Helper.getLabel(
                            language,
                            "general_info",
                            "General Information"
                        )}
                    >
                        <Form>
                            <div className='container-fluid'>
                                <FieldsRenderer updateState={setFormData} currentState={formData} fieldsArray={fieldsArrayTaxCodes} />
                            </div>
                        </Form> 
                    </Collapse>
                </>

                <div className="addDocumentNumber-body-buttons" style={{ justifyContent: "flex-end" }}>
                    <Button
                        isActive={false} 
                        isDisable={apiCallBtn}
                        title={Helper.getLabel(language, "close", "Close")}
                        onClick={onPopupClose}
                    />

                    <Button
                        isDisable={apiCallBtn}
                        className="solid_btn" isActive={true}
                        title={Helper.getLabel(language, "create", "Create")}
                        onClick={handleSubmit}
                    />
                </div>
            </Popup>
        </Fragment>
    )
}

export default TaxCodesModal;