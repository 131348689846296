import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

import Helper from "../../../inc/Helper";
import Api from "../../../inc/Api";
import Settings from "../../../inc/Settings";
import OverlayLoader from "../common/OverlayLoader";

import CartIconBlue from "../../../assets/cartIcon-blue.svg";
import DeleteGrey from "../../../assets/delete-grey.svg";
import ImageNotFound from "../../../assets/image-not-found.jpg";
import ConfirmationModal from "./ConfirmationModal";
import ActionTypes from "../../../inc/ActionsTypes";
import RsWithRouter from "../../inc/RsWithRouter";

class ShoppingCartModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      cartItems: [],
      subTotal: 0,
      imagesFetched: false,
      lineTypesList: [],
      isDataPosting:false,
      showConfirmationModal: false,
      itemToDelete: null,
      isAllItemsDeleted:false,
    }

    this.deleteCartItem = this.deleteCartItem.bind(this)
    this.deleteAllCartItems = this.deleteAllCartItems.bind(this)
    this.getSubTotal = this.getSubTotal.bind(this)
    this.handleCheckout = this.handleCheckout.bind(this);
    this.getSearchParam=this.getSearchParam.bind(this)
    this.handleCloseConfirmationModal =
      this.handleCloseConfirmationModal.bind(this)
    this.handleShowConfirmationModal = this.handleShowConfirmationModal.bind(this);
  }

  componentDidMount() {
    this.getCartItems()
    this.getLineTypesList()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.visibility !== this.props.visibility &&
      this.props.visibility === true
    )
      this.getCartItems()
  }

  getLineTypesList() {
    let api = Api
    api.setUserToken()
    let that = this
    api
      .axios()
      .get(Settings.loginUrl + `/enum/po_lineType`)
      .then(function (res) {
        if (res.data?.status === 'success') {
          that.setState({
            lineTypesList: [...res.data?.data],
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  getSearchParam = (name) => {
    const params = new URLSearchParams(this.props.rs_router.location?.search)
    return params.get(name)
  }

  getCartItems() {
    let api = Api 
    this.setState({ isLoading: true })
    let that = this
    const prHeaderId = this.getSearchParam('pr_view_id')
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + `/lines/${prHeaderId}/${Settings.source_id.purchase_requisition}`)
      .then(function (res) {
        that.setState(
          {
            isLoading: false,
            cartItems: res.data.data,
          },
        )
        that.props.changeCartCount(res.data.data?.length);
      })
      .catch((error) => {
        that.setState({ isLoading: false })
        Helper.alert(error?.response?.data?.message, 'error')
        that.props.changeCartCount(0);
      })
  }

  deleteCartItem() {
    const item = this.state.itemToDelete;
    if (!item) return;
    let api = Api
    this.setState({ isLoading: true })
    api.setUserToken()
    let that = this
    api
      .axios()
      .delete(
        Settings.apiPurchaseOrderUrl + '/line/' + item.line_id + "/" + Settings.source_id.purchase_requisition
      )
      .then(function (res) {
        if (res.data?.status === 'success') {
          that.setState((prevState) => {
            const cartItems = [...prevState.cartItems]
            const index = cartItems.findIndex(
              (cartItem) => cartItem.line_id === item.line_id
            )
            if (index !== -1) {
              cartItems.splice(index, 1)
            }
            return { cartItems, showConfirmationModal: false, itemToDelete: null }
          })
          Helper.alert(res.data?.message, 'success')
          that.setState({ isLoading: false, showConfirmationModal: false })
          that.props.decrementCartCount()
        }
      })
      .catch(function (error) {
        Helper.alert(error?.response?.data?.message, 'error')
        that.setState({ isLoading: false, showConfirmationModal: false, itemToDelete: null })
      })
  }

  deleteAllCartItems(){
    if (this.state?.cartItems?.length ===0 ) return;
    let api = Api
    this.setState({ isLoading: true })
    api.setUserToken()
    const lineIds = this.state.cartItems.map(item => item.line_id).join(',');
    const url = `${Settings.apiPurchaseOrderUrl}/delete_lines/${lineIds}/${Settings.source_id.purchase_requisition}`;
    let that = this
    api
      .axios()
      .delete(
        url
      )
      .then(function (res) {
        if (res.data?.status === 'success') {
          that.setState({
            showConfirmationModal: false
          })
          Helper.alert(res.data?.message, 'success')
          that.setState({ isLoading: false, showConfirmationModal: false, isAllItemsDeleted:false })
          that.getCartItems()
        }
      })
      .catch(function (error) {
        Helper.alert(error?.response?.data?.message, 'error')
        that.setState({ isLoading: false, showConfirmationModal: false })
      })
  }

  getSubTotal() {
    let subtotal = 0
    this.state.cartItems.forEach((item) => {
      if (item.line_amount) subtotal += parseFloat(item.line_amount)
    })
    return subtotal.toFixed(2)
  }

  checkLineTypeName(id) {
    const type = this.state.lineTypesList?.find((obj) => obj.id === id)
    return type ? type.name : null
  }

  handleCloseConfirmationModal() {
    this.setState({ showConfirmationModal: false, itemToDelete: null, isAllItemsDeleted:false })
  }

  handleShowConfirmationModal(item) {
    this.setState({ showConfirmationModal: true, itemToDelete: item })
  }

  handleCheckout() {
    const prHeaderId = this.getSearchParam('pr_view_id')
    this.props.rs_router.navigate(`/purchase-requisition/addrequisitiondetails?pr_view_id=${prHeaderId}`)
  }

  render() {
    const { visibility, hide } = this.props
    const { itemToDelete, showConfirmationModal, isAllItemsDeleted } = this.state;
    return (
      <Modal show={visibility} onHide={hide} className='shoppingcart-modal'>
        <Modal.Body className='shoppingcart-modal-body'>
          <div className='shoppingcart-modal-content'>
            <div className='shoppingcart-modal-content-header'>
              <div>
                <img src={CartIconBlue} alt='cart' />
                <label>
                  {Helper.getLabel(
                    this.props.language,
                    'shopping_cart',
                    'Shopping Cart'
                  )}
                </label>
              </div>
              <span>({this.state.cartItems.length ?? 0}) Item</span>
            </div>
            <div className='shoppingcart-modal-content-items'>
              {this.state.cartItems?.map((item) => {
                // const imageData =
                //   item?.images && item?.images[0]?.image?.slice(2, -1)
                // const imageUrl = `data:image/${item?.image?.image};base64,${imageData}`
                return (
                  <div
                    className='shoppingcart-modal-content-item'
                    key={item?.line_id}
                  >
                    <div className='shoppingcart-modal-content-item-image'>
                      {item?.images && item?.images[0]?.image !== null ? (
                        <img src={`${item?.images[0]?.image}`} alt='cart item' />
                      ) : (
                        <img src={ImageNotFound} alt='cart item' />
                      )}
                    </div>
                    <div className='shoppingcart-modal-content-item-info'>
                      <div>
                        <label className='shoppingcart-modal-content-item-title'>
                          {parseInt(item?.line_type)===0?item.item_name:parseInt(item?.line_type)==1?item.category_name:item?.comments?.substring(0,25) + (item?.comments?.length > 25 ? "..." : "")}
                        </label>
                        <button onClick={() => {
                            this.handleShowConfirmationModal(item)
                        }} className="border-0 p-0 m-0 bg-transparent">
                        <img
                          className='shoppingcart-modal-content-item-delete'
                          src={DeleteGrey}
                          alt='delete'
                        />
                        </button>
                      </div>
                      <div className='shoppingcart-modal-content-item-info-type'>
                        <label>
                          {Helper.getLabel(
                            this.props.language,
                            'item_type',
                            'Item Type'
                          )}
                        </label>
                        {this.checkLineTypeName(item?.line_type)}
                      </div>
                      <div>
                        <label>
                          {Helper.getLabel(
                            this.props.language,
                            'quantity',
                            'Quantity'
                          )}
                        </label>
                        <span>
                          {parseInt(item?.ordered_qty).toFixed(2) ?? '-'}
                        </span>
                      </div>
                      <div>
                        <label>
                          {Helper.getLabel(
                            this.props.language,
                            'unit_price',
                            'Unit Price'
                          )}
                        </label>
                        <span>
                          AED{' '}
                          {item?.unit_price
                            ? Helper.addThousandSeparator(
                                parseInt(item?.unit_price).toFixed(2)
                              )
                            : '0'}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              })}
              {this.state.isLoading || this.state.isDataPosting ? <OverlayLoader height={40} isHeightPercentage={true} /> : null}
            </div>
            <div className='shoppingcart-modal-content-action'>
              <div className='shoppingcart-modal-content-action-separator'></div>
              <div className='shoppingcart-modal-content-action-total'>
                <label>
                  {Helper.getLabel(this.props.language, 'total', 'Total')}
                </label>
                <span className='shoppingcart-modal-content-action-total-highlightedprice'>
                  <span>
                    AED {Helper.addThousandSeparator(this.getSubTotal())}
                  </span>
                </span>
              </div>
                <button
                  className='btn rfq_save_btn pr-primary-btn-disabled'
                  disabled={this.state.cartItems?.length === 0}
                  onClick={() => {
                    this.setState({
                       showConfirmationModal:true,
                       isAllItemsDeleted:true
                    })
                  }}
                >
                  {Helper.getLabel(this.props.language, 'clear_all_items', 'Clear all items')}
                </button>
                <button
                  className='pr-primary-btn pr-primary-btn-solid'
                  type='submit'
                  disabled={this.state.cartItems?.length === 0}
                  onClick={this.handleCheckout}
                >
                  {Helper.getLabel(this.props.language, 'checkout', 'Checkout')}
                  {this.state.isDataPosting? <output className="spinner-border spinner-border-sm mx-2" aria-hidden="true"></output>:<></>}
                </button>
            </div>
          </div>
        </Modal.Body>
        {showConfirmationModal && <ConfirmationModal
          show={showConfirmationModal}
          hide={this.handleCloseConfirmationModal}
          isLoading={this.state.isLoading}
          callback={isAllItemsDeleted?this.deleteAllCartItems:this.deleteCartItem}
          itemInAction={isAllItemsDeleted?null:itemToDelete}
          content={Helper.getLabel(
            this.props.language,
            'are_you_sure_want_to_delete_item.',
            `Are you sure want to delete ${isAllItemsDeleted?'all':'this'} item?`
          )}
          primaryBtnText={Helper.getLabel(
            this.props.language,
            'yes',
            'Yes'
          )}
          secondaryBtnText={Helper.getLabel(
            this.props.language,
            'no',
            'No'
          )}
        />}
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    userData: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      setPRCartInfo:(prInfo) => { dispatch({type:ActionTypes.SET_PR_ID , payload:prInfo}) },
      changeCartCount: (payload) => dispatch({ type: "CHANGE_CART_COUNT", payload }),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(RsWithRouter(ShoppingCartModal));
