import React, { Fragment, useCallback, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Api from '../../../inc/Api'
import Gui_id_list from '../../../inc/Gui_id_list'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings' 
import MasterComponentWraper from '../../Backend/MasterComponentWraper' 
import Alert from '../../inc/Alert'
import ButtonNew from '../../inc/ButtonNew'  
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import { checkForRevokePermission, getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import AgGridNew from '../../grid/ag/ag-grid-new'
import { gridLinkValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import CreateItemModal from './CreateItemModal'

let gridApi = null;
const ItemArchive = (props) => {
  const [showCreateItemModal, setShowCreateItemModal] = useState(false)
  const navigate = useNavigate();

  const CellHandler = (event) => {   
    if(event.colDef.field === 'item_name') {  
      navigate(`/edit-item/${event.data.item_id}`)
    }  
    if(event.colDef.field === 'code') {  
      navigate(`/edit-item/${event.data.item_id}`)
    }
  };
 
  const DeleteHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    let delItem_ID = element?.data?.item_id;
    try {
      const res = await api
        .axios()
        .delete(Settings.apiItemUrl+`/item/${delItem_ID}`)
      const rowNode = gridApi?.getRowNode(delItem_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi.applyServerSideTransaction(transaction)
      }, 200)
      gridApi.refreshServerSide({ purge: true })
      gridApi?.deselectAll();
      Helper.alert('Item deleted successfully', 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])

  const Columns_Headings = [ 
    {field:'code', filter: 'agTextColumnFilter', headerName:Helper.getLabel(props.language,'item_code',"Code"), cellRenderer: (params) => gridLinkValueFormat(params), editable: false},
    {field:'item_name', filter: 'agTextColumnFilter', headerName:Helper.getLabel(props.language,'name',"Name"), cellRenderer: (params) => gridLinkValueFormat(params), editable: false},
    {field:'display_name', filter: 'agTextColumnFilter', headerName:Helper.getLabel(props.language,'display_name',"Display Name"), editable: false},  
  ];
  
  let security = props.security;
  let frontendIds= Gui_id_list;
  if(!security.canView(Gui_id_list.masterData.item_master_modules.item.item)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  };
  
  return ( 
    <div className='items_archive'>
      <div className='container-fluid'>
        <NavigationHeder hideMoreBtn={true} backUrl='/master-data' title={Helper.getLabel(props.language,'items',"Items")}>
          <ButtonNew onClick={() => setShowCreateItemModal(true)} title={Helper.getLabel(props.language,'new',"New")} isDisable={!security.canCreate(frontendIds.masterData.item_master_modules.item.item_main_create_button)} /> 
        </NavigationHeder> 

        <AgGridNew
          apiUrl={`${Settings.apiItemUrl}/item`}
          hideDeleteBtn={!security.canDelete(frontendIds.masterData.item_master_modules.item.item_main_delete_button) || checkForRevokePermission(props?.user?.roles, 'Item')}
          pagination={true}
          columnDefs={Columns_Headings}
          hideAddBtn={true}
          height={500}
          uniqueField={'item_id'}
          onCellClicked={CellHandler}
          handleDeleteSelectedRows={DeleteHandler}
          onGridReady={(params) => gridApi = params.api} 
          gridId={"rs_items_list"}
        /> 
        {showCreateItemModal && <CreateItemModal setShowCreateItemModal={setShowCreateItemModal} />}
      </div>
    </div> 
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    user:state.auth.user,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.masterData.item_master_modules.item.item
}
   
export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(ItemArchive, SecurityOptions))))