import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import MasterComonent from '../../components/Backend/MasterComonent'
import InnerScreen from '../../components/Inner Screens/InnerScreen'
import Helper from '../../inc/Helper'

const ReceiveScreen = ({ language, auth }) => {
  return (
    <div className='modules_screen_lynkaz'>
      <MasterComonent>
        <InnerScreen innerTitle='Questionnaire'>
          <div className='col-lg-3 gy-4'>
            <div className='inner-page-header border-bottom'>
              <h2>{Helper.getLabel(language, 'questionnaire', 'Questionnaire List')}</h2>
            </div>

            <div className='inner-page-routes'>
              <Link to='/questionnaire-list'>{Helper.getLabel(language, 'questionnaire_all', 'Questionnaire - All')}</Link>
            </div>
          </div>
          <div className='col-lg-3 gy-4'>
            <div className='inner-page-header border-bottom'>
              <h2>{Helper.getLabel(language, 'answer_groups', 'Answer Groups')}</h2>
            </div>
            <div className='inner-page-routes'>
              <Link to={`/answer-groups`}>{Helper.getLabel(language, 'answer_groups_all', 'Answer Groups - All')}</Link>
            </div>
          </div>
          <div className='col-lg-3 gy-4'>
            <div className='inner-page-header border-bottom'>
              <h2>{Helper.getLabel(language, 'questionnaire_simulation', 'Questionnaire Simulation')}</h2>
            </div>
            <div className='inner-page-routes'>
              <Link to={`/questionnaire-form`}>{Helper.getLabel(language, 'questionnaire_simulation', 'Questionnaire Simulation')}</Link>
            </div>
          </div>
          <div className='col-lg-3 gy-4'>
            <div className='inner-page-header border-bottom'>
              <h2>{Helper.getLabel(language, 'questionnaire_integration', 'Questionnaire Integration')}</h2>
            </div>
            <div className='inner-page-routes'>
              <Link to={`/questionnaire-integration`}>{Helper.getLabel(language, 'questionnaire_integration', 'Questionnaire Integration')}</Link>
            </div>
          </div>
        </InnerScreen>
      </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(ReceiveScreen)
