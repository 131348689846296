import React from 'react'

import Step from "./Step"

const StepperSection = ({currentStep,savedSteps,shipmentHeader,type,userType}) => {
  let tabsCondition = type==="invoice" && userType===0
  return (
    <div className="steps">
      <Step
        number="1"
        name="General Information"
        isCurrentStep={currentStep === 0}
        isSaved={shipmentHeader !== null || savedSteps[0]}
      />
      <div className="arrow">
        <img src="/images/icons/angle-right.svg" alt="arrow-right-icon" />
      </div>
      <Step
        number="2"
        name={type==="invoice"?"Invoice Lines":"Work Confirmation Lines"}
        isCurrentStep={currentStep === 1}
        isSaved={savedSteps[1]}
      />
      <div className="arrow">
        <img src="/images/icons/angle-right.svg" alt="arrow-right-icon" />
      </div>
      {tabsCondition && 
      <><Step
        number="3"
        name="Price & Discount"
        isCurrentStep={currentStep === 2}
        isSaved={savedSteps[2]}
      />
      <div className="arrow">
        <img src="/images/icons/angle-right.svg" alt="arrow-right-icon" />
      </div>
      </>}
      <Step
        number={(tabsCondition)?"4":"3"}
        name="Documents"
        isCurrentStep={tabsCondition?currentStep === 3:currentStep=== 2}
        isSaved={savedSteps[tabsCondition?3:2]}
      />
      <div className="arrow">
        <img src="/images/icons/angle-right.svg" alt="arrow-right-icon" />
      </div>
      <Step
        number={tabsCondition?"5":"4"}
        name="Summary"
        isCurrentStep={tabsCondition?currentStep===4:currentStep===3}
        isSaved={savedSteps[tabsCondition?4:3]}
      />
    </div>
  )
}

export default StepperSection
