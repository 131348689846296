import React, { Fragment, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import MasterComonent from '../../../Backend/MasterComonent'
import Settings from '../../../../inc/Settings'
import Api from '../../../../inc/Api'
import { connect } from 'react-redux'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import Helper from '../../../../inc/Helper'
import Button from '../../../inc/Button'
import Tabs from '../../../Widgets/Tabs/Tabs'
import MasterSidebar from '../../../Sidebars/MasterSidebar'
import NoteAttachmentHistory from '../../../Widgets/NoteAttachmentHistory'
import LineViewComponent from '../../../Purchase Orders Internal/Edit PO/ListView/LineViewComponent'
import CommonApiCalls from '../../Purchase Order Screen/CommonApiCalls/CommonApiCalls'
import SimpleLoading from '../../../Loading/SimpleLoading'
import Collapse from '../../../inc/Collapse'
import TotalWidget from '../../../Widgets/TotalWidget/TotalWidget'
import PriceDiscountTab from '../../Purchase Order Screen/PurchaseOrderDetails/PriceDiscountTab'
import AddressList from '../../../Tenant/Address/AddressList'
import DeliveryTab from '../../../Purchase Orders Internal/Edit PO/DeliveryTab'
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation'
import AdditionalInformation from '../../RFQ Vendor Reply/RFQBiddingDetails/AdditionalInformation'

//for render tabs data
const ReleaseOrderData = (props) => {
  //data for grid and tabs
  const defaultNav = [
    {
      title: Helper.getLabel(props.language, 'hide_tab', 'hide_tab'),
      isActive: true,
    },
  ]

  const innerTabs = [
    {
      title: Helper.getLabel(
        props.language,
        "general_information",
        "General Information"
      ),
      isActive: true,
    },
    {
      title: Helper.getLabel(
        props.language,
        "additional _information",
        "Additional Information"
      ),
    },
    {
      title: Helper.getLabel(
        props.language,
        "price_and_discount",
        "Price & Discount"
      ),
    },
    {
      title: Helper.getLabel(
        props.language,
        "price_and_discount",
        "Price & Discount"
      ),
    },
    {
      title: Helper.getLabel(props.language, 'delivery', 'Delivery'),
    },
    {
      title: Helper.getLabel(props.language, 'address', 'Address'),
    },
  ];

  let status = props.status?.toLowerCase()

  const generalData = [
    {
      id: 1,
      title: 'Document number',
      subtitle: props.releaseOrderData?.document_number,
    },
    {
      id: 2,
      title: 'Status',
      subtitle: props.status,
      className:status==='confirmed'?`status-style success-style`:`status-style draft-style`
    },
    {
      id: 3,
      title: 'Purchase agreement no. ',
      subtitle: props.releaseOrderData?.agreement_document_number,
    },
    {
      id: 3,
      title: 'Vendor code',
      subtitle: props.releaseOrderData?.vendor_code,
    },
    {
      id: 4,
      title: 'Document date',
      subtitle: Helper.formateDate(props.releaseOrderData?.document_date),
    },
    {
      id: 5,
      title: 'Vendor name',
      subtitle: props.releaseOrderData?.vendor_name,
    },
    {
      id: 6,
      title: 'Vendor site',
      subtitle: props.releaseOrderData?.vendorsite_name,
    },
  ]

  //for add right sidebar
  const addComponentRight = () => {
    return (
      <MasterSidebar>
        <div style={{background:"#fff"}}>
          <NoteAttachmentHistory
            notesource_integrator={props.releaseOrderData?.purchase_order_id}
            attachmentsource_integrator={props.releaseOrderData?.purchase_order_id}
            source_id={Settings.source_id.purchase_order}
            hideHistory={true}
          />
        </div>
      </MasterSidebar>
    )
  }

  return (
    <Tabs
      navs={defaultNav}
      showAll={true}
      scrollOffset="300"
      disableSticky={true}
      addComponentRight={addComponentRight}
    >
      <Fragment>
        <div className="bidding_tab mt-3">
          <div className="mt-1 header-tab-rfq">
            <Tabs
              navs={innerTabs}
              disableSticky={true}
              scrollOffset="300"
              showAll={false}
            >
              <div className="mt-3 ps-3 pe-3">
                <div className="external-general-information">
                  <div className="purchase-order-inner-details general-details-responsive">
                    {generalData.map((item) => (
                      <div
                        className="row justify-content-between"
                        key={item.id}
                      >
                        <div className="col-sm-6">
                          <span className="purchase-order-external-title">
                            {item.title} :
                          </span>
                        </div>
                        <div className="col-sm-6">
                          <span className={item.className}>{item.subtitle}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='ps-3 pe-3'>
              <Collapse
                  className="mt-3"
                  title={Helper.getLabel(
                    props.language,
                    "additional_information",
                    "Additional Information"
                  )}
                  open={true}
                >
                <AdditionalInformation language={props.language} data={props?.releaseOrderData} isNeedNotes={false} title={Helper.getLabel(
                    props.language,
                    "description",
                    "Description"
                  )} />
                </Collapse>
              </div>
              <div className="ps-3 pe-3">
                <Collapse
                  className="mt-3"
                  title={Helper.getLabel(
                    props.language,
                    "price_and_discount",
                    "Price & Discount"
                  )}
                  open={true}
                >
                  <PriceDiscountTab
                    isEditable={false}
                    state={props.releaseOrderData}
                    setState={props.setReleaseOrderData}
                    name="price_include_tax"
                    paymentTermName="payment_term_id"
                  />
                </Collapse>
              </div>
              <div className="bidding_commercial_proposal ps-3 pe-3 ">
                <Collapse
                className="mt-3 mb-3"
                title={Helper.getLabel(props.language, 'delivery', 'Delivery')}
                open={true}
                >
                  <DeliveryTab isEditable={false} setState={props.setReleaseOrderData} state={props.releaseOrderData}/>
                </Collapse>
              </div>
              <div className='bidding_commercial_proposal ps-3 pe-3 '>
              <div className='mt-3 mb-3 address-grid'>
                <AddressList
                  disableRecords={true}
                  canEdit={false}
                  address_source_integrator={
                    props.releaseOrderData?.purchase_order_id
                  }
                  addressSourc_id={props?.releaseOrderData?.addresssource_id}
                  source_id={props?.releaseOrderData?.source_id}
                />
              </div>
            </div>
            </Tabs>
          </div>
        </div>
        <div className="mt-3 external-read-only-grid line-grid-btns-common">
          <LineViewComponent
            defaultValues={{ requester_id: null }}
            source_id={Settings.source_id.release_order}
            line_source={Settings.source_id.release_order}
            isEditable={false}
            header_id={props.releaseOrderData?.purchase_order_id}
            formType={'release order'}
            userType={props.usertype}
            agreementId={props?.releaseOrderData?.agreement_id}
            purchase_status={null}
            document_status={null}
            delivery_date={null}
            onbehalf_request={false}
            vendor_id={null}
            vendorsite_id={null}
            setOrderStatusProps={null}
            hideDistribution={false}
            hideGeneral={false}
            hideSetup={false}
            hideBillingRule={true}
            alternate_line={false}
            lineGridTitle={Helper.getLabel(props.language, 'lines', 'Lines')}
            hideDistributionInnerTabs={true}
            hideMoreBtns={true}
            hideAddBtn={true}
            hideDeleteBtn={true}
          />
        </div>
      </Fragment>
    </Tabs>
  )
}

const ReleaseOrderDetails = (props) => {
  const contractOrderApiCalls = new CommonApiCalls()
  const location = useLocation()

  //state for release order data
  const [releaseOrderData, setReleaseOrderData] = useState({})
  const [loading, setLoading] = useState(false)
  const [releaseOrderDocumentStatus, setReleaseOrderDocumentStatus] = useState([])

  const navigate = useNavigate()

  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }

  const purchase_order_id = getSearchParam('purchase_order_id')

  //api call
  const get_release_order_details = () => {
    let api = Api
    api.setUserToken()
    setLoading(true)
    if (purchase_order_id === null || purchase_order_id === undefined) return
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + `/PO_header/${purchase_order_id}`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setReleaseOrderData(res.data?.data)
          setLoading(false)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
        setLoading(false)
      })
  }

  //function to fetch status
  const getAsyncData = async () => {
    const document_statuses =
      await contractOrderApiCalls.getAllDocumentStatus();
    if (document_statuses);
    setReleaseOrderDocumentStatus(document_statuses);
  };

  useEffect(() => {
    get_release_order_details();
    getAsyncData()
  }, [])

  const onReceivingsClick=() =>{
     navigate(`/goods-receiving-list?&purchaseorder_id=${purchase_order_id}`,{state:{document_number:releaseOrderData?.document_number,type:'release order'}})
  }

  const onInvoiceCreate=() =>{
    navigate(`/invoice-list?purchase_order_id=${purchase_order_id}`)
 }

  const document_status = releaseOrderDocumentStatus?.find(
    (option) => option.id === parseInt(releaseOrderData?.document_status)
  )

  return (
    <div className="request_for_quotation_detail_screen external-detail-screens external-theme responsive-screen-style">
      <MasterComonent>
        <NavigationHeder
          title={`<span style="color:#757575;">${Helper.getLabel(
            props.language,
            'Release Orders List',
            'Release Orders List',
          )} > </span><span style="color:#313638">Release Order Details # </span> ${releaseOrderData?.document_number}`}
          hideMoreBtn={true}
        >
          <TotalWidget
            source_id={releaseOrderData?.source_id}
            integrator={releaseOrderData?.purchase_order_id}
          ></TotalWidget>
          <Button title={Helper.getLabel(
              props.language,
              'receivings',
              'Receivings',
            )} onClick={onReceivingsClick}/>
          <Button
            title={Helper.getLabel(
              props.language,
              'invoices',
              'Invoices',
            )}
            onClick={onInvoiceCreate}
            className='brown-btn-style'
          />
        </NavigationHeder>

        <div className="tabs_default">
          {loading ? (
            <SimpleLoading />
          ) : (
            <ReleaseOrderData
              language={props.language}
              releaseOrderData={releaseOrderData}
              setReleaseOrderData={setReleaseOrderData}
              status={document_status?.name}
              usertype={props?.auth?.user?.usertype}
            />
          )}
        </div>
      </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(ReleaseOrderDetails)
