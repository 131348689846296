import React from 'react'
import Popup from '../../../../Popup/Popup'
import Input from '../../../../Forms/Input'
import Button from '../../../../inc/Button'
import Helper from '../../../../../inc/Helper'
import Settings from '../../../../../inc/Settings'
import LineViewComponent from '../../../../Purchase Orders Internal/Edit PO/ListView/LineViewComponent'

const LineDetailsPopUp = (props) => {
  const source_line_name = props.lineIdOptions?.find((item)=>item.line_id===props.popUpData?.po_line_id)
  
  return (
    <Popup
      autoOpen={true}
      onClose={() => {
        props.setShowPopup(false)
        props?.gridApi?.api?.refreshServerSide({purge:false})
      }}
      width="1100px"
    >
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '16px',
          }}
        >
          <h5>
            {Helper.getLabel(
              props.language,
              'enter_line_detail_here',
              'Enter line detail here',
            )}
          </h5>
          <Button
            title={Helper.getLabel(props.language, 'total', 'Total')}
          ></Button>
        </div>

        <div className="rfq-bidding-information rounded">
          <div className="rfq-information-title ">
            <h2 className="grid_label_rfq mb-0">Source Details</h2>
          </div>
          <div className="col-sm-12 p-3 pt-4">
            <div className="row">
              <div className="col-sm-4">
                <Input
                  inputType="text"
                  label={Helper.getLabel(
                    props.language,
                    'source_line',
                    'Source Line',
                  )}
                  value={source_line_name?.name}
                />
              </div>
              <div className="col-sm-4">
                <Input
                  inputType="text"
                  label={Helper.getLabel(
                    props.language,
                    'billing_rule',
                    'Billing rule',
                  )}
                  value={props.popUpData?.billingrule.description}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={props.type==="invoice"?"work-confirmation-line-detail-grid invoice-line-detail-grid line-grid-btns-common":"work-confirmation-line-detail-grid line-grid-btns-common"}>
          <LineViewComponent
            defaultValues={{ requester_id: null, orderer_id: null }}
            source_id={
              props.type === 'invoice'
                ? 64
                : Settings.source_id.ShipmentGoodReceivingWorkConfirmation
            }
            line_source={
              props.type === 'invoice'
                ? 64
                : Settings.source_id.ShipmentGoodReceivingWorkConfirmation
            }
            // viewType="shipment"
            isEditable={
              props.shipmentHeader?.document_status === 1 ? false : true
            }
            header_id={props.popUpData?.line_id}
            purchase_status={null}
            document_status={null}
            delivery_date={null}
            onbehalf_request={false}
            vendor_id={null}
            vendorsite_id={null}
            setOrderStatusProps={null}
            hideDistribution={true}
            hideGeneral={true}
            hideSetup={true}
            hideBillingRule={true}
            alternate_line={false}
            lineGridTitle={Helper.getLabel(props.language, 'lines', 'Lines')}
          />
        </div>
        <div className="d-flex gap-3 justify-content-end mt-1">
          <Button
            title={Helper.getLabel(props.language, 'close', 'Close')}
            onClick={() => {
              props.setShowPopup(false)
              props?.gridApi?.api?.refreshServerSide({purge:false})
            }}
          ></Button>
          <Button
            isActive={true}
            title={Helper.getLabel(props.language, 'ok', 'Ok')}
            onClick={() => {
              props.setShowPopup(false)
              props?.gridApi?.api?.refreshServerSide({purge:false})
            }}
            className="rfq_save_btn"
          ></Button>
        </div>
      </div>
    </Popup>
  )
}

export default LineDetailsPopUp
