import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import NavigationHeder from '../../../Navigations/NavigationHeder';
import Collapse from '../../../inc/Collapse';
import SimpleLoading from '../../../Loading/SimpleLoading';
import Helper from '../../../../inc/Helper';
import Input from '../../../Forms/Input';
import DropDownInput from '../../../Forms/Dropdown';
import LinkBtn from '../../../inc/LinkBtn';
import Button from '../../../inc/Button';
import { useNavigate, useParams } from 'react-router-dom';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings';
import { areObjectsDifferent, fixColsData, getHeaderRowData, getPopupMessageBasedOnStatus, updateExcelDataProps, validateHeaderObjectsFromExcel } from '../../../../inc/Validation';
import MasterComonent from '../../../Backend/MasterComonent';
import * as XLSX from 'xlsx'; 
import AgGridNew from '../../../grid/ag/ag-grid-new';
import { getNewValue } from '../../../grid/ag/commonFunctions/GridCommonFunctions';
import OverlayLoader from '../../../PurchaseRequisition/common/OverlayLoader';

/*

Validations: Category is required field

LYNKAZ - Compliances With Templates / Lines,
includes?

EditMode, execution of EDIT-MODE API level calls,
CreateMode, execution of CREATE-MODE APOI level calls,

*/

let currentTemplateLineViewGridObj = null;
let defaultComplianceTemplateObject = null; 
let AddBtn = null;
let transaction = null;

const DetailViewComplianceTemplatesWithLines = ({
  language, transactionalLevel=false, reloadParentGridCompliance, editMode=false, executeAPIForSaveHandler, rfq_id, closePopupView
}) => {
  const [complianceTemplate, setComplianceTemplate] = useState({
    name: null,
    complianceheader_id: null, 
  });
 
  const [templateLineRFQ, setTemplateLineRFQ] = useState({
    category: null,
    subcategory: null, 
    compliance_description: null
  });

  const [complianceHeaders, setComplianceHeaders] = useState([]);

  const [loading, setLoading] = useState(true);
  const wrapperDiv = useRef(null);
  
  const [height, setHeight] = useState(0);

  const {id} = useParams();

  const navigate = useNavigate()

  let complianceHeaderCalled = false;
  let complianceTemplateCalled = false; 

  useEffect(() => { 
    Helper.resizeWindowHandle(wrapperDiv, setHeight, window) 
  }, [wrapperDiv?.current?.clientHeight, loading]);

  useEffect(() => {
    getComplianceTemplateById() 
    getComplianceHeaders()
  }, [])

  const getComplianceTemplateById = () => {
    if (complianceTemplateCalled || (!editMode && !id)) 
    {
      setLoading(false);
      return;
    };

    setLoading(true);
    let api = Api
    complianceTemplateCalled = true;
    api.setUserToken()
    api.axios().get(Settings.apiPurchaseRequisitionUrl+`/compliance_template/${id}`,{}).then(function(res){
      if(res.data.status==='success'){
        setComplianceTemplate(res.data.data); 
        defaultComplianceTemplateObject=res.data.data
        setLoading(false);
      };
    }).catch((res) => { 
      setLoading(false);
      setComplianceTemplate({})
      getPopupMessageBasedOnStatus(res) 
    })
  }
  
  const getComplianceHeaders = () => {
    if(complianceHeaderCalled) return;

    let api = Api
    complianceHeaderCalled = true;
    api.setUserToken()
    api.axios().get(Settings.apiPurchaseRequisitionUrl+'/complianceresponseheader',{}).then(function(res){
      if(res.data.status==='success'){
        let Dropdown_data = []
        res.data.data.forEach((item) => {
          Dropdown_data.push({
            value: item.complianceheader_id,
            label: item.compliance_name 
          })
        });
        setComplianceHeaders(Dropdown_data); 
      };
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }

  // template level functions,
  
  const saveComplianceTemplateData = () => {
    let url = `/compliance_template`;
    if(transactionalLevel){ url += '?for_transaction=true' };

    setLoading(true);
    let api = Api 
    api.setUserToken()
    api.axios().post(Settings.apiPurchaseRequisitionUrl + url,{
      "name": complianceTemplate.name,
      "compliance_header_id": complianceTemplate.complianceheader_id,
      "rfq_id": transactionalLevel ? rfq_id : null
    }).then(function(res){
      if(res.data.status==='success'){ 
        Helper.alert(res.data.message);
        if(!transactionalLevel) navigate('/compliance-templates/listings');
        else {
          createTemplateLineForTransactional(res?.data?.template_id);
        }
        setLoading(false);
      } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
      setLoading(false);
    })
  }

  const createTemplateLineForTransactional = async (template_id) => { 
    try { 
      let payload = {
        category: templateLineRFQ?.category,
        subcategory: templateLineRFQ?.subcategory,
        compliance_description: templateLineRFQ?.compliance_description
      };

      let api = Api;
      api.setUserToken();
      
      const res = await api.axios().post(Settings.apiPurchaseRequisitionUrl+`/compliance_template_lines/${template_id}?for_transaction=true`, payload);
      if (res.data.status === "success") {
        Helper.alert(res?.data?.message);
        reloadParentGridCompliance();
        closePopupView();
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }

  const updateComplianceTemplateData = () => {
    if (!editMode && !id) return;

    if (!areObjectsDifferent(defaultComplianceTemplateObject, complianceTemplate)) {
      return;
    }

    setLoading(true);
    let api = Api 
    api.setUserToken()
    api.axios().put(Settings.apiPurchaseRequisitionUrl+`/compliance_template/${id}`,{
      name: complianceTemplate.name,
      compliance_header_id: complianceTemplate.complianceheader_id
    }).then(function(res){
      if(res.data.status==='success'){ 
        getComplianceTemplateById()
        Helper.alert(res.data.message)
        setLoading(false);
      } 
    }).catch((res) => { 
      getComplianceTemplateById()
      getPopupMessageBasedOnStatus(res) 
      setLoading(false);
    })
  }

  // grid level functions,
  
  const saveComplianceTemplateLineData = async (template_id, payload) => {  
    if(editMode) {
      if(!template_id) return;

      let url = `/compliance_template_lines/${template_id}`
      if(transactionalLevel) { url += `?for_transaction=true`}
    
      try { 
        let api = Api;
        api.setUserToken();
        
        const res = await api.axios().post(Settings.apiPurchaseRequisitionUrl + url, payload);
        if (res.data.status === "success") {
          setTimeout(() => {
            currentTemplateLineViewGridObj?.api?.refreshServerSide({ purge: true });  
            currentTemplateLineViewGridObj?.api?.deselectAll();
          }, 100);
  
          setTimeout(() => {
            if (AddBtn) {
              currentTemplateLineViewGridObj?.api?.applyServerSideTransaction(transaction);
            }
          }, 1000);
          
          Helper.alert(res?.data?.message);
        }
      } catch (error) {
        getPopupMessageBasedOnStatus(error);
      } 
    } 
  }
 
  const updateComplianceTemplateLineData = async (compliance_template_id, payload) => {
    if (!editMode && !id) return; 
    try { 
      let api = Api;
      api.setUserToken();
      const res = await api.axios().put(Settings.apiPurchaseRequisitionUrl+`/compliance_template_line/${compliance_template_id}`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          currentTemplateLineViewGridObj?.api?.refreshServerSide({ purge: true });
          currentTemplateLineViewGridObj?.api?.deselectAll();
        }, 100);

        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }
  
  //grid level functions,
  const onFileTypeValueChange = (e) => {
    const fileList = e.target.files;
    if (fileList.length === 0) return;

    const f = fileList[0];

    if (!f.name.endsWith('.xlsx')) {
      Helper.alert("Invalid file format. Only Excel files (.xlsx) are allowed.", 'error');
      return;
    };

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const fixedData = fixColsData(data);
      const workbook = XLSX.read(btoa(fixedData), { type: 'base64' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      
      let headerObjects = getHeaderRowData(worksheet);
      let excelData = updateExcelDataProps(XLSX.utils.sheet_to_json(worksheet));
      let validateHeaderObjects = validateHeaderObjectsFromExcel(headerObjects);
  
      let currentObjectsInGrid = [];
      currentTemplateLineViewGridObj?.api?.forEachNode((node) => {
        if(!node.data) return;
        currentObjectsInGrid.push(node.data)
      });

      if (validateHeaderObjects) {
        excelData?.forEach((item) => {
          saveComplianceTemplateLineData(id, {
            ...item,
            compliance_description: item?.description
          });  
        }) 
      };
    };
    reader.readAsArrayBuffer(f);
  }

  const ExportTemplateLinesSampleFile = () => { 
    const filePath = '/excel/Compliance_Template_Lines_Sample.xlsx';
    const a = document.createElement('a');
    a.href = filePath;
    a.download = 'Compliance_Template_Lines_Sample.xlsx';  
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const ExcelImportActionButton = () => { 
    return (
      <Fragment>
        <div className='compliance_action_excel_btn'>  
          <img className='img-fluid' src="/images/icons/excel-logo.svg" /> 
          <input type="file" onChange={onFileTypeValueChange} />
        </div>

        <div className='import_sample_template'>
          <button onClick={ExportTemplateLinesSampleFile}><img className='img-fluid' src="/images/icons/excel-logo.svg" />Export sample excel file</button>
        </div>
      </Fragment>
    )
  }

  const onInputValueChangeTemplateLine = (evt) => {
    let value = evt.target.value;
    let name = evt.target.name;
    setTemplateLineRFQ((prevState) => {
      return {
        ...prevState,
        [name]: value
      }
    })
  };

  const onInputValueChangeTemplate = (evt) => {
    let value = evt.target.value;
    let name = evt.target.name;
    setComplianceTemplate((prevState) => {
      return {
        ...prevState,
        [name]: value
      }
    })
  };
  
  const gridColHeaders = [
    { field:'category', editable: true, minWidth: 230, headerName: Helper.getLabel(language,'category','Category*')}, 
    { field:'subcategory', editable: true, minWidth: 230, headerName: Helper.getLabel(language,'subcategory','Sub category')},
    { field:'description', editable: true, minWidth: 230, headerName: Helper.getLabel(language,'description','Description')}, 
  ];

  const DeleteTemplateLine = useCallback(async (element) => {
    if(!editMode) return;

    let api = Api;
    api.setUserToken();
  
    try {
      const res = await api
        .axios()
        .delete(Settings.apiPurchaseRequisitionUrl+`/compliance_template_line/${element?.data?.compliance_templateline_id}`)
      const rowNode = currentTemplateLineViewGridObj?.api?.getRowNode(element?.data?.compliance_templateline_id)

      if (rowNode) {
        rowNode.setSelected(false)
      }
  
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        currentTemplateLineViewGridObj?.api?.applyServerSideTransaction(transaction)
      }, 200) 
  
      currentTemplateLineViewGridObj?.api?.refreshServerSide({ purge: true });
      currentTemplateLineViewGridObj?.api?.deselectAll();
      
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);

  const handleFetchData = (response) => {
    return {
      rowData: response?.data?.data?.map((item) => ({
        ...item,
        description: item.compliance_description,
        gridId: `${Date.now()}_${getNewValue()}`
      })),
      rowCount: response?.data?.data?.length,
    }
  }
  
  const handleBtn = (value, transactionData) => {
    AddBtn = value
    transaction = transactionData
  }

  const onRowValueChanged = (event) => {
    let payload = {
      category: event?.data?.category,
      subcategory: event?.data?.subcategory,
      compliance_description: event?.data?.description
    }
    if(!event?.data?.compliance_templateline_id){
      saveComplianceTemplateLineData(id,payload);
    } else {
      updateComplianceTemplateLineData(event?.data?.compliance_templateline_id, payload);
    }
  }
 
  // executing functions
 
  const onClickSaveButton = () => {
    if(!complianceTemplate.name) {
      Helper.alert('Please provide name', 'error')
      return;
    }
    
    if(!complianceTemplate.complianceheader_id) {
      Helper.alert('Please provide compliance header', 'error')
      return;
    }

    if(transactionalLevel) {
      if(!templateLineRFQ.category){
        Helper.alert('Please provide category', 'error')
        return;
      }

      if(!templateLineRFQ.subcategory){
        Helper.alert('Please provide Sub category', 'error')
        return;
      }
    }
  
    if (!editMode) {
      saveComplianceTemplateData();  
      return;
    }
 
    updateComplianceTemplateData();  
  };

  {
    transactionalLevel &&
    executeAPIForSaveHandler(onClickSaveButton);
  } // allowing save handler function to be triggered inside parent component,

  // modules, 

  const ComplianceTemplateModule = () => {
    return (
      <div className='compliance_templates_fields_collapse mb-4'> 
        <Collapse open={true} title={`Compliance Template`}>
          <div className='template_view_fields_wrapper'> 
            <div className='row'>
              <div className='col-lg-3'>
                <Input name='name' required={true} label={Helper.getLabel(language,'name',"Name")} onChange={onInputValueChangeTemplate} value={complianceTemplate.name} />
              </div>
              <div className='col-lg-3'>
                <DropDownInput reRenderRequired={true} required={true} name='complianceheader_id' id='complianceheader_id' label={Helper.getLabel(language,'compliance_header',"Compliance header")} options={complianceHeaders} onChange={onInputValueChangeTemplate} value={complianceTemplate.complianceheader_id} />
              </div>
            </div>
          </div>
        </Collapse> 
      </div>
    )
  };

  const ComplianceTemplateLineViewModuleRFQ = () => {
    return (
      <div className='compliance_templates_lines_collapse'> 
        <Collapse className='grid_save_btn_hide' open={true} title={`Default template line`}>
          <div className='container-fluid grid_compliance_templates_lines'> 
            <div className='row'>
              <div className='col-lg-3'>
                <Input name='category' required={true} label={Helper.getLabel(language,'category',"Category")} onChange={onInputValueChangeTemplateLine} value={templateLineRFQ.category} />
              </div>
              <div className='col-lg-3'>
                <Input name='subcategory' required={true} label={Helper.getLabel(language,'subcategory',"Sub category")} onChange={onInputValueChangeTemplateLine} value={templateLineRFQ.subcategory} />
              </div>
              <div className='col-lg-6'>
                <Input name='compliance_description' label={Helper.getLabel(language,'description',"Description")} onChange={onInputValueChangeTemplateLine} value={templateLineRFQ.compliance_description} />
              </div>
            </div>
          </div>
        </Collapse> 
      </div>
    )
  }

  const ComplianceTemplateLineViewModule = () => {
    return (
      <div className='compliance_templates_lines_collapse'> 
        <Collapse className='grid_save_btn_hide' open={true} title={`Compliance Template Lines`}>
          <div className='container-fluid grid_compliance_templates_lines'> 
            <AgGridNew
              apiUrl={id?Settings.apiPurchaseRequisitionUrl+`/compliance_template_lines/${id}`:null}  
              handleDeleteSelectedRows={DeleteTemplateLine} 
              beforeDeleteBtns={<ExcelImportActionButton />} 
              fetchData={handleFetchData}
              handleAddButton={handleBtn}
              columnDefs={gridColHeaders}  
              onRowValueChanged={onRowValueChanged}
              onGridReady={(params) => currentTemplateLineViewGridObj = params}
              height={500}
              gridId={'compliance_template_lines_grid'}
              uniqueField={'compliance_templateline_id'}
              pagination={false}  
            />
          </div>
        </Collapse> 
      </div>
    )
  };

  const ModuleTopLevelHeaderWrapper = () => {
    return (
      <div ref={wrapperDiv}> 
        <NavigationHeder hideMoreBtn={true} backUrl='/compliance-templates/listings' title={Helper.getLabel(language, editMode ? 'edit_template' : 'create_template', editMode ? 'Edit Template' : 'Create Template')}>  
          <LinkBtn isActive= {false} to={'/compliance-templates/listings'} title={Helper.getLabel(language,'close',"Close")} />
          <Button className="rfq_save_btn" isActive= {false} title={Helper.getLabel(language,'save',"Save")} onClick={onClickSaveButton} /> 
        </NavigationHeder>  
      </div>
    )
  };

  const DetailViewModuleWrapperForMasterLevel = () => {
    return (
      <MasterComonent> 
        <div className='container-fluid detail_view_template_with_lines'>
          {ModuleTopLevelHeaderWrapper()}
          <div className='compliance_templates_with_lines_wrapper common_scrollbar_styles' style={{ height: `${height - 30}px`, overflowY: 'auto'}}>
            {ComplianceTemplateModule()}
            {editMode && ComplianceTemplateLineViewModule()}
          </div>
        </div>
      </MasterComonent>
    );
  };

  const TransactionalLevelView = () => {
    return (
      <div className='container-fluid detail_view_template_with_lines p-0'> 
        {ComplianceTemplateModule()}
        {ComplianceTemplateLineViewModuleRFQ()}
      </div>
    )
  };

  return (
    <Fragment>  
      { loading ? <OverlayLoader isPopupInTab={true} /> : (
        !transactionalLevel ?
          DetailViewModuleWrapperForMasterLevel()
        :
          TransactionalLevelView()
        )
      }
    </Fragment>
  )
};

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
};

export default connect(mapStateToProps) ((DetailViewComplianceTemplatesWithLines)); 