import React from 'react'
import EditPO from '../../../Purchase Orders Internal/Edit PO/EditPO'
import Helper from '../../../../inc/Helper'

const EditInvoiceOnAccount = () => {
  return (
    <EditPO
      title={Helper.getLabel(
        null,
        'edit_invoice',
        'Edit Invoice'
      )}
      lineTitle={Helper.getLabel(null, 'invoice_lines',"Invoice Lines")}
      backUrl={-1}
      hideStartDate={false}
      hideEndDate={false}
      hideBillingRule={false}
      source_id={16}
      visibleEndDate={true}
      visibleStartDate={true}
      type={"invoice on account"}
    />
  )
}

export default EditInvoiceOnAccount
