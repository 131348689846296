import React, { useState } from 'react'
import Popup from '../../Popup/Popup'
import CustomHtmlCell from '../../grid/ag/cellRender/CustomHtmlCell'
import LineViewComponent from '../../Purchase Orders Internal/Edit PO/ListView/LineViewComponent'
import Helper from '../../../inc/Helper'
import RFQAttachmentPopup from '../../External Screens/RFQ Vendor Reply/RFQList/RFQAttachmentPopup'
import $ from 'jquery'

const AlternateLinesPopup = ({
  rfqVendorData,
  onAlternateLinePopupClose,
  allFieldsAreZero,
  bidStatus,
  id,
  onGridReady,
  gridValueColorFormat,
  displayViewAttachedLogo,
  language,
  vendorReplyLineId,
  get_rfq_attachments,
  setRFQVendorAttachments,
  setLineId,
  lineId,
  rfqAttachmentData
}) => {
 const [showModal,setShowModal]=useState(false)
 const [popUpPosition, setPopUpPosition] = useState({ top: 0, right: 0 })
 const [attachmentSourceId,setAttachmentSourceId]=useState(null)

 const closeModal = () => {
    setShowModal(false);
    $('html').removeClass('rs_popup_open');
  };

  const attachmentCellClick = (event) => {
    if (event.colDef.field === 'attached') {
    setShowModal(true)
    let scrollY = window.scrollY || document.documentElement.scrollTop;
    let mouseY = event.event.clientY;
    let absoluteMouseY = mouseY + scrollY;
    let mouseX = event.event.clientX;

    let popupWidth = 370; 
    let absoluteMouseX = window.innerWidth - mouseX - popupWidth;

    absoluteMouseX = Math.max(absoluteMouseX, 0);
    absoluteMouseX = Math.min(absoluteMouseX, window.innerWidth - popupWidth);

    setPopUpPosition({ top: absoluteMouseY, right: absoluteMouseX });
      const attachedId = event.data.attachmentsource_id
      const fetchId = event.data.line_id
      setLineId(fetchId)
      setAttachmentSourceId(attachedId)
      setRFQVendorAttachments([])
      const source_id =  80 
      get_rfq_attachments(fetchId, source_id)
    }
  }
  return (
    <Popup autoOpen={true} width='1200px' onClose={onAlternateLinePopupClose}>
      <div className='rfq-line-view'>
        <LineViewComponent
          defaultValues={{
            requester_id: rfqVendorData?.requester_id,
          }}
          lineGridId='rfq-alternate-lines'
          source_id={rfqVendorData?.source_id}
          line_source={rfqVendorData?.source_id}
          isEditable={
            rfqVendorData?.onhold ||  rfqVendorData?.RFQ_status===2|| rfqVendorData?.RFQ_status === 9 || !rfqVendorData?.edit_on_behalf ? false : rfqVendorData.interested_to_participate &&
            !allFieldsAreZero &&
            !bidStatus
          }
          lineGridTitle={Helper.getLabel(
            language,
            'Alternate Lines',
            'Alternate Lines'
          )}
          header_id={id}
          purchase_status={null}
          document_status={null}
          delivery_date={null}
          onbehalf_request={false}
          vendor_id={null}
          vendorsite_id={null}
          requester_id={rfqVendorData?.requester_id}
          orderer_id={rfqVendorData?.order_id}
          setOrderStatusProps={null}
          warehouse_id={null}
          taxgroup_item={null}
          taxgroup_id={null}
          onCellClicked={attachmentCellClick}
          onLineGridReady={onGridReady}
          alternate_line={true}
          formType="rfq"
          distributionGridId="distribution_alternative"
          billingrulegrid_id="alternate"
          vendorReplyLineId={vendorReplyLineId}
          rfqExternal={false}
          additionalLineColumns={[
            {
              field: 'attached',
              minWidth: 120,
              headerName: Helper.getLabel(language, 'attached', 'Attached'),
              cellClass: 'rfq-attached-cell',
              cellRenderer: displayViewAttachedLogo,
              editable:false,
            },
          ]}
        />
      </div>
      {showModal && (
          <RFQAttachmentPopup
            closeModal={closeModal}
            data={rfqAttachmentData}
            language={language}
            type={'line view'}
            source_id={80}
            attachmentsource_integrator={lineId}
            setRfqAttachment={setRFQVendorAttachments}
            onAttachmentUpload={() => {
              get_rfq_attachments(lineId, 80)
            }}
            style={{
              top: popUpPosition.top,
              right: popUpPosition.right,
            }}
            attachmentsource_id={attachmentSourceId}
          />
        )}
    </Popup>
  )
}

export default AlternateLinesPopup
