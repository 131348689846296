const Constant_GUI = { 
    createMode: '001',
    editMode: '002',
    listPage: '003',
    sidePanel: '004',
    simpleForm: '005',
    screen: '006',
    detailView: '007',
    buttonResourceId: '35',
    createButton: '01',
    editButton: '02',
    deleteButton: '03',
    saveButton: '04',
    viewButton: '05',
    openButton: '06',
    uniqueButton: '07'
}

export default Constant_GUI;