import React,{useState,useEffect} from "react";
import Tabs from "../../../Widgets/Tabs/Tabs";
import Dropdown from "../../../Forms/Dropdown";
import Helper from "../../../../inc/Helper";
import Input from "../../../Forms/Input";
import SimpleLoading from "../../../Loading/SimpleLoading";
import { useLocation } from "react-router-dom";
import Settings from "../../../../inc/Settings";
import Api from "../../../../inc/Api";
import RFQCommonApiCalls from "../../RFQCommonApiCalls";
import Checkbox from "../../../Forms/Checkbox";
import DatepickerFunctions from "../../../../inc/DatepickerHelper";
import RFQBiddingRequirement from "../../../External Screens/RFQ Vendor Reply/RFQBiddingDetails/RFQBiddingRequirement";

const GeneralInformationRFQReply = ({
  language,
  rfqHeader,
  isLoading,
  setRfqHeader,
  updateRFQ,
  setUpdateRFQ,
  employeesList,
  currencyList,
  securityLevelList,
  quotationTypeList,
  paymentTermList,
  modeOfDeliveryList,
  deliveryTermList,
  allowEdit,
  allowEditOnly
}) => {
  const location = useLocation();
  const [entity, setEntity] = useState([]);
  const [sites, setSites] = useState([]);
  const [warehouse, setWarehouse] = useState([]);

  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search);
    return params.get(name);
  };

  const generalNavs = [
    { title: "Basic", isActive: true },
    { title: "Additional Information" },
    { title: "Price & Discount" },
    { title: "Requirements" },
  ];

  const rfqCommonApiCalls = new RFQCommonApiCalls();

  useEffect(() => {
    const api = Api;
    api.setUserToken();

    const getSites = async () => {
      const res = await rfqCommonApiCalls.getAllSites();
      setSites(res);
    };

    getSites();

    const getWarehouse = async () => {
      const res = await rfqCommonApiCalls.getAllWarehouses();
      setWarehouse(res);
    };

    getWarehouse();

    const getAllEntity = () => {
      api
        .axios()
        .post(Settings.loginUrl + "/search_entity", {
          code: null,
          name: null,
        })
        .then((res) => {
          if (res.data.status === "success") {
            setEntity(res.data?.data);
          }
        })
        .catch((error) => {
          Helper.alert(error?.response?.data?.message, "error");
        });
    };

    getAllEntity();
  }, []);

  const getBuyerNameFromId = (id) => {
   let buyer = employeesList.find((emp) => emp.employee_id === id)
    return buyer?.displayname;
  };

  const getSecurityLevelFromId = (id) => {
    return securityLevelList.filter((sec) => sec.id === id)[0]?.name;
  };

  const getQuotationTypeFromId = (id) => {
    return quotationTypeList.filter((quo) => quo.id === id)[0]?.name;
  };

  const getEntityNameFromId = (id) => {
    return entity.filter((en) => en.entity_id === id)[0]?.name;
  };

  const getSiteNameFromId = (id) => {
    return sites.filter((s) => s.site_id === id)[0]?.name;
  };

  const getWarehouseNameFromId = (id) => {
    return warehouse.filter((wr) => wr.warehouse_id === id)[0]?.name;
  };

  const getModeOfDeliveryNameFromId = (id) => {
    return modeOfDeliveryList.filter((el) => el.mode_of_delivery_id === id)[0]
      ?.name;
  };

  const getDeliveryTermNameFromId = (id) => {
    return deliveryTermList.filter((el) => el.delivery_term_id === id)[0]?.name;
  };

  const onInputCheckboxChangeHandler = (name, value) => {
    if (value === "on") {
      setRfqHeader((prevState) => {
        return {
          ...prevState,
          price_include_tax: true,
        };
      });
      setUpdateRFQ((prevState) => {
        return {
          ...prevState,
          price_include_tax: true,
        };
      });
    } else {
      setRfqHeader((prevState) => {
        return {
          ...prevState,
          price_include_tax: false,
        };
      });
      setUpdateRFQ((prevState) => {
        return {
          ...prevState,
          price_include_tax: false,
        };
      });
    }
  };

  let paymentTermListOptions = [];
  paymentTermList.forEach((item) => {
    paymentTermListOptions.push({
      value: item.paymentterm_id,
      label: `${item.code} - ${item.name}`,
    });
  });

  return (
    <div className="generalInformation">
      {isLoading && !getSearchParam("new") ? (
        <SimpleLoading />
      ) : (
        <Tabs navs={generalNavs} showAll={false} disableSticky={true}>
          <div className="generalInformation-basic">
            <div className="generalInformation-basic-grid">
              <InputPlaceholder
                label={`${Helper.getLabel(
                  language,
                  "document_number",
                  "Document number"
                )}`}
                value={rfqHeader?.document_number}
              />
              <InputPlaceholder
                label={`${Helper.getLabel(language, "version", "Version")}`}
                value={rfqHeader?.version===-1 ? "":rfqHeader?.version ?? "-"}
              />

              <InputPlaceholder
                label={`${Helper.getLabel(language, "title", "Title")}`}
                value={rfqHeader?.title}
              />
                <InputPlaceholder
                label={`${Helper.getLabel(
                  language,
                  'description',
                  'Description'
                )}`}
                value={rfqHeader?.description}
              />
              <InputPlaceholder
                label={`${Helper.getLabel(language, "buyer", "Buyer")}`}
                value={getBuyerNameFromId(rfqHeader?.buyer_id)}
              />
              <InputPlaceholder
                label={`${Helper.getLabel(
                  language,
                  "security_level",
                  "Security level"
                )}`}
                value={getSecurityLevelFromId(rfqHeader?.security_level)}
              />

              <InputPlaceholder
                label={`${Helper.getLabel(
                  language,
                  "quotation_type",
                  "Quotation type"
                )}`}
                value={getQuotationTypeFromId(rfqHeader?.quotation_type)}
              />
              <InputPlaceholder
                label={`${Helper.getLabel(
                  language,
                  "delivery_date",
                  "Delivery date"
                )}`}
                value={DatepickerFunctions?.convertDateFromDataBase(rfqHeader?.delivery_date)}
              />
              <InputPlaceholder
                label={`${Helper.getLabel(
                  language,
                  "last_publish_date_time",
                  "Last publish date and time"
                )}`}
                value={DatepickerFunctions.convertDateTimeFromDataBase(
                  rfqHeader?.publish_datetime
                )}
              />
              <InputPlaceholder
                label={`${Helper.getLabel(
                  language,
                  "expiry_date_time",
                  "Expiry date and time"
                )}`}
                timepicker={true}
                value={DatepickerFunctions.convertDateTimeFromDataBase(
                  rfqHeader?.expiry_datetime
                )}
              />
              <InputPlaceholder
                label={`${Helper.getLabel(language, "entity", "Entity")}`}
                value={getEntityNameFromId(rfqHeader?.entity_id)}
              />

              <InputPlaceholder
                label={`${Helper.getLabel(language, "site", "Site")}`}
                value={getSiteNameFromId(rfqHeader?.site_id)}
              />
            </div>
            <div className="generalInformation-basic-grid">
              <InputPlaceholder
                label={`${Helper.getLabel(language, "warehouse", "Warehouse")}`}
                value={getWarehouseNameFromId(rfqHeader?.warehouse_id)}
              />
              <InputPlaceholder
                label={`${Helper.getLabel(
                  language,
                  "vendor_name",
                  "Vendor name"
                )}`}
                value={rfqHeader?.Vendor_name}
              />
              <InputPlaceholder
                label={`${Helper.getLabel(
                  language,
                  "vendor_code",
                  "Vendor code"
                )}`}
                value={rfqHeader?.vendor_code}
              />
              <InputPlaceholder
                label={`${Helper.getLabel(
                  language,
                  "attention_information",
                  "Attention information"
                )}`}
                value={rfqHeader?.attention_information}
              />
              <Input
                label={`${Helper.getLabel(language, "rank", "Rank")}`}
                value={rfqHeader?.rank}
                disable={!allowEditOnly}
                inputType="number"
                maxLength={3}
                onKeyDown={(event)=>{
                  if (event.key === '.') {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setUpdateRFQ({
                    ...updateRFQ,
                    rank: e.target.value,
                  })
                  setRfqHeader({
                    ...rfqHeader,
                    rank: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="generalInformation-advanced">
            <div className="generalInformation-basic-grid">
              <InputPlaceholder
                label={`${Helper.getLabel(language, "requester", "Requester")}`}
                value={getBuyerNameFromId(rfqHeader?.requester_id)}
              />
              <Dropdown
                label={`${Helper.getLabel(language, "currency", "Currency")}`}
                options={currencyList?.map((item) => {
                  return {
                    value: item.currency_id,
                    label: `${item.currency_code} - ${item.currency_name}`,
                  };
                })}
                placeHolder={`${Helper.getLabel(
                  language,
                  "currency",
                  "Currency"
                )}`}
                id="rfq_general_currency"
                onChange={(e) => {
                  setUpdateRFQ({
                    ...updateRFQ,
                    currency_id: e.target.value,
                  });
                  setRfqHeader({
                    ...rfqHeader,
                    currency_id: e.target.value,
                  });
                }}
                value={rfqHeader?.currency_id}
                isDisable={!allowEdit}
              />
              <InputPlaceholder
                label={`${Helper.getLabel(
                  language,
                  "mode_of_delivery",
                  "Mode of delivery"
                )}`}
                value={getModeOfDeliveryNameFromId(
                  rfqHeader?.mode_of_delivery_id
                )}
              />
              <InputPlaceholder
                label={`${Helper.getLabel(
                  language,
                  "delivery_term",
                  "Delivery term"
                )}`}
                value={getDeliveryTermNameFromId(rfqHeader?.delivery_term_id)}
              />
              {/* <InputPlaceholder
                label={`${Helper.getLabel(language, "email", "Email")}`}
                value={rfqHeader?.email_address}
              /> */}
              <InputPlaceholder
                label={`${Helper.getLabel(
                  language,
                  "external_reference",
                  "External reference"
                )}`}
                value={rfqHeader?.external_reference}
              />
            </div>
            <div className="bidding_requirement rfq-internal-requirements w-100">
                <div className="scope_of_work">
                  <h2 className="border-bottom-h2">
                    {Helper.getLabel(
                      language,
                      "notes_to_vendor",
                      "Notes to vendor"
                    )}
                  </h2>
                  <div className="request_for_quotation_content w-100 mt-2">
                    <textarea
                      className="request_for_quotation_content rfq-textarea w-100"
                      disabled={true}
                      name="note_to_vendor"
                      defaultValue={rfqHeader?.note_to_supplier}
                      value={rfqHeader?.note_to_supplier}
                    >
                    </textarea>
                  </div>
                </div>
              </div>
          </div>
          <div>
            <div className="generalInformation-basic-grid">
              <Dropdown
                ajax={null}
                id="payment_term_rfq_dropdown"
                required={false}
                label="Payment term"
                options={paymentTermListOptions}
                value={rfqHeader?.paymentterm_id}
                name="paymentterm_id"
                isDisable={!allowEdit}
                onChange={(e) => {
                  setUpdateRFQ({
                    ...updateRFQ,
                    paymentterm_id: e.target.value,
                  });
                  setRfqHeader({
                    ...rfqHeader,
                    paymentterm_id: e.target.value,
                  });
                }}
              />
              <Input
                label={`${Helper.getLabel(
                  language,
                  "discount_percentage",
                  "Discount percentage"
                )}`}
                value={rfqHeader?.discount_percentage}
                disable={!allowEdit}
                inputType="number"
                onChange={(e) => {
                  setUpdateRFQ({
                    ...updateRFQ,
                    discount_percentage: e.target.value,
                  });
                  setRfqHeader({
                    ...rfqHeader,
                    discount_percentage: e.target.value,
                  });
                }}
              />
              <Input
                label={`${Helper.getLabel(
                  language,
                  "discount_amount",
                  "Discount amount"
                )}`}
                value={rfqHeader?.discount_amount}
                disable={!allowEdit}
                inputType="number"
                onChange={(e) => {
                  setUpdateRFQ({
                    ...updateRFQ,
                    discount_amount: e.target.value,
                  });
                  setRfqHeader({
                    ...rfqHeader,
                    discount_amount: e.target.value,
                  });
                }}
              />
              <Input
                label={`${Helper.getLabel(
                  language,
                  "rounding_amount",
                  "Rounding amount"
                )}`}
                value={rfqHeader?.rounding_amount}
                disable={!allowEdit}
                inputType="number"
                onChange={(e) => {
                  setUpdateRFQ({
                    ...updateRFQ,
                    rounding_amount: e.target.value,
                  });
                  setRfqHeader({
                    ...rfqHeader,
                    rounding_amount: e.target.value,
                  });
                }}
              />
               <Checkbox
                id="price_include_tax"
                type="checkbox"
                name="price_include_tax"
                onChange={onInputCheckboxChangeHandler}
                disableWithoutIcon={!allowEdit}
                label="Price inclusive of tax"
                isChecked={rfqHeader?.price_include_tax}
              />
            </div>
          </div>
          <div className="bidding_requirement rfq-internal-requirements">
            <RFQBiddingRequirement 
               scopeOfWork={rfqHeader?.scope_of_work}
               materialsAndServicesNotIncluded={
               rfqHeader?.material_service_not_included
             }
             agreementTerms={rfqHeader?.requirement_description}
             type="rfq"
             disable={true}
             onRequirementChange={()=>{}}
             onRequirementBlur={()=>{}}
            />
          </div>
        </Tabs>
      )}
    </div>
  );
};

const InputPlaceholder = ({ label, value }) => {
  return (
    <Input label={label} disable={true} value={value} />
  );
};

export default GeneralInformationRFQReply;
