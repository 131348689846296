import React, { Fragment, useState } from 'react'
import Tabs from '../../../Widgets/Tabs/Tabs'
import MasterSidebar from '../../../Sidebars/MasterSidebar'
import NoteAttachmentHistory from '../../../Widgets/NoteAttachmentHistory'
import Summary from '../CreateWorkConfirmation/NewWorkConfirmation/Summary'
import Helper from '../../../../inc/Helper'
import Settings from '../../../../inc/Settings'
import SimpleLoading from '../../../Loading/SimpleLoading'
import WorkFlowOffCanvas from '../../../Header/WorkFlowOffCanvas'
import AdditionalInformation from '../../RFQ Vendor Reply/RFQBiddingDetails/AdditionalInformation'
import Collapse from '../../../inc/Collapse'

//for render tabs data
const WorkConfirmationTabData = (props) => {
  const [showCanvas, setShowCanvas] = useState(false);
  const [showCurrentAssignees, setShowCurrentAssignees] = useState(false)

  //data for grid and tabs
  const defaultNav = [
    {
      title: Helper.getLabel(props.language, 'hide_tab', 'hide_tab'),
      isActive: true,
    },
  ]

  const innerTabs = [
    {
      title: Helper.getLabel(
        props.language,
        'general_information',
        'General Information'
      ),
      isActive: true,
    },
    {
      title: Helper.getLabel(
        props.language,
        'additional_information',
        'Additional Information'
      ),
    },
  ]

  let generalData = [
    {
      id: 1,
      title: 'Source document number',
      subtitle: props.workConfirmationData?.document_number,
    },
    {
      id: 2,
      title: 'Status',
      subtitle: props.document_status,
      className:props?.document_status?.toLowerCase()==='posted'?`status-style success-style`:`status-style draft-style`
    },
    {
      id: 3,
      title: 'Vendor code',
      subtitle: props.workConfirmationData?.vendor_code,
    },
    {
      id: 4,
      title: 'Vendor name',
      subtitle: props.workConfirmationData?.vendor_name,
    },
    {
      id: 5,
      title: 'Vendor site',
      subtitle: props.workConfirmationData?.vendorsite_name,
    },
    // {
    //   id: 6,
    //   title: 'Show lines',
    //   subtitle: '-',
    // },
    {
      id: 7,
      title: 'Work confirmation number',
      subtitle: props.workConfirmationData?.receiving_reference_number,
    },
    {
      id: 8,
      title: 'Accounting date',
      subtitle: Helper.formateDate(props.workConfirmationData?.accounting_date),
    },
    {
      id: 9,
      title: 'Document date',
      subtitle: Helper.formateDate(props.workConfirmationData?.document_date),
    },
  ]

  //for add right sidebar
  const addComponentRight = () => {
    return (
      <div className='parent_sidebar_cn'> 
        <MasterSidebar>
          <div style={{ background: '#fff' }}>
            <NoteAttachmentHistory
              openCanvas={setShowCanvas}
              openCurrentAssignees={setShowCurrentAssignees}
              hideHistory={!props.workflowActionHistory?.history?.length || props?.userType === 1}
              historyArray={props.workflowActionHistory}
              notesource_integrator={props.shipment_id}
              attachmentsource_integrator={props.shipment_id}
              source_id={Settings.source_id.ShipmentGoodReceivingWorkConfirmation}
            />
          </div>
        </MasterSidebar>
        
        {showCanvas &&
          <WorkFlowOffCanvas
            isOpen={showCanvas}
            type={{ label: "Action History", value: 9 }}
            closeSidebar={setShowCanvas}
            source_id={Settings.source_id.ShipmentGoodReceivingWorkConfirmation}
            source_item_id={props.shipment_id}
          />
        }

      {showCurrentAssignees && 
          <WorkFlowOffCanvas
            isOpen={showCurrentAssignees}
            type={{ label: "View current assignment", value: 43 }}
            closeSidebar={setShowCurrentAssignees}
            source_id={Settings.source_id.ShipmentGoodReceivingWorkConfirmation}
            source_item_id={props.shipment_id}
          />
        }    
      </div>
    )
  }

  if(props.userType===1){
    generalData = generalData.filter((item)=>item.title !== "Accounting date")
  }

  return (
    <Tabs
      navs={defaultNav}
      showAll={true}
      scrollOffset="300"
      disableSticky={true}
      addComponentRight={addComponentRight}
    >
      <Fragment>
        <div className="bidding_tab mt-3">
          <div className="mt-1 header-tab-rfq">
            <Tabs
              navs={innerTabs}
              disableSticky={true}
              scrollOffset='300'
              showAll={false}
            >
              <div className="mt-3 ps-3 pe-3">
                <div className='external-general-information'>
                  <div className='purchase-order-inner-details general-details-responsive'>
                    {generalData.map((item) => (
                      <div
                        className="row justify-content-between"
                        key={item.id}
                      >
                        <div className="col-sm-6">
                          <span className="purchase-order-external-title">
                            {item.title} :
                          </span>
                        </div>
                        <div className="col-sm-6">
                          <span className={item.className}>{item.subtitle}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='ps-3 pe-3'>
              <Collapse
                  className="mt-3"
                  title={Helper.getLabel(
                    props.language,
                    "additional_information",
                    "Additional Information"
                  )}
                  open={true}
                >
                <AdditionalInformation language={props.language} data={props?.workConfirmationData} isNeedNotes={false} title={Helper.getLabel(
                    props.language,
                    "description",
                    "Description"
                  )} />
                </Collapse>
              </div>
            </Tabs>
          </div>
        </div>

        <div className="mt-3 external-read-only-grid line-tabs">
          {props.isLoading ? (
            <SimpleLoading />
          ) : (
            <Summary
              shipment_id={props.shipment_id}
              linesData={props.linesData}
              lineIdOptions={props.lineIdOptions}
              viewType="Work Confirmation Details"
              userType={props.userType}
            />
          )}
        </div>
      </Fragment>
    </Tabs>
  )
}

export default WorkConfirmationTabData
