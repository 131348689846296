import React, { Component } from "react";
import { connect } from "react-redux";

import Helper from "../../../inc/Helper";
import ActionTypes from "../../../inc/ActionsTypes";

import EditGrey from "../../../assets/edit-grey.svg";
import DeleteGrey from "../../../assets/delete-grey.svg";
import DropdownIcon from "../../../assets/dropdown-grey.svg";
import ImageNotFound from "../../../assets/image-not-found.jpg";
import DistributionTabs from "../common/DistributionTabs";
import Settings from "../../../inc/Settings";
import Api from "../../../inc/Api";
import { getPopupMessageBasedOnStatus } from "../../../inc/Validation";

class CartItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false,
      quantity: 0,
      material_service_not_included:this.props.cartItem?.material_service_not_included ?? null,
      scope_of_work:this.props.cartItem?.scope_of_work ?? null,
      requirement_description:this.props.cartItem?.requirement_description ?? null
    };

    this.onCreateFirstDistribution = this.onCreateFirstDistribution.bind(this);
    this.getImageUrl = this.getImageUrl.bind(this)
  }
  componentDidMount() {
    this.setState({ quantity: this.props.cartItem.ordered_qty });
    if (
      this.props.selectedCartItems.indexOf(this.props.cartItem.line_id) !== -1
    ) {
      this.setState({ checked: true });
    } else {
      this.setState({ checked: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.selectedCartItems) !==
      JSON.stringify(prevProps.selectedCartItems)
    ) {
      if (
        this.props.selectedCartItems.indexOf(this.props.cartItem.line_id) !== -1
      ) {
        this.setState({ checked: true });
      } else {
        this.setState({ checked: false });
      }
    }
  }

  getTotalDiscount(item) {
    let totalDiscount = 0;
    if (item.ordered_qty && item.unit_price && item.line_amount) {
      totalDiscount +=
        parseFloat(item.ordered_qty) * parseFloat(item.unit_price) -
        parseFloat(item.line_amount);
    } else {
      return "-";
    }

    return parseFloat(totalDiscount.toFixed(2)).toString();
  }

  checkLineTypeName(id) {
    const type = this.props.lineTypesList?.find((obj) => obj.id === id);
    return type ? type.name : null;
  }

  async onCreateFirstDistribution(source_id,source_integator) {
    let api = Api;
    api.setUserToken();
    try {
      const res = await api.axios().put(
        Settings.apiPurchaseOrderUrl +
          "/linesite/" +
          source_integator + "/" + Settings.source_id.purchase_requisition,

        { distributionsource_id: source_id }
      );
      if (res) {
        this.setState({ distributionSourceId: source_id });
      }
    } catch (error) {
       getPopupMessageBasedOnStatus(error)
    }
  }

  getImageUrl(){
    const {
      cartItem,
    } = this.props;
    return cartItem?.images[0]?.image

  }

  render() {
    const {
      cartItem,
      activeDistribution,
      toggleDistribution,
      handleSelect,
      viewOnly,
    } = this.props;

    return (
      <div className="cartItem-item">
        <div className="cartItem-content">
          <div className="cartItem-content-check">
            {!viewOnly ? (
              <input
                type="checkbox"
                onClick={(event) => event.stopPropagation()}
                onChange={() => handleSelect([cartItem.line_id])}
                checked={this.state.checked}
              />
            ) : null}
            <img
              src={
                cartItem?.images && cartItem?.images[0]?.image !== null
                  ? this.getImageUrl()
                  : ImageNotFound
              }
              alt="item"
            />
          </div>
          <div className="cartItem-content-info">
            <div className="cartItem-content-info-titlebar">
              <div className="cartItem-content-info-titlebar-title">
                {parseInt(cartItem?.line_type)===0?cartItem.item_name:parseInt(cartItem?.line_type)==1?cartItem.category_name:cartItem?.comments}
              </div>
              {!viewOnly ? (
                <div className="cartItem-content-info-titlebar-icons align-items-center">
                  <button onClick={() => {
                      this.props.showOldCartModal(cartItem);
                    }} className="m-0 bg-transparent p-0 border-0">
                  <img
                    src={EditGrey}
                    alt="edit"
                  />
                  </button>
                  <button onClick={() => {
                      this.props.deleteCartItem(cartItem);
                   }} className="m-0 bg-transparent p-0 border-0">
                  <img
                    src={DeleteGrey}
                    alt="delete"
                    style={{height:"20px"}}
                  />
                  </button>
                </div>
              ) :  this.props.editMode && 
              <button  
              onClick={() => {
                this.props.showOldCartModal(cartItem);
              }} className="m-0 bg-transparent p-0 border-0">
              <img
              src={EditGrey}
              alt="edit"
            /></button>}
            </div>
            <div className="cartItem-content-info-specs">
            <div className="cartItem-content-info-specs-spec">
                <span className="cartItem-content-info-specs-spec-label">
                  {Helper.getLabel(
                    this.props.language,
                    "line_number",
                    "Line number"
                  )}
                </span>{" "}
                <span className="cartItem-content-info-specs-spec-value">
                  {cartItem?.line_number
                    ? cartItem?.line_number
                    : "-"}
                </span>
            </div>
            <div className="cartItem-content-info-specs-spec">
                <span className="cartItem-content-info-specs-spec-label">
                  {Helper.getLabel(this.props.language, "quantity", "Quantity")}
                </span>{" "}
                <span className="cartItem-content-info-specs-spec-value">
                  {cartItem?.ordered_qty
                    ? Helper.addThousandSeparator(
                        parseFloat(cartItem?.ordered_qty)
                      )
                    : "-"}
                </span>
              </div>
              <div className="cartItem-content-info-specs-spec">
                <span className="cartItem-content-info-specs-spec-label">
                  {Helper.getLabel(
                    this.props.language,
                    "unit_price",
                    "Unit price"
                  )}
                </span>{" "}
                <span className="cartItem-content-info-specs-spec-value">
                  {cartItem?.unit_price
                    ? Helper.addThousandSeparator(
                        parseFloat(cartItem?.unit_price)
                      )
                    : "-"}
                </span>
              </div>
              <div className="cartItem-content-info-specs-spec">
                <span className="cartItem-content-info-specs-spec-label">
                  {Helper.getLabel(
                    this.props.language,
                    "item_type",
                    "Item type"
                  )}
                </span>{" "}
                <span className="cartItem-content-info-specs-spec-value">
                  {this.checkLineTypeName(cartItem?.line_type)}
                </span>
              </div>
              <div className="cartItem-content-info-specs-spec">
                <span className="cartItem-content-info-specs-spec-label">
                  {Helper.getLabel(
                    this.props.language,
                    "need_by_date",
                    "Need by date"
                  )}
                </span>{" "}
                <span className="cartItem-content-info-specs-spec-value">
                  {cartItem?.delivery_date ? cartItem?.delivery_date : "-"}
                </span>
              </div>
              <div className="cartItem-content-info-specs-spec">
                <span className="cartItem-content-info-specs-spec-label">
                  {Helper.getLabel(
                    this.props.language,
                    "line_amount",
                    "Line amount"
                  )}
                </span>{" "}
                <span className="cartItem-content-info-specs-spec-value">
                  {cartItem?.line_amount
                    ? Helper.addThousandSeparator(
                        parseFloat(cartItem?.line_amount)
                      )
                    : "-"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="search-filters-seemore"
          onClick={() => {
            this.props.updateActiveCartItem(cartItem);
            toggleDistribution(cartItem);
          }}
          onKeyDown={() => {}}
        >
          <img
            src={DropdownIcon}
            alt="dropdown"
            style={
              this.state.activeDistribution === cartItem?.line_id
                ? { transform: "rotate(180deg)" }
                : {}
            }
          />
          {Helper.getLabel(
            this.props.language,
            "view_distribution",
            "View distribution"
          )}
        </div>
        {activeDistribution === cartItem.line_id ? (
          <DistributionTabs
            viewOnly={this.props.viewLineDistribution?this.props.viewLineDistribution:this.props.currentDocumentStatus !== 0}
            gridId="cart-grids-seg-additional"
            source_id={this.props.source_id}
            onCreateFirstDistribution={this.onCreateFirstDistribution}
            currentDocumentStatus={this.props.currentDocumentStatus}
            type={this.props.type}
            material_service_not_included={this.state.material_service_not_included}
            scope_of_work={this.state?.scope_of_work}
            requirement_description={this.state?.requirement_description}
            isDisable={true}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    showOldCartModal: (payload) => {
      dispatch({ type: ActionTypes.SHOW_EDITOLDCART_MODAL, payload });
    },
    updateActiveCartItem: (payload) => {
      dispatch({ type: ActionTypes.UPDATE_ACTIVRCARTITEM, payload });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CartItem);
