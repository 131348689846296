import axios from "axios";
import Api from "../../inc/Api";
import Settings from "../../inc/Settings"; 
import Helper from "../../inc/Helper";
import { checkIfArrayIsEmpty, formatDateWithTime, getFormattedDateWithoutTime, getTimeWithoutDate, isEmptyObject,  } from "../../inc/Validation";
import { objectIsEmpty } from "../../inc/Validation";
import DatepickerFunctions from "../../inc/DatepickerHelper";

/*
module_item_id is the existing id on which the user is navigated to.

*/ 

export default class WorkFlowCommonHeaderAPICalls {
    api;

    constructor() {
      this.api = Api;
      this.api.setUserToken();
    } 

    async checkWorkFlowForSpecificModule(module_id, tenant_id, trigger) {
        try {
            const res = await axios.get(Settings.apiWorkflowUrl + `/workflow/check?tenant_id=${tenant_id}&module_id=${module_id}&trigger=${trigger}`);
            if (res.status === 200) {
                return res?.data?.eligible_for_instance_create;
            } else { 
                return null; 
            }
        } catch (error) { 
            return null;  
        }
    }

    async getWorkFlowInstanceExist(tenant_id, module_id, module_item_id) {
        try {
            const res = await axios.get(
                Settings.apiWorkflowUrl +
                `/instance/check?tenant_id=${tenant_id}&module_id=${module_id}&module_item_id=${module_item_id}`
            );
        
            const data = {
                instance_exist: res.status === 200 ? res.data.instance_exist : null,
                workflow_name: res.data.data.workflow_name,
                instance_details: res.data.data
            };
        
            return data;
        } catch (error) {
            return {
                instance_details: null,
                instance_exist: null,
                workflow_name: null
            };
        }
    }

    async getWorkFlowRequestTypes() {
        const res = await axios.get(Settings.apiWorkflowUrl + `/enum/get?enum_type=assignee_type`);
        if (res.status === 200) return res.data?.data?.filter(val => val.name !== 'Hierarchy');
        return res;
    }

    async getWorkFlowDurationTypes() { 
        const res = await axios.get(Settings.apiWorkflowUrl + `/enum/get?enum_type=duration_type`);
        if (res.status === 200) return res.data.data;
        return res;
    }
    
    async getWorkFlowCompletionPolicy() { 
        const res = await axios.get(Settings.apiWorkflowUrl + `/enum/get?enum_type=completion_policy`);
        if (res.status === 200) return res.data.data;
        return res;
    }

    async getWorkFlowTaskActionsList(tenant_id, user_id, module_id, module_item_id) {
        try {
            const res = await axios.get(Settings.apiWorkflowUrl + `/instance-task/get?task_type=incompleted&tenant_id=${tenant_id}&user_id=${user_id}&module_id=${module_id}&module_item_id=${module_item_id}`);
            const Updated_Data = [];
    
            if (res.status === 200 && res.data.data.length > 0) {
                for (const [key, value] of Object.entries(res.data.data[0].actions)) {
                    Updated_Data.push({ value: key, label: value });
                }
            }
    
            return res.data.data.length > 0
                ?   {
                    actions: [...Updated_Data, { value: 17, label: 'View current assignment' }],
                    action_taken: [],
                    instance_task_id: res.data.data[0]?.instance_task_id,
                    workflow_name: res.data.data[0]?.workflow_name,
                    stage: res.data.data[0]?.stage, 
                    initiated_date: DatepickerFunctions.convertDateFromDataBase(res.data.data[0]?.initiated_date),
                    task_assignee: res.data.data[0]?.task_assignee
                }
                :   {};
        } catch (error) {
            return {}
        }
    }

    async getWorkFlowTaskActionsListCompleted(tenant_id, user_id, module_id, module_item_id) {
        try {
            const res = await axios.get(Settings.apiWorkflowUrl + `/instance-task/get?task_type=completed&tenant_id=${tenant_id}&user_id=${user_id}&module_id=${module_id}&module_item_id=${module_item_id}`);
            const Updated_Data = [];
    
            if (res.status === 200 && res.data.data.length > 0) {
                for (const [key, value] of Object.entries(res.data.data[0].action_taken)) {
                    Updated_Data.push({ value: key, label: value });
                }
            }
    
            return res.data.data.length > 0
                ?   {
                    actions: [],
                    action_taken: Updated_Data,
                    stage: res.data.data[0]?.stage,
                    instance_task_id: res.data.data[0]?.instance_task_id,
                    workflow_name: res.data.data[0]?.workflow_name,
                    initiated_date: DatepickerFunctions.convertDateFromDataBase(res.data.data[0]?.initiated_date),
                    task_assignee: res.data.data[0]?.task_assignee
                }
                :   {};
        } catch (error) { 
            return {}; 
        }
    }

    async getWorkFlowTaskActionsListById(instance_task_id) {
        try {
            const res = await axios.get(Settings.apiWorkflowUrl + `/instance-task/details?instance_task_id=${instance_task_id}`);
            const Updated_Data = [];
            const Actions_Taken = [];
    
            if (res.status === 200) {
                if (!isEmptyObject(res.data.data.action_taken)) {
                    for (const [key, value] of Object.entries(res.data.data.action_taken)) {
                        Actions_Taken.push({ value: key, label: value });
                    }
                    return {
                        actions: Updated_Data,
                        action_taken: Actions_Taken,
                        instance_task_id: res.data.data.instance_task_id,
                        stage: res.data.data.stage,
                        workflow_name: res.data.data.workflow_name,
                        initiated_date: DatepickerFunctions.convertDateFromDataBase(res.data.data.initiated_date),
                        task_assignee: res.data.data.task_assignee
                    };
                }
    
                for (const [key, value] of Object.entries(res.data.data.actions)) {
                    Updated_Data.push({ value: key, label: value });
                }
            }
    
            return {
                actions: [...Updated_Data, { value: 17, label: 'View current assignment' }],
                action_taken: Actions_Taken,
                instance_task_id: res.data.data.instance_task_id,
                workflow_name: res.data.data.workflow_name,
                stage: res.data.data.stage,
                initiated_date: DatepickerFunctions.convertDateFromDataBase(res.data.data.initiated_date),
                task_assignee: res.data.data.task_assignee
            };
        } catch (error) {
            return {}
        }
    }
 
    async getWorkFlowPositions() {
        try {
            const res = await this.api.axios().get(Settings.apiOrgUrl + `/workflow/get_positions`);
            if (res.status === 200 && res.data.status === "success") {
                return res.data.data;
            } else { return [] }
        } catch (error) { return [] }
    }
    
    async getWorkFlowTeams(page_number, page_size) {
        try {
            const res = await this.api.axios().get(Settings.apiUrl + `/workflow/get_teams/${page_number}/${page_size}`);
            if (res.status === 200 && res.data.status === "success") {
                return res.data.data;
            } else { return [] }
        } catch (error) { return [] }
    }
    
    async getWorkFlowUsers(page_number, page_size) {
        try {
            const res = await this.api.axios().post(Settings.apiUrl + `/workflow/get_users/${page_number}/${page_size}`, {"name":null});
            if (res.status === 200 || res.data.status === "Success") {
                return res.data.data;
            } else { return [] }
        } catch (error) { return [] }
    }
 
    async getUserPendingActions () {
        const res = await this.api.axios().post(Settings.apiUrl + `/workflow/pending_actions`, { "task_type":"incompleted" });
        if (res.data.status === "success") return res.data.data;
        return res
    }
    
    // ENUMS Calls-
    async getWorkFlowTriggers () { 
        try {
            const res = await axios.get(Settings.apiWorkflowUrl + `/enum/get?enum_type=workflow_triggers`);
            
            if (res.status === 200) return res.data.data 
            else return [];
        } catch (error) { return [] }
    };
    async getWorkFlowInstanceStatus() { 
        const res = await axios.get(Settings.apiWorkflowUrl + `/enum/get?enum_type=instance_status`);
        if (res.status === 200) return res.data.data;
        return res;
    }
    async getAllSystemSources(){
        const res = await this.api.axios().get(Settings.apiUrl + `/source`);
        if (res.data.status === "success") return res.data.data;
        return res;
    }

    // Action History Calls
    async getWorkFlowActionHistory(source_id, sourceitem_id, page_number = 1, page_size = 10) {
        try {
            const res = await this.api.axios().post(Settings.apiUrl + `/workflow/get_actions_history`, {
                "source_id": source_id,
                "sourceitem_id": sourceitem_id,
                "messages_level": "User",
                "page_number": page_number,
                "page_size": page_size
            });
    
            const Updated_Data = [];
    
            if (res.data.status === "success") {
                if (!objectIsEmpty(res.data.data[0])) {
                    res.data.data[0].logs.forEach((item) => {
                        Updated_Data.push({
                            name: item?.name,
                            completion_time: item.completion_time ? item.completion_time : null,
                            assigned_date: item.created_at ? item.created_at : null,
                            action_taken: item.action_taken ? `${Object.values(item.action_taken)[0]} by` : null,
                            comment: item?.comment,
                            stageName: item.stage ? item.stage : '-',
                            kpi_violate: item?.kpi_violate,
                            logo: item?.image,
                            duration: item?.duration,
                            duration_type: item.duration_type ? item.duration_type[Object.keys(item.duration_type)[0]] : null
                        });
                    });
                }
            }
    
            return {
                history: Updated_Data,
                currentState: !checkIfArrayIsEmpty(res.data.data) ? res.data.data[0]?.status : null,
                intanceCreated: !checkIfArrayIsEmpty(res.data.data) ? res.data.data?.length : null
            };
        } catch (error) { 
            return {
                history: [],
                currentState: null,
                intanceCreated: null
            };
        }
    }
      
    async getWorkflowHistoryAllLogs(source_id, sourceitem_id, page_number=1, page_size=10) {
        const res = await this.api.axios().post(Settings.apiUrl + `/workflow/get_actions_history`, {
          "source_id": source_id,
          "sourceitem_id": sourceitem_id,
          "messages_level": "User",
          "page_number" : page_number,
          "page_size" : page_size
        });

        const FilterData = [];
      
        if (res.data.status === "success") {
            if (!checkIfArrayIsEmpty(res.data.data)) {
                res.data.data && res.data.data.forEach((element) => {
                    FilterData.push({
                        instance_id: element.instance_id,
                        initiated_date: element.initiated_date ? DatepickerFunctions.convertDateTimeFromDataBase(element.initiated_date) : '-',
                        logs: element.logs.map((item) => ({
                            name: item.name,
                            completion_time: item.completion_time ? item.completion_time : null,
                            assigned_date: item.created_at ? item.created_at : null,
                            action_taken: item.action_taken ? `${Object.values(item.action_taken)[0]} by` : null,
                            comment: item.comment,
                            stageName: item.stage ? item.stage : '-',
                            kpi_violate: item.kpi_violate,
                            logo: item.image,
                            duration: item.duration,
                            duration_type: item.duration_type ? item.duration_type[Object.keys(item.duration_type)[0]] : null
                        }))
                    });
                });
            }
        }
        return FilterData;
    }
    
    async getWorkFlowCurrentAssignee(tenant_id, module, module_item_id) { 
        const resAssignee = await axios.get(Settings.apiWorkflowUrl + `/instance/current-assignee?tenant_id=${tenant_id}&module_id=${module}&module_item_id=${module_item_id}`);

        if (resAssignee.status === 200){
            const Updated_data = []; 
      
            for (const element of resAssignee?.data?.data) {
                const res = await this.api.axios().get(Settings.apiUrl + `/get_user/${element.task_assignee}`);
                const userData = res?.data;
                
                if (userData.status === "success") {  
                    Updated_data.push({
                        stage: element?.stage ? element?.stage : null,
                        employee: userData.data.employee_name ? userData.data.employee_name : null,
                        initiated_date: element.initiated_date ? element.initiated_date : null,
                        due_date: element.due_date ? element.due_date : null 
                    });
                } else { 
                    Updated_data.push({
                        employee: null,
                        stage: element?.stage ? element?.stage : null,
                        initiated_date: element.initiated_date ? element.initiated_date : null,
                        due_date: element.due_date ? element.due_date : null 
                    });
                }
            }

            return Updated_data
        }
    }
}
 