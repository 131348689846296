import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import Helper from '../../../../inc/Helper'
import Api from '../../../../inc/Api'
import Settings from '../../../../inc/Settings'
import Input from '../../../Forms/Input'
import Dropdown from '../../../Forms/Dropdown'
import Checkbox from '../../../Forms/Checkbox'
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation'

const PriceDiscountTab = (props) => {
  let paymentTermsCalled = false
  //States For Dropdowns
  const [paymentTerms, setPaymentTerms] = useState([])
  //End

  //Api call for payment terms dropdown
  const PaymentTermsAPI = () => {
    if (paymentTermsCalled) {
      return
    }
    let api = Api
    paymentTermsCalled = true
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiVendorUrl + '/paymentterm', {})
      .then(function (res) {
        if (res.data.status === 'success') {
          let PaymentARR = []
          res.data.data.forEach((item) => {
            PaymentARR.push({
              value: item.paymentterm_id,
              label: `${item.code} - ${item.name}`,
            })
          })
          setPaymentTerms(PaymentARR)
        }
      })
      .catch((res) => {
        console.log(res.response.data.message)
      })
  }
  //API Calls Renderer
  useEffect(() => {
    PaymentTermsAPI()
  }, [])
  // End

  const onInputChangeHandler = useCallback((e) => {
    if (e.target.value !== props.state[e.target.name]) {
        props.setState((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value
            }
        })
    }
}, [props.state])

  const updateRFQHeader = (tempData) => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .put(
        Settings.apiPurchaseRequisitionUrl +
          `/RFVendorreplyheader/${props.rfq_vendor_id}`,
        tempData,
      )
      .then(function (res) {
        if (res.data.status === 'success') {
          Helper.alert(res.data.message, 'success')
          props.setState((prev) => {
            return { ...prev, ...tempData }
          })
        }
      })
      .catch(function (error) {
         getPopupMessageBasedOnStatus(error)
      })
  }
  

  const debouncedUpdateRFQHeader = useCallback(debounce(updateRFQHeader, 1000), [])

  const onInputValueChange = useCallback((e) => {
    if (e.target.value !== props.state[e.target.name]) {
        props.setState((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value
            }
        })
        let payload = {[e.target.name]: e.target.value}
        if(e.target.name==='discount_amount' || e.target.name==='discount_percentage' || e.target.name==='rounding_amount'){
          payload={[e.target.name]:e.target.value===""?0:e.target.value}
        }
        debouncedUpdateRFQHeader(payload);
    }
  }, [props.state, debouncedUpdateRFQHeader])

  const onDropdownChangeHandler = (e) => {
    const newPaymentTermId = e.target.value
    const tempData = {
     paymentterm_id: newPaymentTermId==="" || newPaymentTermId === null  ? "" : parseInt(newPaymentTermId),
    }
    updateRFQHeader(tempData)
  }

const onCheckboxValueChanged = (name,value) => {
  const newPrice = value==="on"
  const tempData = {
    price_include_tax: newPrice,
  }
  updateRFQHeader(tempData)
}


const onInputCheckboxChangeHandler = (name,value) => {  
    if(value === 'on'){
     props.setState((prevState) => {
        return {
          ...prevState,
          [props.name]: true
        }
      })
    }else {
      props.setState((prevState) => {
        return {
          ...prevState,
          [props.name]: false
        }
      })
    }
}

// Debounce function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

  let textBoxInputSettings = [
    {
      label: Helper.getLabel(props.language, 'payment_term', 'Payment term'),
      options: paymentTerms,
      inputType: 'dropdown',
      reRenderRequired:true,
      id: 'paymentterm_id',
      name: props.paymentTermName,
      placeHolder: 'Select payment term',
      disable: !props.isEditable,
    },
    {
      label: Helper.getLabel(
        props.language,
        'discount_percentage',
        'Discount percentage',
      ),
      inputType: 'number',
      max: 100,
      name: 'discount_percentage',
      disable: !props.isEditable,
    },
    {
      label: Helper.getLabel(
        props.language,
        'discount_amount',
        'Discount amount',
      ),
      inputType: 'number',
      name: 'discount_amount',
      disable: !props.isEditable,
    },
    {
      label: Helper.getLabel(
        props.language,
        'rounding_amount',
        'Rounding amount',
      ),
      inputType: 'number',
      name: 'rounding_amount',
      disable: !props.isEditable,
    },
    {
      label: Helper.getLabel(
        props.language,
        'price_inclusive_of_tax',
        'Price inclusive of tax'
      ),
      inputType: 'checkbox',
      name: props.name,
      disable: !props.isEditable,
    },
  ]

  return (
      <div className='row'>
        {textBoxInputSettings.map((element) => {
          return (
            <div key={element.name} className='col-sm-6'>
              {element.inputType == 'text' && (
                <Input
                  type='text'
                  id={element.id ? element.id : null}
                  required={element.required ? element.required : false}
                  disable={element.disable ? element.disable : false}
                  name={element.name}
                  label={element.label}
                  onChange={props.type==='rfq'?onInputValueChange:onInputChangeHandler}
                  value={props?.state?.[element.name]}
                />
              )}
              {element.inputType == 'number' && (
                <Input
                  inputType='number'
                  id={element.id ? element.id : null}
                  required={element.required ? element.required : false}
                  disable={element.disable ? element.disable : false}
                  name={element.name}
                  label={element.label}
                  onChange={props.type==='rfq'?onInputValueChange:onInputChangeHandler}
                  value={props?.state?.[element.name]}
                />
              )}
              {element.inputType == 'dropdown' && (
                <Dropdown
                  ajax={element.ajax ? element.ajax : null}
                  id={element.id ? element.id : null}
                  required={element.required ? element.required : false}
                  isDisable={element.disable ? element.disable : false}
                  label={element.label}
                  options={element.options ? element.options : []}
                  value={props?.state?.[element.name]}
                  name={element.name}
                  reRenderRequired={element.reRenderRequired}
                  placeHolder={element.placeHolder}
                  onChange={props.type==="rfq"?onDropdownChangeHandler:onInputChangeHandler}
                />
              )}
              {element.inputType == 'checkbox' && (
                <Checkbox
                  id={element.id ? element.id : null}
                  type={element.inputType}
                  name={element.name}
                  onChange={props.type==="rfq"?onCheckboxValueChanged:onInputCheckboxChangeHandler}
                  disableWithoutIcon={element.disable ? element.disable : false}
                  label={element.label}
                  isChecked={props.state?.[element?.name]}
                />
              )}
            </div>
          )
        })}
      </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(PriceDiscountTab)
