import Api from "../../../../inc/Api";
import Settings from "../../../../inc/Settings";

export default class CommonApiCalls {
  api;
  constructor() {
    this.api = Api;
    this.api.setUserToken();
  }
 
  async getAllCurrencies() {
    const res = await this.api.axios().get(Settings.apiUrl + "/currency");
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getAllEntities() {
    const res = await this.api
      .axios()
      .post(Settings.loginUrl + "/view_entity", { user_id: null });
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getAllSites() {
    const res = await this.api
      .axios()
      .get(Settings.loginUrl + "/get_site");
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getPaymentTerms() {
    const res = await this.api
      .axios()
      .get(Settings.apiVendorUrl + "/paymentterm");
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getAllVendors() {
    const res = await this.api
      .axios()
      .get(Settings.apiVendorUrl + "/vendor");
    if (res.data.status === "success") return res.data.data;
    return res;
  }

   async getAllPurchaseOrderStatus() {
    const res = await this.api
      .axios()
      .get(Settings.loginUrl + "/enum/POHeaderPurchaseStatus");
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getAddress(addresssource_id) {
    const res = await this.api
      .axios()
      .get(Settings.loginUrl + `/get_address/${addresssource_id}`);
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getAddressPurpose() {
    const res = await this.api
      .axios()
      .get(Settings.loginUrl + `/enum/address_purpose`);
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getAllDocumentStatus() {
    const res = await this.api
      .axios()
      .get(Settings.loginUrl + `/enum/POHeaderDocumentStatus`);
    if (res.data.status === "success") return res.data.data;
    return res;
  }
}
