import React from "react";
import $ from "jquery";
import Popup from "../../../Popup/Popup";
import Input from "../../../Forms/Input";
import Button from "../../../inc/Button";
import Helper from "../../../../inc/Helper";

const ReasonPopUp = (props) => {
  const handleCommentChange = (event) => {
    props.setComment(event.target.value);
  };

  return (
    <Popup
      autoOpen={true}
      onClose={() => {
        $("html").removeClass("rs_popup_open");
        props.setShowPopup(false);
      }}
      width="750px"
    >
      <div>
        <h5 className="mb-4">Please comments below for not interested</h5>
        <div className="rfq-bidding-information rounded">
          <div className="rfq-information-title ">
            <h2 className="grid_label_rfq">
              {Helper.getLabel(props.language, "comment", "Comment")}
            </h2>
          </div>
          <div className="col-sm-12 p-3 pt-4 rfq-input">
            <Input
              inputType="textarea"
              placeholder="Please type here..."
              value={props.comment}
              onChange={handleCommentChange}
            />
            <div className="d-flex gap-3 justify-content-end mt-1">
              <Button
                title={Helper.getLabel(props.language, "close", "Close")}
                onClick={() => {
                  $("html").removeClass("rs_popup_open");
                  props.setShowPopup(false);
                }}
              ></Button>
              <Button
                isActive={true}
                title={Helper.getLabel(props.language, "save", "Save")}
                className="rfq_save_btn"
                onClick={props.handleCommentSave}
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default ReasonPopUp;
