import React, { Fragment } from 'react';
import NavigationHeder from '../Navigations/NavigationHeder';
import Helper from '../../inc/Helper';
import { connect } from 'react-redux';
import MasterComonent from '../Backend/MasterComonent';
import ContractFirstRow from './ContractFirstRow/ContractFirstRow';
import ContractSecondRow from './ContractSecondRow/ContractSecondRow';
import ContractLastRow from './ContractLastRow/ContractLastRow';
import ApplySecurityRoles from '../SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../inc/Gui_id_list';
import Alert from '../inc/Alert';

const ContractReports = (props) => {
  let security = props?.security;

  if (!security.canView(Gui_id_list.reports.contracts.contracts_main)) {
    return (
      <Fragment>
        <MasterComonent>
          <Alert 
            message="You do not have any access to the following screen, please contact your administrator!" 
            type="danger" 
          />
        </MasterComonent>
      </Fragment>
    );
  }

  return (
    <div className='contract-dashboard-screen'> 
    <MasterComonent>
      <NavigationHeder title={Helper.getLabel(props.language,"contract-dashboard","Contract Dashboard")}  hideMoreBtn={true} />
      <div className='employee-dashboard-screen'>
        <ContractFirstRow language={props.language} />
        <ContractSecondRow />
        <ContractLastRow />
      </div>
    </MasterComonent>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}
 
const SecurityOptions = {
  gui_id:Gui_id_list.reports.contracts.contracts_main
};

export default connect(mapStateToProps) (((ApplySecurityRoles(ContractReports, SecurityOptions))));