import React, { Fragment, useEffect, useState } from 'react';
import $ from 'jquery';
import Popup from '../../../Popup/Popup';
import SimpleLoading from '../../../Loading/SimpleLoading';
import Collapse from '../../../inc/Collapse';
import Helper from '../../../../inc/Helper';
import { connect } from 'react-redux';
import FieldsRenderer from '../../../Purchase Orders Internal/Edit PO/FieldsRenderer';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings';
import { convertObjectValuesToInteger, getPopupMessageBasedOnStatus, isEmptyObject } from '../../../../inc/Validation';
import OverlayLoader from '../../../PurchaseRequisition/common/OverlayLoader';
import Button from '../../../inc/Button';

let defaultRecallBid = null

const RecallBidding = ({closePopup, id, language, gridApi}) => {
    const [RFQRecall, setRFQRecall] = useState({})
    
    // dropdown states, 
    const [RFQOperationFor, setRFQOperationFor] = useState([])

    // loading state component wise,
    const [loading, setLoading] = useState(true)
 
    let RFQOperationAllowForCalled = false
    let RFQByIdCalled = false
   
    useEffect(() => {
        getRecallDataByID()
        getRecallAllowedFor() 
    }, [])

    const getRecallDataByID = () => {
        if(!id) return;
        if(RFQByIdCalled) return; 

        RFQByIdCalled = true
        let api = Api 
        api.setUserToken()
        api.axios().get(Settings.apiUrl+`/RFQRecallPolicy/${id}`,{}).then(function(res){
            if(res.data.status==="success"){   
                defaultRecallBid = res.data.data
                setRFQRecall(res.data.data)
            }
        }).catch((res) => {  
            console.log(res.response.data.message, 'error') 
        })
    }

    const getRecallAllowedFor = () => {
        if(RFQOperationAllowForCalled) return;

        setLoading(true)
        let api = Api
        RFQOperationAllowForCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/enum/AllowedRecallFor',{}).then(function(res){
            if(res.data.status==="success"){   
                let updated_dropdown_data = []
                res.data.data.forEach((item) => {
                    updated_dropdown_data.push({
                        value: item.id,
                        label: item.name
                    })
                })
                setRFQOperationFor(updated_dropdown_data)
                setLoading(false)
            }
        }).catch((res) => {  
            console.log(res.response.data.message, 'error')
            setLoading(false)
        })
    }
  
    // popup close handlers,
    const onPopupCloseHandler = () => {
        closePopup((prevState) => {
            return {
                ...prevState,
                showPopup: false
            }
        })
        $('html').removeClass('rs_popup_open');
    }

    // modules,
    const displayHeadLine = () => { return (<h1 className='rfq_head_line_txt_recall'>RFQ re-call policy</h1>) }

    const RecallRFQBiddingModule = () => {
        const textBoxInputSettings = [  
            {label:Helper.getLabel(language,'allowedrecall_for',"Re-call operation for"), className: 'col-lg-12', inputType: "dropdown", options: RFQOperationFor, name:'allowedrecall_for', id:'allowedrecall_for'}
        ]

        return (
            <Collapse open={true} title={Helper.getLabel(language,'enter_info_txt',"Enter the following information")}>
                <FieldsRenderer updateState={setRFQRecall} currentState={RFQRecall} fieldsArray={textBoxInputSettings} />
            </Collapse>
        )
    }

    const actionBtns = () => {
        return (
            <div className="addDocumentNumber-body-buttons" style={{ justifyContent: "flex-end", marginTop: '1rem' }}>
                <Button
                    isDisable={loading}
                    isActive={false} 
                    title={Helper.getLabel(language, "close", "Close")}
                    onClick={onPopupCloseHandler}
                />
                <Button
                    isDisable={loading}
                    className="solid_btn" isActive={true}
                    title={Helper.getLabel(language, "create", "Create")} onClick={SaveHandler}
                />
            </div> 
        )
    }
    // ---

    // API Execution,

    const executeAPI = async (url, data, id) => {
        try {
            const api = Api;
            api.setUserToken();
            const res = await (id ? api.axios().put(url, data) : api.axios().post(url, data));
            if (res.data.status === "success") {
                Helper.alert(res.data.message);
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error);
        }
    };
    
    const SaveHandler = async () => {
        if (isEmptyObject(RFQRecall)) return;
    
        setLoading(true);
        const url = id ? `${Settings.apiUrl}/RFQRecallPolicy/${id}` : `${Settings.apiUrl}/RFQRecallPolicy`;
        await executeAPI(url, convertObjectValuesToInteger(RFQRecall), id);
    
        setTimeout(() => {
            gridApi?.refreshServerSide({ purge: true });
        }, 200);
        gridApi?.deselectAll();
        setLoading(false);
        onPopupCloseHandler();
    };
     
    return (
        <Fragment> 
            { loading ? <OverlayLoader /> : null }
            <Popup onClose={ onPopupCloseHandler } autoOpen={true} width="752px" className='mb-5'>
                { <div className='container-fluid'> { displayHeadLine() } { RecallRFQBiddingModule() } { actionBtns() } </div> }
            </Popup>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return { 
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
}

export default connect(mapStateToProps) (RecallBidding);