import React, { Fragment, useEffect, useRef, useState } from "react";
import "./DashboardNav.css";
import PendingActions from "../Dashboard Navigation Components/PendingActions";
import MyRequest from "../Dashboard Navigation Components/MyRequest"; 
import { connect } from "react-redux";
import Helper from "../../../inc/Helper";
import DashboardReq from "../Dashboard Request/DashboardReq";
import FixedNavigationHeder from "../../Navigations/FixedNavigationHeder";
import TabContentWraper from "../../Widgets/Tabs/TabContentWraper";
import DashboardInfo from "../Dashboard Information/DashboardInfo"; 

const DashboardNav = (props) => {
  let sectionName = "dash-board";
  
  const [selectedSupNav, setSelectedSupNav] = useState("Header View");
  const [scrollDitection, setScrollDitection] = useState("down");

  const [height, setHeight] = useState(0);

  const myDivRef = useRef(null);

  useEffect(() => {
    Helper.resizeWindowHandle(myDivRef,setHeight,window)
  },[myDivRef?.current?.clientHeight,scrollDitection]);

  let navs = [
    {
      title: Helper.getLabel(
        props.language,
        "pending_actions",
        "Pending Actions"
      ),
      data_section: "one",
      active_status: "active_tab",
    },
    {
      title: Helper.getLabel(props.language, "my_request", "My Request"),
      data_section: "two",
      active_status: "",
    },
  ];

  return (
    <div className="dashboard-nav-main scrollBarBlinkControl ps-5 pe-5">
      <div ref={myDivRef}>
        <DashboardInfo />
        <DashboardReq scrollDitection={scrollDitection} />
        <FixedNavigationHeder
          sectionName={sectionName}
          navs={navs}
          selectedSupNav={selectedSupNav}
          scrollDitection={scrollDitection}
        />
      </div>

      <TabContentWraper
        height={height} 
        setScrollDitection={setScrollDitection}
        leftSideContent={
          <>
            <div
              className="lists_contant one"
              id={`${sectionName}Pending Actions`}
              style={{ position: "relative" }}
            >
              <PendingActions />
            </div>
            <div 
              className="lists_contant two" 
              id={`${sectionName}My Request`}
              style={{ position: "relative" }}
            >
              <MyRequest />
            </div>
          </>
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  };
};

export default connect(mapStateToProps)(DashboardNav); 