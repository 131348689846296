import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import MasterComponentWraper from '../../../Backend/MasterComponentWraper'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import LinkBtn from '../../../inc/LinkBtn'
import Button from '../../../inc/Button'
import $ from 'jquery'
import Helper from '../../../../inc/Helper'
import { useLocation, useParams } from 'react-router-dom'
import Collapse from '../../../inc/Collapse'
import Input from '../../../Forms/Input'
import DropDownInput from '../../../Forms/Dropdown'
import SimpleLoading from '../../../Loading/SimpleLoading'
import JoditEditor from 'jodit-react' 
import CustomHtmlCell from '../../../grid/ag/cellRender/CustomHtmlCell'
import TermsHTMLDisplayPopup from './TermsHTMLDisplayPopup'
import { checkIfArrayIsEmpty, getPopupMessageBasedOnStatus } from '../../../../inc/Validation'
import Api from '../../../../inc/Api'
import Settings from '../../../../inc/Settings'
import FormValidator from '../../../../inc/FormValidator'
import AgGridNew from '../../../grid/ag/ag-grid-new'
import { getDisplayNamesFromArray, getNewValue, getOptions, optionsKeyCreator } from '../../../grid/ag/commonFunctions/GridCommonFunctions'

let headingsChildGridObj = null;
let contentSubChildGridObj = null; 

// add a transactional prop when user coming to add headings
const CommonDetailViewAddHeadings = ({language}) => {
    const wrapperDiv = useRef(null);
    const {id} = useParams();
    const location = useLocation();

    const getSearchParam = (name) => {
        const params = new URLSearchParams(location.search);
        return params.get(name);
    };
    const transaction_mode = getSearchParam('transactional');
 
    const [termsCondtionsTemplates, setTermsCondtionsTemplates] = useState([]);
    const [parentHeadings, setParentHeadings] = useState([]);
    const [enumDocumentType, setEnumDocumentType] = useState([]);

    const [termsDetails, setTermsDetails] = useState({
        openPopup: false,
        index: null,
        index_otherlanguage: null,
        heading: null,
        heading_otherlanguage: null,
        parent: null,
        templateheading_id: null,
        headingcontent_id: null,
        content: '',
        content_otherlanguage: ''
    });

    const [loading, setLoading] = useState(true); 
    const [height, setHeight] = useState(0);

    let getAllTemplatesListingsCalled = false;
    let getAllEnumDocumentTypesCalled = false

    useEffect(() => { Helper.resizeWindowHandle(wrapperDiv,setHeight,window) }, [wrapperDiv?.current?.clientHeight]);

    useEffect(() => { 
        getTermsConditionsTemplatesById()
        getEnumDocumentTypes() 
    }, []);

    let templateHeadingsValidator;

    const validationConfigure = () => {
        let fieldConfig = [
            { name: 'index', displayName: Helper.getLabel(language, 'index', 'Index'), types: ['Required'] },
            { name: 'heading', displayName: Helper.getLabel(language, 'heading', 'Heading'), types: ['Required'] }
        ];
        if (!templateHeadingsValidator) {
            templateHeadingsValidator = new FormValidator(fieldConfig, language)
        };
    };

    validationConfigure();

    const getTermsConditionsTemplatesById = () => {
        if (getAllTemplatesListingsCalled && !id) return;
        getAllTemplatesListingsCalled = true;
    
        let terms_conditions_api_url = `/tnc_template/${id}`;
        if (transaction_mode) terms_conditions_api_url += `?for_documents=true`;
    
        setLoading(true);
    
        let api = Api;
        api.setUserToken();
        api.axios()
            .get(Settings.apiUrl + terms_conditions_api_url, {})
            .then( async function (res) {
                if (res.data.status === "success") {
                    const getTemplateHeadingData = await getTermsConditionsHeadings(id);
                    
                    setTermsCondtionsTemplates([{
                        ...res.data.data,
                        headings: getTemplateHeadingData
                    }]);
                    setLoading(false); 
                }
            })
            .catch((res) => {
                setTermsCondtionsTemplates([]);
                setLoading(false);
                getPopupMessageBasedOnStatus(res);
            });
    };

    const getEnumDocumentTypes = () => {
        if(getAllEnumDocumentTypesCalled) return;
        getAllEnumDocumentTypesCalled = true;

        let api = Api
        api.setUserToken()
        api.axios().get(Settings.apiUrl+`/enum/DocumentTypeTnC`,{}).then(function(res){
            if(res.data.status==="success"){  
                let Updated_data = [];
                res.data.data.forEach((item) => {
                    Updated_data.push({
                        label: item.name,
                        value: item.id
                    })
                });
                setEnumDocumentType(Updated_data);  
            }
        }).catch((res) => {
            getPopupMessageBasedOnStatus(res);
        });
    };

    const getTermsConditionsHeadings = async (template_id) => {
        if (!template_id) return [];

        let apiURL = `/tnc_heading?template_id=${template_id}`;
        if (transaction_mode) apiURL += `&for_documents=true`;

        let api = Api;
        api.setUserToken();

        try {
            const res = await api.axios().get(Settings.apiUrl + apiURL, {});
            if (res.data.status === "success") {
                let Updated_dropdown_data = [];

                let promises = res.data.data.map(async (item) => {
                    try {
                        const headingsContent = await getTermsConditionsHeadingsContent(item?.templateheading_id);
                        return {
                            ...item,
                            content: headingsContent, 
                            index: parseInt(item?.index),
                        };
                    } catch (error) {
                        console.error("Error fetching heading content:", error);
                        return null; 
                    }
                }); 
                res.data.data.forEach((item) => {
                    Updated_dropdown_data.push({
                        value: item.templateheading_id,
                        label: item.heading
                    })
                })
 
                setParentHeadings(Updated_dropdown_data);
                let Updated_response = await Promise.all(promises);  
                return Updated_response.filter((item) => item !== null);
            }
        } catch (error) {
            console.error("Error fetching headings:", error);
            return [];
        }
    };

    const getTermsConditionsHeadingsContent = async (templateheading_id) => {
        if (!templateheading_id) return []; 
    
        let apiURL = `/tnc_content?templateheading_id=${templateheading_id}`;
        if (transaction_mode) apiURL += `&for_documents=true`;
    
        let api = Api;
        api.setUserToken();
    
        try {
            const res = await api.axios().get(Settings.apiUrl + apiURL, {});
            if (res.data.status === "success") { 
                return res.data.data;
            }
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    // modules & input value functions:
    const onInputValueChanged = (event) => { 
        let name = event.target.name;
        let value = event.target.value;

        if(name === 'index') {
            if(isNaN(value)) {
                Helper.alert('Please numeric value for index', 'error')
                return;
            }
            setTermsDetails((prevState) => {
                return {
                    ...prevState,
                    [name]: value
                }
            }); 
            return;
        }
        setTermsDetails((prevState) => {
            return {
                ...prevState,
                [name]: value
            }
        })
    };

    const onBlurContentChange = useCallback(
        (newContent) => { 
            setTermsDetails((prevState) => {
                return {
                    ...prevState,
                    content: newContent
                }
            })
        }, []
    );

    const onBlurOtherLanguageContentChange = useCallback(
        (newContent) => { 
            setTermsDetails((prevState) => {
                return {
                    ...prevState,
                    content_otherlanguage: newContent
                }
            })
        }, []
    );
    
    const HeadingFields = (otherLanguage=false) => {
        return (
            <Collapse className={otherLanguage && 'collase_with_rtl'} open={true} title={!otherLanguage ? `Template Information` : 'Template Information Other Language'}>
                {!otherLanguage && 
                    <div className='row'>
                        <div className='col-lg-4'>
                            <Input required={true} label={Helper.getLabel(language,'index',"Index")} type='text' name='index' onChange={onInputValueChanged} value={termsDetails.index} />
                        </div>
                        <div className='col-lg-4'>
                            <Input required={true} label={Helper.getLabel(language,'heading',"Heading")} name='heading' type='text' onChange={onInputValueChanged} value={termsDetails.heading} />
                        </div> 
                        <div className='col-lg-4'>
                            <DropDownInput reRenderRequired={true} id='parentheading_id_dropdown' name='parent' label={Helper.getLabel(language,'parent',"Parent")} options={parentHeadings} onChange={onInputValueChanged} value={termsDetails.parent} /> 
                        </div> 
                    </div>
                }
                {otherLanguage &&
                    <div className='row' style={{ direction: 'rtl' }}>
                        <div className='col-lg-6'>
                            <Input label={Helper.getLabel(language,'index_otherlanguage',"Index")} type='text' name='index_otherlanguage' onChange={onInputValueChanged} value={termsDetails.index_otherlanguage} />
                        </div>
                        <div className='col-lg-6'>
                            <Input label={Helper.getLabel(language,'heading_otherlanguage',"Heading")} name='heading_otherlanguage' type='text' onChange={onInputValueChanged} value={termsDetails.heading_otherlanguage} />
                        </div>
                    </div>
                }
            </Collapse>
        )
    }

    const TemplateReadOnlyGridView = () => {
        const gridLinkValueFormat = () => {
            return `<img className='img-fluid' src='/images/eye-content.svg' />`;
        }

        const TermsConditionParentGridHeaderCols = [
            {field:'title', filter: false, cellRenderer: 'agGroupCellRenderer' , headerName:Helper.getLabel(language,'title',"Title"), editable:false},
            {field:'description', filter: false, editable: false, headerName: Helper.getLabel(language,'description',"Description")},
            ...(transaction_mode 
                    ? 
                [
                    { 
                        field: 'document_type', 
                        headerName: Helper.getLabel(language,'document_type',"Document type"),
                        editable: false,  
                        cellRenderer: (params) => getDisplayNamesFromArray(params, enumDocumentType, 'value', 'label'),
                        filterParams: { 
                            values: (params) => getOptions(params, enumDocumentType), 
                            keyCreator: (params) => optionsKeyCreator(params, 'value'), 
                            valueFormatter: params => params.value.label 
                        }, 
                        filter: false
                    }
                ]
                    :
                [
                    { 
                        field: 'document_type', 
                        headerName: Helper.getLabel(language,'document_type',"Document type"),
                        editable: false,
                        filter: false
                    }
                ]
            )
        ];
    
        const TermsConditionsChildGridHeaderCols = [
            { field: 'index', filter: 'agNumberColumnFilter', cellRenderer: 'agGroupCellRenderer', editable: false, headerName: Helper.getLabel(language,'index',"Index"), checkboxSelection: true },
            { field: 'heading', filter: 'agTextColumnFilter', editable: false, headerName: Helper.getLabel(language,'heading',"Heading") },
            { 
                field: 'parentheading_id', 
                headerName: Helper.getLabel(language,'parent',"Parent"),
                editable: false,  
                cellRenderer: (params) => getDisplayNamesFromArray(params, parentHeadings, 'value', 'label'),
                filterParams: { 
                    values: (params) => getOptions(params, parentHeadings), 
                    keyCreator: (params) => optionsKeyCreator(params, 'value'), 
                    valueFormatter: params => params.value.label 
                }, 
                filter: 'agSetColumnFilter', 
                minWidth: 180
            }
        ];
        
        const TermsConditionsSubChildGridHeaderCols = [
            { field: 'content', filter: false, editable: false, headerName: Helper.getLabel(language,'content',"Content"), cellRenderer: CustomHtmlCell, checkboxSelection: true, valueFormatter: gridLinkValueFormat }
        ];
      
        const onContentCellClicked = (event) => {
            if(event.colDef.field === 'content') { 
                setTermsDetails((prevState) => {
                    return {
                        ...prevState,
                        openPopup: true, 
                    }
                })
            }
        }

        const onHeadingsCheckboxSelectionChange = (event) => { 
            let currentNode = event.api.getSelectedNodes();
            if(checkIfArrayIsEmpty(currentNode)) {
                setTermsDetails((prevState) => {
                    return {
                        ...prevState, 
                        parent: null,
                        heading: null,
                        index: null,
                        index_otherlanguage: null,
                        heading_otherlanguage: null,
                        templateheading_id: null
                    }
                })
                return;
            };
            
            setTermsDetails((prevState) => {
                return {
                    ...prevState,
                    index: currentNode[0]?.data?.index,
                    index_otherlanguage: currentNode[0]?.data?.index_otherlanguage,
                    heading: currentNode[0]?.data?.heading,
                    heading_otherlanguage: currentNode[0]?.data?.heading_otherlanguage,
                    parent: currentNode[0].data.parentheading_id ? parseInt(currentNode[0].data.parentheading_id) : null,
                    templateheading_id: currentNode[0]?.data?.templateheading_id
                }
            }) 
        }

        const onContentCheckboxSelectionChange = (event) => {
            let currentNode = event.api.getSelectedNodes();
            if(checkIfArrayIsEmpty(currentNode)) {
                setTermsDetails((prevState) => {
                    return {
                        ...prevState,
                        content_otherlanguage: '',
                        content: '', 
                        headingcontent_id: null
                    }
                })
                return;
            };
            
            setTermsDetails((prevState) => {
                return {
                    ...prevState,  
                    content_otherlanguage: currentNode[0]?.data?.content_otherlanguage,
                    content: currentNode[0]?.data?.content, 
                    headingcontent_id:  currentNode[0]?.data?.headingcontent_id
                }
            }) 
        }

        let dataSource = {
            getRows: async function (params) {
                params?.success({
                    rowData: termsCondtionsTemplates?.map((item) => ({
                        ...item,
                        gridId: `${Date.now()}_${getNewValue()}`
                    })),
                    rowCount: termsCondtionsTemplates?.length
                })
            },
        };

        const onGridHeadingsReady = (params) => {
            if(params) {  
                setTimeout(()=>{ 
                    params?.api?.setGridOption('serverSideDatasource', dataSource);
                }, 200) 
            }
        }

        let masterSettings = {
            detailRowAutoHeight: true,
            masterDetail: true,
            detailCellRendererParams: {
              detailGridOptions: {
                detailRowAutoHeight: true,
                masterDetail: true,
                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: TermsConditionsSubChildGridHeaderCols,  
                        defaultColDef: { 
                            flex: 1,
                            sortable: true 
                        },  
                        rowSelection: 'single',
                        onGridReady: (params) => {contentSubChildGridObj = params},
                        onCellClicked: onContentCellClicked,
                        onSelectionChanged: onContentCheckboxSelectionChange,
                    },
                    getDetailRowData: function (params) {
                        params.successCallback(params.data.content);
                    },
                    template: '<div style="padding: 10px 30px; box-sizing: border-box;">' + 
                    '  <div ref="eDetailGrid"></div>' +
                    '</div>',
                },
                    columnDefs: TermsConditionsChildGridHeaderCols,  
                    defaultColDef: { 
                        flex: 1,
                        sortable: true
                    },  
                    rowSelection: 'single',
                    onSelectionChanged: onHeadingsCheckboxSelectionChange,
                    onGridReady: (params) => {headingsChildGridObj = params}
                },
                getDetailRowData: function (params) {
                    params.successCallback(params.data.headings);
                },
                template: '<div style="padding: 10px 30px; box-sizing: border-box;">' + 
                '  <div ref="eDetailGrid"></div>' +
                '</div>'
            }
        };

        return (
            <Collapse open={true} title={`Template`}> 
                <AgGridNew
                    isDisabledCheckbox={true}
                    onGridReady={onGridHeadingsReady}
                    columnDefs={TermsConditionParentGridHeaderCols} 
                    masterSettings={masterSettings}
                    hideAddBtn={true}
                    hideDeleteBtn={true}
                    height={500} 
                    hidePaginationFooter={true}
                    pagination={false}  
                    gridId={'rfq_master_level_terms_conditions_grid'}
                />
            </Collapse>
        )
    }

    const ContentTextEditor = (otherLanguage=false) => { 
        return (
            <Collapse open={true} className={otherLanguage ? 'collapse_for_texteditor collase_with_rtl' : 'collapse_for_texteditor'} title={!otherLanguage ? `Content` : 'Content Other Language'}> 
                {!otherLanguage && 
                    <JoditEditor
                        value={termsDetails?.content} 
                        tabIndex={1} 
                        onBlur={onBlurContentChange}
                    />
                }
                {otherLanguage &&
                    <div className='rtl_jodit_editor'> 
                        <JoditEditor  
                            value={termsDetails?.content_otherlanguage} 
                            tabIndex={2} 
                            onBlur={onBlurOtherLanguageContentChange} 
                        /> 
                    </div>
                }
            </Collapse>
        )
    }

    const CompleteModuleWrapper = () => {
        return (
            <div className='detail_view_headings_wrapper common_scrollbar_styles' style={{ height: `${height-30}px`, overflowX: 'hidden', overflowY: 'auto'}}>
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col-12 col-lg-4'>
                            <div className='left-agGrid'>
                                { loading ? <div style={{height: '450px'}}> <SimpleLoading /> </div> : TemplateReadOnlyGridView() }
                            </div>
                        </div>
                        <div className='col-6 col-lg-4 mt-lg-0 mt-4'>
                            <div style={{ width: '100%' }}>
                                {HeadingFields()}
                                {ContentTextEditor()}
                            </div>
                        </div>
                        <div className='col-6 col-lg-4 mt-lg-0 mt-4'>
                            <div style={{ width: '100%' }}>
                                {HeadingFields(true)}
                                {ContentTextEditor(true)}
                            </div> 
                        </div>   
                    </div> 
                </div>
            </div>
        )
    };
    //---

    const DeleteHeadingsOfTemplate = () => {
        if(!headingsChildGridObj) return;

        let selectedNodesHeadingsGrid = headingsChildGridObj?.api?.getSelectedNodes()[0];
        if(!selectedNodesHeadingsGrid?.data?.templateheading_id) return; 

        let heading_url = `/tnc_heading/${selectedNodesHeadingsGrid?.data?.templateheading_id}` 
        if (transaction_mode) heading_url += `?for_documents=true`;

        let api = Api;
        api.setUserToken();

        api.axios().delete(Settings.apiUrl+heading_url,{}).then(function(res){
            if(res.data.status==="success"){ 
                Helper.alert(res.data.message);

                setTermsDetails((prevState) => {
                    return {
                        ...prevState,
                        content: '',
                        content_otherlanguage: '',
                        heading_otherlanguage: null,
                        index_otherlanguage: null,
                        openPopup: false,
                        index: null,
                        heading: null,
                        parent: null,
                        templateheading_id: null,
                        headingcontent_id: null,
                    }
                });

                headingsChildGridObj=null;
                getTermsConditionsTemplatesById();  
            }
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res);
        });
    };
    
    const DeleteHeadingsContentOfTemplate = () => {
        if(!contentSubChildGridObj) return;

        let selectedNodesContentGrid = contentSubChildGridObj?.api?.getSelectedNodes()[0];
        if(!selectedNodesContentGrid?.data?.headingcontent_id) return;
        
        let heading_content_url = `/tnc_content/${selectedNodesContentGrid?.data?.headingcontent_id}`
        if (transaction_mode) heading_content_url += `?for_documents=true`;

        let api = Api;
        api.setUserToken();

        api.axios().delete(Settings.apiUrl+heading_content_url,{}).then(function(res){
            if(res.data.status==="success"){ 
                Helper.alert(res.data.message);

                setTermsDetails((prevState) => {
                    return {
                        ...prevState,
                        content: '',
                        content_otherlanguage: '',
                        index_otherlanguage: null,
                        heading_otherlanguage: null,
                        openPopup: false,
                        index: null,
                        heading: null,
                        parent: null,
                        templateheading_id: null,
                        headingcontent_id: null,
                    }
                });

                contentSubChildGridObj=null;
                getTermsConditionsTemplatesById();  
            }
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res);
        });
    };

    const DeleteSelectedHeading = () => {
        let selectedNodesHeadingsGrid = headingsChildGridObj?.api?.getSelectedNodes()?.[0] || null;
        let selectedNodesContentGrid = contentSubChildGridObj?.api?.getSelectedNodes()?.[0] || null;

        if(selectedNodesHeadingsGrid && !selectedNodesContentGrid){
            if(selectedNodesHeadingsGrid?.data?.content?.length === 0){
                DeleteHeadingsOfTemplate();
                return;
            };
            let bodyMessage = `Deleting the heading will also delete its content. Continue?`;
            $('<div>'+bodyMessage+'</div>').dialog({
                modal: true,
                zIndex: 30005,
                title:'Do you want to delete?',
                buttons: {
                    "Yes": function() {
                        $( this ).dialog( "close" );
                        DeleteHeadingsOfTemplate();
                    },
                    Cancel: function() {
                        $( this ).dialog( "close" );
                    }
                }
            });
        };
        if (selectedNodesContentGrid) DeleteHeadingsContentOfTemplate();
    };
 
    const addNewHeadingToTemplate = () => {
        if(termsDetails.headingcontent_id) return;
        if (!termsDetails.heading && !termsDetails.index && !termsDetails.parent) {
            Helper.alert('Please provide the index & heading to add a new heading to the template', 'error');
            return;
        };
        
        let validData = { ...termsDetails }

        if (!templateHeadingsValidator.isValid(validData)) {
            templateHeadingsValidator.displayMessage();
            templateHeadingsValidator.reset();
            return;
        }
  
        let heading_post_url = `/tnc_heading`;
        if (transaction_mode) heading_post_url += `?for_documents=true`;

        let api = Api
        api.setUserToken()
        api.axios().post(Settings.apiUrl+heading_post_url,{
            "template_id": parseInt(id),
            "index": termsDetails.index ? parseInt(termsDetails.index) : null,
            "index_otherlanguage": termsDetails.index_otherlanguage ? termsDetails.index_otherlanguage : null,
            "heading_otherlanguage": termsDetails.heading_otherlanguage ? termsDetails.heading_otherlanguage : null,
            "heading": termsDetails.heading ? termsDetails.heading : null,
            "parentheading_id": termsDetails.parent ? parseInt(termsDetails.parent) : null
        }).then(function(res){
            if(res.data.status==='success'){ 
                Helper.alert(res.data.message);
                addContentToTemplate(res?.data?.heading_id); 
                if(!res?.data?.heading_id || !termsDetails.content){
                    setTermsDetails((prevState) => {
                        return {
                            ...prevState,
                            content: '',
                            content_otherlanguage: '',
                            index_otherlanguage: null,
                            heading_otherlanguage: null,        
                            openPopup: false,
                            index: null,
                            heading: null,
                            parent: null,
                            templateheading_id: null,
                            headingcontent_id: null,
                        }
                    });
                    headingsChildGridObj=null;
                    getTermsConditionsTemplatesById();  
                }
            } 
        }).catch((res) => {   
            getPopupMessageBasedOnStatus(res);
        })
    };

    const addContentToTemplate = (templateheading_id) => {
        if(!templateheading_id || !termsDetails.content) return; 

        let heading_content_post_url = `/tnc_content?templateheading_id=${templateheading_id}`;
        if (transaction_mode) heading_content_post_url += `&for_documents=true`;

        let api = Api 
        api.setUserToken()
        api.axios().post(Settings.apiUrl+heading_content_post_url,{
            "templateheading_id": parseInt(templateheading_id),
            "content": termsDetails.content,
            "content_otherlanguage": termsDetails.content_otherlanguage
        }).then(function(res){
            if(res.data.status==='success'){ 
                Helper.alert(res.data.message); 
                setTermsDetails((prevState) => {
                    return {
                        ...prevState,
                        content: '',
                        content_otherlanguage: '',
                        index_otherlanguage: null,
                        heading_otherlanguage: null,        
                        openPopup: false,
                        index: null,
                        heading: null,
                        parent: null,
                        templateheading_id: null,
                        headingcontent_id: null,
                    }
                });
                headingsChildGridObj=null;
                getTermsConditionsTemplatesById();  
            } 
        }).catch((res) => {   
            getPopupMessageBasedOnStatus(res); 
        })
    };

    const updateHeadingToTemplate = () => {  
        if(!termsDetails.heading && !termsDetails.index && !termsDetails.parent) return;

        let validData = { ...termsDetails }

        if (!templateHeadingsValidator.isValid(validData)) {
            templateHeadingsValidator.displayMessage();
            templateHeadingsValidator.reset();
            return;
        }

        let heading_put_url = `/tnc_heading/${termsDetails.templateheading_id}`;
        if (transaction_mode) heading_put_url += `?for_documents=true`;

        let api = Api
        api.setUserToken()
        api.axios().put(Settings.apiUrl+heading_put_url,{
            "template_id": parseInt(id),
            "index": termsDetails.index ? parseInt(termsDetails.index) : null,
            "index_otherlanguage": termsDetails.index_otherlanguage ? termsDetails.index_otherlanguage : null,
            "heading_otherlanguage": termsDetails.heading_otherlanguage ? termsDetails.heading_otherlanguage : null,
            "heading": termsDetails.heading ? termsDetails.heading : null,
            "parentheading_id": termsDetails.parent ? parseInt(termsDetails.parent) : null
        }).then(function(res){
            if(res.data.status==='success'){ 
                Helper.alert(res.data.message);
                setTermsDetails((prevState) => {
                    return {
                        ...prevState,
                        content: '',
                        content_otherlanguage: '',
                        heading_otherlanguage: null,
                        index_otherlanguage: null, 
                        openPopup: false,
                        index: null,
                        heading: null,
                        parent: null,
                        templateheading_id: null,
                        headingcontent_id: null,
                    }
                });
                contentSubChildGridObj=null;
                getTermsConditionsTemplatesById();  
            } 
        }).catch((res) => {   
            getPopupMessageBasedOnStatus(res);
        })
    };

    const updateContentToTemplate = () => {
        if(!termsDetails.content) return;

        let heading_content_put_url = `/tnc_content/${termsDetails.headingcontent_id}`;
        if (transaction_mode) heading_content_put_url += `?for_documents=true`;

        let api = Api
        api.setUserToken()
        api.axios().put(Settings.apiUrl+heading_content_put_url,{
            "content": termsDetails.content,
            "content_otherlanguage": termsDetails.content_otherlanguage
        }).then(function(res){
            if(res.data.status==='success'){ 
                Helper.alert(res.data.message);
                setTermsDetails((prevState) => {
                    return {
                        ...prevState,
                        content: '',
                        content_otherlanguage: '',
                        heading_otherlanguage: null,
                        index_otherlanguage: null, 
                        openPopup: false,
                        index: null,
                        heading: null,
                        parent: null,
                        templateheading_id: null,
                        headingcontent_id: null,
                    }
                });
                contentSubChildGridObj=null;
                getTermsConditionsTemplatesById();  
            }
        }).catch((res) => {   
            getPopupMessageBasedOnStatus(res);
        })
    };

    const SaveHandler = () => {
        if(!id) return;
          
        if( termsDetails.templateheading_id && (termsDetails.templateheading_id == termsDetails.parent)){
            Helper.alert('A heading cannot be its own parent!', 'error');
            return;
        };

        const ObjectOfParent = termsCondtionsTemplates[0]?.headings?.find(val => val.templateheading_id == termsDetails.parent);
  
        if((ObjectOfParent?.parentheading_id == termsDetails.templateheading_id) && termsDetails.templateheading_id){
            Helper.alert('Heading you are making parent is already a parent!', 'error');
            return;
        }

        if (!termsDetails.templateheading_id) {
            addNewHeadingToTemplate(); 
        }

        if (termsDetails.templateheading_id && !termsDetails.headingcontent_id) { 
            addContentToTemplate(termsDetails.templateheading_id); 
        }
         
        if (termsDetails.templateheading_id) {
            updateHeadingToTemplate();  
        }

        if (termsDetails.headingcontent_id) {
            updateContentToTemplate();  
        }
    };

    return (
        <div className='common_detail_view_headings'>
            <div className='container-fluid scrollBarBlinkControl'>
                <div ref={wrapperDiv}>
                    <NavigationHeder hideMoreBtn={true} title={Helper.getLabel(language,'headings', "Headings To Template")}>
                        <LinkBtn isActive= {false} title={Helper.getLabel(language,'close',"Close")} /> 
                        <Button title={Helper.getLabel(language,'delete',"Delete")} onClick={DeleteSelectedHeading} /> 
                        <Button className="rfq_save_btn" isActive= {false} title={Helper.getLabel(language,'save',"Save")} onClick={SaveHandler} /> 
                    </NavigationHeder>
                </div>
                {CompleteModuleWrapper()}
            </div>
            {termsDetails.openPopup && <TermsHTMLDisplayPopup templateTitle={termsDetails.title} content_otherlanguage={termsDetails.content_otherlanguage} content={termsDetails.content} ClosePopupView={setTermsDetails} />}
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        appOptions:state.options,
        auth:state.auth,
        language:state.language
    }
};

export default connect(mapStateToProps) (MasterComponentWraper(CommonDetailViewAddHeadings));