import React, { Component } from 'react';
import Input from '../../Forms/Input';
import {connect} from 'react-redux';
import { Link ,Navigate } from 'react-router-dom';
import Settings from '../../../inc/Settings';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import ActionTypes from '../../../inc/ActionsTypes';
import FormValidator from '../../../inc/FormValidator';
import { requestPermission } from '../../../services/Firebase/FirebaseServiceFunctions';
import ForgotPasswordPopup from '../../../screens/LoginRegister/ForgotPasswordPopup';

class LoginForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: '',
            password: '',
            isFormSubmited:false,
            message:null,
            messageType:'fail',
            isLogin:false,
            isAdmin: false,
            isForgotPasswordPopup:false,
        }
        this.validationConfigure();
    }
    validationConfigure(){
        const language = this.props.language;
        let fieldConfig = [
            {name:'email',displayName:Helper.getLabel(language,'email','Email'),types:['Required','Email'],max:80 },
            {name:'password',displayName:Helper.getLabel(language,'password','Password'),types:['Required'],min:5,max:80 },
            //{name:'isactive',displayName:Helper.getLabel(language,'active','Active'),types:['Required']},
        ]
        this.validator = new FormValidator(fieldConfig,language);
    }
    onChangeHandeler(e){
        this.setState({
            ...this.state,
            [e.target.name]:e.target.value
        })
    }
    async onLoginClickHandler(e){
        console.log("login handler 0")
        if(this.state.isFormSubmited){
            console.log("login handler 1")
            return;
        }
        let token
        let validData = {
            email: this.state.email,
            password: this.state.password,
        }
        if(!this.validator.isValid(validData)){
            console.log("login handler 2")
            this.validator.displayMessage(this);
            return;
        } 
        try {
            console.log("login handler 3")
            token = await requestPermission();
            console.log("login handler 4")
        } catch (error) {
            console.error('Error requesting permission:', error);
        }    
        console.log("login handler 5")                                           
        let formData 
        if(token){
            formData = {
                user_name: this.state.email,
                password: Helper.encryptPassword(this.state.password),
                web_fcm_token:token,
            };
        }else{
            formData = {
                user_name: this.state.email,
                password: Helper.encryptPassword(this.state.password),
            };
        }
        console.log("login handler 6")
        let that = this;
        this.setState({
            isFormSubmited:true,
            message:null,
            messageType:'fail',
            isLogin:false
        });
        console.log("login handler 7")
        let api = Api;
        api.setJsonHeader();
        console.log("login handler 8")
        api.axios().post(Settings.apiUrl + '/login',formData).then(function({data}){
            if(data && data.status === "Success"){ 
                const aditionalData = data.data;
                const location = window.location.href;
                const match = location.match(/\/\/([a-zA-Z0-9-]+)\./);
                const subdomain = match ? match[1] : null;
                //set user token to browser
                Helper.setCookie(Settings.userTokenKey,data.jwttoken,Settings.userTokenExpireDate);
                Helper.setCookie(Settings.dateFormateKey,(!aditionalData?.tenant?.date_format ? 'dd/mm/yyyy' : aditionalData?.tenant?.date_format));
                Helper.setCookie(Settings.tenantSubdomain,subdomain)
                that.props.setDateFormate((!aditionalData?.tenant?.date_format ? 'dd/mm/yyyy' : aditionalData?.tenant?.date_format));
                that.setState({
                    isAdmin: aditionalData?.admin_user
                })
                api.setUserToken();
                api.axios().get(Settings.apiUrl+'/user_details',{}).then(function({data}){
                    if(data && data.Status === "Success"){
                        that.props.setUser(data.data);
                    }
                    that.setState({
                        isFormSubmited:false,
                        messageType:data.status,
                        message: `Welcome ${aditionalData.first_name} ${aditionalData.first_name}`,
                        isLogin:true
                    },function(){
                        that.props.login(aditionalData);
                    });
                })
                

            }else{
                that.setState({
                    isFormSubmited:false,
                    messageType:data.status,
                    message: data.message,
                    isLogin:true
                });
            }
            
        }).catch(error => {
            
            that.setState({
                message: error.code  == 'ERR_NETWORK' ? "Network not working. please try again" : (error && error.response ? error.response.data.message : ''),
                isFormSubmited:false
            });
        })
    }
    onKeyPreshHander(event){
        if(event.key =='Enter'){
            this.onLoginClickHandler(event);
        }
    }
    onClose(){
        this.setState({
            isForgotPasswordPopup:false
        })
    }
    render() { 
        if(this.state.isLogin){
            return <Navigate to = {!this.state.isAdmin ? ('/' + Settings.redirectUrlAfterLogin) : ('/' + Settings.redirectAdminAfterLogin)}/>
        }
        return (
            <div className='login_form_group'>
                <div className='lf_title_subtitle'>
                    <h2>Log in to your account</h2>
                    <p>Enter your login details below</p>
                </div>
                <Input label="Email" hasError={this.validator.hasError('email')}  inputType="email" placeholder="Email" name="email" onKeyPress={ this.onKeyPreshHander.bind(this)} value={this.state.email} onChange= { e => this.onChangeHandeler(e) } placeHolder = "Please enter your email" />
                <Input label="Password" hasError={this.validator.hasError('password')} inputType="password" placeholder="password"  onKeyPress={ this.onKeyPreshHander.bind(this)} value={this.state.password} onChange= { e => this.onChangeHandeler(e) } name="password" placeHolder="Enter password" />
                <p className="mb-0 mt-2 text-end" style={{color:"#FC5E24",cursor:"pointer"}} onClick={()=>{this.setState({isForgotPasswordPopup:true})}}>Forgot your password?</p>
                { this.state.message ? <p className={this.state.messageType ==='Success' ? 'alert alert-success' : 'alert alert-danger' }>{this.state.message}</p> : ''}
                {this.state.isForgotPasswordPopup && <ForgotPasswordPopup language={this.props.language} forgotPasswordPopup={this.state.isForgotPasswordPopup} handleClose={this.onClose.bind(this)} />}
                <button className='btn btn_block btn_active sb_btn' onClick={e => this.onLoginClickHandler(e)}>Log in <img src="/images/icons/arrow-right-white.svg" alt="Sign In" /> {this.state.isFormSubmited ? <output className="spinner-border spinner-border-sm" aria-hidden="true"></output> : ''}</button>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        language:state.language,
        auth:state.auth
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        //date_format
        setDateFormate:(formate) => { dispatch({type:ActionTypes.SET_DATE_FORMATE , payload:formate}) },
        login:(user) => { dispatch({type:ActionTypes.SET_LOGIN , payload:user}) },
        setUser:(user) => { dispatch({type:ActionTypes.SET_USER , payload:user}) },
    }
}
export default connect(mapStateToProps,mapDispatchToProps) (LoginForm);