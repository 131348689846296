import React, { Fragment, useState } from 'react';
import $ from 'jquery'; 
import { connect } from "react-redux";
import axios from 'axios';    
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import FormValidator from '../../inc/FormValidator';
import Api from '../../inc/Api';
import FieldsRenderer from '../Purchase Orders Internal/Edit PO/FieldsRenderer';
import Helper from '../../inc/Helper';
import Popup from '../Popup/Popup'; 
import Collapse from '../inc/Collapse';
import Button from '../inc/Button';
import { getPopupMessageBasedOnStatus } from '../../inc/Validation';
import Settings from '../../inc/Settings';
import OverlayLoader from '../PurchaseRequisition/common/OverlayLoader';

let cancelTokenSource = axios.CancelToken.source();
const CreateProcurementModal = ({ setShowCreateProcureModal, language }) => {
    const navigate = useNavigate();

    const [procurementData, setProcurementData] = useState({});
 
    const [apiCallBtn, setApiCallBtn] = useState(false);
  
    let procurementValidator;   

    const validationConfigure = () => {
        let procurementFieldConfig = [
            {name:'name', displayName:Helper.getLabel(language,'name','Name'), types:['Required']}
        ];

        if(!procurementValidator) {
            procurementValidator = new FormValidator(procurementFieldConfig, language)
        }; 
    };

    validationConfigure();
 
    const fieldsArrayGeneral = [
        {className:'col-lg-4', required: true, label: Helper.getLabel(language,'name',"Name"), inputType: "text", id:'name', name:'name'} 
    ];

    const onPopupClose = () => {
        $("html").removeClass("rs_popup_open");
        setShowCreateProcureModal(false);
    }

    const executePOSTProcurementAPI = async () => {
        try { 
            let api = Api;
            api.setUserToken();
        
            const res = await api.axios().post(Settings.apiProcurementSettingsUrl+'/procurementsetting', procurementData,
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res.data.message); 
                onPopupClose();
                navigate(`/edit-procurement-settings/${res?.data?.procurementsetting_id}`); 
            };
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        };
    };

    const handleSubmit = () => { 
        if(!procurementValidator.isValid({...procurementData})){
            procurementValidator.displayMessage();
            procurementValidator.reset();
            return;
        };
 
        Helper.createDebouncedAPIFunction( 
            [executePOSTProcurementAPI], 
            setApiCallBtn,
            cancelTokenSource
        )();
    };

    return (
        <Fragment>
            { apiCallBtn ? <OverlayLoader /> : null } 
            <Popup
                autoOpen={true} width="900px"
                onClose={onPopupClose}
            > 
                <>
                    <h5 className="create_rfq_modal__title">Create Procurement Settings</h5>

                    <Collapse
                        className="mt-4 mb-4" open={true}
                        title={Helper.getLabel(
                            language,
                            "general_info",
                            "General Information"
                        )}
                    >
                        <Form>
                            <div className='container-fluid'>
                                <FieldsRenderer updateState={setProcurementData} currentState={procurementData} fieldsArray={fieldsArrayGeneral} />
                            </div>
                        </Form> 
                    </Collapse> 
                </> 

                <div className="addDocumentNumber-body-buttons" style={{ justifyContent: "flex-end" }}>
                    <Button
                        isActive={false} title={Helper.getLabel(language, "cancel", "Cancel")}
                        onClick={onPopupClose}
                    />
                    <Button
                        isDisable={apiCallBtn}
                        className="solid_btn" isActive={true}
                        title={Helper.getLabel(language, "create", "Create")} onClick={handleSubmit}
                    />
                </div>
            </Popup>
        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        appOptions: state.options,
        auth: state.auth,
        language: state.language,
    };
};

export default connect(mapStateToProps)(CreateProcurementModal); 