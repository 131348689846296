import React, { useState } from 'react'
import Popup from '../../../Popup/Popup'
import Helper from '../../../../inc/Helper'
import $ from 'jquery'
import DatepickerFunctions from '../../../../inc/DatepickerHelper'
import NewInputDatePicker from '../../../Forms/NewInputDatePicker'
import Button from '../../../inc/Button'
import { Form } from 'react-bootstrap'
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation'
import Api from '../../../../inc/Api'
import Settings from '../../../../inc/Settings'
import FormValidator from '../../../../inc/FormValidator'
import OverlayLoader from '../../../PurchaseRequisition/common/OverlayLoader'

const RequestExtensionModal = ({handleCloseModal,language,rfq_vendor_id,actions}) => {
  const [extensionData,setExtensionData]=useState({
    newexpirydate:null,
    reason:''
  })
  const [isLoading,setIsLoading]=useState(false)

  let validator ; 

  const validationConfigure = () => {
    let fieldConfig = [ 
        {name:'newexpirydate', displayName:Helper.getLabel(language,'expiry_datetime','Expiry Date Time'),types:['Required']}, 
        {name:'reason', displayName:Helper.getLabel(language,'reason','Reason'),types:['Required']},   
      ]
    
    if(!validator) {
      validator = new FormValidator(fieldConfig,language)
    }
  }
  validationConfigure()
  
  const onSubmit = () => {
    let validData = {
      ...extensionData
    }

    if(!validator.isValid(validData)){
      validator.displayMessage();
      validator.reset() 
      return;
    } 

    const matchingAction = actions.find(
      (action) => action.name.toLowerCase() === "request-extension",
    )
    const data = {
      rfq_id: null,
      vendorreply_id: rfq_vendor_id,
      action: null,
      newexpirydatetime:DatepickerFunctions.convertDateTimeForDataBase(extensionData?.newexpirydate),
      reason: extensionData?.reason,
      vendors: null,
    }
    if (matchingAction) {
      const { id } = matchingAction
      data.action = id
    }
    let api = Api
    api.setUserToken()
    setIsLoading(true)
    api
      .axios()
      .post(
        Settings.apiPurchaseRequisitionUrl + `/vendoraction_on_vendorreply`,
        data,
      )
      .then(function (res) {
        if (res.data.status === 'success') {
          Helper.alert(res.data.message, 'success')
          setIsLoading(false)
          handleCloseModal()
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
        setIsLoading(false)
      })
  }

  return (
    <>
    <Popup
    autoOpen={true}
    width='900px'
    onClose={() => {
      $('html').removeClass('rs_popup_open')
      handleCloseModal()
    }}
  >
    <>
     <div className="d-flex justify-content-between align-items-start">
      <div>
      <h5 className='create_rfq_modal__title'>{Helper.getLabel(language, 'extension', 'Extension')}</h5>
      <h6 className='inviteModalSubHeading mb-3'>
        {Helper.getLabel(
          language,
          'please_suggest_new_expiry_date_and_time',
          'Please suggest new expiry date and time.'
        )}
      </h6>
      </div>
      <div
        className='addDocumentNumber-body-buttons justify-content-end gap-3'
      >
        <Button
          isActive={false}
          title={Helper.getLabel(language, 'close', 'Close')}
          onClick={() => {
            $('html').removeClass('rs_popup_open')
            handleCloseModal()
          }}
        />
        <Button
          className='solid_btn rfq_save_btn'
          title={Helper.getLabel(language, 'submit', 'Submit')}
          onClick={() => {
            onSubmit()
            $('html').removeClass('rs_popup_open')
          }}
        />
      </div>
    </div>

      <div
        title={Helper.getLabel(
          language,
          'fill_up_the_form',
          'Fill up the form'
        )}
        open={true}
      >
        <Form>
          <div
            className='addVendorModalForm'
            style={{ marginBottom: '10px' }}
          >
            <NewInputDatePicker
              label={`${Helper.getLabel(
                language,
                'select_expiry_date_time',
                'Select Expiry Date Time'
              )}`}
              id='newexpirydate'
              name='newexpirydate'
              empty={true}
              onChange={(date) => {
                setExtensionData({
                    ...extensionData,
                    newexpirydate:date,
                })
              }}
              value={extensionData?.newexpirydate}
              timePicker={true}
              minDate={new Date()}
              required
            />
          </div>

          <label htmlFor='inviteModal__message'>
            {Helper.getLabel(language, 'reason', 'Reason')} <span style={{color:'red'}}>*</span>
          </label>

          <textarea
            name='inviteModal__message'
            id='inviteModal__message'
            rows='5'
            value={extensionData?.reason}
            className="mb-3"
            maxLength={255}
            onChange={(e) => setExtensionData({
                ...extensionData,
                reason:e.target.value
            })}
            required={true}
          ></textarea>
        </Form>
      </div>
    </>
  </Popup>
   {isLoading ? <OverlayLoader /> : null}
  </>
  )
}

export default RequestExtensionModal
