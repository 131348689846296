import React, {useState, useEffect, Fragment, useCallback} from 'react'
import { connect, useDispatch } from 'react-redux'
import NavigationHeder from '../../Navigations/NavigationHeder' 
import { gridIdActions } from '../../../store/grid-id' 
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'
import Helper from '../../../inc/Helper' 
import { useLocation, useNavigate } from 'react-router-dom' 
import Gui_id_list from '../../../inc/Gui_id_list' 
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import ButtonNew from '../../inc/ButtonNew'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import AgGridNew from '../../grid/ag/ag-grid-new'
import { getDisplayNamesFromArray, getOptions, gridLinkValueFormat, optionsKeyCreator, statusLabelValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import Alert from '../../inc/Alert'

let gridApi= null;

const UserArchive = (props) => { 
  const [userType, setUserType] = useState([]);
  
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
 
  const urlParams = new URLSearchParams(location.search);
  const user_type = urlParams?.get('user_type');

  let userTypeCalled = false;
  
  useEffect(() => { getUserTypes() }, []);

  const getUserTypes = () => {
    if(userTypeCalled) return;
    userTypeCalled = true

    let api = Api 
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/enum/usertype`,{}).then(function(res){
      if(res.data.status==='success'){
        setUserType(res?.data?.data?.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        }))
      }
    }).catch((res) => {  
      console.log(res)
    })
  }

  const CellHandler = (event) => {
    if(event.colDef.field === 'user_name') {
      dispatch(gridIdActions.UsersIdChange({payload: event.data.user_id}))  
      navigate(`/user/edit/${event.data.user_id}`)
    }
  }

  const onDeleteUserHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    let delUser_ID = element?.data?.user_id;
    try {
      const res = await api
        .axios()
        .delete(Settings.apiUrl+`/user/${delUser_ID}`)
      const rowNode = gridApi?.getRowNode(delUser_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi.applyServerSideTransaction(transaction)
      }, 200)
      gridApi.refreshServerSide({ purge: true })
      gridApi?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);

  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.administrationForms.users.users)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  const gridColHeaders = [
    {field:'user_name', filter:'agTextColumnFilter', headerName: Helper.getLabel(props.language,'250020104',"User name"), cellRenderer: (params) => gridLinkValueFormat(params)},
    {field:'usertype', filterParams: { values: (params) => getOptions(params, userType), keyCreator: (params) => optionsKeyCreator(params, 'value'), valueFormatter: params => params.value.label }, filter: 'agSetColumnFilter', headerName: Helper.getLabel(props.language,'250070104',"User type"), minWidth: 350, cellRenderer: (params) => getDisplayNamesFromArray(params, userType, 'value', 'label')},
    {
      field:'is_buyer', filter: 'agSetColumnFilter', filterParams: { values: [true, false], keyCreator: (params) => params.value, valueFormatter: (params) => params.value ? 'true' : 'false' }, 
      editable: false, headerName: 'is Buyer',
      minWidth: 250,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      cellRendererParams: { disabled: true },
      valueGetter: (params) => {
        if(!params?.data?.is_buyer || !params?.data){
          return false
        }
        else {
          return params.data?.is_buyer
        }
      }
    },
    {field:'enable', filter: 'agSetColumnFilter', filterParams: { values: [true, false], keyCreator: (params) => params.value, valueFormatter: (params) => params.value ? 'Active' : 'Inactive' }, cellRenderer: (params) => statusLabelValueFormat(params), headerName: Helper.getLabel(props.language,'250030104',"Status"), minWidth: 350},
    {field:'first_name', filter:'agTextColumnFilter', headerName: Helper.getLabel(props.language,'250040104',"First name"), minWidth: 350},
    {field:'middle_name', filter:'agTextColumnFilter', headerName: Helper.getLabel(props.language,'250050104',"Middle name"), minWidth: 350 },  
    {field:'last_name', filter:'agTextColumnFilter', headerName: Helper.getLabel(props.language,'250060104',"Last name"), minWidth: 250},
  ];

  return (
    <div className='users_archive'>
      <div className='container-fluid'>
        <NavigationHeder hideMoreBtn={true} title={Helper.getLabel(props.language,`${user_type ? 'Users - External' : 'Users - All'}`,`${user_type ? 'Users - External' : 'Users - All'}`)}>
          <ButtonNew isDisable={!security.canCreate(frontendIds.administrationForms.users.users_main_create_button)} to="/new-user" title={Helper.getLabel(props.language,'new',"New")} icon={'/images/icons/plus-white.svg'} />
        </NavigationHeder> 

        <AgGridNew
          apiUrl={Settings.apiUrl+`/user`}
          additionalParams={user_type ? `&user_type=1`: ''}
          hideDeleteBtn={!security.canDelete(frontendIds.administrationForms.users.users_main_delete_button)}
          pagination={true}
          columnDefs={gridColHeaders} 
          hideAddBtn={true}
          height={500}
          uniqueField={'user_id'}
          onCellClicked={CellHandler}
          handleDeleteSelectedRows={onDeleteUserHandler}
          onGridReady={(params) => gridApi = params.api} 
          gridId={"rs_users_list"}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.administrationForms.users.users
}

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(UserArchive, SecurityOptions))));