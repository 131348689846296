import React, { useState, useEffect, useRef, Fragment } from 'react';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import Input from '../../Forms/Input';
import Alert from '../../inc/Alert';
import Button from '../../inc/Button';
import Collapse from '../../inc/Collapse';
import LinkBtn from '../../inc/LinkBtn';
import RsDataLoader from '../../inc/RsDataLoader';
import SimpleLoading from '../../Loading/SimpleLoading';
import NavigationHeder from '../../Navigations/NavigationHeder';
import TextTranslations from '../../Popup/TextTranslation/TextTranslations';
import AddressList from '../../Tenant/Address/AddressList';
import ContactAddress from '../../Tenant/Address/ContactAddress';
import Dropdown from '../../Forms/Dropdown';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';
import FormValidator from '../../../inc/FormValidator';
import { useParams } from 'react-router-dom';

const EditTaxAuthority = (props) => {
    const { id } = useParams();
    
    const wrapperDiv = useRef(null);  
    const [height, setHeight] = useState(0);  

    const language = props.dataLoaderProps.language;
    const [isLoading, setIsLoading] = useState(false);
    const [taxData, setTaxData] = useState({});
    const [currentSearchTerm, setCurrentSearchTerm] = useState('');
    const parentTaxVendorData = useRef(null);

    const source_id = 21;

    const fieldConfig = [
        { name: 'code', displayName: Helper.getLabel(language, 'code', 'Code'), types: ['Required'] },
        { name: 'name', displayName: Helper.getLabel(language, 'name', 'Name'), types: ['Required'] },
        { name: 'vendor_id', displayName: Helper.getLabel(language, 'vendor', 'Vendor'), types: ['Required'] },
    ];

    const validator = new FormValidator(fieldConfig, language);

    useEffect(() => { 
        Helper.resizeWindowHandle(wrapperDiv,setHeight,window);
    }, [wrapperDiv?.current?.clientHeight, isLoading]);
    
    useEffect(() => {
        loadTaxAuthority();
    }, []);

    const loadTaxAuthority = () => {
        setIsLoading(true);
        Api.setUserToken();

        Api.axios().get(`${Settings.apiUrl}/taxauthority/${id}`)
            .then((res) => {
                if (res.data.status === 'success') {
                    setTaxData(res.data.data);
                } else {
                    setTaxData({});
                }
            })
            .catch(() => {
                setTaxData({});
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const mapApiVendorItems = (item) => ({
        id: item.vendor_id,
        text: item.name,
    });

    const onInputHandler = (e) => {
        setTaxData((prevTaxData) => ({
            ...prevTaxData,
            [e.target.name]: e.target.value,
        }));
    };

    const onSaveHandler = () => {
        if (!validator.isValid(taxData)) {
            validator.displayMessage();
            return;
        }

        setIsLoading(true);
        Api.setUserToken();

        Api.axios().put(`${Settings.apiUrl}/taxauthority/${taxData.taxauthority_id}`, taxData)
            .then((res) => {
                Helper.alert(res.data.message);
                if (res.data.status === 'success') {
                    loadTaxAuthority();
                }
            })
            .catch((error) => {
                getPopupMessageBasedOnStatus(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const AddressCreate = (response) => {
        if (taxData && !taxData.addresssource_id) {
            setTaxData((prevTaxData) => ({
                ...prevTaxData,
                addresssource_id: response?.address_source_id,
            }));
        }
    };

    const onContactCreate = (response) => {
        if (taxData && !taxData.contactsource_id) {
            setTaxData((prevTaxData) => ({
                ...prevTaxData,
                contactsource_id: response?.contact_source_id,
            }));
        }
    };

    const updateTranslationId = (translationId) => { };

    return (
        <Fragment>
            {isLoading ? <OverlayLoader isPopupInTab={true} /> : null}
            <div className={'container-fluid'}> 
                <div ref={wrapperDiv}> 
                    <NavigationHeder hideMoreBtn={true} backUrl="/tax/authority" title={Helper.getLabel(language, 'edit_tax_authority', 'Edit Tax Authority')}>
                        <TextTranslations
                            source_id={source_id}
                            source_integrator={taxData?.taxauthority_id}
                            translationsource_id=""
                            original_text={taxData?.name}
                            product_number={taxData?.code}
                            onFirstTranslationCreate={updateTranslationId}
                        />
                        <LinkBtn to={-1} isActive={false} title={Helper.getLabel(language, 'close', 'Close')} />
                        <Button className="rfq_save_btn" onClick={onSaveHandler} isActive={false} title={Helper.getLabel(language, 'save', 'Save')} />
                    </NavigationHeder>
                </div>
                
                <div
                    className="common_scrollbar_styles"
                    style={{ height: `${height - 30}px`, overflowY: "auto" }}
                >
                    <Collapse title={Helper.getLabel(language, 'general', 'General')} open={true}>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <Input
                                        maxLength={10}
                                        disable={true}
                                        name="code"
                                        hasError={validator.hasError('code')}
                                        value={taxData.code}
                                        label={Helper.getLabel(language, 'code', 'Code')}
                                        required={true}
                                        onChange={onInputHandler}
                                    />
                                </div>
                                <div className='col-sm-4'>
                                    <Input
                                        maxLength={80}
                                        name="name"
                                        value={taxData.name}
                                        hasError={validator.hasError('name')}
                                        label={Helper.getLabel(language, 'name', 'Name')}
                                        required={true}
                                        onChange={onInputHandler}
                                    />
                                </div>
                                <div className='col-sm-4'>
                                    <Dropdown
                                        id="vendor_id"
                                        name="vendor_id"
                                        label={Helper.getLabel(language, 'vendor', 'Vendor')}
                                        options={[
                                            { value: taxData?.vendor_id, label: taxData?.vendor_name }
                                        ]}
                                        reRenderRequired={true}
                                        apiUrl={Settings.apiVendorUrl + '/vendor'}
                                        currentSearchTerm={currentSearchTerm}
                                        mapFunction={mapApiVendorItems}
                                        allDataRef={parentTaxVendorData}
                                        pagination={true}
                                        required={true}
                                        placeHolder={Helper.getLabel(language, 'select_vendor', 'Select vendor')}
                                        value={taxData?.vendor_id}
                                        onChange={onInputHandler}
                                    />
                                </div>
                            </div>
                        </div>
                    </Collapse>
                    {isLoading ? null : <AddressList onAddressCreate={AddressCreate} addressSourc_id={taxData?.addresssource_id} address_source_integrator={id} source_id={source_id} /> }
                    {isLoading ? null : 
                        <Collapse title={Helper.getLabel(language, 'contacts', 'Contacts')} open={true}> 
                            <ContactAddress
                                id='warehouse_contact'
                                contactsource_integrator={id}
                                onContactCreate={onContactCreate}
                                contactsource_id={taxData?.contactsource_id}
                                source_id={source_id}
                                address_id={null}
                            />
                        </Collapse> 
                    }
                </div> 
            </div>
        </Fragment>
    );
};

export default RsDataLoader(EditTaxAuthority, { vendors: true }); 