
import { connect } from 'react-redux'; 
import './MyRequest.css';
import Helper from '../../../inc/Helper';
import CollapseWithButton from '../../inc/CollapseWithButton';
import { Fragment, useEffect, useState } from 'react';
import Tabs from '../../Widgets/Tabs/Tabs'; 
import TaskActionDropdown from './TaskActionDropdown';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import SimpleLoading from '../../Loading/SimpleLoading'; 
import { checkIfArrayIsEmpty, getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import { useNavigate } from 'react-router-dom'; 
import RFQCommonApiCalls from '../../RequestForQuotation/RFQCommonApiCalls';
import WorkConfirmationApiCalls from '../../External Screens/Work Confirmation/CreateWorkConfirmation/NewWorkConfirmation/WorkConfirmationApiCalls';
import DatepickerFunctions from '../../../inc/DatepickerHelper';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { getOptions, getStatusNameById, gridLinkValueFormat, optionsKeyCreator, purchaseStatusValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions';

const MyRequest = (props) => {
  const navigate = useNavigate();

  const commonApiCalls = new RFQCommonApiCalls();
 
  const [documentStatus, setDocumentStatus] = useState([])
 
  const [POStatuses, setPOStatuses] = useState([])
  const [purchaseRequisitionStatus, setPurchaseRequisitionStatus] = useState([])
  const [rfqStatusList, setRfqStatusList] = useState([]);
  const [typeDropdownData, setTypeDropdownData] = useState([])

  const [workConfirmationType, setWorkConfirmationType] = useState('');
  const [rfxStatus, setRfXStatus] = useState('Type');
  
  const [loadingRFX, setLoadingRFX] = useState(false)
  const [loadingWorkConfirmations, setLoadingWorkConfirmations] = useState(true)
   
  let POStatus = false
  let PurchaseReqStatus = false

  useEffect(() => {  
    getAllWorkConfirmations()
    getRFQStatuses()   
    getPurchaseOrderStatuses()
    getPurchaseRequisitionStatuses()
  }, [])

  // My Request tabs Array
  let navs = [
    {title:`${Helper.getLabel(props.language,'purchase_order','Purchase Order')}`, forceLoadContents: true, isActive: true}, 
    {title:`${Helper.getLabel(props.language,'contracts','Contract')}`, forceLoadContents: true},
    {title:`${Helper.getLabel(props.language,'work_confirmation','Work Confirmation')}`, forceLoadContents: true},
    {title:`${Helper.getLabel(props.language,'purchase_requisition','Purchase Requisition (PR)')}`,forceLoadContents: true},
    {title:`${Helper.getLabel(props.language,'rfx','RFx')}`, forceLoadContents: true},  
  ];
 
  const getRFQStatuses = async () => {
    try { 
      const rfqStatusData = await commonApiCalls.getAllRfqStatus(); 
      if (rfqStatusData) {
        let Updated_Data = []
        rfqStatusData.forEach((item) => {
          Updated_Data.push({
            value: item.id,
            label: item.name
          })
        })
        setTypeDropdownData(Updated_Data);
        setRfqStatusList(rfqStatusData);
      }
    } catch (error) {
      Helper.alert(
        error?.response?.status === 500
          ? Helper.getLabel(
              this.props.language,
              "something_went_wrong",
              "Something went wrong"
            )
          : error?.response?.data?.message,
        "error"
      );
    }
  };

  const getAllWorkConfirmations = async () => {
    const workConfirmationApiCalls = new WorkConfirmationApiCalls()

    const shtypes = await workConfirmationApiCalls.getAllShType()
    const shtype = shtypes?.find((item) => item.name === 'Work Confirmation')
    const documentStatus = await workConfirmationApiCalls.getAllShDocumentStatus()
    
    if (shtype) setWorkConfirmationType(shtype?.id)
    if (documentStatus) setDocumentStatus(documentStatus)
    setLoadingWorkConfirmations(false)
  };
  
  const getPurchaseOrderStatuses = () => {
    if(POStatus) return;

    let api = Api
    POStatus = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/enum/POHeaderPurchaseStatus',{}).then(function(res){
      if(res.data.status==="success"){
        setPOStatuses(res?.data?.data) 
      }
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const getPurchaseRequisitionStatuses = () => {
    if(PurchaseReqStatus) return;

    let api = Api
    PurchaseReqStatus = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/enum/pr_document_status',{}).then(function(res){
      if(res.data.status==="success"){
        setPurchaseRequisitionStatus(res?.data?.data) 
      }
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }
 
  // modules
  const PRModule = () => {
    const CellHandler = (event) => {
      if (event.colDef.field === "document_number") {
        navigate(
          "/purchase-requisition/summary?pr_view_id=" +
            event.data.purchaserequistion_id +
            "&return=pr"
        );
      }
    }
  
    const gridColumnHeadersPR = [
      { field: "document_number", minWidth: 280, headerName: Helper.getLabel( props.language, "document_number", "Document Number" ), cellRenderer: (params) => gridLinkValueFormat(params), editable: false },
      { field: "description", minWidth: 280, headerName: Helper.getLabel(props.language,"description", "Description" ), editable: false },
      {
        field: 'document_status',
        minWidth: 200,
        headerName: Helper.getLabel(
          props.language,
          'document_status',
          'Document status',
        ),
        editable: false,
        cellRenderer: (params)=> getStatusNameById(params.value, purchaseRequisitionStatus),
        filter:'agSetColumnFilter',
        filterParams: {
          values: (params) => getOptions(params, purchaseRequisitionStatus),
          keyCreator: (params) => optionsKeyCreator(params, 'id'),
          valueFormatter: params => params.value.name
        }
      },
      {field: "requester_name", minWidth: 280, headerName: Helper.getLabel(props.language, "requester", "Requester"), editable: false },
      {field: 'current_stage', valueFormatter: (params) => params.value ? params.value : '-' , minWidth: 280, headerName: Helper.getLabel(props.language,'current_stage',"Current Stage"), editable: false },
      {field: 'current_assignee', valueFormatter: (params) => !checkIfArrayIsEmpty(params.value) ? params.value : '-', minWidth: 280, headerName: Helper.getLabel(props.language,'current_assignee',"Current Assignee"), editable: false },
    ]

    return (
      <Fragment>
        <AgGridNew
          apiUrl={Settings.apiPurchaseRequisitionUrl+`/pr_header`}
          additionalParams={`&createdby=${props?.auth?.user?.user_id}&dashboard=true`}
          filterAdditionalParams={`&createdby=${props?.auth?.user?.user_id}&dashboard=true`}
          pagination={true} 
          columnDefs={gridColumnHeadersPR} 
          onCellClicked={CellHandler}
          hideDeleteBtn={true}
          hideAddBtn={true}
          height={500}
          gridId={'rs_pr_list'}
        />
      </Fragment>
    )
  }

  const TypeDropdownOnChangeHandler = (status) => { 
    setLoadingRFX(true) 
    setRfXStatus(status)
    setTimeout(() => {setLoadingRFX(false)}, 200)
    //comment because api call is coming from useEffect
    // getRFXHeaders(status)
  }

  const RFXModule = () => {
    const cellHandler = (event) => {
      if (event?.value && event?.colDef?.field === "document_number") {
        navigate(
          "/request-for-quotation/rfqdetailform?rfq_view_id=" +
            event?.data?.RFQ_id +
            "&return=rfqheaderlist"
        );
      }
    };

    const gridColumnHeaderRFX = [
      { field: "document_number", headerName: Helper.getLabel(props.language, "document_number", "Document Number"), cellRenderer: (params) => gridLinkValueFormat(params), editable: false, minWidth: 250 },
      {
        field: 'rfq_status',
        headerName: Helper.getLabel(props.language, 'rfq_status', 'RFQ status'),
        cellRenderer:(params)=> getStatusNameById(params.value, rfqStatusList),
        editable: false,
        minWidth: 150,
        filter:'agSetColumnFilter',
        filterParams: {
          values: (params) => getOptions(params, rfqStatusList),
          keyCreator: (params) => optionsKeyCreator(params, 'id'),
          valueFormatter: params => params.value.name 
        }
      },
      { field: "publish_datetime", valueFormatter: (params) => DatepickerFunctions.convertDateTimeFromDataBase(params?.value), filter:'agDateColumnFilter', headerName: Helper.getLabel(props.language, "publish_date_time", "Publish Date Time"), valueFormatter: (params) => DatepickerFunctions.convertDateTimeFromDataBase(params.value) ?? "-", editable: false, minWidth: 250 },
      { field: "delivery_date", valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter:'agDateColumnFilter', headerName: Helper.getLabel(props.language, "delivery_date", "Delivery Date"), valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params.value) ?? "-", editable: false, minWidth: 250 },
      { field: "expiry_datetime", valueFormatter: (params) => DatepickerFunctions.convertDateTimeFromDataBase(params?.value), filter:'agDateColumnFilter', headerName: Helper.getLabel(props.language, "expiry_datetime", "Expiry Date Time"), valueFormatter: (params) => DatepickerFunctions.convertDateTimeFromDataBase(params.value) ?? "-", editable: false, minWidth: 250 },
      { field: 'current_stage',valueFormatter: (params) => params.value ? params.value : '-' , minWidth: 280, headerName: Helper.getLabel(props.language,'current_stage',"Current Stage"), editable: false },
      { field: 'current_assignee',valueFormatter: (params) => !checkIfArrayIsEmpty(params.value) ? params.value : '-', minWidth: 280, headerName: Helper.getLabel(props.language,'current_assignee',"Current Assignee"), editable: false },
    ];
    
    return (
      <Fragment> 
        <div className='d-flex justify-content-end'> <TaskActionDropdown APIData={typeDropdownData} onChangeHandler={TypeDropdownOnChangeHandler} /> </div>
        {loadingRFX ? (
          <div style={{ height: '500px', textAlign: 'center' }}>
            <SimpleLoading />
          </div>
          ) : 
          (
            rfxStatus === 'Type' ? (
              <div style={{ height: '500px', textAlign: 'center' }}></div>
            ) 
            : 
            (
              <AgGridNew
                apiUrl={Settings.apiPurchaseRequisitionUrl + `/rfq_header`}
                additionalParams={`&rfq_status=${rfxStatus}&createdby=${props?.auth?.user?.user_id}&dashboard=true`}
                filterAdditionalParams={`&rfq_status=${rfxStatus}&createdby=${props?.auth?.user?.user_id}&dashboard=true`}
                pagination={true}
                columnDefs={gridColumnHeaderRFX}
                onCellClicked={cellHandler}
                hideDeleteBtn={true}
                hideAddBtn={true}
                height={500}
                gridId={'rs_rfx_list'}
              />
            )
        )}
      </Fragment>
    )
  }

  const POModule = () => {  
    const gridColumnHeadersPO = [
      {field:'document_number', minWidth: 280, headerName:Helper.getLabel(props.language,'document_number',"Document Number"), editable: false,cellRenderer: (params) => gridLinkValueFormat(params) },
      {field:'vendor_code', minWidth: 300, headerName:Helper.getLabel(props.language,'vendor_code',"Vendor Code"), editable: false, cellRenderer: (params) => gridLinkValueFormat(params)},
      {field:'vendor_name', minWidth: 300, headerName:Helper.getLabel(props.language,'vendor_name',"Vendor Name"), editable: false },
      {
        field: 'purchase_status',
        headerName: Helper.getLabel(props.language, 'purchase_status', 'Purchase status'),
        minWidth: 280,
        cellRenderer: (params)=> purchaseStatusValueFormat(params, POStatuses),
        editable: false,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params) => getOptions(params, POStatuses),
          keyCreator: (params) => optionsKeyCreator(params, 'id'),
          valueFormatter: (params) => params.value.name,
        }
      },
      {field:'description', valueFormatter: (params) => params.value ? params.value : '-' , minWidth: 480, headerName:Helper.getLabel(props.language,'description', "Description"), },
      {field:'current_stage',valueFormatter: (params) => params.value ? params.value : '-' , minWidth: 280, headerName: Helper.getLabel(props.language,'current_stage',"Current Stage"), editable: false},
      {field:'current_assignee',valueFormatter: (params) => !checkIfArrayIsEmpty(params.value) ? params.value : '-', minWidth: 280, headerName: Helper.getLabel(props.language,'current_assignee',"Current Assignee"), editable: false},
      {field:'document_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter:'agDateColumnFilter', minWidth: 280, headerName:Helper.getLabel(props.language,'document_date', "Document Date"), },
      {field:'creationtime', valueFormatter: (params) => DatepickerFunctions.convertDateTimeFromDataBase(params?.value), filter:'agDateColumnFilter', minWidth: 280, headerName:Helper.getLabel(props.language,'creationdatetime',"Creation Date Time"), editable: false},  
      {field:'accounting_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter:'agDateColumnFilter', editable:false, hide:true},
      {field:'attention_information', editable:false, hide:true},
      {field:'delivery_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter:'agDateColumnFilter', editable:false, hide:true},
      {field:'delivery_name', editable:false, hide:true},
      {field:'discount_amount', editable:false, hide:true},
      {field:'discount_percentage', editable:false, hide:true},
      {field:'total_amount', editable:false, hide:true}
    ]

    const CellHandlerPO = (event) => {
      if(event.colDef.field === 'document_number') { 
        navigate(`/edit-purchase-order/${event.data.purchase_order_id}`)
      }
      if(event.colDef.field === 'vendor_code') { 
        navigate(`/edit-vendor/${event.data.vendor_id}`,{ state: {prevPath:'/dashboard'} })
      }
    }
    return (
      <div className='grid_wrapper'>
        <AgGridNew
          apiUrl={Settings.apiPurchaseOrderUrl+`/PO_header`}
          additionalParams={`&type=0&createdby=${props?.auth?.user?.user_id}&dashboard=true`}
          filterAdditionalParams={`&type=0&createdby=${props?.auth?.user?.user_id}&dashboard=true`}
          pagination={true} 
          columnDefs={gridColumnHeadersPO} 
          onCellClicked={CellHandlerPO}
          hideDeleteBtn={true}
          hideAddBtn={true}
          height={500}
          gridId={'rs_po_list'}
        />
      </div>
    )
  }

  const ContractModule = () => {
    const gridColumnHeadersCO = [
      {field:'document_number', minWidth: 280, headerName:Helper.getLabel(props.language,'document_number',"Document Number"), editable: false, cellRenderer: (params) => gridLinkValueFormat(params)},
      {field:'vendor_code', minWidth: 300, headerName:Helper.getLabel(props.language,'vendor_code',"Vendor Code"), editable: false, cellRenderer: (params) => gridLinkValueFormat(params) },
      {field:'vendor_name', minWidth: 300, headerName:Helper.getLabel(props.language,'vendor_name',"Vendor Name"), editable: false },
      {
        field: 'purchase_status',
        headerName: Helper.getLabel(props.language, 'purchase_status', 'Purchase status'),
        minWidth: 280,
        cellRenderer: (params)=> purchaseStatusValueFormat(params, POStatuses),
        editable: false,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params) => getOptions(params, POStatuses),
          keyCreator: (params) => optionsKeyCreator(params, 'id'),
          valueFormatter: (params) => params.value.name,
        }
      }, 
      {field:'description', minWidth: 480, headerName:Helper.getLabel(props.language,'description', "Description"), },
      {field:'current_stage', valueFormatter: (params) => params.value ? params.value : '-' , minWidth: 280, headerName: Helper.getLabel(props.language,'current_stage',"Current Stage"), editable: false },
      {field:'current_assignee',valueFormatter: (params) => !checkIfArrayIsEmpty(params.value) ? params.value : '-' , minWidth: 280, headerName: Helper.getLabel(props.language,'current_assignee',"Current Assignee"), editable: false },
      {field:'document_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter:'agDateColumnFilter', minWidth: 280, headerName:Helper.getLabel(props.language,'document_date', "Document Date"), },
      {field:'creationtime', valueFormatter: (params) => DatepickerFunctions.convertDateTimeFromDataBase(params?.value), filter:'agDateColumnFilter', minWidth: 280, headerName:Helper.getLabel(props.language,'creationdatetime',"Creation Date Time"), editable: false},  
      {field:'accounting_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter:'agDateColumnFilter', editable:false, hide:true},
      {field:'attention_information', editable:false, hide:true},
      {field:'delivery_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter:'agDateColumnFilter', editable:false, hide:true},
      {field:'delivery_name', editable:false, hide:true},
      {field:'discount_amount', editable:false, hide:true},
      {field:'discount_percentage', editable:false, hide:true},
      {field:'total_amount', editable:false, hide:true}
    ]
 
    const CellHandlerPO = (event) => {
      if(event.colDef.field === 'document_number') { 
        navigate(`/edit-purchase-order/${event.data.purchase_order_id}`)
      }
      if(event.colDef.field === 'vendor_code') { 
        navigate(`/edit-vendor/${event.data.vendor_id}`, {state: {prevPath:'/dashboard'}})
      }
    }

    return (
      <div className='grid_wrapper'> 
        <AgGridNew
          apiUrl={Settings.apiPurchaseOrderUrl+`/PO_header`}
          additionalParams={`&type=1&createdby=${props?.auth?.user?.user_id}&dashboard=true`}
          filterAdditionalParams={`&type=1&createdby=${props?.auth?.user?.user_id}&dashboard=true`}
          pagination={true} 
          columnDefs={gridColumnHeadersCO} 
          onCellClicked={CellHandlerPO}
          hideDeleteBtn={true}
          hideAddBtn={true}
          height={500}
          gridId={'rs_contract_list'}
        />
      </div>
    )
  }

  const WorkConfirmationModule = () => {  
    const CellHandler = (event) => {
      if (event.colDef.field === 'receiving_reference_number') {
        navigate(
          `/work-confirmation-details?shipment_id=${event.data?.shipment_id}`,
        )
      }
      if (event.colDef.field === 'document_number') {
        navigate(
          `/purchase-order-details?&purchaseorder_id=${event.data?.purchaseorder_id}&shipment_id=${event.data?.shipment_id}`,
        )
      }
    }
   
    const gridColumnHeadersWC = [
      { field: 'receiving_reference_number', headerName: Helper.getLabel(props.language, 'work_confirmation_no', 'Work Confirmation no.'), minWidth: 220, editable: false, cellRenderer: (params) => gridLinkValueFormat(params) },
      { field: 'description', minWidth: 180, headerName: Helper.getLabel(props.language, 'description', 'Description'), editable: false },
      { field: 'current_stage', valueFormatter: (params) => params.value ? params.value : '-' , minWidth: 280, headerName: Helper.getLabel(props.language,'current_stage',"Current Stage"), editable: false },
      { field: 'current_assignee',valueFormatter: (params) => !checkIfArrayIsEmpty(params.value) ? params.value : '-', minWidth: 250, headerName: Helper.getLabel(props.language,'current_assignee',"Current Assignee"), editable: false },
      { field: 'document_number', headerName: Helper.getLabel(props.language, 'source_document_no', 'Source Document Number'), minWidth: 240, editable: false, cellRenderer: (params) => gridLinkValueFormat(params)},
      { field: 'vendor_code', minWidth: 200, headerName: Helper.getLabel(props.language, 'vendor_code', 'Vendor Code'), editable: false },
      { field: 'vendor_name', minWidth: 200, headerName: Helper.getLabel(props.language, 'name', 'Name'), editable: false },
      { field: 'vendorsite_name', headerName: Helper.getLabel(props.language, 'site', 'Site'), editable: false },
      {
        field: 'document_status',
        minWidth: 200,
        headerName: Helper.getLabel(
          props.language,
          'document_status',
          'Document status',
        ),
        editable: false,
        cellRenderer: (params) => getStatusNameById(params.value, documentStatus),
        filter:'agSetColumnFilter',
        filterParams: {
          values: (params) => getOptions(params, documentStatus),
          keyCreator: (params) => optionsKeyCreator(params, 'id'),
          valueFormatter: params => params.value.name,
        }
      },
      { field: 'accounting_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter:'agDateColumnFilter', minWidth: 200, headerName: Helper.getLabel(props.language, 'accounting_date', 'Accounting Date'), editable: false },
      { field: 'document_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter:'agDateColumnFilter', minWidth: 200, inputType: 'datepicker', headerName: Helper.getLabel(props.language, 'document_date', 'Document Date'), editable: false },
    ];

    return (
      <div className='grid_wrapper'> 
        {
          loadingWorkConfirmations ? <SimpleLoading /> : 
          <AgGridNew
            apiUrl={Settings.apiPurchaseOrderUrl + `/sh_headers/${workConfirmationType}`}
            additionalParams={`&dashboard=true&createdBy=${props?.auth?.user?.user_id}`}
            filterAdditionalParams={`&dashboard=true&createdBy=${props?.auth?.user?.user_id}`}
            pagination={true}
            columnDefs={gridColumnHeadersWC} 
            onCellClicked={CellHandler}
            hideDeleteBtn={true}
            hideAddBtn={true}
            height={500}
            gridId={'rs_work_confirmation_listing'}
          />
        }
      </div>
    )
  }

  const BtnClick = (e) => { console.log(e) }

  return ( 
    <CollapseWithButton id='request_collapsible_bar' requestBtnClick={BtnClick} btnLabel={'My New Request'} title={Helper.getLabel(props.language,'request','My Request')} open={true} className="my_request_collapse mb-4 mt-4">
      <Tabs navs={navs} id='request_tabs' showAll={false} scrollOffset="300px">
        <div className='po_module_grid'>{POModule()}</div>
        <div className='po_module_grid'>{ContractModule()}</div> 
        <div className='po_module_grid'>{WorkConfirmationModule()}</div> 
        <div className='pr_module_grid'>{PRModule()}</div>
        <div className='rfx_module_grid'>{RFXModule()}</div> 
      </Tabs>
    </CollapseWithButton>    
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

export default connect(mapStateToProps) (MyRequest);