import React, { Fragment, useEffect, useState } from 'react';
import './WorkflowHeader.css';
import WorkflowHeaderDropdown from './WorkflowHeaderDropdown';
import Button from '../inc/Button';
import WorkFlowOffCanvas from './WorkFlowOffCanvas';
import { connect } from 'react-redux';
import WorkFlowCommonHeaderAPICalls from './WorkflowCommonHeaderAPICalls';
import { checkIfArrayIsEmpty, isEmptyObject } from '../../inc/Validation';

/*
props:

workflowEligible: (response from the API of checkWorkFlowForSpecificModule)

workflowExist: (response from the API of getWorkFlowInstanceExist), 

workflowName: "WorkFlow For PO/Vendor/Item Master/ etc.",

submitWorkflowAction: when workflowEligible true, and workflowExist false, then this function will be required
to be sended,

setEnableFields: when workflowInstanceExist true, and this prop will be enabling the disabled fields
when the user recalls the workflow,
  
(
  When is_buyer flag is found inside the loggedin User's Object, 
  if the createdby id is found to be comparable with the current user's loggedin id, ( only this condition is checked for external/vendor portal users only )
  if the user has Tenant Admin role
)
->
Submit & Recall Action Buttons are Enabled *

*/

let check_dropdown_type = false
const WorkFlowCommonHeader = ({
    workflowEligible, 
    workflowName, 
    setEnableFields,
    workflowInstanceExist, 
    submitWorkflowAction,
    executeLogicsBeforeTakeAction,
    workflowInstanceDetails,
    instance_task_id,
    module_id, 
    setWorkflowTaskActions,
    reloadFunction,
    module_item_id,
    SubmitWorkflowObjDetails,
    source_id,
    source_item_id,
    createdby, 
    user,
    workFlowFunction,
    executeApiOfTaskAction
  }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false) 
  const [taskActions, setTaskActions] = useState([])

  useEffect(() => {
    getTaskActionsData()
    getTaskActionsDataById()
  }, [])
  
  const getTaskActionsData = async () => {
    if(!module_item_id && !module_id) {
      return;
    }
    const apiCalls = new WorkFlowCommonHeaderAPICalls()
    const tasksListInCompleted = await apiCalls.getWorkFlowTaskActionsList(user?.tenant_id, user?.user_id, module_id, module_item_id)

    if(isEmptyObject(tasksListInCompleted)){
      const tasksListCompleted = await apiCalls.getWorkFlowTaskActionsListCompleted(user?.tenant_id, user?.user_id, module_id, module_item_id) 
      setTaskActions(!isEmptyObject(tasksListCompleted) ? tasksListCompleted : []) 
      if(typeof setWorkflowTaskActions === 'function') {
        setWorkflowTaskActions(!isEmptyObject(tasksListCompleted) ? tasksListCompleted : [])
      } 
      check_dropdown_type = true;
      return;
    } 
    setTaskActions(!isEmptyObject(tasksListInCompleted) ? tasksListInCompleted : []) 
    if(typeof setWorkflowTaskActions === 'function') {
      setWorkflowTaskActions(!isEmptyObject(tasksListInCompleted) ? tasksListInCompleted : [])
    }
    check_dropdown_type = false
  } 

  const getTaskActionsDataById = async () => {
    if(!instance_task_id) {
      return;
    }

    const apiCalls = new WorkFlowCommonHeaderAPICalls()
    const tasksListFromid = await apiCalls.getWorkFlowTaskActionsListById(instance_task_id) 
    
    if(!checkIfArrayIsEmpty(tasksListFromid.action_taken)) {  
      setTaskActions(!isEmptyObject(tasksListFromid) ? tasksListFromid : []);
      if(typeof setWorkflowTaskActions === 'function') {
        setWorkflowTaskActions(!isEmptyObject(tasksListFromid) ? tasksListFromid : [])
      }
      check_dropdown_type = true; 
      return;
    }

    setTaskActions(!isEmptyObject(tasksListFromid) ? tasksListFromid : []);
    if(typeof setWorkflowTaskActions === 'function') {
      setWorkflowTaskActions(!isEmptyObject(tasksListFromid) ? tasksListFromid : [])
    }
    check_dropdown_type = false;
  } 

  const SubmitBtnHandler = () => { setSidebarOpen(true) }

  const workflowHeader = ({showDropdown, showSubmitBtn}) => { 
    return (
      <div className='workflow_common_header'>
        <h2>
          {
            (checkIfArrayIsEmpty(taskActions) || 
            (checkIfArrayIsEmpty(taskActions?.actions) && checkIfArrayIsEmpty(taskActions?.action_taken))) &&  
            workflowInstanceExist &&
            !(showSubmitBtn && (user?.usertype === 0 ? (createdby === user?.user_id || user?.roles?.hasOwnProperty('71') || user?.is_buyer) : (createdby === user?.user_id)))
            ? 
              'Under Workflow'
            :
              workflowName
          }
        </h2>

        {showDropdown && (
          <WorkflowHeaderDropdown
            setEnableFields={setEnableFields}
            reloadFunction={reloadFunction}
            instance_task_id={instance_task_id}
            executeLogicsBeforeTakeAction={executeLogicsBeforeTakeAction}
            taskActions={taskActions}
            module_item_id={module_item_id}
            createdby={createdby}
            check_dropdown_type={check_dropdown_type}
            module_id={module_id}
            source_id={source_id}
            source_item_id={source_item_id}
            workFlowFunction={workFlowFunction}
            executeApiOfTaskAction={executeApiOfTaskAction}
          />
        )}

        {
          (showSubmitBtn && (user?.usertype === 0 ? (createdby === user?.user_id || user?.roles?.hasOwnProperty('71') || user?.is_buyer) : (createdby === user?.user_id))) && (
            <Button isActive={false} title={'Submit'} onClick={SubmitBtnHandler} />
          )
        }
      </div> 
    )
  }
  
  return (
    <Fragment> 
      {(!workflowInstanceExist && !workflowEligible) && ''}
      {(!workflowInstanceExist && workflowEligible && !workflowInstanceDetails?.last_instance_status?.hasOwnProperty(3)) && workflowHeader({ showDropdown: false, showSubmitBtn: true })}
      {(!workflowInstanceExist && workflowEligible && workflowInstanceDetails?.last_instance_status?.hasOwnProperty(3)) && workflowHeader({ showDropdown: true, showSubmitBtn: false })}
      {(workflowInstanceExist && workflowEligible) && workflowHeader({ showDropdown: true, showSubmitBtn: false })}
      {(workflowInstanceExist && !workflowEligible) && workflowHeader({ showDropdown: true, showSubmitBtn: false })}

      {sidebarOpen && 
        <WorkFlowOffCanvas
          isOpen={sidebarOpen} 
          type={{
            label: "Submit",
            value: 7
          }} 
          source_id={source_id}
          workFlowActionObject={SubmitWorkflowObjDetails}
          submitWorkflowAction={submitWorkflowAction}
          closeSidebar={setSidebarOpen} 
        />
      }
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {  
    user: state.auth.user 
  }
}

export default connect(mapStateToProps) (WorkFlowCommonHeader);