import React, { useState, useEffect, Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import FormValidator from '../../../inc/FormValidator';
import DropDownInput from '../../Forms/Dropdown';
import Input from '../../Forms/Input';
import Button from '../../inc/Button';
import Collapse from '../../inc/Collapse';
import LinkBtn from '../../inc/LinkBtn';
import NavigationHeder from '../../Navigations/NavigationHeder'; 
import RsWithRouter from '../../inc/RsWithRouter';
import TaxcodeValues from './TaxcodeValues'; 
import Checkbox from '../../Forms/Checkbox';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';
import { useParams } from 'react-router-dom';

const EditTaxCode = ({language}) => {
    const {id} = useParams();

    const wrapperDiv = useRef(null);
    const [height, setHeight] = useState(0);  

    const [SettlementPeriodsList, setSettlementPeriodsList] = useState([]);
    const [taxpostinggroup, setTaxpostinggroup] = useState([]);
    const [taxOrigin, setTaxOrigin] = useState([]);
    const [allTaxCodes, setAllTaxCodes] = useState([]);
    const [allUnits, setAllUnits] = useState([]);
    const [allItemGroups, setAllItemGroups] = useState([]);
    const [taxCodes, setTaxCodes] = useState({});
    const [isLoading, setIsLoading] = useState(false); 

    let validator; 

    const validationConfigure = () => {
        const fieldConfig = [
            { name: 'code', displayName: Helper.getLabel(language, 'code', 'Code'), types: ['Required'] },
            { name: 'name', displayName: Helper.getLabel(language, 'name', 'Name'), types: ['Required'] },
            { name: 'settlementperiodsheader_id', displayName: Helper.getLabel(language, 'settlement_period', 'Settlement Period'), types: ['Required'] },
            { name: 'taxpostinggroup_id', displayName: Helper.getLabel(language, 'taxpostinggroup', 'Tax  Posting Group'), types: ['Required'] },
            { name: 'itemgroup_id', displayName: Helper.getLabel(language, 'item_group', 'Item group'), types: ['Required', 'Number'] }
        ];
        if(!validator) {
            validator = new FormValidator(fieldConfig, language)
        };
    }

    validationConfigure();

    let getTaxCodeByIdCalled = false;
    let isLoadSettlementPeriods = false;
    let isLoadedTaxPostingGroup = false;
    let isLoadedTaxOrigin = false;
    let isLoadTaxCodes = false;
    let isLoadUnits = false;
    let isLoadItemGroups= false;

    useEffect(() => { 
        Helper.resizeWindowHandle(wrapperDiv,setHeight,window);
    }, [wrapperDiv?.current?.clientHeight, isLoading]);

    useEffect(() => {
        loadTaxCode();
        loadSettlementPeriods();
        loadTaxpostingGroup(); 
        loadTaxOrigin();
        loadAllTaxCodes();
        loadAllUnits();
        loadAllItemGroups();
    }, []);

    const loadTaxCode = () => { 
        if (!id || getTaxCodeByIdCalled) return;
        getTaxCodeByIdCalled = true;
 
        setIsLoading(true);

        Api.setUserToken();
        Api.axios().get(`${Settings.apiUrl}/taxcodes/${id}`).then(res => {
            setTaxCodes(res?.data?.data);
            setIsLoading(false);
        }).catch((res) => {
            setIsLoading(false);
            getPopupMessageBasedOnStatus(res) 
        });
    };

    const loadTaxOrigin = () => {
        if (isLoadedTaxOrigin) return;
        isLoadedTaxOrigin = true;

        Api.setUserToken();
        Api.axios().get(`${Settings.apiUrl}/enum/tax_origin`).then(res => {
            setTaxOrigin(res?.data?.status === 'success' ? res?.data?.data?.map(item => ({
                label: item.name,
                value: item.id,
            })) : []);
        });
    };

    const loadAllTaxCodes = () => {
        if (isLoadTaxCodes) return;
        isLoadTaxCodes = true;

        Api.setUserToken();
        Api.axios().get(`${Settings.apiUrl}/taxcodes?for_salestax_on_salestax=True`).then(res => {
            setAllTaxCodes(res?.data?.status === 'success' ? res?.data?.data?.map(item => ({
                label: `${item.code} - ${item.name}`,
                value: item.taxcodes_id,
            })) : []);
        });
    };

    const loadAllUnits = () => {
        if (isLoadUnits) return;
        isLoadUnits = true;

        Api.setUserToken();
        Api.axios().get(`${Settings.apiUrl}/unit`).then(res => {
            setAllUnits(res?.data?.status === 'success' ? res?.data?.data?.map(item => ({
                label: `${item.code} - ${item.description}`,
                value: item.unit_id,
            })) : []);
        });
    };

    const loadAllItemGroups = () => {
        if (isLoadItemGroups) return;
        isLoadItemGroups = true;

        Api.setUserToken();
        Api.axios().get(`${Settings.apiItemUrl}/item_group`).then(res => {
            setAllItemGroups(res?.data?.status === 'success' ? res?.data?.data?.map(item => ({
                label: item.name,
                value: item.itemgroup_id,
            })) : []);
        });
    };

    const loadSettlementPeriods = () => {
        if (isLoadSettlementPeriods) return;
        isLoadSettlementPeriods = true;

        Api.setUserToken();
        Api.axios().get(`${Settings.apiUrl}/settlementheader`).then(res => {
            setSettlementPeriodsList(res?.data?.status === 'success' ? res?.data?.data?.map(settlement => ({
                label: settlement.code,
                value: settlement.settlementperiodheader_id
            })) : []);
        });
    };

    const loadTaxpostingGroup = () => {
        if (isLoadedTaxPostingGroup) return;
        isLoadedTaxPostingGroup = true;
         
        Api.setUserToken();
        Api.axios().get(`${Settings.apiUrl}/taxpostinggroup`).then(res => {
            setTaxpostinggroup(res?.data?.status === 'success' ? res?.data?.data?.map(taxPosting => ({
                label: taxPosting.name,
                value: taxPosting.taxpostinggroup_id
            })) : []);
        });
    };

    const onInputHandler = (e) => {
        setTaxCodes({
            ...taxCodes,
            [e.target.name]: e.target.value
        }); 
    };

    const onDropDownChange = (event) => {
        setTaxCodes({
            ...taxCodes,
            [event.target.name]: event.target.value
        });
    };

    const onCheckboxChange = (name, value) => {
        setTaxCodes({
            ...taxCodes,
            [name]: value === 'on' ? true : false
        });
    };

    const onSaveHandler = (e) => {
        if (!validator.isValid(taxCodes)) {
            validator.displayMessage();
            return;
        }

        if(canEditUnit() && (!taxCodes?.unit_id || taxCodes?.unit_id === null || taxCodes?.unit_id === "")) {
            Helper.alert('Unit field is required', 'error');
            return;
        }
  
        if(canEditSalesTax() && (!taxCodes?.salestax_on_salestax || taxCodes?.salestax_on_salestax === null || taxCodes?.salestax_on_salestax === "")) {
            Helper.alert('Salestax on salestax field is required', 'error');
            return;
        }

        setIsLoading(true);

        const taxCodesData = {
            ...taxCodes,
            tax_origin: Helper.getOnlyInt(taxCodes?.tax_origin),
            salestax_on_salestax: Helper.getOnlyInt(taxCodes?.salestax_on_salestax, true),
            unit_id: Helper.getOnlyInt(taxCodes?.unit_id, true),
            itemgroup_id: Helper.getOnlyInt(taxCodes?.itemgroup_id, true),
            price_include_tax: taxCodes?.price_include_tax === true || taxCodes?.price_include_tax === 'yes',
        };

        Api.axios().put(`${Settings.apiUrl}/taxcodes/${id}`, taxCodesData).then(res => {
            Helper.alert(res?.data?.message); 
            loadTaxCode();
        }).catch(res => {
            setIsLoading(false);
            getPopupMessageBasedOnStatus(res);
        });
    };

    const displayTaxcodesValue = () => {
        if (isLoading || !id) return null;
        return <TaxcodeValues taxcode_id={id} />;
    };

    const canEditSalesTax = () => { 
        return taxCodes?.tax_origin && taxCodes?.tax_origin == '2';
    };

    const canEditUnit = () => { 
        return taxCodes?.tax_origin && taxCodes?.tax_origin == '3';
    };
 
    return (
        <Fragment>
            { isLoading ? <OverlayLoader isPopupInTab={true} /> : null }
            <div className='container-fluid'>
                <div ref={wrapperDiv}>
                    <NavigationHeder hideMoreBtn={true} backUrl="/tax/codes" title={Helper.getLabel(language, 'tax_codes', 'Tax Codes')}>
                        <LinkBtn to="/tax/codes" isActive={false} title={Helper.getLabel(language, 'close', 'Close')} />
                        <Button onClick={onSaveHandler} isActive={false} className="rfq_save_btn" title={Helper.getLabel(language, 'save', 'Save')} />
                    </NavigationHeder>
                </div>

                <div
                    className="common_scrollbar_styles"
                    style={{ height: `${height - 30}px`, overflowY: "auto" }}
                >
                    <Collapse title={Helper.getLabel(language, 'general', 'General')} open={true}>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <Input maxLength={10} name="code" value={taxCodes?.code} label={Helper.getLabel(language, 'code', 'Code')} required={true} onChange={onInputHandler} />
                                </div>
                                <div className='col-sm-4'>
                                    <Input maxLength={80} name="name" value={taxCodes?.name} label={Helper.getLabel(language, 'name', 'Name')} required={true} onChange={onInputHandler} />
                                </div>
                                <div className='col-sm-4'>
                                    <DropDownInput
                                        required={true}
                                        name="settlementperiodsheader_id"
                                        id="settlementperiodsheader_id"
                                        placeHolder = 'Select settlement periods'
                                        label={Helper.getLabel(language, 'settlement_period', 'Settlement period')}
                                        options={SettlementPeriodsList}
                                        value={taxCodes?.settlementperiodsheader_id}
                                        onChange={onDropDownChange} 
                                        reRenderRequired={true}
                                    />
                                </div>
                                <div className='col-sm-4'>
                                    <DropDownInput
                                        required={true}
                                        name="taxpostinggroup_id"
                                        id="taxpostinggroup_id"
                                        placeHolder = 'Select tax posting group'
                                        label={Helper.getLabel(language, 'taxpostinggroup', 'Tax posting group')}
                                        options={taxpostinggroup}
                                        value={taxCodes?.taxpostinggroup_id}
                                        onChange={onDropDownChange} 
                                        reRenderRequired={true}
                                    />
                                </div>
                                <div className='col-sm-4'>
                                    <DropDownInput
                                        name="itemgroup_id"
                                        id="itemgroup_id"
                                        placeHolder = 'Select item group'
                                        required={true}
                                        label={Helper.getLabel(language, 'item_group', 'Item group')}
                                        options={allItemGroups}
                                        value={taxCodes?.itemgroup_id}
                                        onChange={onDropDownChange} 
                                        reRenderRequired={true}
                                    />
                                </div>
                                <div className='col-sm-4'>
                                    <DropDownInput
                                        name="tax_origin"
                                        id="tax_origin"
                                        placeHolder = 'Select tax origin'
                                        label={Helper.getLabel(language, 'tax_origin', 'Tax origin')}
                                        options={taxOrigin}
                                        value={taxCodes?.tax_origin}
                                        onChange={onDropDownChange}
                                        reRenderRequired={true}
                                    />
                                </div>
                                <div className='col-sm-4'>
                                    <DropDownInput placeHolder = 'Select salestax on salestax' reRenderRequired={true} name="salestax_on_salestax" isDisable={!canEditSalesTax()} required={ canEditSalesTax() } value={ canEditSalesTax() ? taxCodes?.salestax_on_salestax : '' } id="salestax_on_salestax" options={ allTaxCodes } label={Helper.getLabel(language,'salestax_on_salestax','Salestax on salestax')}  onChange={  onDropDownChange  } />
                                </div>
                                <div className='col-sm-4'>
                                    <DropDownInput placeHolder = 'Select unit' reRenderRequired={true} name="unit_id" value={ canEditUnit ? taxCodes?.unit_id : '' } isDisable={!canEditUnit()} required={ canEditUnit() } id="unit_id" options={ allUnits } label={Helper.getLabel(language,'unit_id','Unit')}  onChange={  onDropDownChange  }/>
                                </div>  
                                <div className='col-sm-4'>
                                    <Checkbox
                                        name="price_include_tax"
                                        label={Helper.getLabel(language, 'price_include_tax', 'Price includes tax')}
                                        isChecked={taxCodes?.price_include_tax}
                                        onChange={onCheckboxChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </Collapse>
                    {displayTaxcodesValue()}
                </div>
            </div>
        </Fragment>
    );
};

export default connect(state => ({
    language: state.language,
    auth: state.auth
}))(RsWithRouter(EditTaxCode)); 