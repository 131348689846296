import React, {useState, useEffect, Fragment} from 'react'
import { connect } from 'react-redux'
import Api from '../../../inc/Api'
import FormValidator from '../../../inc/FormValidator'
import Gui_id_list from '../../../inc/Gui_id_list'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import DropDownInput from '../../Forms/Dropdown'
import $ from 'jquery'
import Input from '../../Forms/Input'
import Button from '../../inc/Button'
import Collapse from '../../inc/Collapse'
import axios from 'axios'
import Popup from '../../Popup/Popup'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import Checkbox from '../../Forms/Checkbox'
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import OverlayLoader from "../../PurchaseRequisition/common/OverlayLoader";

// Universal Component /--
// Master Users Component -- Vendor's User Association's Inc.
// --- Compiled For VNVendors ---

let cancelTokenSource = axios.CancelToken.source();
const UserPopup = ({
  language, 
  vendor_id, 
  dataExportable, 
  setUsersGrid, 
  setUsersGridData,
  onPopupClose,
  editData,
  gridApi,
  editable
}) => {

  // Component States

  const [userType, setUserType] = useState([])
  const [languageId, setLanguage] = useState([])
  const [saveBtnApi, setSaveBtnApi] = useState(false)

  // ---- 

  // User Data From Component ---
  
  let user_data = {
    "first_name": editData.first_name,
    "last_name": editData.last_name,
    "middle_name": editData.middle_name,
    "user_name": editData.user_name ,
    "usertype": editData.usertype,
    "enable": Object.keys(editData).length === 0 ? true : editData.enable, 
    "user_id": editData.user_id,
    "language_id": editData.language_id,
    "uniqueid": editData.uniqueid  // unique identifier for avoiding data duplication
  }
  
  // ----- 

  // COMPONENTS RENDER's

  useEffect(() => {
    UserTypeList()
    UserLanguageList() 
  }, [])
  
  let validator ;  

  const validationConfigure = () => {
    let fieldConfig = [
      {name:'first_name', displayName:Helper.getLabel(language,'first_name','First name'),types:['Required']},   
      {name:'last_name', displayName:Helper.getLabel(language,'last_name','Last name'),types:['Required']},    
      {name:'user_name', displayName:Helper.getLabel(language,'user_name','User email'),types:['Required']},
    ] 
    if(!validator) {
      validator = new FormValidator(fieldConfig,language)
    }
  }

  validationConfigure()
 
  const UserTypeList = () => {
    let api = Api
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/enum/usertype`,{}).then(function(res){
      if(res.data.status==="success"){
        setUserType(res?.data?.data?.map((item) => {
          return {
            value: item.id,
            label: item.name
          }
        })) 
      }
    })
  }
  
  const UserLanguageList = () => {
    let api = Api
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/language`,{}).then(function(res){
      if(res.data.status==="success"){  
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
         UPDATED_STATE.push({
          value: element.language_id,
          label: element.language
        })
      })
      setLanguage(UPDATED_STATE)
    }
    })
  }

  // ---- 

  // Dropdown Handles --

  const UserFirstNameHandler = (event) => {
    let first_name = event.target.value
    setUsersGridData((prevState) => {
      return {
        ...prevState, 
        first_name: first_name
      }
    }) 
  }
  
  const UserMiddleNameHandler = (event) => {
    let middle_name = event.target.value
    setUsersGridData((prevState) => {
      return {
        ...prevState, 
        middle_name: middle_name
      }
    }) 
  }

  const EnableHandler = (name, value) => { 
    let enableVal = value == "on";
    setUsersGridData((prevState) => {
      return {
        ...prevState, 
        enable: enableVal
      }
    })
  }
  
  const UserNameHandler = (event) => {
    let user_name = event.target.value
    setUsersGridData((prevState) => {
      return {
        ...prevState, 
        user_name: user_name
      }
    }) 
  }
  
  const LastNameHandler = (event) => {
    let last_name = event.target.value
    setUsersGridData((prevState) => {
      return {
        ...prevState, 
        last_name: last_name
      }
    }) 
  }

  const UserLanguageHandler = (event) => {
    let lang = event.target.value
    setUsersGridData((prevState) => {
      return {
        ...prevState, 
        language_id: lang
      }
    }) 
  }
 
  const onPopupCloseHandler = () => { 
    if(onPopupClose){
      onPopupClose();
    }
    $('html').removeClass('rs_popup_open');
  }
 
  const CreateNewVendorUser = async () => {
    try {
      let api = Api;
      api.setUserToken();
  
      const res = await api.axios().post(Settings.apiUrl + `/user`, {
        "first_name": user_data.first_name,
        "last_name": user_data.last_name,
        "middle_name": user_data.middle_name,
        "user_name": user_data.user_name,
        "enable": user_data.enable == true || user_data.enable == "yes" || user_data.enable == undefined ? true : user_data.enable,
        "SID": null,
        "network_domain": null,
        "network_alias": null,
        "language_id": languageId?.find(val => val.label == "English")?.value || null,
        "usertype": userType ? userType?.find(val => val.label == "External")?.value : null,
        "vendor_id": parseInt(vendor_id)
      },
      { cancelToken: cancelTokenSource.token });
  
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.api?.refreshServerSide({ purge: true });
          gridApi?.api?.deselectAll();
        }, 100);
        Helper.alert(res.data.message);
        onPopupCloseHandler();
      }
    } catch (res) {
      getPopupMessageBasedOnStatus(res);
      setTimeout(() => {
        gridApi?.api?.refreshServerSide({ purge: true });
        gridApi?.api?.deselectAll();
      }, 100);
    }
  };

  const UpdateVendorUser = async () => {
    try {
      let api = Api;
      api.setUserToken();
  
      const res = await api.axios().put(Settings.apiUrl + `/user/${user_data.user_id}`, {
        "first_name": user_data.first_name,
        "last_name": user_data.last_name,
        "middle_name": user_data.middle_name,
        "enable": user_data.enable == true || user_data.enable == "yes" || user_data.enable == undefined ? true : false,
        "SID": null,
        "network_domain": null,
        "network_alias": null,
        "language_id": languageId?.find(val => val.label == "English")?.value || null
      },
      { cancelToken: cancelTokenSource.token });

      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.api?.refreshServerSide({ purge: true });
          gridApi?.api?.deselectAll();
        }, 100);
        Helper.alert(res.data.message);
        onPopupCloseHandler();
      }
    } catch (res) {
      getPopupMessageBasedOnStatus(res);
      setTimeout(() => {
        gridApi?.api?.refreshServerSide({ purge: true });
        gridApi?.api?.deselectAll();
      }, 100);
    }
  };

  const onSaveHandler = () => {
    let validData = {
      ...user_data
    }

    if(!validator.isValid(validData)){
      validator.displayMessage();
      validator.reset() 
      return;
    }

    if(/\d/.test(user_data.first_name)){
      Helper.alert('User First Name cannot be in numbers/numeric!', 'error')
      return;
    }
 
    if (/\d/.test(user_data.middle_name)) {
      Helper.alert('User Middle Name cannot contain numbers/numeric!', 'error');
      return;
    }

    if(/\d/.test(user_data.last_name)){
      Helper.alert('User Last Name cannot be in numbers/numeric!', 'error')
      return;
    } 
  
    if(!editData.user_id && vendor_id) {
      Helper.createDebouncedAPIFunction(
        [CreateNewVendorUser],
        setSaveBtnApi,
        cancelTokenSource
      )();
    }
    
    if(editData.user_id && editable && vendor_id && dataExportable == false) {
      Helper.createDebouncedAPIFunction(
        [UpdateVendorUser],
        setSaveBtnApi,
        cancelTokenSource
      )();
    } 
  }
 
  const UserPopupModule = () => {
    return (
    <Fragment>
      <div className='d-flex justify-content-end mb-4'>
        <Button className="rfq_save_btn" title={Helper.getLabel(language,'save_and_close','Save & Close')} onClick={onSaveHandler} isActive={false} /> 
      </div>
      <Collapse title={ Helper.getLabel(language,'vendor_user_details' , 'Vendor User Details') } open={true}>
        <div className='row'>
          <div className='col-xs-12 col-sm-3'> 
            <Input maxLength={80} required={true} disable={user_data.user_id ? true : false} label={Helper.getLabel(language,'user_email',"User email")} type='text' value={user_data.user_name} onChange={UserNameHandler} />
          </div>  
          <div className='col-xs-12 col-sm-3'> 
            <Input maxLength={50} required={true} label={Helper.getLabel(language,'first_name',"First name")} type='text' onChange={UserFirstNameHandler} value={user_data.first_name} />
          </div> 
          <div className='col-xs-12 col-sm-3'> 
            <Input maxLength={50} label={Helper.getLabel(language,'middle_name',"Middle name")} type='text' value={user_data.middle_name} onChange={UserMiddleNameHandler} />
          </div> 
          <div className='col-xs-12 col-sm-3'> 
            <Input maxLength={50} required={true} label={Helper.getLabel(language,'last_name',"Last name")} type='text' value={user_data.last_name} onChange={LastNameHandler} />
          </div>  
          <div className='col-xs-12 col-sm-3'>
            <DropDownInput label={Helper.getLabel(language,'user_language_id',"Language")} isDisable={true} required={true} options={languageId} onChange={UserLanguageHandler} value={languageId?.find(val => val.label == "English")?.value || null} id='language_id' />
          </div> 
          <div className='col-xs-12 col-sm-3'> 
            <Checkbox label={Helper.getLabel(language,'enable', 'Enable')} required={true} type="checkbox" className='is_default_bank me-2' isChecked= { user_data.enable == undefined ? !user_data.enable : user_data.enable} onChange={EnableHandler} />
          </div>
        </div>
      </Collapse>
    </Fragment>
    )
  }

  // ----

  return (
    <div className='users_popup_close'>
      { saveBtnApi ? <OverlayLoader isPopupInTab={true} /> : null } 
      <Popup onClose={onPopupCloseHandler} autoOpen={true} width="1350px">
        { UserPopupModule() }
      </Popup>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.masterData.vendors
}

export default connect(mapStateToProps) (ApplySecurityRoles(UserPopup, SecurityOptions))