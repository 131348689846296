import React, { Fragment, useCallback, useEffect, useState } from 'react';
import $ from 'jquery';
import Popup from '../../../Popup/Popup';
import SimpleLoading from '../../../Loading/SimpleLoading';
import Collapse from '../../../inc/Collapse';
import Button from '../../../inc/Button';
import Helper from '../../../../inc/Helper';
import Input from '../../../Forms/Input';
import DropDownInput from '../../../Forms/Dropdown';
import { connect } from 'react-redux';
import DetailViewComplianceTemplatesWithLines from './DetailViewComplianceTemplatesWithLines';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings'; 
import * as XLSX from 'xlsx';
import { checkIfArrayIsEmpty, fixColsData, getHeaderRowData, getPopupMessageBasedOnStatus, updateExcelDataProps, validateHeaderObjectsFromExcel } from '../../../../inc/Validation';
import AgGridNew from '../../../grid/ag/ag-grid-new';
import { getNewValue } from '../../../grid/ag/commonFunctions/GridCommonFunctions';
 
let currentTemplateLinesGridObject = null;
let propsFunctionsExecute = null;  

const CommonCompliancePopup = ({
    setClosePopup, 
    rfq_id, 
    newCategory=false, 
    newCompliance=false, 
    addNewTemplate=false, 
    importTemplate=false, 
    importExcelTemplate=false,
    reloadParentGrid,
    setDisableAddBtn,
    defaultTemplateId=null, 
    language
}) => { 
    const [loadingImportTemplateModule, setLoadingImportTemplateModule] = useState(true);
    const [loadingImportTemplateLinesModule, setLoadingImportTemplateLinesModule] = useState(true);
    const [loadingTemplateLines, setLoadingTemplateLines] = useState(false);
 
    const [complianceTemplatesLines, setComplianceTemplatesLines] = useState([]);
    const [complianceTemplates, setComplianceTemplates] = useState([]);
    const [complianceResponseHeader, setComplianceResponseHeader] = useState([]);
    const [complianceTemplatesList, setComplianceTemplatesList] = useState([]);
    const [complianceResponseList, setComplianceResponseList] = useState([]);
 
    const [complianceTemplateLinesId, setComplianceTemplateLinesId] = useState('');
    const [currentComplianceTemplate, setCurrentComplianceTemplate] = useState('');
    const [currentComplianceResponseHeader, setCurrentComplianceResponseHeader] = useState('');
    
    let complianceTemplateCalled = false;
    let complianceResponseHeaderCalled = false;

    useEffect(() => {
        getAllComplianceTemplates()
        getAllComplianceResponseHeader()
    }, [])

    const getAllComplianceTemplates = () => {
        if(complianceTemplateCalled) return;
 
        setLoadingImportTemplateModule(true);
        let api = Api
        complianceTemplateCalled = true;
        api.setUserToken()
        api.axios().get(Settings.apiPurchaseRequisitionUrl+'/compliance_template',{}).then(function(res){
            if(res.data.status==='success'){
                let Compliance_Templates = []
                res.data.data.forEach((item) => {
                    Compliance_Templates.push({
                        label: item.name,
                        value: item.template_id
                    })
                })
                setComplianceTemplates(Compliance_Templates);
                setComplianceTemplatesList(res.data.data);
                setLoadingImportTemplateModule(false);
            } 
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res) 
            setLoadingImportTemplateModule(false);
        })
    }
    
    const getAllComplianceResponseHeader = () => {
        if(complianceResponseHeaderCalled) return;
 
        setLoadingImportTemplateLinesModule(true);
        let api = Api
        complianceResponseHeaderCalled = true;
        api.setUserToken()
        api.axios().get(Settings.apiPurchaseRequisitionUrl+'/complianceresponseheader',{}).then(function(res){
            if(res.data.status==='success'){
                let Compliance_Response_Header = []
                res.data.data.forEach((item) => {
                    Compliance_Response_Header.push({
                        label: item.compliance_name,
                        value: item.complianceheader_id
                    })
                })
                setComplianceResponseHeader(Compliance_Response_Header);
                setComplianceResponseList(res.data.data);
                setLoadingImportTemplateLinesModule(false);
            } 
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res) 
            setLoadingImportTemplateLinesModule(false);
        })
    }
 
    // Component-Related Functions,
    const onPopupCloseClickHandler = () => {
        $('.common_compliance_popup .rs_popup_close').trigger('click');
        setClosePopup((prevState) => {
            return {
                ...prevState,
                openPopup: false,
                addNewTemplate: false,
                importTemplate: false,
                newCategory: false,
                newCompliance: false,
                importExcelTemplate: false,
                details: {}
            }
        })
    }

    const PostComplianceTemplateLines = (currentSelectedNodes, template_id) => {
        let api = Api;
        api.setUserToken();
  
        if(importTemplate) { 
            const apiCalls = currentSelectedNodes.map((node) => {
                let item = node?.data;
                return api.axios().post(Settings.apiPurchaseRequisitionUrl + `/compliance_template_lines/${template_id}?for_transaction=true`, {
                    category: item?.category,
                    subcategory: item?.subcategory,
                    compliance_description: item?.description
                });
            });

            Promise.all(apiCalls)
                .then((responses) => { 
                    const allSuccess = responses.every((res) => res.data.status === 'success');
        
                    if (allSuccess) { 
                        currentTemplateLinesGridObject?.api?.refreshServerSide({ purge: true })
                        currentTemplateLinesGridObject?.api?.deselectAll();
                        Helper.alert('Lines Added Successfully.');
                    };
                })
                .catch((error) => { Helper.alert(`Apologies, few Lines couldn't Added!`, 'error') })
        }

        if (importExcelTemplate) {
            const apiCalls = currentSelectedNodes.map((node) => {
                let item = node;
                return api.axios().post(Settings.apiPurchaseRequisitionUrl + `/compliance_template_lines/${template_id}?for_transaction=true`, {
                    category: item?.category,
                    subcategory: item?.subcategory,
                    compliance_description: item?.description
                });
            });
        
            Promise.all(apiCalls)
                .then((responses) => { 
                    const allSuccess = responses.every((res) => res.data.status === 'success');
        
                    if (allSuccess) { 
                        currentTemplateLinesGridObject?.api?.refreshServerSide({ purge: true })
                        currentTemplateLinesGridObject?.api?.deselectAll();
                        Helper.alert('Lines Added Successfully.') 
                    }  
                })
                .catch((error) => { Helper.alert(`Apologies, few Lines couldn't Added!`, 'error') })
        } 
        setTimeout(() => {
            onPopupCloseClickHandler() 
            reloadParentGrid() 
        }, 300) 
    }

    const PostComplianceTemplateforTransactionalLevel = (templateObj, linesArray) => { 
        if(!templateObj && !templateObj.name) return;

        if(defaultTemplateId) {
            PostComplianceTemplateLines(linesArray, defaultTemplateId);
            return;
        }

        let api = Api;
        api.setUserToken();

        let complianceName = 
        importExcelTemplate ? templateObj?.compliance_name : templateObj?.name;

        api.axios().post(Settings.apiPurchaseRequisitionUrl+`/compliance_template?for_transaction=true`,{
            name: complianceName,
            compliance_header_id: templateObj?.complianceheader_id,
            rfq_id: rfq_id
        }).then(function(res){
            if(res.data.status==='success'){
                Helper.alert(res.data.message);
                PostComplianceTemplateLines(linesArray, res.data.template_id)
            }
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res) 
        })
    }

    const ImportComplianceTemplate = () => { 
        if(!currentTemplateLinesGridObject) return;
        
        if(importTemplate) {
            if(currentComplianceTemplate == "" || currentComplianceTemplate == null|| !currentComplianceTemplate ) {
                Helper.alert('Please select the compliance template first, then try to import!', 'error');
                return; 
            };

            let currentSelectedNodes = currentTemplateLinesGridObject.api.getSelectedNodes();
            if(checkIfArrayIsEmpty(currentSelectedNodes)){
                Helper.alert('Please select the rows, which you want to import!', 'error');
                return; 
            }
            
            let complianceTemplateObject = complianceTemplatesList?.find(val => val.template_id == currentComplianceTemplate);
            PostComplianceTemplateforTransactionalLevel(complianceTemplateObject, currentSelectedNodes)
        }

        if(importExcelTemplate) {
            if(currentComplianceResponseHeader == "" || currentComplianceResponseHeader == null|| !currentComplianceResponseHeader ) {
                Helper.alert('Please select the response header first, then try to import!', 'error');
                return; 
            };

            let excelLinesArray = [];
            currentTemplateLinesGridObject.api.forEachNode(node => excelLinesArray.push(node.data));

            if(checkIfArrayIsEmpty(excelLinesArray)){
                Helper.alert('Please import some lines from excel, then try again!', 'error');
                return; 
            }
  
            let complianceResponseObject = complianceResponseList?.find(val => val.complianceheader_id == currentComplianceResponseHeader);
            PostComplianceTemplateforTransactionalLevel(complianceResponseObject, excelLinesArray);
        }
    }

    const onDeleteComplianceExcelLines = async (element) => {  
        if(element?.data) {
            setLoadingImportTemplateLinesModule(true);
        
            const updatedLines = complianceTemplatesLines?.filter(item => item.category !== element.data.category);
            setComplianceTemplatesLines(updatedLines);
            
            setTimeout(() => {
                setLoadingImportTemplateLinesModule(false);
            }, 200);
        } 
    };

    const takePropsTransactionalLevel = props => propsFunctionsExecute = props;

    const onSaveClickHandler = () => {
        if(newCompliance) {
            if (!propsFunctionsExecute) return;
            propsFunctionsExecute();
        }
    }

    // modules: Templates/Headings
    const TemplatesCreationPopupModule = () => {
        return ( 
            <div className='row'>
                <div className='col-lg-12'>
                    <Input label={Helper.getLabel(language,'template_name',"Template name")} type='text' onChange={() => {}} value={''} />
                </div> 
            </div> 
        )
    };

    const displayHeadLineRFQ = () => { return (<h1 className='rfq_head_line_txt'>This template refers to RFQ only</h1>) }

    const TopHeadLine = () => {
        return (
            <div className='row'>
                { !newCompliance && 
                    <div className='col-12 d-flex justify-content-start align-items-center'>
                        <h2 className='compliance_common_heading'>
                            {addNewTemplate && 'Create new template'} 
                            {importTemplate && 'Import compliance template'}
                            {importExcelTemplate && 'Import compliance template lines from excel'}
                            {newCategory && 'Add new category'} 
                        </h2>
                    </div>
                }
                { newCompliance && 
                    <> 
                        <div className='col-4 d-flex justify-content-start align-items-center'>
                            <h2 className='compliance_common_heading_black'>
                                {newCompliance && 'Add new compliance'} 
                            </h2>
                        </div>
                        <div className='col-4 d-flex justify-content-center align-items-center'>{displayHeadLineRFQ()}</div>
                        <div className='col-4 d-flex justify-content-end align-items-center'>
                            {SaveButtonActionTrigger()}
                        </div>
                    </>
                }
            </div>
        )
    };

    const SaveButtonActionTrigger = () => { 
        return (
            <div className='row'>
                <div className='col-12 d-flex justify-content-end align-items-center'>
                    <Button  onClick={onSaveClickHandler} className='btn_blue_templates_saving' title={Helper.getLabel(language,`${!importTemplate ? 'save_close' : 'save'}`, 'Save')} />
                </div>
            </div>
        )
    };

    const TemplateModuleWrapper = () => {
        return(
            <Fragment>  
                <div className='templates_creation_wrapper'>
                    { ( TemplatesCreationPopupModule() )}
                </div>
                <div className='templates_next_btn'>{ SaveButtonActionTrigger() }</div>
            </Fragment>
        )
    };

    const filterByComplianceTemplate = () => {
        const onComplianceTemplateValueChange = (evt) => {
            setCurrentComplianceTemplate(evt.target.value);

            if(!evt.target.value) return;
            setLoadingTemplateLines(true);
            setComplianceTemplateLinesId(evt.target.value);
            setTimeout(() => {
                setLoadingTemplateLines(false);
            }, 200)
        };
        return (
            <div className='row'> 
                <div className='col-3'>
                    <DropDownInput label={Helper.getLabel(language,'compliance_templates',"Compliance templates")} options={complianceTemplates} id='compliance_templates_id' required={true} onChange={onComplianceTemplateValueChange} value={currentComplianceTemplate} />  
                </div> 
            </div>
        )
    }

    const filterByComplianceResponseHeader = () => {
        const onComplianceResponseHeaderValueChange = (evt) => { setCurrentComplianceResponseHeader(evt.target.value) };

        return (
            <div className='row'> 
                <div className='col-3'>
                    <DropDownInput label={Helper.getLabel(language,'compliance_response_header',"Compliance response header")} options={complianceResponseHeader} id='compliance_response_header_id' required={true} onChange={onComplianceResponseHeaderValueChange} value={currentComplianceResponseHeader} />  
                </div> 
            </div>
        )
    }

    const actionBtns = () => {
        return (
            <div className='d-flex justify-content-end align-items-center mt-5'>
                <button className='me-2 cancel_action_btn' onClick={onPopupCloseClickHandler}>Close</button>
                <button className='submit_action_btn' onClick={ImportComplianceTemplate}>Import</button>
            </div>
        )
    }
 
    const ComplianceTemplateLines = () => {
        const gridColumnHeadersLines = [ 
            {field:'category', editable:false, headerName:Helper.getLabel(language,'category',"Category*")},
            {field:'subcategory', headerName:Helper.getLabel(language,'subcategory',"Sub category"), editable:false},
            {field:'description', headerName:Helper.getLabel(language,'description',"Description"), editable:false}
        ]
  
        const handleFetchData = (response) => { 
            return {
                rowData: response?.data?.data?.map((item) => ({
                    ...item,
                    gridId: `${Date.now()}_${getNewValue()}`,
                    description: item?.compliance_description
                })),
                rowCount: response?.data?.data?.length
            }
        };

        let dataSource = {
            getRows: async function (params) {
                params?.success({
                    rowData: complianceTemplatesLines?.map((item) => ({
                        ...item,
                        gridId: `${Date.now()}_${getNewValue()}`
                    })),
                    rowCount: complianceTemplatesLines?.length
                })
            },
        };

        const onComplianceTemplateGridReady = (params) => {
            if(params) {
                currentTemplateLinesGridObject = params
                if(importExcelTemplate) {
                    setTimeout(()=>{ 
                      params?.api?.setGridOption('serverSideDatasource', dataSource);
                    }, 200)
                }
            }
        }

        const onFileTypeValueChange = (e) => {
            const fileList = e.target.files;
            if (fileList.length === 0) return;
        
            const f = fileList[0];

            if (!f.name.endsWith('.xlsx')) {
                Helper.alert("Invalid file format. Only Excel files (.xlsx) are allowed.", 'error');
                return;
            }
        
            setLoadingImportTemplateLinesModule(true);

            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const fixedData = fixColsData(data);
                const workbook = XLSX.read(btoa(fixedData), { type: 'base64' });
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                
                let headerObjects = getHeaderRowData(worksheet)
                let excelData = updateExcelDataProps(XLSX.utils.sheet_to_json(worksheet))
                let validateHeaderObjects = validateHeaderObjectsFromExcel(headerObjects)
            
                if (validateHeaderObjects && importExcelTemplate) {
                    setComplianceTemplatesLines(excelData) 
                    setTimeout(() => {
                        setLoadingImportTemplateLinesModule(false);
                    }, 200);
                }
            };
            reader.readAsArrayBuffer(f);
        }

        const ExportTemplateLinesSampleFile = () => { 
            const filePath = '/excel/Compliance_Template_Lines_Sample.xlsx';
            const a = document.createElement('a');
            a.href = filePath;
            a.download = 'Compliance_Template_Lines_Sample.xlsx';  
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }

        const ExcelImportActionButton = () => {
            if(importTemplate) return null;
            return (
                <Fragment>
                    <div className='compliance_action_excel_btn'>  
                        <img className='img-fluid' src="/images/icons/excel-logo.svg" /> 
                        <input type="file" onChange={onFileTypeValueChange} />
                    </div>

                    <div className='import_sample_template'>
                        <button onClick={ExportTemplateLinesSampleFile}><img className='img-fluid' src="/images/icons/excel-logo.svg" />  Export sample excel file</button>
                    </div>
                </Fragment>
            )
        }

        return (
            <Collapse open={true} title={Helper.getLabel(language,'compliance_template_lines', "Compliance Template Lines")}>
                {loadingImportTemplateLinesModule ? <SimpleLoading /> : 
                    <AgGridNew
                        apiUrl={!importExcelTemplate ? (complianceTemplateLinesId?Settings.apiPurchaseRequisitionUrl+`/compliance_template_lines/${complianceTemplateLinesId}`:null) : null}
                        afterDeleteBtns={<ExcelImportActionButton />}
                        hideDeleteBtn={!importExcelTemplate}
                        dissableCheckboxSelection={true}
                        fetchData={!importExcelTemplate ? handleFetchData : null}
                        hideAddBtn={true}
                        columnDefs={gridColumnHeadersLines}
                        rowType={importExcelTemplate ? 'single' : 'multiple'}
                        handleDeleteSelectedRows={importExcelTemplate ? onDeleteComplianceExcelLines : null}
                        onGridReady={onComplianceTemplateGridReady}
                        height={500}
                        allowDeleteProps={true}
                        gridId={'import_template_lines_rfq'}
                        pagination={false}
                    /> 
                }
            </Collapse>
        )
    }

    const ImportTemplateModuleWrapper = () => {
        return (
            <Fragment>
                {loadingImportTemplateModule ? <SimpleLoading /> : 
                    <div className='container-fluid p-0'>
                        { filterByComplianceTemplate() }
                        { loadingTemplateLines ? <SimpleLoading /> : ComplianceTemplateLines() }
                        { actionBtns() }
                    </div>
                } 
            </Fragment>
        )
    };

    const ImportTemplateLinesFromExcelModuleWrapper = () => {
        return (
            <Fragment>
                { loadingImportTemplateLinesModule ? <SimpleLoading /> : 
                    <div className='container-fluid p-0'>
                        { filterByComplianceResponseHeader() }
                        { ComplianceTemplateLines() }
                        { actionBtns() }
                    </div>
                }
            </Fragment>
        )
    };

    const AddNewCategoryModuleWrapper = () => {
        return (
            <Fragment>
                <div className='row'>
                    <div className='col-lg-12'>
                        <DropDownInput id='category' label={Helper.getLabel(language,'category',"Category")} options={[]} onChange={() => {}} value={''} />
                    </div>
                    <div className='col-lg-12'>
                        <DropDownInput id='parent_category' label={Helper.getLabel(language,'parent_category',"Parent Category (optional)")} options={[]} onChange={() => {}} value={''} />
                    </div>
                </div> 

                <div className='templates_next_btn'>{ SaveButtonActionTrigger() }</div>
            </Fragment>
        )
    }

    const AddNewComplianceModuleWrapper = () => {
        return ( 
            <DetailViewComplianceTemplatesWithLines 
                rfq_id={rfq_id} 
                executeAPIForSaveHandler={takePropsTransactionalLevel} 
                transactionalLevel={true} 
                closePopupView={onPopupCloseClickHandler}
                reloadParentGridCompliance={reloadParentGrid}
            /> 
        )
    }

    return (
        <Popup className="common_compliance_popup" autoOpen={true} width={addNewTemplate || newCategory ? ' 470px' : '1200px'} onClose={onPopupCloseClickHandler}>
            <div className='top_head_line_wrapper'>{TopHeadLine()}</div>  
            
            {/* All Component Views */}
            { addNewTemplate && TemplateModuleWrapper() } 
            { importTemplate && ImportTemplateModuleWrapper() } 
            { importExcelTemplate && ImportTemplateLinesFromExcelModuleWrapper() } 
            { newCategory && AddNewCategoryModuleWrapper() }
            { newCompliance && AddNewComplianceModuleWrapper() } 
        </Popup>
    );
};

const mapStateToProps = (state) => {
    return {
        appOptions:state.options,
        auth:state.auth,
        language:state.language
    }
};

export default connect(mapStateToProps) ((CommonCompliancePopup));