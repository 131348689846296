import React, {useState, useEffect, Fragment, useRef} from 'react';
import './FiscalYear.css';
import './FiscalCalendar.css' 
import {Dropdown} from 'react-bootstrap';
import Api from '../../inc/Api'; 
import $ from 'jquery';
import '../../../node_modules/jquery-datetimepicker/build/jquery.datetimepicker.min.css'; 
import Delete from '../../assets/delete.svg';
import Settings from '../../inc/Settings';
import MasterComponentWraper from '../Backend/MasterComponentWraper'
import DropDownInput from '../Forms/Dropdown';
import { CustomToggle } from './CustomToggle';  
import { connect } from "react-redux";
import InputDatePicker from '../Forms/InputDatePicker'
import AgGrid from '../grid/ag/ag-grid';    
import Treeview from '../Treeview/Treeview';
import Helper from '../../inc/Helper'; 
import SimpleLoading from '../Loading/SimpleLoading';
import ApplySecurityRoles from '../SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../inc/Gui_id_list';
import Alert from '../inc/Alert';
import NavigationHeder from '../Navigations/NavigationHeder'; 
import moment from 'moment';
import { customDateComparator, getPopupMessageBasedOnStatus } from '../../inc/Validation';
import Button from '../inc/Button';
import LinkBtn from '../inc/LinkBtn';
import DatepickerFunctions from '../../inc/DatepickerHelper';
import NewInputDatePicker from '../Forms/NewInputDatePicker';
import AgGridNew from '../grid/ag/ag-grid-new';
import SimpleDropdown from '../grid/ag/cellEditor/SimpleDropdown';
import { getLabelFromDropdown, getOptions, optionsKeyCreator } from '../grid/ag/commonFunctions/GridCommonFunctions';

let gridApi = null;

// LYNKAZ - Fiscal Year SYSTEM
const FiscalYear = (props) => {
  const [calendar, setCalendar] = useState({ 
    fiscalcalendar: '',
    fiscaldescription: ''
  })  
   
  // local states for displaying data dynamically inside the grey box -
  const [fiscalData, setFiscalData] = useState({
    description: null,
    fiscalcalendar_id: null,
    year_id: null,
    name: null,
    start_date: null,
    end_date: null
  })
  const [fiscalPeriodsList, setFiscalPeriodsList] = useState({
    name: '',
    type: null,
    start_date: new Date(),
    end_date: DatepickerFunctions.formatDateYearOneDayMinusCurrentDate(),
    status: null
  })

  // Loading state component - wide:

  const wrapperDiv = useRef(null);
  const [height, setHeight] = useState(0);

  const [treeLoading, setTreeLoading] = useState(false)
  const [loadingFiscalYears, setLoadingFiscalYears] = useState(false) 
  const [loadingFiscalCalendars, setLoadingFiscalCalendars] = useState(false) 
  const [fiscalYearsLoading, setfiscalYearsLoading] = useState(false)

  const [treeStructure, setTreeStructure] = useState([])
  const [periodTypeList, setPeriodTypeList] = useState([])
  const [periodStatusList, setPeriodStatusList] = useState([])
  
  let treeDataCalled = false
  let periodTypeListCalled = false
  let periodStatusListCalled = false
 
  useEffect(() => {
    treeStructureData()
    get_period_type_list_data()
    get_period_status_list_data()
  }, [])

  useEffect(() => { 
    Helper.resizeWindowHandle(wrapperDiv,setHeight,window);
  }, [wrapperDiv?.current?.clientHeight]);
  
  // API Calls Functions / modules
  const childTreeItems = (arr) => { 
    let output = [];
      arr.forEach(element => {
          let newItem = {
          id:element.year_id,
          title:element.name,
          start_date: element.start_date,
          end_date: element.end_date, 
          isMaster:false, 
          items:[]
        }
        output.push(newItem)
      })
    return output  
  }

  const treeStructureData = () => {
    if(treeDataCalled){
      return;
    }
    setTreeLoading(true)
    let api = Api
    treeDataCalled = true
    api.setUserToken()
    let treeItems = []; 
    api.axios().get(Settings.apiUrl+'/fiscalcalendar',{}).then(function(res){
      if(res.data.status==='success'){ 
        let HIERARCHY = [] 
        res.data.data.forEach(element => { 
          HIERARCHY.push({
            id: element.fiscalcalendar_id,
            title:element.description,
            showChild: false,
            items: childTreeItems(element.year)
          }) 
        })
        setTreeStructure(HIERARCHY)
        setTreeLoading(false)
      } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
      setTreeLoading(false)
    })
    setTreeStructure(treeItems)
  }  
 
  const get_period_type_list_data = () => {
    if(periodTypeListCalled){
      return
    }

    let api = Api
    periodTypeListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/enum/fiscalperiodtype',{}).then(function(res){
      if(res.data.status==="success"){
        let Period_Type_List_API = []
       res.data.data.forEach(item => {  
        Period_Type_List_API.push({
          value: item.id,
          label: item.name
        })
       })
       setPeriodTypeList(Period_Type_List_API)
      } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const get_period_status_list_data = () => {
    if(periodStatusListCalled){
      return;
    }
    
    let api = Api
    periodStatusListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/enum/fiscalperiodstatus',{}).then(function(res){
      if(res.data.status==="success"){
        let Period_Status_List_API = []
       res.data.data.forEach(item => {  
        console.log(item.id)
        Period_Status_List_API.push({
          value: item.id,
          label: item.name
        })
       })
       setPeriodStatusList(Period_Status_List_API)
      } 
  }).catch((res) => { 
    getPopupMessageBasedOnStatus(res) 
  })
  }

  // onChange Input handlers -
  
  const CalendarChange = (event) => {
    let Calendar = event.target.value
    setCalendar((prevState) => {
      return {...prevState, fiscalcalendar: Calendar}
    })
  }

  const DescriptionChange = (event) => {
    let description = event.target.value
    setCalendar((prevState) => {
      return {...prevState, fiscaldescription: description}
    })
  } 

  const YearNameChange = (event) => {
    let Name = event.target.value
    setFiscalPeriodsList((prevState) => {
      return {...prevState, name:Name}
    })
  }

  const periodTypeHandler = (event) => { 
    let periodType = event.target.value 
    setFiscalPeriodsList(prevState => {
      return {...prevState, type: periodType}
    })
  }

  const periodStatusHandler = (event) => {
    let periodStatus = event.target.value  
    setFiscalPeriodsList(prevState => {
      return {...prevState, status: periodStatus}
    })
  }
 
  const onChangeInputDateStart = (date) => {  
    if(!date) return;  

    let currDate = date;
    let convertedDate = moment(currDate);
    let endDate = convertedDate.clone().add(1, "years").subtract(1, "days");
    
    setFiscalPeriodsList((prevState) => {
      return {
        ...prevState,
        start_date: date,
        end_date: endDate.format(props.dateFormate.formate.toUpperCase())
      };
    }); 
  }
  
  const onItemClickTree = (data) => { 
    setfiscalYearsLoading(true)
    if(data.isMaster == false) {  
      setFiscalData((prevState) => {
        return {
          ...prevState,
          description: data.title,
          year_id: data.id,
          start_date: data.start_date,
          end_date: data.end_date,
          fiscalcalendar_id: null
        }
      }) 
    } else { 
      setFiscalData((prevState) => {
        return {
          ...prevState,
          description: data.title,
          start_date: null,
          end_date: null,
          fiscalcalendar_id: data.id,
          year_id: null
        }
      }) 
    }
    setTimeout(() => {
      setfiscalYearsLoading(false)
    }, 200) 
  }
 
  // API Calls functions - 
  const CreateFiscalCalendar = () => {
    if(calendar.fiscalcalendar && calendar.fiscaldescription) {
      setLoadingFiscalCalendars(true)
      let api = Api
      api.setUserToken()
      api.axios().post(Settings.apiUrl+'/fiscalcalendar',{
        "description":calendar.fiscaldescription
      }).then(function(res){
        if(res.data.status==='success'){  
          Helper.alert(res.data.message)
          setCalendar({    
            fiscalcalendar: '',
            fiscaldescription: ''
          }) 
          treeStructureData()
          $('.fiscal-year-calendar').trigger('click')
          setLoadingFiscalCalendars(false)
        }
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
        treeStructureData()
        setLoadingFiscalCalendars(false)
        setCalendar({    
          fiscalcalendar: '',
          fiscaldescription: ''
        })
      }) 
    }else {
      Helper.alert('Please enter data in fields!', 'error')
    }
  }

  const DeleteHandler = () => {
    if(!fiscalData.fiscalcalendar_id && !fiscalData.year_id){
      Helper.alert('Select Any Fiscal Calendar Or Fiscal Year To Delete!', 'error')
      return;
    }
    setfiscalYearsLoading(true)
    let api = Api
    api.setUserToken()
    if(fiscalData.fiscalcalendar_id){ 
      api.axios().delete(Settings.apiUrl+`/fiscalcalendar/${fiscalData.fiscalcalendar_id}`,{}).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
          treeStructureData() 
          setFiscalData((prevState) => {
            return {
              ...prevState,
              description: '',
              fiscalcalendar_id: null
            }
          })
          setfiscalYearsLoading(false)
        }
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
        setfiscalYearsLoading(false)
      }) 
    }
    if(fiscalData.year_id) {
      setfiscalYearsLoading(true)
      api.axios().delete(Settings.apiUrl+`/fiscalcalendaryear/${fiscalData.year_id}`,{}).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
          treeStructureData()
          setFiscalData((prevState) => {
            return {
              ...prevState,
              description: '',
              name: '',
              year_id: null,
              start_date: null,
              end_date: null
            }
          })
          setfiscalYearsLoading(false)
        }
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
        setfiscalYearsLoading(false)
      })
    } 
  }
  
  const CreateFiscalPeriod = () => {  
    if(fiscalData.fiscalcalendar_id){  
      if(fiscalPeriodsList.name === ""){
        Helper.alert('Fiscal Calendar Year Name missing!', 'error')
        return;
      } 
      if(document.getElementById('period_type').value === ""){
        Helper.alert('Period Type missing!', 'error')
        return;
      }
      if(document.getElementById('period_status').value === ""){
        Helper.alert('Period Status missing!', 'error')
        return;
      }

      setLoadingFiscalYears(true)
      let api = Api
      api.setUserToken()
      api.axios().post(Settings.apiUrl+'/fiscalcalendaryear',{
        "fiscalcalendar_id": fiscalData.fiscalcalendar_id,
        "period_type": parseInt(document.getElementById('period_type').value),
        "fiscalcalendaryear_name":fiscalPeriodsList.name, 
        "start_date": DatepickerFunctions.convertDateForDataBase(fiscalPeriodsList.start_date),
        "end_date": DatepickerFunctions.convertDesiredDateFormatForDataBase(fiscalPeriodsList.end_date),
        "period_status": parseInt(document.getElementById('period_status').value)
      }).then(function(res){
      if(res.data.status==='success'){ 
        Helper.alert(res.data.message) 
        treeStructureData() 
        setFiscalPeriodsList((prevState) => {
          return {
            ...prevState,
            name: '',
            type: null,
            start_date: new Date(),
            end_date: DatepickerFunctions.formatDateYearOneDayMinusCurrentDate(),
            status: null
          }
        })
        $('.fiscal-year').trigger('click')
        setLoadingFiscalYears(false)
      } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
        setLoadingFiscalYears(false)
        setFiscalPeriodsList((prevState) => {
          return {
            ...prevState,
            name: '',
            type: null,
            start_date: new Date(),
            end_date: DatepickerFunctions.formatDateYearOneDayMinusCurrentDate(),
            status: null
          }
        })
      })
    }else {
      Helper.alert('Please select any fiscal calendar to add fiscal year!', 'error')
    }
  }
  
  const onRowValueChanged = (event) => {  
    if (event?.data?.period_id) {
      updateData(event)
    }
  }
  
  const updateData = async (element) =>{  
    try {
      let payload = {
        type: null,
        name: null,
        description: "",
        start_date: null,
        end_date: null,
        month_number: 1,
        quarter_number: 1,
        period_status: parseInt(element?.data?.period_status)
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().put(Settings.apiUrl+`/fiscalsperiods/${element?.data?.period_id}`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }
  
  // modules/functions - wrappers,

  // 2702002 -> edit to calendars

  const FiscalYearHeaderWrapper = () => {
    return (
      <div className='fiscal-header-'>
        <div className='row ps-2 pt-3 pb-2'>
          <div className='col-4'>
            <h2 className='fiscal_calendar_heading ms-3'>{Helper.getLabel(props.language, 'calendars', 'Calendars')}</h2>
          </div>

          <div className='col-6 d-flex justify-content-start'>
            {FiscalYearCalendarCreateButton()}
            {FiscalYearCreateButton()}
            {FiscalYearDeleteButton()}
          </div>
        </div>
      </div>
    )
  }

  const FiscalYearHierarchyGridWrapper = () => {
    return ( 
      <div className='row'>
        {FiscalYearSideBarHierarchyView()} 
        {FiscalYearGreyBoxInfo()} 
      </div>
    )
  }

  const FiscalYearSideBarHierarchyView = () => {
    return (
      <div className='col-4'>
        {treeLoading ? (
          <SimpleLoading />
        ) : (
          <div className='fiscal_year_sidebar ps-4 pt-2'>
            <Treeview items={treeStructure} onClick={onItemClickTree} />
          </div>
        )}
      </div>
    )
  }

  const FiscalYearGreyBoxInfo = () => {
    return (
      <div className='col-8'>
        <div className='fiscal_year_content'>
          <div className='info mb-4 p-4'>
            <div className='row'>
              <h3 className='info-heading'>{Helper.getLabel(props.language, '2706006', 'Calendar')}</h3>
              <div className='col-3'>
                <h4 className='info-calender-description-label'>{Helper.getLabel(props.language, '2707007', 'Description')}</h4>
              </div>

              <div className='col-9'>
                <h4 className='info-description'>{fiscalData.description}</h4>
              </div>
            </div>

            <div className='row mt-3'>
              <h3 className='info-heading'>{Helper.getLabel(props.language, 'year', 'Year')}</h3>
              <div className='col-3'>
                <h4 className='info-start-date-label'>{Helper.getLabel(props.language, '2708008', 'Start Date')}</h4>
                <h4 className='info-end-date-label'>{Helper.getLabel(props.language, '2709009', 'End Date')}</h4>
              </div>

              <div className='col-9'>
                <h4 className='info-start-date'>{fiscalData.start_date && DatepickerFunctions.convertDateFromDataBase(fiscalData.start_date)}</h4>
                <h4 className='info-end-date'>{fiscalData.end_date && DatepickerFunctions.convertDateFromDataBase(fiscalData.end_date)}</h4>
              </div>
            </div>
          </div>
          
          <div className='rs_periods_grid_save_trigger grid_save_btn_hide'>
            {console.log(fiscalData?.year_id, 'year')}
            {fiscalYearsLoading ? (
              <SimpleLoading />
            ) :   
            (
              <AgGridNew
                apiUrl={fiscalData.year_id ? Settings.apiUrl+`/fiscalsperiods/${fiscalData?.year_id}` : null}
                pagination={false}
                columnDefs={gridColumnHeaders}
                onRowValueChanged={onRowValueChanged}
                hideDeleteBtn={true}
                hideAddBtn={true}
                height={500}
                onGridReady={(params) => gridApi = params?.api} 
                uniqueField={`period_id`}
                gridId={'fiscal-years-grid'}
              />
            )
          }
          </div>
        </div>
      </div>
    )
  }

  const FiscalYearCalendarCreateButton = () => {
    return (
      <div className='fiscal-header-calendar-create'>
        <Dropdown className='fiscal-year-calendar'> 
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            {Helper.getLabel(props.language, '2703003', 'New Fiscal Calendar')}
          </Dropdown.Toggle> 

          <Dropdown.Menu className='dropdown'>
            <div className='fiscal-calendar-section pt-3'>
              {loadingFiscalCalendars ? (
                <SimpleLoading />
              ) : (
                <Fragment>
                  <div className='container-fluid p-0 m-0'>
                    <h4 className='fiscal-calendar-heading mb-3'>{Helper.getLabel(props.language, '2710010', 'New Fiscal Calendar Details')}</h4>
                    <div className='row gy-3'>
                      <div className='col-12'>
                        <label className='mb-2'>{Helper.getLabel(props.language, '2711011', 'Calendar')}<span className='asterik_sign'>*</span></label>
                        <input type='text' onChange={CalendarChange} value={calendar.fiscalcalendar} />
                      </div>
                      <div className='col-12'>
                        <label className='mb-2'>{Helper.getLabel(props.language, '2712012', 'Calendar Description')}<span className='asterik_sign'>*</span></label>
                        <textarea onChange={DescriptionChange} value={calendar.fiscaldescription}></textarea>
                      </div>
                      <div className='col-12'>
                        <button
                          className='create-fiscal-calendar'
                          disabled={!security.canCreate(frontendIds.setupForms.fiscal_year.fiscal_calendar_create_button)}
                          onClick={CreateFiscalCalendar}
                        >
                          {Helper.getLabel(props.language, '2713013', 'Create')}
                        </button>
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )
  }

  const FiscalYearCreateButton = () => {
    return (
      <div className='fical-header-calendar-year ms-5'>
        <Dropdown className='fiscal-year'>
          <div className='toggle-wrapper'>
            <Dropdown.Toggle as={CustomToggle}>
              {Helper.getLabel(props.language, '2704004 ', 'New Fiscal Year')}
            </Dropdown.Toggle>
          </div>

          <Dropdown.Menu className='dropdown'>
            <div className='fiscal-year-section pt-4 p-0'>
              {loadingFiscalYears ? (
                <SimpleLoading />
              ) : (
                <Fragment>
                  <div className='container'>
                    <h4 className='fiscal-year-heading mb-3 mt-2'>{Helper.getLabel(props.language, '2710010', 'New Fiscal Calendar Details')}</h4>
  
                    <div className='row gy-3'>
                      <div className='col-12'>
                        <label>{Helper.getLabel(props.language, '2714014', 'Year')}<span className='asterik_sign'>*</span></label>
                        <input type='text' onChange={YearNameChange} value={fiscalPeriodsList.name} />
                      </div>
                      <div className='col-6'>
                        <label>{Helper.getLabel(props.language, '2715015', 'Start Of Fiscal Year')}<span className='asterik_sign'>*</span></label>
                        <NewInputDatePicker name='start_date' value={fiscalPeriodsList.start_date} onChange={onChangeInputDateStart} id='start_date_id' />
                      </div>
                      <div className='col-6'>
                        <label>{Helper.getLabel(props.language, '2716016', 'End Of Fiscal Year')}<span className='asterik_sign'>*</span></label>
                        <input className='end_date_fiscal_btn' value={fiscalPeriodsList.end_date} disabled />
                      </div>
                    </div>
  
                    <h4 className='fiscal-year-heading'>{Helper.getLabel(props.language, '2717017', 'Period Details')}</h4>
                    <div className='row gy-3 mb-2 mt-2'>
                      <div className='col-12'>
                        <label className='mb-1'>{Helper.getLabel(props.language, '2718018', 'Period Type')}<span className='asterik_sign'>*</span></label>
                        <DropDownInput className='fiscal_period_type_drp' onChange={periodTypeHandler} value={fiscalPeriodsList.type} id='period_type' options={periodTypeList} />
                      </div>
                      <div className='col-12'>
                        <label className='mb-1'>{Helper.getLabel(props.language, '2719019', 'Period Status')}<span className='asterik_sign'>*</span></label>
                        <DropDownInput className='fiscal_period_status_drp' onChange={periodStatusHandler} value={fiscalPeriodsList.status} id='period_status' options={periodStatusList} />
                      </div>
                      <div className='col-12'>
                        <button
                          disabled={!security.canCreate(frontendIds.setupForms.fiscal_year.fiscal_year_create_button)}
                          className='create-fiscal-calendar'
                          onClick={CreateFiscalPeriod}
                        >
                          {Helper.getLabel(props.language, '2720020', 'Create')}
                        </button>
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  const FiscalYearDeleteButton = () => {
    return (
      <div className='fical-header-calendar-delete ms-4'>
        <button
          disabled={!security.canDelete(frontendIds.setupForms.fiscal_year.fiscal_delete_button)}
          className='fiscal-year-delete'
          onClick={DeleteHandler}
        >
          <img src={Delete} className='img-fluid' alt="Delete" />
        </button>
      </div>
    );
  };
 
  const SaveHandler = () => {
    $('.rs_periods_grid_save_trigger .rs_grid_btn_save').trigger('click'); 
  }

  const gridColumnHeaders = [
    {field:'name', filter: 'agTextColumnFilter', headerName: Helper.getLabel(props.language,'2720021','Period Name'), editable: false},
    {
      field: 'start_date',
      headerName: Helper.getLabel(props.language,'2715015','Period Start Date'),
      filter: 'agDateColumnFilter', 
      valueFormatter: (params) => { 
       return params.value
          ? DatepickerFunctions.convertDateFromDataBase(params?.value)
          : '-'
      },
      editable: false,
      minWidth: 220,
      comparator: customDateComparator
    },
    {
      field: 'end_date',
      headerName: Helper.getLabel(props.language,'2716016','Period End Date'),
      filter: 'agDateColumnFilter', 
      valueFormatter: (params) => { 
       return params.value
          ? DatepickerFunctions.convertDateFromDataBase(params?.value)
          : '-'
      },
      editable: false,
      minWidth: 220,
      comparator: customDateComparator
    },
    {
      field: 'period_status',
      headerName: Helper.getLabel(props.language,'2719019','Period Status'),
      editable: true,
      cellEditor: SimpleDropdown,
      isAllowZero:true,
      inputId: 'fiscal-year-period-status',
      minWidth:180,
      cellEditorParams: {
        values: periodStatusList
      },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, periodStatusList),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label
      },
    }
  ];

  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.setupForms.fiscal_year.fiscal_year)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  return ( 
    <Fragment>
      <div className='container-fluid'>
        <div ref={wrapperDiv}> 
          <NavigationHeder hideMoreBtn={true} title={Helper.getLabel(props.language,'fiscal_calendars','Fiscal Calendars')}>
            <LinkBtn isActive= {false} to="/setup" title={Helper.getLabel(props.language,'close','Close')} />
            <Button isDisable={!security.canEdit(frontendIds.setupForms.fiscal_year.fiscal_year_update_button)} isActive= {false} title={Helper.getLabel(props.language,'save','Save')} onClick={SaveHandler} className="rfq_save_btn" />
          </NavigationHeder>
        </div>
        
        <div className='common_scrollbar_styles' style={{ height: `${height - 30}px`, overflowX: 'hidden', overflowY: 'auto'}}>
          {FiscalYearHeaderWrapper()}
          {FiscalYearHierarchyGridWrapper()} 
        </div>
      </div> 
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language,
    dateFormate: state.dateFormate
  }
}
const SecurityOptions = {
  gui_id:Gui_id_list.setupForms.fiscal_year.fiscal_year
}

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(FiscalYear, SecurityOptions))))