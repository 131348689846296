import React, { useCallback, useRef, useState } from 'react'
import Helper from '../../../../../inc/Helper'
import ItemDetails from './ItemDetails'
import Settings from '../../../../../inc/Settings'
import Api from '../../../../../inc/Api'
import LineDetailsPopUp from './LineDetailsPopUp'
import { getPopupMessageBasedOnStatus } from '../../../../../inc/Validation'
import AgGridNew from '../../../../grid/ag/ag-grid-new'
import { getWorkConfirmationLineColumns } from '../../ColumnsHeader'
import { getNewValue } from '../../../../grid/ag/commonFunctions/GridCommonFunctions'
import { useLocation } from 'react-router-dom'

let gridObj = null
let isStopAutoSave = false


const BeforeDeleteButton = ({language,shipmentHeader,handleAllRetreive}) => {
  return (
    <div
      className={`list_view_action_btns_grid ${
        shipmentHeader?.document_status === 1 ? 'd-none' : ''
      }`}
    >
      <button
        onClick={
          shipmentHeader?.document_status === 1 ? '' : handleAllRetreive
        }
      >
        <span>
          <img
            src="/images/icons/retrieve.svg"
            className="img-fluid"
            alt="retrieve all icon"
          />
        </span>
        {Helper.getLabel(language, 'retrieve_all', 'Retrieve All')}
      </button>
    </div>
  )
}
let AddBtn = false
let transaction

const LineGrid = (props) => {
  const [popUpData, setPopUpData] = useState({})
  const [detailsPopUp, setDetailsPopUp] = useState(false)

  const billingRuleOptions = useRef([])
  const location = useLocation()

  //function to show button when item details required
  const addOREditButton = (params) => {
    return params.data?.item_detail_required ||
      params.data?.billingrule?.item_detail_required
      ? <button className="rs_grid_simple_link border-0 bg-transparent p-0 text-primary">line details</button>
      : <></>
  }

  const getBillingRuleDescription = (params) => {
    return <div>{params?.data?.line_id
      ? params?.data?.billingrule?.description || "-"
      : null}</div>
  }

  const handleGridReady = (params) => {
      gridObj = params
  }

  const postPOLines = (data) => {
    let api = Api
    api.setUserToken()
    if (props.shType === null) return
    api
      .axios()
      .post(Settings.apiPurchaseOrderUrl + `/sh_lines/${props.shType}`, data)
      .then(function (res) {
        if (res.data.status === 'success') {
          Helper.alert(res.data?.message, 'success')
          setTimeout(()=>{
            gridObj?.api?.refreshServerSide({purge:true})
          },100)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)  
      })
  }

  const postInvoicePOLines = (data) => {
    let api = Api
    api.setUserToken()
    data.forEach((item) => {
      api
        .axios()
        .post(
          Settings.apiPurchaseOrderUrl +
            `/invoices_line/${props.shipmentHeader?.invoice_id}`,{
              po_line_id:item.po_line_id,
              billingrule_id:item.billingrule_id,
            }
        )
        .then(function (res) {
          if (res?.data?.status === 'success') {
            Helper.alert(res.data?.message, 'success')   
            setTimeout(() => {
              gridObj?.api?.refreshServerSide({purge:true})
              gridObj?.api?.deselectAll()
            }, 100);
          }
        })
        .catch((res) => { 
          getPopupMessageBasedOnStatus(res)    
        })
    })
  
  }

  //function to fetch lines for retrieve all
  const fetchPOLInes = async () => {
    const lineData = await props.workConfirmationApiCalls.getAllPOLInes(
      props.shipmentHeader?.purchaseorder_id,
      props.type==="contract type invoice" && true 
    )
    if (lineData) {
      const currentLines = props.lineData || []
      const newLines = lineData.filter(
        (newLine) =>
          !currentLines.some(
            (currentLine) =>
              currentLine.billingrule_id === newLine.billingrule_id,
          ),
      )
      if (newLines.length > 0) {
        const lines = newLines.map((newLine) => ({
          line_id: newLine.po_line_id,
          billingrule_id: newLine.billingrule_id,
          received_qty: newLine?.type === 2 ? 100 : 0
        }))

        const shipment_id = props.shipmentHeader?.shipment_id
       
      
          const invoiceLines = newLines.map((newLine) => ({
            po_line_id: newLine.po_line_id,
            billingrule_id: newLine.billingrule_id,
          }))

          const data = {
          shipment_id: shipment_id,
          lines: lines,
        }
        props.type==="contract type invoice"?postInvoicePOLines(invoiceLines):postPOLines(data)
      }
    }
  }

  const handleAllRetreive = () => {
    fetchPOLInes()
  }

  const onCellClicked = (event) => {
    if (
      (event?.colDef?.field === 'line-details' &&
        event.data?.item_detail_required === true) ||
      (event?.colDef?.field === 'line-details' &&
        event.data?.billingrule?.item_detail_required === true)
    ) {
      setPopUpData(event.data)
      setDetailsPopUp(true)
    }
  }

  const onCompletionQtyChange = (event,params) => {
      let billingRuleId = document?.getElementById('billingrule_id')
      const billingRule = billingRuleOptions?.current?.find((item)=>item?.billingrule_id===parseInt(billingRuleId?.value))
      let received_qty = event?.target?.value || 0
      let type = params?.data?.line_id ? params?.data?.billingrule?.type : billingRule?.type
      let editorInsAr = params.api.getCellEditorInstances()
      let unit_price = params?.data?.line_id ? params?.data?.billingrule?.unit_price: params?.data?.unit_price
      let ordered_amount = params?.data?.line_id ? params?.data?.billingrule?.amount: params?.data?.amount
      let line_amount = 0 
      if(type===0){
        line_amount = parseFloat(unit_price) * parseFloat(received_qty)
      }else if(type ===1 || type===3){
        line_amount = (parseFloat(ordered_amount) * parseFloat(received_qty)) / 100
        editorInsAr.forEach((cellEditorIns) => {
          if (cellEditorIns.params && cellEditorIns.params.colDef.field == 'line_amount') {
            if (cellEditorIns.updateValue && typeof cellEditorIns.updateValue == 'function') {
              cellEditorIns.updateValue(line_amount)
            }
          }
        })
      }
      params?.node?.setDataValue('line_amount',line_amount)
  }

  const onLineAmountChange = (event,params) => {
    let amount  = event?.target?.value || 0
    let editorInsAr = params.api.getCellEditorInstances()
    let ordered_amount = params?.data?.line_id ? params?.data?.billingrule?.amount: params?.data?.amount
    let received_qty = 0 
    received_qty = (parseFloat(amount) / parseFloat(ordered_amount)) * 100
      editorInsAr.forEach((cellEditorIns) => {
        if (cellEditorIns.params && cellEditorIns.params.colDef.field == 'received_qty') {
          if (cellEditorIns.updateValue && typeof cellEditorIns.updateValue == 'function') {
            cellEditorIns.updateValue(received_qty)
          }
        }
      })
    params?.node?.setDataValue('received_qty',received_qty)
  }

  const getResetObject = (keyArray,billingRule) => {
    let output = {}
    // keyArray.forEach((keyItem) => {
    //   output[keyItem] = ''
    // })
    if (keyArray.includes('ordered_qty')) {
      output.ordered_qty = billingRule?.percentage_or_quantity
    }
    if(billingRule?.type ==2){
      if(keyArray.includes('received_qty')){
        output.received_qty = 100
      }
      if(keyArray.includes('line_amount')){
        output.line_amount = billingRule?.amount
      }
    }
    if (keyArray.includes('unit_price')) {
      output.unit_price = billingRule?.unit_price
    }
    if (keyArray.includes('amount')) {
      output.amount = billingRule?.amount
    }
    if (keyArray.includes('remaining_qty')) {
      output.remaining_qty = billingRule?.remaining_percentage_or_qty
    }
    return output
  }

  const enableDisableFields = (billingRuleId,billingRule,params) => {
    isStopAutoSave = true
    let getFocusedCell = gridObj?.api?.getFocusedCell()
    let getSelectedNode = gridObj?.api?.getSelectedNodes()
    gridObj.api.stopEditing()
    let resetData = {}
    if(billingRule?.type == 2){
      resetData = getResetObject(['ordered_qty','unit_price','amount','remaining_qty','received_qty','line_amount'],billingRule)
    }else{
      resetData = getResetObject(['ordered_qty','unit_price','line_amount','amount','remaining_qty','received_qty'],billingRule)
    }

    if (getSelectedNode.length >= 1) {
      getSelectedNode[0].setData({
        ...getSelectedNode[0].data,
        po_line_id:params?.data?.po_line_id,
        billingrule_id:billingRuleId,
        billingrule:{
           type:billingRule?.type
        },
        ...resetData,
      })
    }

    setTimeout(function () {
      gridObj?.api?.startEditingCell({
        rowIndex: getFocusedCell ? getFocusedCell.rowIndex : 0,
        colKey: 'billingrule_id',
      })
      isStopAutoSave = false
    }, 300)
  }


  const handleDropdown = (event,params) => {
   const billingRule = billingRuleOptions?.current?.find((item)=>item?.billingrule_id===parseInt(event?.params?.data?.id))
   if(billingRule){
    enableDisableFields(event?.target?.value,billingRule,params)
    if(billingRule?.type !== 2){
      params.node.setDataValue('received_qty',null)
      params.node.setDataValue('line_amount',0)
     }
   }
  }

  const handleFetchData = (response) => {
    props.setLinesData(response?.data?.data)
    return {
      rowData: response?.data?.data?.map((item) => ({
        ...item,
        gridId: `${Date.now()}_${getNewValue()}`
      })),
      rowCount: response?.data?.data?.length,
    }
  }

  const GridColumnsHeadingLines = getWorkConfirmationLineColumns({
    language:props.language,
    lineIdOptions:props.lineIdOptions,
    shipmentHeader:props.shipmentHeader,
    getBillingRuleDescription:getBillingRuleDescription,
    type:props.type,
    addOREditButton,
    handleOnChangeDropdown:handleDropdown,
    billingRuleOption:billingRuleOptions,
    onCompletionQtyChange:onCompletionQtyChange,
    onLineAmountChange:onLineAmountChange,
  })

  let masterSettings = {
    detailRowAutoHeight: true,
    masterDetail: true,
    isRowMaster: function (dataItem) {
      return (
        dataItem.billingrule?.item_detail_required ||
        dataItem?.item_detail_required
      )
    },
    detailCellRenderer: ItemDetails,
    detailCellRendererParams: {
      // items: props.linesData?.items,
      getDetailRowData: (params) => {
        let api = Api
        api.setUserToken()
        api
          .axios()
          .get(
            Settings.apiPurchaseOrderUrl +
              `/lines/${params?.data?.line_id}/${Settings.source_id.ShipmentGoodReceivingWorkConfirmation}`
          )
          .then((res) => {
            if (res.data.status === 'success') {
              return res.data.data
            } 
        })
     }
    },
  }

  const handlePutRequest = (api,putRequestData,event)=> {
    if((event.data.billingrule?.type!==0 && event.data.received_qty>parseFloat(100))||(event.data.billingrule?.type!==0 && event.data.received_qty===parseFloat(0))||(event.data.billingrule?.type===0 && parseFloat(event.data.received_qty)>parseFloat(event.data.remaining_qty))){
      props.type==="contract type invoice"?Helper.alert("Invoice Prcnt / Qty cannot be 0 or greater than Remaining Prcnt / Qty","error"):Helper.alert("Completion Prcnt / Qty cannot be 0 or greater than Remaining Prcnt / Qty","error")
      props.setLinesData((prevLines)=>[...prevLines])
      return
    }
    api
    .axios()
    .put(
      props.type==="contract type invoice"?Settings.apiPurchaseOrderUrl + `/line/${event.data?.line_id}/${props.shipmentHeader?.source_id}`:Settings.apiPurchaseOrderUrl + `/sh_line/${event.data?.line_id}`,
      putRequestData,
    )
    .then(function (res) {
      if (res.data.status === 'success') {
        Helper.alert(res.data?.message, 'success')
        gridObj?.api?.refreshServerSide({purge:true})
        gridObj?.api?.deselectAll()
      }
    })
    .catch((res) => {
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const handleAddBtn = (value, transactionData) => {
    AddBtn = value
    transaction = transactionData
  }

  const onRowValueChanged = (event) => {
    const id = props.type==="contract type invoice"?props.shipmentHeader?.invoice_id:props.shipmentHeader?.shipment_id ?? location?.hash?.replace("#",'')
    const putRequestData = {
      received_qty: parseFloat(event.data?.received_qty),
      line_amount:parseFloat(event?.data?.line_amount)
    }
    let api = Api
    api.setUserToken()
    if (!id || isStopAutoSave) return
    if (
      event.data?.line_id &&
      event.data?.billingrule?.item_detail_required === false
    ) {
      // PUT request
      handlePutRequest(api,putRequestData,event)
    }
  }

  const handleOnRowEditingStopped = (params) => {
    const id = props.type==="contract type invoice"?props.shipmentHeader?.invoice_id:location?.hash?.replace("#",'')
    const data = {
      shipment_id: parseInt(id),
      lines: [
        {
          line_id: parseInt(params.data?.po_line_id),
          billingrule_id: parseInt(params.data?.billingrule_id),
          received_qty: parseFloat(parseFloat(params.data?.received_qty)?.toFixed(6)),
        },
      ],
    }
    const invoiceData = {
      po_line_id:parseInt(params?.data?.po_line_id),
      billingrule_id:parseInt(params?.data?.billingrule_id),
    }
    let api = Api
    api.setUserToken()
    if (!id || isStopAutoSave) return
     if(!params?.data?.line_id || params?.data?.isNew){
      api
      .axios()
      .post(props.type==="contract type invoice"?Settings.apiPurchaseOrderUrl + `/invoices_line/${props.shipmentHeader?.invoice_id}`:Settings.apiPurchaseOrderUrl + `/sh_lines/${props.shType}`,props.type==="contract type invoice"?invoiceData:data)
      .then(function (res) {
        if (res.data.status === 'success') {
          Helper.alert(res.data?.message, 'success')
          setTimeout(()=>{
            gridObj?.api?.refreshServerSide({purge:true})
            gridObj?.api?.deselectAll()
          },100)
          setTimeout(() => {
            if (AddBtn) {
              gridObj?.api?.applyServerSideTransaction(transaction);
            }
          }, 1000);
        }
      })
      .catch((res) => { 
        getPopupMessageBasedOnStatus(res)    
      })
     }
  }

  const onLineDelete = useCallback(async (element,index,lastIndex) => { 
    const shipment_id = location.hash.replace('#', '')
    let api = Api;
    api.setUserToken();
    if (!shipment_id && props.type !== 'contract type invoice') return
    let lineUrl = props.type === 'contract type invoice' ? `/line/${element?.data?.line_id}/${props.shipmentHeader?.source_id}`: `/sh_line/${element?.data?.line_id}`
    try {
      const res = await api
        .axios()
        .delete( Settings.apiPurchaseOrderUrl +
          lineUrl)
      const rowNode = gridObj?.api?.getRowNode(element.data?.line_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridObj?.api?.applyServerSideTransaction(transaction)
      }, 300)

      if (index === lastIndex) {
        setTimeout(() => {
          gridObj?.api?.refreshServerSide({ purge: true });
          gridObj?.api?.deselectAll();
        }, 400);
      }

      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
      console.log(err)
    }
  }, [])

  return (
    <div className={props.userType === 0 ? 'work_completion_line_grid_responsive' : 'work_completion_line_grid work_completion_line_grid_responsive'}>
      <input
        type="hidden"
        disabled
        value={Settings.source_id?.contract}
        id="source_id"
      />
      {/* <AgGrid
        id="work_confirmation_line_grid"
        header={GridColumnsHeadingLines}
        labels={{ btnDelete: 'Remove', btnAdd: 'Add Item' }}
        beforeDeleteButton={BeforeDeleteButton}
        data={props.lineData}
        hideSave={true}
        addBtnHide={false}
        allBtnHide={props.shipmentHeader?.document_status === 1}
        onCellClicked={onCellClicked}
        settings={masterSettings}
        hideDelete={false}
        onDelete={onRowDelete}
        onRowValueChanged={onRowValueChanged}
      /> */}
      <AgGridNew 
        gridId="work_confirmation_line_grid_1"
        uniqueField={'line_id'}
        height={500}
        columnDefs={GridColumnsHeadingLines}
        beforeDeleteBtns={<BeforeDeleteButton language={props.language} shipmentHeader={props.shipmentHeader} handleAllRetreive={handleAllRetreive} />}
        onRowValueChanged={onRowValueChanged}
        handleDeleteSelectedRows={onLineDelete}
        fetchData={handleFetchData}
        allBtnHide={props.shipmentHeader?.document_status === 1}
        masterSettings={masterSettings}
        onCellClicked={onCellClicked}
        apiUrl={Settings.apiPurchaseOrderUrl + `/get_sh_lines/${location.hash.replace('#', '')}`}
        onGridReady={handleGridReady}
        handleAddButton={handleAddBtn}
        onRowEditingStopped={handleOnRowEditingStopped}
        noNeedRefresh={true}
        addLabel={"Add Line"}
        returnApiCondition={!location.hash.replace('#', '')}
      />
      {detailsPopUp && (
        <LineDetailsPopUp
          setShowPopup={setDetailsPopUp}
          popUpData={popUpData}
          fetchAllLines={props.fetchAllLines}
          shipmentHeader={props.shipmentHeader}
          lineIdOptions={props.lineIdOptions}
        />
      )}
    </div>
  )
}

export default LineGrid
