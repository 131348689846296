import React, { Component } from "react";
import { Link } from "react-router-dom";

import Helper from "../../../inc/Helper";
import WishlistIcon from "../../../assets/wishlistIcon.svg";
import Delete from '../../../assets/delete.svg';
import CartIcon from "../../../assets/cartIcon.svg";
import { connect } from "react-redux";
import ShoppingCartModal from "../modals/ShoppingCartModal";
import Button from "../../inc/Button";
import Api from "../../../inc/Api";
import { getPopupMessageBasedOnStatus } from "../../../inc/Validation";
import RsWithRouter from "../../inc/RsWithRouter";
import Settings from "../../../inc/Settings";

class NavCartWishlist extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showShoppingCart: false,
    }

    this.handleShowShoppingCart = this.handleShowShoppingCart.bind(this)
    this.handleCloseShoppingCart = this.handleCloseShoppingCart.bind(this)
    this.decrementCartCount = this.decrementCartCount.bind(this)
    this.getSearchParam = this.getSearchParam.bind(this)
  }

  handleCloseShoppingCart() {
    this.setState({ showShoppingCart: false })
  }

  handleShowShoppingCart() {
    this.setState({ showShoppingCart: true })
  }

  getSearchParam = (name) => {
    const params = new URLSearchParams(this.props.rs_router.location?.search)
    return params.get(name)
  }

  onPurchaseRequisitionDelete() {
    let api = Api
    api.setUserToken()
    let that = this
    api
      .axios()
      .delete(
        Settings.apiPurchaseRequisitionUrl +
          `/pr_header/${this.getSearchParam('pr_view_id')}`
      )
      .then(function (res) {
        if (res.data.status === 'success') {
          setTimeout(()=>{
            that.props.rs_router.navigate('/purchase-requisition')
          },1000)
          Helper.alert(res.data?.message, 'success')
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
      })
  }

  decrementCartCount() {
    this.props.changeCartCount(
      this.props.cartCount >= 1
        ? this.props.cartCount - 1
        : this.props.cartCount
    )
  }


  render() {
    return (
      <div className='pr-nav'>
        {this.props?.currentDocumentStatus === 0 && (
          <button
            onClick={this.onPurchaseRequisitionDelete.bind(this)}
            className='border-0 p-0 bg-transparent'
          >
            <div className='pr-nav-cart'>
              <Button title={Helper.getLabel(this.props.language, 'delete', 'Delete')} className='red-btn-style' isActive={false} isDisable={this.props?.currentDocumentStatus!==0} />
              {/* <img src={Delete} alt='cart' height='20px' width='20px' /> */}  
            </div>
          </button>
        )}
        {this.props.isCartVisible && <>
        <Link className='pr-nav-wishlist' to='/purchase-requisition/wishlist'>
          <img src={WishlistIcon} alt='wishlist' height='20px' width='20px' />
          {Helper.getLabel(this.props.language, 'wishlist', 'Wishlist')}
          {this.props.wishlistCount > 0 ? (
            <span
              className={`${
                Helper.isRtl()
                  ? 'pr-nav-wishlist-count-rtl'
                  : 'pr-nav-wishlist-count'
              }`}
            >
              {this.props.wishlistCount}
            </span>
          ) : null}
        </Link>
        <div className='pr-nav-cart' onClick={this.handleShowShoppingCart}>
          <img src={CartIcon} alt='cart' height='20px' width='20px' />
          {Helper.getLabel(this.props.language, 'cart', 'Cart')}
          {this.props.cartCount > 0 ? (
            <span
              className={`${
                Helper.isRtl() ? 'pr-nav-cart-count-rtl' : 'pr-nav-cart-count'
              }`}
            >
              {this.props.cartCount}
            </span>
          ) : null}
        </div> </>}
        {
          this.props.isContinueShopping &&  this.props.currentDocumentStatus === 0 && <Button className="brown-btn-style ms-1" title={Helper.getLabel(this.props.language, 'delete', 'Continue shopping')} onClick={()=>{this.props.rs_router.navigate(`/purchase-requisition/shop?pr_view_id=${this.props.prId}`)}} />
        }
       {this.props.hideNextBtn ? null : <div className="d-flex gap-3">
          {
          this.props.showSaveBtn &&  <Button
          title={Helper.getLabel(this.props.language, "save", "Save")}
          onClick={this.props.handleSaveBtn}
          isDisable={this.props.disableNext}
          className='rfq_save_btn'
         />
          }
          <Button
            title={Helper.getLabel(this.props.language, 'back', 'Back')}
            onClick={this.props.handleBackBtn}
            className='blue-btn-style'
          />
          <Button
            title={Helper.getLabel(this.props.language, 'next', 'Next')}
            onClick={this.props.nextBtnHandler}
            isDisable={this.props.disableNext}
            className='blue-btn-style'
          />
        </div>}
        <ShoppingCartModal
          visibility={this.state.showShoppingCart}
          hide={this.handleCloseShoppingCart}
          decrementCartCount={this.decrementCartCount}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    cartCount: state.prShop.cartCount,
    wishlistCount: state.prShop.wishlistCount,
    prCart:state.prCart.prCartInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCartCount: (payload) =>
      dispatch({ type: "CHANGE_CART_COUNT", payload }),
    changeWishlistCount: (payload) =>
      dispatch({ type: "CHANGE_WISHLIST_COUNT", payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RsWithRouter(NavCartWishlist));
