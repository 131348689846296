import React from 'react'
import Popup from '../../../Popup/Popup'
import $ from 'jquery'

const TermsHTMLDisplayPopup = ({ content, content_otherlanguage, heading_otherlanguage, templateTitle, ClosePopupView }) => {

    // Component-Related Functions,
    const onPopupCloseClickHandler = () => {
        $('.terms_html_content_display .rs_popup_close').trigger('click');
        ClosePopupView((prevState) => {
            return {
                ...prevState,
                openPopup: false
            }
        })
    };

    return ( // set a minHeight for enalbing scroll
        <Popup className="terms_html_content_display" autoOpen={true} width="1200px" onClose={onPopupCloseClickHandler}>
            <div className='row'>
                <div className='col-6'> 
                    <div className='template_headings_for_content'>
                        <h2><span>{templateTitle}</span></h2> 
                    </div>
                </div>
                <div className='col-6'> 
                    <div className='template_headings_for_content' style={{direction: 'rtl'}}>
                        <h2><span>{heading_otherlanguage}</span></h2>
                    </div>
                </div>
            </div>

            <div className='container-fluid p-0 m-0'>
                {Array.isArray(content) ? 
                    content.map((item, index) => (
                        <div className='row' key={index}>
                            <div className='col-6'>
                                <div className='content_wrapper_html'>  
                                    <div dangerouslySetInnerHTML={{ __html: item?.content }} />
                                </div>
                            </div>
                            <div className='col-6' style={{ direction: 'rtl' }}>
                                <div className='content_wrapper_html'>
                                    <div dangerouslySetInnerHTML={{ __html: item?.content_otherlanguage }} />
                                </div>
                            </div>
                        </div>
                    )) 
                    :
                    <div className='row'>
                        <div className='col-6'>
                            <div className='content_wrapper_html'>  
                                <div dangerouslySetInnerHTML={{ __html: content }} />
                            </div>
                        </div>
                        <div className='col-6' style={{ direction: 'rtl' }}>
                            <div className='content_wrapper_html'>  
                                <div dangerouslySetInnerHTML={{ __html: content_otherlanguage }} />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </Popup>
    )
}

export default TermsHTMLDisplayPopup;