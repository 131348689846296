import Api from "../../inc/Api";
import Settings from "../../inc/Settings";

export default class RFQCommonApiCalls {
  api;

  constructor() {
    this.api = Api;
    this.api.setUserToken();
  }

  async getAllItems() {
    const res = await this.api.axios().get(Settings.apiItemUrl + "/item");
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getAllUnits() {
    const res = await this.api.axios().get(Settings.loginUrl + "/unit");
    if (res.data.status === "success") return res.data.data;
    return res;
  }
  
  async getAllCurrencies() {
    const res = await this.api.axios().get(Settings.apiUrl + "/currency");
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getAllRfqStages() {
    const res = await this.api.axios().get(Settings.apiUrl + "/enum/RFQStages");
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getAllEmployees() {
    const res = await this.api.axios().get(Settings.apiOrgUrl + "/employee_dropdown");
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getAllBuyers() {
    const res = await this.api.axios().get(Settings.apiOrgUrl + "/employee_dropdown?only_buyer=true");
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getAllEntities() {
    const res = await this.api
      .axios()
      .post(Settings.loginUrl + "/view_entity", { user_id: null });
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getAllSites() {
    const res = await this.api
      .axios()
      .get(Settings.loginUrl + "/get_site");
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getAllWarehouses() {
    const res = await this.api
      .axios()
      .get(Settings.loginUrl + "/get_warehouse")
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getRFQLinesComparisons(rfq_id) {
    try {
      const res = await this.api
        .axios()
        .get(Settings.apiPurchaseRequisitionUrl + `/lines_comparison/${rfq_id}`);
  
      if (res.data.status === "success") {
        const dataArray = res.data.data;
  
        if (dataArray && typeof dataArray === 'object') { 
          const transformedData = Object.entries(dataArray).flatMap(([line_id, items]) =>
            items.map(item => ({ ...item, line_id: parseInt(line_id) }))
          );
          return transformedData;
        }
      };
      return [];
    } catch (error) { return [] };
  }

  async getTargetDocumentTypes() {
    const res = await this.api
      .axios()
      .get(Settings.loginUrl + "/enum/pr_targetdocumenttype");
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async convertPRLinesToRFQ(data) {
    const res = await this.api
      .axios()
      .post(Settings.apiPurchaseRequisitionUrl + "/convertPrTo", data);
    if (res.data.status === "success") return res.data;
    return res;
  }

  async getRFQHeaderByID(id) {
    const res = await this.api
      .axios()
      .get(Settings.apiPurchaseRequisitionUrl + "/rfq_header/" + id);
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getAllTeamSourceLines(sourceId, integratorId) {
    const res = await this.api
      .axios()
      .get(
        Settings.loginUrl +
          "/get_teamsourcelines/" +
          sourceId +
          "/" +
          integratorId
      );

    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async addTeamSourceLine(data) {
    const res = await this.api
      .axios()
      .post(Settings.loginUrl + "/teamsourcelines", data);
    if (res.data.status === "success") return res.data;
    return res;
  }

  async updateTeamSourceLine(id, data) {
    const res = await this.api
      .axios()
      .put(Settings.loginUrl + "/teamsourcelines/" + id, data);
    if (res.data.status === "success") return res.data;
    return res;
  }

  async deleteTeamSourceLine(id) {
    const res = await this.api
      .axios()
      .delete(Settings.loginUrl + "/teamsourcelines/" + id);
    if (res.data.status === "success") return res.data;
    return res;
  }

  async getAllTeams(id) {
    const res = await this.api.axios().get(Settings.loginUrl + "/teamheader?dynamic_team_header=false");
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getRfqSecurityLevels() {
    const res = await this.api
      .axios()
      .get(Settings.loginUrl + "/enum/RQ_securitylevel");
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getRfqQuotationTypes() {
    const res = await this.api.axios().get(Settings.loginUrl + "/enum/RQ_type");
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getDeliveryTerms() {
    const res = await this.api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + "/delivery_term");
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getModeOfDelivery() {
    const res = await this.api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + "/mode_of_delivery");
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getPaymentTerms() {
    const res = await this.api
      .axios()
      .get(Settings.apiVendorUrl + "/paymentterm");
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getVendorByVendorId(vendor_id) {
    const res = await this.api
      .axios()
      .get(Settings.apiVendorUrl + `/vendor/${vendor_id}`);
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getTeamlinesByHeaderId(id) {
    const res = await this.api
      .axios()
      .get(Settings.loginUrl + "/teamlines_header/" + id);
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async updateRfqHeader(id, data) {
    const res = await this.api
      .axios()
      .put(Settings.apiPurchaseRequisitionUrl + "/rfq_header/" + id, data);
    if (res.data.status === "success") return res.data;
    return res;
  }

  async updateVenorReplyHeader(id, data) {
    const res = await this.api
      .axios()
      .put(
        Settings.apiPurchaseRequisitionUrl + "/RFVendorreplyheader/" + id,
        data
      );
    if (res.data.status === "success") return res.data;
    return res;
  }

  async getAllRfqVendors(id) {
    const res = await this.api
      .axios()
      .get(Settings.apiPurchaseRequisitionUrl + `/get_vendors_of_RFQ/${id}`);
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getAllVendorReplyHeaderById(id) {
    const res = await this.api
      .axios()
      .get(Settings.apiPurchaseRequisitionUrl + `/RFVendorreplyheader/${id}`);
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getAllRfqHeaders(pageNumber, pageSize, rfq_status, vendor_reply) {
    const base_url = vendor_reply ? '/RFvendorreplyheader' : '/rfq_header';
    let api_url = `${base_url}?page_number=${pageNumber}&page_size=${pageSize}`;
    
    if (rfq_status !== null) api_url += `&rfq_status=${rfq_status}`;
    const res = await this.api
      .axios()
      .get(Settings.apiPurchaseRequisitionUrl + api_url); 
    if (res.data.status === "success") return res.data;
    return res;
  }

  async getAllRFIHeaders(pageNumber,pageSize,user_id) {
    let api_url = `/rfq_header?rfq_type=1&page_number=${pageNumber}&page_size=${pageSize}`
    if(user_id) api_url+= `&createdby=${user_id}`
    const res = await this.api
      .axios()
      .get(Settings.apiPurchaseRequisitionUrl+api_url);
    if (res.data.status === "success") return res.data;
    return res;
  }

  async getAllRFIHeadersByUserId(user_id) {
    const res = await this.api
      .axios()
      .get(Settings.apiPurchaseRequisitionUrl + `/rfq_header?rfq_type=1&createdby=${user_id}`);
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getAllRfqStatus() {
    const res = await this.api
      .axios()
      .get(Settings.loginUrl + "/enum/RFQ_status");
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getAllRfqVendorReplyStatus() {
    const res = await this.api
      .axios()
      .get(Settings.loginUrl + "/enum/RFvendorreplyheaderstatus");
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getDynamicTeams() {
    const res = await this.api.axios().get(Settings.apiUrl+'/teamheader?dynamic_team_header=true');
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getTemplates() {
    const res = await this.api.axios().get(Settings.apiPurchaseRequisitionUrl+`/get_all_documents_template/${Settings.source_id.request_for_quotation}`);
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async convertToTemplate(payload) {
    const res = await this.api
      .axios()
      .post(Settings.apiPurchaseRequisitionUrl + "/convert_document_to_template", payload);
    if (res.data.status === "success") return res.data;
    return res;
  }

  async getAllTaxGroups() {
    const res = await this.api.axios().get(Settings.apiUrl + "/taxgroup");
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async getAllTaxGroupItems() {
    const res = await this.api.axios().get(Settings.apiUrl + "/taxgroupitem");
    if (res.data.status === "success") return res.data.data;
    return res;
  }

  async initiateBAFO(payload,cancelToken) {
    const res = await this.api
      .axios()
      .post(Settings.apiPurchaseRequisitionUrl + "/initiate_bafo", payload, cancelToken);
    if (res.data.status === "success") return res.data;
    return res;
  }
}


