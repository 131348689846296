import React from 'react'
import Api from '../../inc/Api'
import Settings from '../../inc/Settings'
import DatepickerFunctions from '../../inc/DatepickerHelper'
import { getPopupMessageBasedOnStatus } from '../../inc/Validation'
import Helper from '../../inc/Helper'
import { useNavigate } from 'react-router-dom'

const NotificationTile = ({notification,setNotifications,notifications}) => {

  const navigate = useNavigate()

  const responseUrl = notification?.notification?.meta_data;
  const parsedUrl = typeof responseUrl === 'string' ? JSON.parse(responseUrl.replace(/\\/g, '')) : responseUrl;

  const getNavigationLink = () => {
    return typeof notification?.notification?.meta_data === 'object' ?
    navigate(`/${notification?.notification?.meta_data?.url}`) :
    navigate(`/${parsedUrl?.url}`)
  }

  const handleNavigationTileClick = () => {
    if(notification?.acknowledge){      
       getNavigationLink()
        return
    }
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(
        Settings.apiUrl +
          `/ack_notifications/${notification?.notification_id}`
      )
      .then(function (res) {
        if (res.data.status === 'success') {
            Helper.alert(res.data.message,"success")
            let newNotificationsData = notifications.map((item) =>
              item.notification_id === notification?.notification_id
                ? { ...item, acknowledge: true }
                : item
            )
            setNotifications(newNotificationsData)
            setTimeout(()=>{
               getNavigationLink()
            },300)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
      })
  }
  
  return (
    <div className='d-flex justify-content-between notification-tile' onClick={handleNavigationTileClick}>
        <div className='d-flex flex-column notification-info'>
            <h6>{notification?.notification?.body}</h6>
            <p>{DatepickerFunctions.convertDateTimeFromDataBase(notification?.createddatetime)}</p>
        </div>
        {!notification?.acknowledge && <div className='notification-status-div'></div>}
    </div>
  )
}

export default NotificationTile