import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import MasterComonent from "../../components/Backend/MasterComonent";
import InnerScreen from "../../components/Inner Screens/InnerScreen";
import Helper from "../../inc/Helper";

const RequestScreen = ({ language, auth }) => {
  return (
    <div className="modules_screen_lynkaz">
      <MasterComonent>
        <InnerScreen innerTitle="Request">
          <div className="col-lg-3 col-md-12 gy-4">
            <div className="inner-page-header border-bottom">
              <h2>
                {Helper.getLabel(
                  language,
                  "purchase_requistition",
                  "Purchase Requisitions"
                )}
              </h2>
            </div>
            
            <div className="inner-page-routes">
              <Link to="/purchase-requisition">
                {Helper.getLabel(
                  language,
                  "purchase_requistition",
                  "Purchase Requisitions - All"
                )}
              </Link>
              <Link to='/request-for-quotation/allrequisitions'>
                {Helper.getLabel(
                  language,
                  'purchase_requisition_open',
                  'Purchase Requisitions - Open'
                )}
              </Link>
              <Link to={`/purchase-requisition?user_id=${auth?.user?.user_id}`}>
                {Helper.getLabel(
                  language,
                  'purchase_requisition_created_by_me',
                  'Purchase Requisitions - Created by me'
                )}
              </Link>
            </div>
          </div>

          <div className='col-lg-4 col-md-12 gy-4'>
            <div className='inner-page-header border-bottom'>
              <h2>
                {Helper.getLabel(
                  language,
                  'requestion_for_informations',
                  'Requestion for Informations'
                )}
              </h2>
            </div>
            <div className='inner-page-routes'>
              <Link to="/request-for-informations">
                {Helper.getLabel(
                  language,
                  "request_for_informations",
                  "Requestion for Informations (RFI)"
                )}
              </Link>
              <Link to={`/request-for-informations?user_id=${auth?.user?.user_id}`}>
                {Helper.getLabel(
                  language,
                  'request_for_informations_created_by_me',
                  'Requestion for Informations (RFI) - Created by me'
                )}
              </Link>
            </div>
          </div>
        </InnerScreen>
      </MasterComonent>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
  };
};

export default connect(mapStateToProps)(RequestScreen);