import Helper from "./Helper";
import $ from "jquery";
import Settings from "./Settings";
import e from "jquery-datetimepicker"; 
import * as XLSX from 'xlsx';
import DatepickerFunctions from "./DatepickerHelper";

export const ValidateData = (Data) => {
  const found = Object.values(Data).every((value) => {
    if (value === null || value === "" || value === undefined) {
      return true;
    } else {
      return false;
    }
  });
  return found;
};

export const extractPropValue = (obj, propName) => { 
  if (obj.hasOwnProperty(propName)) { 
    return obj[propName];
  } 
  return null;
}

export const filterVisibleColumns = (columns) => {
  return columns ? columns.filter(column => column.hide === undefined || column.hide === false || !column.hide) : [];
};

export const apiHasParams = (inputString) => { 
  return inputString.includes('?');
}

export const checkSegmentLength = (segmentsGrid) => {
  let totalLength = 0;

  if (!segmentsGrid || segmentsGrid.length === 0) {
    return { result: false, remaining: 15 };
  }

  for (let i = 0; i < segmentsGrid.length; i++) {
    const segmentLength = segmentsGrid[i]?.segment_value?.length || 0;
    totalLength += segmentLength;

    if (totalLength > 15) {
      return { result: true, remaining: 0 };  
    }
  }

  return { result: false, remaining: 15 - totalLength }; 
};

export const checkIfArrayIsEmpty = (arr) => {
  if (Array.isArray(arr)) return arr.length === 0;
  return false;
};

export const checkForRevokePermission = (permissionsObject, checkParam) => { 
  for (const [key, value] of Object.entries(permissionsObject)) { 
    if (value.includes(checkParam) && value.includes("Revoke")) {
      return true;
    }
  }
  return false;
};

export const checkIfUserExistInScorings = (login_user_id, userScoreList) => {
  return userScoreList?.some(item => item?.user_id === login_user_id);
};
 
export const calculateCompliancePerc = (total, length) => { 
  let Length = (total / length);
  return (Length * 100).toFixed(1)
}

export const countComplianceOccurrences = (vendorreplyId, complianceId, responses) => { 
  let count = 0;
 
  for (const response of responses) { 
    if (response.rfq_vendorreply_id === vendorreplyId && response.compliance_id === complianceId) {
      count++;
    }
  }
 
  return count;
}
 
export const calculateSubArrayTotals = (inputArray, lines, rfqVendors) => {
  const vendorTotals = [];
  const CalculatedArray = [];

  for (const subArray of inputArray) {
    for (const object of subArray) {
      if (object.vendorreply_id !== undefined && object.total !== 0) {
        const vendorIndex = vendorTotals.findIndex((vendor) => vendor.vendorreply_id === object.vendorreply_id);

        if (vendorIndex !== -1) {
          vendorTotals[vendorIndex].total += object.total;
          vendorTotals[vendorIndex].percentage = calculateCompliancePerc(vendorTotals[vendorIndex].total, lines);
        } else {
          vendorTotals.push({
            vendorreply_id: object.vendorreply_id,
            total: object.total,
            percentage: object.percentage || 0,
          });
        }
      }
    }
  }

  // Prepare CalculatedArray based on rfqVendors
  for (let i = 0; i < rfqVendors?.length; i++) {
    const matchingVendor = vendorTotals.find((vendor) => vendor.vendorreply_id === rfqVendors[i]?.vendorreply_id);

    if (matchingVendor) {
      CalculatedArray.push(matchingVendor);
    } else {
      CalculatedArray.push({
        vendorreply_id: rfqVendors[i]?.vendorreply_id,
        total: 0,
        percentage: 0,
      });
    }
  }
 
  return CalculatedArray;
};

export const createSummaryGridArray = (numArrays, vendorRFQArray, RFQComplianceResponses, RFQResponseLines, TotalLines) => {
  const result = [];

  for (let i = 0; i < numArrays; i++) {
    let subArray = [];
    let compliance_id = RFQResponseLines[i]?.compliance_id;

    for (let vendor = 0; vendor < vendorRFQArray?.length; vendor++) {
      let total = countComplianceOccurrences(vendorRFQArray[vendor]?.vendorreply_id, compliance_id, RFQComplianceResponses);
      let percentage = calculateCompliancePerc(total, TotalLines);
      
      subArray.push({ 
        total: total, 
        compliance_id: compliance_id, 
        vendorreply_id: vendorRFQArray[vendor]?.vendorreply_id, 
        percentage: percentage 
      });
    }
    result.push(subArray)
  }

  return (result);
};
 
export const replaceLastArray = (arr1, arr2) => {
  if (Array.isArray(arr1) && Array.isArray(arr2) && arr1.length > 0) {
    arr1[arr1.length - 1] = arr2;
  }
  return arr1;
}

export const segmentNumberSequenceFormat = (segmentsGrid) => {
  if (!Array.isArray(segmentsGrid) || segmentsGrid?.length === 0) return '';

  return segmentsGrid
    .filter(gridItem => gridItem?.segment_value)
    .map(gridItem => gridItem.segment_value)
    .join('');
};
 
export const convertStringToLowerCaseRemoveHypens = (inputString) => { 
  const convertedString = inputString.replace(/[- ]/g, '').toLowerCase(); 
  return convertedString;
}

export const submergeComplianceResponses = (array1, array2, array3) => {
  const combinedArray = [];

  array1.forEach((item) => {
    let matched = false;

    array2.forEach((subItem) => {
      array3.forEach((childItem) => {
        if (item.compliance_templateline_id === subItem.compliance_templateline_id) {
          if(subItem.compliance_id === childItem.compliance_id) { 
            combinedArray.push({
              ...item, 
              ...subItem,  
              date_mandatory: childItem.date_mandatory,
              comments_mandatory: childItem.comments_mandatory, 
              compliance_date: subItem?.compliance_date
            });
            matched = true;
            return;
          }
        }
      }) 
    });

    if (!matched) {
      combinedArray.push({
        ...item,
        compliance_id: null,
        comments_mandatory: false,
        comments: null,
        date_mandatory: false,
        compliance_date: null,
        color: null
      });
    }
  });

  return combinedArray;
}

export const combineComplianceResponsesWithTemplateLines = (array1, array2) => {
  // Create a map of compliance_templateline_id to associated objects from array2
  const map = new Map();
  for (const item of array2) {
    const id = item.compliance_templateline_id;
    if (!map.has(id)) {
      map.set(id, []);
    }
    map.get(id).push(item);
  }

  // Combine arrays
  const result = array1.map((item1) => {
    const id = item1.compliance_templateline_id;
    if (map.has(id)) {
      // Combine item1 with items from array2
      return {
        ...item1,
        compliances: map.get(id),
      };
    } else {
      // No matches in array2
      return {
        ...item1,
        compliances: [],
      };
    }
  });

  return result;
}

export const findLongestArrayLength = (arrayOfObjects, objProp) => {
  if (!Array.isArray(arrayOfObjects) || arrayOfObjects.length === 0) { 
    return 0;
  }

  let longestLength = 0;

  for (const obj of arrayOfObjects) {
    if (obj.hasOwnProperty(objProp)) {  
      const currentArray = obj[objProp];  

      if (Array.isArray(currentArray) && currentArray.length > longestLength) {
        longestLength = currentArray.length;
      }
    }
  }

  return longestLength;
}

export const fixColsData = (data) => {
  let o = '';
  let l = 0;
  const w = 10240;
  for (; l < data.byteLength / w; ++l) {
    o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)));
  }
  o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
  return o;
}

export const updateExcelDataProps = (inputArray) => {
  const updatedArray = [];

  for (const item of inputArray) {
    const updatedItem = {};

    let hasError = false;

    for (const key in item) {
      const updatedKey = convertStringToLowerCaseRemoveHypens(key);

      if ((updatedKey === "category" || updatedKey === "subcategory") && item[key].length > 40) {
        Helper.alert(`Please follow 40 character's limit for both Category & Sub-Category Columns!`, 'error');
        hasError = true;
        break;
      } else {
        updatedItem[updatedKey] = item[key];
      }
    }

    if (!hasError) {
      updatedArray.push(updatedItem);
    }
  }

  return updatedArray;
};

export const validateHeaderObjectsFromExcel = (inputArray) => {
  if (inputArray.length > 3) {
    Helper.alert("Only 3 columns listed on grid header required!", 'error');
    return false;
  }

  const allowedFields = ["category", "subcategory", "description"];
  let hasError = false;

  for (const obj of inputArray) {
    if (obj.field) {
      if (!allowedFields.includes(obj.field)) {
        Helper.alert("The Excel doesn't contain the required fields", 'error');
        hasError = true;
      }
    } else {
      Helper.alert("Invalid Excel data found", 'error');
      hasError = true;
    }
  }

  return !hasError;  
}

export const getHeaderRowData = (sheet) => {
  const headers = [];
  const range = XLSX.utils.decode_range(sheet['!ref']);
  for (let C = range.s.c; C <= range.e.c; ++C) {
    const cell = sheet[XLSX.utils.encode_cell({ c: C, r: range.s.r })];
    let hdr = null; 
    if (cell && cell.t) {
      hdr = XLSX.utils.format_cell(cell);
    } else {
      continue;
    }
    const column = { field: convertStringToLowerCaseRemoveHypens(hdr) };
    headers.push(column);
  }
  return headers;
}

export const SourceIdentificationFromDocumentType = (
  document_type,
  current_type,
  type
) => {
  if(type==="purchase agreement"){
    return Settings.source_id.purchase_agreement
  }
  let source_label = document_type?.find(
    (val) => val.value === current_type
  )?.label;
  let formatedLabel = convertSourceToStandard(source_label);
  let source_object = Settings?.source_id;
  let source_id = source_object[formatedLabel] || null;
  
  return source_id;
};

export const convertSourceToStandard = (source) => {
  // convert the following text to lowercase & add _ separator if spaces found - This function is maintaining our standard settings
  if (!source) return "";
  const lowercaseText = source.toLowerCase();
  const formattedText = lowercaseText.replace(/\s+/g, "_");
  return formattedText;
};

export const toggleActiveClassNameForDefaultSite = () => {
  $(document).ready(function () {
    const defaultItem = $(".treeitem")[0];
    $(defaultItem).addClass("treeview_sub_item_active");

    $(".treeitem").on("click", function () {
      $(".treeitem").removeClass("treeview_sub_item_active");
      $(this).addClass("treeview_sub_item_active");
    });
  });
};

export const replaceMmWithMM = (inputString) => {
  if (typeof inputString == "string") {
    const replacedString = inputString?.replace(/mm/g, "MM");
    return replacedString;
  }
};

export const transformSplitArrayData = (array, RFQVendors) => {
  const RowData = array.map((item) => {
    const {
      total_score,
      total_score_in_percentage,
      scorereplyheader_id,
      disqualified,
      line_recommendation,
      rfq_vendorreply_id,
    } = item;
    return {
      total_score,
      total_score_in_percentage,
      scorereplyheader_id,
      disqualified,
      line_recommendation,
      rfq_vendorreply_id,
    };
  });

  const ColumnHeadersData = array.map((item) => {
    const { rfq_vendorreply_id } = item;

    const correspondingEntry = RFQVendors.find(
      (item) => item.reply_id === rfq_vendorreply_id
    );

    const evaluator_name = correspondingEntry ? correspondingEntry.vendor : "-";

    return { rfq_vendorreply_id, evaluator_name };
  });

  return { RowData, ColumnHeadersData };
};

export const ArrayInitializerOfObjects = (data) => {
  const arrayOfObjects = [];

  for (let i = 0; i < data.length; i++) {
    const { reply_id } = data[i];
    arrayOfObjects.push({
      total_score: 0,
      total_score_in_percentage: 0,
      disqualified: false,
      line_recommendation: 0,
      rfq_vendorreply_id: reply_id,
      scorereplyheader_id: null,
    });
  }

  return arrayOfObjects;
};

export const compareGridResponseArrays = (array1, array2) => { 
  const differentObjects = [];
 
  array1.forEach((obj1) => { 
    const obj2 = array2.find((item) => item.compliance_id === obj1.compliance_id);
 
    if (obj2) { 
      for (const key in obj1) {
        if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key) && key !== 'setRowDataGridRowId') { 
          if (obj1[key] !== obj2[key]) { 
            differentObjects.push(obj2);
            break;  
          }
        }
      }
    } else { 
      differentObjects.push(obj1);
    }
  });

  return differentObjects;
};

export const compareGridResponseComplianceArrays = (array1, array2) => {
  const differentObjects = [];
 
  array1.forEach((obj1) => { 
    const obj2 = array2.find((item) => item.compliance_templateline_id === obj1.compliance_templateline_id);
 
    if (obj2) { 
      for (const key in obj1) {
        if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key) && key !== 'setRowDataGridRowId') { 
          if (obj1[key] !== obj2[key]) { 
            differentObjects.push(obj2);
            break;  
          }
        }
      }
    } else { 
      differentObjects.push(obj1);
    }
  });

  return differentObjects;
}
 
export const ArrayToArrayTransformation = (inputArray) => {
  const transformedArray = inputArray.map((item) => ({
    rfq_vendorreply_id: item.reply_id,
    evaluator_name: item.vendor,
  }));

  return transformedArray;
};

export const getLocalTimeAndDate = (
  utcTimeString,
  targetCountry = "Asia/Karachi"
) => {
  if (!utcTimeString) return;

  const utcTime = new Date(utcTimeString);

  // Options for time formatting
  const timeOptions = {
    timeZone: targetCountry,
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  };

  // Options for date formatting
  const dateOptions = {
    timeZone: targetCountry,
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const time = utcTime.toLocaleString("en-US", timeOptions);
  const date = utcTime.toLocaleString("en-US", dateOptions);

  let datetime = date + "  " + time;

  return datetime;
};

export const extractCriteriaArray = (objects) => {
  const criteriaArray = [];

  for (let i = 0; i < objects.length; i++) {
    const object = objects[i];

    // Extract the "criteria" array from each object
    const criteria = object.criteria;
    criteriaArray.push(...criteria);
  }

  return criteriaArray;
};

export const extractPropsValue = (array, propName) => {
  const idArray = [];

  array.forEach((obj) => {
    const idAttr = obj[propName];

    if (typeof idAttr === "number" && !isNaN(idAttr)) {
      idArray.push(idAttr);
    }
  });

  return idArray;
};

export const calculatePercentage = (totalScore, numberOfCriterias) => {
  let totalCriterias = numberOfCriterias * 10;
  let percentage = (totalScore / totalCriterias) * 100;

  return parseFloat(percentage).toFixed(2);
};

export const filterByReference = (arr1, arr2) => {
  let res = [];
  res = arr1.filter((el) => {
    return !arr2.find((element) => {
      return element.role_id === el.role_id;
    });
  });
  return res;
};

export const moveLastToTop = (arr) => {
  const lastElement = arr.pop();
  arr.unshift(lastElement);
  return arr;
};

export const checkValue = (value) => {
  if (value === null || value === "" || /^\s*$/.test(value)) {
    return false;
  } else {
    return true;
  }
};

export const objectIsEmpty = (obj) => {
  if (!obj) {
    return true;
  } else if (Array.isArray(obj) || typeof obj === "string") {
    return obj.length === 0;
  } else if (typeof obj === "object") {
    return Object.keys(obj).length === 0;
  } else {
    return false;
  }
};

export const isEmptyObject = (obj) => {
  if (obj === null || obj === undefined) {
    return true;
  }
  return Object.keys(obj).length === 0;
};

export const multipleUserScorings = (inputArray, rfqVendors, evaluatorArray, totalPercentageSum = 0) => {
  const commonObjects = {};
  const rfqVendorReplyIdsSet = new Set(rfqVendors?.map(vendor => vendor?.reply_id));
 
  inputArray?.forEach(obj => {
    const rfqVendorReplyId = obj?.rfq_vendorreply_id;
    if (rfqVendorReplyIdsSet.has(rfqVendorReplyId)) {
      if (!commonObjects[rfqVendorReplyId]) {
        commonObjects[rfqVendorReplyId] = {
          rfq_vendorreply_id: rfqVendorReplyId,
          scores: [],
          total_score: 0,
          total_weighted_percentage: 0
        };
      }
      const entry = commonObjects[rfqVendorReplyId];
      entry.scores.push(obj);
      entry.total_score += obj.total_score;
      entry.total_weighted_percentage += obj.total_score_in_percentage;
    }
  });
 
  rfqVendors?.forEach(vendor => {
    const rfqVendorReplyId = vendor?.reply_id;
    if (!commonObjects[rfqVendorReplyId]) {
      commonObjects[rfqVendorReplyId] = {
        rfq_vendorreply_id: rfqVendorReplyId,
        scores: null,
        total_score: 0,
        total_weighted_percentage: 0
      };
    }
  });
 
  for (const item of Object.values(commonObjects)) {
    const totalScore = item.total_score;
    item.total_weighted_percentage = totalScore > 0
      ? parseFloat(((totalScore / totalPercentageSum) * 100).toFixed(2))
      : 0;
  }
 
  const simplifiedObjects = rfqVendors?.map(vendor => commonObjects[vendor?.reply_id]);
 
  const evaluatorNameIndexMap = new Map(evaluatorArray?.map((evaluator, index) => [evaluator?.evaluator_id, index]));
 
  const sortedSimplifiedObjects = simplifiedObjects?.map(item => {
    if (item?.scores) {
      item?.scores?.sort((a, b) => {
        const indexA = evaluatorNameIndexMap.get(a?.evaluator_id) ?? Infinity;
        const indexB = evaluatorNameIndexMap.get(b?.evaluator_id) ?? Infinity;
        return indexA - indexB;
      });
    }
    return item;
  });
 
  return { simplifiedObjects: sortedSimplifiedObjects };
};

 
export const removeDuplicates = (arr) => {
  let newArr = arr.filter((c, index) => {
    return arr.indexOf(c) === index;
  });
  return newArr;
};

export const convertObjectValuesToInteger = (obj) => {
  const convertedObject = {};

  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      const value = obj[prop];
      const convertedValue = parseInt(value, 10);
      convertedObject[prop] = convertedValue;
    }
  }

  return convertedObject;
};

export const getNextIncrementalNumber = (data) => {
  const sequenceValues = data
    .filter((item) => typeof item.sequence === "number")
    .map((item) => item.sequence); // Extract numeric 'sequence' values from the data

  const sortedSequenceValues = [...new Set(sequenceValues)].sort(
    (a, b) => a - b
  ); // Remove duplicates and sort the sequence values in ascending order

  for (let i = 0; i < sortedSequenceValues.length; i++) {
    if (sortedSequenceValues[i] !== i + 1) {
      return i + 1; // Return the next incremental number
    }
  }

  return sortedSequenceValues.length + 1; // If there are no gaps, return the next incremental number after the maximum value
};

export const hasSameSequenceValues = (data) => {
  const sequenceSet = new Set();

  for (let i = 0; i < data.length; i++) {
    const sequenceValue = data[i].sequence;

    if (sequenceSet.has(sequenceValue)) {
      return true; // Found a duplicate sequence value
    }

    sequenceSet.add(sequenceValue);
  }

  return false; // No duplicate sequence values found
};

export const checkTextAreasHavingValues = () => {
  let disabledTextAreas = document.querySelectorAll("textarea.required");
  for (let textarea of disabledTextAreas) {
    if (textarea?.value?.trim() === '') {
      return false;
    }
  }
  return true;
}

export const checkInputFieldsSliderValues = () => {
  const inputElements = document.querySelectorAll('input[name="range_slider_scores_rfq"]');
  return Array.from(inputElements).some(input => input.value !== '0');
};
 
export const checkLineRecommendations = (arr) => {
  for (const obj of arr) {
    if (obj?.line_recommendation > 0) {
      return false;
    }
  }
  return true;
};
 
export const areObjectsDifferent = (obj1, obj2) => {
  for (const key in obj1) {
    if (
      obj1.hasOwnProperty(key) &&
      obj1[key] !== null &&
      typeof obj1[key] === "object"
    ) {
      // recursively compare nested objects
      if (areObjectsDifferent(obj1[key], obj2[key])) {
        return true;
      }
    } else {
      // compare values of non-nested properties
      if (obj1[key] !== obj2[key]) {
        return true;
      }
    }
  }
  return false;
};
 
export const checkDefault = (array) => {
  const updatedArray = [...array]; // Create a copy of the input array
  let count = 0;

  let latestIndex = -1;

  for (let i = 0; i < updatedArray.length; i++) {
    if (updatedArray[i].is_default) {
      latestIndex = i;
    }
  }

  if (latestIndex !== -1) {
    for (let i = 0; i < latestIndex; i++) {
      updatedArray[i].is_default = false;
      count++;
    }
  }

  const isExist = count > 1;

  return {
    isExist: isExist,
    newArr: updatedArray,
  };
};

export const getObjectsWithNullImageId = (newObjArr) => {
  const nullImageIdObjs = newObjArr.filter((obj) => obj.image_id == null);
  if (nullImageIdObjs.length === 0) {
    return false;
  } else {
    return nullImageIdObjs;
  }
};

export const isGreaterThan32Bits = (num) => {
  return num >= Math.pow(2, 32);
};

export const excludeProperty = (obj, propertyToExclude) => {
  const { [propertyToExclude]: property, ...rest } = obj;
  return rest;
};

export const isGreater = (value1, value2) => {
  return value1 > value2;
};

export const mergeArrays = (defaultArray, manipulatedArray) => {
  let result = [];
  defaultArray.forEach((defaultObj) => {
    let manipulatedObj = manipulatedArray.find(
      (o) => o.segment_id == defaultObj.segment_id
    );
    if (manipulatedObj) {
      let mergedObj = { ...defaultObj, ...manipulatedObj };
      result.push(mergedObj);
    } else {
      result.push(defaultObj);
    }
  });
  return result;
};

export const isWithinRange = (value, min, max) => {
  return value >= min && value <= max;
};

export const checkObjectProperties = (obj) => {
  for (const prop in obj) {
    if (obj[prop] !== null) {
      return true;
    }
  }
  return false;
};

export const checkItemPricingObjEmpty = (obj) =>
  obj.price !== null && obj.price !== "";

export const checkItemDiscountObjEmpty = (obj) => {
  const values = Object.values(obj);
  return values.some(
    (prop) =>
      prop !== null &&
      !Number.isNaN(prop) &&
      prop !== "" &&
      !(prop === 1 && obj.discount_unit === 1)
  );
};

export const checkObjectResponseCodes = (obj) => {
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = obj[key];
    if (value !== null && value === 500) {
      return false;
    }
  }
  return true;
};

export const checkNullProperties = (arr) => {
  for (let i = 0; i < arr.length; i++) {
    const obj = arr[i];
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop) && obj[prop] != null) {
        return false;
      }
    }
  }
  return true;
};

export const formatTime = (timeString) => {
  const timeParts = timeString.split(":");
  const hours = timeParts[0];
  const minutes = timeParts[1];
  return `${hours}:${minutes}`;
};

export const is_valid_input = (input_str) => {
  // Check if the input contains only digits
  if (!/^\d+$/.test(input_str.trim())) {
    return false;
  }

  // Convert the input to an integer
  let input_int = parseInt(input_str.trim());

  // Check if the input is between 1 and 31 (inclusive)
  if (input_int < 1 || input_int > 31) {
    return false;
  }

  return true;
};

export const validateArrayHavingMoreThanObj = (array) => {
  const segmentType3Objects = array.filter((obj) => obj.segment_type == "3");
  if (segmentType3Objects.length > 1) {
    return false;
  } else {
    return true;
  }
};

export const updateArray = (updateArray, defaultArray) => {
  const updatedArray = defaultArray.map((defaultObj) => {
    const updatedObj = updateArray.find(
      (updateObj) => updateObj.segment_id == defaultObj.segment_id
    );
    if (updatedObj) {
      return { ...defaultObj, ...updatedObj };
    }
    return defaultObj;
  });
  return updatedArray;
};

export const calculateSmallestLargestNumbersOnDigits = (numOfDigits) => {
  if (numOfDigits < 1) return;

  const smallest = "0".repeat(numOfDigits - 1) + "1";
  const largest = "9".repeat(numOfDigits);

  return { smallest, largest };
};

export const validateArrayHavingAtleastOneObj = (arr) => {
  return arr.some((obj) => obj.segment_type == "3");
};

export const getFormattedDateWithoutTime = (dateString) => {
  if (dateString) {
    const datePart = dateString.split(",")[0].trim();

    return datePart;
  }
  return null;
};

export const getTimeWithoutDate = (dateString) => {
  if (dateString) {
    const timeString = dateString.split(",")[1]?.trim();

    return timeString;
  }
  return null;
};

export const ValidateArray = (dataArray) => {
  let found;
  dataArray.forEach((element) => {
    Object.values(element).every((value) => {
      if (value === null || value === "" || value === undefined) {
        found = true;
      } else {
        found = false;
      }
    });
  });
  return found;
};
 
export const formatDateWithTime = (dateString) => {
  const date = new Date(dateString);

  // Array of month names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get day, month, and year components
  const day = date.getDate().toString().padStart(2, "0");
  const monthIndex = date.getMonth();
  const month = monthNames[monthIndex];
  const year = date.getFullYear();

  // Get hour and minute components
  let hour = date.getHours();
  let minute = date.getMinutes();
  let period = "am";

  // Convert hour to 12-hour format and determine period (am/pm)
  if (hour >= 12) {
    period = "pm";
    hour = hour % 12;
  }
  if (hour === 0) {
    hour = 12;
  }

  // Format the time with leading zeroes
  hour = hour.toString().padStart(2, "0");
  minute = minute.toString().padStart(2, "0");

  // Combine the date and time components
  const formattedDate = `${day} ${month} ${year}, ${hour}:${minute}${period}`;

  return formattedDate;
};

export const calculateNewDate = (inputDate) => {
  const dateParts = inputDate.split("-");
  if (dateParts.length !== 3) {
    throw new Error("Invalid date format");
  }

  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]);
  const day = parseInt(dateParts[2]);

  // Create a new date object with the correct order of parameters (year, month, day)
  const dateObject = new Date(year, month - 1, day);

  // Add 1 year and subtract 1 day
  dateObject.setFullYear(dateObject.getFullYear() + 1);
  dateObject.setDate(dateObject.getDate() - 1);

  // Format the new date
  const newYear = dateObject.getFullYear().toString();
  const newMonth = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const newDay = dateObject.getDate().toString().padStart(2, "0");

  return `${newYear}-${newMonth}-${newDay}`;
};

//checks for inputType, and onChange field data
export const VallidateFields = (fieldData, inputType) => {
  let validate = false;
  if (inputType === "string" || inputType === "text") {
    if (fieldData !== "" || fieldData !== null) {
      validate = true;
    }
  }
  if (inputType === "number" || inputType === "integer") {
    if (fieldData !== "" || fieldData !== null) {
      validate = true;
    }
  }
  return validate;
};

// accepts only alphabet, number, hyphen or underscore
export const isValidDocumentNumber = (input) => {
  var regex = /^[a-zA-Z0-9_-]*$/;
  return regex.test(input);
};

export const checkArraysValues = (array1, array2) => { 
  if (array1.length !== array2.length) {
    return false;  
  }
 
  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;  
    }
  } 
  return true;
}
 
// accepts only number
export const isValidNumber = (input) => {
  var regex = /^[0-9.]*$/;
  return regex.test(input);
};

export const isValidDiscountPercentage = (input) => {
  const regex = /^$|^[0-9]+(\.[0-9]+)?$/; // Regular expression for numbers with decimal or empty string

  try {
    if (!regex.test(input)) {
      return false;
    }

    if (input === "") {
      return true;
    }

    let num = parseFloat(input);
    if (num >= 0 && num <= 100) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const findMatchingDisqualifiedObjects = (array1, array2) => {
  const mismatchedObjects = [];

  for (const obj1 of array1) {
    const obj2 = array2.find(
      (obj) => obj.scorereplyheader_id === obj1.scorereplyheader_id
    );
    if (obj2 && obj2.disqualified !== obj1.disqualified) {
      mismatchedObjects.push(obj1);
    }
  }

  return mismatchedObjects;
};

// -- Custom Functions;
 
export const calculateTotalScoringPercentages = (allVendorsList) => {
  let extractVendorReplyArr = extractPropsValue(allVendorsList, "reply_id");
  extractVendorReplyArr.forEach((item) => {
    let rangeSliderDivs = document.querySelectorAll(
      `div[name="range_slider_${item}"]`
    );
    let totalValue = 0;
    let totalInputs = 1;

    rangeSliderDivs.forEach((rangeSliderDiv) => {
      let inputField = rangeSliderDiv.querySelector(
        'input[name="range_slider_scores_rfq"]'
      );
      
      if (!inputField.disabled) {
        let inputValue = parseFloat(inputField.value);
        totalValue += inputValue;
        totalInputs++;
      }
    });

    if (totalInputs) {
      let totalScorePercentage = calculatePercentage(totalValue, totalInputs - 1);
      
      let totalScore = document.querySelector(
        `td span[name="total_score_${item}"]`
      );
      let totalPerctScore = document.querySelector(
        `td span[name="score_prct_${item}"]`
      );

      if(totalScore && totalPerctScore) {
        totalScore.textContent = totalValue;
        totalPerctScore.textContent = `${totalScorePercentage}%`;
      } 
    }
  });
};

export const disableTextAreaInputFields = (ArrayRFQMasterScoring) => {
  let allCriterias = extractCriteriaArray(ArrayRFQMasterScoring);
  let idsArrayCriteria = extractPropsValue(
    allCriterias,
    "scoringcriterialdetailrfq_id"
  );

  idsArrayCriteria.forEach((item) => {
    let respectiveToggle = document.querySelectorAll(
      `input[name="checkbox_field_rfq_${item}"]`
    );
    let divElements = document.querySelectorAll(
      `.column[data-name="criteria_${item}"]`
    );

    if (respectiveToggle.length > 0) {
      let firstCheckbox = respectiveToggle[0];
      let isChecked = firstCheckbox.checked;

      divElements.forEach((divElement) => {
        let inputElement = divElement.querySelector(
          'input[name="range_slider_scores_rfq"]'
        );
        let textareaElement = divElement.querySelector(
          'textarea[name="comment_field_rfq[][]"]'
        );

        if (inputElement && textareaElement) {
          inputElement.disabled = isChecked;
          textareaElement.disabled = isChecked;
        }
      });
    }
  });
};

export const getCalculatedDataFromFormData = (
  comment,
  score,
  formData,
  RFQScoringMasterSections,
  RFQVendors
) => {
  let result = [];
  let allCriterias = extractCriteriaArray(RFQScoringMasterSections);
  let index = allCriterias.length * RFQVendors.length;
  for (let i = 0; i < index; i++) {
    let criteriaIndex = Math.floor(i / RFQVendors.length);
    let vendorIndex = i % RFQVendors.length;

    let scoringcriterialdetailrfq_id =
      allCriterias[criteriaIndex].scoringcriterialdetailrfq_id;
    let vendorreply_id = RFQVendors[vendorIndex].reply_id || null;
    if (!vendorreply_id) {
      Helper.alert(
        `Vendor reply_id from ${RFQVendors[vendorIndex].vendor} vendor missing!`,
        "error"
      );
      return [];
    }
    if (allCriterias[criteriaIndex].comments_mandatory && comment[i] === "") {
      Helper.alert(
        `Comments are mandatory for criteria ${allCriterias[criteriaIndex].name}, please provide comments!`,
        "error"
      );
      return [];
    }
    result.push({
      rfq_vendorreply_id: vendorreply_id,
      scoring_criteriadetailrfq_id: scoringcriterialdetailrfq_id,
      score: score[i],
      comments: comment[i],
      not_applicable: formData.getAll(
        `checkbox_field_rfq_${scoringcriterialdetailrfq_id}`
      )[0]
        ? true
        : false,
      scorecriterialine_id:
        RFQVendors[vendorIndex]?.vendorScoring.find(
          (val) =>
            val.scorecriteriadetail_RFQ_id == scoringcriterialdetailrfq_id
        )?.scorecriterialine_id || null,
    });
  }
  return result;
};

// Error Handling Common Function,

export const getPopupMessageBasedOnStatus = (res) => {
  if (!res) return;

  if (res?.response?.status >= 500) {
    const errorMessage = res.response?.data?.message?.toLowerCase();

    if (errorMessage && errorMessage.includes('already exist')) {
      Helper.alert("This record is already exist.", 'error');
    } else {
      Helper.alert("Apologies, something went wrong from our side.", 'error');
    }
  } else {
    const message = res.response?.data?.message || 'An unexpected error occurred.';
    Helper.alert(message, 'error');
  }
}; 

export function customComparator(valueA, valueB) {
  valueA = valueA.toLowerCase()
  valueB = valueB.toLowerCase()
  return valueA.localeCompare(valueB) // Use localeCompare for string comparison
}

export function customDateComparator(dateStringA, dateStringB) {
  const dateA = new Date(dateStringA)
  const dateB = new Date(dateStringB)
  if (dateA < dateB) return -1
  if (dateA > dateB) return 1
  return 0
}

export const getSourceIdForWorkflowTrigger = (rfq_status) => {
  switch (rfq_status) {
    case 5:
      return {
        value: Settings.source_id.rfq_technical_evaluation,
        label: 'RFQ Technical Evaluation Workflow'
      }
    case 6:
      return {
        value: Settings.source_id.rfq_financial_evaluation,
        label: 'RFQ Commercial Evaluation Workflow'
      }
    case 7:
      return {
        value: Settings.source_id.rfq_awarding,
        label: 'RFQ Awarding Workflow'
      }
    default:
      return {
        value: Settings.source_id.request_for_quotation,
        label: 'Request for Quotation Workflow'
      }
  }
};