import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import Helper from '../../../../inc/Helper'
import Api from '../../../../inc/Api'
import Settings from '../../../../inc/Settings'
import ToggleSwitch, { TitlePosition } from '../../../Forms/ToggleSwitch'
import CustomHtmlCell from '../../../grid/ag/cellRender/CustomHtmlCell'
import LineViewComponent from '../../../Purchase Orders Internal/Edit PO/ListView/LineViewComponent'
import RFQAttachmentPopup from '../RFQList/RFQAttachmentPopup'
import TaxTransactionPopup from '../../../Purchase Orders Internal/Edit PO/ListView/TaxTransactionPopup'
import { getPopupMessageBasedOnStatus, objectIsEmpty } from '../../../../inc/Validation'
import AgGridNew from '../../../grid/ag/ag-grid-new'
import { getNewValue } from '../../../grid/ag/commonFunctions/GridCommonFunctions'
import NumberCellEditor from '../../../grid/ag/cellEditor/NumberCellEditor'

let forceRender = 1
let gridObj = null

const RFQBiddingProposal = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [popUpPosition, setPopUpPosition] = useState({ top: 0, right: 0 })
  const [rfqAttachments, setRFQAttachments] = useState([])
  const [vendorReplyStatus, setVendorReplyStatus] = useState([])
  const [allUnit,setAllUnit]=useState([])
  const [showTaxPopup, setShowTaxPopup]=useState(false)
  const [lineTaxId,setLineTaxId]=useState(null)
  const [lineId,setLineId]=useState(0)
  const [attachmentSourceId,setAttachmentSourceId]=useState(0)
  const [alternativeLines,setAlternativeLines]=useState(false)
  const [isLineProposed,setIsLineProposed]=useState(null)
  const [selectedRow,setSelectedRow]=useState({})

  const navigate = useNavigate()
  const rfqLines = useRef([])

  //value formatters
  const gridValueFormat = (params) => {
    const cellValue =
      params.value === null || params.value === undefined ? '-' : parseFloat(params.value).toFixed(6)
    return <div className="d-flex align-items-center" style={{height:"40px"}}><div className="rfq-cell">{Helper.formateCurrency(cellValue)}</div></div>
  }

  const gridValueColorFormat = (params) => {
    const cellValue =
      params.value === null || params.value === undefined ? ' ' : parseFloat(params.value).toFixed(6)
    const cellClass = cellValue === ' ' ? 'rfq-null-value-cell' : ''
    return <div className='d-flex align-items-center' style={{height:"40px"}}><div className={`rfq-cell ${cellClass}`}>{Helper.formateCurrency(cellValue)}</div></div>
  }

  const displayViewLogo = () => {
    return <div className="logo_inside_grid"><img src="/images/icons/view.svg" alt="view logo" style={{height:'24px'}} /></div>
  }

  const displayViewAttachedLogo = () => {
    return <div style={{textAlign:"center", marginLeft:"-17px"}} ><img src="/images/icons/attach.svg" alt="attach icon" style={{height:"24px"}} /></div>
  }

  const getLineStatusById = (params) => {
    const statusName = vendorReplyStatus?.find(
      (option) => option.id === params.value,
    )
    return statusName ? <div>{statusName.name}</div> : null
  }

  const getUnitNameByID = (params) => {
    const unitName = allUnit?.find((option) => option.unit_id === params.value)
    return unitName ? <div>{unitName.description}</div> : null
  }

  const taxTransactionViewCell = (params) => {
    if (params.data.line_id) {
      return <div className="rs_inactive_label">View tax</div>
    }
    return <></>
  }

  //On Cell Click Event
  const onProposalCellClicked = (event) => {
    if (event.colDef.field === 'view') {
      const lineId = event.data.line_id
      navigate(
        `/rfq-vendor-reply/rfq-bidding-general-details?line_id=${lineId}`,
        { state: props.rfqData },
      )
    }
    if (event.colDef.field === 'item_name') {
      if(event?.data?.line_type!==0){
        return
      }
      const itemId = event.data.item_id
      navigate(`/rfq-vendor-reply/rfq-bidding-details/${itemId}`, {
        state: props.rfqData,
      })
    }
    onCellClicked(event)
  }

  const onCellClicked = (event) => {
    if (event.colDef.field === 'attached') {
      setShowModal(true)
      let scrollY = window.scrollY || document.documentElement.scrollTop
      let mouseY = event.event.clientY
      let absoluteMouseY = mouseY + scrollY
      let mouseX = event.event.clientX
      let absoluteMouseX = window.innerWidth - mouseX - 100
      setPopUpPosition({ top: absoluteMouseY, right: absoluteMouseX })
      const alternate_line = event.data?.alternate_line
      const attachedId = !alternate_line ? event.data.RFQLine_attachmentsource_id: event.data?.attachmentsource_id
      const line_id = event.data?.line_id
      setLineId(line_id)
      setAttachmentSourceId(attachedId)
      setAlternativeLines(alternate_line)
      setRFQAttachments([])
      get_rfq_attachments(alternate_line?line_id:event.data?.RFQLine_id
        ,alternate_line?80:61)
    }
    if(event.colDef.field === 'view_tax_transaction'){
      setLineTaxId(event.data?.line_id)
      setShowTaxPopup(true)
   }
  }

  //function to close attachment modal
  const closeModal = () => {
    setShowModal(false)
  }

  const onTaxTransactionPopupClose = () => {
    setLineTaxId(null)
    setShowTaxPopup(false)
  }

  //function to calculate line amount
  const getLineViewAmount = (params, lineData = null) => {
    let data = params.data ? params.data : params
    let qtyData = data.required_qty 
    let quantity = parseFloat(qtyData) ? parseFloat(qtyData) : 0;
    let unitPrice = parseFloat(data.unit_price) ? parseFloat(data.unit_price) : 0
    let discount_amount = parseFloat(data.discount_amount) ? parseFloat(data.discount_amount) : 0
    let discount_percentage = parseFloat(data.discount_percentage) ?? 0
    if (lineData) {
      qtyData =  lineData.required_qty 
      quantity =  parseFloat(qtyData) ? parseFloat(qtyData) : 0;
      unitPrice = parseFloat(lineData.unit_price) ? parseFloat(lineData.unit_price) : 0
      discount_amount = parseFloat(lineData.discount_amount) ? parseFloat(lineData.discount_amount) : 0
      discount_percentage = parseFloat(lineData.discount_percentage) ? parseFloat(lineData.discount_percentage) : 0
    }
    if (discount_amount > unitPrice) {
      discount_amount = unitPrice
    }
    discount_amount = discount_amount * quantity

    let totalLineAmountBeforeDiscount = quantity * unitPrice
    let lineAmount = totalLineAmountBeforeDiscount - discount_amount
    lineAmount = lineAmount - (discount_percentage / 100) * lineAmount
    let finalLineAmount = lineAmount
    if (finalLineAmount <= 0) {
      return 0
    }
    return finalLineAmount
  }

  const onUnitPriceChangeHandler = (event,params) => {
    let required_qty = document.getElementById('required_qty')
    let discount_amount = document.getElementById('discount_amount')
    let discount_percentage = document.getElementById('discount_percentage')
    let lineData = {
      required_qty:required_qty.value,
      unit_price:event?.target?.value,
      discount_amount:discount_amount.value,
      discount_percentage:discount_percentage.value
    }
    let totalLineAmount = getLineViewAmount(params,lineData);
    let editorInsAr = params.api.getCellEditorInstances();
    editorInsAr.forEach( cellEditorIns => {
        if(cellEditorIns.params && cellEditorIns.params.colDef.field == 'line_amount'){
            if(cellEditorIns.updateValue && typeof cellEditorIns.updateValue == 'function'){
                cellEditorIns.updateValue(totalLineAmount)
            }else{
                params.node.setDataValue('line_amount',totalLineAmount)
            }
        }
    })
    params.node.setDataValue('line_amount', totalLineAmount);
}

const valueFormateForLineView = (params) => {
    let lineData = null

    if(!params.data.line_id){
      lineData = {
        required_qty:document.getElementById('required_qty')?.value,
        unit_price:document.getElementById('unit_price')?.value,
        discount_amount:document.getElementById('discount_amount')?.value,
        discount_percentage:document.getElementById('discount_percentage')?.value
      }
    }

    let value = params.value === null || params.value === undefined ? getLineViewAmount(params,lineData) : params.value
   
    return  <div className="rs_ag_currency_renderer">{Helper.formateCurrency(value)}</div>
}

const handleAmountChange = (event,params) => {
  let lineData = {
    required_qty:document.getElementById('required_qty')?.value,
    unit_price:document.getElementById('unit_price')?.value,
    discount_amount:document.getElementById('discount_amount')?.value,
    discount_percentage:document.getElementById('discount_percentage')?.value
  }
  let totalLineAmount = getLineViewAmount(params,lineData)
  params.node.setDataValue('line_amount',totalLineAmount)
 }

  //Api Calls
  const get_rfq_attachments = (id,source_id) => {
    let api = Api
    api.setUserToken()
    if (lineId === null || lineId===undefined || id===null) return
    api
      .axios()
      .get(Settings.loginUrl + `/get_attachments/${source_id}/${id}`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setRFQAttachments(res.data?.data)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }
  
  const get_all_line_status_external = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.loginUrl + `/enum/RFvendorreplylinestatus_external`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setVendorReplyStatus(res.data.data)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }

  const get_all_unit_names = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.loginUrl + `/unit`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setAllUnit(res.data.data)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }


  const updateLinesValue = (data,line_id) => {
    let api = Api
    api.setUserToken()
    if(line_id === null) return
    api
      .axios()
      .put(
        Settings.apiPurchaseOrderUrl +
          `/line/${line_id}/${props.rfqData?.source_id}`,
        data,
      )
      .then(function (res) {
        if (res.data.status === 'success') {
          Helper.alert(res.data?.message, 'success')
          // forceRender++
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }

  const onRowValueChanged = (event) => {
    const line_id = event.data.line_id
    const line_amount = Helper.formateCurrency(getLineViewAmount(event))
    const rowData = rfqLines?.current?.find((item)=>item.line_id===line_id)
    let data = {
      line_amount:parseFloat(Helper.getCurrencyForDb(line_amount))
    }
    if (event.data.required_qty !== rowData.required_qty) {
      data.required_qty = parseInt(event.data.required_qty) || 0;
    }
    
    if (event.data.unit_price !== rowData.unit_price) {
      data.unit_price = parseFloat(event.data.unit_price) || 0;
    }
    
    if (event.data.discount_amount !== rowData.discount_amount) {
      data.discount_amount = parseFloat(event.data.discount_amount) || 0;
    }
    
    if (event.data.discount_percentage !== rowData.discount_percentage) {
      data.discount_percentage = Helper.getOnlyNumber(event.data.discount_percentage) || 0;
    }  
    updateLinesValue(data,line_id) 
  }

  const onSwitchValueChanged = (value) => {
     setIsLineProposed(value)
  }

  const onSelectionChanged = (event) => {
    let selectedRows = event.api.getSelectedRows()
    setIsLineProposed(false)
    setSelectedRow(selectedRows[0])
  }

  useEffect(()=>{
    get_all_line_status_external()
    get_all_unit_names()
  },[])

  //data for columns
  const GridColumnsHeadingProposalLines = [
    {
      field: 'line_number',
      headerName: Helper.getLabel(props.language, 'line_number', 'Line number'),
      minWidth: 200,
    },
    {
      field: 'item_name',
      headerName: Helper.getLabel(props.language, 'item_name', 'Item name'),
      minWidth: 200,
      cellClass: 'rs_grid_simple_link rfq-link',
      valueFormatter:(params)=>params.value?params.value:"-"
    },
    {
      field: 'description',
      headerName: Helper.getLabel(props.language, 'description', 'Description'),
      minWidth: 200,
      valueFormatter:(params)=>params.value?params.value:"-"
    },
    {
      field: 'category_name',
      headerName: Helper.getLabel(props.language, 'category', 'Category'),
      minWidth: 180,
      valueFormatter:(params)=>params.value?params.value:"-"
    }, 
    {
      field: 'comments',
      headerName: Helper.getLabel(props.language, 'comments', 'Comments'),
      minWidth: 240,
      valueFormatter:(params)=>params.value?params.value:"-"
    },
    {
      field: 'ordered_qty',
      headerName: Helper.getLabel(props.language, 'qty_require', 'Qty require'),
      minWidth: 180,
    },
    {
      field: 'required_qty',
      headerName: Helper.getLabel(props.language, 'qty_propose', 'Qty propose'),
      minWidth: 150,
      onChange:handleAmountChange,
      cellEditor:NumberCellEditor, 
      cellRenderer: gridValueColorFormat,
    },
    {
      field: 'unit',
      headerName: Helper.getLabel(props.language, 'unit', 'Unit'),
      minWidth: 150,
      cellRenderer:getUnitNameByID,
    },
    {
      field: 'unit_price',
      headerName: Helper.getLabel(props.language, 'unit_price', 'Unit price'),
      minWidth: 200,
      onChange:onUnitPriceChangeHandler,
      cellEditor:NumberCellEditor, 
      cellRenderer: gridValueColorFormat,
    },
    {
      field: 'discount_amount',
      headerName: Helper.getLabel(
        props.language,
        'discount_amount',
        'Discount amount',
      ),
      minWidth: 200,
      cellEditor:NumberCellEditor, 
      onChange:handleAmountChange,
      cellRenderer: gridValueFormat,
    },
    {
      field: 'discount_percentage',
      headerName: Helper.getLabel(
        props.language,
        'discount_percentage',
        'Discount percentage',
      ),
      minWidth: 200,
      onChange:handleAmountChange,
      cellEditor:NumberCellEditor,
      cellRenderer: gridValueFormat,
    },
    {
      field: 'line_amount',
      headerName: Helper.getLabel(props.language, 'line_amount', 'Line amount'),
      minWidth: 200,
      editable: false,
      cellRenderer:(params)=>{return valueFormateForLineView(params)}, 
      valueFormatter:(params)=>{return getLineViewAmount(params)}
    },
    {
      field:'vendorreplystatus_external',
      headerName: Helper.getLabel(props.language, 'status', 'Status'),
      minWidth:200,
      cellRenderer: getLineStatusById,
    },
    {
      field: 'attached',
      minWidth: 140,
      headerName: Helper.getLabel(props.language, 'attachment', 'Attachment'),
      cellClass: 'rfq-attached-cell',
      cellRenderer: displayViewAttachedLogo,
      sortable:false,
      filter:false
    },
    {
      field: 'view',
      minWidth: 120,
      headerName: Helper.getLabel(props.language, 'view', 'View'),
      cellRenderer: displayViewLogo,
      sortable:false,
      filter:false
    },
    {
      field: 'view_tax_transaction',
      minWidth: 150,
      headerName: Helper.getLabel(props.language, 'tax', 'Tax'),
      editable: false,
      cellRenderer:taxTransactionViewCell,
      sortable:false,
      filter:false
    },
    {
      field:'advance',
      minWidth:200,
      hide:true,
      headerName: Helper.getLabel(
        props.language,
        'advance_amount',
        'Advance Amount',
      ),
      editable:false
    },
    {
      field:'comments',
      minWidth:200,
      hide:true,
      headerName: Helper.getLabel(
        props.language,
        'comments',
        'Comments',
      ),
      editable:false
    },
    {
      field:'recoupment_rate',
      minWidth:200,
      hide:true,
      headerName: Helper.getLabel(
        props.language,
        'recoupment_rate',
        'Recoupment Rate',
      ),
      editable:false
    },
    {
      field:'retainage_rate',
      minWidth:200,
      hide:true,
      headerName: Helper.getLabel(
        props.language,
        'retainage_rate',
        'Retainage Rate',
      ),
      editable:false
    },
    {
      field:'max_retainage_amount',
      minWidth:220,
      hide:true,
      headerName: Helper.getLabel(
        props.language,
        'maximum_retainage_amount',
        'Maximum Retainage Amount',
      ),
      editable:false
    },
  ]

  GridColumnsHeadingProposalLines.forEach((column) => {
    if (
      column.field === 'unit_price' ||
      column.field === 'required_qty' ||
      column.field === 'discount_amount' ||
      column.field === 'discount_percentage' 
    ) {
      const targetDate = new Date(props?.rfqData?.expiry_datetime);
      const currentDate = new Date();
      column.editable =
       props?.rfqData?.onhold || props?.rfqData?.RFQ_status === 2 || props?.rfqData?.RFQ_status === 9?false:props.rfqData?.interested_to_participate === true &&
        currentDate <= targetDate &&
        !props.bidStatus &&
        !(props.rfqData?.require_full_quantity === true && column.field === 'required_qty');
        // !props.lineLevelStatus
    } else {
      column.editable = false
    }
  })

  const checkLineStatus = () => {
    if (!rfqLines?.current || rfqLines?.current?.length === 0) {
      return false;
    }
    return rfqLines?.current?.some((line) =>
      [2, 3, 4, 5, 8, 9].includes(line.vendorreplystatus_external)
    )
  }

  const lineStatus = checkLineStatus()

  const handleFetchData = (response) => {
     let data = response?.data?.data?.filter((item)=>item.alternate_line !== true)
     rfqLines.current = data
     return {
      rowData: data?.map((item) => ({
        ...item,
        gridId: `${Date.now()}_${getNewValue()}`
      })),
      rowCount: data?.length
     }
  }

  const handleGridReady = (params) => {
      gridObj = params
  }
 
  return (
    <Fragment>
      <div>
        {/* <AgGridMemo
          id='proposal_line_grid'
          header={GridColumnsHeadingProposalLines}
          data={dataRfqLines}
          hideSave={true}
          addBtnHide={true}
          hideDelete={true}
          singleRowSelect={true}
          onCellClicked={onProposalCellClicked}
          onRowValueChanged={onRowValueChanged}
          forceRender={forceRender}
          onSelectionChanged={onSelectionChanged}
          dissableCheckBox={!props?.rfqData?.allow_alternate_lines_on_vendor_response}
        /> */}
        <AgGridNew
          gridId={'proposal_line_grid'}
          columnDefs={GridColumnsHeadingProposalLines}
          key={forceRender}
          apiUrl={Settings.apiPurchaseOrderUrl+`/lines/${props.rfq_vendor_id}/${props.rfqData?.source_id || Settings.source_id?.RfqVendorReply}`}
          hideDeleteBtn={true}
          hideAddBtn={true}
          fetchData={handleFetchData}
          onCellClicked={onProposalCellClicked}
          onRowValueChanged={onRowValueChanged}
          onSelectionChanged={onSelectionChanged}
          rowType={'single'}
          onGridReady={handleGridReady}
          isDisabledCheckbox={!props?.rfqData?.allow_alternate_lines_on_vendor_response}       
        />
        {showModal && (
          <RFQAttachmentPopup
            closeModal={closeModal}
            data={rfqAttachments}
            setRfqAttachment={setRFQAttachments}
            onAttachmentUpload={()=>{get_rfq_attachments(lineId,80)}}
            language={props.language}
            type={alternativeLines?"line view":""}
            style={{
              top: popUpPosition.top,
              right: popUpPosition.right,
            }}
            source_id={alternativeLines?80:props.rfqData?.source_id}
            attachmentsource_integrator={lineId}
            attachmentsource_id={attachmentSourceId}
            allowEdit={
              props.rfqData?.interested_to_participate === false ||
              props.rfqData?.interested_to_participate === null ||
              props.allFieldsAreZero === true ||
              props.type === 'versions' ||
              props.bidStatus || !alternativeLines
            }
          />
        )}
      </div>

    {showTaxPopup && lineTaxId && <TaxTransactionPopup onTaxTransactionPopupClose = {onTaxTransactionPopupClose} line_id={lineTaxId} source_id={props.rfqData?.source_id} integrator={props.rfq_vendor_id}/> }

     {props.rfqData?.allow_alternate_lines_on_vendor_response &&
        !props?.rfqData?.require_full_quantity && !objectIsEmpty(selectedRow) && <ToggleSwitch
        margin={'mb-4 mt-3'}
        titlePosition={TitlePosition.right}
        value={isLineProposed}
        onChange={onSwitchValueChanged}
        title='Do you want to propose any alternate item/service then requested in this proposal?'
      />}

      <div className='rs_bidding_proposal_grid'></div>
      {isLineProposed && !objectIsEmpty(selectedRow) &&(
          <LineViewComponent
            defaultValues={{ requester_id: props.rfqData?.buyer_id }}
            source_id={props.rfqData?.source_id}
            line_source={props.rfqData?.source_id}
            isEditable={
             props.rfqData?.onhold || props.allFieldsAreZero || props?.rfqData?.RFQ_status === 2 || props?.rfqData?.RFQ_status === 9 ? false:( props.rfqData?.interested_to_participate === true ||
              props.rfqData?.vendorreply_status === 1 ||
              props.bidStatus ||
              lineStatus || alternativeLines) 
            }
            header_id={props.rfqData?.RFQ_vendorreply_id}
            purchase_status={null}
            document_status={props.rfqData?.vendor_replystatus}
            delivery_date={props.rfqData?.delivery_date}
            onbehalf_request={false}
            vendor_id={null}
            vendorsite_id={null}
            setOrderStatusProps={null}
            hideDistribution={true}
            hideGeneral={true}
            hideSetup={true}
            hideBillingRule={true}
            alternate_line={true}
            vendorReplyLineId={selectedRow?.line_id}
            onCellClicked={onCellClicked}
            formType="rfq"
            lineGridTitle={Helper.getLabel(props.language, 'alternate_lines', 'Alternate Lines')}
            additionalLineColumns={[
              {
                field: 'attached',
                minWidth: 120,
                headerName: Helper.getLabel(props.language, 'attached', 'Attached'),
                cellClass: 'rfq-attached-cell',
                cellRenderer: displayViewAttachedLogo,
              },
            ]}
            hideDistributionInnerTabs={true}
            
          />
        )}
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(RFQBiddingProposal)
