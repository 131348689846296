import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import MasterComonent from '../../components/Backend/MasterComonent'
import InnerScreen from '../../components/Inner Screens/InnerScreen'
import Helper from '../../inc/Helper'

const ReportsScreen = ({language}) => {
  return (
    <div className='modules_screen_lynkaz'>
      <MasterComonent>
        <InnerScreen innerTitle='Reports'>

          <div className='col-lg-3 gy-4'>
            <div className='inner-page-header border-bottom'>
              <h2>{Helper.getLabel(language,'dashboard','Dashboard')}</h2>
            </div>
            <div className='inner-page-routes'>
              <Link to='/employee-task-performance'>{Helper.getLabel(language,'employee-task-performance','Employee Task Performance')}</Link> 
              <Link to='/contract-dashboard'>{Helper.getLabel(language,'contracts','Contracts')}</Link>
            </div>
          </div> 
        </InnerScreen>
      </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth:state.auth,
    language:state.language
  }
}

export default connect(mapStateToProps) (ReportsScreen);