import React, { Component } from 'react';
import PoVersion from '../../components/Purchase Orders Internal/PoVersion/PoVersion';
import Helper from '../../inc/Helper';

class ReleaseOrderVersionPage extends Component {
    render() {
        return (
            <PoVersion
                title = {Helper.getLabel(null,'release_order_version','Release order version')}
                backUrl = {'/release-order/edit'}
            />
        );
    }
}

export default ReleaseOrderVersionPage;