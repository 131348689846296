import React, { Fragment, useEffect, useState } from 'react';
import OverlayLoader from '../../../PurchaseRequisition/common/OverlayLoader';
import Popup from '../../../Popup/Popup';
import Collapse from '../../../inc/Collapse';
import { Form } from 'react-bootstrap';
import FieldsRenderer from '../../../Purchase Orders Internal/Edit PO/FieldsRenderer';
import Button from '../../../inc/Button';
import { connect } from 'react-redux';
import Helper from '../../../../inc/Helper';
import $ from 'jquery';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import FormValidator from '../../../../inc/FormValidator';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings';
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation';

let cancelTokenSource = axios.CancelToken.source();
const ComplianceTemplateMasterModal = ({ language, setShowCreateModal }) => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({});
 
    const [apiCallBtn, setApiCallBtn] = useState(false);
 
    const [complianceHeaders, setComplianceHeaders] = useState([]);

    let formDataValidator;   

    const validationConfigure = () => {
        let formDataFieldConfig = [
            {name:'name', displayName:Helper.getLabel(language,'name','Name'), types:['Required']},  
            {name:'compliance_header_id', displayName:Helper.getLabel(language,'compliance_header_id','Compliance header'), types:['Required']}
        ];

        if(!formDataValidator) {
            formDataValidator = new FormValidator(formDataFieldConfig, language)
        }; 
    };

    validationConfigure();
 
    let complianceHeaderCalled = false; 

    useEffect(() => {  
        getComplianceHeaderApi(); 
    }, []);

    const getComplianceHeaderApi = () => {
        if(complianceHeaderCalled) return;
        complianceHeaderCalled = true;

        let api = Api 
        api.setUserToken()
        api.axios().get(Settings.apiPurchaseRequisitionUrl+'/complianceresponseheader',{}).then(function(res){
            if(res.data.status==='success') {
                setComplianceHeaders(res?.data?.data?.map((item) => {
                    return {
                        value: item.complianceheader_id,
                        label: item.compliance_name 
                    }
                })); 
            };
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res) 
        })
    };

    const fieldsArrayGeneral = [
        {className:'col-lg-6', required: true, disable: apiCallBtn, label: Helper.getLabel(language,'name',"Name"), inputType: "text", id:'name', name:'name'},
        {className:'col-lg-6', disable: apiCallBtn, required: true, options: complianceHeaders, label: Helper.getLabel(language,'complianceheader',"Compliance header"), inputType: "dropdown", id:'compliance_header_id', name:'compliance_header_id'},
    ];

    const onPopupClose = () => {
        $("html").removeClass("rs_popup_open");
        setShowCreateModal(false);
    }

    const executePOSTAPI = async () => {
        try { 
            let api = Api;
            api.setUserToken();
        
            const res = await api.axios().post(Settings.apiPurchaseRequisitionUrl + '/compliance_template', {
                ...formData,
                rfq_id: null
            },
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res?.data?.message); 
                onPopupClose();
                navigate(`/compliance-templates/edit/${res?.data?.template_id}`); 
            };
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        };
    };

    const handleSubmit = () => { 
        if(!formDataValidator.isValid({...formData})){
            formDataValidator.displayMessage();
            formDataValidator.reset();
            return;
        };
  
        Helper.createDebouncedAPIFunction( 
            [executePOSTAPI], 
            setApiCallBtn,
            cancelTokenSource
        )();
    };

    return (
        <Fragment> 
            { apiCallBtn ? <OverlayLoader /> : null }
            <Popup
                autoOpen={true} 
                width="900px"
                onClose={onPopupClose}
            >
                <>
                    <h5 className="create_rfq_modal__title">Create Compliance Template</h5>

                    <Collapse
                        className="mt-4 mb-4" open={true}
                        title={Helper.getLabel(
                            language,
                            "general_info",
                            "General Information"
                        )}
                    >
                        <Form>
                            <div className='container-fluid'>
                                <FieldsRenderer updateState={setFormData} currentState={formData} fieldsArray={fieldsArrayGeneral} />
                            </div>
                        </Form> 
                    </Collapse> 
                </> 

                <div className="addDocumentNumber-body-buttons" style={{ justifyContent: "flex-end" }}>
                    <Button
                        isActive={false} 
                        title={Helper.getLabel(language, "close", "Close")}
                        isDisable={apiCallBtn}
                        onClick={onPopupClose}
                    />
                    <Button
                        isDisable={apiCallBtn}
                        className="solid_btn" 
                        isActive={true}
                        title={Helper.getLabel(language, "create", "Create")} 
                        onClick={handleSubmit}
                    />
                </div>
            </Popup>
        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        appOptions: state.options,
        auth: state.auth,
        language: state.language,
    };
};

export default connect(mapStateToProps)(ComplianceTemplateMasterModal); 