import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import MasterComonent from "../../components/Backend/MasterComonent";
import InnerScreen from "../../components/Inner Screens/InnerScreen";
import Helper from "../../inc/Helper";

const ReceiveScreen = ({ language,auth }) => {
  return (
    <div className="modules_screen_lynkaz">
      <MasterComonent>
        <InnerScreen innerTitle="Receiving">
          <div className="col-lg-3 gy-4">
            <div className="inner-page-header border-bottom">
              <h2>
                {Helper.getLabel(
                  language,
                  "goods_receiving",
                  "Goods Receiving"
                )}
              </h2>
            </div>
            
            <div className="inner-page-routes">
              <Link to="/goods-receiving-list">
                {Helper.getLabel(
                  language,
                  "goods_receiving_all",
                  "Goods Receiving - All"
                )}
              </Link>
              <Link to={`/goods-receiving-list?status=0`}>
                {Helper.getLabel(
                  language,
                  "goods_receiving_open",
                  "Goods Receiving - Open"
                )}
              </Link>
              <Link to={`/goods-receiving-list?createby=${auth.user?.user_id}`}>
                {Helper.getLabel(
                  language,
                  "goods_receiving_created_by_me",
                  "Goods Receiving - Created by Me"
                )}
              </Link>
            </div>
          </div>

          <div className='col-lg-3 gy-4'>
            <div className='inner-page-header border-bottom'>
              <h2>
                {Helper.getLabel(
                  language,
                  'work_confirmations',
                  'Work Confirmations'
                )}
              </h2>
            </div>
            <div className='inner-page-routes'>
              <Link to='/work-confirmation-list'>
                {Helper.getLabel(
                  language,
                  'work_confirmation_all',
                  'Work Confirmations - All'
                )}
              </Link>
              <Link to={`/work-confirmation-list?status=0`}>
                {Helper.getLabel(
                  language,
                  'work_confirmation_open',
                  'Work Confirmations - Open'
                )}
              </Link>
              <Link  to={`/work-confirmation-list?createby=${auth.user?.user_id}`}>
                {Helper.getLabel(
                  language,
                  'work_confirmation_created_by_me',
                  'Work Confirmations - Created by Me'
                )}
              </Link>
            </div>
          </div>
        </InnerScreen>
      </MasterComonent>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
  };
};

export default connect(mapStateToProps)(ReceiveScreen);