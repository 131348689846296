import React from 'react'
import { Link } from 'react-router-dom';
import './InnerScreen.css'

const InnerScreen = ({innerTitle, children}) => {
  return (
    <div className='inner-page-section'>
      <div className='container-fluid'>
        <div className='row gy-2'>
            <div className='col-12 border-bottom'>
                <h1>{innerTitle}</h1>
            </div>
            <div className='col-12'>
                <div className='container-fluid inner-page-container'>
                    <div className='row'>
                        {children}
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default InnerScreen;
