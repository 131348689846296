import React, { useState, useEffect} from 'react'
import Helper from '../../../../inc/Helper'
import Collapse from '../../../inc/Collapse'
import SimpleLoading from '../../../Loading/SimpleLoading'

const PurchaseAgreementFulFillment = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [rowData, setRowData] = useState(props.activePOLine ? props.activePOLine.data : {})

  useEffect(() => {
    if (props.activePOLine?.data?.is_amendmentline) {
      setIsLoading(true)
      setRowData(props.activePOLine.data)
      setIsLoading(false)
    }
  }, [props.activePOLine])

  useEffect(() => {
    if (props.activePOLine?.data?.line_id) {
      setRowData(props.activePOLine.data)
    }
  }, [props.activePOLine?.data?.line_id])

  let language = null
  let data = rowData
  
  if (isLoading) {
    return <SimpleLoading />
  }

  return (
    <Collapse open={true} title={Helper.getLabel(language, 'balance', 'Balance')} className='mb-3'>
      <div className='d-flex flex-column gap-2'>
        <div className='d-flex gap-2 align-items-center'>
          <h6 className='p-0 m-0 fw-bold col-sm-4 col-xl-3'>{Helper.getLabel(language, 'total_amount', 'Total amount')} :</h6>
          <div style={{maxWidth:"140px",width:"100%"}}>
            <p className='p-0 m-0 text-end'>{Helper.addThousandSeparator(data?.line_amount) ?? "0.000000"}</p>
          </div>
        </div>
        <div className='d-flex gap-2 align-items-center'>
          <h6 className='p-0 m-0 fw-bold col-sm-4 col-xl-3'>{Helper.getLabel(language, 'total_release_amount', 'Total release amount')} :</h6>
          <div style={{maxWidth:"140px",width:"100%"}}>
           <p className='p-0 m-0 text-end'>{Helper.addThousandSeparator(data?.balances?.total_release_value) ?? "0.000000"}</p>
          </div>
        </div>
        <div className='d-flex gap-2 align-items-center'>
          <h6 className='p-0 m-0 fw-bold col-sm-4 col-xl-3'>{Helper.getLabel(language, 'total_receive_amount', 'Total receive amount')} :</h6>
          <div style={{maxWidth:"140px",width:"100%"}}>
           <p className='p-0 m-0 text-end'>{Helper.addThousandSeparator(data?.balances?.total_grn_value) ?? "0.000000"}</p>
          </div>
        </div>
        <div className='d-flex gap-2 align-items-center'>
          <h6 className='p-0 m-0 fw-bold col-sm-4 col-xl-3'>{Helper.getLabel(language, 'total_invoice_amount', 'Total invoice amount')} :</h6>
          <div style={{maxWidth:"140px",width:"100%"}}>
            <p className='p-0 m-0 text-end'>{Helper.addThousandSeparator(data?.balances?.total_invoice_value) ?? "0.000000"}</p>
          </div>
        </div>
        <div className='d-flex gap-2 align-items-center'>
          <h6 className='p-0 m-0 fw-bold col-sm-4 col-xl-3'>{Helper.getLabel(language, 'total_direct?.invoice_value', 'Total direct invoice value')} :</h6>
          <div style={{maxWidth:"140px",width:"100%"}}>
           <p className='p-0 m-0 text-end'>{Helper.addThousandSeparator(data?.balances?.total_direct_invoice_value) ?? "0.000000"}</p>
          </div>
        </div> 
        <div className='d-flex gap-2 align-items-center'>
          <h6 className='p-0 m-0 fw-bold col-sm-4 col-xl-3' style={{color:"#2d74e0"}}>{Helper.getLabel(language, 'balance', 'Balance')} :</h6>
          <div style={{maxWidth:"140px",width:"100%"}}>
           <p className='p-0 m-0 text-end' style={{color:"#2d74e0"}}>{Helper.addThousandSeparator(data?.balances?.balance) ?? "0.000000"}</p>
          </div>
        </div>
      </div>
    </Collapse>
  )
}

export default PurchaseAgreementFulFillment
