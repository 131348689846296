import React, { useRef } from 'react';
import $ from 'jquery'
import Popup from '../../../Popup/Popup';
import Collapse from '../../../inc/Collapse';
import Helper from '../../../../inc/Helper';
import { connect } from 'react-redux';
import DropDownInput from '../../../Forms/Dropdown';
import Button from '../../../inc/Button';
import Checkbox from '../../../Forms/Checkbox';
import { getPopupMessageBasedOnStatus, objectIsEmpty } from '../../../../inc/Validation';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings';

const CollaborationTeamPopup = ({language,onPopupClose,employeesList,dynamicTeamsList,accessLevel,teamData,setTeamsData,getAsyncData,collaborationTeams,setCollaborationTeams,onSaveHandler, isDisabled}) => {
  const employeesData = useRef([])
  const mapEmployeesData = (item) => {
    return {
      id: item.employee_id,
      text: item.displayname,
    };
  }

  const handleUnassignedTeams = async (id) => {
    let api = Api;
    api.setUserToken();
    try {
      const res = await api
        .axios()
        .delete(`${Settings.loginUrl}/unassigned_dynamicteam/${teamData?.teamsourceline_id}/${id}`)
      if(res?.data?.status==="success"){
        Helper.alert(res?.data?.message, 'success')
      }
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }

  const collaborationTeamsHandler = (newObj) => {
    for (const key in newObj) {
        if (newObj.hasOwnProperty(key)) {
          if (newObj[key] === true) {
            newObj[key] = true;
          } else if (newObj[key] === true) {
            newObj[key] = false;
          }
        }
      }
      let index = collaborationTeams.findIndex(
        (obj) => obj.teamsourceline_id === newObj.teamsourceline_id
      );
  
      if (index >= 0) {
        // If the row already exists, update the existing row
        const newCollaborationteams = [...collaborationTeams];
        newCollaborationteams[index] = newObj;
        setCollaborationTeams(newCollaborationteams);
      } else {
        // If the row doesn't exist, add the new row
        const newCollaborationteams = [...collaborationTeams, newObj];
        setCollaborationTeams(newCollaborationteams);
      }
  }

  const InputChangeHandler = (event) => {
    setTeamsData(prev => {
      const updatedteamsHeader = { ...prev, [event.target.name]: event?.target?.value};
      if (event.target.name === "employee_id") {
        const employeeName = employeesData?.current?.find(item => parseInt(item.employee_id) === parseInt(event?.target?.value))?.displayname;
        updatedteamsHeader.employee_name = employeeName
      }
      return updatedteamsHeader;
    });
    const newObj = {
        ...teamData,
        [event?.target?.name]:event?.target?.value,
    };
    if (event.target.name === "employee_id"){
      const employeeName = employeesData?.current?.find(item => parseInt(item.employee_id) === parseInt(event?.target?.value))?.displayname;
      newObj.employee_name=employeeName
    }
    collaborationTeamsHandler(newObj)
  }

  const onDynamicInputChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions).map((option) => parseInt(option.value));
    setTeamsData({
      ...teamData,
      dynamicteamheader_ids:selectedOptions,
    })

    const existingDynamicTeamHeaderIds=Object.keys(teamData?.dynamicteamheader_names || {});

    if(existingDynamicTeamHeaderIds.includes(event.params.data.id) && event?.type==="select2:unselect"){
      handleUnassignedTeams(event.params.data.id)
    }
    
    const newObj = {
      ...teamData,
      dynamicteamheader_ids:selectedOptions,
  };
  collaborationTeamsHandler(newObj)
  }

  const select2Data = objectIsEmpty(teamData?.dynamicteamheader_names) ? {} : Object.keys(teamData?.dynamicteamheader_names);

  const onInputCheckboxChangeHandler = (name,value) => {
    setTeamsData({
        ...teamData,
        [name]:value === "on",
    })
    const newObj = {
        ...teamData,
        [name]:value === "on",
    };
    collaborationTeamsHandler(newObj)
  }
  return (
    <div className='close-popup_po'>
        <Popup onClose={onPopupClose} autoOpen={true} width="1100px">
           <div className='addDocumentNumber-body-buttons' style={{ justifyContent: 'flex-end' }}>
          <Button className='solid_btn publish_rfq_blue_btn' isActive={true} title={Helper.getLabel(language, 'save_and-close', 'Save & Close')} onClick={onSaveHandler} />
        </div>
           <Collapse className='mb-4 info_po_tabs mt-4' open={true} title={Helper.getLabel(language,'collaboration_team',"Collaboration Team")}>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <DropDownInput id='employee_id' label={Helper.getLabel(language,'name',"Name")} options={teamData?.employee_id?[{
                  value: teamData?.employee_id,
                  label: teamData?.employee_name || employeesData?.current?.find(item => parseInt(item.employee_id) === parseInt(teamData?.employee_id))?.displayname,
                }]:employeesList} value={teamData?.employee_id} name='employee_id' onChange={InputChangeHandler} isDisable={isDisabled}  pagination={true} 
            apiUrl={Settings?.apiOrgUrl + `/employee_dropdown`} 
            allDataRef={employeesData}
            mapFunction={mapEmployeesData}
            reRenderRequired={true} />
                        </div> 
                        <div className='col-lg-4'>
                            <DropDownInput id='dynamicteamheader_ids' label={Helper.getLabel(language,'dynamic_team',"Dynamic team")} options={dynamicTeamsList} value={objectIsEmpty(teamData?.dynamicteamheader_names) ? [] : select2Data} name='dynamicteamheader_ids' onChange={onDynamicInputChange} isDisable={isDisabled} select2Settings={{multiple:true}} />
                        </div>
                        <div className='col-lg-4'>
                            <DropDownInput id='access_level' label={Helper.getLabel(language,'access',"Access")} options={accessLevel} value={teamData?.access_level} name='access_level' onChange={InputChangeHandler} isDisable={isDisabled} />
                        </div>
                        <div className='col-lg-4'>
                            <Checkbox type={'checkbox'} onChange={onInputCheckboxChangeHandler} label={Helper.getLabel(language,'view_pr',"View PR")} name='PR_view_access' isChecked={teamData?.PR_view_access} disableWithoutIcon={isDisabled} />
                        </div>
                        <div className='col-lg-4'>
                            <Checkbox type={'checkbox'} onChange={onInputCheckboxChangeHandler} label={Helper.getLabel(language,'view_rfq',"View RFQ")} name='RFP_view_access' isChecked={teamData?.RFP_view_access} disableWithoutIcon={isDisabled}/>
                        </div>
                        <div className='col-lg-4'>
                            <Checkbox type={'checkbox'} onChange={onInputCheckboxChangeHandler} label={Helper.getLabel(language,'perform_technical_evaluation',"View technical evaluation")} name='perform_RFQ_technical_evaluation' isChecked={teamData?.perform_RFQ_technical_evaluation} disableWithoutIcon={isDisabled} />
                        </div>
                        <div className='col-lg-4'>
                            <Checkbox type={'checkbox'} onChange={onInputCheckboxChangeHandler} label={Helper.getLabel(language,'perform_commercial_evaluation',"View commercial evaluation")} name='perform_RFQ_financial_evaluation' isChecked={teamData?.perform_RFQ_financial_evaluation} disableWithoutIcon={isDisabled} />
                        </div>
                        <div className='col-lg-4'>
                            <Checkbox type={'checkbox'} onChange={onInputCheckboxChangeHandler} label={Helper.getLabel(language,'allowed_to_award',"Allowed to award")} name='allowed_to_award' isChecked={teamData?.allowed_to_award} disableWithoutIcon={isDisabled} />
                        </div>
                        <div className='col-lg-4'>
                            <Checkbox type={'checkbox'} onChange={onInputCheckboxChangeHandler} label={Helper.getLabel(language,'unseal_technical_proposal',"Unseal technical proposal")} name='allowed_to_unsealed_technical_proposal' isChecked={teamData?.allowed_to_unsealed_technical_proposal} disableWithoutIcon={isDisabled}/>
                        </div>
                        <div className='col-lg-4'>
                            <Checkbox type={'checkbox'} onChange={onInputCheckboxChangeHandler} label={Helper.getLabel(language, "unseal_commercial_proposal","Unseal commercial proposal")} name='allowed_to_unsealed_commercial_proposal' isChecked={teamData?.allowed_to_unsealed_commercial_proposal} disableWithoutIcon={isDisabled} />
                        </div>
                    </div>
                </div> 
           </Collapse>
        </Popup>
    </div>
  )
}

 
const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language,
    }
}


export default connect(mapStateToProps) (CollaborationTeamPopup)
