import React, { useState, useEffect } from 'react'
import Helper from '../../../../../inc/Helper'
import ItemDetails from './ItemDetails'
import AgGridNew from '../../../../grid/ag/ag-grid-new'
import Settings from '../../../../../inc/Settings'
import { getWorkConfirmationHeader } from '../../ColumnsHeader'
import Api from '../../../../../inc/Api'

let gridObj = null

const Summary = (props) => {
  const [selectedRowData, setSelectedRowData] = useState({})

  const onSelectionChanged = (event) => {
    const selectedNodes = event.api.getSelectedNodes()
    if (selectedNodes.length === 0) {
      setSelectedRowData({})
    } else {
      const rowData = selectedNodes[0].data
      setSelectedRowData(rowData)
    }
  }

  const GridColumnsHeadingLines = getWorkConfirmationHeader({
    language:props.language,
    lineIdOptions:props.lineIdOptions
  })

  const handleGridReady = (gridApi) => {
    gridObj = gridApi
  }

  let masterSettings = {
    detailRowAutoHeight: true,
    masterDetail: true,
    isRowMaster: function (dataItem) {
      return (
        dataItem.billingrule?.item_detail_required ||
        dataItem?.item_detail_required
      )
    },
    detailCellRenderer: ItemDetails,
    detailCellRendererParams: {
      items: props.linesData?.items,
      getDetailRowData: (params) => {
        let api = Api
        api.setUserToken()
        api
          .axios()
          .get(
            Settings.apiPurchaseOrderUrl +
              `/lines/${params?.data?.line_id}/${Settings.source_id.ShipmentGoodReceivingWorkConfirmation}`,
            {},
          )
          .then((res) => {
            if (res.data.status === 'success') {
              return res.data.data
            } 
          })
     }
    },
  }

  const workConfirmationNavs = [
    {
      title: Helper.getLabel(props.language, 'distribution', 'Distribution'),
      isActive:
        selectedRowData && Object.keys(selectedRowData).length > 0
          ? false
          : true,
      isDisable:
        selectedRowData && Object.keys(selectedRowData).length > 0
          ? false
          : true,
    },
  ]

  useEffect(() => {
    console.log('selectedRow', selectedRowData)
  }, [selectedRowData])

  return (
    <div
      className={
        props.userType === 0 ? 'work_completion_line_grid_responsive' : 'work_completion_line_grid work_completion_line_grid_responsive'
      }
    >
      <h2 className="summary-title">
        {props?.linesData.length !== 0 &&
          Helper.getLabel(props.language, 'lines_summary', 'Lines Summary')}
      </h2>
      {/* <AgGridMemo
        id="work_confirmation_line_grid"
        header={GridColumnsHeadingLines}
        labels={{ btnDelete: 'Remove' }}
        singleRowSelect={true}
        data={props?.linesData}
        settings={masterSettings}
        onSelectionChanged={
          props.viewType === 'Work Confirmation Details'
            ? onSelectionChanged
            : ''
        }
        selectedRow={selectedRowData}
        hideSave={true}
        addBtnHide={true}
        hideDelete={true}
        enableCheckbox={true}
      /> */}
      <div className='mb-4'>
      <AgGridNew
        apiUrl={Settings.apiPurchaseOrderUrl + `/get_sh_lines/${props.shipment_id}`} 
        columnDefs={GridColumnsHeadingLines}
        hideAddBtn={true}
        hideDeleteBtn={true}
        onGridReady={handleGridReady}
        isDisabledCheckbox={true}
        rowType="single"
        masterSettings={masterSettings}
        gridId="work_confirmation_line_grid_2"
        onSelectionChanged={
        props.viewType === 'Work Confirmation Details'
          ? onSelectionChanged
          : ''
      }
      />
      </div>
      {/* {props.viewType === 'Work Confirmation Details' &&
      props?.linesData.length !== 0 ? (
        <Tabs
          id="rs_list_view_tabs"
          tabContantsId="rs_listViewContents"
          wrapperClass={'solid_nav mt-4'}
          navs={workConfirmationNavs}
          disableSticky={true}
        >
          
        </Tabs>
      ) : null} */}
    </div>
  )
}

export default Summary
