import React, { useEffect, useRef,useState } from 'react'

import Api from "../../../../inc/Api"
import Settings from "../../../../inc/Settings"
import Helper from "../../../../inc/Helper"
import { Button } from 'react-bootstrap'
import Droppable from 'droppable'
import AttachmentLoader from './AttachmentLoader'
import Dropdown from '../../../Forms/Dropdown'
import { connect } from 'react-redux'
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation'

const RFQAttachmentPopup = (props) => {
  const [totalFiles, setTotalFiles] = useState(0)
  const [uploadingFiles, setUploadingFiles] = useState(0)
  const [percentage, setPercentage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [attachmentTypeId,setAttachmentTypeId]=useState(null)
  const [attachmentTypeIds,setAttachmentTypeIds]=useState([])
  const [currentFileName,setCurrentFileName]=useState('')

  const modalRef = useRef(null)
  const id = props.id ? props.id : 'file_uploader_canvas'

  const uploadFiles = (files,attachmentTypeId) => {
    if (!props.source_id) {
      Helper.alert('Source id not found', 'error')
      return
    }
    if (attachmentTypeId === null) {
      Helper.alert('Type Id not found', 'error')
      return
    }

    setIsLoading(true)
    setTotalFiles(files.length)
    setUploadingFiles(1)

    const loaderController = () => {
      const currentFileCount = uploadingFiles + 1
      setIsLoading(currentFileCount <= totalFiles)
      setUploadingFiles(currentFileCount)
      setPercentage(0)
    }

    const api = Api
    api.setUserToken()

    const ProgressConfig = {
      onUploadProgress: (progressEvent) => {
        const PercentageUpload =
          (progressEvent.loaded / progressEvent.total) * 100
        setPercentage(PercentageUpload)
      },
    }

    const handleResponse = (response, fileDataToUpdate) => {
      loaderController()
      if (response.data.status === 'success') {
        onFileUpload(fileDataToUpdate)
        Helper.alert(response.data.message)
      } else {
        Helper.alert(response.data.message, 'error')
      }
    }

    files.forEach((file) => {
      const endpoint = '/attachment'

      const fileSizeInMB = file.size / (1024 * 1024);
      const fileDataToUpdate = {
            source_id: props.source_id,
            attachmentsource_integrator:parseInt(props.attachmentsource_integrator),
            attachmenttype_id: parseInt(attachmentTypeId),
            attachment_for:1,
            filesizeinMB:parseFloat(fileSizeInMB.toFixed(2)),
          }

      const finalFormData = new FormData()
      finalFormData.append('file', file)
      finalFormData.append('data', JSON.stringify(fileDataToUpdate))

      setCurrentFileName(file.name)

      api
        .axios()
        .post(Settings.apiUrl + endpoint, finalFormData, ProgressConfig)
        .then((res) => {handleResponse(res, fileDataToUpdate)
        setAttachmentTypeId(null)
        props.onAttachmentUpload()
        setCurrentFileName('')
        })
        .catch((error) => {
          loaderController()
          Helper.alert(error.response.data.message, 'error')
        })
    })
  }

  const onFileUpload = (file) => {
    if (props.onFileUpload && typeof props.onFileUpload === 'function') {
      props.onFileUpload(file)
    }
  }

  
const getAttachmentsType = () => {
    let api = Api

    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + `/attachmenttype`)
      .then(function (res) {
        if (res.data.status === 'success') {
          const result = res.data.data.filter((item)=>item.source_id===props.source_id)
          setAttachmentTypeIds(result)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }

  const onAttachmentDropdownChange = (e) => {
    setAttachmentTypeId(e.target.value)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        props.closeModal()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [props.closeModal])

  useEffect(()=>{
    getAttachmentsType()
  },[])

  useEffect(() => {
    if(!attachmentTypeId) return
    let droppable = null
    if (!droppable || !attachmentTypeId) {
      droppable = new Droppable({
        element: document.querySelector('#' + id),
      })

      droppable.onFilesDropped((files) => {
        uploadFiles(files,attachmentTypeId)
      })
    }

    return () => {
      droppable.destroy()
    }
  }, [attachmentTypeId])

  const onDownloadClick = (id) => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.loginUrl + `/get_attachment/${id}`,{ responseType: 'blob' })
      .then(async function (res) {
        if (res.status === 200) {
          const response = res
          const contentDisposition = response.headers['content-disposition']
          const fileName =
            contentDisposition?.split('filename=')[1] || 'dummy.jpg'
          const fileBlob = new Blob([response.data], {
            type: response.headers['content-type'],
          })
          const downloadLink = document.createElement('a')
          downloadLink.href = URL.createObjectURL(fileBlob)
          downloadLink.download = fileName

          document.body.appendChild(downloadLink)
          downloadLink.click()
          document.body.removeChild(downloadLink)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }

  const fileDeleteHandler = (id) => {
    let api = Api
    api.setUserToken()
    if(id===null || !props.attachmentsource_integrator) return
    api
      .axios()
      .delete(Settings.apiUrl + '/attachment/' + id)
      .then(function (res) {
        if (res.data.status === 'success') {
          Helper.alert(res.data.message)
          props.setRfqAttachment(prevLines => prevLines.filter(line => line.attachment_id !== id))
          props.getAttachments()
        } else {
          Helper.alert(res.data.message, 'error')
        }
      })
  }
 
  return (
    <div ref={modalRef} className='rfq-attachment-popup' style={props.style}>
      <div
        style={{ display: 'flex', marginLeft: '-5px', marginBottom: '18px' }}
      >
        <img src='/images/icons/attach.svg' alt='attachment-logo' />
        <span className='rfq-attachment-title'>
          {Helper.getLabel(props.language, 'attachments', 'Attachments')}
        </span>
      </div>
      <p className='rfq-subtitle rfq-attachment-documents'>
        Uploaded Documents
      </p>
      <div className='d-flex mb-2 flex-column gap-2' style={{maxHeight:"160px",overflowY:'auto'}}>
        {props.data === null || props.data.length === 0 ? (
          <p>No Attachment Found</p>
        ) : (
          props.data.map((item) => (
            <div key={item} className='rfq-attachment-files dotted-outer-div'>
              <div style={{ display: 'flex', gap: '10px' }}>
                <img src='/images/icons/document-gray.svg' alt="icon-attachment" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className='rfq-subtitle rfq-attachment-document-name'>
                    {item.filename}
                  </span>
                  <span className='rfq-attachment-value'>
                    {item.filesize?.toFixed(3)} MB . {item?.attachmenttype_name}
                  </span>
                </div>
              </div>
              <div className='d-flex gap-2 align-items-center'>
                <button
                  onClick={() => onDownloadClick(item.attachment_id)}
                  style={{ cursor: 'pointer' }}
                  className='p-0 border-0 bg-transparent'
                >
                  <img src='/images/icons/print.svg' alt='print-icon' />
                </button>
                {(props.type==="line view" && !props.allowEdit)&&<button
                  onClick={() => fileDeleteHandler(item.attachment_id)}
                  style={{ cursor: 'pointer', height: '17px' }}
                  className='p-0 border-0 bg-transparent'
                >
                  <img
                    src='/images/icons/delete.svg'
                    alt='delete-icon'
                    style={{ marginTop: '-10px' }}
                  />
                </button>}
              </div>
            </div>
          ))
        )}
      </div>

  {(props.type==="line view" && !props.allowEdit) && <div>
     {!attachmentTypeId ? <Dropdown id="attachment-dropdown" options={attachmentTypeIds.map((item)=>{
        return {
          value:item.attachmenttype_id,
          label:item.name
        }
      })} onChange={onAttachmentDropdownChange} /> :
      <><AttachmentLoader percentage={percentage} isLoading={isLoading} fileName={currentFileName} />
      <div className='dotted-outer-div'>
        <span className='rfq-attachment-documents'>Upload Document</span>
        <Button variant='outline-primary' id={id}>
          Upload
        </Button>
        {/* <span className="rfq-attachment-value">22/12/21 6:00:00 PM</span> */}
      </div>
      </>}     
    </div>}
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(RFQAttachmentPopup)
