import React, { useState, Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import MasterComonent from '../../../../Backend/MasterComonent'
import NavigationHeder from '../../../../Navigations/NavigationHeder'
import MasterSidebar from '../../../../Sidebars/MasterSidebar'
import NoteAttachmentHistory from '../../../../Widgets/NoteAttachmentHistory'
import Api from '../../../../../inc/Api'
import Settings from '../../../../../inc/Settings'
import Button from '../../../../inc/Button'
import Helper from '../../../../../inc/Helper'
import WorkConfirmationForm from './WorkConfirmationForm'
import LineGrid from './LineGrid'
import Summary from './Summary'
import WorkConfirmationApiCalls from './WorkConfirmationApiCalls'
import { useLocation, useNavigate } from 'react-router-dom'
import StepperSection from './StepperSection/StepperSection'
import TotalWidget from '../../../../Widgets/TotalWidget/TotalWidget'
import DatepickerFunctions from '../../../../../inc/DatepickerHelper'
import { getPopupMessageBasedOnStatus, objectIsEmpty } from '../../../../../inc/Validation'
import axios from 'axios'
import Documents from '../../../../Registrations/Vendor/Documents'
import OverlayLoader from '../../../../PurchaseRequisition/common/OverlayLoader'

const AddComponentRight = ({ shipment_id }) => {
  return (
   !shipment_id ? <></> : <MasterSidebar>
      <div className="work-confirmation-sidebar">
        <NoteAttachmentHistory
          notesource_integrator={shipment_id}
          attachmentsource_integrator={shipment_id}
          source_id={Settings.source_id?.ShipmentGoodReceivingWorkConfirmation}
          hideHistory={true}
        />
      </div>
    </MasterSidebar>
  )
}

let cancelTokenSource = axios.CancelToken.source();
const NewWorkConfirmation = (props) => {
  const currentDate = new Date()

  const location = useLocation()
  const navigate = useNavigate()

  const [currentStep, setCurrentStep] = useState(0)
  const [savedSteps, setSavedSteps] = useState([false, false, false])
  const [loading, setLoading] = useState(false)
  const [saveBtnApi, setSaveBtnApi] = useState(false);
  const [deleteBtnApi, setDeleteBtnApi] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState({})
  const [shType, setShType] = useState(null)
  const [workingConfirmationNumber, setWorkingConfirmationNumber] = useState('')
  const [workConfirmationDescription, setWorkConfirmationDescription] = useState('')
  const [accountingDate, setAccountingDate] = useState(currentDate)
  const [documentDate, setDocumentDate] = useState(currentDate)
  const [linesData, setLinesData] = useState([])
  const [shipmentHeader, setShipmentHeader] = useState(null)
  const [lineIds, setLineIds] = useState([])
  const [purchaseOrderData,setPurchaseOrderData]=useState({})
  const workConfirmationApiCalls = new WorkConfirmationApiCalls()

  const nextStep = () => {
    setCurrentStep((prevStep) => (prevStep < 3 ? prevStep + 1 : prevStep))
  }

  const prevStep = () => {
    setCurrentStep((prevStep) => (prevStep > 0 ? prevStep - 1 : prevStep))
  }

  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }

  const purchaseorder_id = getSearchParam('purchaseorder_id')

  const handleSave = () => {
    setSavedSteps((prevSavedSteps) => {
      const updatedSavedSteps = [...prevSavedSteps]
      updatedSavedSteps[currentStep] = true
      localStorage.setItem('savedSteps', JSON.stringify(updatedSavedSteps))
      return updatedSavedSteps
    })
  }

  const isNextButtonDisabled = () => {
    if (
      (currentStep === 0 && !shipmentHeader && !workingConfirmationNumber) ||
      (currentStep === 0 &&
        shipmentHeader &&
        !shipmentHeader?.receiving_reference_number &&
        !shipmentHeader?.accounting_date &&
        !shipmentHeader?.document_date &&
        currentStep === 0 &&
        !workingConfirmationNumber)
    ) {
      return true
    }
    if(currentStep===3 || shipmentHeader?.document_status===1){
      return true;
    }
  }
  const disable = isNextButtonDisabled()

  //Api call
  const getAllLines = (shipment_id) => {
    if (!shipment_id) {
      return
    }
    setLoading(true)
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + `/get_sh_lines/${shipment_id}`, {})
      .then(function (res) {
        if (res.data.status === 'success') {
          const linePromises = res.data.data.map((obj) =>
            getLineItemDetails(obj.line_id),
          )
          Promise.all(linePromises)
            .then((lineItems) => {
              const updatedData = res.data.data.map((obj, index) => ({
                ...obj,
                items: lineItems[index],
              }))
              setLinesData(updatedData)
              setLoading(false)
            })
            .catch((error) => {
              console.error(error)
              setLoading(false)
            })
        }
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  const getLineItemDetails = (line_id) => {
    return new Promise((resolve, reject) => {
      let api = Api
      api.setUserToken()
      api
        .axios()
        .get(
          Settings.apiPurchaseOrderUrl +
            `/lines/${line_id}/${Settings.source_id.ShipmentGoodReceivingWorkConfirmation}`,
          {},
        )
        .then((res) => {
          if (res.data.status === 'success') {
            resolve(res.data.data)
          } else {
            reject(new Error('API call failed'))
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const get_purchase_order_details = () => {
    let api = Api
    api.setUserToken()
    if (purchaseorder_id === null || purchaseorder_id === undefined) return
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + `/PO_header/${purchaseorder_id}`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setPurchaseOrderData(res.data?.data)
          setSelectedDocument(res.data.data)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }

  const data = {
    purchase_order_id: selectedDocument?.purchase_order_id,
    document_number: selectedDocument?.document_number,
    description: workConfirmationDescription,
    vendor_id: selectedDocument?.vendor_id,
    vendorsite_id: selectedDocument?.vendorsite_id,
    vendorgroup_id: selectedDocument?.vendorgroup_id,
    accounting_date: DatepickerFunctions.convertDateForDataBase(accountingDate),
    document_date: DatepickerFunctions.convertDateForDataBase(documentDate),
    delivery_date: selectedDocument?.delivery_date,
    entity_id: selectedDocument?.entity_id,
    document_status: selectedDocument?.document_status,
    sh_type: shType,
    type: selectedDocument?.type,
    receiving_reference_number: workingConfirmationNumber,
  }

  const updatedShipmentHeaderData = {
    receiving_reference_number: workingConfirmationNumber,
    description:workConfirmationDescription,
    contactsource_id: null,
    attachmentsource_id: null,
    notesource_id: null,
    addresssource_id: null,
    accounting_date:DatepickerFunctions.convertDateForDataBase(accountingDate),
    document_date:DatepickerFunctions.convertDateForDataBase(documentDate)
  }

  const updatedShipmentHeader = async (workflow_triggerer_object = {}) => {
    let shipment_header_api_payload =
      objectIsEmpty(workflow_triggerer_object)
        ? updatedShipmentHeaderData
        : workflow_triggerer_object;  
    let api = Api
    api.setUserToken()
    try {
      const res = await api
        .axios()
        .put(
          Settings.apiPurchaseOrderUrl +
            `/sh_header/${shipmentHeader?.shipment_id}`,
          shipment_header_api_payload,
        )  
      if (res.data.status === 'success') {
        Helper.alert(res.data?.message, 'success');
        if(objectIsEmpty(workflow_triggerer_object)) {
          await getShipmentHeaderById();
          nextStep()
        }
      }
    }catch(res) {
      getPopupMessageBasedOnStatus(res)
    }
  }

  const handleStep1Next = () => {
    if (currentStep === 1) {
      if (linesData.length !== 0) {
        const incompleteLine = linesData?.find(line => parseInt(line.received_qty) === 0);
        if (incompleteLine) {
          Helper.alert('The Completion percentage / quantity cannot be zero. Please ensure all values are greater than zero before proceeding.', 'error');
        } else {
          nextStep();
          handleSave();
        }
      } else {
        Helper.alert('Please add line details', 'error');
      }
    }
    if(currentStep===2){
      nextStep()
      handleSave()
    }
  }

  const postWorkConfirmation = async (api) => {
    try {
      const res = await api.axios().post(Settings.apiPurchaseOrderUrl + `/sh_headers/${shType}`, data, { cancelToken: cancelTokenSource.token });
  
      if (res.data.status === 'success') {
        Helper.alert(res.data.message, 'success');
        navigate(`/work-confirmation/edit${location.search}#` + res.data.shipment_id,{state:{prevPath:location.search?`/work-confirmation/new${location.search}`:`/work-confirmation/new`}});
        handleSave();
        nextStep();
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  };
    
  const handleNextStep = () => {
    if (currentStep === 0) {
      let api = Api
      api.setUserToken()
      if (workingConfirmationNumber?.length > 20) {
        Helper.alert("Work confirmation number should be less than or equal to 20 characters.", "error");
        return;
      }
      if (location.hash) {
        if (
          location.hash &&
          shipmentHeader?.document_status !== 1 &&
          (shipmentHeader?.receiving_reference_number !==
            workingConfirmationNumber ||
            Helper.formateDate(shipmentHeader?.accounting_date) !==
              Helper.formateDate(accountingDate) ||
            Helper.formateDate(shipmentHeader?.document_date) !==
              Helper.formateDate(documentDate) || shipmentHeader?.description !== workConfirmationDescription)
        ) {
          Helper.createDebouncedAPIFunction([
            async () => await updatedShipmentHeader()
          ], setSaveBtnApi, cancelTokenSource, false, 500000)();  
        } else {
          nextStep()
        }
      } else {
        if(objectIsEmpty(selectedDocument)){
          Helper.alert("Please select a document","error")
          return
        }
        Helper.createDebouncedAPIFunction([
          async () => await postWorkConfirmation(api)
        ], setSaveBtnApi, cancelTokenSource,false, 500000)();  
      }
    }
    handleStep1Next()
  }

  const handleSubmit = () => {
    getShipmentHeaderById();
    updatedShipmentHeader({ workflow_initiated:true });

    const timer = setTimeout(() => {
      purchaseorder_id ? navigate(`/work-confirmation-list?purchaseorder_id=${purchaseorder_id}`) : navigate('/work-confirmation-list')
    }, 500)
    return () => clearTimeout(timer)
  }

  const onDeleteApiHandle = async (api) => {
    api.setUserToken();
    
    try {
      const res = await api
        .axios()
        .delete(Settings.apiPurchaseOrderUrl + `/sh_header/${shipmentHeader?.shipment_id}`);
      
      if (res.data.status === 'success') {
        Helper.alert(res.data?.message, 'success');
        const timer = setTimeout(() => {
          navigate(-2);
        }, 500);
        return () => clearTimeout(timer);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }


  const onDeleteButtonClick = () => {
    let api = Api
    Helper.createDebouncedAPIFunction([
      async () => await onDeleteApiHandle(api)
    ], setDeleteBtnApi, cancelTokenSource, 50000)();  
  }
  

  const getShipmentHeaderById = async () => {
    if (!location.hash && !shipmentHeader) return
    const shipment_header = await workConfirmationApiCalls.getAllShipmentHeaderById(
      location.hash.replace('#', ''),
    )
    if (location.hash) setShipmentHeader(shipment_header)
  }

  const getAsyncLineData = async () => {
    if (!shipmentHeader) return
    getAllLines(shipmentHeader?.shipment_id)
    const lineId = await workConfirmationApiCalls.getAllHeaderLines(
      shipmentHeader?.purchaseorder_id,
      Settings.source_id.contract,
    )
    if (lineId) setLineIds(lineId)
  }

  const handleClose = () => {
    if(location.state?.prevPath==="/details" || currentStep===0){
      navigate(-1)
    }else{
      navigate(-2)
    }
  }

  //useEffects
  useEffect(() => {
    const savedStepsFromLocalStorage = localStorage.getItem('savedSteps')
    if (savedStepsFromLocalStorage) {
      setSavedSteps(JSON.parse(savedStepsFromLocalStorage))
    }
  }, [])

  useEffect(() => {
    const getAsyncData = async () => {
      const shtypes = await workConfirmationApiCalls.getAllShType()
      const shtype = shtypes?.find((item) => item.name === 'Work Confirmation')
      if (shtype) setShType(shtype.id)
    }
    getAsyncData()
  }, [])

  useEffect(() => {
    getAsyncLineData()
  }, [shipmentHeader])

  useEffect(() => {
    getShipmentHeaderById()
  }, [location.hash])

  useEffect(()=>{
    get_purchase_order_details()
  },[purchaseorder_id])
  
  return (
    <div
      className={
        props.user?.usertype === 0
          ? 'request_for_quotation_archive external-screens work-confirmation-internal-screen responsive-screen-style'
          : 'request_for_quotation_archive external-screens responsive-screen-style'
      }
    >
      <MasterComonent>
        <div className="container-fluid invoice-screen">
          <NavigationHeder
            backUrl={location.state?.prevPath==="/details" || !location.hash?-1:-2}
            title={
              location.hash
                ? `Edit Work Confirmation`
                : `Create Work Confirmation`
            }
            hideMoreBtn={true}
          >
            <Button
                title={Helper.getLabel(props.language, 'close', 'Close')}
                onClick={handleClose}
                className="black-btn-style"
            /> 
             {
              shipmentHeader &&
              shipmentHeader.document_status === 0 && (
                <Button
                  title={Helper.getLabel(props.language, "delete", "Delete")}
                  onClick={onDeleteButtonClick}
                  className="red-btn-style"
                  isDisable={deleteBtnApi}
                />
              )}
            {currentStep > 0 && currentStep <= 3 && (
              <TotalWidget source_id={shipmentHeader?.source_id} integrator={shipmentHeader?.shipment_id} />
            )}
            {currentStep !== 0 && (
              <Button
                title={Helper.getLabel(props.language, 'back', 'Back')}
                onClick={prevStep}
                isDisable={shipmentHeader?.document_status===1}
                className="blue-btn-style"
              />
            )}
            {currentStep < 3 && <Button
              title={Helper.getLabel(props.language, "next", "Next")} 
              onClick={handleNextStep}
              isDisable={disable || saveBtnApi}
              className="blue-btn-style"
            />}
            {currentStep === 3 && (
              <Button
                title={Helper.getLabel(props.language, 'submit', 'Submit')}
                isActive={true}
                onClick={handleSubmit}
                isDisable={shipmentHeader?.document_status===1}
              />
            )}
          </NavigationHeder>
          <div>
            <StepperSection
              currentStep={currentStep}
              savedSteps={savedSteps}
              shipmentHeader={shipmentHeader}
            />
            <div className="work-confirmation-main-screen-grid" style={{gap:((objectIsEmpty(shipmentHeader) && !shipmentHeader?.shipment_id)) && '0px'}}>
                {currentStep === 0 && (
                  <div className="general-information-white">
                    <div className="rfq-bidding-information rounded">
                      <div className="rfq-information-title ">
                        <h2 className="grid_label_rfq mb-0">
                          {Helper.getLabel(
                            props.language,
                            'general_information',
                            'General Information',
                          )}
                        </h2>
                      </div>
                      <div className="col-sm-12 p-3 pt-4">
                        <WorkConfirmationForm
                          isLoading={loading}
                          selectedDocument={selectedDocument}
                          setSelectedDocument={setSelectedDocument}
                          workConfirmationNumber={workingConfirmationNumber}
                          setWorkingConfirmationNumber={
                            setWorkingConfirmationNumber
                          }
                          workConfirmationDescription={workConfirmationDescription}
                          setWorkConfirmationDescription={setWorkConfirmationDescription}
                          shipmentHeader={shipmentHeader}
                          accountingDate={accountingDate}
                          setAccountingDate={setAccountingDate}
                          documentDate={documentDate}
                          setDocumentDate={setDocumentDate}
                          userType={props.user?.usertype}
                          purchaseorder_id={purchaseorder_id}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {currentStep === 1 && (
                  <LineGrid
                    lineData={linesData}
                    lineIdOptions={lineIds}
                    setLinesData={setLinesData}
                    shType={shType}
                    workConfirmationApiCalls={workConfirmationApiCalls}
                    shipmentHeader={shipmentHeader}
                    fetchAllLines={() =>
                      getAllLines(shipmentHeader?.shipment_id)
                    }
                    userType={props.user?.usertype}
                  />
                )}
                {currentStep === 2 &&
                   <Documents source_id={shipmentHeader?.source_id} attachmentsource_id={shipmentHeader?.attachement_source_id} attachmentsource_integrator={shipmentHeader?.shipment_id} document_type="invoice" document_status={shipmentHeader?.document_status} />}
                {currentStep === 3 && (
                  <Summary
                    linesData={linesData}
                    lineIdOptions={lineIds}
                    userType={props.user?.usertype}
                    shipment_id={shipmentHeader?.shipment_id}
                  />
                )}
                {
                  <AddComponentRight
                    shipment_id={shipmentHeader?.shipment_id}
                  />
                }
            </div>
          </div>
        </div>
      </MasterComonent>
      {saveBtnApi || deleteBtnApi ? <OverlayLoader isLogoCenter={true} isPopupInTab={true} /> : <></>}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
    user: state.auth.user,
  }
}

export default connect(mapStateToProps)(NewWorkConfirmation)
