import React, { Fragment, useEffect, useState } from 'react';
import $ from 'jquery';
import Popup from '../../Popup/Popup';
import { connect } from "react-redux";
import axios from 'axios';
import Collapse from '../../inc/Collapse';
import { Form } from "react-bootstrap";
import Helper from '../../../inc/Helper';
import Button from '../../inc/Button';
import FieldsRenderer from '../../Purchase Orders Internal/Edit PO/FieldsRenderer';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import { useNavigate } from 'react-router-dom' 
import FormValidator from '../../../inc/FormValidator';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'; 
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';

let cancelTokenSource = axios.CancelToken.source();
const CreateVendorModal = ({setShowCreateVendorModal, language}) => {
    const navigate = useNavigate();

    const [vendorData, setVendorData] = useState({});
    const [vendorSiteData, setVendorSiteData] = useState({});

    const [apiCallBtn, setApiCallBtn] = useState(false);

    const [numberSequence, setNumberSequence] = useState({});
    const [vendorType, setVendorType] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [paymentTerms, setPaymentTerms] = useState([]);
    const [vendorGroup, setVendorGroup] = useState([]);
    const [taxGroup, setTaxGroup] = useState([]);

    let vendorValidator;  
    let vendorSitesValidator;

    const validationConfigure = () => {
        let vendorFieldConfig = [  
            {name: 'name', displayName:Helper.getLabel(language,'name','Name'),types:['Required']},  
            {name: 'vendor_type', displayName:Helper.getLabel(language,'type','Vendor Type'),types:['Required']},
            // {name: 'dunsnumber', displayName: Helper.getLabel(language, 'dunsnumber', 'Duns Number'), types: ['Number']} 
        ];
    
        let fieldConfigVendorSites = [
            { name: 'name', displayName: Helper.getLabel(language, 'vendorsite_name', 'Site Name'), types: ['Required'] },
            { name: 'currency_id', displayName: Helper.getLabel(language, 'currency_id', 'Currency'), types: ['Required'] },  
            { name: 'vendor_group_id', displayName: Helper.getLabel(language, 'vendor_group_id', 'Vendor Group'), types: ['Required'] },
        ];

        if(!vendorValidator) {
            vendorValidator = new FormValidator(vendorFieldConfig, language)
        };
    
        if(!vendorSitesValidator) {
            vendorSitesValidator = new FormValidator(fieldConfigVendorSites, language)
        };
    };

    validationConfigure();

    let vendorTypeCalled = false;
    let currenyListCalled = false;
    let paymentTermsListCalled = false;
    let vendorGroupListCalled = false;
    let taxGroupListCalled = false;
    let nsApiCalled = false;

    useEffect(() => { 
        getVendorTypes();
        getSystemCurrencies();
        getSystemPaymentTerms();
        getAllVendorGroups();
        getTaxGroups();
        getNumberSequenceData();
    }, []);

    const getNumberSequenceData = async () => {
        if(nsApiCalled) return;
        nsApiCalled = true;

        try {
            let api = Api
            api.setUserToken();
            const numberSequenceRecord = await api.axios().get(Settings.apiUrl + `/ns_status/None/${Settings.source_id.vendor}`)
            if (numberSequenceRecord?.status===200) { 
                setNumberSequence(numberSequenceRecord?.data)
            }
        } catch(error){
            setNumberSequence({})
        }
    };

    const getVendorTypes = () => {
        if(vendorTypeCalled) return;

        let api = Api;
        vendorTypeCalled = true;
        api.setUserToken();
        api.axios().get(Settings.apiUrl+`/enum/vendortype`,{}).then(function(res){
            if(res.data.status==="success"){  
                setVendorType(res?.data?.data?.map((element) => {
                    return {
                        value: element.id,
                        label: element.name
                    }
                }))
            }
        })
    };

    const getSystemCurrencies = () => {
        if(currenyListCalled) return;

        let api = Api
        currenyListCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+`/currency`,{}).then(function(res){
            if(res.data.status==="success"){  
                setCurrency(res?.data?.data?.map((element) => {
                    return {
                        value: element.currency_id,
                        label: element.currency_name
                    }
                })) 
            }
        })
    };

    const getSystemPaymentTerms = () => {
        if(paymentTermsListCalled) return;

        let api = Api
        paymentTermsListCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiVendorUrl+`/paymentterm`,{}).then(function(res){
            if(res.data.status==="success"){  
                setPaymentTerms(res?.data?.data?.map((element) => {
                    return {
                        value: element.paymentterm_id,
                        label: element.name
                    }
                })) 
            }
        })
    };

    const getAllVendorGroups = () => {
        if(vendorGroupListCalled) return;

        let api = Api
        vendorGroupListCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiVendorUrl+`/vendorgroup`,{}).then(function(res){
            if(res.data.status==="success"){   
                setVendorGroup(res?.data?.data?.map((element) => {
                    return {
                        value: element.vendorgroup_id,
                        label: element.name
                    }
                })) 
            }
        })
    };

    const getTaxGroups = () => {
        if(taxGroupListCalled) return;

        let api = Api
        taxGroupListCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+`/taxgroup`,{}).then(function(res){
            if(res.data.status==="success"){  
                setTaxGroup(res?.data?.data?.map((element) => {
                    return {
                        value: element.taxgroup_id,
                        label: element.name
                    }
                }))
            }
        })
    };

    const fieldsArrayGeneral = [
        {className:'col-lg-4', maxLength: 15, required: !numberSequence?.ns_type == "continuous", disable: numberSequence?.ns_type == "continuous" || apiCallBtn, label: Helper.getLabel(language,'vendor_code',"Vendor code"), inputType: "text", id:'code', name:'code'},
        {className:'col-lg-4', disable: apiCallBtn, maxLength: 255, label: Helper.getLabel(language,'supplier_trade_name',"Supplier trade name (EN)"), required:true, inputType: "text", id:'name', name:'name'},
        {className:'col-lg-4', disable: apiCallBtn, placeHolder: 'Select type', options: vendorType, label: Helper.getLabel(language,'type',"Type"), required:true, inputType: "dropdown", id:'vendor_type', name:'vendor_type'}, 
        {className:'col-lg-4', disable: apiCallBtn, maxLength: 100, label: Helper.getLabel(language,'supplier_trade_name_ar',"Supplier trade name (AR)"), inputType: "text", id:'alternate_name', name:'alternate_name'},
        // {className:'col-lg-4', disable: apiCallBtn, maxLength: 40, label: Helper.getLabel(language,'legacy_code',"Legacy code"), inputType: "text", id:'legacy_code', name:'legacy_code'},
        {className:'col-lg-4', disable: apiCallBtn, maxLength: 20, label: Helper.getLabel(language,'license_number',"License number"), inputType: "text", id:'externalcode', name:'externalcode'},
        // {className:'col-lg-4', disable: apiCallBtn, maxLength: 18, label: Helper.getLabel(language,'duns_number',"DUNS number"), inputType: "number", id:'dunsnumber', name:'dunsnumber'},
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'one-time-vendor',"One time vendor"), inputType: "checkbox", id:'onetime', name:'onetime'},
    ];

    const fieldsArrayVendorSites = [
        {className:'col-lg-4', disable: apiCallBtn, maxLength: 100, label: Helper.getLabel(language,'vendor_site_name',"Site name"), required:true, inputType: "text", id:'name', name:'name'}, 
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'currency',"Currency"), placeHolder: 'Select currency', options: currency, required: true, inputType: "dropdown", id:'currency_id', name:'currency_id'},
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'payment_terms',"Payment terms"), placeHolder: 'Select payment terms', options: paymentTerms, inputType: "dropdown", id:'paymentterm_id', name:'paymentterm_id'},
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'vendor_group',"Vendor group"), placeHolder: 'Select vendor group', options: vendorGroup, required: true, inputType: "dropdown", id:'vendor_group_id', name:'vendor_group_id'},
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'tax-group',"Tax group"), options: taxGroup, placeHolder: 'Select tax group', inputType: "dropdown", id:'taxgroup_id', name:'taxgroup_id'}, 
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'price_include',"Price include tax"), inputType: "checkbox", id:'price_include_tax', name:'price_include_tax'},
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'exclude_freight_from_discount',"Exclude freight from discount"), inputType: "checkbox", id:'exclude_freight_from_discount', name:'exclude_freight_from_discount'},
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'default',"Default"), inputType: "checkbox", id:'is_default', name:'is_default'}
    ];

    const onPopupClose = () => {
        $("html").removeClass("rs_popup_open");
        setShowCreateVendorModal(false);
    }

    const executePOSTVendorAPI = async () => {
        try { 
            let api = Api;
            api.setUserToken();
        
            const res = await api.axios().post(Settings.apiVendorUrl + '/vendor', {
                ...vendorData,
                onetime: vendorData.onetime == "on",
                vendor_sites: 
                [{
                    ...vendorSiteData, 
                    exclude_freight_from_discount: vendorSiteData.exclude_freight_from_discount == "on", 
                    is_default: vendorSiteData.is_default == "on", 
                    price_include_tax: vendorSiteData.price_include_tax == "on",
                    paymentterm_id: vendorSiteData?.paymentterm_id !== "" ? vendorSiteData?.paymentterm_id : null,
                    taxgroup_id: vendorSiteData?.taxgroup_id !== "" ? vendorSiteData?.taxgroup_id : null
                }]
            },
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res.data.message); 
                onPopupClose();
                navigate(`/edit-vendor/${res?.data?.vendor_id}`); 
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        }
    };

    const handleSubmit = () => { 
        if(!vendorValidator.isValid({...vendorData})){
            vendorValidator.displayMessage();
            vendorValidator.reset();
            return;
        };

        if (!vendorSitesValidator.isValid({...vendorSiteData})) {
            vendorSitesValidator.displayMessage();
            vendorSitesValidator.reset();
            return;
        };

        if(!numberSequence?.ns_type == "continuous" && (!vendorData?.code || vendorData?.code === "")) {
            Helper.alert('Vendor code field is required', 'error');
            return;
        }
        
        Helper.createDebouncedAPIFunction( 
            [executePOSTVendorAPI], 
            setApiCallBtn,
            cancelTokenSource
        )();
    };

    return (
        <Fragment> 
            { apiCallBtn ? <OverlayLoader /> : null }
            <Popup
                autoOpen={true} width="900px"
                onClose={onPopupClose} 
            >
                <>
                    <h5 className="create_rfq_modal__title">Create Vendor</h5>

                    <Collapse
                        className="mt-4 mb-4" open={true}
                        title={Helper.getLabel(
                            language,
                            "general_info",
                            "General Information"
                        )}
                    >
                        <Form>
                            <div className='container-fluid'>
                                <FieldsRenderer updateState={setVendorData} currentState={vendorData} fieldsArray={fieldsArrayGeneral} />
                            </div>
                        </Form> 
                    </Collapse>

                    <Collapse
                        className="mt-4 mb-4" open={true}
                        title={Helper.getLabel(
                            language,
                            "default_site",
                            "Default Site"
                        )}
                    >
                        <Form>
                            <div className='container-fluid'>
                                <FieldsRenderer updateState={setVendorSiteData} currentState={vendorSiteData} fieldsArray={fieldsArrayVendorSites} />
                            </div>
                        </Form>
                    </Collapse>
                </>

                <div className="addDocumentNumber-body-buttons" style={{ justifyContent: "flex-end" }}>
                    <Button
                        isActive={false} 
                        isDisable={apiCallBtn}
                        title={Helper.getLabel(language, "close", "Close")}
                        onClick={onPopupClose}
                    />
                    <Button
                        isDisable={apiCallBtn}
                        className="solid_btn" isActive={true}
                        title={Helper.getLabel(language, "create", "Create")}
                        onClick={handleSubmit}
                    />
                </div>
            </Popup>
        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        appOptions: state.options,
        auth: state.auth,
        language: state.language,
    };
};

export default connect(mapStateToProps)(CreateVendorModal); 