import React from "react";
import $ from "jquery";
import Form from "react-bootstrap/Form";

import Popup from "../../Popup/Popup";
import Helper from "../../../inc/Helper";
import Button from "../../inc/Button";
import { Link } from "react-router-dom";

const SelectHeaderModal = ({
  language,
  handleCloseModal,
  setShowConvertToModal,
  uniqueHeaderIDs,
  selectedPRs,
  selectedHeaderID,
  setSelectedHeaderID,
}) => {
  return (
    <Popup
      autoOpen={true}
      width="900px"
      className="rf_radio_form_modal"
      onClose={() => {
        $("html").removeClass("rs_popup_open");
        setSelectedHeaderID(null);
        handleCloseModal();
      }}
    >
      <h5>
        {Helper.getLabel(
          language,
          "select_one_header_id_to_continue",
          "Select one Header ID to continue"
        )}
      </h5>
      <Form>
        {uniqueHeaderIDs.map((id, index) => {
          const PRInAction = selectedPRs.find((PR) => PR.requisition_id === id);
          if (!PRInAction) return null;
          return (
            <div key={id}>
              <label
                for={`select_header_modal_option_${index}`}
                className={
                  selectedHeaderID === id
                    ? "form_check_selected form_check_row"
                    : "form_check_row"
                }
              >
                <Form.Check
                  name="select_header_id"
                  type="radio"
                  id={`select_header_modal_option_${index}`}
                  value={PRInAction?.requisition_id}
                  onChange={(e) =>
                    setSelectedHeaderID(parseInt(e.target.value))
                  }
                />
                <Link
                  to={
                    "/purchase-requisition/summary?pr_view_id=" +
                    id +
                    "&return=rfq"
                  }
                >
                  {PRInAction?.requisition_number}
                </Link>
              </label>
              <hr />
            </div>
          );
        })}
      </Form>
      <div
        className="addDocumentNumber-body-buttons"
        style={{ justifyContent: "flex-end" }}
      >
        <Button
          isActive={false}
          title={Helper.getLabel(language, "cancel", "Cancel")}
          onClick={() => {
            $("html").removeClass("rs_popup_open");
            setSelectedHeaderID(null);
            handleCloseModal();
          }}
        />
        <Button
          className="solid_btn"
          isActive={true}
          title={Helper.getLabel(language, "select", "Select")}
          onClick={() => {
            $("html").removeClass("rs_popup_open");
            handleCloseModal();
            setShowConvertToModal(true);
          }}
        />
      </div>
    </Popup>
  );
};

export default SelectHeaderModal;
