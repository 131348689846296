import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';
import Popup from '../../Popup/Popup';
import Collapse from '../../inc/Collapse';
import { Form } from 'react-bootstrap';
import FieldsRenderer from '../../Purchase Orders Internal/Edit PO/FieldsRenderer';
import Button from '../../inc/Button';
import axios from 'axios';
import $ from 'jquery';
import FormValidator from '../../../inc/FormValidator';
import { useNavigate } from 'react-router-dom';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import Helper from '../../../inc/Helper';

let cancelTokenSource = axios.CancelToken.source();
const SettlementPeriodsModal = ({ setShowCreateModal, language }) => {
    const [taxAuthorities, setTaxAuthorities] = useState([]); 
    const [settlementsIntervals, setSettlementsIntervals] = useState([]); 

    const [formData, setFormData] = useState({}); 

    const [apiCallBtn, setApiCallBtn] = useState(false);

    const navigate = useNavigate();

    let formValidatory;   

    const validationConfigure = () => {
        let formFieldConfig = [
            {name:'code', displayName:Helper.getLabel(language,'code','Code'),types:['Required'] },
            {name:'description', displayName:Helper.getLabel(language,'description','Description'),types:['Required']},
            {name:'taxauthority_id', displayName:Helper.getLabel(language,'tax_authority','Tax authority'),types:['Required']}, 
            {name:'period_interval', displayName:Helper.getLabel(language,'period_interval','Period interval'),types:['Required']},
            {name:'period_interval_days', displayName:Helper.getLabel(language,'period_interval_days','Period interval days'),types:['Required']},
        ];

        if(!formValidatory) {
            formValidatory = new FormValidator(formFieldConfig, language)
        }; 
    };

    validationConfigure();

    let taxAuthoritiesCalled = false; 
    let periodIntervalsCalled = false; 

    useEffect(() => { 
        getTaxAuthorities();
        getPeriodIntervals();
    }, []);

    const getTaxAuthorities = () => {
        if (taxAuthoritiesCalled) return;
        taxAuthoritiesCalled = true;

        let api = Api; 
        api.setUserToken();
        api
        .axios()
        .get(Settings.apiUrl+`/taxauthority`, {})
        .then(function (res) {
            if (res.data.status === "success") { 
                setTaxAuthorities(res?.data?.data?.map((item) => {
                    return {
                        value: item.taxauthority_id,
                        label: item.name
                    }
                }));
            }
        });
    };

    const getPeriodIntervals = () => {
        if (periodIntervalsCalled) return;
        periodIntervalsCalled = true;

        let api = Api; 
        api.setUserToken();
        api
        .axios()
        .get(Settings.apiUrl + '/taxSettlementPeriods', {})
        .then(function (res) {
            if (res.data.status === "success") { 
                setSettlementsIntervals(res?.data?.data?.map((item) => {
                    return {
                        value: item.id,
                        label: item.name
                    }
                }));
            }
        });
    };

    const fieldsArraySettlementPeriods = [ 
        {className:'col-lg-4', required: true, disable: apiCallBtn, maxLength: 18, label: Helper.getLabel(language,'code',"Code"), inputType: "text", id:'code', name:'code'}, 
        {className:'col-lg-8', required: true, disable: apiCallBtn, label: Helper.getLabel(language,'description',"Description"), inputType: "text", id:'description', name:'description'}, 
        {className:'col-lg-4', required: true, disable: apiCallBtn, inputType: "dropdown", label: Helper.getLabel(language,'tax_authority','Tax authority'), options: taxAuthorities, id:'taxauthority_id', name:'taxauthority_id'},
        {className:'col-lg-4', required: true, disable: apiCallBtn, inputType: "dropdown", label: Helper.getLabel(language,'period_interval','Period interval'), options: settlementsIntervals, id:'period_interval', name:'period_interval'},
        {className:'col-lg-4', required: true, disable: apiCallBtn, label: Helper.getLabel(language,'period_interval_days','Period interval days'), inputType: "number", id:'period_interval_days', name:'period_interval_days'}
    ];

    const onPopupClose = () => {
        $("html").removeClass("rs_popup_open");
        setShowCreateModal(false);
    }

    const executePOSTAPI = async () => {
        try { 
            let api = Api;
            api.setUserToken();

            const res = await api.axios().post(Settings.apiUrl + '/settlementheader', {
                ...formData,
                settlements_periods: []
            },
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res?.data?.message); 
                onPopupClose();
                navigate(`/tax/edit-settlement-period/${res?.data?.settlement_header_id}`); 
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        }
    };

    const handleSubmit = () => { 
        if(!formValidatory.isValid({...formData})){
            formValidatory.displayMessage();
            formValidatory.reset(); 
            return;
        };

        Helper.createDebouncedAPIFunction( 
            [executePOSTAPI], 
            setApiCallBtn,
            cancelTokenSource
        )();
    };

    return (
        <Fragment> 
            { apiCallBtn ? <OverlayLoader /> : null }
            <Popup
                autoOpen={true} width="900px"
                onClose={onPopupClose} 
            >
                <>
                    <h5 className="create_rfq_modal__title">Create Settlement Period</h5>

                    <Collapse
                        className="mt-4 mb-4" open={true}
                        title={Helper.getLabel(
                            language,
                            "general_info",
                            "General Information"
                        )}
                    >
                        <Form>
                            <div className='container-fluid'>
                                <FieldsRenderer updateState={setFormData} currentState={formData} fieldsArray={fieldsArraySettlementPeriods} />
                            </div>
                        </Form> 
                    </Collapse>
                </>

                <div className="addDocumentNumber-body-buttons" style={{ justifyContent: "flex-end" }}>
                    <Button
                        isActive={false} 
                        isDisable={apiCallBtn}
                        title={Helper.getLabel(language, "close", "Close")}
                        onClick={onPopupClose}
                    />

                    <Button
                        isDisable={apiCallBtn}
                        className="solid_btn" isActive={true}
                        title={Helper.getLabel(language, "create", "Create")}
                        onClick={handleSubmit}
                    />
                </div>
            </Popup>
        </Fragment>
    )
}
 
const mapStateToProps = (state) => {
    return {
        appOptions: state.options,
        auth: state.auth,
        language: state.language,
    };
};

export default connect(mapStateToProps) (SettlementPeriodsModal);  