import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Api from '../../../inc/Api'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import FieldsRenderer from './FieldsRenderer'

const PriceDiscountTab = (props) => {

  //States For Dropdowns
    const [paymentTerms, setPaymentTerms] = useState([])
  //End

  //API Calls Renderer
   useEffect(() => {
    PaymentTermsAPI()
   }, [])
  // End

  //API Reduction 
   let paymentTermsCalled = false
  //End

  
  // API Calls
  const PaymentTermsAPI = () => {
    if(paymentTermsCalled) {
      return;
    }
      let api = Api
      paymentTermsCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiVendorUrl+'/paymentterm',{}).then(function(res){
      if(res.data.status==="success"){
        let PaymentARR = []
        res.data.data.forEach(item => {  
        PaymentARR.push({
          value: item.paymentterm_id,
          label: `${item.code} - ${item.name}`
        })
      }) 
      setPaymentTerms(PaymentARR)
      } 
    }).catch((res) => { 
      console.log(res.response.data.message)
      // Helper.alert(res.response.data.message, 'error')
    })
  }

  //End

    let textBoxInputSettings = [ 
        {label:Helper.getLabel(props.language,'payment_term',"Payment term"), options: paymentTerms, inputType: "dropdown", id: 'paymentterm_id', name:'payment_term_id',disable:!props.isEditable,reRenderRequired:true,placeHolder:Helper.getLabel(props.language,'select_payment_term',"Select payment term")},
        {label:Helper.getLabel(props.language,'discount_percentage',"Discount percentage"), inputType: "number", max:100, name:'discount_percentage',disable:!props.isEditable,reRenderRequired:true},
        {label:Helper.getLabel(props.language,'discount_amount',"Discount amount"), inputType: "number", name:'discount_amount',disable:!props.isEditable,reRenderRequired:true},
        {label:Helper.getLabel(props.language,'rounding_amount',"Rounding amount"), inputType: "number", name:'rounding_amount',disable:!props.isEditable,reRenderRequired:true},
        {label:Helper.getLabel(props.language,'price_inclusive_of_tax',"Price inclusive of tax"), inputType: "checkbox", name:'price_include_tax' ,disable:!props.isEditable, reRenderRequired:true, className:'col-lg-12'},
    ]

  return (
    <Fragment>
        <FieldsRenderer fieldsArray={textBoxInputSettings} updateState={props.setState} currentState={props.state} />
    </Fragment>
  )
}
 
const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
 


export default connect(mapStateToProps) (PriceDiscountTab) 