import React, { Component } from "react";
import { Link } from "react-router-dom";
import './SettingsHeader.css';
import LanguageSwitch from '../../components/Language Switcher/LanguageSwitch';
import ActionTypes from "../../inc/ActionsTypes";
import { connect } from "react-redux";
import SearchBox from "../Header/SearchBox";

class SettingsHeader extends Component {
  render() {
    return (
      <div className="main_header">
        <div className="container-fluid">
          <div className="d-flex justify-content-between mh_inner">
            <div className="left_section">
              <img className='sidebar_toggle' src= { this.props.appOptions.options.isSidebarOpen ? "/images/icons/close-2.svg" : "/images/icons/menu.svg" } onClick={ e => { this.props.setOption('isSidebarOpen', !this.props.appOptions.options.isSidebarOpen)} } alt="" />
              
            </div>
            <div className="d-flex right_section">
              <LanguageSwitch />
              <SearchBox />
              <Link to={"/dashboard"} className="note"><img src="/images/icons/note.svg" alt="" /></Link>
              Hello
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
      auth:state.auth,
      appOptions:state.options
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
      setOption:(keyOption,valueOption) => { dispatch({type:ActionTypes.SET_OPTIONS ,  key: keyOption, value: valueOption }) },
  }
}
export default connect(mapStateToProps,mapDispatchToProps) (SettingsHeader);
