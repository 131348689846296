import { Component } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { connect } from "react-redux";
import ActionTypes from "../../../inc/ActionsTypes";
import Helper from "../../../inc/Helper";
import Button from "../../inc/Button";
import SimpleLoading from "../../Loading/SimpleLoading";

class ApplyVendorToLinesModal extends Component {
  render() {
    const { show, hide, isLoading, handleApplyVendorsToAll, updateCart } =
      this.props;

    return (
      <Modal className="addDocumentNumber" show={show} onHide={hide}>
        <ModalBody>
          <div className="addDocumentNumber-body p-2">
            <div className="addDocumentNumber-body-content" style={{justifyContent:"start"}}>
              <label>
                {Helper.getLabel(
                  this.props.language,
                  "apply_same_vendor_and_vendor_site_to_all_cart_lines",
                  "Do you want to apply same vendor and vendor site to all cart lines?"
                )}
              </label>
            </div>
            <div className="d-flex justify-content-end gap-2">
              {isLoading ? (
                <SimpleLoading />
              ) : (
                <>
                  {" "}
                  <Button
                    isActive={false}
                    title={Helper.getLabel(
                      this.props.language,
                      "cancel",
                      "Cancel"
                    )}
                    onClick={() => {
                      this.props.hide();
                    }}
                  />
                  <Button
                    isActive={true}
                    title={Helper.getLabel(
                      this.props.language,
                      "apply",
                      "Apply"
                    )}
                    onClick={() => {
                      handleApplyVendorsToAll();
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleDidCartUpdate: () => {
      dispatch({ type: ActionTypes.TOGGLE_DIDCARTUPDATE });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplyVendorToLinesModal);
