import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import MasterComonent from '../../components/Backend/MasterComonent'
import InnerScreen from '../../components/Inner Screens/InnerScreen'
import Helper from '../../inc/Helper'

const ProcureScreen = ({language}) => {

{/*
  route to use after modules done:- 
  contract orders:
 
  /contract-orders-coming-renewal
  /contract-orders-renew
  /contract-orders-billing-rule

  request for quotations:

  /request-for-quotations-extended
  /request-for-quotations-amendment 
*/}

  return (
    <div className='modules_screen_lynkaz'>
      <MasterComonent>
        <InnerScreen innerTitle='Sourcing'>

          <div className='col-lg-3 gy-4'>
            <div className='inner-page-header border-bottom'>
              <h2>{Helper.getLabel(language,'request_for_quotations','Request for Quotations')}</h2>
            </div>
            <div className='inner-page-routes'>
              <Link to='/request-for-quotation/rfqheaderlist'>{Helper.getLabel(language,'request_for_quotations_all','Request for Quotations - All')}</Link> 
              <Link to='/request-for-quotation/rfqheaderlist?status=0'>{Helper.getLabel(language,'request_for_quotations_open','Request for Quotations - Open')}</Link>
              <Link to='/request-for-quotation/rfqheaderlist?vendor_reply=true'>{Helper.getLabel(language,'request_for_quotations_vendor_reply','Request for Quotations - Vendor reply')}</Link>
              {/* <Link to='/procure'>{Helper.getLabel(language,'request_for_quotations_extended','Request for Quotations - Extended')}</Link> Add link when module completed */}
              {/* <Link to='/procure'>{Helper.getLabel(language,'request_for_quotations_amendment','Request for Quotations - Amendment')}</Link> Add link when module completed */}
            </div>
          </div> 
          
          <div className='col-lg-3 gy-4'>
            <div className='inner-page-header border-bottom'>
              <h2>{Helper.getLabel(language,'purchase_orders','Purchase Orders')}</h2>
            </div>
            <div className='inner-page-routes'>
              <Link to='/purchase-order/all'>{Helper.getLabel(language,'purchase_orders_all','Purchase Orders - All')}</Link> 
              <Link to='/purchase-order/all?status=0,3,6'>{Helper.getLabel(language,'purchase_orders_open_withdraw_freeze','Purchase Orders - Open, Reopen, Withdraw Freeze')}</Link>
              <Link to='/purchase-order/all?status=4'>{Helper.getLabel(language,'purchase_orders_freeze','Purchase Orders - Freeze')}</Link>  
              <Link to='/purchase-order/all?status=5'>{Helper.getLabel(language,'purchase_orders_onhold','Purchase Orders - On Hold')}</Link>
              <Link to='/purchase-order/all?status=7'>{Helper.getLabel(language,'purchase_orders_cancelled','Purchase Orders - Canceled')}</Link>
            </div>
          </div> 
          
          <div className='col-lg-3 gy-4'>
            <div className='inner-page-header border-bottom'>
              <h2>{Helper.getLabel(language,'release_order','Release Orders')}</h2>
            </div>
            <div className='inner-page-routes'>
              <Link to='/release-order/all'>{Helper.getLabel(language,'release_order_all','Release Orders - All')}</Link> 
              <Link to='/release-order/all?status=0,3,6'>{Helper.getLabel(language,'release_order_open_withdraw_free','Release Orders - Open, Reopen, Withdraw Freeze')}</Link>
              <Link to='/release-order/all?status=4'>{Helper.getLabel(language,'release_orders_freeze','Release Orders - Freeze')}</Link>  
              <Link to='/release-order/all?status=5'>{Helper.getLabel(language,'release_orders_on_hold','Release Orders - On Hold')}</Link>
              <Link to='/release-order/all?status=7'>{Helper.getLabel(language,'release_orders_cancelled','Release Orders - Canceled')}</Link>
            </div>
          </div> 
          
          <div className='col-lg-3 gy-4'>
            <div className='inner-page-header border-bottom'>
              <h2>{Helper.getLabel(language,'contract_orders','Contract Orders')}</h2>
            </div>
            <div className='inner-page-routes'>
              <Link to='/contract-order/all'>{Helper.getLabel(language,'contract_orders_all','Contract Orders - All')}</Link>  
              <Link to='/contract-order/all?status=0,3,6'>{Helper.getLabel(language,'contrac_orders_open_reopen_withdraw_freeze','Contract Orders - Open, Reopen, Withdraw Freeze')}</Link> 
              <Link to='/contract-order/all?status=4'>{Helper.getLabel(language,'contract_orders_freeze','Contract Orders - Freeze')}</Link>   
              <Link to='/contract-order/all?status=5'>{Helper.getLabel(language,'contract_orders_on_hold','Contract Orders - On Hold')}</Link> 
              <Link to='/contract-order/all?status=7'>{Helper.getLabel(language,'contract_orders_cancelled','Contract Orders - Canceled')}</Link> 
              <Link to='/contract-order/all?status=10'>{Helper.getLabel(language,'contract_orders_amendments','Contract Orders - Amendments')}</Link> 
              {/* <Link to='/procure'>{Helper.getLabel(language,'contract_orders_coming_renewal','Contract Orders - Coming Renewal (month)')}</Link> Add link when module completed */}
              {/* <Link to='/procure'>{Helper.getLabel(language,'contract_orders_renew','Contract Orders - Renew')}</Link> Add link when module completed */}
              {/* <Link to='/procure'>{Helper.getLabel(language,'contract_orders_billing_rule','Contract Orders - Billing Rule')}</Link> Add link when module completed */}
            </div>
          </div> 
          
          <div className='col-lg-3 gy-4'>
            <div className='inner-page-header border-bottom'>
              <h2>{Helper.getLabel(language,'purchase_agreement','Purchase Agreement')}</h2>
            </div>
            <div className='inner-page-routes'>
              <Link to='/purchase-agreement/all'>{Helper.getLabel(language,'purchase_agreement_all','Purchase Agreement - All')}</Link>
              <Link to='/purchase-agreement/all?status=0,3,6'>{Helper.getLabel(language,'purchase_agreement_open_reopen_withdraw_freeze','Purchase Agreement - Open, Reopen, Withdraw Freeze')}</Link>
              <Link to='/purchase-agreement/all?status=4'>{Helper.getLabel(language,'purchase_agreement_freeze','Purchase Agreement - Freeze')}</Link>  
              <Link to='/purchase-agreement/all?status=5'>{Helper.getLabel(language,'purchase_agreement_on_hold','Purchase Agreement - On Hold')}</Link>
              <Link to='/purchase-agreement/all?status=7'>{Helper.getLabel(language,'purchase_agreement_canceled','Purchase Agreement - Canceled')}</Link> 
              <Link to='/purchase-agreement/all?status=10'>{Helper.getLabel(language,'purchase_agreement_amendments','Purchase Agreement - Amendments')}</Link>
              <Link to='/procure'>{Helper.getLabel(language,'purchase_agreement_coming_renewal_month','Purchase Agreement - Coming Renewal (month)')}</Link>   {/* Add link when module completed */}
              <Link to='/procure'>{Helper.getLabel(language,'purchase_agreement_renew','Purchase Agreement - Renew')}</Link> {/* Add link when module completed */}
            </div>
          </div> 
        </InnerScreen>
      </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth:state.auth,
    language:state.language
  }
}

export default connect(mapStateToProps) (ProcureScreen);