import React, { Component } from 'react';
import Api from '../../../inc/Api';
import Button from '../../inc/Button';
import SimpleLoading from '../../Loading/SimpleLoading';
import AddressList from '../../Tenant/Address/AddressList';
import Collapse from '../../inc/Collapse';
import ContactAddress from '../../Tenant/Address/ContactAddress';

class CompanyAddress extends Component {
    constructor(props){
        super(props);
        this.state = {
           isLoading:false,
           vendorAddress:[],
        }
        this.gridObj = null
        this.contactGridObj = null
        this.total = 0
        this.contactGridTotal = 0
    }

    onAddressGridReady(event){
        this.gridObj = event
    }

    onContactGridReady(event){
        this.contactGridObj = event
    }

    getTotalItems(){
       if(this.gridObj){
          this.gridObj.api.forEachNode(item => {
            this.total++;
          })
       }
       return this.total
    }

    getContactTotalItems(){
        if(this.contactGridObj){
           this.contactGridObj.api.forEachNode(item => {
             this.contactGridTotal++;
           })
        }
        return this.contactGridTotal
     }

    goStep(stepNumber){  
        this.getTotalItems()
        this.getContactTotalItems() 
        if (this.total > 0 && this.contactGridTotal > 0) {
            this.props.updateStep(1,true)
        }
        else{
            this.props.updateStep(1,false) 
        }
        this.props.goStep(stepNumber);
    }

    onAddressCreate(responseData){
        let validData = this.props.data;
        let excludedFields = ['code']
        validData = Object.keys(validData)
        .filter(key => !excludedFields.includes(key))
        .reduce((obj, key) => {
            obj[key] = validData[key];
            return obj;
        }, {});
        if(!validData.addresssource_id && responseData.status =='Success'){
            validData.addresssource_id = responseData.address_source_id;
            let that = this;
            that.setState({
                isLoading:true
            })
            let api = Api;
            if(!validData.vendor_id){
                console.log("Vendor Id not found!");
                return;
            }
            that.props.setVendorState(validData)
            that.props.updateStep(1,true)
            that.props.loadVendorDetails()
        }
    }
    onContactCreate(responseData){
        let validData = {
            ...this.props.data,
            contactsource_id:responseData.contact_source_id
        }
        let that = this;
        that.props.setVendorState(validData)
        that.props.loadVendorDetails()
        that.props.updateStep(1,true)
    }
    onAddressLoad(res){
        let that = this
        if (res?.length > 0) {
            that.props.updateStep(1,true)
        }
        else{
            that.props.updateStep(1,false) 
        }
    }
    onContactLoad(res){
        if (res?.length > 0) {
            this.props.updateStep(1,true)
        }
        else{
            this.props.updateStep(1,false) 
        }
    }
    render() {
        
        let activeStep = this.props.activeStep;
        let data = this.props.data;
        let disableCondition = data?.document_status!=="Draft";
        let addressSourc_id = data.addresssource_id ? data.addresssource_id : null;
        let address_source_integrator = data.vendor_id ? data.vendor_id : null;
        let source_id = this.props.source_id;
        let contactsource_id = this.props.data.contactsource_id ? this.props.data.contactsource_id : null;
        let contactsource_integrator = this.props.data.vendor_id ? this.props.data.vendor_id : null;
        return (
            <div>
                {this.state.isLoading ? <SimpleLoading/> :<><AddressList onGridReady={this.onAddressGridReady.bind(this)} source_id={this.props.source_id} onAddressCreate={ this.onAddressCreate.bind(this)} onAddressLoad={this.onAddressLoad.bind(this)} addressSourc_id={addressSourc_id} address_source_integrator={address_source_integrator} disableRecords={disableCondition}/>
                <Collapse title="Contact Detail" open={true} className={disableCondition?"vendor-registration-contact":""}>
                    {this.state.isLoading ? <SimpleLoading/> : <ContactAddress onGridReady={this.onContactGridReady.bind(this)} source_id={source_id} contactsource_id ={contactsource_id} contactsource_integrator ={contactsource_integrator} onContactCreate={this.onContactCreate.bind(this)} canEdit={!disableCondition} onContactLoad={this.onContactLoad.bind(this)}/> }
                </Collapse>
                </>}
                <div className='tabs_changer'>
                    {activeStep >= 2 ? <Button className="light_gray btn_back" title="Back" onClick={e => this.goStep( activeStep - 1 )}/> : '' }
                    <Button className="blue" title="Next" onClick={e => this.goStep( activeStep + 1 )}/>
                </div>
            </div>
            
        );
    }
}

export default CompanyAddress;