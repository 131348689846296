import React, { Component } from 'react';
import EditPO from '../../components/Purchase Orders Internal/Edit PO/EditPO';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings';

class ReleaseOrderEditPage extends Component {
    render() {
        return (
            <EditPO
                title={Helper.getLabel(null,'edit_release_order',"Edit Release Order")}
                lineTitle={Helper.getLabel(null, 'release_order_lines',"Release Order Lines")}
                backUrl={-1}
                versionUrl='/release-order/version'
                historyUrl='/release-order/history'
                logsUrl='/release-order/logs'
                goodReceivingUrl='/release-order/good-receiving/notes'
                returnOrderUrl='/release-order/return-order/all'
                amendmentRequestUrl='/release-order/amendment-request/all'
                hideStartDate = {true}
                hideEndDate = {true}
                hideBillingRule = {true}
                formType={"release order"}
                type={"2"}
                source_id = {Settings.source_id.release_order}
            />
        );
    }
}

export default ReleaseOrderEditPage;