import React, { Component } from 'react';
import EditPO from '../../components/Purchase Orders Internal/Edit PO/EditPO';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings';

class PurchaseAgreementEditPage extends Component {
    render() {
        return (
            <EditPO
                title={Helper.getLabel(null,'edit_purchase_agreement',"Edit Purchase Agreement")}
                lineTitle={Helper.getLabel(null, 'agreement_lines',"Purchase Agreement Lines")}
                backUrl='/purchase-agreement/all'
                versionUrl='/purchase-agreement/version'
                historyUrl='/purchase-agreement/history'
                logsUrl='/purchase-agreement/logs'
                goodReceivingUrl='/purchase-agreement/good-receiving/notes'
                returnOrderUrl='/purchase-agreement/return-order/all'
                amendmentRequestUrl='/purchase-agreement/amendment-request/all'
                hideStartDate = {false}
                hideEndDate = {false}
                hideBillingRule = {false}
                source_id = {Settings.source_id.purchase_agreement}
                visibleEndDate = {true}
                visibleStartDate = {true}
                formType={'purchase agreement'}
                type={'purchase agreement'}
            />
        );
    }
}

export default PurchaseAgreementEditPage;