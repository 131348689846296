import React from "react";  
import InnerScreen from "../../components/Inner Screens/InnerScreen";
import { Link } from "react-router-dom"; 
import MasterComonent from "../../components/Backend/MasterComonent";
import {connect} from "react-redux"

const Finance = (props) => { 
    return (
        <div className="modules_screen_lynkaz">  
            <MasterComonent> 
                <InnerScreen innerTitle="Finance">
                    <div className="col-lg-3 gy-3">
                        <div className="inner-page-header border-bottom">
                            <h2>Invoices</h2>
                        </div>
                        <div className="inner-page-routes">
                            <Link to="/invoice-list">Invoices - All</Link>
                            <Link to="/invoice-list?status=0">Invoices - Open</Link>
                            <Link to={`/invoice-list?createby=${props.auth?.user?.user_id}`}>Invoices - Created by Me</Link>
                            <Link to="/invoice-list?document_status=1">Invoices - Posted</Link>
                            <Link to="/invoice-list?on_account=true">Invoices - On Account</Link>
                            <Link to="/credit-notes">Credit Notes - All</Link>
                            <Link to="/credit-notes?status=0">Credit Notes - Open</Link>
                            <Link to={`/credit-notes?createby=${props.auth?.user?.user_id}`}>Credit Notes - Created by Me</Link>
                            <Link to="/credit-notes?document_status=1">Credit Notes - Posted</Link>
                            <Link to="/credit-notes?on_account=true">Credit Notes - On Account</Link>
                        </div>
                    </div> 
                </InnerScreen> 
            </MasterComonent>
        </div>
    );
};


const mapStateToProps = (state) => {
    return {
      auth: state.auth,
      language: state.language,
    };
  };
  
  export default connect(mapStateToProps)(Finance);