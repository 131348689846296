import React, { Component } from 'react';
import GoodsReceivingNotes from '../../components/GoodsReceiving/GoodsReceivingNotes';
import Helper from '../../inc/Helper';

class PoGoodReceivingNotesPage extends Component {
    render() {
        return (
            <GoodsReceivingNotes
                title={Helper.getLabel(null,'purchase_order_goods_receiving_note','Purchase Order Goods Receiving Note')}
                backUrl='/purchase-order/edit'
                editUrl='/purchase-order/good-receiving/edit'
            />
        );
    }
}

export default PoGoodReceivingNotesPage;