import React, { Component } from "react";
import { connect } from "react-redux";

import Api from "../../../inc/Api";
import Helper from "../../../inc/Helper";
import DatepickerFunctions from "../../../inc/DatepickerHelper";
import Settings from "../../../inc/Settings";
import Collapse from "../../inc/Collapse";
import NavigationHeder from "../../Navigations/NavigationHeder";
import SegmentsDistribution from "../../Segments/SegmentsDistribution";
import MasterSidebar from "../../Sidebars/MasterSidebar";
import NoteAttachmentHistory from "../../Widgets/NoteAttachmentHistory";
import Tabs from "../../Widgets/Tabs/Tabs";
import OrderSummary from "../common/OrderSummary";
import MasterComonent from "../../Backend/MasterComonent";
import NavCartWishlist from "../common/NavCartWishlist";
import RsWithRouter from "../../inc/RsWithRouter";
import ActionTypes from "../../../inc/ActionsTypes";
import SimpleLoading from "../../Loading/SimpleLoading";
import HeaderForm from "./HeaderForm";
import RFQBiddingRequirement from "../../External Screens/RFQ Vendor Reply/RFQBiddingDetails/RFQBiddingRequirement";
import axios from "axios";
import { getPopupMessageBasedOnStatus } from "../../../inc/Validation";
import FormValidator from "../../../inc/FormValidator";
import OverlayLoader from "../common/OverlayLoader";
import WorkFlowCommonHeaderAPICalls from "../../Header/WorkflowCommonHeaderAPICalls";
import WorkFlowOffCanvas from "../../Header/WorkFlowOffCanvas";

class AddRequisitionDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      saveBtnApi: false,
      isLoading: false,
      isCreatingHeader: false,
      cartItems: [],
      selectedLineIds: [],
      purchaseRequisitionId: null,
      discount: 0,
      tax: 0,
      requisitionStatusList: [],
      documentStatusList: [],
      initialDataLoaded: false,
      initialDataUnvailable: true,
      showCanvas: false,
      showCurrentAssignees: false,
      description: null,
      delivery: null,
      attentionInformation: null,
      documentNumber:null,
      documentDate: new Date(),
      accountingDate: new Date(),
      deliveryDate: new Date(),
      requesterList: [],
      employeeList: [],
      requester: this.props.userData?.employee_id,
      submitterList: [],
      submitter: this.props.userData?.employee_id,
      onBehalfRequest: false,
      email: null,
      externalReference: null,
      currencyList: [],
      currency: null,
      entitylist: [],
      entity: null,
      siteList: [],
      site: null,
      warehouseList: [],
      warehouse: null,
      requisitionStatus: null,
      documentStatus: null,
      distributionSourceId: null,
      sources: [], 
      total:{},
      settings:{},
      teams:[],
      workflow_action_history: [],
      sme:'',
      sme_team:'',
      material_service_not_included: null,
      scope_of_work: null,
      requirement_description: null,
      prev_material_service_not_included: null,
      prev_scope_of_work: null,
      siteAdditionalParams:{},
      warehouseAdditionalParams:{},
      prev_requirement_description: null,
    }

    this.entitiesDataRef = React.createRef();
    this.sitesDataRef = React.createRef();
    this.warehouseDataRef= React.createRef();
 
    this.cancelTokenSource = axios.CancelToken.source();
    this.handleNextClick = this.handleNextClick.bind(this)
    this.onCreateFirstDistribution = this.onCreateFirstDistribution.bind(this)
    this.handleBackBtn = this.handleBackBtn.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.getSearchParam = this.getSearchParam.bind(this)
    this.handleSaveBtn = this.handleSaveBtn.bind(this)
    this.getTotal = this.getTotal.bind(this)
    this.getProcurementSetting = this.getProcurementSetting.bind(this)
    this.onSegmentDistributionPopupClose = this.onSegmentDistributionPopupClose.bind(this)
    this.validationConfigure()
  }
  componentDidMount() {
    this.getAllSources()
    this.getCartItems()
    this.getAllEmployees()
    this.getCurrencies()
    this.getEntities()
    this.getWishlistCount()
    this.getRequisitionStatusList()
    this.getDocumentStatusList()
    this.getExistingHeaderData()
  }

  componentDidUpdate(prevProps, prevState) {
    // set requester submitter if not set on page load
    if (
      ((JSON.stringify(prevProps.userData) !==
        JSON.stringify(this.props.userData) ||
        JSON.stringify(prevState.employeeList) !==
          JSON.stringify(this.state.employeeList)) &&
        this.state.employeeList?.length > 0) ||
      (JSON.stringify(prevState.currencyList) !==
        JSON.stringify(this.state.currencyList) &&
        this.state.currencyList?.length > 0)
    ) {
      if (
        Object.keys(this.state.requesterList).length === 0 &&
        Object.keys(this.state.submitterList).length === 0
      ) {
        const customEmployeeList = this.state.employeeList?.filter(
          (employee) =>
            employee.employee_id === this.props.userData?.employee_id
        )
        this.setState({
          requesterList: customEmployeeList,
          submitterList: customEmployeeList,
        })
      }
      if (!this.state.requester) {
        this.setState({
          requester: this.props.userData?.employee_id,
        })
      }
      if (!this.state.submitter) {
        this.setState({
          submitter: this.props.userData?.employee_id,
        })
      }
      if (!this.state.currency) {
        this.setState({
          currency: this.props.userData?.default_currency_id,
        })
      }
      if (!this.state.entity && !this.state.site && !this.state.warehouse) {
        this.setState({
          // entity: this.props.userData?.Employee?.Entity_id,
          site: this.props.userData?.Employee?.Site_id,
          warehouse: this.props.userData?.Employee?.Warehouse_id,
        })
      }
    }

    // handle entity site warehouse change
    if (
      this.state.entity &&
      this.state.site &&
      this.state.warehouse &&
      !this.state.initialDataLoaded &&
      this.state.initialDataUnvailable
    ) {
      this.getSites(this.state.entity)
      this.getWarehouses(this.state.site)
      this.setState({ initialDataLoaded: true })
      return
    }
    if (
      JSON.stringify(prevProps.prCart) !== JSON.stringify(this.props.prCart)
    ) {
      this.getExistingHeaderData()
    }
  }

  getAllSources() {
    let api = Api
    let that = this
    api.setUserToken()
    api
      .axios()
      .get(Settings.loginUrl + '/source')
      .then(function (res) {
        if (res.data.status === 'success') {
          that.setState({ isLoading: false, sources: res.data?.data })
        }
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, 'error')
      })
  }

  getCartItems() {
    const prHeaderId = this.getSearchParam('pr_view_id')
    let api = Api
    let that = this
    this.setState({ isLoading: true })
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + `/lines/${prHeaderId}/${Settings.source_id.purchase_requisition}`)
      .then(function (res) {
        if (that.props?.checkoutLineIds) {
          const checkoutIds = []
          res.data?.data.forEach((item) => {
            checkoutIds.push(item.line_id)
          })
          that.props.updateCheckoutIds(checkoutIds)
        }
        that.setState({
          isLoading: false,
          cartItems: [...res.data.data],
        })
      })
      .catch((error) => {
        this.setState({ isLoading: false })
        getPopupMessageBasedOnStatus(error)
      })
  }

  getAllEmployees() {
    let api = Api
    let that = this
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiOrgUrl + '/employee')
      .then(function (res) {
        if (res.data.status === 'success') {
          that.setState({ employeeList: res.data?.data })
        }
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error)
      })
  }

  getCurrencies() {
    let api = Api
    let that = this
    api.setUserToken()
    api
      .axios()
      .get(Settings.loginUrl + '/currency')
      .then(function (res) {
        if (res.data.status === 'success') {
          that.setState({ currencyList: res.data?.data })
        }
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error)
      })
  }

  getEntities() {
    let api = Api
    let that = this
    api.setUserToken()
    api
      .axios()
      .get(Settings.loginUrl + '/get_entities')
      .then(function (res) {
        if (res.data.status === 'success') {
          that.setState({ entitylist: res.data?.data })
        }
      })
      .catch((error) => {
       getPopupMessageBasedOnStatus(error)
      })
  }

  getSites(id) {
    let api = Api
    api.setUserToken()
    let that = this

    api
      .axios()
      .get(Settings.loginUrl + '/get_site')
      .then(function (res) {
        if (res.data.status === 'success') {
          that.setState({ siteList: res.data?.data })
        }
      })
      .catch((error) => {
       getPopupMessageBasedOnStatus(error)
      })
  }

  getWarehouses(id) {
    let api = Api
    let that = this
    api.setUserToken()

    api
      .axios()
      .get(Settings.loginUrl + '/get_warehouse')
      .then(function (res) {
        if (res.data.status === 'success') {
          that.setState({ warehouseList: res.data?.data })
        }
      })
      .catch((error) => {
       getPopupMessageBasedOnStatus(error)
      })
  }

  getRequisitionStatusList() {
    let api = Api
    let that = this
    api.setUserToken()
    api
      .axios()
      .get(Settings.loginUrl + '/enum/pr_requisition_status')
      .then(function (res) {
        if (res.data.status === 'success') {
          that.setState({
            requisitionStatusList: res.data?.data,
          })
        }
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error)
      })
  }

  getDocumentStatusList() {
    let api = Api
    let that = this
    api.setUserToken()
    api
      .axios()
      .get(Settings.loginUrl + '/enum/POHeaderDocumentStatus')
      .then(function (res) {
        if (res.data.status === 'success') {
          that.setState({
            documentStatusList: res.data?.data,
          })
        }
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error)
      })
  }

  getSearchParam = (name) => {
    const params = new URLSearchParams(this.props.rs_router.location?.search)
    return params.get(name)
  }

  setStateHandlingCanvas(value){
    this.setState({ showCanvas: value });
  }

  setStateHandlingCurrentAssignee(value){
    this.setState({ showCurrentAssignees: value });
  }

  getWishlistCount() {
    let api = Api
    api.setUserToken()
    let that = this
    api
      .axios()
      .get(Settings.apiPurchaseRequisitionUrl + '/wishlist')
      .then(function (res) {
        that.props.changeWishlistCount(res.data.data?.length)
        that.props.updateWishlistIds([...res.data.data])
      })
  }

  async getTotal(integratorId){
    let api = Api
    api.setUserToken()
    try{
      let total = await api.axios().get(Settings.apiPurchaseOrderUrl+`/total/${Settings.source_id.purchase_requisition}/${integratorId}`)
        this.setState({
            total:total.data.data
        })
    }catch(error){

    }
  }

  // Settings.apiProcurementSettingsUrl + `/procurementsetting_by_entity/${entity_id}`

  async getProcurementSetting(entity_id){
    let api = Api
    api.setUserToken()
    try{
      let settingsData = await api.axios().get(Settings.apiProcurementSettingsUrl + `/procurementsetting_by_entity/${entity_id}`)
        if(settingsData?.data?.data?.is_sme_done_by_team_in_pr){
          let teamsData =  await api.axios().get(Settings.apiUrl + `/teamheader?sme_team=true`)
          this.setState({
            teams:teamsData.data.data,
          })
        }
        this.setState({
            settings:settingsData.data.data
        })
    }catch(error){

    }
  }

  validationConfigure(){
    const language = this.props.language;
    let fieldConfig = [
        {name:'email_address',displayName:Helper.getLabel(language,'email','Email'),types:['Email'],max:80 },
        {name:'description',displayName:Helper.getLabel(language,'description','Description'),types:['Required'] },
        {name:'document_date',displayName:Helper.getLabel(language,'document_date','Document date'),types:['Required'] },
        {name:'accounting_date',displayName:Helper.getLabel(language,'accounting_date','Accounting date'),types:['Required'] },
        {name:'requester_id',displayName:Helper.getLabel(language,'requester','Requester'),types:['Required'] },
        {name:'submitter_id',displayName:Helper.getLabel(language,'submitter','Submitter'),types:['Required'] },
        {name:'currency_id',displayName:Helper.getLabel(language,'currency','Currency'),types:['Required'] },
        {name:'entity_id',displayName:Helper.getLabel(language,'entity','Entity'),types:['Required'] },
        {name:'site_id',displayName:Helper.getLabel(language,'site','Site'),types:['Required'] },
        {name:'warehouse_id',displayName:Helper.getLabel(language,'warehouse','Warehouse'),types:['Required'] },
    ]
    this.validator = new FormValidator(fieldConfig,language);
  }

  async getWorkFlowCalls() {  
    const workflowCalls = new WorkFlowCommonHeaderAPICalls(); 
    const workflowActionHistory = await workflowCalls.getWorkFlowActionHistory(Settings.source_id.purchase_requisition, this.getSearchParam('pr_view_id'));
    
    if(workflowActionHistory) this.setState({ workflow_action_history: workflowActionHistory });
  }

  async getExistingHeaderData() {
    const prHeaderId = this.getSearchParam('pr_view_id')
    let api = Api
    api.setUserToken()
    const {submitter} = this.state
    // fetch existing header data of PR id exists
    if (prHeaderId) {
      this.setState({ purchaseRequisitionId: prHeaderId })
      await this.getTotal(prHeaderId)
      try {
        const headerDataRes = await api
          .axios()
          .get(
            Settings.apiPurchaseRequisitionUrl + '/pr_header/' + prHeaderId
          )
        if (headerDataRes) {
          const data = headerDataRes.data?.data
          this.getProcurementSetting(data?.entity_id)
          this.setState(
            {
              description: data.description,
              delivery: data.delivery_name,
              attentionInformation: data.attention_information,
              documentDate: data.document_date,
              documentNumber: data.document_number,
              // documentDate: DatepickerFunctions.convertDateTimeForDataBase(
              //   data.accounting_date
              // ),
              documentStatus:data.document_status,
              accountingDate: data.accounting_date,
              deliveryDate: data.delivery_date,
              requester: data.requester_id,
              submitter: data.orderer_name,
              onBehalfRequest: data.on_behalf_request,
              email: data.email_address,
              externalReference: data.external_reference,
              currency: data.currency_id,
              entity: data.entity_id,
              site: data.site_id,
              warehouse: data.warehouse_id,
              distributionSourceId: data.distributionsource_id,
              material_service_not_included: data.material_service_not_included,
              scope_of_work: data.scope_of_work,
              requirement_description: data.requirement_description,
              prev_material_service_not_included: data.material_service_not_included,
              prev_scope_of_work: data.scope_of_work,
              prev_requirement_description: data.requirement_description,
              sme:data.sme,
              sme_team:data.sme_team,
              siteAdditionalParams:{entity_id:data.entity_id},
              warehouseAdditionalParams:{site_id:data.site_id},
            },
            () => {
              this.setState({ initialDataLoaded: true })
              this.getSites(data.entity_id)
            }
          )
          this.props.setPRCartInfo(data)
        }
      } catch (error) {
        this.setState({ isCreatingHeader: false })
        getPopupMessageBasedOnStatus(error)
      }
    } else {
      this.setState({ initialDataUnvailable: true })
    }
    this.getWorkFlowCalls();
  }

  async onCreateFirstDistribution(source_id) {
    let api = Api
    api.setUserToken()
    try {
      const res = await api
        .axios()
        .put(
          Settings.apiPurchaseRequisitionUrl +
            '/pr_header/' +
            this.getSearchParam('pr_view_id'),
          { distributionsource_id: source_id }
        )
      if (res) {
        this.setState({ distributionSourceId: source_id })
      }
    } catch (error) {
       getPopupMessageBasedOnStatus(error)
    }
  }

  async onSegmentUpdateOnLine(){
    let api = Api
    api.setUserToken()
    try {
      const res = await api
        .axios()
        .put(
          Settings.apiPurchaseRequisitionUrl +
            '/pr_header/' +
            this.getSearchParam('pr_view_id'),
          {is_distribution_updated_on_lines: true }
        )
    } catch (error) {
       getPopupMessageBasedOnStatus(error)
    }
  }

  async onSegmentDistributionPopupClose(allSegments){
    if(allSegments?.length > 0){
      Helper.confirmMe(
        () => {this.onSegmentUpdateOnLine()},
        Helper.getLabel(null,'do_you_want_to_update_on_line_also','Do you want to update segments on lines also?'),
        null,
        Helper.getLabel(
          null,'do_you_want_to_update_on_line_also','Do you want to update segments on lines also?'),
        470
      )
    }

  }

  handleBackBtn() {
    this.props.rs_router.navigate(-1)
  }

  async executeUpdateApiRequest(api, prHeaderData, deliveryDate) {
    const {material_service_not_included,scope_of_work,requirement_description} = this.state
    if(!this.validator.isValid(prHeaderData)){
      this.validator.displayMessage(this);
      return;
    } 
    try {
      const createHeaderResponse = await api
        .axios()
        .put(
          Settings.apiPurchaseRequisitionUrl +
            '/pr_header/' +
            this.state.purchaseRequisitionId,
          prHeaderData, 
          { cancelToken: this.cancelTokenSource.token }
        )
      if (createHeaderResponse) {
        this.setState({
          prev_material_service_not_included:material_service_not_included,
          prev_scope_of_work:scope_of_work,
          prev_requirement_description:requirement_description
        })

        let lastItemProcessed = true
        if (lastItemProcessed) { 
          return true
        }
      }
    } catch (error) {
      this.setState({ isCreatingHeader: false })
      getPopupMessageBasedOnStatus(error)
    }
  }
   
  async handleHeaderSubmission(is_update_cart_line) {
    const {
      delivery,
      attentionInformation,
      documentDate,
      accountingDate,
      deliveryDate,
    } = this.state

    let api = Api
    api.setUserToken()
    let prHeaderData = {
      description: this.state.description,
      requisition_status:
        this.state.requisitionStatus ?? this.state.requisitionStatusList[0]?.id,
      document_status:
        this.state.documentStatus ?? this.state.documentStatusList[0]?.id,
      accounting_date:
        DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(accountingDate)),
      document_date: DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(documentDate)),
      delivery_date: DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(deliveryDate)),
      deliveryaddress_id: null,
      delivery_name: delivery,
      attention_information: attentionInformation,
      priceinclude_tax: null,
      requester_id: this.state.requester,
      currency_id: parseInt(this.state.currency),
      email_address: this.state.email,
      entity_id: parseInt(this.state.entity),
      site_id: parseInt(this.state.site),
      warehouse_id: parseInt(this.state.warehouse),
      distributionsource_id: parseInt(this.state.distributionSourceId),
      externalreference: this.state.externalReference,
      attachmentsource_id: null,
      notesource_id: null,
      contactsource_id: null,
      convertPr_to: null,
      agreement_id: null,
      onbehalf_request: this.state.onBehalfRequest,
      material_service_not_included: this.state.material_service_not_included,
      scope_of_work: this.state.scope_of_work,
      requirement_description: this.state.requirement_description,
      submitter_id:this.state.submitter,
      is_updated_on_cartline:is_update_cart_line
    } 
    if(this?.state?.settings?.is_sme_done_by_team_in_pr){
      prHeaderData = {
        ...prHeaderData,
        sme_team:parseInt(this.state.sme_team),
      }
    }else{
      prHeaderData = {
        ...prHeaderData,
        sme:parseInt(this.state.sme),
      }
    }
    this.setState({ isCreatingHeader: true })
    return Helper.createDebouncedAPIFunction([
      async () => await this.executeUpdateApiRequest(api, prHeaderData, deliveryDate)
    ].map(func => func.bind(this)), this.setState.bind(this), this.cancelTokenSource, true)();
  }

  async handleNextClick(needNext=false) {
    const prHeaderId = this.getSearchParam('pr_view_id')
    if(this.state.material_service_not_included  !== this.state.prev_material_service_not_included || this.state.scope_of_work !== this.state.prev_scope_of_work || this.state.requirement_description !== this.state.prev_requirement_description){
    const confirmFunctionWrapper = async (is_updated_on_line) => {
      const createdHeader = await this.handleHeaderSubmission(is_updated_on_line)
      if(needNext){
        if (createdHeader[0][0]) {
          this.props.rs_router.navigate(`/purchase-requisition/cart?pr_view_id=${prHeaderId}`)
        }
      }
    }
    Helper.confirmMe(
      () => {confirmFunctionWrapper(true)},
      Helper.getLabel(null,'do_you_want_to_update_on_line_also','Do you want to update requirements on line also?'),
      () => {confirmFunctionWrapper(false)},
      Helper.getLabel(
        null,'do_you_want_to_update_on_line_also','Do you want to update requirements on line also?'),
      470
    )
   }else{
      const createdHeader = await this.handleHeaderSubmission(false)
      if(needNext){
        if (createdHeader[0][0]) {
          this.props.rs_router.navigate(`/purchase-requisition/cart?pr_view_id=${prHeaderId}`)
        }
      }
    }
  }

  handleSaveBtn(){
    this.handleNextClick(false)
  }

  addComponentRight() {
    return (
      <div className='parent-sidebar-cn'>
        <MasterSidebar disableSticky={true}>
          <OrderSummary
            title={Helper.getLabel(
              this.props.language,
              'requisition_summary',
              'Requisition summary'
            )}
            itemCount={this.props.checkoutLineIds.length}
            subtotal={this.state.total?.subtotal}
            discount={this.state.total?.line_discount}
            tax={this.state.total?.tax}
            total={this.state.total?.total}
            hideNextBtn={true}
            buttonText={false}
            isLoading={this.state.isCreatingHeader}
          />
          <NoteAttachmentHistory
            openCanvas={this.setStateHandlingCanvas.bind(this)}
            openCurrentAssignees={this.setStateHandlingCurrentAssignee.bind(this)}
            hideHistory={!this.state.workflow_action_history?.history?.length}
            historyArray={this.state.workflow_action_history}
            source_id={this.getSourceId('Purchase Requisition')}
            notesource_integrator={this.getSearchParam('pr_view_id')}
            attachmentsource_integrator={
              this.getSearchParam('pr_view_id')
            }
            hideVendorPortal={true}
          />
        </MasterSidebar>

        {this.state.showCanvas && 
          <WorkFlowOffCanvas
            isOpen={this.state.showCanvas}
            type={{ label: "Action History", value: 9 }}
            closeSidebar={this.setStateHandlingCanvas.bind(this)}
            source_id={this.getSourceId("Purchase Requisition")}
            source_item_id={this.getSearchParam("pr_view_id")}
          />
        }

        {this.state.showCurrentAssignees &&
          <WorkFlowOffCanvas
            isOpen={this.state.showCurrentAssignees}
            type={{ label: "View current assignment", value: 43 }}
            closeSidebar={this.setStateHandlingCurrentAssignee.bind(this)}
            source_id={this.getSourceId("Purchase Requisition")}
            source_item_id={this.getSearchParam("pr_view_id")}
          />
        }
      </div>
    )
  }

  getSourceId(name) {
    for (let i = 0; i < this.state.sources?.length; i++) {
      if (this.state.sources[i]?.source_name === name) {
        return this.state.sources[i]?.source_id
      }
    }
    return null
  }

  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  render() {
    let navs = [
      {
        title: Helper.getLabel(
          this.props.language,
          'general_information',
          'General Information'
        ),
        isActive: true,
      },
      { title: Helper.getLabel(this.props.language, 'segments', 'Segments') },
      {
        title: Helper.getLabel(
          this.props.language,
          'requirements',
          'Requirements'
        ),
      },
    ]
    return (
      <div  style={{position:'relative'}}>
      <MasterComonent>
        <div className='pr-wrapper'>
          <NavigationHeder
            title={this.props.title}
            backUrl={this.props.rs_router?.location?.state?.isBackSummary?-2:this.props.backUrl}
            hideMoreBtn={this.props.hideMoreBtn}
          >
            {this.props.hideMoreBtn ? (
              <NavCartWishlist
                disableNext={this.state.saveBtnApi}
                showSaveBtn={true}
                nextBtnHandler={this.handleNextClick}
                handleBackBtn={this.handleBackBtn}
                handleSaveBtn={this.handleSaveBtn}
                isContinueShopping={true}
                currentDocumentStatus={this.state.documentStatus}
                prId={this.getSearchParam('pr_view_id')}
              />
            ) : null}
          </NavigationHeder>
          <div className='container-fluid'>
            {this.state.isLoading || !this.state.initialDataLoaded ? (
              <SimpleLoading />
            ) : (
              <div id='summary_tabs_sections'>
                <Tabs
                  navs={navs}
                  showAll={true}
                  scrollOffset='300'
                  addComponentRight={this.addComponentRight.bind(this)}
                  disableSticky={true}
                >
                  <Collapse
                    title={Helper.getLabel(
                      this.props.language,
                      'general_information',
                      'General Information'
                    )}
                    open={true}
                    className="mb-5"
                  >
                    <HeaderForm
                      description={this.state.description}
                      documentNumber={this.state.documentNumber}
                      delivery={this.state.delivery}
                      attentionInformation={this.state.attentionInformation}
                      documentDate={this.state.documentDate}
                      accountingDate={this.state.accountingDate}
                      deliveryDate={this.state.deliveryDate}
                      requester={this.state.requester}
                      requesterList={this.state.requesterList}
                      submitter={this.state.submitter}
                      submitterList={this.state.submitterList}
                      employeeList={this.state.employeeList}
                      onBehalfRequest={this.state.onBehalfRequest}
                      email={this.state.email}
                      externalReference={this.state.externalReference}
                      currency={this.state.currency}
                      currencyList={this.state.currencyList}
                      entity={this.state.entity}
                      entitylist={this.state.entitylist}
                      site={this.state.site}
                      siteList={this.state.siteList}
                      warehouse={this.state.warehouse}
                      siteAdditionalParams={this.state.siteAdditionalParams}
                      warehouseAdditionalParams={this.state.warehouseAdditionalParams}
                      entitiesDataRef={this.entitiesDataRef}
                      sitesDataRef={this.sitesDataRef}
                      warehouseDataRef={this.warehouseDataRef}
                      warehouseList={this.state.warehouseList}
                      setDescription={(val) =>
                        this.setState({ description: val })
                      }
                      setDelivery={(val) => this.setState({ delivery: val })}
                      setAttentionInfo={(val) =>
                        this.setState({ attentionInformation: val })
                      }
                      setDocumentDate={(val) => {
                        this.setState({
                          documentDate: val,
                        })
                      }}
                      setAccountingDate={(val) => {
                        this.setState({
                          accountingDate: val,
                        })
                      }}
                      setDeliveryDate={(val) => {
                        this.setState({
                          deliveryDate: val,
                        })
                      }}
                      setRequester={(val) => this.setState({ requester: val })}
                      setOnBehalfRequest={() => {
                        this.setState(
                          {
                            onBehalfRequest: !this.state.onBehalfRequest,
                          },
                          () => {
                            if (this.state.onBehalfRequest)
                              this.setState({
                                requester: null,
                              })
                            else
                              this.setState({
                                requester: this.props.userData?.employee_id,
                              })
                          }
                        )
                      }}
                      setSubmitter={(val) => this.setState({ submitter: val })}
                      setEmail={(val) => this.setState({ email: val })}
                      setExternalRef={(val) =>
                        this.setState({ externalReference: val })
                      }
                      setCurrency={(val) => this.setState({ currency: val })}
                      setEntity={(val) =>
                        this.setState(
                          {
                            site: null,
                            warehouse: null,
                            entity: val,
                            siteAdditionalParams:{entity_id:val},
                            warehouseAdditionalParams:{entity_id:val}
                          },
                          () => {
                            if (this.state.entity === '') return
                            this.getSites(parseInt(val))
                          }
                        )
                      }
                      setSite={(val) =>
                      {
                        const siteData = this.sitesDataRef?.current?.find((item)=>item.site_id === parseInt(val))
                        this.setState(
                          { warehouse: siteData?.warehouse_id, site: val, entity:siteData?.entity_id,
                          siteAdditionalParams:{entity_id:siteData?.entity_id},
                          warehouseAdditionalParams:{site_id:val} },
                          () => {
                            this.getWarehouses(parseInt(val))
                          }
                        )
                      }
                      }
                      setWarehouse={(val) => {
                        const warehouseData = this.warehouseDataRef?.current?.find((item)=>item.warehouse_id === parseInt(val))
                        this.setState({ warehouse: val , siteAdditionalParams:{entity_id:warehouseData?.entity_id},warehouseAdditionalParams:{site_id:warehouseData?.site_id},site:warehouseData?.site_id,entity:warehouseData?.entity_id})
                      }}
                      isDisableAllField={this.state.saveBtnApi}
                      isSMEExist={this.state?.settings?.is_sme_done_by_team_in_pr}
                      teams={this.state.teams}
                      sme={this.state.settings?.is_sme_done_by_team_in_pr?this.state?.sme_team:this?.state?.sme}
                      setSMEExpert={(val) => this.state.settings?.is_sme_done_by_team_in_pr ? this.setState({ sme_team: val }):this.setState({ sme: val })}
                    />
                  </Collapse>
                  <Collapse
                    title={Helper.getLabel(
                      this.props.language,
                      'segments',
                      'Segments'
                    )}
                    className='mb-5'
                    open={true}
                  >
                    <SegmentsDistribution
                      source_id={this.getSourceId('Purchase Requisition') || 10}
                      totalAmount={100}
                      isEditable={!this.state.saveBtnApi}
                      integrator={this.getSearchParam('pr_view_id')}
                      distribution_source_id={this.state.distributionSourceId}
                      onCreateFirstDistribution={this.onCreateFirstDistribution}
                      disableFinancial={true}
                      onSegmentDistributionPopupClose={this.onSegmentDistributionPopupClose}
                      accountingDate={this.state.accountingDate}
                    />
                  </Collapse>
                  <Collapse
                    title={Helper.getLabel(
                      this.props.language,
                      'requirements',
                      'Requirements'
                    )}
                    className='mb-5'
                    open={true}
                  >
                    <div className='rfq-internal-requirements bidding_requirement'>
                      <RFQBiddingRequirement
                        type='rfq'
                        scopeOfWork={this.state?.scope_of_work}
                        materialsAndServicesNotIncluded={
                          this.state?.material_service_not_included
                        }
                        agreementTerms={this.state?.requirement_description}
                        disable={this.state.saveBtnApi}
                        onRequirementChange={this.handleInputChange}
                        onRequirementBlur={this.handleInputChange}
                      />
                    </div>
                  </Collapse>
                </Tabs>
              </div>
            )}
          </div>
        </div>
        {this.state.saveBtnApi ? <OverlayLoader isLogoCenter={true} /> : null}
      </MasterComonent>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
    userData: state.auth.user,
    checkoutLineIds: state.prShop.checkoutLineIds,
    prCart:state.prCart.prCartInfo
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCartCount: (payload) =>
      dispatch({ type: ActionTypes.CHANGE_CART_COUNT, payload }),
    changeWishlistCount: (payload) =>
      dispatch({ type: ActionTypes.CHANGE_WISHLIST_COUNT, payload }),
    updateWishlistIds: (payload) =>
      dispatch({ type: ActionTypes.UPDATE_WISHLIST_IDS, payload }),
    updateCheckoutIds: (payload) =>
      dispatch({ type: ActionTypes.UPDATE_CHECKOUT_LINE_IDS, payload }),
    setPRCartInfo:(prInfo) => { dispatch({type:ActionTypes.SET_PR_ID , payload:prInfo}) },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RsWithRouter(AddRequisitionDetails));
