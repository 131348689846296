import React, { Component } from 'react';
import PoVersion from '../../components/Purchase Orders Internal/PoVersion/PoVersion';
import Helper from '../../inc/Helper';

class ContractOrderVersionPage extends Component {
    render() {
        return (
            <PoVersion
                title = {Helper.getLabel(null,'contract_version','Contract version')}
                backUrl = {'/contract-order/edit'}
                source_id={14}
            />
        );
    }
}

export default ContractOrderVersionPage;