import React, { Component } from "react";
import { connect } from "react-redux";

import Helper from "../../../inc/Helper";
import ShoppingCartModal from "../modals/ShoppingCartModal";
import ImagePopupCarousel from "../modals/ImagePopupCarousel";
import Api from "../../../inc/Api";
import Settings from "../../../inc/Settings";
import SimpleLoading from "../../Loading/SimpleLoading";
import RsWithRouter from "../../inc/RsWithRouter";

import Rating from "../../../assets/rating.svg";
import EnlargeImage from "../../../assets/enlarge-image.svg";
import ImageNotFound from "../../../assets/image-not-found.jpg";
import OverlayLoader from "../common/OverlayLoader";

class ProductDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isAddingToCart: false,
      isFetchingImages: false,
      itemData: null,
      itemImages: [],
      activeImageIndex: 0,
      showImagePopupCarousel: false,
      quantity: 1,
      lineTypesList: [],
    };

    this.handleCloseImagePopupCarousel =
      this.handleCloseImagePopupCarousel.bind(this);
    this.handleShowImagePopupCarousel =
      this.handleShowImagePopupCarousel.bind(this);
    this.getSearchParam = this.getSearchParam.bind(this)
  }

  getSearchParam = (name) => {
    const params = new URLSearchParams(this.props.rs_router.location?.search)
    return params.get(name)
  }

  componentDidMount() {
    let pathname = this.props.rs_router.location.pathname;
    let idInAction = pathname.substr(pathname.lastIndexOf("/") + 1);
    this.getItemDetails(idInAction);
    this.getItemImages(idInAction);
    this.getLineTypesList();
  }

  getLineTypesList() {
    let api = Api;
    api.setUserToken();
    let that = this;
    api
      .axios()
      .get(Settings.loginUrl + `/enum/po_lineType`)
      .then(function (res) {
        if (res.data?.status === "success") {
          that.setState({
            lineTypesList: [...res.data.data],
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getItemDetails(id) {
    let api = Api;
    let that = this;
    api.setUserToken();
    this.setState({ isLoading: true });
    api
      .axios()
      .get(Settings.apiItemUrl + "/item/" + id)
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ isLoading: false, itemData: res.data.data });
        }
      })
      .catch((error) => {
        console.log(error);
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getItemImages(id) {
    let api = Api;
    let that = this;
    api.setUserToken();
    this.setState({ isFetchingImages: true });
    api
      .axios()
      .get(Settings.apiItemUrl + "/itemimage/" + id)
      .then(function (res) {
        if (res.data.status === "success") {
          let imagesArray = [];
          if (res.data?.data?.length > 4) {
            imagesArray = res.data?.data?.slice(0, 4);
          } else {
            imagesArray = res.data?.data;
          }
          that.setState({ isFetchingImages: false, itemImages: imagesArray });
        }
      })
      .catch((error) => {
        that.setState({ isFetchingImages: false });
      });
  }

  getPricingUnit(pricingArray, pricingType) {
    for (let i = 0; i < pricingArray?.length; i++) {
      if (pricingArray[i].pricing_type === pricingType) {
        return parseFloat(pricingArray[i].price);
      }
    }
    return null;
  }

  async addToCart(item) {
    let itemData = {
      line_type: this.getIdByName("Item"),
      item_id: item.item_id,
      header_id:this.getSearchParam('pr_view_id'),
      source_id:Settings.source_id.purchase_requisition,
      unit_price: this.getPricingUnit(item.prices, this.getIdByName("Item")),
      ordered_qty: this.state.quantity,
      line_amount:
        this.getPricingUnit(item.prices, this.getIdByName("Item")) *
        this.state.quantity,
      unit: item.purchaseUOM,
    };

    this.setState({ isAddingToCart: true });
    let api = Api;
    api.setUserToken();
    try {
      const cartItemsRes = api
        .axios()
        .get(Settings.apiPurchaseOrderUrl + `/lines/${this.getSearchParam('pr_view_id')}/${Settings.source_id.purchase_requisition}`);
      const cartItems = (await cartItemsRes).data?.data;

      let existingItem = null;
      if (cartItemsRes) {
        for (const cartItem of cartItems) {
          if (cartItem.item_id === item.item_id) {
            existingItem = cartItem;
            break;
          }
        }
        if (existingItem) {
          itemData.ordered_qty += parseFloat(existingItem.ordered_qty);
          itemData.line_amount += parseFloat(existingItem.line_amount);
        }

        const res = existingItem
          ? await api
              .axios()
              .put(
                Settings.apiPurchaseOrderUrl +
                  "/line/" +
                  existingItem.line_id + "/" + Settings.source_id.purchase_requisition,
                itemData
              )
          : await api
              .axios()
              .post(
                Settings.apiPurchaseOrderUrl + "/lines",
                itemData
              );

        this.setState({ isAddingToCart: false });
        if (res.data?.status === "success") {
          if (!existingItem) this.incrementCartCount();
          this.setState({ quantity: 1 });
          Helper.alert(res.data?.message, "success");
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ isAddingToCart: false });
      Helper.alert(error?.response?.data?.message, "error");
    }
  }

  getIdByName(name) {
    for (let i = 0; i < this.state.lineTypes?.length; i++) {
      if (this.props.lineTypes[i]?.name === name) {
        return this.props.lineTypes[i]?.id;
      }
    }
    return 0;
  }

  handleCloseImagePopupCarousel() {
    this.setState({ showImagePopupCarousel: false });
  }

  handleShowImagePopupCarousel() {
    this.setState({ showImagePopupCarousel: true });
  }

  incrementCartCount() {
    this.props.changeCartCount(this.props.cartCount + 1);
  }

  handleQuantityIncrement = () => {
    this.setState({ quantity: parseFloat(this.state.quantity) + 1 });
  };

  handleQuantityDecrement = () => {
    if (this.state.quantity > 1)
      this.setState({ quantity: parseFloat(this.state.quantity) - 1 });
  };

  render() {
    // const imageData = this.state.itemImages[this.state.activeImageIndex]?.image?.slice(2,-1)
    // const imageUrl = `data:image/${this.state.itemImages[this.state.activeImageIndex]?.image};base64,${imageData}`
    const imageUrl = this.state?.itemImages[this.state.activeImageIndex]?.image
    return (
        <div className="pr-wrapper">
          <div className="pr-mainbody-wrapper">
            {this.state.isLoading || this.state.isFetchingImages ? (
              <div style={{ margin: "20px auto" }}>
                <OverlayLoader isLogoCenter={true} />
              </div>
            ) : (
              <div className="pr-productdetail">
                <div className="pr-productdetail-gallery">
                  <div className="pr-productdetail-gallery-main">
                    <img
                      className="pr-productdetail-gallery-main-mainimage"
                      src={
                        this.state.itemImages?.length > 0
                          ? `${
                              imageUrl 
                            }`
                          : ImageNotFound
                      }
                      alt="gallery-main"
                    />
                    {this.state.itemImages?.length > 0 ? (
                      <img
                        className="pr-productdetail-gallery-main-enlarge"
                        src={EnlargeImage}
                        alt="enlarge"
                        onClick={this.handleShowImagePopupCarousel}
                      />
                    ) : null}
                  </div>
                  <div className="pr-productdetail-gallery-thumbnails">
                    {this.state.itemImages.map((itemImage, index) => {
                      // const imageData = itemImage?.image?.slice(2,-1)
                      // const imageUrl = `data:image/${itemImage?.image};base64,${imageData}`
                      const imageUrl = itemImage?.image
                       return <img
                        key={itemImage.image_id}
                        src={itemImage?.image !== null ? imageUrl : ImageNotFound}
                        alt={itemImage.image_name}
                        onClick={() => {
                          this.setState({ activeImageIndex: index });
                        }}
                        role="button"
                      />
                    })}
                  </div>
                </div>
                <div className="pr-productdetail-content">
                  <h4>{this.state.itemData?.name}</h4>
                  {this.props?.hidePrice ? <></> : <div className="pr-productdetail-content-ratingprice">
                    <span className="pr-productdetail-content-ratingprice-rating">
                      <img src={Rating} alt="rating" />{" "}
                      {this.state.itemData?.rating ?? "-"}
                    </span>
                    <span className="pr-productdetail-content-ratingprice-price">
                      AED{" "}
                      {Helper.addThousandSeparator(
                        this.getPricingUnit(
                          this.state.itemData?.prices,
                          this.getIdByName("Item")
                        )
                      ) ?? "-"}
                    </span>
                  </div>}
                  <div className="pr-productdetail-content-spec">
                    <label>
                      {Helper.getLabel(
                        this.props.language,
                        "display_name",
                        "Display Name"
                      )}
                    </label>
                    <span>{this.state.itemData?.display_name ?? "-"}</span>
                  </div>
                  <div className="pr-productdetail-content-spec">
                    <label>
                      {Helper.getLabel(this.props.language, "sku", "SKU")}
                    </label>
                    <span>{this.state.itemData?.purchaseUoM_name ?? "-"}</span>
                  </div>
                  <div className="pr-productdetail-content-spec">
                    <label>
                      {Helper.getLabel(this.props.language, "brand", "Brand")}
                    </label>
                    <span>{this.state.itemData?.itembrand_name ?? "-"}</span>
                  </div>
                  <div className="pr-productdetail-content-spec">
                    <label>
                      {Helper.getLabel(this.props.language, "style", "Style")}
                    </label>
                    <span>{this.state.itemData?.style_name ?? "-"}</span>
                  </div>
                  <div className="pr-productdetail-content-spec">
                    <label>
                      {Helper.getLabel(this.props.language, "color", "Color")}
                    </label>
                    <span>{this.state.itemData?.color_name ?? "-"}</span>
                  </div>
                  <div className="pr-productdetail-content-spec">
                    <label>
                      {Helper.getLabel(this.props.language, "size", "Size")}
                    </label>
                    <span>{this.state.itemData?.size_name ?? "-"}</span>
                  </div>
                  <div className="pr-productdetail-content-spec">
                    <label>
                      {Helper.getLabel(
                        this.props.language,
                        "configuration",
                        "Configuration"
                      )}
                    </label>
                    <span>
                      {this.state.itemData?.configuration_name ?? "-"}
                    </span>
                  </div>
                  <div className="pr-productdetail-content-spec">
                    <label>
                      {Helper.getLabel(
                        this.props.language,
                        "dimensions",
                        "Dimensions"
                      )}
                    </label>
                    <span>{this.state.itemData?.dimension_name ?? "-"}</span>
                  </div>
                  <div className="pr-productdetail-content-spec pr-productdetail-content-desc">
                    <label>Description</label>
                    <p>{this.state.itemData?.description ?? "-"}</p>
                  </div>
                  <div className="pr-productdetail-content-spec pr-productdetail-content-quantity">
                    <label>
                      {Helper.getLabel(
                        this.props.language,
                        "quantity",
                        "Quantity"
                      )}
                    </label>
                    <div>
                      <span
                        className="pr-productdetail-content-quantity-button"
                        onClick={(event) => {
                          event.stopPropagation();
                          this.handleQuantityDecrement();
                        }}
                      >
                        -
                      </span>{" "}
                      <input
                        type="number"
                        value={this.state.quantity}
                        onChange={(e) => this.handleQuantityChange(e)}
                      />{" "}
                      <span
                        className="pr-productdetail-content-quantity-button"
                        onClick={(event) => {
                          event.stopPropagation();
                          this.handleQuantityIncrement();
                        }}
                      >
                        +
                      </span>
                    </div>
                  </div>
                  {this.state.isAddingToCart ? (
                    <OverlayLoader isLogoCenter={true} />
                  ) : (
                    <button
                      className="pr-productdetail-content-btn-outlined"
                      onClick={() => this.addToCart(this.state.itemData)}
                    >
                      {Helper.getLabel(
                        this.props.language,
                        "add_to_cart",
                        "Add to Cart"
                      )}
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
          <ShoppingCartModal
            visibility={this.state.showShoppingCart}
            hide={this.handleCloseShoppingCart}
          />
          <ImagePopupCarousel
            show={this.state.showImagePopupCarousel}
            hide={this.handleCloseImagePopupCarousel}
          >
            {this.state.itemImages.map((itemImage, index) => {
              // const imageData = itemImage?.image?.slice(2,-1)
              // const imageUrl = `data:image/${itemImage?.image};base64,${imageData}`
              const imageUrl = itemImage?.image
              return (
                <img
                  key={itemImage.image_id}
                  src={imageUrl ?? ImageNotFound}
                  alt={itemImage.image_name}
                />
              );
            })}
          </ImagePopupCarousel>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    wishlistCount: state.prShop.wishlistCount,
    cartCount: state.prShop.cartCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCartCount: (payload) =>
      dispatch({ type: "CHANGE_CART_COUNT", payload }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RsWithRouter(ProductDetail));
