import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import Popup from '../../Popup/Popup'; 
import Button from '../../inc/Button';
import axios from 'axios';
import Helper from '../../../inc/Helper';
import $ from 'jquery'; 
import TenantFields from '../Tenant Fields/TenantFields';
import FormValidator from '../../../inc/FormValidator'; 
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import Api from '../../../inc/Api';
import DatepickerFunctions from '../../../inc/DatepickerHelper';
import Settings from '../../../inc/Settings';

let cancelTokenSource = axios.CancelToken.source();
let validator;
const TenantFormPopup = ({setShowCreateTenantModal, reloadGrid, language, auth}) => {
    const [tenantFormData, setTenantFormData] = useState({
        isactive: false,
        enable: false,
    });

    const [apiCallBtn, setApiCallBtn] = useState(false);

    useEffect(() => { validationConfigure() }, []);

    const validationConfigure = () => {
        let fieldConfig = [
            { name: 'code', displayName: Helper.getLabel(language, 'code', 'Code'), types: ['Required'], max: 10 },
            { name: 'name', displayName: Helper.getLabel(language, 'name', 'Name'), types: ['Required'], max: 100 },
            { name: 'start_date', displayName: Helper.getLabel(language, 'start_date', 'Start date'), types: ['Required'] },
            { name: 'end_date', displayName: Helper.getLabel(language, 'end_date', 'End date'), types: ['Required'] },
            { name: 'first_name', displayName: Helper.getLabel(language, 'first_name', 'First name'), types: ['Required'] },
            { name: 'user_email', displayName: Helper.getLabel(language, 'user_email', 'Email'), types: ['Required', 'Email'] },
            { name: 'user_type', displayName: Helper.getLabel(language, 'user_type', 'User type'), types: ['Required'] },
            { name: 'isactive', displayName: Helper.getLabel(language, 'active', 'Active'), types: ['Required'] }
        ];

        validator = new FormValidator(fieldConfig, language);
    };

    const executePOSTApiOfTenant = async (formData) => {
        let api = Api;
        api.setUserToken();
    
        let tenantData = {
            code: formData.code,
            name: formData.name,
            isactive: formData.isactive === "true" ? true : false,
            start_date: DatepickerFunctions.convertDateForDataBase(formData?.start_date),
            end_date: DatepickerFunctions.convertDateForDataBase(formData?.end_date),
            first_name: formData.first_name,
            last_name: formData.last_name,
            middle_name: formData.middle_name,
            user_email: formData.user_email,
            enable: formData.isactive === "true" ? true : false,
            is_deleted: false,
            default_login_entity: formData.default_login_entity,
            SID: formData.SID,
            language_id: 1,
            domain_code: formData.domain_code,
            usertype: formData.user_type,
            createdby: auth?.user?.user_id,
            modifiedby: auth?.user?.user_id,
            address: null,
            contact: null,
        };
    
        try {
            const res = await api.axios().post(Settings.apiUrl + '/add_tenant', tenantData, { cancelToken: cancelTokenSource?.token });
            if (res?.data?.Status === 'Success') {
                Helper.alert(res?.data?.Message); 
                setShowCreateTenantModal(false);
                reloadGrid(); 
            }
        } catch (err) {
            getPopupMessageBasedOnStatus(err);
        }
    };

    const handleSubmit = () => {
        let formData = tenantFormData;
    
        let validData = { ...formData };
    
        if (!validator.isValid(validData)) {
            validator.displayMessage();
            return;
        };

        Helper.createDebouncedAPIFunction( 
            [() => executePOSTApiOfTenant(formData)], 
            setApiCallBtn,
            cancelTokenSource
        )();
    };

    return (
        <Popup
            autoOpen={true} 
            width="900px"
            onClose={() => {
                if(apiCallBtn) return;

                $("html").removeClass("rs_popup_open");
                setShowCreateTenantModal(false);
            }}
        >
            <div className='mb-4'>
                <h5 className="create_rfq_modal__title mb-4">Create Tenant</h5>
                <TenantFields validator={validator} data={tenantFormData ? tenantFormData : {}} setFormData={setTenantFormData} />
            </div> 

            <div className="addDocumentNumber-body-buttons" style={{ justifyContent: "flex-end" }}>
                <Button
                    isActive={false} isDisable={apiCallBtn} title={Helper.getLabel(language, "cancel", "Cancel")}
                    onClick={() => {
                        $("html").removeClass("rs_popup_open");
                        setShowCreateTenantModal(false);
                    }}
                />
                <Button
                    isDisable={apiCallBtn}
                    className="solid_btn" isActive={true}
                    title= {<div><span> {Helper.getLabel(null, "create", "Create")} </span> {apiCallBtn ? <output className="spinner-border spinner-border-sm ms-1" aria-hidden="true"></output> : ''}</div>}
                    onClick={handleSubmit}
                />
            </div>
        </Popup>
    )
}

const mapStateToProps = (state) => {
    return {
        appOptions: state.options,
        auth: state.auth,
        language: state.language,
    };
};

export default connect(mapStateToProps) (TenantFormPopup);