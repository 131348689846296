import React from "react"; 
import TermConditionsTemplates from "../../../External Screens/Request For Quotation/Terms Conditions/TermConditionsTemplates"; 
import Settings from "../../../../inc/Settings";
 
const ContractTerms = ({ transactional_document_type, sourceintegration_id, disableComponent }) => { 
  return (
    <TermConditionsTemplates transactional_document_type={transactional_document_type} disableComponent={disableComponent} TransactionalLevel={true} sourceintegration_id={sourceintegration_id}  module_source_id={Settings.source_id.request_for_quotation} />
  );
};

export default ContractTerms;