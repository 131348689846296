import React, { Component } from 'react';
import PoVersion from '../../components/Purchase Orders Internal/PoVersion/PoVersion';
import Helper from '../../inc/Helper';

class PurchaseAgreementVersionPage extends Component {
    render() {
        return (
            <PoVersion
                title = {Helper.getLabel(null,'purchase_agreement_version','purchase agreement version')}
                backUrl = {'/purchase-agreement/edit'}
            />
        );
    }
}

export default PurchaseAgreementVersionPage;