import React, { Fragment, useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import Popup from '../../../Popup/Popup';
import SimpleLoading from '../../../Loading/SimpleLoading';
import Collapse from '../../../inc/Collapse';
import Button from '../../../inc/Button';
import Helper from '../../../../inc/Helper';
import Input from '../../../Forms/Input';
import DropDownInput from '../../../Forms/Dropdown';
import { useNavigate } from 'react-router-dom'; 
import CustomHtmlCell from '../../../grid/ag/cellRender/CustomHtmlCell';
import TermsHTMLDisplayPopup from './TermsHTMLDisplayPopup';
import { connect } from 'react-redux';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings';
import { checkArraysValues, checkIfArrayIsEmpty, getPopupMessageBasedOnStatus, getResponsiveWidthForPopup } from '../../../../inc/Validation';
import FormValidator from '../../../../inc/FormValidator';
import Dropdown from '../../../Forms/Dropdown';
import AgGridNew from '../../../grid/ag/ag-grid-new'; 
import { getDisplayNamesFromArray, getNewValue, getOptions, optionsKeyCreator } from '../../../grid/ag/commonFunctions/GridCommonFunctions';
import axios from 'axios';
  
let cancelTokenSource = axios.CancelToken.source();
const CommonTemplatesPopup = ({setClosePopup, transactional_document_type, defaultDocumentTypeArray, TransactionalLevel=false, editModeOfTemplate=false, module_source_id, sourceintegration_id, reloadParentGrid, documentTypes=[], nodeObj={}, importTemplate=false, language}) => {
    const navigate = useNavigate();

    const [templateHeadingsWithContent, setTemplateHeadingsWithContent] = useState([]);
    const [currentTemplate, setCurrentTemplate] = useState('');
    const [allTemplatesListings, setAllTemplatesListings] = useState([]);
    const [allTemplatesDropdown, setAllTemplatesDropdown] = useState([]);
    const [parentHeadings, setParentHeadings] = useState([]);

    const [loadingImportTemplate, setLoadingImportTemplate] = useState(true);
    const [loadingImportTemplateHeadingGrid, setLoadingImportTemplateHeadingGrid] = useState(false);
    const [detailViewContent, setDetailViewContent] = useState({
        openPopup: false,
        content: '', 
        content_otherlanguage: '', 
        heading_otherlanguage: '',
        title: null
    })

    const [saveBtnApi, setSaveBtnApi] = useState(false);

    const [templateHeadings, setTemplateHeadings] = useState(nodeObj);  

    let TemplateListingCalled = false;
    let validator; 
    
    const validationConfigure = () => {
        let fieldConfig = [ 
            { name:'title', displayName: 'Title' ,types:['Required'] }
        ]
        if(!validator) {
            validator = new FormValidator(fieldConfig, language)
        }
    };

    validationConfigure();

    useEffect(() => {
        getTemplatesListing()
    }, [])
  
    const getTemplatesListing = () => {
        if(TemplateListingCalled) return;
        TemplateListingCalled = true;

        setLoadingImportTemplate(true);
    
        let api = Api;
        api.setUserToken();
        api.axios()
            .get(Settings.apiUrl + `/tnc_template`, {})
            .then(function (res) {
                if (res.data.status === "success") { 
                    let templates_dropdown_data = [];
                    res?.data?.data?.forEach((item) => {
                        templates_dropdown_data.push({
                            value: item.tnctemplate_id,
                            label: item.title
                        })
                    }) 
                    setAllTemplatesListings(res.data.data);
                    setAllTemplatesDropdown(templates_dropdown_data);
                    setLoadingImportTemplate(false);
                }
            })
            .catch((res) => {
                setLoadingImportTemplate(false);
                getPopupMessageBasedOnStatus(res);
            }); 
    }

    const getTermsConditionsHeadings = async (template_id) => {
        if (!template_id) {
            setTemplateHeadingsWithContent([])
            return;
        };

        let apiURL = `/tnc_heading?template_id=${template_id}`; 
 
        setLoadingImportTemplateHeadingGrid(true);

        let api = Api;
        api.setUserToken();
 
        try {
            const res = await api.axios().get(Settings.apiUrl + apiURL, {});
            if (res.data.status === "success") {
                let Updated_dropdown_data = [];

                let promises = res.data.data.map(async (item) => {
                    try {
                        const headingsContent = await getTermsConditionsHeadingsContent(item?.templateheading_id);
                        return {
                            ...item,
                            content: headingsContent, 
                            index: parseInt(item?.index),
                        };
                    } catch (error) {
                        console.error("Error fetching heading content:", error);
                        return null; 
                    }
                });

                res?.data?.data?.forEach((item) => {
                    Updated_dropdown_data.push({
                        value: item.templateheading_id,
                        label: item.heading
                    })
                }) 

                let Updated_response = await Promise.all(promises); 
                setTemplateHeadingsWithContent(Updated_response.filter((item) => item !== null));
                setParentHeadings(Updated_dropdown_data);

                setLoadingImportTemplateHeadingGrid(false);
            }
        } catch (error) { 
            setLoadingImportTemplateHeadingGrid(false);
            console.log(error)
        }
    };

    const getTermsConditionsHeadingsContent = async (templateheading_id) => {
        if (!templateheading_id) return []; 
    
        let apiURL = `/tnc_content?templateheading_id=${templateheading_id}`; 
    
        let api = Api;
        api.setUserToken();
    
        try {
            const res = await api.axios().get(Settings.apiUrl + apiURL, {});
            if (res.data.status === "success") { 
                return res.data.data;
            }
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    // Component-Related Functions,
    const onTemplateSelectionChange = (evt) => { 
        let template_id = evt.target.value;

        getTermsConditionsHeadings(template_id);
        setCurrentTemplate(template_id); 
    };

    const onPopupCloseClickHandler = () => {
        $('.add_templates_popup .rs_popup_close').trigger('click');
        setClosePopup((prevState) => {
            return {
                ...prevState,
                openPopup: false
            }
        })
    }

    // POST/PUT Calls & Save Button Executions,
    const createNewTemplate = async (importTemplatePayload = {}) => {
        try {
            let apiPayload = !importTemplate ? {
                title: templateHeadings.title,
                description: templateHeadings.description,
                document_type: !TransactionalLevel ? templateHeadings.document_type : getTypeValue(transactional_document_type[0]),
            } : importTemplatePayload;
    
            let templateURL = `/tnc_template`;
    
            if (TransactionalLevel && (!module_source_id || !sourceintegration_id)) {
                Helper.alert('source_id & sourceintegration_id are required!', 'error');
                return;
            };
    
            if (TransactionalLevel) {
                templateURL += `?for_documents=true`;
    
                if (!importTemplate) {
                    apiPayload = {
                        ...apiPayload,
                        source_id: module_source_id,
                        sourceintegration_id: sourceintegration_id,
                    };
                };
            };
    
            let api = Api;
            api.setUserToken();

            const res = await api.axios().post(Settings.apiUrl + templateURL, apiPayload, { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert('Template added successfully');
                if (importTemplate) {
                    const headingIdArray = [];
                
                    for (const [index, item] of templateHeadingsWithContent.entries()) { 
                        if (item.parentheading_id === null) {
                            const headingRes = await executeAPICallForHeadings(res.data.template_id, item, null);
                
                            if (headingRes && headingRes.data.status === "success") { 
                                headingIdArray[index] = headingRes.data.heading_id;
                
                                for (const subItem of item.content || []) {
                                    await executeAPICallForContent(headingRes.data.heading_id, subItem);
                                }
                            }
                        } else { 
                            const parentHeadingIndex = parentHeadings.findIndex(
                                (parentHeading) => parentHeading.value === item.parentheading_id
                            );
                 
                            if (parentHeadingIndex !== -1) {
                                const parentHeadingId = headingIdArray[parentHeadingIndex];
                 
                                const headingRes = await executeAPICallForHeadings(
                                    res.data.template_id,
                                    item,
                                    parentHeadingId
                                );
                
                                if (headingRes && headingRes.data.status === "success") { 
                                    headingIdArray[index] = headingRes.data.heading_id;
                
                                    for (const subItem of item.content || []) {
                                        await executeAPICallForContent(headingRes.data.heading_id, subItem);
                                    }
                                }
                            }
                        }
                    }  
                }
                reloadParentGrid();
                onPopupCloseClickHandler();
            }
        } catch (res) {
            getPopupMessageBasedOnStatus(res);
            onPopupCloseClickHandler();
        }
    };
     
    const executeAPICallForHeadings = async (template_id, apiObject, newParentId) => {
        if (!template_id) return;
    
        try {
            let api = Api;
            api.setUserToken();
    
            const res = await api.axios().post(Settings.apiUrl + `/tnc_heading?for_documents=true`, {
                template_id: template_id,
                index: apiObject.index ? parseInt(apiObject.index) : null,
                index_otherlanguage: apiObject.index_otherlanguage ? apiObject.index_otherlanguage : null,
                heading: apiObject.heading ? apiObject.heading : null,
                heading_otherlanguage: apiObject.heading_otherlanguage ? apiObject.heading_otherlanguage : null,
                parentheading_id: apiObject.parentheading_id ? ( parentHeadings.find(val => val.value == apiObject.parentheading_id) ? newParentId : null ) : null
            });
    
            return res;
        } catch (res) {
            getPopupMessageBasedOnStatus(res);
        }
    };
    
    const executeAPICallForContent = async (heading_id, apiObject) => {
        if (!heading_id || !apiObject.content) return;
    
        try {
            let api = Api;
            api.setUserToken();
    
            const res = await api.axios().post(Settings.apiUrl + `/tnc_content?templateheading_id=${heading_id}&for_documents=true`, {
                templateheading_id: heading_id,
                content: apiObject.content ? apiObject.content : null,
                content_otherlanguage: apiObject.content_otherlanguage ? apiObject.content_otherlanguage : null
            });
    
            return res;
        } catch (res) {
            getPopupMessageBasedOnStatus(res);
        }
    };
 
    const updateDocumentType = async (updateDocumentTypes) => {
        if (!nodeObj.tnctemplate_id) return;
    
        let templateURL = `/tnc_documenttypeassociation/${nodeObj.tnctemplate_id}/<document_type>`;
    
        let api = Api;
        api.setUserToken();
    
        const updatePromises = updateDocumentTypes.map(async item => {
            let updatedURL = templateURL.replace('<document_type>', item);
            try {
                const res = await api.axios().put(Settings.apiUrl + updatedURL, {
                    content: null,
                    content_otherlanguage: null
                });
                if (res.data.status === "success") {
                    Helper.alert(`Document type was associated successfully!`);
                } else {
                    Helper.alert(`Document type couldn't be associated!`, 'error');
                }
            } catch (error) {
                Helper.alert(`Document type couldn't be associated!`, 'error');
            }
        });
    
        return Promise.all(updatePromises);
    };

    const deleteDocumentType = async (removeDocumentTypes) => {
        if (!nodeObj.tnctemplate_id) return;
    
        let templateURL = `/tnc_documenttypeassociation/${nodeObj.tnctemplate_id}/<document_type>`;
    
        let api = Api;
        api.setUserToken();

        const deletePromises = removeDocumentTypes.map(async item => {
            let updatedURL = templateURL.replace('<document_type>', item);
            try {
                const res = await api.axios().delete(Settings.apiUrl + updatedURL, {
                    content: null,
                    content_otherlanguage: null
                });
                if (res.data.status === "success") {
                    Helper.alert(`Document type was deassociated successfully!`);
                } else {
                    Helper.alert(`Document type couldn't be deassociated!`, 'error');
                }
            } catch (error) {
                Helper.alert(`Document type couldn't be deassociated!`, 'error');
            }
        });
    
        return Promise.all(deletePromises);
    };

    const processDocumentTypesAssociations = async () => {
        const removeAssociatedDocumentType = [];
        const addAssociationDocumentTypes = [];
 
        if(checkArraysValues(defaultDocumentTypeArray, getDocumentTypeValues())) {
            reloadParentGrid();
            onPopupCloseClickHandler();
            return;
        };

        defaultDocumentTypeArray?.forEach(id => {
            if (!templateHeadings?.document_type?.includes(id)) {
                removeAssociatedDocumentType.push(id);
            }
        });
    
        templateHeadings?.document_type?.forEach(id => {
            if (!defaultDocumentTypeArray?.includes(id)) {
                addAssociationDocumentTypes.push(id);
            }
        });
    
        if (!checkIfArrayIsEmpty(addAssociationDocumentTypes)) {
            await updateDocumentType(addAssociationDocumentTypes);
        }
        
        if (!checkIfArrayIsEmpty(removeAssociatedDocumentType)) {
            await deleteDocumentType(removeAssociatedDocumentType);
        }
    
        reloadParentGrid();
        onPopupCloseClickHandler();
    };
     
    const updateExistingTemplate = () => { 
        if(!nodeObj.tnctemplate_id) return;

        let templateURL = `/tnc_template/${nodeObj.tnctemplate_id}`;
        if(TransactionalLevel) templateURL+=`?for_documents=true`;

        let api = Api;
        api.setUserToken();

        api.axios().put(Settings.apiUrl+templateURL,{
            title: templateHeadings.title,
            description: templateHeadings.description 
        }).then(function(res){
            if(res.data.status==="success"){
                Helper.alert('Template updated successfully');
                if(!TransactionalLevel) {
                    processDocumentTypesAssociations();
                    return;
                }
                reloadParentGrid();
                onPopupCloseClickHandler();
            }
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res);
            onPopupCloseClickHandler();
        });
    };

    const onSaveClickHandler = () => {
        if(!importTemplate){
            let validData = { ...templateHeadings };
                
            if(!validator.isValid(validData)){
                validator.displayMessage();
                validator.reset() 
                return;
            };
 
            if(!TransactionalLevel && (checkIfArrayIsEmpty(templateHeadings.document_type) || !templateHeadings.document_type)){
                Helper.alert('Document type is required!', 'error');
                return
            }

            if(TransactionalLevel && (checkIfArrayIsEmpty(transactional_document_type) || !transactional_document_type)){
                Helper.alert('Document type is required!', 'error');
                return
            }
        };
 
        if(!importTemplate && !editModeOfTemplate) {
            Helper.createDebouncedAPIFunction(
                [createNewTemplate], 
                setSaveBtnApi, 
                cancelTokenSource
            )();  
        };
        if(!importTemplate && editModeOfTemplate) {
            updateExistingTemplate()  
        };

        if(importTemplate) {
            if(!currentTemplate) {
                Helper.alert('Please select a template, then try importing!', 'error');
                return;
            };

            if (!module_source_id || !sourceintegration_id) {
                Helper.alert('source_id & sourceintegration_id are required!', 'error');
                return;
            };

            const TemplateObjToImport = allTemplatesListings?.find(val => val.tnctemplate_id == currentTemplate);

            const apiPayload = {
                title: TemplateObjToImport?.title,
                description: TemplateObjToImport?.description,
                document_type: !TransactionalLevel ? getTypeValue(TemplateObjToImport?.document_type[0]) : getTypeValue(transactional_document_type[0]),
                source_id: module_source_id,
                sourceintegration_id: sourceintegration_id
            };
            
            Helper.createDebouncedAPIFunction([
                async () => await createNewTemplate(apiPayload)
            ], setSaveBtnApi, cancelTokenSource)();
        };  
    };

    // multi-select dropdown
    const getTypeValue = (label) => documentTypes?.find(type => type?.label === label)?.value;

    const getDocumentTypeValues = () => {
        if (!TransactionalLevel) {
            return templateHeadings?.document_type?.map(label => getTypeValue(label)) || 'null';
        } else {
            return transactional_document_type?.map(label => getTypeValue(label)) || 'null';
        }
    }; 

    // modules: Templates/Headings
    const TemplatesCreationPopupModule = () => {
        return (
          <Collapse open={true} title={`Template Information`}>
            <div className="row">
              <div className="col-lg-4">
                <Input
                  label={Helper.getLabel(language, "title", "Title")}
                  type="text"
                  required={true}
                  onChange={(evt) => {
                    setTemplateHeadings((prevState) => {
                      return {
                        ...prevState,
                        title: evt.target.value,
                      };
                    });
                  }}
                  value={templateHeadings.title}
                />
              </div>
              <div className="col-lg-4">
                <Input
                  label={Helper.getLabel(
                    language,
                    "description",
                    "Description"
                  )}
                  type="text"
                  onChange={(evt) => {
                    setTemplateHeadings((prevState) => {
                      return {
                        ...prevState,
                        description: evt.target.value,
                      };
                    });
                  }}
                  value={templateHeadings.description}
                />
              </div>
              <div className="col-lg-4">
                <Dropdown
                  label={Helper.getLabel(
                    language,
                    "document_type",
                    "Document type"
                  )}
                  placeHolder={`${Helper.getLabel(
                    language,
                    "select_document_type",
                    "Select document type"
                  )}`}
                  id="select-document-type"
                  select2Settings={{ multiple: true }} 
                  options={documentTypes}
                  isDisable={TransactionalLevel}
                  onChange={(e) => {
                    const selectedOptions = Array.from(
                      e.target.selectedOptions
                    ).map((option) => (option.value));
                    if (selectedOptions) {
                      setTemplateHeadings((prevState) => {
                        return {
                          ...prevState,
                          document_type: selectedOptions 
                        };
                      });
                    }
                  }}
                  value={getDocumentTypeValues()} 
                  required={true} 
                />
              </div>
            </div>
          </Collapse>
        );
    };
    
    const TemplatesImportingPopupModule = () => {
        return (
            <Collapse open={true} title={`Template`}>
                <div className='row'>
                    <div className='col-lg-4'>
                        <DropDownInput id='template_name' label={Helper.getLabel(language,'template_name',"Template name")} options={allTemplatesDropdown} onChange={onTemplateSelectionChange} value={currentTemplate} />
                    </div>
                </div>
            </Collapse>
        )
    };

    const TemplatesHeadingsPopupModule = () => {
        const gridLinkValueFormat = () => {
            return `<img className='img-fluid' src='/images/eye-content.svg' />`;
        }
 
        const TermsConditionHeadings = [
            { field: 'index', filter: 'agNumberColumnFilter', cellRenderer: 'agGroupCellRenderer', editable: false, headerName: Helper.getLabel(language,'index',"Index")  },
            { field: 'heading', filter: 'agTextColumnFilter', editable: false, headerName: Helper.getLabel(language,'heading',"Heading") },
            { 
                field: 'parentheading_id', 
                headerName: Helper.getLabel(language,'parent',"Parent"),
                editable: false,  
                cellRenderer: (params) => getDisplayNamesFromArray(params, parentHeadings, 'value', 'label'),
                filterParams: { 
                    values: (params) => getOptions(params, parentHeadings), 
                    keyCreator: (params) => optionsKeyCreator(params, 'value'), 
                    valueFormatter: params => params.value.label 
                }, 
                filter: 'agSetColumnFilter', 
                minWidth: 180
            }
        ];

        const TermsConditionsSubChildGridHeaderCols = [
            { field: 'content', filter: false, editable: false, headerName: Helper.getLabel(language,'content',"Content"), cellRenderer: CustomHtmlCell, valueFormatter: gridLinkValueFormat }
        ];

        let dataSource = {
            getRows: async function (params) {
                params?.success({
                    rowData: templateHeadingsWithContent?.map((item) => ({
                        ...item,
                        gridId: `${Date.now()}_${getNewValue()}`
                    })),
                    rowCount: templateHeadingsWithContent?.length
                })
            },
        };

        const onGridTemplatesReady = (params) => {
            if(params) {  
                setTimeout(()=>{ 
                    params?.api?.setGridOption('serverSideDatasource', dataSource);
                }, 200) 
            }
        }

        const onContentCellClicked = (event) => {
            if(event.colDef.field === 'content') {  
                setDetailViewContent((prevState) => {
                    return {
                        ...prevState,
                        openPopup: true, 
                        content: event?.data?.content,
                        heading_otherlanguage: event?.data?.heading_otherlanguage,
                        title: event?.data?.heading,
                        content_otherlanguage: event?.data?.content_otherlanguage
                    }
                })
            }
        };

        let masterSettings = {
            detailRowAutoHeight: true,
            masterDetail: true,
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: TermsConditionsSubChildGridHeaderCols,  
                    defaultColDef: { 
                        flex: 1,
                        sortable: true 
                    },  
                    rowSelection: 'single', 
                    onCellClicked: onContentCellClicked
                },
                getDetailRowData: function (params) { 
                    const updatedContent = params.data.content.map(item => { 
                        return {
                            ...item,
                            heading: params.data.heading,
                            heading_otherlanguage: params.data.heading_otherlanguage,
                            templateheading_id: params.data.templateheading_id
                        };
                    });
                    params.successCallback(updatedContent);
                },
                template: '<div style="padding: 10px 25px; box-sizing: border-box;">' + 
                '  <div ref="eDetailGrid"></div>' +
                '</div>',
            }
        };

        return (
            <Collapse open={true} title={`Headings`}> 
                <AgGridNew
                    isDisabledCheckbox={true}
                    onGridReady={onGridTemplatesReady}
                    columnDefs={TermsConditionHeadings} 
                    masterSettings={masterSettings}
                    hideAddBtn={true}
                    hideDeleteBtn={true}
                    height={500} 
                    pagination={false}  
                    gridId={'rfq_master_transactional_level_grid'}
                />
            </Collapse>
        )
    };

    const TopHeadLine = () => {
        return (
            <div className='row'>
                <div className='col-6 d-flex justify-content-start align-items-center'>
                    <h2 className='terms_condition_common_heading'>
                        { !importTemplate ? ( editModeOfTemplate ? 'Edit Template' : 'Add New Template' ) : 'Import Terms & Condition Template' }
                    </h2>
                </div>
                <div className='col-6 d-flex justify-content-end align-items-center'>
                    <Button isDisable={saveBtnApi} onClick={onSaveClickHandler} className='btn_blue_templates_saving' title={Helper.getLabel(language,`${!importTemplate ? 'save_close' : 'save'}`, `${!importTemplate ? 'Save & Close' : 'Save'}`)} />
                </div>
            </div>
        )
    };

    const NextButtonActionTrigger = () => {
        const NextButtonActionHandler = () => { 
            // add the template id here, when template created then add the id in url and navigate user
            onPopupCloseClickHandler()
            navigate('/add-headings-template/3');
        };

        return (
            <div className='row'>
                <div className='col-12 d-flex justify-content-end align-items-center'>
                    <Button onClick={NextButtonActionHandler} title={Helper.getLabel(language,'next', "Next") }  />
                </div>
            </div>
        )
    };

    const TemplateModuleWrapper = () => {
        return(
            <Fragment>  
                <div className='templates_creation_wrapper'>
                    { TemplatesCreationPopupModule() }
                </div>
                {/* <div className='templates_next_btn'>{ NextButtonActionTrigger() }</div> */}
            </Fragment>
        )
    };

    const ImportTemplateModuleWrapper = () => {
        return (
            <Fragment> 
                {loadingImportTemplate ? <SimpleLoading /> : 
                    <Fragment>
                        <div className='templates_import_wrapper mb-4'>{TemplatesImportingPopupModule()}</div>
                        <div className='templates_headings_wrapper'>{loadingImportTemplateHeadingGrid ? <SimpleLoading /> : TemplatesHeadingsPopupModule()}</div>
                    </Fragment>
                } 
            </Fragment>
        )
    };

    return (
        <div className='common_responsive_class_popup_component'> 
            <Popup className="add_templates_popup" autoOpen={true} onClose={onPopupCloseClickHandler}>
                <div className='top_head_line_wrapper'>{TopHeadLine()}</div>  
                
                {!importTemplate ? TemplateModuleWrapper() : ImportTemplateModuleWrapper()}
                {detailViewContent.openPopup && <TermsHTMLDisplayPopup templateTitle={detailViewContent.title} heading_otherlanguage={detailViewContent.heading_otherlanguage} content_otherlanguage={detailViewContent.content_otherlanguage} content={detailViewContent.content} ClosePopupView={setDetailViewContent} />}
            </Popup>
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        appOptions:state.options,
        auth:state.auth,
        language:state.language
    }
}

export default connect(mapStateToProps) ((CommonTemplatesPopup));