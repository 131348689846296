import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings'; 
import Collapse from '../../inc/Collapse'; 
import UserPopup from '../Master User Popup/UserPopup';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { getLabelFromDropdown, getNewValue, getOptions, gridLinkValueFormat, optionsKeyCreator } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import SimpleDropdown from '../../grid/ag/cellEditor/SimpleDropdown';

let gridApi = null;
const UsersEdit = ({language, id, disableFields}) => {
  const [masterUser, setMasterUser] = useState({
    usersPopup: false,
    selectedUsersDetails: null,
    vendorUsers: [], 
    editModeGridData: {},
    editMode: false
  });
  const [usersGridData, setUsersGridData] = useState({});
  const [languageId, setLanguage] = useState([]);
 
  useEffect(() => { UserLanguageList() }, []);
 
  let userLanguageListCalled = false
 
  const UserLanguageList = () => {
    if(userLanguageListCalled){
      return;
    }
    let api = Api
    userLanguageListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/language`,{}).then(function(res){
      if(res.data.status==="success"){  
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
          UPDATED_STATE.push({
            value: element.language_id,
            label: element.language
          })
        })
        setLanguage(UPDATED_STATE)
      }
    })
  }
 
  const onCellClickedUser = (event) => {
    if (event.colDef.field === 'user.user_name') {
      if(disableFields) return; 

      setUsersGridData({
        SID: event?.data?.user?.SID, 
        enable: event?.data?.user?.enable == true ? "yes" : "no",
        first_name: event?.data?.user?.first_name,
        language_id: event?.data?.user?.language_id,
        last_name: event?.data?.user?.last_name, 
        network_alias: event?.data?.user?.network_alias,
        network_domain: event?.data?.user?.network_domain,
        user_id: event?.data?.user?.user_id,
        user_name: event?.data?.user?.user_name,
        middle_name: event?.data?.user?.middle_name
      });

      setMasterUser((prevState) => {
        return {
          ...prevState,
          editMode: true,
          usersPopup: true
        }
      })
    }
  }

  const onUsersPopupClose = () => {
    setMasterUser((prevState) => {
      return {
        ...prevState,
        usersPopup: false
      }
    })
  }

  const onUsersPopupCloseHandler = () => {
    setMasterUser((prevState) => {
      return {
        ...prevState,
        editModeGridData: {},
        editMode: false,
        usersPopup: false
      }
    })
  }

  const ShowUsersPopup = () => {
    setMasterUser((prevState) => {
      return {
        ...prevState,
        usersPopup: true,
        editModeGridData: {}
      }
    })
    setUsersGridData({})
  }

  const DeleteUserOfVendor = useCallback(async (element) => { 
    let api = Api
    api.setUserToken();
    try {
      const res = await api
        .axios()
        .delete(Settings.apiUrl+`/user/${element?.data?.user?.user_id}`,{})
      const rowNode = gridApi?.api?.getRowNode(element?.data?.user?.user_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi?.api?.applyServerSideTransaction(transaction)
      }, 200)
      gridApi?.api?.refreshServerSide({ purge: true })
      gridApi?.api?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])

  /* Grid Column Headers */

  const gridUserCols = [
    { field:'user.user_name', customColId: 'user_name', filter: 'agTextColumnFilter', editable: false, minWidth: 340, headerName:'User name', cellRenderer: (params) => gridLinkValueFormat(null, params?.data?.user?.user_name) },
    { field:'user.first_name', customColId: 'user_first_name', filter: 'agTextColumnFilter', editable: false, minWidth: 310, headerName:'First name' },
    { field:'user.middle_name', customColId: 'user_middle_name', filter: 'agTextColumnFilter', editable: false, minWidth: 310, headerName:'Middle name' },
    { field:'user.last_name', customColId: 'user_last_name', filter: 'agTextColumnFilter', editable: false, minWidth: 310, headerName:'Last name' },  
    {
      field: 'user.language_id',
      customColId: 'user_language_id',
      headerName: 'Language',
      editable: false,
      cellEditor: SimpleDropdown,
      isAllowZero:true,
      minWidth:310,
      cellEditorParams: {
        values: languageId,
      },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, languageId),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label,
      }
    },
    {
      field:'user.enable', editable: false, headerName: 'Enable', 
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      customColId: 'user_enable',
      filter: 'agSetColumnFilter', filterParams: { 
        values: [true, false],
        keyCreator: (params) => params.value,
        valueFormatter: params => params.value ? 'true' : 'false'
      },
      minWidth: 310,
      valueGetter:(params)=>{
        if(!params?.data?.user?.enable || !params?.data?.user){
          return false
        }
        else {
          return params.data?.user?.enable
        }
      }
    }
  ];

  return (
    <Fragment> 
      <Collapse title={Helper.getLabel(language,'users','Users')} className="mb-4 users_tabs" open={true}>
        <div className='container-fluid user'>
          <AgGridNew
            apiUrl={id ? Settings.apiVendorUrl+`/vendoruser/${id}` : null}
            pagination={false}  
            hideAddBtn={true}
            hideDeleteBtn={true}
            onCellClicked={onCellClickedUser}
            onAddClick={ShowUsersPopup}
            columnDefs={gridUserCols}
            handleDeleteSelectedRows={DeleteUserOfVendor}
            onGridReady={(params) => gridApi = params} 
            deleteUniqueId={`element?.data?.user?.user_id`}
            gridId={'vendor-users-common'}
          /> 
        </div>
      </Collapse>
      {masterUser.usersPopup ? <UserPopup gridApi={gridApi} setUsersGridData={setUsersGridData} vendor_id={id} dataExportable={false} editable={masterUser.editMode} editData={usersGridData} setUsersGrid={setMasterUser} onPopupClose = {onUsersPopupClose} closeBtnClick = {onUsersPopupCloseHandler} /> : ""}
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

export default connect(mapStateToProps) (UsersEdit);