import React, { useState, useEffect } from 'react';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import SimpleLoading from '../../Loading/SimpleLoading';
import FileListItem from './FileListItem';

function FileUploaderList(props) {
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        loadFiles();
    }, [props.activeKey, props.attachmenttype_id]);

    const loadFiles = () => {
        const api = Api;
        api.setUserToken();
        const { attachmentsource_integrator, source_id, activeKey } = props;
        if(!attachmentsource_integrator) return
        setIsLoading(true);

        let apiUrl
        const type = activeKey === 'internal' ? 0 : 1;

        if (attachmentsource_integrator && source_id) {
            apiUrl = Settings.apiUrl + `/get_attachments/${source_id}/${attachmentsource_integrator}?attachment_for=${type}`;
        }

        api.axios()
            .get(apiUrl)
            .then((res) => {
                if (res.data.status === 'success') {
                    const fetchedFiles = res.data.data;
                    setFiles(fetchedFiles.reverse());
                    if (props.onFilesLoaded) {
                        props.onFilesLoaded(fetchedFiles);
                    }
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };
    const handleFileDeleted = (deletedAttachmentId) => {
        setFiles((prevFiles) => prevFiles.filter((file) => file.attachment_id !== deletedAttachmentId));
    };
    if (isLoading) {
        return <SimpleLoading />;
    }
    return (
        <div className="uploaded_files_list">
            {files.length !==0? <div className='app_navigation' style={{marginBottom:"0.5rem",borderBottom:"1px solid #AFAFAF"}}>
               <h5 className='app_nav_title'>Attachments</h5>
            </div>:null}
            {files?.length===0 ? <p>No Attachment Found</p>: files.map((item) => (
                <FileListItem file={item} key={item.attachment_id} condition={props.condition} onFileDeleted={handleFileDeleted} />
            ))}
        </div>
    );
};

export default FileUploaderList;