import React, { Component } from 'react';
import EditPO from '../../components/Purchase Orders Internal/Edit PO/EditPO';
import Helper from '../../inc/Helper';

class PoEditPage extends Component {
    render() {
        return (
            <EditPO
                title={Helper.getLabel(null,'edit_po',"Edit Purchase Order")}
                backUrl='/purchase-order/all'
                versionUrl='/purchase-order/version'
                historyUrl='/purchase-order/history'
                logsUrl='/purchase-order/logs'
                goodReceivingUrl='/purchase-order/good-receiving/notes'
                returnOrderUrl='/purchase-order/return-order/all'
                amendmentRequestUrl='/purchase-order/amendment-request/all'
                hideStartDate = {true}
                hideEndDate = {true}
                hideBillingRule = {true}
                source_id = {null}
            />
        );
    }
}

export default PoEditPage;