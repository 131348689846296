import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import Helper from '../../../inc/Helper';
import Popup from '../../Popup/Popup';
import Collapse from '../../inc/Collapse';
import FieldsRenderer from '../../Purchase Orders Internal/Edit PO/FieldsRenderer';
import { Form } from 'react-bootstrap';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import FormValidator from '../../../inc/FormValidator';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';
import axios from 'axios';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import Button from '../../inc/Button';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';

let cancelTokenSource = axios.CancelToken.source();
const BankAccountModal = ({language, setShowCreateModal}) => {
    const [formData, setFormData] = useState({});

    const [bankList, setBankList] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);

    const [apiCallBtn, setApiCallBtn] = useState(false);

    const navigate = useNavigate();

    let banksListCalled = false;
    let currencyListCalled = false; 

    useEffect(() => { 
        getBanks();
        getCurrencies();
    }, []);

    const getBanks = () => {
        if(banksListCalled) return;
        banksListCalled = true;

        let api = Api 
        api.setUserToken()
        api.axios().get(Settings.apiOrgUrl+`/bank`,{}).then(function(res){
            if(res.data.status==="success"){
                setBankList(res?.data?.data?.map((element) => {
                    return {
                        value: element.bank_id,
                        label: element.name
                    }
                })) 
            }
        })
    };

    const getCurrencies = () => {
        if(currencyListCalled) return;
        currencyListCalled = true;

        let api = Api 
        api.setUserToken()
        api.axios().get(Settings.apiUrl+`/currency`,{}).then(function(res){
            if(res.data.status==="success") {
                setCurrencyList(res?.data?.data?.map((element) => {
                    return {
                        value: element.currency_id,
                        label: `${element.currency_code} - ${element.currency_name}`
                    }
                })) 
            }
        })
    };

    let formValidator;   

    const validationConfigure = () => {
        let formFieldConfig = [ 
            { name:'account_code', displayName:Helper.getLabel(language,'account code','Account Code'), types:['Required'] },
            { name:'bank_id', displayName:Helper.getLabel(language,'bank','Bank'), types:['Required'] },  
            { name:'name', displayName:Helper.getLabel(language,'name','Name'), types:['Required'] },  
            { name:'account_number', displayName:Helper.getLabel(language,'account number','Account Number'), types:['Required'] },
            { name:'currency_id', displayName:Helper.getLabel(language,'currency','Currency'), types:['Required'] }
        ];

        if(!formValidator) {
            formValidator = new FormValidator(formFieldConfig, language)
        }; 
    };

    validationConfigure();

    const fieldsArrayBankAccountSetup = [ 
        { label: Helper.getLabel(language,'account_code',"Account code"), maxLength: 20, className: 'col-lg-4', inputType: "text", required: true, name: 'account_code', id: 'account_code' },
        { label: Helper.getLabel(language,'bank',"Bank"), placeHolder: 'Select bank', className: 'col-lg-4', inputType: "dropdown", options: bankList, required: true , name:'bank_id', id: 'bank_id' }, 
        { label: Helper.getLabel(language,'name',"Name"), inputType: "text", maxLength: 80, className: 'col-lg-4', name:'name', id: 'name', required:true },
        { label: Helper.getLabel(language,'account_number',"Account number"), maxLength: 35, className: 'col-lg-4', inputType: "number", name:'account_number', required:true }, 
        { label: Helper.getLabel(language,'currency',"Currency"), placeHolder: 'Select currency', className: 'col-lg-4', inputType: "dropdown", options: currencyList, id: 'currency_id', name:'currency_id', required: true }, 
        { label: Helper.getLabel(language,'phone',"Phone"), maxLength: 20, inputType: "text", className: 'col-lg-4', id: 'phone', name:'phone' }, 
        { label: Helper.getLabel(language,'iban',"IBAN"), maxLength: 35, inputType: "text", className: 'col-lg-4', id: 'IBAN', name:'IBAN' },  
        { label: Helper.getLabel(language,'SWFTno',"Swift no"), inputType: "text", className: 'col-lg-4', id: 'SWIFTno', name:'SWIFTno' },
        { label: Helper.getLabel(language,'email',"Email"), inputType: "text", className: 'col-lg-4', id: 'email', name:'email' }, 
        { label: Helper.getLabel(language,'url',"URL"),  inputType: "text", className: 'col-lg-4', id: 'URL', name:'URL' }
    ];

    const onPopupClose = () => {
        $("html").removeClass("rs_popup_open");
        setShowCreateModal(false);
    }

    const executePOSTAPI = async () => {
        try { 
            let api = Api;
            api.setUserToken();
        
            const res = await api.axios().post(Settings.apiOrgUrl+'/bankaccount', {
                ...formData,
                address: null,
                status: true,
                contact: { contacts: [] }
            },
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res?.data?.message); 
                onPopupClose();
                navigate(`/edit-bank-account/${res?.data?.bank_account_id}`); 
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        }
    };

    const handleSubmit = () => { 
        if(!formValidator.isValid({...formData})){ 
            formValidator.displayMessage();
            formValidator.reset(); 
            return;
        };
 
        Helper.createDebouncedAPIFunction( 
            [executePOSTAPI], 
            setApiCallBtn,
            cancelTokenSource
        )();
    };
    return (
        <Fragment> 
            { apiCallBtn ? <OverlayLoader /> : null }
            <Popup
                autoOpen={true} width="900px"
                onClose={onPopupClose} 
            >
                <>
                    <h5 className="create_rfq_modal__title">Create Bank Account</h5>

                    <Collapse
                        className="mt-4 mb-4" open={true}
                        title={Helper.getLabel(
                            language,
                            "general_info",
                            "General Information"
                        )}
                    >
                        <Form>
                            <div className='container-fluid'>
                                <FieldsRenderer updateState={setFormData} currentState={formData} fieldsArray={fieldsArrayBankAccountSetup} />
                            </div>
                        </Form> 
                    </Collapse> 
                </>

                <div className="addDocumentNumber-body-buttons" style={{ justifyContent: "flex-end" }}>
                    <Button
                        isActive={false} 
                        isDisable={apiCallBtn}
                        title={Helper.getLabel(language, "close", "Close")}
                        onClick={onPopupClose}
                    />

                    <Button
                        isDisable={apiCallBtn}
                        className="solid_btn" 
                        isActive={true}
                        title={Helper.getLabel(language, "create", "Create")}
                        onClick={handleSubmit}
                    />
                </div>
            </Popup>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        appOptions: state.options,
        auth: state.auth,
        language: state.language,
    };
};

export default connect(mapStateToProps) (BankAccountModal); 