import React, { Fragment, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import MasterComponentWraper from '../../Backend/MasterComponentWraper';
import NavigationHeder from '../../Navigations/NavigationHeder'; 
import SimpleLoading from '../../Loading/SimpleLoading';
import AgGridMemo from '../../grid/ag/AgGridMemo';
import Helper from '../../../inc/Helper';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import Tabs from '../../Widgets/Tabs/Tabs'; 
import VendorSitesEdit from './VendorSitesEdit';
import BusinessClassEdit from './BusinessClassEdit';
import DatepickerFunctions from '../../../inc/DatepickerHelper';
import GeneralCreate from '../Vendors Create/GeneralCreate'; 
import CommonAPIVendorCalls from './CommonAPIVendorCalls';
import { gridIdActions } from '../../../store/grid-id';
import { toggleActiveClassNameForDefaultSite } from '../../../inc/Validation';
import BanksEdit from './BanksEdit';
import AddressList from '../../Tenant/Address/AddressList';
import AgGridNew from '../../grid/ag/ag-grid-new';
 
const VendorVersioning = ({language}) => {
    const { id } = useParams(); 
    const dispatch = useDispatch(); 
 
    const [TabDataloading, setTabDataloading] = useState(false); 
     
    const [vendorType, setVendorType] = useState([])
    const [vendorCurrency, setVendorCurrency] = useState([])
    const [vendorSitesHierarchy, setVendorSitesHierarchy] = useState([])
    const [vendorSitesList, setVendorSitesList] = useState([])
 
    const [vendorTabData, setVendorTabData] = useState({});
    const [vendorSiteTabData, setVendorSiteTabData] = useState({});
    const [businessClassTabData, setBusinessClassTabData] = useState([]);
    const [banksTabData, setBanksTabData] = useState([]);
   
    let vendorCommonCallsAPI = false;

    useEffect(() => {
        dispatch(gridIdActions.VendorID({ payload: null})); 
        getVendorCommonCalls(); 
    }, []);
  
    const getVendorCommonCalls = async () => {
        if(vendorCommonCallsAPI) return;
        vendorCommonCallsAPI = true;

        const commonAPIVendorCalls = new CommonAPIVendorCalls()
        const vendorTypes = await commonAPIVendorCalls.getVendorTypes()
        const vendorCurreny = await commonAPIVendorCalls.getVendorCurrencies()
        
        if(vendorTypes) setVendorType(vendorTypes)
        if(vendorCurreny) setVendorCurrency(vendorCurreny)
    }
  
    const getVendorSiteTabRecords = async (version) => {
        try {
            let api = Api;
            api.setUserToken();
            const response = await api.axios().get(Settings.apiVendorUrl + `/vendor_sites_version/${id}/${version}`, {});
    
            if (response.data.status === "success") {
                const vendorSitesHierarchy = [];
                const vendorSitesAllList = [];
                response.data.data.forEach((item) => {
                    vendorSitesHierarchy.push({
                        ...item,
                        id: item.vendorsite_id,
                        title: item.name,
                    });
                });
                response.data.data.forEach((item) => {
                    vendorSitesAllList.push({
                        value: item.vendorsite_id,
                        label: item.name,
                    });
                });
                return { vendorSitesHierarchy, vendorSitesAllList };
            }
        } catch (error) {
            return { vendorSitesHierarchy: [], vendorSitesAllList: [] };
        }
    };
 
    const getBusinessClassTabRecords = async (version) => {
        try {
            let api = Api;
            api.setUserToken();
            const response = await api.axios().get(Settings.apiVendorUrl + `/vendor_classification_version/${id}/${version}`, {});
          
            if (response.data.status === "success") {
                return response.data.data;
            } 
        } catch (error) { 
            return [];
        }
    };

    const getBanksTabRecords = async (site_id, version) => {
        if(!site_id) return [];
        try {
            let api = Api;
            api.setUserToken();
            const response = await api.axios().get(Settings.apiVendorUrl + `/vendor_sites_bank_version/${site_id}/${version}`, {});
        
            if (response.data.status === "success") {
                let Updated_Response = response.data?.data?.map((item) => {
                    return { 
                        bank_id: item.bank_name ?? '-', 
                        name: item.account_name ?? '-',
                        account_number: item.account_number ?? '-',
                        SWIFTno: item.swift_no ?? '-',
                        IBAN: item.IBAN ?? '-',
                        status: item.status == true ? "yes" : "no",
                        account_code: item.account_code ?? '-',
                        currency_id: item.currency_id,  
                        account_id: item.id
                    }
                });
                return Updated_Response;
            } 
        } catch (error) { 
            return [];
        }
    };

    const SidebarGridSelection = async (event) => { 
        let selectedRows = event.api.getSelectedRows();  
        if(selectedRows.length === 0) { // re-setting states/ids so, that when onNodeSelection Changes the data is vanished/refreshed
            setVendorTabData({});  
            setVendorSiteTabData({});
            setVendorSitesHierarchy([]);
            setBusinessClassTabData([]);
            setBanksTabData([]);
            dispatch(gridIdActions.VendorID({ payload: null}))
            return;
        };
            const selectedRowNodeObject = selectedRows[0];
            setTabDataloading(true);
 
            const { vendorSitesHierarchy, vendorSitesAllList } = await getVendorSiteTabRecords(selectedRowNodeObject?.version);
            const businessClassObject = await getBusinessClassTabRecords(selectedRowNodeObject?.version);
            const banksData = await getBanksTabRecords(vendorSitesHierarchy[0]?.vendorsite_id, selectedRowNodeObject?.version)

            if(selectedRowNodeObject) setVendorTabData(selectedRowNodeObject);
            if(vendorSitesHierarchy && vendorSitesAllList) {   
                setVendorSitesHierarchy((prevState) => {
                    return {
                        ...prevState,
                        mainTitle: selectedRowNodeObject.name,
                        data: vendorSitesHierarchy
                    }
                }) 
                setVendorSitesList(vendorSitesAllList)
                dispatch(gridIdActions.VendorID({ payload: vendorSitesHierarchy[0]?.vendorsite_id }))
                toggleActiveClassNameForDefaultSite()
            };
            if(businessClassObject) setBusinessClassTabData(businessClassObject);
            if(banksData) setBanksTabData(banksData);

            setTabDataloading(false); 
    };

    const Column_Heading_Sidebar_Grid = [
        {field:'name', filter: 'agTextColumnFilter', minWidth: 250, editable:false, headerName:'Vendor name'},
        {field:'version', filter: 'agNumberColumnFilter', minWidth: 250, editable:false, headerName:'Version'},
        {field:'createdby', filter: 'agTextColumnFilter', minWidth: 250, editable:false, headerName:'Created by'},
        {field:'createddatetime', filter:'agDateColumnFilter', valueFormatter: (params) => params?.value ? DatepickerFunctions.convertDateTimeFromDataBase(params?.value) : '-', minWidth: 250, editable:false, headerName:'Created at'}, 
        {field:'modifiedby', filter: 'agTextColumnFilter', minWidth: 250, editable:false, headerName:'Modified by'}, 
        {field:'modifieddatetime', filter:'agDateColumnFilter', valueFormatter: (params) => params?.value ? DatepickerFunctions.convertDateTimeFromDataBase(params?.value) : '-', minWidth: 250, editable:false, headerName:'Modified at'},
    ];

    let navs = [
        { title: Helper.getLabel(language, 'general', 'General'), isActive: true, forceLoadContents:true, data_section: "one", active_status: 'active_tab' },
        { title: Helper.getLabel(language, 'sites', 'Sites'), data_section: "two", forceLoadContents:true, active_status: '' },
        { title: Helper.getLabel(language, 'business_classification', 'Business classification'), forceLoadContents:true, data_section: "three", active_status: '' },
        { title: Helper.getLabel(language, 'address', 'Address'), forceLoadContents:true, data_section: "four", active_status: '' }, 
    ]; 

    const GeneralModule = () => {
        return (
            <GeneralCreate
                websiteUrl={true}
                disableFields={true} 
                createVendor={vendorTabData}
                vendorType={vendorType} 
            />
        )
    };

    const VendorSitesGridModule = () => { 
        return (
            <VendorSitesEdit
                disableFields={true}   
                version={true}
                vendorSites={vendorSitesList}
                currency={vendorCurrency} 
                vendorSiteHierarchy={vendorSitesHierarchy}
                id={id}
                editVendorSite={vendorSiteTabData}
                setEditVendorSite={setVendorSiteTabData}
            />
        )
    };

    const BusinessClassificationModule = () => {
        return (
            <BusinessClassEdit version={true} gridData={businessClassTabData} disableFields={true} id={id} />
        )
    };

    const BankModule = () => {
        return (
            <BanksEdit currency={vendorCurrency} version={true} gridData={banksTabData} disableFields={true} />
        )
    };

    const VendorAddress = () => {
        return (
            <AddressList
                disableRecords={true}
                id="Vendor_version_address" 
                address_source_integrator={id}
                addressSourc_id={vendorTabData.addresssource_id}
                source_id={6}
            />
        )
    }

    const VendorVersionModuleWrapper = () => {
        return (
            <div className='row'>
                <div className='col-12 col-md-6 col-lg-4 mb-5'>
                    <AgGridNew
                        apiUrl={id ? Settings.apiVendorUrl+`/vendor_versions/${id}` : null}
                        pagination={false}
                        hidePaginationFooter={true}
                        columnDefs={Column_Heading_Sidebar_Grid}
                        rowType="single"
                        onSelectionChanged={SidebarGridSelection} 
                        allBtnHide={true} 
                        height={500}
                        gridId={'versions-all'}
                    />
                </div>
                <div className='col-12 col-md-6 col-lg-8'> 
                    {TabDataloading ? <SimpleLoading /> :
                        <Tabs disableSticky={true} navs={navs} showAll={false} scrollOffset="500">
                            <Fragment>{GeneralModule()}</Fragment>
                            <Fragment>{VendorSitesGridModule()}</Fragment>
                            <Fragment>{BusinessClassificationModule()}</Fragment>  
                            <Fragment>{VendorAddress()}</Fragment>
                        </Tabs> 
                    }
                </div>
            </div>
        );
    };
    return (
        <div className='container-fluid'>
            <NavigationHeder hideMoreBtn={true} title={ Helper.getLabel(language,'version','Version')}/>
            { VendorVersionModuleWrapper() }
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        language:state.language
    }
};

export default connect(mapStateToProps) (MasterComponentWraper(VendorVersioning));