import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import MasterComonent from '../../components/Backend/MasterComonent'
import InnerScreen from '../../components/Inner Screens/InnerScreen'
import ApplySecurityRoles from '../../components/SecurityRoles/ApplySecurityRoles'
import Form_gui_ids from '../../inc/Form_gui_ids'

const SetupScreen = () => {
    return (
        <div className='modules_screen_lynkaz'>
            <MasterComonent>
                <InnerScreen innerTitle='Setup'>
                    <div className='col-lg-3 gy-4'>
                        <div className='inner-page-header border-bottom'>
                            <h2>Organization</h2>
                        </div>
                        <div className='inner-page-routes'> 
                            <Link to='/entity'>Legal Entities</Link>
                            <Link to='/organisation-type'>Organizations Type</Link>
                            <Link to='/organisation-unit'>Organizations Unit</Link>
                        </div>
                    </div>

                    <div className='col-lg-3 gy-4'>
                        <div className='inner-page-header border-bottom'>
                            <h2>Currency</h2>
                        </div>
                        <div className='inner-page-routes'>
                            <Link to='/currency-conversion'>Currency Conversion</Link>
                        </div>
                    </div>

                    <div className='col-lg-3 gy-4'> 
                        <div className='inner-page-header border-bottom'>
                            <h2>Inventory</h2>
                        </div>
                        <div className='inner-page-routes'>
                            <Link to='/sites'>Sites</Link>
                            <Link to='/warehouse'>Warehouses</Link> 
                        </div>
                    </div> 

                    <div className='col-lg-3 gy-4'>
                        <div className='inner-page-header border-bottom'>
                            <h2>Cost Segments</h2>
                        </div>
                        <div className='inner-page-routes'>
                            <Link to='/segments'>Segments</Link> 
                        </div>
                    </div> 

                    <div className='col-lg-3 gy-4'>
                        <div className='inner-page-header border-bottom'>
                            <h2>Unit of Measurement</h2>
                        </div>
                        <div className='inner-page-routes'>
                            <Link to='/unit'>Unit</Link>
                            <Link to='/unit-conversion'>Unit Conversion</Link>
                        </div>
                    </div> 

                    <div className='col-lg-3 gy-4'>
                        <div className='inner-page-header border-bottom'>
                            <h2>Fiscal Calendars</h2>
                        </div>
                        <div className='inner-page-routes'>
                            <Link to='/fiscal-year'>Fiscal Calendars</Link> 
                        </div>
                    </div> 

                    <div className='col-lg-3 gy-4'>
                        <div className='inner-page-header border-bottom'>
                            <h2>Number Sequence</h2>
                        </div>
                        <div className='inner-page-routes'>
                            <Link to='/number-sequence'>Number Sequence</Link>
                        </div>
                    </div> 
    
                    <div className='col-lg-3 gy-4'>
                        <div className='inner-page-header border-bottom'>
                            <h2>Categories</h2>
                        </div>
                        <div className='inner-page-routes'>
                            <Link to='/category'>Categories</Link> 
                        </div>
                    </div>

                    <div className='col-lg-3 gy-4'>
                        <div className='inner-page-header border-bottom'>
                            <h2>Procurement Settings</h2>
                        </div>
                        <div className='inner-page-routes'>
                            <Link to='/procurement-settings'>Procurement Settings</Link>  
                        </div>
                    </div>

                    <div className='col-lg-3 gy-4'>
                        <div className='inner-page-header border-bottom'>
                            <h2>Request for Quotations</h2>
                        </div>
                        <div className='inner-page-routes'>
                            <Link to='/bidding-count-listings'>Bid Count Policy</Link>
                            <Link to='/bidding-count-recall-listings'>Bid Recall Policy</Link>
                            <Link to='/non-compliance-policies'>Non Compliance Policy</Link>
                            <Link to='/pr-request-policies'>PR Request Policy</Link>
                        </div>
                    </div>

                    <div className='col-lg-3 gy-4'>
                        <div className='inner-page-header border-bottom'>
                            <h2>Item</h2>
                        </div>
                        <div className='inner-page-routes'>
                            <Link to='/item-group'>Items Group</Link>
                            <Link to='/item-group-account'>Items Group - Account</Link>
                            <Link to='/item-group-account-type'>Item Group - Account Type</Link>
                            <Link to='/item-discount-type'>Items - Discount Type</Link>
                            <Link to='/item-buyer-group'>Items - Buyer Group</Link>     
                        </div>
                    </div> 
    
                    <div className='col-lg-3 gy-4'>
                        <div className='inner-page-header border-bottom'>
                            <h2>Attachment</h2>
                        </div>
                        <div className='inner-page-routes'>
                            <Link to='/attachment-types'>Attachment Types</Link> 
                        </div>
                    </div>

                    <div className='col-lg-3 gy-4'>
                        <div className='inner-page-header border-bottom'>
                            <h2>Email</h2>
                        </div>
                        <div className='inner-page-routes'>
                            <Link to='/email-templates'>Email Templates</Link>
                            <Link to='/notification-events'>Notifications</Link>
                        </div>
                    </div> 
  
                    <div className='col-lg-3 gy-4'> 
                        <div className='inner-page-header border-bottom'>
                            <h2>Payments</h2>
                        </div>
                        <div className='inner-page-routes'>
                            <Link to='/payment-terms'>Payment Terms</Link> 
                        </div>
                    </div> 

                    <div className='col-lg-3 gy-4'> 
                        <div className='inner-page-header border-bottom'>
                            <h2>Banks</h2>
                        </div>
                        <div className='inner-page-routes'>
                            <Link to='/banks'>Banks</Link> 
                            <Link to='/banks-accounts'>Bank Accounts</Link> 
                        </div> 
                    </div>

                    <div className='col-lg-3 gy-4'> 
                        <div className='inner-page-header border-bottom'>
                            <h2>Users</h2>
                        </div>
                        <div className='inner-page-routes'>
                            <Link to='/users'>Users - All</Link> 
                            <Link to='/users?user_type=1'>Users - External</Link> 
                        </div> 
                    </div>

                    <div className='col-lg-3 gy-4'>
                        <div className='inner-page-header border-bottom'>
                            <h2>Tax</h2>
                        </div>
                        <div className='inner-page-routes'>
                            <Link to='/tax/authority'>Authority</Link> 
                            <Link to='/tax/settlement-periods'>Tax Settlement</Link> 
                            <Link to='/tax/posting-group'>Posting Group</Link> 
                            <Link to='/tax/codes'>Tax Codes</Link> 
                            <Link to='/tax/group'>Tax Group</Link> 
                            <Link to='/tax/tax-group-with-items'>Tax Item Group</Link> 
                        </div>
                    </div>

                    <div className='col-lg-3 gy-4'>
                        <div className='inner-page-header border-bottom'>
                            <h2>Compliances</h2>
                        </div>
                        <div className='inner-page-routes'>
                            <Link to='/compliances-responses/listings'>Compliance Responses</Link>
                        </div>
                    </div>
                    <div className='col-lg-3 gy-4'>
                        <div className='inner-page-header border-bottom'>
                            <h2>Delivery</h2>
                        </div>
                        <div className='inner-page-routes'>
                            <Link to='/delivery-terms'>Delivery Terms</Link> 
                            <Link to='/modes-of-delivery'>Modes of Delivery</Link> 
                        </div>
                    </div>
                    <div className='col-lg-3 gy-4'>
                        <div className='inner-page-header border-bottom'>
                            <h2>Purchase Agreement Classifications</h2>
                        </div>
                        <div className='inner-page-routes'>
                            <Link to='/purchase-agreement-classifications'>Purchase Agreement Classifications</Link> 
                        </div>
                    </div>
                </InnerScreen>
            </MasterComonent>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        language:state.language
    }
}

const SecurityOptions = {
    gui_id:Form_gui_ids.setup.page_id
}

export default connect (mapStateToProps) ( ApplySecurityRoles( SetupScreen ,SecurityOptions));