import React, { useEffect, useState, Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import Input from '../../Forms/Input';
import Button from '../../inc/Button';
import LinkBtn from '../../inc/LinkBtn';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';
import RsWithRouter from '../../inc/RsWithRouter'; 
import NavigationHeder from '../../Navigations/NavigationHeder';
import AddressList from '../../Tenant/Address/AddressList';
import ContactAddress from '../../Tenant/Address/ContactAddress';
import TextTranslations from '../../Popup/TextTranslation/TextTranslations';
import Collapse from '../../inc/Collapse';
import FormValidator from '../../../inc/FormValidator';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import { useParams } from 'react-router-dom';

let entityServerResponseOfLogo = null;
const EditForm = ({ language }) => {
    const {id} = useParams();

    const wrapperDiv = useRef(null); 
    const [entity, setEntity] = useState({});

    const [isLoading, setIsLoading] = useState(false);
    const [height, setHeight] = useState(0);  

    const source_id = Settings.source_id.entity;

    let validator; 

    const validationConfigure = () => {
        const fieldConfig = [
            { name: 'code', displayName: Helper.getLabel(language, 'code', 'Code'), types: ['Required'] },
            { name: 'name', displayName: Helper.getLabel(language, 'name', 'Name'), types: ['Required'] },
        ];
        if(!validator) {
            validator = new FormValidator(fieldConfig, language)
        };
    }

    validationConfigure();

    let getEntityCalled = false;

    useEffect(() => { 
        Helper.resizeWindowHandle(wrapperDiv,setHeight,window);
    }, [wrapperDiv?.current?.clientHeight, isLoading]);

    useEffect(() => { loadEntity() }, []);
 
    const loadEntity = () => { 
        if(getEntityCalled || !id) return;
        getEntityCalled = true;

        Api.setUserToken();
        setIsLoading(true);
        Api.axios().get(Settings.apiUrl + '/entity/' + id)
            .then((res) => {
                if (res.data.status === 'success') {
                    let entityFromServer = res?.data?.data;
                    if (entityFromServer && entityFromServer.contact && entityFromServer.contact.contactsource_id) {
                        entityFromServer.contactsource_id = entityFromServer.contact.contactsource_id;
                    }
                    if (entityFromServer && entityFromServer.address && entityFromServer.address.addresssource_id) {
                        entityFromServer.addresssource_id = entityFromServer.address.addresssource_id;
                    }
                    setIsLoading(false);

                    entityServerResponseOfLogo = processUserProfileImage(entityFromServer?.logo)
                    setEntity({...entityFromServer, logo: processUserProfileImage(entityFromServer?.logo)});
                } 
            })
            .catch((res) => {
                setIsLoading(false);
                getPopupMessageBasedOnStatus(res) 
            });
    };
  
    const saveEntity = () => {
        let newEntityData = {
            "code": entity?.code,
            "name": entity?.name,
            "logo": entityServerResponseOfLogo == entity?.logo ? null : entity?.logo,
        };
        if (!validator.isValid(newEntityData)) {
            validator.displayMessage();
            return;
        }
        
        setIsLoading(true);
        Api.setUserToken();
         
        Api.axios().put(Settings.apiUrl + '/entity/' + id, newEntityData)
            .then((res) => {
                setIsLoading(false);
                Helper.alert(res.data.message, res.data.status === 'success' ? 'success' : 'error');
            })
            .catch((error) => {
                setIsLoading(false);
                getPopupMessageBasedOnStatus(error);
            });
    };

    const logoChange = (e, fileObj) => {
        const imageData = fileObj?.fileData; 
        
        setEntity(prevEntity => ({
            ...prevEntity,
            logo: imageData
        }));
    };

    const onInputChangeHandler = (e) => {
        setEntity(prevEntity => ({
            ...prevEntity,
            [e.target.name]: e.target.value
        }));
    };
    
    const onContactCreate = (response) => {
        if (entity && !entity.contactsource_id) {
            setEntity(prevEntity => ({
                ...prevEntity,
                contactsource_id: response?.contact_source_id
            }));
        }
    };

    const onAddressCreate = (response) => {
        if (entity && !entity.addresssource_id) {
            setEntity(prevEntity => ({
                ...prevEntity,
                addresssource_id: response?.address_source_id
            }));
        }
    };

    const processUserProfileImage = (image) => {
        const imageData = image?.slice(2, -1); 
        const imageUrl = `data:image/png;base64,${imageData}`;
         
        return imageUrl;
    };
     
    const displayLogo = () => {  
        return ( 
            <div style={{
                width: '100%',
                height: '100px',
                background: '#fff', 
                boxSizing: 'border-box', 
                position: 'relative',
                backgroundSize: 'contain',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${entity?.logo})`
            }}>
            </div>
        );
    };

    return (
        <Fragment>
            { isLoading ? <OverlayLoader isPopupInTab={true} /> : null }
            <div className='container-fluid'>
                <div ref={wrapperDiv}>
                    <NavigationHeder hideMoreBtn={true} backUrl="/entity" title={Helper.getLabel(language, 'edit_entity', 'Edit Entity')}>
                        <TextTranslations
                            source_id={source_id}
                            source_integrator={id}
                            translationsource_id={entity?.translationsource_id}
                            original_text={entity?.name}
                            product_number={entity?.code}
                        />
                        <LinkBtn to="/entity" isActive={false} title={Helper.getLabel(language, 'close', 'Close')} />
                        <Button isActive={false} className="rfq_save_btn" onClick={saveEntity} title={Helper.getLabel(language, 'save', 'Save')} />
                    </NavigationHeder>
                </div>

                <div className='common_scrollbar_styles' style={{ height: `${height - 30}px`, overflowY: 'auto'}}> 
                    <Collapse open={true} title={Helper.getLabel(language, 'general', 'General')}>
                        <form id="new_form" className='rs_form_container' encType="multipart/form-data">
                            <div className='row'>
                                <div className='col-xs-12 col-lg-4 col-md-6'>
                                    <Input
                                        name="code"
                                        disable={true}
                                        label="Code"
                                        maxLength={10} 
                                        onChange={onInputChangeHandler}
                                        value={entity?.code}
                                        required={true}
                                    />
                                </div>
                                <div className='col-xs-12 col-lg-4 col-md-6'>
                                    <Input
                                        name="name"
                                        hasError={validator.hasError('name')}
                                        label="Name"
                                        maxLength={100} 
                                        onChange={onInputChangeHandler}
                                        value={entity?.name}
                                        required={true}
                                    />
                                </div>
                                <div className='col-xs-12 col-lg-4 col-md-6'>
                                    <Input
                                        name="logo"
                                        label={Helper.getLabel(language, 'logo', "Logo")}
                                        placeholder={Helper.getLabel(language, 'select_logo', "Select logo")}
                                        onChange={logoChange}
                                        inputType="file"
                                    />
                                </div>
                                <div className='entity-logo-wrapper'>
                                    {  displayLogo() }
                                </div>
                            </div>
                        </form>
                    </Collapse>
                    { isLoading ? null : 
                        <AddressList
                            id='entity_address'
                            onAddressCreate={onAddressCreate}
                            addressSourc_id={entity?.addresssource_id}
                            source_id={source_id}
                            address_source_integrator={id}
                        />
                    }
                    { isLoading ? null : 
                        <Collapse open={true} title={"Contact"}>
                            <div className="entity_contacts">
                                <ContactAddress
                                    id='entity_contact'
                                    contactsource_integrator={id}
                                    onContactCreate={onContactCreate}
                                    contactsource_id={entity?.contactsource_id}
                                    source_id={source_id}
                                    address_id={null}
                                />
                            </div>
                        </Collapse> 
                    } 
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    language: state.language
});

export default connect(mapStateToProps)(RsWithRouter(EditForm));