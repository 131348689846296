import React from 'react'
import Helper from '../../../../inc/Helper'
import EditPO from '../../../Purchase Orders Internal/Edit PO/EditPO'

const EditCreditNote = () => {
  return (
    <EditPO
      title={Helper.getLabel(
        null,
        'edit_credit_note',
        'Edit Credit Note'
      )}
      lineTitle={Helper.getLabel(null, 'credit_notes_lines',"Credit Notes Lines")}
      backUrl={-1}
      hideStartDate={false}
      hideEndDate={false}
      hideBillingRule={false}
      source_id={81}
      visibleEndDate={true}
      visibleStartDate={true}
      type="credit notes"
    />
  )
}

export default EditCreditNote
