import React, { Component } from 'react';
import ActionsHistory from './ActionsHistory/ActionsHistory';
import FileUploaderList from './FileUpload/FileUploaderList';
import UploaderWithList from './FileUpload/UploaderWithList';
import Notes from './Notes/Notes';
import $ from 'jquery';
/**
 * source_id ={18} 
 * notesource_integrator= {59}  
 * attachmentsource_integrator ={59}
 */
class NoteAttachmentHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeComponent: this.props.hideNotes ? 'attachment' : 'notes',
        };
    }

    componentDidMount() {
        this.fixWidgetHeight();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.historyArray !== this.props.historyArray) {
            this.fixWidgetHeight();
        }
    }

    fixWidgetHeight() {
        let minHeight = $('.verticle_items').outerWidth();
        $('.verticle_items').css({ translate: ' 0 ' + minHeight + 'px' });
        $('.note_attachment_history_widget').css({
            'min-height': minHeight + 30 + 'px',
        });
    }

    setActiveComponent(e, activeComponent) {
        this.setState({
            activeComponent: activeComponent,
        });
    }

    displayComponent() {
        switch (this.state.activeComponent) {
            case 'notes':
                return !this.props.hideNotes && <Notes notesHeight={this.props?.height?(this.props?.height-120):380} hideVendorPortal={this.props.hideVendorPortal} notesource_integrator={this.props.notesource_integrator} source_id={this.props.source_id} exportable={!this.props.notesource_integrator} />;
            case 'attachment':
                return <UploaderWithList hideVendorPortal={this.props.hideVendorPortal} source_id={this.props.source_id} attachmentsource_integrator={this.props.attachmentsource_integrator} condition={this.props.condition}/>;
            case 'action_history':
                return <ActionsHistory actionHistoryLoadingState={this.props.actionHistoryLoadingState} setActionHistoryState={this.props.setActionHistoryState} showActionHistoryDropdown={this.props.showActionHistoryDropdown} openCurrentAssignees={this.props.openCurrentAssignees} showCanvas={this.props.openCanvas} historyArray={this.props.historyArray} />;
            default:
                break;
        }
    }

    render() {
        let heights = this.props.height;
        
        return (
            <div className='note_attachment_history_widget'>
                <div className='verticle_items'>
                    {!this.props.hideNotes && <span>Notes</span>}
                    {!this.props.hideAttachments && <span>Attachment</span>}
                    {!this.props.hideHistory && <span>Action History</span>}
                </div>
                <div className='note_attachment_history_widget_inner'>
                    <div className='note-attchement-style'>
                        <ul className={`menu_style_2 ${this.props.hideHistory ? 'menu_style_3' : ''}`}>
                            {!this.props.hideNotes && <li className={this.state.activeComponent === 'notes' ? 'active' : ''} onClick={e => this.setActiveComponent(e, 'notes')}>Notes</li>}
                            {!this.props.hideAttachments && <li className={this.state.activeComponent === 'attachment' ? 'active' : ''} onClick={e => this.setActiveComponent(e, 'attachment')}>Attachment</li>}
                            {!this.props.hideHistory && <li className={this.state.activeComponent === 'action_history' ? 'active' : ''} onClick={e => this.setActiveComponent(e, 'action_history')}>Action History</li>}
                        </ul>
                    </div>
                    <div
                        className='widget_contents note-tab-wraper'
                        style={{
                            transition: '0.6s',
                            maxHeight: `${heights?(heights-120):380}px`,
                            overflow: this.state?.activeComponent === 'attachment' ? 'scroll' : 'hidden'
                        }}
                    >
                        {this.displayComponent()}
                    </div>
                </div>
            </div>
        );
    }
}

export default NoteAttachmentHistory;