import React, { Component } from 'react';
import GoodsReceivingNotes from '../../components/GoodsReceiving/GoodsReceivingNotes';
import Helper from '../../inc/Helper';

class ReGoodReceivingPage extends Component {
    render() {
        return (
            <GoodsReceivingNotes
                title={Helper.getLabel(null,'release_order_good_receiving_note','Release order good receiving note')}
                backUrl={-1}
                isDynamicBackUrl={true}
                editUrl='/release-order/good-receiving/edit'
            />
        );
    }
}

export default ReGoodReceivingPage;