// import React, { Component } from 'react';
// import Helper from '../../../../inc/Helper';
// import AgGrid from '../../../grid/ag/ag-grid';
// import SegmentsDistribution from '../../../Segments/SegmentsDistribution';
// import Tabs from '../../../Widgets/Tabs/Tabs';
// import DistributionAditionalInformation from './DistributionAditionalInformation';
// import Api from '../../../../inc/Api';
// import Settings from '../../../../inc/Settings';
// import AgGridMemo from '../../../grid/ag/AgGridMemo';
// import Alert from '../../../inc/Alert';
// import FormValidator from '../../../../inc/FormValidator';
// import GridSegmentDistributionDetails from '../../../Segments/GridSegmentDistributionDetails';
// import RsDataLoader from '../../../inc/RsDataLoader';
// import SimpleLoading from '../../../Loading/SimpleLoading';
// import AmendmentSitePopup from './AmendmentSitePopup';
// import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation';
// /**
// settings = A call object that should be inherited from DistributionSettings class
// segment_distribution_source_id=segment_distribution_source_id
// source_id= line site source id
// distributeKey = "ordered_qty"
// distributeValue = {this.state.activePOLine ? Helper.getOnlyNumber(this.state.activePOLine.data.ordered_qty) : 0}
// lineAmount = {this.state.activePOLine ? Helper.getOnlyNumber(this.state.activePOLine.data.line_amount) : 0}
// po_line_id={this.state.activePOLine ? this.state.activePOLine.data.line_id : null}
//  */
// class DistributionTab extends Component {
//     constructor(props){
//         super(props);
//         this.totalApiReq=0;
//         this.gridObj = null;
//         this.settings = this.props.settings ? this.props.settings : null;
//         this.uniqueIdForTable = this.settings ? this.settings.getIdKey() : 'linesite_id';
//         this.isSegmentsLoaded = false;
//         this.state = {
//             amendmentPopupShow:false,
//             activePoLineSite:null,
//             forceRender:false,
//             po_line_id: null,
//             PoLinesite:[],
//             isLoading:false,
//         }
//     }
//     componentDidMount(){
//         let that = this;
//         this.setState({
//             po_line_id: this.props.po_line_id ? this.props.po_line_id : null
//         },function(){
//             that.loadPoLinesite()
//         })
//     }
//     onSelectionChanged(event){
//         let selectedNodes = event.api.getSelectedNodes();
//         if(selectedNodes.length <=0){
//             this.setState({
//                 activePoLineSite:null
//             })
            
//         }else{
//             this.setState({
//                 activePoLineSite:selectedNodes[0]
//             })
//         }
//     }
    
//     loadPoLinesite(){
//         if(!this.state.po_line_id){
//             this.setState({
//                 PoLinesite:[],
//                 isLoading:false
//             })
//             return;
//         }
//         let that = this;
//         let api = Api;
//         api.setUserToken();
//         that.setState({
//             forceRender: !that.state.forceRender,
//             PoLinesite:[],
//             isLoading:true
//         })
//         let apiUrl = this.settings.getApiUrl().get +'/' +this.state.po_line_id+'/'+this.props.source_id;
//         if(this.props.viewType =='shipment'){
//             apiUrl = Settings.apiPurchaseOrderUrl+'/SH_linesites/'+this.state.po_line_id
//         }
//         api.axios().get(apiUrl).then(res => {
//             let poLineSiteData = res.data.data;
//             /*
//             if(that.props.viewType == 'shipment'){
//                 poLineSiteData =  res.data.data.map( dataItem => {
//                     if(that.props.shipmentType == 'return_order'){
//                         return dataItem;
//                     }
//                     let tempDateaItem = {
//                         ...dataItem,
//                         received_qty: Helper.getOnlyNumber(dataItem.received_qty) == 0  ? dataItem.remaining_qty : dataItem.received_qty
//                     }
//                     return tempDateaItem;
//                 })
//             }
//             */
//             that.setState({
//                 forceRender: !that.state.forceRender,
//                 PoLinesite:poLineSiteData,
//                 isLoading:false
//             })
//         }).catch(error =>{
//             that.setState({
//                 forceRender: !that.state.forceRender,
//                 PoLinesite:[],
//                 isLoading:false
//             })
//         })
//     }
//     onSaveHandler(items){
//         /*
//         this.totalApiReq = items.length;
//         items.forEach(item => {
//             //item.line_status = 1;
//             if(item[this.uniqueIdForTable]){
//                 this.update(item);
//             }else{
//                 this.createNew(item);
//             }
//         });
//         */
//     }
//     createNew(data){
//         let newData = this.getDataForDb(data);
//         let that = this, api = Api;
//         api.setUserToken();
//         api.axios().post( this.settings.getApiUrl().create ,newData).then( res=> {
//             that.totalApiReq--;
            
//             if(that.totalApiReq <= 0){
//                 Helper.alert(res.data.message)
//                 that.loadPoLinesite()
//             }
//         }).catch(error => {
//             that.totalApiReq--;
//             if(that.totalApiReq <= 0){
//                 getPopupMessageBasedOnStatus(error)
//             }
//         })
//     }
//     update(data){
//         let newData = this.getDataForDb(data);
//         let that = this , api = Api;
//         api.setUserToken();
//         let updateUrl = this.settings.getApiUrl().update +'/'+data[this.uniqueIdForTable]+'/'+this.props.source_id;
//         if(this.props.viewType == 'shipment'){
//             updateUrl = Settings.apiPurchaseOrderUrl+'/SH_linesite/'+ data[this.uniqueIdForTable];
//             newData = {
//                 received_qty: Helper.getOnlyNumber(newData.received_qty),
//                 line_amount:null
//             }
//         }
//         if(this.props.viewType == 'invoice'){
//             newData = {
//                 received_qty: Helper.getOnlyNumber(newData.received_qty),
//                 line_amount:null
//             }
//         }
//         api.axios().put(updateUrl ,newData).then( (res)=> {
//             that.totalApiReq--;
//             if(that.totalApiReq <= 0){
//                 Helper.alert(res.data.message)
//                 //that.loadPoLinesite()
//             }
//             if(that.props.onRowValueUpdated){
//                 that.props.onRowValueUpdated(data)
//             }
//         }).catch( (error) => {
//             that.totalApiReq--;
//             if(that.totalApiReq <= 0){
//                 getPopupMessageBasedOnStatus(error)
//             }
//         })
//     }
//     getDataForDb(data){
//         let filterData = {}
//         if(data[this.settings.idKey]){
//             filterData = this.settings.filterPutData(data)
//         }else{
//             filterData =  this.settings.filterPostData(data)
//         }
//         return {
//             source_id: this.props.source_id,
//             line_id: this.state.po_line_id,
//             ...filterData,
//             [this.uniqueIdForTable]: data[this.uniqueIdForTable] ? data[this.uniqueIdForTable] : null
//         };
//     }
//     componentDidUpdate( prevProps){
//         if(this.props.po_line_id != prevProps.po_line_id){
//             let that = this;
//             this.setState({
//                 activePoLineSite:null,
//                 forceRender: !that.state.forceRender,
//                 po_line_id: this.props.po_line_id ? this.props.po_line_id : null
//             },function(){
//                 that.loadPoLinesite();
//             })
//         }
//     }
//     hasActivePoLineSite(){
//         if(this.state.activePoLineSite && this.state.activePoLineSite.data[this.uniqueIdForTable]){
//             return true;
//         }
//         return false;
//     }
//     getSettingsError(){
//         let settingsError = null;
//         if(!this.settings){
//             settingsError = 'settings props are required';
//             return settingsError;
//         }
//         /*
//         if(this.settings.constructor.name != 'DistributionSettings'){
//             settingsError = 'Settings props should have instance of /inc/PropsSettings/DistributionSettings class';
//         }

//         */
//         return settingsError;
//     }
//     getAmount(orderQuantityArg){
//         let that = this;
//         let orderQuantity =  Helper.getOnlyNumber(orderQuantityArg);
//         let lineOrderQuantity = Helper.getOnlyNumber(that.props.distributeValue);
//         let unitPrice =( Helper.getOnlyNumber(that.props.lineAmount) / lineOrderQuantity );
//         return unitPrice * orderQuantity;
//     }
//     getGridHeaders(){
        
//         let that = this;
//         let gridHeder = this.settings.getDistributionHeader();
//         let additionalFields = this.settings.getAdditionalFields();
//         additionalFields.forEach( adsField => {
//             let isFind = gridHeder.find( item => { return item.field === adsField.name } )
//             if(!isFind){
//                 gridHeder.push({field:adsField.name,  hide:true})
//             }
//         })
//         let isAmmountFind = this.props.shipmentType==="invoice" && this.props.gridType==="contract"?true:gridHeder.find( item => { return item.field === 'amount' } );
//         if(!isAmmountFind){
//             gridHeder.push({
//                 field:'amount', 
//                 headerName:Helper.getLabel(null,'amount',"Amount"),
//                 editable:false,
//                 valueFormatter:(params) => {
                    
//                     return  Helper.formateCurrency(that.getAmount(params.data[that.props.distributeKey]))
//                 }
//             })
//         }
//         let finalHeaderAr = [];
//         gridHeder.forEach( (headerItem,itemKey) => {
//             if(itemKey === 0){
//                 headerItem = {
//                     ...headerItem,
//                     cellRenderer: 'agGroupCellRenderer',
//                 }
//             }
//             if(headerItem.field == that.props.distributeKey){
//                 headerItem.defaultValue =  that.props.viewType==="invoice" || this.props.viewType==="shipment"?1:that.getDefaultDistributedValue.bind(that);
//                 if(that.props.viewType!=="invoice" && this.props.viewType!=="shipment"){
//                 headerItem.maxValue = that.getDistributionMaxValue.bind(that);
//                 }
//                 headerItem.onChange = that.onDistributedAmountChange.bind(that);
//             }
//             finalHeaderAr.push(headerItem)
//         } )
//         return finalHeaderAr;
//     }
//     getDefaultDistributedValue(){
//         let maxValue = Helper.getOnlyNumber(this.props.distributeValue);
//         let distributedQuanity = 0;
//         this.gridObj.api.forEachNode(node => {
//             distributedQuanity += Helper.getOnlyNumber(node.data[this.props.distributeKey])
//         })
//         return maxValue - distributedQuanity;
//     }
//     getDistributionMaxValue(oldValue,params,inputBox,editorInput){
//         let maxValueAllow = Helper.getOnlyNumber(this.props.distributeValue);
//         let valueUsed = 0;
//         params.api.forEachNode(node => {
//             if(node.rowIndex != params.rowIndex){
//                 valueUsed += Helper.getOnlyNumber(node.data[this.props.distributeKey])
//             }
//         })
//         valueUsed += Helper.getPositiveNumber(oldValue);
//         if(valueUsed >maxValueAllow){
//             maxValueAllow = maxValueAllow - ( valueUsed - Helper.getPositiveNumber(oldValue) )
//         }
//         return maxValueAllow;
//     }
//     onDistributedAmountChange(event,params){
//         let quantityUnitPrice = this.props.lineAmount / this.props.distributeValue;
//         let newAmount = quantityUnitPrice * event.target.value;
//         if(this.props.viewType==="shipment" || this.props.viewType==="invoice"){
//         if(parseInt(event.target.value)>parseInt(this.props.remaining_qty)){
//             event.target.value = this.props.remaining_qty
//             params.node.setDataValue('received_qty',event.target.value)
//         }
//         params.node.setDataValue('received_qty', params.data.received_qty);
//         params.api.refreshCells({ rowNodes: [params.node], columns: ['received_qty','amount'] });
//         }

//         //let editorInsAr = params.api.getCellEditorInstances();
//         params.node.setDataValue('amount', newAmount);
//         /*
//         editorInsAr.forEach( cellEditorIns => {
//             if(cellEditorIns.params){
//                 if(cellEditorIns.params.colDef.field == 'amount'){
//                     cellEditorIns.updateValue(newAmount)
//                     Helper.alert("prams")
//                 }
//             }
            
//         })
//         */
//     }
//     getDistributionTotal(){
//         let gridData = Helper.getDataFromGrid(this.gridObj);
//         let totalDistribution = 0;
//         gridData.forEach(data => {
//             totalDistribution += Number.parseFloat(data[this.props.distributeKey])
//         })
//         return totalDistribution;
//     }

//     rowEditStophandler(event){
//         // check maximum data
//         let distributionTotal = this.getDistributionTotal();
//         if(distributionTotal > this.props.distributeValue){
//             let maximumValueCanBe = this.props.viewType==="invoice" || this.props.viewType==="shipment"?this.props.remaining_qty:this.props.distributeValue - ( distributionTotal - Helper.getOnlyNumber(event.data[this.props.distributeKey]) );
//             event.node.setData({
//                 ...event.data,
//                 [this.props.distributeKey] : maximumValueCanBe
//             })
//         }
//     }
//     lineSiteDeleteHander(items){
//         let that = this , api = Api;
//         api.setUserToken();
//         items.forEach(lineSite => {
//             api.axios().delete( this.settings.getApiUrl().delete +'/'+lineSite[this.uniqueIdForTable]+'/'+this.props.source_id).then( res=> {
//                 that.loadPoLinesite()
//             }).catch(error => {
//                 that.loadPoLinesite()
//             })
//         })
        
       
//     }
//     getSegmentDistributionAmmount(){
//         let selectedNodes = this.gridObj.api.getSelectedNodes();
//         let output = 0
//         if(selectedNodes.length >=1){
//            output = this.getAmount(selectedNodes[0].data[this.props.distributeKey]) 
//         }
//         return output;
//     }
//     validationCheck(data){
//         let configuration = [];
//         const gridHeder = this.settings.getDistributionHeader();
//         gridHeder.forEach(headerItem => {
//             if(headerItem.validationRule){
//                 configuration.push(
//                     {
//                         name:headerItem.field,
//                         displayName:headerItem.headerName,
//                         ...headerItem.validationRule
//                     }
                    
//                 )
//             }
//         })
//         let validator = new FormValidator(configuration);
//         if(validator.isValid(data)){
//             return true;
//         }
//         return false;
//     }
//     onRowValueChanged(event){
//         let dataItem = event.data;
//         this.totalApiReq = 1;
//         if(!this.validationCheck(dataItem)){
//             return;
//         }
//         if(dataItem[this.uniqueIdForTable]){
//             this.update(dataItem);
//         }else{
//             this.createNew(dataItem);
//         }
//     }
//     onCreateFirstDistribution(distribution_source_id){
//         let selectedData = this.state.activePoLineSite.data;
//         let activePoLineSite = this.state.activePoLineSite;
//         let newData = {
//             distributionsource_id:distribution_source_id
//         }
//         let that = this , api = Api;
//         api.setUserToken();
//         api.axios().put( this.settings.getApiUrl().update +'/'+selectedData[this.uniqueIdForTable]+'/'+this.props.source_id,newData).then( res=> {
//             activePoLineSite.setData(
//                 {
//                     ...selectedData,
//                     distributionsource_id:distribution_source_id
//                 }
//             );
//             that.setState({
//                 activePoLineSite:activePoLineSite
//             })
//         }).catch(error => {
            
//         })
//     }
//     // row segment start
//     appendSegmentDistributionInRow(headerData){
        
//         return headerData;
//     }
//     loadSegmentsBySource(distribution_source_id , apiCallback,errorCallback = null){
//         if(!distribution_source_id){
//             if(errorCallback){
//                 errorCallback()
//             }
//             return;
//         }
//         let that = this;
//         that.setState({
//             //isLoading:true
//         })
//         let api = Api;
//         api.setUserToken();
//         api.axios().get(Settings.apiUrls.segments+'/distribution_detail_by_source/'+distribution_source_id).then( res => {
//             apiCallback(res)
//         }).catch(errro =>{
//             if(errorCallback){
//                 errorCallback(errro)
//             }
//         })
//     }
    
//     getSegmentNameFromValue(valueIds){
//         if(!Helper.getNullableValue(valueIds)){
//             return "";
//         }
//         let valueIdAr = valueIds.split("|");
//         let allSegmentsValues = this.props.dataLoaderProps.segmentsValues.segmentsValues;
//         let output = '';
//         valueIdAr.forEach( valueId => {
//             allSegmentsValues.forEach(value =>{
//                 if(value.segmentvalue_id == valueId){
//                     if(output===''){
//                         output = value.code;
//                     }else{
//                         output += '-'+value.code;
//                     }
//                 }
                
//             })
//         })
//         return output;
//     }
//     addButtonsAfterDeleteButon(){
//         if(this.props.viewType == 'amendment'){
//             if(this.props.disableDecreaseAmendment){
//                 return(
//                     <p class="rs_h_controller  ctl-dissable"><img src="/images/icons/pen-gray-with-iocon.svg" alt="Decrease amendment"/><span>{Helper.getLabel(null,'decrease_amendment','Decrease amendment')}</span></p>
//                 ) 
//             }
//             return(
//                 <p class="rs_h_controller" onClick={ this.onClickAmendmentButton.bind(this) }><img src="/images/icons/pen-gray-with-iocon.svg" alt="Decrease amendment"/><span>{Helper.getLabel(null,'decrease_amendment','Decrease amendment')}</span></p>
//             )
//         }
//     }
//     onClickAmendmentButton(){
        
//         this.setState({
//             amendmentPopupShow:true
//         })
//     }
//     onCloseSegmentSitePopup(){
//         this.setState({
//             amendmentPopupShow:false
//         })
//     }
//     render() {
//         if(this.getSettingsError()){
//             return <Alert type="danger" message={this.getSettingsError()}  />
//         }
//         if(this.state.isLoading){
//             return <div style={{minHeight:'300px'}}><SimpleLoading/></div>
//         }
//         let that = this;
//         let language = null;
//         let PurchaseOrderLinesInnerNavs = [
//             {title:Helper.getLabel(language,'general','General'), isActive: true,forceLoadContents:true}, 
//             {title:Helper.getLabel(language,'addtional_info','Additional Information'),isDisable: !this.hasActivePoLineSite(),forceLoadContents:true}, 
//             {title:Helper.getLabel(language,'segment','Segment'),isDisable: !this.hasActivePoLineSite(),forceLoadContents:true},  
//         ]
//         if(that.props.shipmentType==="invoice"){
//             PurchaseOrderLinesInnerNavs = PurchaseOrderLinesInnerNavs.filter((item)=>item.title!==Helper.getLabel(language,'addtional_info','Additional Information'))
//         }
//         let lineViewTabs = PurchaseOrderLinesInnerNavs.filter((item)=>item.title===Helper.getLabel(language,'general','General'))
//         let employeeList = this.props.dataLoaderProps.employee.all.map( itemObj => {
//             return {
//                 value: itemObj.employee_id,
//                 label: `${itemObj.code} - ${itemObj.display_name}`
//             }
//         });
        
//         let advsSettings = {
//             detailRowAutoHeight: true,
//             masterDetail: true,
//             singleClickEdit:false,
//             cellRenderer: 'agGroupCellRenderer',
//             //detailCellRenderer: GridSegmentDistributionDetails,
//             detailCellRendererParams: {
//                 refreshStrategy: 'everything',
//                 detailGridOptions: {
//                     rowSelection: 'single',
//                     columnDefs: [
//                         { 
//                             field: 'segmentcombination_value' , headerName: Helper.getLabel(null,'segments','Segments'),
//                             cellRenderer: 'agGroupCellRenderer',
//                             valueFormatter: params => { return this.getSegmentNameFromValue(params.value)}
//                         },
//                         { field: 'percentage' , headerName:Helper.getLabel(null,'percentage','Percentage') ,valueFormatter: params => { return Helper.getOnlyNumber(params.value)}},
//                         { field: 'amount' , headerName:Helper.getLabel(null,'amount','Amount'),valueFormatter: params => { return Helper.getOnlyNumber(params.value)}}
//                     ],
//                     defaultColDef: {
//                             sortable: true,
//                             flex: 1,
//                     },
//                     detailRowAutoHeight: true,
//                     masterDetail: true,
//                     detailCellRenderer: GridSegmentDistributionDetails,
//                 },
//                 getDetailRowData: params => {
//                     that.loadSegmentsBySource(params.data.distributionsource_id ? params.data.distributionsource_id : null, function(res){
//                         params.successCallback(res.data.data);
//                     },function(){
//                         params.successCallback([]);
//                     })
//                 }
//             }
//             /*
//             onCellKeyDown: params => {
//                 let alreadyDistribution = (this.getTotalDistributionValue() - Helper.getOnlyNumber(params.value));
//                 if( (alreadyDistribution + Helper.getOnlyNumber(params.event.target.value) ) > this.props.distributeValue ){
//                     let maximumValueExist = this.props.distributeValue - alreadyDistribution;
//                     if(maximumValueExist<0)
//                         maximumValueExist = 0
//                     params.event.target.value = maximumValueExist;
//                 }
//             },
//             */
//         }
//         let PoLinesiteData = this.state.PoLinesite;
//         let gridHederObj = this.getGridHeaders();
//         let seg_distribution_source_id = null;
//         let segment_integrator = null;
//         let activeSite = null;
//         if(this.hasActivePoLineSite()){
//             activeSite = this.state.activePoLineSite.data;
//             segment_integrator = this.state.activePoLineSite.data.linesite_id;
//             seg_distribution_source_id = this.state.activePoLineSite.data.distributionsource_id;
//         }

//         return (
//             <Tabs  id='rsListViewGridDistribution' tabContantsId="rsListViewGridDistributionContents" navs={this.props.hideDistributionInnerTabs?lineViewTabs:PurchaseOrderLinesInnerNavs} disableSticky={true}>
                
//                 <div  className='rs_distribution_grid_wraper'>
//                     { this.state.amendmentPopupShow ? <AmendmentSitePopup  {...this.props} po_line_id={this.state.po_line_id} amendment_id = { this.props.amendment_id ? this.props.amendment_id : null }  onClose={this.onCloseSegmentSitePopup.bind(this)} /> :  ''}
//                     <AgGridMemo
//                         afterDeleteButtons = { this.addButtonsAfterDeleteButon.bind(this)}
//                         readOnlyGrid={this.props.viewType !=="invoice"&&!this.props.isEditable}
//                         viewOnly={this.props.viewType ==="invoice"&& !this.props.isEditable}
//                         onRowValueChanged ={ this.onRowValueChanged.bind(this)}
//                         onDelete={ this.lineSiteDeleteHander.bind(this)}
//                         onRowEditingStopped ={this.props.viewType==="invoice" || this.props.viewType==="shipment"?null:this.rowEditStophandler.bind(this)} 
//                         settings={advsSettings} 
//                         singleRowSelect={true} 
//                         onGridReady={ (grid) => { this.gridObj = grid }} 
//                         onSelectionChanged = { this.onSelectionChanged.bind(this)} 
//                         forceRender={this.state.forceRender} 
//                         onSave={ this.onSaveHandler.bind(this) }  
//                         data={PoLinesiteData} 
//                         id={this.props.gridId ? this.props.gridId : "polvdg_grid1"} 
//                         header={gridHederObj} 
//                         hideSave={true}
//                         allBtnHide={this.props.shipmentType==="invoice"}
//                     />
//                 </div>
//                 {that.props.shipmentType==="invoice"?        
//                     this.hasActivePoLineSite() ? <SegmentsDistribution isEditable={this.props.isEditable} id="poline_segment_distribution" onCreateFirstDistribution={this.onCreateFirstDistribution.bind(this)} distribution_source_id={seg_distribution_source_id} integrator={segment_integrator} source_id ={this.props.segment_distribution_source_id} totalAmount = {this.getSegmentDistributionAmmount()} /> : ''
//                 : <div>
//                <DistributionAditionalInformation 
//                         settings = {this.settings}
//                         uniqueIdForTable = {this.uniqueIdForTable}
//                         employeeList = {employeeList}
//                         update={this.update.bind(this)}  
//                         create={this.createNew.bind(this)} 
//                         activePoLineSite={this.state.activePoLineSite} 
//                         grid={this.gridObj}
//                         isEditable = {this.props.isEditable}
//                 />
//                 </div>}
//                 <div>
//                     {
//                         this.hasActivePoLineSite() ? <SegmentsDistribution isEditable={this.props.isEditable} id="poline_segment_distribution" onCreateFirstDistribution={this.onCreateFirstDistribution.bind(this)} distribution_source_id={seg_distribution_source_id} integrator={segment_integrator} source_id ={this.props.segment_distribution_source_id} totalAmount = {this.getSegmentDistributionAmmount()} /> : ''
//                     }
                    
//                 </div>
//             </Tabs>
//         );
//     }
// }

// export default RsDataLoader (DistributionTab,{ segments:true,segmentsValues:true,employee:true});

import React, { useState, useEffect, useRef, useCallback } from 'react'
import Helper from '../../../../inc/Helper'
import SegmentsDistribution from '../../../Segments/SegmentsDistribution'
import Tabs from '../../../Widgets/Tabs/Tabs'
import DistributionAditionalInformation from './DistributionAditionalInformation'
import Api from '../../../../inc/Api'
import Settings from '../../../../inc/Settings'
import AgGridMemo from '../../../grid/ag/AgGridMemo'
import Alert from '../../../inc/Alert'
import FormValidator from '../../../../inc/FormValidator'
import GridSegmentDistributionDetails from '../../../Segments/GridSegmentDistributionDetails'
import RsDataLoader from '../../../inc/RsDataLoader'
import SimpleLoading from '../../../Loading/SimpleLoading'
import AmendmentSitePopup from './AmendmentSitePopup'
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation'
import AgGridNew from '../../../grid/ag/ag-grid-new'
import Collapse from '../../../inc/Collapse'

let gridObj = null

const DistributionTab = (props) => {
  const [amendmentPopupShow, setAmendmentPopupShow] = useState(false)
  const [activePoLineSite, setActivePoLineSite] = useState(null)
  const [forceRender, setForceRender] = useState(false)
  const [po_line_id, setPoLineId] = useState(null)
  const [PoLinesite, setPoLinesite] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  let totalApiReq = 0
  let settings = props.settings ? props.settings : null
  let uniqueIdForTable = settings ? settings.getIdKey() : 'linesite_id'
  let isSegmentsLoaded = false
  let segment_integrator = activePoLineSite?.data?.linesite_id
  let seg_distribution_source_id = activePoLineSite?.data?.distributionsource_id

  let employeeList = props.dataLoaderProps.employee.all.map((itemObj) => {
    return {
      value: itemObj.employee_id,
      label: `${itemObj.code} - ${itemObj.display_name}`,
    }
  })

  useEffect(() => {
    setPoLineId(props.po_line_id ? props.po_line_id : null)
    loadPoLinesite()
  }, [props.po_line_id])

  const onSelectionChanged = (event) => {
    let selectedNodes = event.api.getSelectedNodes()
    if (selectedNodes.length <= 0) {
      setActivePoLineSite(null)
    } else {
      setActivePoLineSite(selectedNodes[0])
    }
  }

  const loadPoLinesite = () => {
    let apiUrl =
      settings.getApiUrl().get + '/' + props.po_line_id + '/' + props.source_id
    if (!props?.po_line_id) {
      setPoLinesite([])
      setIsLoading(false)
      return
    }
    setIsLoading(true)
    if (props.viewType === 'shipment') {
      apiUrl = Settings.apiPurchaseOrderUrl + '/SH_linesites/' + po_line_id
    }
    Api.setUserToken()
    Api.axios()
      .get(apiUrl)
      .then((res) => {
        let poLineSiteData = res.data.data
        setPoLinesite(poLineSiteData)
        setIsLoading(false)
      })
      .catch((error) => {
        setPoLinesite([])
        setIsLoading(false)
      })
  }

  const createNew = (data) => {
    let newData = getDataForDb(data)
    Api.setUserToken()
    Api.axios()
      .post(settings.getApiUrl().create, newData)
      .then((res) => {
        totalApiReq--
        if (totalApiReq <= 0) {
          Helper.alert(res.data.message)
          loadPoLinesite()
        }
      })
      .catch((error) => {
        totalApiReq--
        if (totalApiReq <= 0) {
          getPopupMessageBasedOnStatus(error)
        }
      })
  }

  const update = (data) => {
    let newData = getDataForDb(data)
    let updateUrl =
      settings.getApiUrl().update +
      '/' +
      data[uniqueIdForTable] +
      '/' +
      props.source_id
    if (props.viewType === 'shipment') {
      updateUrl =
        Settings.apiPurchaseOrderUrl + '/SH_linesite/' + data[uniqueIdForTable]
      newData = {
        received_qty: Helper.getOnlyNumber(newData.received_qty),
        line_amount: null,
      }
    }
    if (props.viewType === 'invoice') {
      newData = {
        received_qty: Helper.getOnlyNumber(newData.received_qty),
        line_amount: null,
      }
    }
    Api.setUserToken()
    Api.axios()
      .put(updateUrl, newData)
      .then((res) => {
        totalApiReq--
        if (totalApiReq <= 0) {
          Helper.alert(res.data.message)
        }
        if (props.onRowValueUpdated) {
          props.onRowValueUpdated(data)
        }
      })
      .catch((error) => {
        totalApiReq--
        if (totalApiReq <= 0) {
          getPopupMessageBasedOnStatus(error)
        }
      })
  }

  const getDataForDb = (data) => {
    let filterData = {}
    if (data?.[settings.idKey]) {
      filterData = settings?.filterPutData(data)
    } else {
      filterData = settings?.filterPostData(data)
    }
    return {
      source_id: props.source_id,
      line_id: props?.po_line_id ? props?.po_line_id : null,
      ...filterData,
      [uniqueIdForTable]: data[uniqueIdForTable]
        ? data[uniqueIdForTable]
        : null,
    }
  }

  const lineSiteDeleteHandler = useCallback(async (element) => {
    let api = Api
    api.setUserToken();
    try {
      const res = await api
        .axios()
        .delete(  settings.getApiUrl().delete + '/' + element?.data[uniqueIdForTable] +  '/' +  props.source_id)
     const rowNode = gridObj?.api?.getRowNode(element?.data[uniqueIdForTable])
     if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridObj?.api?.applyServerSideTransaction(transaction)
      }, 200)
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])

  const getSegmentNameFromValue = (valueIds) => {
    if (!Helper.getNullableValue(valueIds)) {
      return ''
    }
    let valueIdAr = valueIds.split('|')
    let allSegmentsValues = props.dataLoaderProps.segmentsValues.segmentsValues
    let output = ''
    valueIdAr.forEach((valueId) => {
      allSegmentsValues?.forEach((value) => {
        if (value.segmentvalue_id == valueId) {
          if (output === '') {
            output = value.code
          } else {
            output += '-' + value.code
          }
        }
      })
    })
    return output
  }

  const getAmount = (orderQuantityArg) => {
    let orderQuantity = Helper.getOnlyNumber(orderQuantityArg)
    let lineOrderQuantity = Helper.getOnlyNumber(props.distributeValue)
    let unitPrice = Helper.getOnlyNumber(props.lineAmount) / lineOrderQuantity
    return unitPrice * orderQuantity
  }

  const hasActivePoLineSite = () => {
    if (activePoLineSite && activePoLineSite.data[uniqueIdForTable]) {
      return true
    }
    return false
  }



  const loadSegmentsBySource = (
    distribution_source_id,
    apiCallback,
    errorCallback = null
  ) => {
    if (!distribution_source_id) {
      if (errorCallback) {
        errorCallback()
      }
      return
    }
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(
        Settings.apiUrls.segments +
          '/distribution_detail_by_source/' +
          distribution_source_id
      )
      .then((res) => {
        apiCallback(res)
      })
      .catch((errro) => {
        if (errorCallback) {
          errorCallback(errro)
        }
      })
  }

  const onRowValueChanged = (event) => {
    let dataItem = event.data
    totalApiReq = 1
    if (dataItem[uniqueIdForTable]) {
      update(dataItem)
    } else {
      createNew(dataItem)
    }
  }

  const getDefaultDistributedValue = () => {
    let maxValue = Helper.getOnlyNumber(props.distributeValue)
    let distributedQuanity = 0
    gridObj?.api?.forEachNode((node) => {
      distributedQuanity += Helper.getOnlyNumber(node.data[props.distributeKey])
    })
    return maxValue - distributedQuanity
  }
  const getDistributionMaxValue = (oldValue, params) => {
    let maxValueAllow = Helper.getOnlyNumber(props.distributeValue)
    let valueUsed = 0
    params?.api?.forEachNode((node) => {
      if (node.rowIndex != params.rowIndex) {
        valueUsed += Helper.getOnlyNumber(node.data[props.distributeKey])
      }
    })
    valueUsed += Helper.getPositiveNumber(oldValue)
    if (valueUsed > maxValueAllow) {
      maxValueAllow =
        maxValueAllow - (valueUsed - Helper.getPositiveNumber(oldValue))
    }
    return maxValueAllow
  }
  const onDistributedAmountChange = (event, params) => {
    let quantityUnitPrice = props.lineAmount / props.distributeValue
    let newAmount = quantityUnitPrice * event?.target?.value
    if (props.viewType === 'shipment' || props.viewType === 'invoice') {
      if (parseInt(event.target.value) > parseInt(props.remaining_qty)) {
        event.target.value = props.remaining_qty
        params.node.setDataValue('received_qty', event.target.value)
      }
      params.node.setDataValue('received_qty', params.data.received_qty)
      params?.api?.refreshCells({
        rowNodes: [params.node],
        columns: ['received_qty', 'amount'],
      })
    }

    params?.node?.setDataValue('amount', newAmount)
  }

  const getGridHeaders = () => {
    let gridHeder = settings.getDistributionHeader()
    let additionalFields = settings.getAdditionalFields()
    additionalFields.forEach((adsField) => {
      let isFind = gridHeder.find((item) => {
        return item.field === adsField.name
      })
      if (!isFind) {
        gridHeder.push({ field: adsField.name, hide: true })
      }
    })
    let isAmmountFind =
      props.shipmentType === 'invoice' && props.gridType === 'contract'
        ? true
        : gridHeder.find((item) => {
            return item.field === 'amount'
          })
    if (!isAmmountFind) {
      gridHeder.push({
        field: 'amount',
        headerName: Helper.getLabel(null, 'amount', 'Amount'),
        editable: false,
        valueFormatter: (params) => {
          return Helper.formateCurrency(
            getAmount(params.data[props.distributeKey])
          )
        },
      })
    }
    let finalHeaderAr = []
    gridHeder.forEach((headerItem, itemKey) => {
      if (itemKey === 0) {
        headerItem = {
          ...headerItem,
          cellRenderer: 'agGroupCellRenderer',
        }
      }
      if (headerItem.field == props.distributeKey) {
        headerItem.defaultValue = props.viewType==="invoice" || props.viewType==="shipment"?1:()=>{return getDefaultDistributedValue()};
        if(props.viewType!=="invoice" && props.viewType!=="shipment"){
          headerItem.maxValue = (oldValue,params)=>{return getDistributionMaxValue(oldValue,params)};
        }
        headerItem.valueFormatter = (params) => {return params?.data?.isNew ? getDefaultDistributedValue()  : params.value}
        headerItem.onChange = onDistributedAmountChange;
      }
      if(!props.isEditable && headerItem.field!=="received_qty"){
        headerItem.editable = false
      }
      // if(headerItem.field === "received_qty"){
      //   headerItem.editable = props.canShipmentEdit
      // }
      finalHeaderAr.push(headerItem)
    })
    return finalHeaderAr
  }

  const getDistributionTotal = () => {
    let gridData = Helper.getDataFromGrid(gridObj)
    let totalDistribution = 0
    gridData.forEach((data) => {
      totalDistribution += Number.parseFloat(data[props.distributeKey])
    })
    return totalDistribution
  }

  const onCreateFirstDistribution = (distribution_source_id) => {
    let selectedData = activePoLineSite.data
    // let activePoLineSite = activePoLineSite
    let newData = {
      distributionsource_id: distribution_source_id,
    }
     let api = Api
    api.setUserToken()
    api
      .axios()
      .put(
        settings.getApiUrl().update +
          '/' +
          selectedData[uniqueIdForTable] +
          '/' +
          props.source_id,
        newData
      )
      .then((res) => {
        activePoLineSite.setData({
          ...selectedData,
          distributionsource_id: distribution_source_id,
        })
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error)
      })
  }

  const getSegmentDistributionAmount = () => {
    let selectedNodes = gridObj.api.getSelectedNodes()
    let output = 0
    if (selectedNodes?.length >= 1) {
      output = getAmount(selectedNodes[0]?.data[props.distributeKey])
    }
    return output
  }
  let PurchaseOrderLinesInnerNavs = [
    {
      title: Helper.getLabel(props.language, 'general', 'General'),
      isActive: true,
      forceLoadContents: true,
    },
    {
      title: Helper.getLabel(
        props.language,
        'addtional_info',
        'Additional Information'
      ),
      isDisable: !hasActivePoLineSite(),
      forceLoadContents: true,
    },
    {
      title: Helper.getLabel(props.language, 'segment', 'Segment'),
      isDisable: !hasActivePoLineSite(),
      forceLoadContents: true,
    },
  ]
  if (props.shipmentType === 'invoice') {
    PurchaseOrderLinesInnerNavs = PurchaseOrderLinesInnerNavs.filter(
      (item) =>
        item.title !==
        Helper.getLabel(
          props.language,
          'addtional_info',
          'Additional Information'
        )
    )
  }
  let lineViewTabs = PurchaseOrderLinesInnerNavs.filter(
    (item) =>
      item.title === Helper.getLabel(props.language, 'general', 'General')
  )

  let advsSettings = {
    detailRowAutoHeight: true,
    masterDetail:props?.userType===1 ? false : true,
    cellRenderer: 'agGroupCellRenderer',
    detailCellRendererParams: {
      refreshStrategy: 'everything',
      detailGridOptions: {
        rowSelection: 'single',
        columnDefs: [
          {
            field: 'segmentcombination_value',
            headerName: Helper.getLabel(null, 'segments', 'Segments'),
            cellRenderer: 'agGroupCellRenderer',
            valueFormatter: (params) => {
              return getSegmentNameFromValue(params.value)
            },
          },
          {
            field: 'percentage',
            headerName: Helper.getLabel(null, 'percentage', 'Percentage'),
            valueFormatter: (params) => {
              return Helper.getOnlyNumber(params.value)
            },
          },
          {
            field: 'amount',
            headerName: Helper.getLabel(null, 'amount', 'Amount'),
            valueFormatter: (params) => {
              return Helper.getOnlyNumber(params.value)
            },
          },
        ],
        defaultColDef: {
          sortable: true,
          flex: 1,
        },
        detailRowAutoHeight: true,
        masterDetail: true,
        detailCellRenderer: GridSegmentDistributionDetails,
      },
      getDetailRowData: (params) => {
        loadSegmentsBySource(
          params.data.distributionsource_id
            ? params.data.distributionsource_id
            : null,
          function (res) {
            params.successCallback(res.data.data)
          },
          function () {
            params.successCallback([])
          }
        )
      },
    },
  }
  return (
    <Tabs
      id='rsListViewGridDistribution'
      tabContantsId='rsListViewGridDistributionContents'
      navs={
        props.hideDistributionInnerTabs
          ? lineViewTabs
          : PurchaseOrderLinesInnerNavs
      }
      disableSticky={true}
    >
      <div>
        {amendmentPopupShow ? (
          <AmendmentSitePopup
            {...props}
            po_line_id={po_line_id}
            amendment_id={props.amendment_id ? props.amendment_id : null}
            onClose={() => setAmendmentPopupShow(false)}
          />
        ) : (
          ''
        )}
        {isLoading ? (
          <SimpleLoading />
        ) : (
          //  <AgGridMemo
          //     readOnlyGrid={props.viewType !== 'invoice' && !props.isEditable}
          //     viewOnly={props.viewType === 'invoice' && !props.isEditable}
          //     onRowValueChanged={onRowValueChanged}
          //     onDelete={lineSiteDeleteHandler}
          //     onRowEditingStopped={props.viewType === 'invoice' || props.viewType === 'shipment' ? null : rowEditStophandler}
          //     settings={advsSettings}
          //     singleRowSelect={true}
          //     onGridReady={(grid) => (gridObj = grid)}
          //     onSelectionChanged={onSelectionChanged}
          //     forceRender={forceRender}
          //     onSave={onSaveHandler}
          //     data={PoLinesite}
          //     id={props.gridId ? props.gridId : 'polvdg_grid1'}
          //     header={getGridHeaders()}
          //     hideSave={true}
          //     hideRefresh={true}
          //     isLoading={isLoading}
          //     onNewClick={() => {
          //         setAmendmentPopupShow(true);
          //     }}
          // />
          <div className='mb-5'>
          <AgGridNew
            gridId={props.gridId ? props.gridId : 'polvdg_grid1'}
            masterSettings={advsSettings}
            apiUrl={ settings.getApiUrl().get + '/' +  props.po_line_id +  '/' + props.source_id }
            columnDefs={getGridHeaders()}
            onSelectionChanged={onSelectionChanged}
            onGridReady={(grid) => (gridObj = grid)}
            rowType={'single'}
            onRowValueChanged={onRowValueChanged}
            handleDeleteSelectedRows={lineSiteDeleteHandler}
            uniqueField={uniqueIdForTable}
            isAddBtnDisable={props.viewType !== 'invoice' && !props.isEditable}
            isDeleteBtnDisable={props.viewType !== 'invoice' && !props.isEditable}
            hideAddBtn={props.hideAddBtn}
            hideDeleteBtn={props.hideDeleteBtn}
          />
          </div>
        )}
        {props.showDistributionInfo ? <Collapse open={true} title={Helper.getLabel(props.language,'additional_information','Additional Information')}><DistributionAditionalInformation /></Collapse> : ''}
      </div>
      {props.shipmentType === 'invoice' ? (
        hasActivePoLineSite() && (
          <div className='mb-5'>
          <SegmentsDistribution
            isEditable={props.isEditable}
            id='poline_segment_distribution'
            onCreateFirstDistribution={onCreateFirstDistribution}
            distribution_source_id={seg_distribution_source_id}
            integrator={segment_integrator}
            source_id={props.segment_distribution_source_id}
            totalAmount={getSegmentDistributionAmount}
          />
          </div>
        ) 
      ) : (
        <div style={{height:"320px"}}>
          <Collapse open={true} className={'line-view-additional-distribution-collapse'} title={Helper.getLabel(props.language,'additional_information','Additional Information')}>
           <DistributionAditionalInformation
             settings={settings}
             uniqueIdForTable={uniqueIdForTable}
             employeeList={employeeList}
             update={update}
             create={createNew}
             activePoLineSite={activePoLineSite}
             grid={gridObj}
             isEditable={props.isEditable}
           />
          </Collapse>
        </div>
      )}
      <div>
        {hasActivePoLineSite() ? (
          <div className='mb-5'>
          <SegmentsDistribution
            isEditable={props.isEditable}
            id='poline_segment_distribution'
            onCreateFirstDistribution={onCreateFirstDistribution}
            distribution_source_id={seg_distribution_source_id}
            integrator={segment_integrator}
            source_id={props.segment_distribution_source_id}
            totalAmount={getSegmentDistributionAmount()}
            accountingDate={props.segmentAccountingDate}
          />
          </div>
        ) : (
          ''
        )}
      </div>
    </Tabs>
  )
}

export default RsDataLoader(DistributionTab, {
  segments: true,
  segmentsValues: true,
  employee: true,
})
