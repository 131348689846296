import React, { Fragment, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import MasterComonent from '../../../Backend/MasterComonent'
import Settings from '../../../../inc/Settings'
import { connect } from 'react-redux'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import Helper from '../../../../inc/Helper'
import Button from '../../../inc/Button'
import Tabs from '../../../Widgets/Tabs/Tabs'
import MasterSidebar from '../../../Sidebars/MasterSidebar'
import NoteAttachmentHistory from '../../../Widgets/NoteAttachmentHistory'
import LineViewComponent from '../../../Purchase Orders Internal/Edit PO/ListView/LineViewComponent'
import WorkConfirmationApiCalls from '../../Work Confirmation/CreateWorkConfirmation/NewWorkConfirmation/WorkConfirmationApiCalls'
import TotalWidget from '../../../Widgets/TotalWidget/TotalWidget'
import Collapse from '../../../inc/Collapse'
import AdditionalInformation from '../../RFQ Vendor Reply/RFQBiddingDetails/AdditionalInformation'

//for render tabs data
const GoodsReceivingData = (props) => {
  //data for grid and tabs
  const defaultNav = [
    {
      title: Helper.getLabel(props.language, 'hide_tab', 'hide_tab'),
      isActive: true,
    },
  ]

  const innerTabs = [
    {
      title: Helper.getLabel(
        props.language,
        'general_information',
        'General Information'
      ),
      isActive: true,
    },
    {
      title: Helper.getLabel(
        props.language,
        'additional_information',
        'Additional Information'
      ),
    },
  ]

  let generalData = [
    {
      id: 1,
      title: 'Document number',
      subtitle: props.goodsReceivingDetailsData?.document_number,
    },
    {
      id: 2,
      title: 'Status',
      subtitle: props.document_status,
      className:props.document_status.toLowerCase()==='posted'?`status-style success-style`:`status-style draft-style`
    },
    {
      id: 3,
      title: 'Vendor code',
      subtitle: props.goodsReceivingDetailsData?.vendor_code,
    },
    {
      id: 4,
      title: 'Goods receipt number',
      subtitle: props.goodsReceivingDetailsData?.receiving_reference_number,
    },
    {
      id: 5,
      title: 'Vendor name',
      subtitle: props.goodsReceivingDetailsData?.vendor_name,
    },
    {
      id: 6,
      title: 'Accounting date',
      subtitle: Helper.formateDate(
        props.goodsReceivingDetailsData?.accounting_date
      ),
    },
    {
      id: 7,
      title: 'Vendor site',
      subtitle: props.goodsReceivingDetailsData?.vendorsite_name,
    },
    {
      id: 8,
      title: 'Document date',
      subtitle: Helper.formateDate(
        props.goodsReceivingDetailsData?.document_date
      ),
    }
  ]

  if(props?.usertype===1){
    generalData = generalData?.filter((item)=>item.title !== 'Accounting date')
  }

  //for add right sidebar
  const addComponentRight = () => {
    return (
      <MasterSidebar>
        <div style={{ background: '#fff' }}>
          <NoteAttachmentHistory
            notesource_integrator={props.shipment_id}
            hideHistory={true}
            attachmentsource_integrator={props.shipment_id}
            source_id={Settings.source_id.ShipmentGoodReceivingWorkConfirmation}
          />
        </div>
      </MasterSidebar>
    )
  }

  return (
    <Tabs
      navs={defaultNav}
      showAll={true}
      scrollOffset="300"
      disableSticky={true}
      addComponentRight={addComponentRight}
    >
      <Fragment>
        <div className="bidding_tab mt-3">
          <div className="mt-1 header-tab-rfq">
            <Tabs
              navs={innerTabs}
              disableSticky={true}
              scrollOffset="300"
              showAll={false}
            >
              <div className="mt-3 ps-3 pe-3">
                <div className="external-general-information">
                  <div className="purchase-order-inner-details general-details-responsive">
                    {generalData.map((item) => (
                      <div
                        className="row justify-content-between"
                        key={item.id}
                      >
                        <div className="col-sm-6">
                          <span className="purchase-order-external-title">
                            {item.title} :
                          </span>
                        </div>
                        <div className="col-sm-6">
                          <span className={item.className}>{item.subtitle}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='ps-3 pe-3'>
              <Collapse
                  className="mt-3"
                  title={Helper.getLabel(
                    props.language,
                    "additional_information",
                    "Additional Information"
                  )}
                  open={true}
                >
                <AdditionalInformation language={props.language} data={props?.goodsReceivingDetailsData} isNeedNotes={false} title={Helper.getLabel(
                    props.language,
                    "description",
                    "Description"
                  )} />
                </Collapse>
              </div>  
            </Tabs>
          </div>
        </div>
        <div className="mt-3 external-read-only-grid line-grid-btns-common">
          <LineViewComponent
            defaultValues={{ requester_id: null }}
            source_id={Settings.source_id.ShipmentGoodReceivingWorkConfirmation}
            line_source={
              Settings.source_id.ShipmentGoodReceivingWorkConfirmation
            }
            isEditable={false}
            viewType="shipment"
            shipmentType="good_receiving"
            userType={props.usertype}
            canShipmentEdit={false}
            header_id={props.shipment_id}
            purchase_status={null}
            document_status={null}
            delivery_date={null}
            onbehalf_request={false}
            vendor_id={null}
            vendorsite_id={null}
            setOrderStatusProps={null}
            hideDistribution={false}
            hideGeneral={false}
            hideSetup={true}
            hideBillingRule={true}
            alternate_line={false}
            lineGridTitle={Helper.getLabel(props.language, 'lines', 'Lines')}
            hideDistributionInnerTabs={true}
            hideAddBtn={true}
            hideDeleteBtn={true}
            hideMoreBtns={true}
          />
        </div>
      </Fragment>
    </Tabs>
  )
}

const GoodsReceivingDetails = (props) => {
  const location = useLocation()
  const goodsReceivingApiCalls = new WorkConfirmationApiCalls()

  const [goodsReceivingDetails, setGoodsReceivingDetails] = useState({})
  const [documentStatuses, setDocumentStatuses] = useState([])
  const [documentStatus,setDocumentStatus]=useState('')

  //for fetch params from route
  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }

  const shipment_id = getSearchParam('shipment_id')
  const purchase_order_id = getSearchParam('purchaseorder_id')

  const navigate = useNavigate()

  useEffect(() => {
    if (!shipment_id) return
    const getShipmentHeaderById = async () => {
      const shipment_header =
        await goodsReceivingApiCalls.getAllShipmentHeaderById(shipment_id)
      const documentStatuses = await goodsReceivingApiCalls.getAllShDocumentStatus()
      if (shipment_header) setGoodsReceivingDetails(shipment_header)
      if(documentStatuses) setDocumentStatuses(documentStatuses)
    }
    getShipmentHeaderById()
  }, [])

  useEffect(() => {
    if (goodsReceivingDetails && goodsReceivingDetails.document_status !== undefined) {
      const document_status = documentStatuses?.find(
        (option) => option.id === goodsReceivingDetails.document_status
      );
      setDocumentStatus(document_status?.name);
    }
  }, [goodsReceivingDetails, documentStatus]);

  const documentName = location?.state?.type==='release order' ? 'Release' : 'Purchase'

  const handleEditButton = () => {
    navigate(`/goods-receiving/edit/${shipment_id}`,{state:{prevPath:"/details"}})
  }

  return (
    <div className={props.auth?.user?.usertype === 0
      ? 'work-confirmation-internal-screen external-detail-screens responsive-screen-style'
      : 'request_for_quotation_detail_screen external-detail-screens external-theme responsive-screen-style'}>
      <MasterComonent>
        <NavigationHeder
          // backUrl="/goods-receiving-list"
          hideMoreBtn={true}
          title={
            !purchase_order_id
              ? `<span style="color:#757575;">${Helper.getLabel(
                  props.language,
                  'good_receiving_details',
                  'Goods Receiving List'
                )} > </span> <span style="color:#313638;"> Goods Receiving Details # </span> ${
                  goodsReceivingDetails?.receiving_reference_number
                }`
              : `<span style="color:#757575;">${Helper.getLabel(
                  props.language,
                  'purchase_order_list',
                  `${documentName} Orders List`
                )} ></span> <span style="color:#313638">${documentName} Order Details : ${location?.state?.document_number || purchase_order_id} > Goods Receiving List > </span>  Goods Receiving Details : ${
                  goodsReceivingDetails?.receiving_reference_number
                }`
          }
        >
          <Button
            title={Helper.getLabel(
              props.language,
              'close',
              'Close'
            )}
            onClick={()=>{navigate(-1)}}
          />
          {goodsReceivingDetails?.document_status === 0 && (
            <Button
              onClick={handleEditButton}
              title={Helper.getLabel(props.language, 'edit', 'Edit')}
            />
          )}
          <TotalWidget
            source_id={goodsReceivingDetails?.source_id}
            integrator={goodsReceivingDetails?.shipment_id}
          ></TotalWidget>
          <Button
            title={Helper.getLabel(
              props.language,
              'invoices',
              'Invoices'
            )}
            onClick={()=>{navigate(`/invoice-list?receiving_id=${goodsReceivingDetails?.shipment_id}`)}}
            className='brown-btn-style'
          />
        </NavigationHeder>

        <div className='tabs_default internal-tabs'>
          {
            <GoodsReceivingData
              language={props.language}
              shipment_id={shipment_id}
              document_status={documentStatus}
              goodsReceivingDetailsData={goodsReceivingDetails}
              setGoodsReceivingDetailsData={setGoodsReceivingDetails}
              usertype={props.auth?.user?.usertype}
            />
          }
        </div>
      </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(GoodsReceivingDetails)
