import React, { Component } from 'react';
import Helper from '../../../inc/Helper';

class NavigationSteps extends Component {
    getAllSteps(){
        return[
            {label:'General Information'},
            {label:'Contact Information'},
            {label:'Contact Person'},
            {label:'Business Classification'},
            {label:"Bank Details"},
            {label:'Procurement Category'},
            {label:'Documents'},
            {label:'Terms & Condition'},
            {label:'Finish'}
        ]
    }
    goStep(className,stepNumber){
        if(stepNumber===9) return
        if(this.props?.data?.vendorsite_id === null || this.props?.data?.vendorsite_id === undefined){
            Helper.alert("Please fill mandatory fields of general information to move forward.","error")
            return;
        }
        this.props.goStep(stepNumber);
    }
    render() {
        let stepNumber = this.props.activeStep ? parseInt(this.props.activeStep) : 1;
        let allSteps = this.getAllSteps();
        let completedSteps = this.props.completedSteps || []
        return (
            <div className='rs_navigation_step'>
                <div className='rs_navigation_step_inner'>
                    { allSteps.map( (step,key) => {
                        let currentStep = key + 1;
                        let className = ""
                        if(completedSteps[key]){
                            className='success'
                        } else if(stepNumber===currentStep){
                            className="active"
                        }
                        return (<div key={currentStep} className={'step ' + className} onClick={ e => {this.goStep(className,currentStep)} }><span className='st_number'>{ className=='success' ? <img src="/images/icons/check-green.svg" alt="Completed" /> : currentStep}</span><span className='st_label'>{step.label}</span></div>)
                    } )}
                </div>
            </div>
        );
    }
}

export default NavigationSteps;