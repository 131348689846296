import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react'; 
import $ from 'jquery'; 
import FormValidator from '../../../../inc/FormValidator';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings';
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation';
import { connect } from 'react-redux';
import Helper from '../../../../inc/Helper';
import Button from '../../../inc/Button';
import { Form } from 'react-bootstrap';
import FieldsRenderer from '../../../Purchase Orders Internal/Edit PO/FieldsRenderer';
import OverlayLoader from '../../../PurchaseRequisition/common/OverlayLoader';
import Popup from '../../../Popup/Popup';
import Collapse from '../../../inc/Collapse';

let cancelTokenSource = axios.CancelToken.source();
const VendorBusinessClassModal = ({setShowCreateModal, vendor_business_id=null, reloadParentGrid, language, attachments}) => {  
    const [formData, setFormData] = useState({}); 

    const [apiCallBtn, setApiCallBtn] = useState(false);
     
    let formValidatory;   

    const validationConfigure = () => {
        let formFieldConfig = [  
            {name:'name', displayName:Helper.getLabel(language,'name','Name'),types:['Required']}
        ];
      
        if(!formValidatory) {
            formValidatory = new FormValidator(formFieldConfig, language)
        }; 
    };

    validationConfigure();

    let vendorBusinessClassCalled = false; 

    useEffect(() => {
        if(!vendor_business_id) {
            setFormData({});
            return;
        }
        getVendorBusinessClass(); 
    }, []);

    const getVendorBusinessClass = () => {
        if(vendorBusinessClassCalled) return;
        vendorBusinessClassCalled = true;

        setApiCallBtn(true);
        let api = Api 
        api.setUserToken()
        api.axios().get(Settings.apiVendorUrl+`/businessclassification/${vendor_business_id}`,{}).then(function(res){
          if(res.data.status==="success"){   
            setFormData(res?.data?.data);
            setApiCallBtn(false);
          }
        }).catch((res) => {
            setApiCallBtn(false);
            setFormData({});
        })
    };
 
    const fieldsArrayGeneral = [
        {className:'col-lg-4', maxLength: 50, label: Helper.getLabel(language,'name',"Name"), required: true, inputType: "text", id:'name', name:'name'},
        {className:'col-lg-8', maxLength: 255, label: Helper.getLabel(language,'description',"Description"), inputType: "text", id:'description', name:'description'},
        {className:'col-lg-4', label: Helper.getLabel(language,'attachment_mandatory_for_vendor',"Attachment mandatory for vendor"), inputType: "checkbox", id:'attachment_mandatory_for_vendor', name:'attachment_mandatory_for_vendor'},
        {className:'col-lg-4', label: Helper.getLabel(language,'attachment_type',"Attachment type"), inputType: "dropdown", id:'attachmenttype_id', name:'attachmenttype_id', options:attachments, reRenderRequired:true},
    ];
 
    const executePOSTAPI = async () => {
        try { 
            let api = Api;
            api.setUserToken();
            
        
            const res = await api.axios().post(Settings.apiVendorUrl + '/businessclassification',{...formData,attachment_mandatory_for_vendor:formData?.attachment_mandatory_for_vendor=="on"},
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res?.data?.message); 
                reloadParentGrid();
                onPopupClose();
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        }
    };

    const executePUTAPI = async () => {
        try { 
            let api = Api;
            api.setUserToken();
        
            const res = await api.axios().put(Settings.apiVendorUrl+`/businessclassification/${vendor_business_id}`,{...formData,attachment_mandatory_for_vendor:formData?.attachment_mandatory_for_vendor=="on"},
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res?.data?.message); 
                reloadParentGrid();
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        }
    };

    const onPopupClose = () => {
        $("html").removeClass("rs_popup_open");
        setShowCreateModal(false);
    };

    const handleSubmit = () => { 
        if(!formValidatory.isValid({...formData})){
            formValidatory.displayMessage();
            formValidatory.reset();
            return;
        };
  
        if(!vendor_business_id) {
            Helper.createDebouncedAPIFunction( 
                [executePOSTAPI], 
                setApiCallBtn,
                cancelTokenSource
            )();
        } else {
            Helper.createDebouncedAPIFunction( 
                [executePUTAPI], 
                setApiCallBtn,
                cancelTokenSource
            )();
        };
    };

    return (
        <Fragment> 
            { apiCallBtn ? <OverlayLoader /> : null }
            <Popup
                autoOpen={true} width="900px"
                onClose={onPopupClose} 
            >
                <>
                    <h5 className="create_rfq_modal__title">{!vendor_business_id?'Create Vendor Business Class':'Edit Vendor Business Class'}</h5>

                    <Collapse
                        className="mt-4 mb-4" open={true}
                        title={Helper.getLabel(
                            language,
                            "general_info",
                            "General Information"
                        )}
                    >
                        <Form>
                            <div className='container-fluid'>
                                <FieldsRenderer updateState={setFormData} currentState={formData} fieldsArray={fieldsArrayGeneral}  />
                            </div>
                        </Form> 
                    </Collapse>
                </>

                <div className="addDocumentNumber-body-buttons" style={{ justifyContent: "flex-end" }}>
                    <Button
                        isActive={false} 
                        isDisable={apiCallBtn}
                        title={Helper.getLabel(language, "close", "Close")}
                        onClick={onPopupClose}
                    />

                    <Button
                        isDisable={apiCallBtn}
                        className={vendor_business_id?"rfq_save_btn":"solid_btn" } 
                        isActive={!vendor_business_id}
                        title={!vendor_business_id?Helper.getLabel(language, "create", "Create"):Helper.getLabel(language, "save", "Save")}
                        onClick={handleSubmit}
                    />
                </div>
            </Popup>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        appOptions: state.options,
        auth: state.auth,
        language: state.language,
    };
};

export default connect(mapStateToProps) (VendorBusinessClassModal);  