import React from "react";
import InnerScreen from "../../components/Inner Screens/InnerScreen";
import { Link } from "react-router-dom";
import MasterComonent from "../../components/Backend/MasterComonent";

const AdminScreen = () => {
  return (
    <div className="modules_screen_lynkaz">
      <MasterComonent>
        <InnerScreen innerTitle="Administration"> 
          <div className="col-lg-3 gy-3">
            <div className="inner-page-header border-bottom">
              <h2>Setup</h2>
            </div>
            <div className="inner-page-routes">
              <Link to="/system-parameters">System Parameters</Link>
              <Link to="/organisation-configuration">Organisation Configuration</Link>
            </div>
          </div>
  
          {/* <div className="col-lg-3 gy-3">
            <div className="inner-page-header border-bottom">
              <h2>Email</h2>
            </div>
            <div className="inner-page-routes">
              <Link to="/email-setting">Email Setting</Link>
              <Link to="/email-sending-status">Email Sending Status</Link>
              <Link to="/email-temp">Email Temp</Link>
            </div>
          </div> */}
 
          <div className="col-lg-3 gy-4">
            <div className="inner-page-header border-bottom">
              <h2>Audit History</h2>
            </div>
            <div className="inner-page-routes">
              <Link to="/history-logs">History Logs</Link>
            </div>
          </div>
        </InnerScreen>
      </MasterComonent>
    </div>
  );
};

export default AdminScreen;