import React from "react";
import "./DashboardReq.css";
import VendorIcon from "../../../assets/vendor req.svg"; 

const DashboardReq = (props) => {
  // h3- labels, these will be replaced with the api response text.
  // span- badge, these will be replaced with the api response text.
  let scrollDitections = props?.scrollDitection;
  const dashboardTotalRequests = [
    {
      id: 1,
      module: 'Vendor Request',
      total_requests: 544,
      perct_request: 73,
      request_type: 'New Request Today'
    },
    {
      id: 2,
      module: 'Urgent Request',
      total_requests: 644,
      perct_request: 53,
      request_type: 'New Urgent Request Today'
    },
    {
      id: 3,
      module: 'Purchase Requistion',
      total_requests: 514,
      perct_request: 23,
      request_type: 'New Urgent Request Today'
    },
    {
      id: 4,
      module: 'RFP/RFI Expiring',
      total_requests: 344,
      perct_request: 63,
      request_type: 'New Request Today'
    },
  ]
  return (
    <div
      className={
        "dashboard_container_wrapper " +
        (scrollDitections == "down" ? "" : "dash_nav_position_style_1")
      }
    >
      <div className="dashboard-request-section">
        {dashboardTotalRequests.map((item) => {
          return (
            <div className="source-request-col border rounded p-2">
              <div className="row request-row align-items-center ps-2 pt-3">
                <h3 className="mb-0">{item.module}</h3>
                <div className="col-6 d-flex justify-content-start mt-0">
                  <span className="request-row-badge">{item.total_requests}</span>
                </div>
                <div className="col-6 d-flex justify-content-end mt-0">
                  <span className="request-row-icon pe-2">
                    <a>
                      <img src={VendorIcon} className="img-fluid" />
                    </a>
                  </span>
                </div>
              </div>
              <h4>
                <span className="request-percentage me-1 ps-2">{item.perct_request}%</span>
                <span className="request-text">{item.request_type}</span>
              </h4>
            </div>
          )
        })} 
      </div>
    </div>
  );
};

export default DashboardReq; 