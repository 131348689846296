import React, { useState, useEffect, useCallback } from 'react';
import NavigationHeder from '../Navigations/NavigationHeder';
import Helper from '../../inc/Helper';
import Api from '../../inc/Api';
import Settings from '../../inc/Settings';
import GoodReceivingCreatePopup from './CreateNote/GoodReceivingCreatePopup';
import PoTopBlueShortDetails from '../Purchase Orders Internal/Widget/PoTopBlueShortDetails';
import { getPopupMessageBasedOnStatus } from '../../inc/Validation';
import AgGridNew from '../grid/ag/ag-grid-new';
import DatepickerFunctions from '../../inc/DatepickerHelper';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getStatusNameById, gridLinkValueFormat } from '../grid/ag/commonFunctions/GridCommonFunctions';
import axios from 'axios';
import MasterComonent from '../Backend/MasterComonent';
import OverlayLoader from '../PurchaseRequisition/common/OverlayLoader';
import ButtonNew from '../inc/ButtonNew';


let gridObj = null
let cancelTokenSource = axios.CancelToken.source();
const GoodsReceivingNotes = (props) => {
    const [isShowCreatePopup, setIsShowCreatePopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCreatingSh, setIsCreatingSh] = useState(false);
    const [shDocumentStatus, setShDocumentStatus] = useState([]);
    const [saveBtnApi,setSaveBtnApi]=useState(false)

    const { poid } = useParams();

    const navigate = useNavigate()

    const location = useLocation()

    useEffect(() => {
        loadShDocumentStatus();
    }, [poid]);

    const loadShDocumentStatus = useCallback(() => {
        const api = Api;
        setIsLoading(true)
        api.setUserToken();
        api.axios().get(Settings.apiUrl + '/enum/SH_DocumentStatus').then(res => {
            setShDocumentStatus(res.data.data);
            setIsLoading(false)
        }).catch((err) => {
            setIsLoading(false)
        });
    }, []);

    const onCellClickHandler = (event) => {
        if(event.colDef.field==='receiving_reference_number'){
           navigate((props.editUrl ? props.editUrl + '/' : "/good-receiving/edit/") + event.data.shipment_id)
        }
        if(event.colDef.field === 'document_number'){
            navigate((props.backUrl ? props.backUrl + '/' : "/edit-purchase-order/") + event.data.purchaseorder_id)
        }
    }

    const hideCreatePopup = () => {
        setIsShowCreatePopup(false);
    };

    const createShFromPoId = async () => {
        if (!poid) return;
        const api = Api;
        api.setUserToken();
        setIsCreatingSh(true);  
        try {
            const res = await api.axios().get(`${Settings.apiPurchaseOrderUrl}/PO_header/${poid}`, {});
            let newData = {
                ...res.data.data,
                accounting_date: DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(new Date())),
                document_date: DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(new Date())),
            };   
            const resSh = await api.axios().post(`${Settings.apiPurchaseOrderUrl}/sh_headers/${Settings.shType.po}`, newData,{ cancelToken: cancelTokenSource.token });
            Helper.alert(resSh.data.message);
            navigate((props.editUrl ? props.editUrl + '/' : "/good-receiving/edit/") + resSh.data.shipment_id);
        }catch(error) {
            getPopupMessageBasedOnStatus(error);
        } finally {
            setIsCreatingSh(false);
        }
    };
    
    const postGoodReceiving = () => {
        Helper.createDebouncedAPIFunction([
            async () => await createShFromPoId()
        ], setSaveBtnApi, cancelTokenSource)(); 
    }

    const deleteHanlder = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();
        
        let delete_shipment_id = element?.data?.shipment_id;
    
        try {
          const res = await api
            .axios()
            .delete(`${Settings.apiPurchaseOrderUrl}/sh_header/${delete_shipment_id}`)
          const rowNode = gridObj?.api?.getRowNode(delete_shipment_id)
          if (rowNode) {
            rowNode.setSelected(false)
          }
          setTimeout(() => {
            const transaction = { remove: [element.data] }
            gridObj?.api?.applyServerSideTransaction(transaction)
          }, 200)
          gridObj?.api?.deselectAll();
          Helper.alert(res?.data?.message, 'success')
        } catch (err) {
          getPopupMessageBasedOnStatus(err)
        }
      }, []);

    let lng = null;
    let gridHeaders = [
        { field: 'receiving_reference_number', headerName: Helper.getLabel(lng, 'good_receiving_number', 'Good receiving number'), minWidth: '250', cellRenderer: gridLinkValueFormat, minWidth:180 },
        { field: 'description', headerName: Helper.getLabel(lng, 'description', 'Description'), minWidth:200 },
        { field: 'document_number', headerName: Helper.getLabel(lng, 'source_document_number', 'Source document number'), cellRenderer: gridLinkValueFormat, minWidth: 220 },
        { field: 'vendor_code', headerName: Helper.getLabel(lng, 'vendor_code', 'Vendor code'), minWidth:180 },
        { field: 'vendor_name', headerName: Helper.getLabel(lng, 'vendor_name', 'Vendor name'), minWidth:200 },
        { field: 'vendorsite_name', headerName: Helper.getLabel(lng, 'vendor_site', 'Vendor site'), minWidth:180 },
        { field: 'accounting_date', headerName: Helper.getLabel(lng, 'accounting_date', 'Accounting date'), valueFormatter:(params)=>{return params.value ? DatepickerFunctions.convertDateFromDataBase(params.value) : "-"}, minWidth:180 },
        { field: 'document_date', headerName: Helper.getLabel(lng, 'document_date', 'Document date'), valueFormatter:(params)=>{return params.value ? DatepickerFunctions.convertDateFromDataBase(params.value) : "-"},minWidth:180 },
        { field: 'document_status', headerName: Helper.getLabel(lng, 'status', 'Status'), cellRenderer: (params) => {return getStatusNameById(params.value,shDocumentStatus)}, minWidth:180 }
    ];

    return (
        <MasterComonent>
         <div className='good_receiving_notes'>
            <div className='container-fluid'>
                <NavigationHeder backUrl={props.backUrl ? props.isDynamicBackUrl ?props?.backUrl: `${props.backUrl}/${poid}` : null} title={props.title ? props.title : Helper.getLabel(lng, 'listing_of_good_receiving_note', 'Listing of Good Receiving Note')} hideMoreBtn={true}>
                    {/* <Button title={"Print"} icon="/images/icons/print.svg" /> */}
                   {location?.pathname?.includes('/purchase-agreement/good-receiving/notes')?null:<ButtonNew id="create_note" isDisable={isCreatingSh} onClick={postGoodReceiving} isActive={true} title={Helper.getLabel(lng, 'new', "New")} />}
                </NavigationHeder>
                {/* {isLoading || isCreatingSh ? <SimpleLoading /> : ""} */}
                {isShowCreatePopup ? <GoodReceivingCreatePopup poid={poid} onClose={hideCreatePopup} /> : ''}
                <PoTopBlueShortDetails poid={poid} apiUrl={location?.pathname?.includes('/purchase-agreement/good-receiving/notes')&&Settings.apiPurchaseOrderUrl+`/PA_header/${poid}`} />
                <AgGridNew gridId="good_receiving_list" height={500} uniqueField={'shipment_id'} handleDeleteSelectedRows={deleteHanlder} columnDefs={gridHeaders} apiUrl={location?.pathname?.includes('/purchase-agreement/good-receiving/notes')?Settings.apiPurchaseOrderUrl+`/get_receivings`:`${Settings.apiPurchaseOrderUrl}/get_sh_header_PO/${poid}/${Settings.shType.po}`}  additionalParams={location?.pathname?.includes('/purchase-agreement/good-receiving/notes') && `&type=0&agreement_id=${poid}`} pagination={true} hideAddBtn={true} onGridReady={(grid)=>{gridObj = grid}} onCellClicked={onCellClickHandler} />
            </div>
         </div>
        {saveBtnApi ? <OverlayLoader /> : null}
        </MasterComonent>
    );
}

export default GoodsReceivingNotes;
