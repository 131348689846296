import React, { Fragment, useState } from 'react';
import Popup from '../../../Popup/Popup';
import OverlayLoader from '../../../PurchaseRequisition/common/OverlayLoader';
import { connect } from 'react-redux'; 
import Helper from '../../../../inc/Helper'; 
import GeneralTemplateModule from './GeneralTemplateModule';
import Button from '../../../inc/Button';
import FormValidator from '../../../../inc/FormValidator';
import axios from 'axios';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings';
import $ from 'jquery';
import Collapse from '../../../inc/Collapse';
import { checkValue, getPopupMessageBasedOnStatus } from '../../../../inc/Validation';
import { useNavigate } from 'react-router-dom';

let cancelTokenSource = axios.CancelToken.source();
const ScoringTemplateModal = ({ language, setShowCreateModal }) => { 
    const [createTemplate, setCreateTemplate] = useState({
        auto_disqualification_financial_score_prct: null,
        auto_disqualification_technical_score_prct: null,
        financial_score_weight: null,
        name: null, 
        show_evaluation_criteria_to_bidder: null,
        technical_score_weight: null
    });
    const [scoringWeights, setScoringWeights] = useState(false)

    const [apiCallBtn, setApiCallBtn] = useState(false);

    const navigate = useNavigate();

    let formValidatory;   

    const validationConfigure = () => {
        let formFieldConfig = [ 
            {name:'name', displayName:Helper.getLabel(language,'name','Name'),types:['Required']},
            {name:'technical_score_weight', displayName:Helper.getLabel(language,'technical_score_weight','Technical score weight'),types:['Required', 'Number']},
            {name:'financial_score_weight', displayName:Helper.getLabel(language,'financial_score_weight','Financial score weight'),types:['Required', 'Number']}
        ];

        if(!formValidatory) {
            formValidatory = new FormValidator(formFieldConfig, language)
        }; 
    };

    validationConfigure();
    
    const onPopupClose = () => {
        $("html").removeClass("rs_popup_open");
        setShowCreateModal(false);
    };

    const executePOSTAPI = async () => {
        try { 
            let api = Api;
            api.setUserToken();
        
            const res = await api.axios().post(Settings.apiPurchaseRequisitionUrl + `/scoringMaster`, {
                auto_disqualification_financial_score_prct: !checkValue(createTemplate.auto_disqualification_financial_score_prct) ? null : createTemplate.auto_disqualification_financial_score_prct,
                auto_disqualification_technical_score_prct: !checkValue(createTemplate.auto_disqualification_technical_score_prct) ? null : createTemplate.auto_disqualification_technical_score_prct,
                financial_score_weight: !checkValue(createTemplate.financial_score_weight) ? null : parseFloat(createTemplate.financial_score_weight),
                name: !checkValue(createTemplate.name) ? null : createTemplate.name, 
                technical_score_weight: !checkValue(createTemplate.technical_score_weight) ? null : parseFloat(createTemplate.technical_score_weight),
                show_evaluation_criteria_to_bidder: createTemplate.show_evaluation_criteria_to_bidder == 'on' ? true : false,
                scoringmaster_id: null,
                RFQ_id: null
            },
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res?.data?.message);
                onPopupClose();
                navigate(`/edit-scoring-template/${res?.data?.scoringmaster_id}`);
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        }
    };
  
    const handleSubmit = () => { 
        let validData = {
            ...createTemplate
        }

        if(!formValidatory.isValid(validData)){
            formValidatory.displayMessage();
            formValidatory.reset() 
            return;
        }
         
        if (!/^\d*\.?\d*$/.test(createTemplate.technical_score_weight) && createTemplate.technical_score_weight !== null) {
            Helper.alert('Technical score weight invalid number', 'error');
            return;
        }

        if (!/^\d*\.?\d*$/.test(createTemplate.auto_disqualification_technical_score_prct) && createTemplate.auto_disqualification_technical_score_prct !== null) {
            Helper.alert('Auto disqualification technical score invalid number', 'error');
            return;
        }

        if (!/^\d*\.?\d*$/.test(createTemplate.financial_score_weight) && createTemplate.financial_score_weight !== null) {
            Helper.alert('Financial score weight invalid number', 'error');
            return;
        }

        if (!/^\d*\.?\d*$/.test(createTemplate.auto_disqualification_financial_score_prct) && createTemplate.auto_disqualification_financial_score_prct !== null) {
            Helper.alert('Auto disqualification financial score invalid number', 'error');
            return;
        }
   
        if (scoringWeights) {
            Helper.alert('Distribute scoring weights about 100%', 'error');
            return;
        };

        Helper.createDebouncedAPIFunction( 
            [executePOSTAPI], 
            setApiCallBtn,
            cancelTokenSource
        )();
    };

    return (
        <Fragment> 
            { apiCallBtn ? <OverlayLoader /> : null }
            <Popup
                autoOpen={true} 
                width="900px"
                onClose={onPopupClose} 
            >
                <>
                    <h5 className="create_rfq_modal__title">Create Scoring Template</h5>

                    <Collapse className="mt-4 mb-4" open={true} title={Helper.getLabel(language,'general_info',"General Information")}>
                        <GeneralTemplateModule setScoringWeights={setScoringWeights} currentState={createTemplate} updateState={setCreateTemplate} /> 
                    </Collapse>
                </>

                <div className="addDocumentNumber-body-buttons" style={{ justifyContent: "flex-end" }}>
                    <Button
                        isActive={false} 
                        isDisable={apiCallBtn}
                        title={Helper.getLabel(language, "close", "Close")}
                        onClick={onPopupClose}
                    />

                    <Button
                        isDisable={apiCallBtn}
                        className={ "solid_btn" } 
                        isActive={true}
                        title={ Helper.getLabel(language, "create", "Create") }
                        onClick={handleSubmit}
                    />
                </div>
            </Popup>
        </Fragment>
    )
}
   
const mapStateToProps = (state) => {
    return { 
        appOptions:state.options,
        auth:state.auth,
        language:state.language
    }
}

export default  connect(mapStateToProps) (ScoringTemplateModal);