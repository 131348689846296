import React, { useCallback, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import Api from '../../../inc/Api'
import {
  checkIfArrayIsEmpty,
  getPopupMessageBasedOnStatus,
  objectIsEmpty,
} from '../../../inc/Validation'
import Helper from '../../../inc/Helper'
import { createRow } from './commonFunctions/GridCommonFunctions'

/** Filters: agDateColumnFilter, agNumberColumnFilter, agTextColumnFilter, agSetColumnFilter */

const AgGridNew = ({apiUrl,pagination,columnDefs, allowDeleteProps,isDataSourceRequire=true,deleteUniqueId,onAddClick,data,fetchData,onRowValueChanged,onRowEditingStopped,onCellClicked,handleDeleteSelectedRows,rowType,gridId,onGridReady=()=>{},handleAddButton=()=>{},height,isAddBtnDisable,isDeleteBtnDisable,afterDeleteBtns,isDisabledCheckbox=false,defaultColDef,hideAddBtn,hideDeleteBtn,additionalParams,uniqueField,masterSettings,beforeDeleteBtns,onFirstDataRendered,domLayout,onSelectionChanged,hidePaginationFooter,onCellValueChanged,onCellEditingStopped,returnApiCondition,allBtnHide,addLabel,onRowEditingStarted,newAddItems, apiMethod , payload, conditionForOverlay, noNeedRefresh=false, lineNumberSource, onBeforeRowAddBtnClick}) => {
  const [isAddBtn,setIsAddBtn]=useState(false)
  const gridApi   = useRef(null)
  let commonGridId 
  commonGridId = gridId ?? Date.now()

  const overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No records found.</span>';

  /** function for make payload for filters api */
  function convertObjectsToFilters(objects, params) {
    const filters = []

    for (const key in objects) {
        const { operator, conditions, filterType, values } = objects[key];
        const { customColId } = params?.api?.getColumn(key)?.colDef;
        const objFilters = [];
        
        if (filterType && filterType === 'set') {
            objFilters.push({
                left: customColId ? customColId : key,
                right: values,
                operation: filterType
            })
            filters.push({
                conditions: 'AND', 
                filters: objFilters
            })
        } else if (filterType && filterType === 'date') {
          if (conditions && conditions.length > 0) {
              const conditionsFilters = conditions.map((condition) => {
                return {
                  left: customColId ? customColId : key,
                  right: condition.type==="inRange"?[condition.dateFrom,condition.dateTo]:condition.dateFrom,
                  operation: condition.type,
              }
              })
              objFilters.push(...conditionsFilters)
              filters.push({
                  conditions: operator,
                  filters: objFilters,
              })
          } else {
            const { type, dateFrom, dateTo } = objects[key]
            objFilters.push({
                left: customColId ? customColId : key,
                right: type==="inRange"?[dateFrom,dateTo]:dateFrom,
                operation: type,
            })
            filters.push({
                conditions: operator || 'AND',
                filters: objFilters,
            })
          }
        } else {
            if (conditions && conditions.length > 0) {
                const conditionsFilters = conditions.map((condition) => ({
                    left: customColId ? customColId : key,
                    right: condition.filter,
                    operation: condition.type,
                }))
                objFilters.push(...conditionsFilters)
                filters.push({
                    conditions: operator,
                    filters: objFilters,
                })
            } else {
                const { type, filter } = objects[key]
                objFilters.push({
                    left: customColId ? customColId : key,
                    right: filter,
                    operation: type,
                })
                filters.push({
                    conditions: operator || 'AND',
                    filters: objFilters,
                })
            }
        }
    }

    return filters
}

const getUrl = (params,paginationUrl,queryString,sortedBy) => {
   let apiBaseUrl = paginationUrl
   if(!objectIsEmpty(params?.request?.filterModel) && !checkIfArrayIsEmpty(params?.request?.sortModel)){
      apiBaseUrl += `${apiBaseUrl?.includes("?")?"&":"?"}${queryString}&${sortedBy}` 
      return apiBaseUrl
   }
   if (!checkIfArrayIsEmpty(params?.request?.sortModel) ){
    apiBaseUrl += `${apiBaseUrl?.includes("?")?"&":"?"}${sortedBy}`
   }
   if (!objectIsEmpty(params?.request?.filterModel)){
    apiBaseUrl += `${apiBaseUrl?.includes("?")?"&":"?"}${queryString}` 
   }
   return apiBaseUrl
}

  /** centerlize store for data fetching */
  let dataSource = {
    getRows: async function (params) {
      const filterObject = convertObjectsToFilters(params?.request?.filterModel, params)
      const pageSize = params?.api?.paginationProxy?.pageSize
      const pageNumber = Math.floor(params.request.startRow / pageSize) + 1
      const additionalParamUrl = additionalParams ? `${apiUrl}?page_number=${pageNumber}&page_size=${pageSize}${additionalParams}`:`${apiUrl}?page_number=${pageNumber}&page_size=${pageSize}`
      const paginationUrl = pagination ? additionalParamUrl : apiUrl + `${additionalParams || ''}`;
      const queryString = `adv_search=${encodeURIComponent(JSON.stringify(filterObject))}`
      const sortingArray = params?.request?.sortModel?.map(item => {
        const { customColId } = params?.api?.getColumn(item?.colId)?.colDef;
        return {
          ...item,
          colId: customColId ? customColId : item?.colId
        }
      });
      const sortingQuery = `sorted_by=${JSON.stringify(sortingArray)}`
      let baseUrl 
      baseUrl = getUrl(params,paginationUrl,queryString,sortingQuery)
      if(!apiUrl){
        params.success({
          rowData:[]?.map((item) => ({
            ...item,
            gridId: `${Date.now()}_${getNewValue()}` 
          })),
          rowCount: 0
        });
        return;
      }
      try {
        let api = Api
        api.setUserToken()
        if((pagination && pageNumber !== (params.api.paginationGetCurrentPage() + 1)) || returnApiCondition){
          if(returnApiCondition){
           params?.api?.showNoRowsOverlay()
            params.success({
              rowData:[]?.map((item) => ({
                ...item,
                gridId: `${Date.now()}_${getNewValue()}` 
            })),
              rowCount: 0,
            })
          }
          return
        }
        let method = apiMethod ? apiMethod : 'get'
        let config = {}

        if (method === 'post' && payload) {
          config = payload
        }
        const response = await api.axios()[method](baseUrl,config)

        let condition = conditionForOverlay ?  conditionForOverlay(response) : response?.data?.data?.length === 0

        if(condition){
          params.api.showNoRowsOverlay()
        }else{
          params.api.hideOverlay()
        }

        setTimeout(() => {
          if (response.status === 200) {
            params.success(fetchData?fetchData(response):{
              rowData:response?.data?.data.map((item) => ({
                ...item,
                gridId: `${Date.now()}_${getNewValue()}` 
            })),
              rowCount: response?.data?.total_records || response?.data?.data?.length,
            })
          } else {
            params.fail()
          }
        }, 300)
      } catch (error) {
        getPopupMessageBasedOnStatus(error)
        params.fail()
        params.success({
          rowData:[]?.map((item) => ({
            ...item,
            gridId: `${Date.now()}_${getNewValue()}` 
        })),
          rowCount: 0,
        })
      }
    },
  }

  /** handle grid ready function to access params  */
  const handleGridReady = (params) => { 
    gridApi.current = params.api
    if(isDataSourceRequire) {
      params.api.setGridOption('serverSideDatasource', dataSource)
    }; 
    let gridElement =document.getElementById(commonGridId);
    document.addEventListener('mousedown', function(e) {
        let targetElement = e.target;
        let dateTarget = document.getElementsByClassName('react-datepicker__month-container')
        if (targetElement && gridElement && !gridElement.contains(targetElement) && !dateTarget[0]) {
            params?.api?.stopEditing();
        }
    });
    onGridReady(params)
    setTimeout(()=>{
      reloadGridUserSettings()
    },300)
  }

  /** Function to set add btn this is for adding line after posting */
  const toggleIsAddBtn = (value,transaction) => {
    setIsAddBtn(value);
    handleAddButton(value,transaction)
  };

  /** Functions to add new empty row */
  const getNewValue = () => {
    return Math.floor(Math.random() * 100000) + 100
  }

  const addEmptyRow = useCallback(() => {
    if(onBeforeRowAddBtnClick && typeof onBeforeRowAddBtnClick==='function'){
      onBeforeRowAddBtnClick(gridApi?.current)
    }
    const rowIndex = 0
    const transaction = {
      addIndex: rowIndex != null ? rowIndex : undefined,
      add: [createRow(newAddItems,gridApi?.current,lineNumberSource)],
    }
    gridApi.current.applyServerSideTransaction(transaction)
    gridApi?.current?.hideOverlay()
    toggleIsAddBtn(true,transaction)
    setTimeout(()=>{
      toggleIsAddBtn(false,null)
    },2500)
  }, [])

  const getRowId = useMemo(() => {
    return (params) => {
      return params?.data?.gridId
    }
  }, [])

  if (columnDefs?.length >= 1 && !isDisabledCheckbox) {
    columnDefs[0].headerCheckboxSelection = true
    columnDefs[0].checkboxSelection = true
  }

  const handlePageSizeChange = (params) => {
    params.api.setGridOption('cacheBlockSize',params?.api?.paginationProxy?.pageSize)
  }

  const onSortChanged = () => {
    gridApi?.current?.refreshServerSide({purge:true})
  }

  const onColumnsMoved = (event) => {
    let columnState = JSON.stringify(event.columnApi.getColumnState());
    localStorage.setItem(commonGridId+'_grid_col_state', columnState);
  }

  const reloadGridUserSettings = useCallback(() => {
    let columnState = JSON.parse(localStorage.getItem(commonGridId + '_grid_col_state'));
    if (columnState && gridApi.current) {
      gridApi.current.applyColumnState({
        state: columnState,
        applyOrder: true,
      });
    }
  }, [gridApi]);

  const deleteButtonHandler = () => {
    let isSelected = gridApi?.current?.getServerSideSelectionState()
    let totalSelectedItems = isSelected?.selectAll ? gridApi?.current?.getRenderedNodes():gridApi.current.getSelectedNodes()
    let lastIndex = totalSelectedItems.length - 1;

   totalSelectedItems.forEach((element, index) => {
    let uniqueId = deleteUniqueId ? deleteUniqueId : element.data[uniqueField];
    
    if (!uniqueId) {
      const transaction = { remove: [element.data] };
      gridApi.current.applyServerSideTransaction(transaction);
      if(allowDeleteProps) handleDeleteSelectedRows(element);
    } else {
      handleDeleteSelectedRows(element,index,lastIndex);
    }

    if (!noNeedRefresh && index === lastIndex) {
      setTimeout(() => {
        gridApi.current.refreshServerSide({ purge: true });
        gridApi.current.deselectAll();
      }, 400);
    }
  });
  }

  const onDelete = () => {
    let isSelected = gridApi?.current?.getServerSideSelectionState()
    let totalSelectedItems = isSelected?.selectAll ? gridApi?.current?.getRenderedNodes():gridApi.current.getSelectedNodes()
    let totalItems = totalSelectedItems?.length
    if(totalItems===0){
      Helper.alert("Please select a row to delete. No row is selected yet","error")
      return
    }
    let bodyMessage = totalItems + (totalItems === 1 ?  " item " : ' items ' )+' will be deleted';
    Helper.confirmMe(() => {
     deleteButtonHandler(totalSelectedItems)
    },bodyMessage,null,"Are you sure you want to delete?",420)
  }

  return (
    <div className='ag-grid-parent'>
      <div className='ag-grid-btns-wrapper d-flex align-items-center'>
      {allBtnHide ? null : <div className='d-flex gap-2'>
        {hideAddBtn ? null:<button onClick={!onAddClick ? addEmptyRow : onAddClick} disabled={isAddBtnDisable} className={isAddBtnDisable?'btn-disabled':''}><img src="/images/icons/add-new.svg"  alt="Add New"/><span>{addLabel?addLabel:'Add'}</span></button>}
        {beforeDeleteBtns}
        {hideDeleteBtn ? null : <button onClick={onDelete} disabled={isDeleteBtnDisable} className={isDeleteBtnDisable?'btn-disabled':''}><img src="/images/icons/delete.svg"  alt={'delete btn'}/><span>{'Delete'}</span></button>}
        {afterDeleteBtns}
      </div>}
      </div>
      <div style={{ width: '100%', height: height || '400px'}}>
        <div
          id={commonGridId}
          className='ag-theme-alpine'
          style={{ height: '100%', width: '100%' }}
          role='grid'
          tabIndex='0'
          onClick={(e) => {
            e.stopPropagation()
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
            }
          }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            defaultColDef={{
              filter: 'agTextColumnFilter', 
              flex:1,
              minWidth:150,
              icons: {
                menu: `<span class="ag-icon-menu" style="font-size:16px !important;"></span>`
              },
              filterParams: {
                buttons: ["reset"],
              },
              ...defaultColDef
            }}
            gridOptions={{
              rowModelType: 'serverSide',
              serverSideStoreType: 'partial',
              checkboxSelection: true,
              rowSelection: rowType || 'multiple',
              rowMultiSelectWithClick: false,
              suppressRowClickSelection: false,
              onRowValueChanged: onRowValueChanged,
              onRowEditingStopped: onRowEditingStopped,
              singleClickEdit: true,
              cacheBlockSize:10,
              onPaginationChanged:handlePageSizeChange,
              onSortChanged:onSortChanged,
              maxBlocksInCache:10,
              suppressColumnMoveAnimation:true,
              onColumnMoved:onColumnsMoved,
              onFirstDataRendered:onFirstDataRendered,
              onCellEditingStopped:onCellEditingStopped,
              onCellClicked:onCellClicked,
              onCellValueChanged: onCellValueChanged,
              suppressCellFocus:false,
              suppressPaginationPanel:hidePaginationFooter,
              onRowEditingStopped:onRowEditingStopped,
              onSelectionChanged:onSelectionChanged,
              onRowEditingStarted:onRowEditingStarted,
              ...masterSettings
            }}
            onGridReady={handleGridReady}
            pagination={pagination ? pagination : true}
            paginationPageSize={10}
            domLayout={domLayout}
            reactiveCustomComponents={true}
            paginationPageSizeSelector={[10,20,50,100]}
            getRowId={getRowId}
            editType={'fullRow'}
            noRowsOverlayComponent={overlayNoRowsTemplate}
          />
        </div>
      </div>
    </div>
  )
}

export default AgGridNew
