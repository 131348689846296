import React, { Component, createRef, Fragment } from 'react';
import { connect } from 'react-redux';
import Api from '../../inc/Api';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings';
import MasterComonent from '../Backend/MasterComonent';
import Checkbox from '../Forms/Checkbox';
import Dropdown from '../Forms/Dropdown';
import Input from '../Forms/Input';
import Button from '../inc/Button';
import ButtonDelete from '../inc/ButtonDelete';
import Collapse from '../inc/Collapse'; 
import SimpleLoading from '../Loading/SimpleLoading';
import NavigationHeder from '../Navigations/NavigationHeder'; 
import MasterSidebar from '../Sidebars/MasterSidebar';
import BlueCallToAction from '../Widgets/BlueCallToAction/BlueCallToAction';
import NoteAttachmentHistory from '../Widgets/NoteAttachmentHistory';
import Tabs from '../Widgets/Tabs/Tabs';
import CategoryVendors from './CategoryVendors';
import MasterCategoryList from './MasterCategoryList';
import $ from 'jquery';
import FormValidator from '../../inc/FormValidator';
import DropDownInput from '../Forms/Dropdown';
import ActionTypes from '../../inc/ActionsTypes';
import CategoryItems from './CategoryItems';
import { getPopupMessageBasedOnStatus } from '../../inc/Validation';
import ApplySecurityRoles from '../SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../inc/Gui_id_list';
import Alert from '../inc/Alert';
class MasterCategory extends Component {
    constructor(props){
        super(props); 
        this.itemsGrid =null;
        this.source_id= 23;
        this.state= {
            isLoading:false,
            currentSearchTerm: '',
            allCategory:[],
            masterCategoryTypes:[],
            itemGroupList:[],
            loadingItemGroup:false,
            taxItemGroupList:[],
            loadingTaxItemGroup:false,
            selectedTreeItem:null,
            selectedCategory:{},
            isCategoryLoading:false,
            category:{
                is_active:true
            },
            isNewForm:true,
            isMaster:true,
            masterCategory:{}
        }
        this.parentCategoryData = createRef()
        this.validationConfigure(this.state.isMaster);
    }

    validationConfigure(isMaster = false){
        const language = this.props.language;
        let fieldConfig;
        if(isMaster){
            fieldConfig = [
                {name:'name',displayName:Helper.getLabel(language,'name','Name'),types:['Required'] },
                {name:'type',displayName:Helper.getLabel(language,'template_type','Template Type'),types:['Required']}, 
            ]
        }else{
            fieldConfig = [
                {name:'hierarchy_id',displayName:Helper.getLabel(language,'template','Template'),types:['Required'] },
                {name:'name',displayName:Helper.getLabel(language,'name','Name'),types:['Required'] },
                {name:'code',displayName:Helper.getLabel(language,'code','Code'),types:['Required'] },
                {name:'itemgroup_id',displayName:Helper.getLabel(language,'itemgroup_id','Item group'), types:['Required']},
            ]
        }
        
        this.validator = new FormValidator(fieldConfig,language);
    }
    componentDidMount(){
        this.loadItemGroup();
        this.loadTaxItemGroup();
        this.loadAllCategory();
        this.loadMasterCategoryTypes();
    }
    
    loadItemGroup(){
        let that = this;
        let api = Api;
        api.setUserToken();
        this.setState({
            loadingItemGroup:true
        })
        api.axios().get(Settings.apiItemUrl+'/item_group').then(res=>{
            that.setState({
                loadingItemGroup:false,
                itemGroupList:res.data.data.reverse()
            })
        })
    }
    loadTaxItemGroup(){
        let that = this;
        let api = Api;
        api.setUserToken();
        this.setState({
            loadingTaxItemGroup:true
        })
        api.axios().get(Settings.apiUrl+'/taxgroupitem').then(res=>{
            that.setState({
                loadingTaxItemGroup:false,
                taxItemGroupList:res.data.data.reverse()
            })
        })
    }
    resetForm(){
        this.setState({
            category:{},
            isMaster:false,
        })
    }
    
    onSaveButtonClickHanlder(event){
        if(this.state.isMaster){
            if(this.state.category.hierarchy_id){
                this.updateMasterCategory()
            }else{
                this.createMasterCategory()
            }
        }else{
            if(this.state.category.category_id){
                $('.grid_save_btn_hide .rs_grid_btn_save').trigger('click');
                this.updateCategory()
            }else{
                this.createNewCategory()
            }
        }
        
    }

    loadAllCategory(){
        let that = this;
        that.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        api.axios().get(Settings.apiUrl + '/categories').then(res => {
            that.setState({
                isLoading:false,
                allCategory:res.data.data
            },function(){
                if(that.category && that.category.category_id){
                    that.loadCategoryById(that.category.category_id)
                }
            })
        })
    }
    loadMasterCategoryTypes(){
        let that = this;
        that.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        api.axios().get(Settings.apiUrl + '/enum/CategoryHierarchyMasterType').then(res => {
            that.setState({
                isLoading:false,
                masterCategoryTypes:res.data.data
            },function(){
                /*
                if(that.category && that.category.category_id){
                    that.loadCategoryById(that.category.category_id)
                }
                */
            })
        })
    }
    
    loadCategoryById(categoryId){
        let api = Api;
        api.setUserToken();
        let that = this;
        that.setState({
            isCategoryLoading:true,
            category:{}
        })
        api.axios().get(Settings.apiUrl+'/categoryhierarchy/'+categoryId).then(res=>{
            that.setState({
                isCategoryLoading:false,
                category:res.data.data
            })
        })
        /*
        this.state.allCategory.forEach( category => {
            if(categoryId === category.category_id){
                this.setState({
                    category:category
                })
            }
        })
        */
    }

    createNewCategory(){ 
        let data = this.state.category;
        let selectedTreeItem = this.state.selectedTreeItem;
        let api = Api;
        
        api.setUserToken();
        let masterId = this.state.masterCategory;
        let newData = {
            hierarchy_id: masterId.hierarchy_id,
            code: data?.code,
            name: data?.name,
            externalcode:Helper.getNullableValue(data?.externalcode),
            parentcategory: (selectedTreeItem && selectedTreeItem.category && selectedTreeItem.category.category_id) ? selectedTreeItem.category.category_id : null,
            is_active:data.is_active ? true : false,
            is_miscellaneouscharge: data.is_miscellaneouscharges ? true : false,
            is_deleted:false,
            translationsource_id:null,
            vendor_ids: null, 
            taxgroupitem_id: data?.taxgroupitem_id !== "" ? parseInt(data?.taxgroupitem_id) : null,
            itemgroup_id: Helper.getOnlyInt(data.itemgroup_id,true),
        }
        if(!this.validator.isValid(newData)){
            this.validator.displayMessage(this);
            return;
        }
        let that = this;
        this.setState({
            isLoading:true
        })
        api.axios().post(Settings.apiUrl+'/categoryhierarchy',newData).then( res=>{
            that.setState({
                isLoading:false,
                category: { 
                    category_id: res?.data?.category_id
                }
            },function(){
                Helper.createNoteIfExist(this.props.notes.notes,that.source_id,res.data.category_id,function(response){
                    that.loadAllCategory();
                    that.props.clearNotes();
                })
            })
            Helper.alert(res.data.message);
        }).catch(errors => {
            getPopupMessageBasedOnStatus(errors);
            this.setState({
                isLoading:false
            })
        })
    }

    createMasterCategory(){
        let data = this.state.category;
        let api = Api;
        
        api.setUserToken();
        let newData = {
            name:data.name,
            type:data.hierarchy_type ? data.hierarchy_type : 0,
            is_active:data.is_active ? true : false,
            translationsource_id:null
        }
        if(!this.validator.isValid(newData)){
            this.validator.displayMessage(this);
            return;
        }
        let that = this;
        this.setState({
            isLoading:true
        })
        api.axios().post(Settings.apiUrl+'/mastercategory',newData).then( res=>{
            that.setState({  
                isLoading:false
            },function(){
                that.resetForm()
            })
            that.loadAllCategory();
            Helper.alert(res.data.message);
        }).catch((err) => {
            getPopupMessageBasedOnStatus(err)
            that.setState({  
                isLoading:false
            })
        })
    }

    updateMasterCategory(){
        let data = this.state.category;
        let hierarchy_id = this.state.selectedTreeItem.masterCategory.hierarchy_id;
        let api = Api;
        
        api.setUserToken();
        let newData = {
            name:data.name,
            type:data.hierarchy_type ? data.hierarchy_type : 0,
            is_active:data.is_active ? true : false,
            translationsource_id:null
        }
        if(!this.validator.isValid(newData)){
            this.validator.displayMessage(this);
            return;
        }
        let that = this;
        this.setState({
            isLoading:true
        })
        api.axios().put(Settings.apiUrl+'/mastercategory/'+hierarchy_id,newData).then( res=>{
            that.setState({
                category: {},
                isLoading:false
            })
            Helper.alert(res.data.message);
            that.loadAllCategory();
        }).catch((error) => {
            getPopupMessageBasedOnStatus(error)
            that.setState({  
                isLoading:false
            })
        })
    }

    updateCategory(){
        let data = this.state.category;
        let api = Api;

        api.setUserToken();
        let masterId = this.state.masterCategory;
        let newData = {
            hierarchy_id: masterId?.hierarchy_id,
            code: data?.code,
            name: data?.name,
            externalcode: Helper.getNullableValue(data?.externalcode),
            // parentcategory: data?.parent_category !== "" ? parseInt(data?.parent_category) : null,
            is_active: data?.is_active ? true : false,
            is_miscellaneouscharge: data?.is_miscellaneouscharges ? true : false,
            is_deleted: false,
            translationsource_id: null,
            taxgroupitem_id: data?.taxgroupitem_id !== "" ? parseInt(data?.taxgroupitem_id) : null,
            itemgroup_id: Helper.getOnlyInt(data?.itemgroup_id,true),
        }
        if(!this.validator.isValid(newData)){
            this.validator.displayMessage(this);
            return;
        }
        let that = this;
        this.setState({
            isLoading:true
        })
        api.axios().put(Settings.apiUrl+'/categoryhierarchy/'+data.category_id,newData).then( res=>{
            that.setState({
                category: {},
                isLoading:false
            })
            that.loadAllCategory();
            Helper.alert(res.data.message);
        }).catch((err) => {
            getPopupMessageBasedOnStatus(err)
            that.setState({  
                isLoading:false
            })
        })
    }

    onTreeItemClick(itemData){  
        let newStateDate={
            selectedTreeItem:itemData,
            //category:itemData.category,
            isNewForm:false,
            selectedCategory:itemData.category,
            isMaster:itemData.isMaster,
            masterCategory:itemData.masterCategory
        }
        if(itemData.category){
            this.loadCategoryById(itemData.category.category_id)
        }else{
            newStateDate.category = { 
                ...itemData.masterCategory,
                is_active: itemData.masterCategory.isactive
            };
        }
        this.validationConfigure(newStateDate.isMaster);
        this.setState(newStateDate, ()=> {
            Helper.refreshAppScript();
        });
        this.props.clearNotes(); 
    }
    onInputChangeHanlder(event){ 
        let category = this.state.category;
        this.setState({
            category:{
                ...category,
                [event.target.name]: event.target.value
            }
        })
    }
    onCheckboxChangeHanlder(name,value){
        let category = this.state.category;
        this.setState({
            category:{
                ...category,
                [name]: value == 'on' ? true: false
            }
        })
    }
    addComponentRight(){
        if(this.state.isMaster){
            return;
        }
        let category = this.getStateCategory();
        return (
            <MasterSidebar>
                {  this.state.isLoading ? <SimpleLoading/> : <NoteAttachmentHistory hideHistory={true} source_id ={this.source_id} notesource_integrator= {category.category_id}  attachmentsource_integrator ={category.category_id}/> }
            </MasterSidebar>
        )
    }
    getStateCategory(){
        return this.state.category ? this.state.category : this.state.masterCategory;
    }
    isEditMode(){
        if(this.state.isNewForm){
            return false;
        }
        let category = this.getStateCategory();
        if(category.category_id || category.hierarchy_id){
            return true;
        }
        return false;
    }
    isDeleted(){
        let category = this.getStateCategory();
        if(category.is_deleted){
            return true;
        }
        return false;
    }
    isInactive(){
        let category = this.getStateCategory();
        if(this.state.isMaster){
            return category.isactive;
        }else{
            return category.is_active;
        }
    }
    addComponentTop(){
        let language = this.props.language;
        let category = this.getStateCategory();
        let categoryTitle = category.name || '';
        let categoryId = category.category_id ? category.category_id : category.hierarchy_id;
        let items = [];
        let categoryLabelTitle = Helper.getLabel(language,'category_name','Category Name');
        if(this.state.isMaster){
            categoryLabelTitle = Helper.getLabel(language,'template_name','Template Name');
        }
        items.push({ title:categoryLabelTitle, subtitle:categoryTitle })
        //items.push({title:Helper.getLabel(language,'category_id','Category ID'),subtitle:categoryId})
        let tags = [];
        if(this.isDeleted()){
            tags.push({title:Helper.getLabel(language,'deleted','Deleted'), type:'danger'})
        }
        if(this.isMasterCategoryCreating()){
            tags.push({title:Helper.getLabel(language,'creating_new_template','Creating new template')})
        }
        if(this.isEditMode()){
            tags.push({title:Helper.getLabel(language,'edit','Edit')})
        }else{
            tags.push({title:Helper.getLabel(language,'new','New')})
        }
        if(this.isInactive() && this.isEditMode()){
            tags.push({title:Helper.getLabel(language,'active','Active')})
        }else{
            if(this.isEditMode()){
                tags.push({title:Helper.getLabel(language,'inactive','in-active'), type:'danger'})
            }
        }
        return(
            <div className='rfq-internal-blue'>
              <BlueCallToAction items={items} tags={tags}/>
            </div>
        )
    }
    onCreateBtnClick(event){
        this.validationConfigure(false);
        this.setState({
            isNewForm:true,
            isMaster:false,
            category:{
                is_active: true
            }
        })
    }
    onCreateMasterCategoryClick(event){
        this.validationConfigure(true);
        $('.master_category_list .rs_treeview_controller').removeClass('active')
        this.setState({
            isMaster:true,
            category:{
                is_active:true
            },
            isNewForm:true
        })
    }
    isMasterCategoryCreating(){
        if(this.state.isNewForm && this.state.isMaster){
            return true;
        }
        return false;
    }
    actionsBtnDissable(){
        if(this.state.isLoading){
            return true;
        }
        return !this.isEditMode();
    }
    deleteCategory(){
        if(!this.isEditMode()){
            return;
        }
        let category = this.getStateCategory();
        let that = this;
        that.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        let deleteUrl = this.state.isMaster ? Settings.apiUrl + '/mastercategory/'+category.hierarchy_id : Settings.apiUrl + '/categoryhierarchy/'+category.category_id;
        api.axios().delete(deleteUrl).then(res => {
            Helper.alert(res.data.message);
            that.setState({
                category: {},
                isLoading:false
            })
        }).catch(error => {
            getPopupMessageBasedOnStatus(error);
            that.setState({
                isLoading:false
            })
        })
    }
    onDropdownChange(event){
        this.onInputChangeHanlder(event)
    }
    mapCategoryItems(item){
        return {
            id:item.category_id,
            text:item.code+'-'+item.name
        }
    }
    render() {
        let language = this.props.language;
        let navs = [
            {title:Helper.getLabel(language,'general',"General"), isActive: true},
            {title:Helper.getLabel(language,'vendors',"Vendors"), isDisable : this.state.isNewForm || this.state.isMaster},
            {title:Helper.getLabel(language,'items',"Items"), isDisable : this.state.isNewForm || this.state.isMaster}
        ];
        if(!this.isEditMode() && !this.state.isMaster){
            
        }
        let category = this.state.category ? this.state.category : {};
        let allCategoryOtions = this.state.allCategory.map( cat => {
            return {
                label:cat.name,
                value: cat.category_id
            }
        })
        let masterCategoryTypesOptions = this.state.masterCategoryTypes.map( cat => {
            return {
                label:cat.name,
                value: cat.id
            }
        })
        let taxItemGroupList = this.state.taxItemGroupList.map( taxItemGroup => {
            return {
                label:taxItemGroup.name,
                value: taxItemGroup.taxgroupitem_id
            }
        })
        let itemGrupOptions = this.state.itemGroupList.map( taxItemGroup => {
            return {
                label:taxItemGroup.name,
                value: taxItemGroup.itemgroup_id
            }
        })
        let isMaster = this.state.isMaster;
        
        if(!this.props?.security.canView(Gui_id_list.setupForms.category.category_main)){ 
            return <Fragment>
                <MasterComonent>
                    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
                </MasterComonent>
            </Fragment>
        }

        // let select2Settings = {
        //     ajax: {
        //         type:'GET',
        //         url:  Settings.apiUrl + '/categories',
        //         processResults: function (data) {
        //             let result = data.data.map( item => {
        //                 return {
        //                     id:item.category_id,
        //                     text:item.code+'-'+item.name
        //                 }
        //             })
        //             return {
        //               results:result,
        //               pagination: {
        //                     more: true
        //                 }
        //             };
        //         }
        //     }
        // }
        return (
            <MasterComonent>
                <div className='container-fluid master_categories'>
                    <NavigationHeder title={Helper.getLabel(language,'categories','Categories')} backUrl="/setup" hideMoreBtn={true}>
                        <ButtonDelete isDisable={!this.props?.security.canDelete(Gui_id_list.setupForms.category.category_main_delete_button) || this.actionsBtnDissable()} onClick = { this.deleteCategory.bind(this)} />
                        {/* { this.isEditMode() ? <TextTranslations  source_id={this.source_id} source_integrator = {category.category_id} translationsource_id={category.translationsource_id} original_text={category.name}  product_number={category.category_id}/> : <TextTranslations  source_id={this.source_id}/> } */}
                        
                        <Button isDisable={!this.props?.security.canCreate(Gui_id_list.setupForms.category.category_main_create_button) || this.isMasterCategoryCreating()} onClick={ this.onCreateMasterCategoryClick.bind(this)}  isActive={false}  icon={'/images/icons/plus.svg'}   title={ Helper.getLabel(language,'template','Template')} />
                        <Button isDisable={!this.props?.security.canCreate(Gui_id_list.setupForms.category.category_main_create_button) || this.actionsBtnDissable()} onClick={ this.onCreateBtnClick.bind(this)}  isActive={false} icon={'/images/icons/plus.svg'}   title={ Helper.getLabel(language,'category','Category')} />
                        <Button isDisable={!this.props?.security.canCreate(Gui_id_list.setupForms.category.category_main_create_button) || this.state.isLoading} isActive={false} title={Helper.getLabel(language,'save',"Save")} onClick = { this.onSaveButtonClickHanlder.bind(this) } className="rfq_save_btn"  />
                    </NavigationHeder>
                    { this.state.isLoading ? <SimpleLoading /> : ''}
                    { this.state.isCategoryLoading ? <SimpleLoading /> : ''}
                    <div className='row'>
                        <div className='col-xs-4 col-sm-3'>
                            {this.state.isLoading ? <SimpleLoading/> : <MasterCategoryList onItemClick={ this.onTreeItemClick.bind(this)}/> }
                        </div>
                        <div className='col-xs-8  col-sm-9'>
                            <Tabs navs={navs} showAll={true} scrollOffset="100" addComponentTop = { this.addComponentTop.bind(this)}  addComponentRight = { this.addComponentRight.bind(this)}>
                                <Collapse  title={Helper.getLabel(language,'general',"General")} className="mb-5" open={true}>
                                    <div className='row'>

                                        {!isMaster ? <div className='col-xs-12 col-sm-6'><Input maxLength={40} hasError={this.validator.hasError('code')} name="code" label={ Helper.getLabel(language,'code','Code') } required={true} value={category.code ? category.code : ''} onChange={ e=> this.onInputChangeHanlder(e)} /> </div>: ''}
                                        
                                        <div className='col-xs-12 col-sm-6'>
                                            <Input name="name" maxLength={100} hasError={this.validator.hasError('name')} label={ Helper.getLabel(language,'name','Name') } required={true} value={category.name ? category.name : ''} onChange={ e=> this.onInputChangeHanlder(e)} />
                                        </div>

                                        {
                                            !this.state.isMaster ? this.state.loadingItemGroup ? <SimpleLoading/> : <div className='col-xs-12 col-sm-6'><Dropdown reRenderRequired={true} placeHolder='Select item group' required={true} id="itemgroup_id" hasError={this.validator.hasError('itemgroup_id')} name="itemgroup_id" value={category.itemgroup_id} label={Helper.getLabel(null,'item_group',"Item group")} options={itemGrupOptions} onChange={ this.onDropdownChange.bind(this)}  /></div> : ""
                                        }

                                        { !isMaster ? <div className='col-xs-12 col-sm-6'><Input maxLength={20} name="externalcode" label={ Helper.getLabel(language,'externalcode','External code') } required={false} value={category.externalcode ? category.externalcode : ''} onChange={ e=> this.onInputChangeHanlder(e)} /> </div>: ''}
                                         
                                        {
                                            !this.state.isMaster ? this.state.loadingTaxItemGroup ? <SimpleLoading/> : <div className='col-xs-12 col-sm-6'><Dropdown reRenderRequired={true} placeHolder='Select tax group item' id="taxgroupitem_id" name="taxgroupitem_id" value={category?.taxgroupitem_id} label={Helper.getLabel(null,'taxgroup_item',"Tax group item")} options={taxItemGroupList} onChange={ this.onDropdownChange.bind(this)}  /></div> : ""
                                        }

                                        <div className='col-xs-12 col-sm-6'>  
                                            { isMaster && <Dropdown name="hierarchy_type" placeHolder='Select type' required={true} reRenderRequired={true} id="hierarchy_type"  hasError={this.validator.hasError('type')} label="Type" value={category.type ? category?.type : category?.hierarchy_type} options={masterCategoryTypesOptions}  onChange={ this.onDropdownChange.bind(this)} /> }
                                            
                                            { !isMaster && !this.state.isNewForm && 
                                                <DropDownInput
                                                    apiUrl={Settings.apiUrl + '/categories'}
                                                    currentSearchTerm={this?.state?.currentSearchTerm}
                                                    mapFunction={this.mapCategoryItems}
                                                    isDisable={true}
                                                    allDataRef={this?.parentCategoryData} 
                                                    label={Helper.getLabel(
                                                    language,
                                                        "parent_category",
                                                        "Parent category"
                                                    )}
                                                    reRenderRequired={true}
                                                    placeHolder={`${Helper.getLabel(
                                                        language,
                                                            'please_select',
                                                            'Select parent category',
                                                        )}`
                                                    } 
                                                    options={this?.state?.allCategory?.map((item) => {
                                                        return {
                                                            value: item.category_id,
                                                            label: `${item.code} - ${item.name}`
                                                        }
                                                    })}
                                                    onChange={this.onDropdownChange.bind(this)}
                                                    value={category?.parent_category}
                                                    id="parent_category"
                                                    name='parent_category'
                                                /> 
                                            }
                                        </div> 
                                         
                                        <div className='col-xs-12 col-sm-3'>
                                            <Checkbox name="is_active"  label={ Helper.getLabel(language,'active','Active') } required={false} isChecked={category.is_active} onChange={this.onCheckboxChangeHanlder.bind(this)} />
                                        </div>
                                        { !this.state.isMaster ? <div className='col-xs-12 col-sm-6'><Checkbox name="is_miscellaneouscharges"  label={ Helper.getLabel(language,'is_miscellaneouscharges','Is miscellaneous charges') } required={false} isChecked={ category.is_miscellaneouscharges } onChange={this.onCheckboxChangeHanlder.bind(this)} /></div> : ''}
                                    </div> 
                                </Collapse>
                                <Collapse title={Helper.getLabel(language,'vendors',"Vendors")} className="mb-5" open={true}>
                                    <CategoryVendors master_category_id={category?.category_id} />
                                </Collapse>
                                <Collapse title={Helper.getLabel(language,'items',"Items")} className="mb-5" open={true}>
                                    <CategoryItems category_id={category?.category_id} />
                                </Collapse>
                            </Tabs>
                        </div>
                        
                    </div>
                    
                </div>
            </MasterComonent>
            
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        notes:state.notes,
        language:state.language
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        clearNotes: () => { dispatch({type:ActionTypes.CLEAR_NOTES})}
    }
}

const SecurityOptions = {
    gui_id:Gui_id_list.setupForms.category.category_main
};
 
export default connect(mapStateToProps, mapDispatchToProps) (((ApplySecurityRoles(MasterCategory, SecurityOptions))));