import React, { Component } from 'react';
import Helper from '../../inc/Helper';
import EditPO from '../../components/Purchase Orders Internal/Edit PO/EditPO';

class ContractOrderEditPage extends Component {
    render() {
        return (
            <EditPO
                title={Helper.getLabel(null,'edit_contract_order',"Edit Contract Order")}
                lineTitle={Helper.getLabel(null,'contract_order_lines',"Contract Order Lines")}
                backUrl='/contract-order/all'
                versionUrl='/contract-order/version'
                historyUrl='/contract-order/history'
                logsUrl='/contract-order/logs'
                goodReceivingUrl='/contract-order/good-receiving/notes'
                returnOrderUrl='/contract-order/return-order/all'
                amendmentRequestUrl='/contract-order/amendment-request/all'
                renewUrl="/contract-order/renew"
                hideStartDate = {false}
                hideEndDate = {false}
                hideBillingRule = {false}
                source_id = {14}
                visibleEndDate = {true}
                visibleStartDate = {true}
                type="contract"
            />
        );
    }
}

export default ContractOrderEditPage;