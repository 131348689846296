import React, { Component } from 'react';
import { connect } from 'react-redux';
import ActionTypes from '../../inc/ActionsTypes';
import Api from '../../inc/Api';
import Settings from '../../inc/Settings';
/**
 * lineSiteStatus, lineaccess_level, purpose,vendors,sites,warehouse,segmentsValues,segments,lineaccess_level,amendmentNature = true|false
 */
const defaultDataLoad = {

}
const RsDataLoader = (OuterComponent,dataLoad =defaultDataLoad) =>{
    class RsDataLoaderClass extends Component {
        constructor(props){
            super(props);
            this.isVendorLoaded = false;
            this.api = Api;
            this.api.setUserToken();
            this.settings = Settings;
            this.addressPurposeCalled = false;
            this.loadSegmentValuesCalled = false;
            this.isSegmentsLoaded = false;
            this.state = {
                vendors:[]
            }
        }
        componentDidMount(){
            if(dataLoad.purpose){   
                this.addressPurpose();
            }
            if(dataLoad.vendors){
                this.loadVendors();
            }
            if(dataLoad.entity){
                this.loadEntityList();
            }
            if(dataLoad.sites){
                this.loadSiteList();
            }
            if(dataLoad.warehouse){
                this.loadWarehouse();
            }
            if(dataLoad.segments){
                this.loadSegments();
            }
            if(dataLoad.segmentsValues){
                this.loadSegmentValues();
            }
            if(dataLoad.lineaccess_level){
                this.LoadLineAccessLevel();
            }
            if(dataLoad.lineSiteStatus){
                this.loadLineSiteStatus();
            }
            if(dataLoad.employee){
                this.loadEmployee();
            }
            if(dataLoad.taxgroup){
                this.loadTaxgroup();
            }
            if(dataLoad.taxcodes){
                this.loadTaxcodes();
            }
            if(dataLoad.amendmentNature){
                this.loadAmendmentNature();
            }
            if(dataLoad.poDocumentStatus){
                this.loadDocumentStatus();
            }
        }
        loadWarehouse(){
            let dataLoaderProps = this.props.dataLoaderProps;
            let dataLoaderPropsMethods = this.props.dataLoaderPropsMethods;
            if(dataLoaderProps.warehouse.isLoaded){
                return true;
            }
            let api = Api;
            api.setUserToken();
            let searchKey = {
                code:null,
                name:null
            }
            api.axios().get(Settings.apiUrl + '/get_warehouse',searchKey).then(function(res){
                dataLoaderPropsMethods.setWarehouse(res.data.data.reverse())
            }).catch((error) => {
                console.log(error);
            })
        }
        loadEntityList(){
            let dataLoaderProps = this.props.dataLoaderProps;
            let dataLoaderPropsMethods = this.props.dataLoaderPropsMethods;
            if(dataLoaderProps.entity.isLoaded){
                return true;
            }
            let api = Api;
            api.setUserToken();
            api.axios().post(Settings.apiUrl+'/view_entity',{}).then(res=> {
                if(res.data.status == 'success'){
                    dataLoaderPropsMethods.setEntity(res.data.data)
                }
            })
        }
        loadSiteList(){
            let dataLoaderProps = this.props.dataLoaderProps;
            let dataLoaderPropsMethods = this.props.dataLoaderPropsMethods;
            if(dataLoaderProps.sites.isLoaded){
                return true;
            }
            let that = this;
            let api = Api;
            api.setUserToken();
            let searchKey = {
                    "code":null,
                    "name":null
                }
            api.axios().get(Settings.apiUrl + '/get_site',searchKey).then(function(res){
                if(res.data.status ==='success'){
                    dataLoaderPropsMethods.setSites(res.data.data)
                }
            }).catch((error) => {
                console.log(error);
            })
        }
        loadVendors(){
            if(this.isVendorLoaded){
                return;
            }
            this.isVendorLoaded = true;
            let that = this;
            this.api.axios().get(Settings.apiVendorUrl + '/vendor').then(res => {
                that.setState({
                    vendors: res.data.data
                })
            })
        }
        addressPurpose(){
            
            let that  = this;
            let dataLoaderProps = this.props.dataLoaderProps;
            let dataLoaderPropsMethods = this.props.dataLoaderPropsMethods;
            if(dataLoaderProps.purpose.purpose.length <=0 && !this.addressPurposeCalled){
                this.addressPurposeCalled = true;
                this.api.axios().get(this.settings.apiUrl + '/enum/address_purpose').then(function(res){
                    dataLoaderPropsMethods.setPurpose(res.data.data)
                })
            }
        }
        loadSegmentValues(){
            if(this.loadSegmentValuesCalled){
                return;
            }
            let api = Api;
            api.setUserToken();
            let that = this;
            
            let dataLoaderProps = this.props.dataLoaderProps;
            let dataLoaderPropsMethods = this.props.dataLoaderPropsMethods;
            api.axios().get(Settings.apiUrls.segments +'/segmentvalue').then(res => {
                let responseData = res.data.data.reverse();
                dataLoaderPropsMethods.setSegmentsValues(responseData)
            })
        }
        loadLineSiteStatus(){
            let dataLoaderProps = this.props.dataLoaderProps;
            if(dataLoaderProps.lineSiteStatus.isLoaded){
                return;
            }
            let api = Api;
            api.setUserToken();
            let dataLoaderPropsMethods = this.props.dataLoaderPropsMethods;
            api.axios().get(Settings.apiUrl +'/enum/po_linesite_status').then(res => {
                let responseData = res.data.data.reverse();
                dataLoaderPropsMethods.setLineSiteStatus(responseData)
            })
        }
        loadEmployee(){
            let dataLoaderProps = this.props.dataLoaderProps;
            if(dataLoaderProps.employee.isLoaded){
                return;
            }
            let api = Api;
            api.setUserToken();
            let dataLoaderPropsMethods = this.props.dataLoaderPropsMethods;
            api.axios().get(Settings.apiOrgUrl+'/employee',{}).then(function(res){
                dataLoaderPropsMethods.setEmployee(res.data.data)
            }).catch((res) => { 
                console.log(res.response.data.message)
            })
        }
        loadTaxgroup(){
            let dataLoaderProps = this.props.dataLoaderProps;
            if(dataLoaderProps.taxgroup.isLoaded){
                return;
            }
            let api = Api;
            api.setUserToken();
            let dataLoaderPropsMethods = this.props.dataLoaderPropsMethods;
            api.axios().get(Settings.apiUrl+'/taxgroup').then(function(res){
                dataLoaderPropsMethods.setTaxgroup(res.data.data)
            }).catch((res) => { 
                console.log(res.response.data.message)
            })
        }
        loadTaxcodes(){
            let dataLoaderProps = this.props.dataLoaderProps;
            if(dataLoaderProps.taxcodes.isLoaded){
                return;
            }
            let api = Api;
            api.setUserToken();
            let dataLoaderPropsMethods = this.props.dataLoaderPropsMethods;
            api.axios().get(Settings.apiUrl+'/taxcodes').then(function(res){
                dataLoaderPropsMethods.setTaxcodes(res.data.data)
            }).catch((res) => { 
                console.log(res.response.data.message)
            })
        }
        loadSegments(){
            if(this.isSegmentsLoaded){
                return;
            }
            this.isSegmentsLoaded = true;
            let api = Api;
            api.setUserToken();
            let dataLoaderPropsMethods = this.props.dataLoaderPropsMethods;
            api.axios().get(Settings.apiUrls.segments + '/get_segments').then(res => {
                let data = res.data.data.reverse();
                dataLoaderPropsMethods.setSegments(data)
            })
        }
        LoadLineAccessLevel(){
            let dataLoaderProps = this.props.dataLoaderProps;
            if(dataLoaderProps.lineAccessLevel.isLoaded){
                return;
            }
            let api = Api;
            api.setUserToken();
            let dataLoaderPropsMethods = this.props.dataLoaderPropsMethods;
            api.axios().get(Settings.apiUrl + '/enum/lineaccess_level').then(res => {
                let data = res.data.data.reverse();
                dataLoaderPropsMethods.setlineAccessLevel(data)
            })
        }
        loadAmendmentNature(){
            let dataLoaderProps = this.props.dataLoaderProps;
            if(dataLoaderProps.amendmentNature.isLoaded){
                return;
            }
            let api = Api;
            api.setUserToken();
            let dataLoaderPropsMethods = this.props.dataLoaderPropsMethods;
            api.axios().get(Settings.apiUrl + '/enum/POAmendment_AmendmentNature').then(res => {
                let data = res.data.data.reverse();
                dataLoaderPropsMethods.setAmentdmentNature(data)
            })
        }
        loadDocumentStatus(){
            let dataLoaderProps = this.props.dataLoaderProps;
            if(dataLoaderProps.documentStatus.isLoaded){
                return;
            }
            let api = Api;
            api.setUserToken();
            let dataLoaderPropsMethods = this.props.dataLoaderPropsMethods;
            api.axios().get(Settings.apiUrl + '/enum/POHeaderDocumentStatus').then(res => {
                let data = res.data.data.reverse();
                dataLoaderPropsMethods.setDocumentStatus(data)
            })
        }
        render() {
            if(dataLoad.vendors){
                this.props.dataLoaderProps.vendors = this.state.vendors;
            }
            return ( <OuterComponent {...this.props} />);
        }
    }
    
    const mapStateToProps = (state) => {
        return {
            dataLoaderProps:{
                entity:state.entity,
                lineAccessLevel:state.lineAccessLevel,
                sites:state.sites,
                auth:state.auth,
                language:state.language,
                locations:state.locations,
                purpose: state.addressPurpose,
                warehouse: state.warehouse,
                notes: state.notes,
                segments: state.segments,
                segmentsValues: state.segmentsValues,
                lineSiteStatus: state.lineSiteStatus,
                employee: state.employee,
                taxgroup: state.taxgroup,
                taxcodes: state.taxcodes,
                amendmentNature: state.amendmentNature,
                documentStatus: state.poDocumentStatus,
            }
        }
    }
    const mapDispatchToProps = (dispatch) => {
        return {
            dataLoaderPropsMethods: {
                setEntity: (entityList) => { dispatch({type: ActionTypes.SET_ENTITY, payload:entityList})},
                setSites: (sites) => { dispatch({type: ActionTypes.SET_SITES, payload:sites})},
                setWarehouse: (warehouse) => { dispatch({type: ActionTypes.SET_WAREHOUSE, payload:warehouse})},
                setPurpose: (purpose) => { dispatch({type:ActionTypes.SET_ALL_PURPOSE, payload:purpose})},
                setCountry: (countryList) => { dispatch({type:ActionTypes.SET_COUNTRY, payload:countryList})},
                setState: (states) => { dispatch({type:ActionTypes.SET_STATE, payload:states})},
                setCity: (cityList) => { dispatch({type:ActionTypes.SET_CITY, payload:cityList})},
                setNotes: (notes) => { dispatch({type:ActionTypes.SET_NOTES, payload:notes})},
                setSegments: (segments) => { dispatch({type:ActionTypes.SET_SEGMENTS, payload:segments})},
                setSegmentsValues: (segmentsValues) => { dispatch({type:ActionTypes.SET_SEGMENTS_VALUES, payload:segmentsValues})},
                clearNotes: () => { dispatch({type:ActionTypes.CLEAR_NOTES})},
                setlineAccessLevel: (accessLevels) => { dispatch({type:ActionTypes.SET_LINE_ACCESS_LEVEL,payload:accessLevels})},
                setLineSiteStatus: (status) => { dispatch({type:ActionTypes.SET_LINESITE_STATUS,payload:status})},
                setEmployee: (employee) => { dispatch({type:ActionTypes.SET_EMPLOYEE_LIST,payload:employee})},
                setTaxgroup: (taxgroup) => { dispatch({type:ActionTypes.SET_TAXGROUP,payload:taxgroup})},
                setTaxcodes: (taxcodes) => { dispatch({type:ActionTypes.SET_TAXCODES,payload:taxcodes})},
                setAmentdmentNature: (amendmentNature) => { dispatch({type:ActionTypes.SET_AMENDMENT_NATURE,payload:amendmentNature})},
                setDocumentStatus: (data) => { dispatch({type:ActionTypes.SET_PO_DOCUMENT_STATUS,payload:data})}
            }
        }
    }

    return connect (mapStateToProps,mapDispatchToProps) ( RsDataLoaderClass );
}

export default RsDataLoader;