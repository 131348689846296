import axios from 'axios';
import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import FormValidator from '../../../../inc/FormValidator';
import Api from '../../../../inc/Api';
import FieldsRenderer from "../../../Purchase Orders Internal/Edit PO/FieldsRenderer";
import Settings from '../../../../inc/Settings';
import { connect } from 'react-redux';
import $ from 'jquery';
import OverlayLoader from '../../../PurchaseRequisition/common/OverlayLoader';
import Popup from '../../../Popup/Popup';
import Collapse from '../../../inc/Collapse';
import { Form } from 'react-bootstrap';
import Button from '../../../inc/Button';
import Helper from '../../../../inc/Helper';
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation';
import NavigationHeder from '../../../Navigations/NavigationHeder';
import { Link } from 'react-router-dom';

let cancelTokenSource = axios.CancelToken.source();
const PRRequestPolicyPopup = ({
  pr_request_policy_id,
  reloadParentGrid,
  setShowCreateModal,
  enumDetails=[],
  language,
}) => {
  const [formData, setFormData] = useState({});

  const [apiCallBtn, setApiCallBtn] = useState(false);

  let formValidatory;

  const validationConfigure = () => {
    let formFieldConfig = [
      {
        name: "name",
        displayName: Helper.getLabel(language, "name", "Name"),
        types: ["Required"],
      },
      {
        name: "which_date_to_be_picked_if_both_provided",
        displayName: Helper.getLabel(language, "which_date_to_be_picked_if_both_provided", "Which date to be picked if both provided"),
        types: ["Required"],
      }
    ];

    if (!formValidatory) {
      formValidatory = new FormValidator(formFieldConfig, language);
    }
  };

  validationConfigure();

  let nonCompliancePolicyObjCalled = false;

  useEffect(() => {
    if (!pr_request_policy_id) {
      setFormData({});
      return;
    }
    getPRRequestPolicyByID();
  }, []);

  const getPRRequestPolicyByID = () => {
    if (nonCompliancePolicyObjCalled || !pr_request_policy_id) return;
    nonCompliancePolicyObjCalled = true;

    setApiCallBtn(true);
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(
        Settings.apiPurchaseRequisitionUrl +
          `/express_request/${pr_request_policy_id}`,
        {}
      )
      .then(function (res) {
        if (res.data.status === "success") {
          setFormData(res?.data?.data);
          setApiCallBtn(false);
        }
      })
      .catch((e) => {
        setApiCallBtn(false);
        setFormData({});
      });
  };

  const fieldsArrayGeneral = [
    {
      className: "col-lg-4",
      required: true,
      maxLength: 50,
      disable: apiCallBtn,
      label: Helper.getLabel(language, "name", "Name"),
      inputType: "text",
      id: "name",
      name: "name",
    }, 
    {
      className: "col-lg-4",
      disable: apiCallBtn,
      label: Helper.getLabel(language, "which_date_to_be_picked_if_both_provided", "Which date to pick if both provided"),
      inputType: "dropdown",
      options: enumDetails,
      reRenderRequired: true,
      required: true,
      id: "which_date_to_be_picked_if_both_provided",
      name: "which_date_to_be_picked_if_both_provided",
    },
    {
      className: "col-lg-4", 
      disable: apiCallBtn,
      label: Helper.getLabel(language, "no_of_days_from_creation_and_deliverydate", "No. of days from creation and delivery date"),
      inputType: "number",
      id: "no_of_days_from_creation_and_deliverydate",
      name: "no_of_days_from_creation_and_deliverydate",
    },
    {
      className: "col-lg-4", 
      disable: apiCallBtn,
      label: Helper.getLabel(language, "no_of_days_from_creation_and_startdate", "No. of days from creation and start date"),
      inputType: "number",
      id: "no_of_days_from_creation_and_startdate",
      name: "no_of_days_from_creation_and_startdate",
    }
  ];

  const executePOSTAPI = async () => {
    try {
      let api = Api;
      api.setUserToken();

      const res = await api.axios().post(
        Settings.apiPurchaseRequisitionUrl + "/express_request",
        {
          ...formData,
          no_of_days_from_creation_and_deliverydate: formData?.no_of_days_from_creation_and_deliverydate ? parseFloat(formData?.no_of_days_from_creation_and_deliverydate) : null,
          no_of_days_from_creation_and_startdate: formData?.no_of_days_from_creation_and_startdate ? parseFloat(formData?.no_of_days_from_creation_and_startdate) : null,
          which_date_to_be_picked_if_both_provided: formData?.which_date_to_be_picked_if_both_provided ? parseInt(formData?.which_date_to_be_picked_if_both_provided) : null
        },
        { cancelToken: cancelTokenSource.token }
      );

      if (res.data.status === "success") {
        Helper.alert(res?.data?.message);
        reloadParentGrid();
        onPopupClose();
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  };

  const executePUTAPI = async () => {
    try {
      let api = Api;
      api.setUserToken();

      const res = await api.axios().put(
        Settings.apiPurchaseRequisitionUrl + `/express_request/${pr_request_policy_id}`,
        {
          ...formData,
          no_of_days_from_creation_and_deliverydate: formData?.no_of_days_from_creation_and_deliverydate ? parseFloat(formData?.no_of_days_from_creation_and_deliverydate) : 0,
          no_of_days_from_creation_and_startdate: formData?.no_of_days_from_creation_and_startdate ? parseFloat(formData?.no_of_days_from_creation_and_startdate) : 0,
          which_date_to_be_picked_if_both_provided: formData?.which_date_to_be_picked_if_both_provided ? parseInt(formData?.which_date_to_be_picked_if_both_provided) : ""
        },
        { cancelToken: cancelTokenSource.token }
      );

      if (res.data.status === "success") {
        Helper.alert(res?.data?.message);
        reloadParentGrid();
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  };

  const onPopupClose = () => {
    $("html").removeClass("rs_popup_open");
    setShowCreateModal(false);
  };

  const moreOptions = () => {
    return (
      <div>
        <ul className='actions_nav'>
          <li>
            <Link
              to={`/pr-request-policy-history`}
            >
              View History
            </Link>
          </li> 
        </ul>
      </div>
    )
  }

  const handleSubmit = () => {
    if (!formValidatory.isValid({ ...formData })) {
      formValidatory.displayMessage();
      formValidatory.reset();
      return;
    }

    if (!pr_request_policy_id) {
      Helper.createDebouncedAPIFunction(
        [executePOSTAPI],
        setApiCallBtn,
        cancelTokenSource
      )();
    } else {
      Helper.createDebouncedAPIFunction(
        [executePUTAPI],
        setApiCallBtn,
        cancelTokenSource
      )();
    }
  };

  return (
    <Fragment>
      {apiCallBtn ? <OverlayLoader /> : null}
      <Popup autoOpen={true} width="1000px" onClose={onPopupClose}>
        <>
          <NavigationHeder moreOptions={moreOptions} hideBackButton={true} title={!pr_request_policy_id ? "Create PR Request Policy" : "Edit PR Request Policy"} />

          <Collapse
            className="mt-4 mb-4"
            open={true}
            title={Helper.getLabel(
              language,
              "general_info",
              "General Information"
            )}
          >
            <Form>
              <div className="container-fluid">
                <FieldsRenderer
                  updateState={setFormData}
                  currentState={formData}
                  fieldsArray={fieldsArrayGeneral}
                />
              </div>
            </Form>
          </Collapse>
        </>

        <div
          className="addDocumentNumber-body-buttons"
          style={{ justifyContent: "flex-end" }}
        >
          <Button
            isActive={false}
            isDisable={apiCallBtn}
            title={Helper.getLabel(language, "close", "Close")}
            onClick={onPopupClose}
          />

          <Button
            isDisable={apiCallBtn}
            className={pr_request_policy_id ? "rfq_save_btn" : "solid_btn"}
            isActive={!pr_request_policy_id}
            title={
              !pr_request_policy_id
                ? Helper.getLabel(language, "create", "Create")
                : Helper.getLabel(language, "save", "Save")
            }
            onClick={handleSubmit}
          />
        </div>
      </Popup>
    </Fragment>
  )
}
 
const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  };
};

export default connect(mapStateToProps)(PRRequestPolicyPopup);