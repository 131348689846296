import React, { Component } from 'react';
import ActionTypes from '../../inc/ActionsTypes';
import {connect} from 'react-redux';
import SettingsHeader from './SettingsHeader'
import SimpleLoading from '../Loading/SimpleLoading';
import Api from '../../inc/Api';
import Settings from '../../inc/Settings';
import { Navigate } from "react-router-dom"; 
import Sidebar from '../Header/Sidebar';
import Header from '../Header/Header';

class MasterSettingsComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
            isUserChecked:false
        }
    }
    componentDidMount(){
        let api = Api;
        let auth = this.props.auth;
        let that = this;
        if(!auth.isLogin && api.getUserToken()){
            api.setJsonHeader();
            api.setUserToken();
            api.get(Settings.apiUrl+'/user_details',{},function({data}){
                if(data && data.Status === "Success"){
                    that.props.setUser(data.data);
                }
                that.setState({
                    isUserChecked:true
                })
            })
        }else{
            that.setState({
                isUserChecked:true
            })
        }
        this.loadAppLanguage();
    }
    loadAppLanguage(){
        
    }

    render() {
        const props = this.props;
        if(!props.auth.isLogin && this.state.isUserChecked){
            return <Navigate to="/login"/>
        }else if(!props.auth.isLogin){
            return <SimpleLoading/>
        }else{
            return (
                <div className="app_master_area">
                    <div className='sidebar_contact_wrapper'>
                         <Sidebar />
                        <div className='contents_with_header'>
                            <Header />
                            <div className='rs_master_canvas'>
                                { props.children }
                            </div>
                        </div>
                    </div>
                    
                </div>
            );
        }
    }
}
const mapStateToProps = (state) => {
    return {
        auth:state.auth
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setUser:(user) => { dispatch({type:ActionTypes.SET_USER , payload:user}) },
        setAuthLoadState:(loadState) => { dispatch({type:ActionTypes.SET_AUTH_LOADING , payload:loadState}) },
    }
}
export default connect (mapStateToProps,mapDispatchToProps) (MasterSettingsComponent);
