import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Api from '../../../inc/Api'
import Gui_id_list from '../../../inc/Gui_id_list'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import { checkForRevokePermission, getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import Alert from '../../inc/Alert'
import Button from '../../inc/Button' 
import SimpleLoading from '../../Loading/SimpleLoading'
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import CreatePOPopup from '../Create PO/CreatePOPopup'
import LoadPurchaseStatus from '../../../actions/LoadPurchaseStatus'
import AgGridNew from '../../grid/ag/ag-grid-new'
import getCommonColumns from './CommonColumns'

let gridApi = null

const POArchive = (props) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [openCreateModePO, setOpenCreateModePO] = useState(false)
  const [documentStatus, setDocumentStatus] = useState([])

  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  

  const LoadPurchaseStatus = () => {
    props.LoadPurchaseStatus(props.purchaseStatus)
  }

  const getDocumentStatusList = () => {
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + "/enum/POHeaderDocumentStatus")
      .then(function (res) {
        if (res.data.status === "success") {
           setDocumentStatus(res?.data?.data)
        }
      })
      .catch((error) => {
       
      });
  }

  useEffect(()=>{
    LoadPurchaseStatus()
    getDocumentStatusList()
  },[])

  const handleGridReady = (params) => {
    gridApi = params.api
    setLoading(false)
  }

  const cellHandler = (event) => {  
    if(event.colDef.field === 'document_number') { 
      let editUrl = props.editUrl ? props.editUrl : '/edit-purchase-order';
      navigate(`${editUrl}/${props?.type==="purchase agreement"? event?.data?.agreement_id:event.data.purchase_order_id}`)
    }
    if(event.colDef.field === 'vendor_code') { 
      navigate(`/edit-vendor/${event.data.vendor_id}`)
    }
  }

  const getAdditionalParams = () => {
    let additionalParams;
    let filterPoStatus = urlParams.get('status');
    let filterDocumentStatus = urlParams.get('d_status');
    let filterAgreementId = urlParams.get('agreement_id')
    if(filterPoStatus){
      additionalParams = '&purchase_status='+filterPoStatus;
    }
    if(filterDocumentStatus){
      additionalParams = '&document_status='+filterDocumentStatus;
    }

    if(filterAgreementId && props.type==='2'){
      additionalParams = '&agreement_id='+filterAgreementId
    }

    return additionalParams;
  }

  const getAdditionalParamsString = (additionalParams) => {
    let additionalParamsString = '';
  
    if (additionalParams !== null && additionalParams !== undefined) {
      if (props.type === "purchase agreement") {
        additionalParamsString = `${additionalParams}`;
      } else {
        additionalParamsString = `&type=${props.type ? props.type : 0}${additionalParams}`;
      }
    } else {
      if (props.type === "purchase agreement") {
        additionalParamsString = ' ';
      } else {
        additionalParamsString = `&type=${props.type ? props.type : 0}`;
      }
    }
  
    return additionalParamsString;
  };
 
  const DeletePOHandler = useCallback(async (element,index,lastIndex) => { 
    let api = Api;
    api.setUserToken();
    let delPO_ID = props?.type==="purchase agreement"?element?.data?.agreement_id:element.data?.purchase_order_id;
    let endPoint = props.type==="purchase agreement" ? '/PA_header/' : '/PO_header/'
    try {
      const res = await api
        .axios()
        .delete(Settings.apiPurchaseOrderUrl+endPoint+delPO_ID)
      const rowNode = gridApi?.getRowNode(delPO_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi.applyServerSideTransaction(transaction)
      }, 200)
      if (res && index === lastIndex) {
          gridApi?.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
      }
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])

    
  const CreateModePurchaseOrder = () => {
    setOpenCreateModePO(true)
  }
 
  const Columns_Headings = getCommonColumns({
    language:props.language,
    purchaseStatus:props.purchaseStatus,
    documentStatus:documentStatus,
  })

  let additionalParams = getAdditionalParams() 
  let additionalParamsString = getAdditionalParamsString(additionalParams);
  
  const getDeleteBtnAccessFromSecurity = () => {
    const typeMapping = {
      'purchase agreement': 'Blanket Agreement',
      '0': 'Purchase Order',
      '1': 'Contract',
      '2': 'Release Order'
    };
  
    const permissionType = typeMapping[props?.type];
    return permissionType ? checkForRevokePermission(props?.user?.roles, permissionType) : false;
  };
 
  const renderGuiList = () => { 
    switch (props?.type) {
      case '0':
        return {
          screen: Gui_id_list.procure.purchase_orders.purchase_orders,
          addBtn: Gui_id_list.procure.purchase_orders.purchase_orders_main_create_button,
          deleteBtn: Gui_id_list.procure.purchase_orders.purchase_orders_main_delete_button,
        }; 
      case '1':
        return {
          screen: Gui_id_list.procure.contract_orders.contract_orders_main,
          addBtn: Gui_id_list.procure.contract_orders.contract_orders_main_create_button,
          deleteBtn: Gui_id_list.procure.contract_orders.contract_orders_main_delete_button
        }; 
      case '2':
        return {
          screen: Gui_id_list.procure.release_orders.release_orders_main,
          addBtn: Gui_id_list.procure.release_orders.release_orders_main_create_button,
          deleteBtn: Gui_id_list.procure.release_orders.release_orders_main_delete_button
        };
      case 'purchase agreement':
        return {
          screen: Gui_id_list.procure.purchase_agreement.purchase_agreement_main,
          addBtn: Gui_id_list.procure.purchase_agreement.purchase_agreement_main_create_button,
          deleteBtn: Gui_id_list.procure.purchase_agreement.purchase_agreement_main_delete_button
        };
      default:
        return '';   
    }
  };
  
  let security = props?.security;

  if (renderGuiList() !== "" && !security.canView(renderGuiList()?.screen)) {
    return (
      <Fragment>
        <Alert 
          message="You do not have any access to the following screen, please contact your administrator!" 
          type="danger" 
        />
      </Fragment>
    );
  }

  return (
    <div className='purchase_orders_archive'>
    {openCreateModePO ? <CreatePOPopup title={props.createFormTitle} editUrl = {props.editUrl} type={props.type} ClosePopup={setOpenCreateModePO} source_id={props.source_id} agreement_id={urlParams.get('agreement_id')} /> : ''}
      <div className='container-fluid'>
        <NavigationHeder backUrl={props.backUrl ? props.backUrl : '/procure'} title={ props.title ? props.title : Helper.getLabel(props.language,'purchase_orders_all',"Purchase Orders - All")} hideMoreBtn={true}>
          <Button onClick={CreateModePurchaseOrder} isActive={true} icon='/images/icons/plus-white.svg' title={Helper.getLabel(props.language,'new',"New")} isDisable={renderGuiList() !== "" && !security.canCreate(renderGuiList()?.addBtn)} /> 
        </NavigationHeder> 

      {loading ? <SimpleLoading /> : null}
        <AgGridNew  
            apiUrl = {props.type==="purchase agreement"?Settings.apiPurchaseOrderUrl+`/PA_header`:Settings.apiPurchaseOrderUrl+`/PO_header`}
            additionalParams={additionalParamsString}
            filterAdditionalParams={props.type=== "purchase agreement"?{}:`&type=${props.type ?? 0}${additionalParams?additionalParams:''}`}
            pagination={true}
            columnDefs={Columns_Headings}
            onGridReady={handleGridReady}
            handleDeleteSelectedRows={DeletePOHandler}
            onCellClicked={cellHandler}
            hideAddBtn={true}
            uniqueField={props.type==="purchase agreement"?'agreement_id':'purchase_order_id'}
            noNeedRefresh={true}
            hideDeleteBtn={(renderGuiList() !== "" && !security.canDelete(renderGuiList()?.deleteBtn)) || getDeleteBtnAccessFromSecurity()}
            height={500}
            gridId={`po-internal-list-${props.type}`}
          />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      user:state.auth.user,
      purchaseStatus:state.purchaseStatus,
      language:state.language
    }
  }
 
const setDispatchToProps = (dispatch) => {
  return {
    LoadPurchaseStatus: (oldData) => { LoadPurchaseStatus(dispatch,oldData)}
  }
}

export default connect(mapStateToProps,setDispatchToProps) (MasterComponentWraper(((POArchive))))