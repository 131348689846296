import React, { Component } from 'react';
import InputPlaceholder from './InputPlaceholder';

class Checkbox extends Component {
    constructor(props){
        super(props);
        this.state = {
            isChecked : (this.props.isChecked =='yes' || this.props.isChecked == true ) ? true : false,
            isRequired: this.props.required ? true : false,
            disableWithoutIcon: this.props.disableWithoutIcon ? true : false,
            restrictCheckboxSelection: this.props.restrictCheckboxSelection ? true : false
        }
    }
    componentDidUpdate(prevProps){
        if(this.props.isChecked != prevProps.isChecked){
            this.setState({
                isChecked : (this.props.isChecked =='yes' || this.props.isChecked == true ) ? true : false
            })
        }
    }
    onChangeHandler(e){
        let that = this; 

        this.setState({
            isChecked : that.props?.restrictCheckboxSelection ? false : !this.state.isChecked
        },function(){
            if(that.props.onChange && typeof that.props.onChange ==='function'){
                that.props.onChange(e.target.name, ( that.state.isChecked && !that.props?.restrictCheckboxSelection ? 'on' : 'off' ) )
            }
        })
    }
  
    render() {
        let id = this.props.id ? this.props.id : Math.floor(Math.random() * 100);
        let dissableCheckboxIcon = '/images/icons/checked-yes.svg';
        
        return (
            <div className="form-group custom-control custom-checkbox rs_input_checkbox">
                { this.props.disable ? <span className='checkbox_dissable' style={{backgroundImage:'url('+dissableCheckboxIcon+')'}}></span> : ""}
                <input type="checkbox" disabled={this.state.disableWithoutIcon} checked={this.state.isChecked} onChange={ e => this.onChangeHandler(e) } className= { "custom-control-input " + (this.props.disable  ? ' d-none' : '') } name={this.props.name} id={id}/>
                { this.props.disable ? <label className="custom-control-label">{this.props.label} {this.state.isRequired ? <span>*</span> : ''}</label> : <label className="custom-control-label" htmlFor={id}>{this.props.label} {this.state.isRequired ? <span>*</span> : ''}</label>} 
            </div>
        );
    }
}

export default Checkbox;