import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Gui_id_list from '../../../../inc/Gui_id_list'
import MasterComonent from '../../../Backend/MasterComonent'
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles'
import { connect } from 'react-redux'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import Helper from '../../../../inc/Helper'
import Tabs from '../../../Widgets/Tabs/Tabs'
import MasterSidebar from '../../../Sidebars/MasterSidebar'
import NoteAttachmentHistory from '../../../Widgets/NoteAttachmentHistory'
import BlueCallToAction from '../../../Widgets/BlueCallToAction/BlueCallToAction'
import Api from '../../../../inc/Api'
import Settings from '../../../../inc/Settings'
import SimpleLoading from '../../../Loading/SimpleLoading'
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation'
import RFQBiddingRequirement from '../RFQBiddingDetails/RFQBiddingRequirement'
import OverlayLoader from '../../../PurchaseRequisition/common/OverlayLoader'

//component to render site properties
const RFQBiddingInformationModule = (props) => {
  const nonNullEntries = Object.entries(props.data)
  .map(([key, value]) => [key.replace(/_/g, " "), value])
  .filter(([key, value]) =>
    value !== null &&
    value !== "" &&
    !key.toLowerCase().includes("id") &&
    !(typeof value === "number" && Number.isInteger(value) && key !== "version" && key !== "revision" ) &&
    !key.toLowerCase().includes("scope of work") &&
    !key.toLowerCase().includes("material service not included") &&
    !key.toLowerCase().includes("requirement description")
);

  const defaultNav = [
    {
      title: Helper.getLabel(props.language, "hide_tab", "hide_tab"),
      isActive: true,
    },
    {
      title: Helper.getLabel(props.language, "requirements", "Requirements"),
    },
  ]

  return (
    <Tabs
      navs={defaultNav}
      showAll={true}
      scrollOffset="300"
      disableSticky={true}
      addComponentTop={props.addComponentTop}
      addComponentRight={props.addComponentRight}
    >
      <div className="rfq-bidding-information mb-4">
          <div className="rfq-information-title">
            <h2 className="grid_label_rfq">General Information</h2>
          </div>
          <div className="rfq-information-inner">
            {nonNullEntries.map(([key, value]) => {
              let renderedValue = value
              if (typeof value === "boolean") {
                renderedValue = value ? "True" : "False"
              }
              if(typeof value==='string' && value?.length>40){
                renderedValue = value?.substring(0, 30) + "..." || "-"
              }
              if(typeof value === 'string' && value?.includes("_")){
                renderedValue = value?.replace("_", " ")
              }

              if (!isNaN(value) && typeof value === "string" && value.match(/^-?\d+(\.\d+)?$/)) {
                renderedValue = Helper.addThousandSeparator(value);
              }
              return (
                <div className="bidding-info-row" key={key}>
                  <span className="bidding-row-key">{key.replace("_", " ")}</span>
                  <span className="bidding-row-value">{renderedValue}</span>
                </div>
              )
            })}
          </div>
        </div>
      <div className="rfq-bidding-information mb-4">
        <div className="rfq-information-title mb-4">
          <h2 className="grid_label_rfq">Requirements</h2>
        </div>
        <div className="bidding_requirement p-0">
          <RFQBiddingRequirement
            scopeOfWork={props?.data?.scope_of_work}
            materialsAndServicesNotIncluded={
              props?.data?.material_service_not_included
            }
            agreementTerms={props?.data?.requirement_description}
            disable={true}
          />
        </div>
      </div>
    </Tabs>
  )
}

const BiddingGeneralDetails = (props) => {
  const [lineData, setLineData] = useState({})
  const [loading, setLoading] = useState(false)

  //function to get params
  const location = useLocation()
  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }

  //line Id
  const lineId = getSearchParam("line_id")

  //To get header data
  const data = location.state

  //Api call to get site field of a line
  const get_line_data = () => {
    let api = Api
    api.setUserToken()
    setLoading(true)
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + `/line/${lineId}/${data.source_id}`)
      .then(function (res) {
        if (res.data.status === "success") {
          setLineData(res.data.data)
          setLoading(false)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
        setLoading(false)
      })
  }

  useEffect(() => {
    get_line_data()
  }, [])

  // right side component
  const addComponentRight = () => {
    return (
      <MasterSidebar>
        <NoteAttachmentHistory
          notesource_integrator={lineData?.RFQ_vendorreply_id}
          attachmentsource_integrator={lineData?.RFQ_vendorreply_id}
          source_id={Settings.source_id.RfqVendorReply}
          hideHistory={true}
        />
      </MasterSidebar>
    )
  }

  //top header
  const addComponentTop = () => {
    let items = [
      {
        title: Helper.getLabel(props.language, "rfq_reply_id", "RFQ Reply Id"),
        subtitle: data?.document_number,
      },
      {
        title: Helper.getLabel(props.language, "title", "Title"),
        subtitle:
          data?.title?.length > 30
            ? data?.title?.substring(0, 30) + "..." || "-"
            : data?.title || "-",
      },
    ]

    return (
      <div className="rfq_detail_form">
        <div className="rfq_details_external_action bidding-details-top-header rfq-internal-blue">
          <BlueCallToAction items={items} tags={null} />
        </div>
        <div className="mb-4" />
      </div>
    )
  }

  return (
    <div className="request_for_quotation_detail_screen">
      {loading ? (
        <OverlayLoader isLogoCenter={true} />
      ) : (
        <MasterComonent>
          <NavigationHeder
            // backUrl={`/rfq-vendor-reply/rfq-bidding-details?RFQ_vendorreply_id=${lineData?.RFQ_vendorreply_id}`}
            title={`<span style="color:#313638;">${Helper.getLabel(props.language, 'rfq', "Request for Quotation")} #</span> ${data?.document_number || ''}`}
            hideMoreBtn={true}
          ></NavigationHeder>
          <div className="tabs_default">
            <RFQBiddingInformationModule
              addComponentRight={addComponentRight}
              addComponentTop={addComponentTop}
              data={lineData}
            />
          </div>
        </MasterComonent>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

const SecurityOptions = {
  gui_id:
    Gui_id_list.procure.request_for_quotation
      .request_for_quotation_create_screen,
}

export default connect(mapStateToProps)(
  ApplySecurityRoles(BiddingGeneralDetails, SecurityOptions),
)
