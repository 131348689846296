import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react' 
import FormValidator from '../../../inc/FormValidator';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import Popup from '../../Popup/Popup';
import { connect } from 'react-redux';
import Helper from '../../../inc/Helper';
import Button from '../../inc/Button';
import { Form } from 'react-bootstrap';
import $ from 'jquery';
import Collapse from '../../inc/Collapse';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';
import FieldsRenderer from '../../Purchase Orders Internal/Edit PO/FieldsRenderer';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';

let cancelTokenSource = axios.CancelToken.source();
const EmployeePositionModal = ({position_id, reloadParentGrid, setShowCreateModal, language}) => {
    const [positionList, setPositionList] = useState([]);
    const [formData, setFormData] = useState({});  

    const [apiCallBtn, setApiCallBtn] = useState(false);

    let formValidatory;   

    const validationConfigure = () => {
        let formFieldConfig = [  
            { name:'code', displayName:Helper.getLabel(language,'1807007','Code'), types:['Required'] },
            { name:'name', displayName:Helper.getLabel(language,'1808008','Name'), types:['Required'] }  
        ];

        if(!formValidatory) {
            formValidatory = new FormValidator(formFieldConfig, language)
        }; 
    };

    validationConfigure();

    let positionListCalled = false; 
    let positionObjCalled = false;

    useEffect(() => {
        getPositions();
        if(!position_id) {
            setFormData({});
            return;
        }
        getPositionById(); 
    }, []);

    const getPositions = () => {
        if (positionListCalled) return;
        positionListCalled = true;

        let api = Api; 
        api.setUserToken();
        api
            .axios()
            .get(Settings.apiOrgUrl + `/get_active_postion`, {})
            .then(function (res) {
                if (res.data.status === "success") {
                    setPositionList(res?.data?.data?.filter((item) => item.position_id !== position_id)?.map((item) => {
                        return {
                            value: item.position_id,
                            label: item.name
                        }
                    }));
                }
            });
    };

    const getPositionById = () => {
        if (positionObjCalled) return;
        positionObjCalled = true;

        setApiCallBtn(true);
        let api = Api;
        api.setUserToken();
        api
            .axios()
            .get(Settings.apiOrgUrl + `/position/${position_id}`, {})
            .then(function (res) {
                if (res.data.status === "success") { 
                    setFormData(res?.data?.data);
                    setApiCallBtn(false);
                }
            })
            .catch((e) => {
                setApiCallBtn(false);
                setFormData({});
            });
    };
  
    const fieldsArrayGeneral = [
        {className:'col-lg-4', required: true, maxLength: 20, disable: apiCallBtn, label: Helper.getLabel(language,'code',"Code"), inputType: "text", id:'code', name:'code'},
        {className:'col-lg-4', required: true, maxLength: 80, disable: apiCallBtn, label: Helper.getLabel(language,'name',"Name"), inputType: "text", id:'name', name:'name'}, 
        {className:'col-lg-4', reRenderRequired: true, placeHolder: 'Select parent position', disable: apiCallBtn, inputType: "dropdown", label: Helper.getLabel(language,'parent_position',"Parent position"), options: positionList, id:'parent_position_id', name:'parent_position_id'},
        {className:'col-lg-4', maxLength: 20, disable: apiCallBtn, label: Helper.getLabel(language,'external_code',"External code"), inputType: "text", id:'external_code', name:'external_code'},
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'is_active',"isActive"), inputType: "checkbox", id:'status', name:'status'}
    ];

    const executePOSTAPI = async () => {
        try { 
            let api = Api;
            api.setUserToken();
        
            const res = await api.axios().post(Settings.apiOrgUrl + `/EMPosition`, {
                ...formData,
                status: formData?.status == 'on',
                parent_position: false,
                parent_position_id: formData?.parent_position_id !== "" ? formData?.parent_position_id : null
            },
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res?.data?.message); 
                reloadParentGrid();
                onPopupClose();
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        }
    };

    const executePUTAPI = async () => {
        try { 
            let api = Api;
            api.setUserToken();
        
            const res = await api.axios().put(Settings.apiOrgUrl+ `/position/${position_id}`, {
                ...formData,
                status: formData?.status == 'on',
                parent_position: false,
                parent_position_id: formData?.parent_position_id !== "" ? formData?.parent_position_id : null
            },
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res?.data?.message); 
                reloadParentGrid();
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        }
    };

    const onPopupClose = () => {
        $("html").removeClass("rs_popup_open");
        setShowCreateModal(false);
    };

    const handleSubmit = () => { 
        if(!formValidatory.isValid({...formData})){
            formValidatory.displayMessage();
            formValidatory.reset();
            return;
        };
  
        if(!position_id) {
            Helper.createDebouncedAPIFunction( 
                [executePOSTAPI], 
                setApiCallBtn,
                cancelTokenSource
            )();
        } else {
            Helper.createDebouncedAPIFunction( 
                [executePUTAPI], 
                setApiCallBtn,
                cancelTokenSource
            )();
        };
    };

    return (
        <Fragment> 
            { apiCallBtn ? <OverlayLoader /> : null }
            <Popup
                autoOpen={true} 
                width="900px"
                onClose={onPopupClose} 
            >
                <>
                    <h5 className="create_rfq_modal__title">{!position_id?'Create Employee Position':'Edit Employee Position'}</h5>

                    <Collapse
                        className="mt-4 mb-4" open={true}
                        title={Helper.getLabel(
                            language,
                            "general_info",
                            "General Information"
                        )}
                    >
                        <Form>
                            <div className='container-fluid'>
                                <FieldsRenderer updateState={setFormData} currentState={formData} fieldsArray={fieldsArrayGeneral} />
                            </div>
                        </Form> 
                    </Collapse>
                </>

                <div className="addDocumentNumber-body-buttons" style={{ justifyContent: "flex-end" }}>
                    <Button
                        isActive={false} 
                        isDisable={apiCallBtn}
                        title={Helper.getLabel(language, "close", "Close")}
                        onClick={onPopupClose}
                    />

                    <Button
                        isDisable={apiCallBtn}
                        className={position_id?"rfq_save_btn":"solid_btn" } 
                        isActive={!position_id}
                        title={!position_id?Helper.getLabel(language, "create", "Create"):Helper.getLabel(language, "save", "Save")}
                        onClick={handleSubmit}
                    />
                </div>
            </Popup>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        appOptions: state.options,
        auth: state.auth,
        language: state.language,
    };
};

export default connect(mapStateToProps) (EmployeePositionModal);