import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import Popup from "../../Popup/Popup";
import Helper from "../../../inc/Helper";
import Input from "../../Forms/Input";
import Collapse from "../../inc/Collapse";
import Dropdown from "../../Forms/Dropdown";
import Api from "../../../inc/Api";
import Settings from "../../../inc/Settings";
import { Form } from "react-bootstrap";
import Button from "../../inc/Button";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import NewInputDatePicker from "../../Forms/NewInputDatePicker";
import DatepickerFunctions from "../../../inc/DatepickerHelper";
import { getPopupMessageBasedOnStatus, objectIsEmpty } from "../../../inc/Validation";
import FormValidator from "../../../inc/FormValidator";
import axios from "axios";
import OverlayLoader from "../common/OverlayLoader";
import RFQCommonApiCalls from "../../RequestForQuotation/RFQCommonApiCalls";

let cancelTokenSource = axios.CancelToken.source();
const CreatePRModal = ({
  language,
  setShowCreatePRModal,
  auth
}) => {
  const [prHeader, setPRHeader] = useState({
    accounting_date:new Date(),
    document_date: new Date(),
    delivery_date:new Date(),
  });
  const [saveBtnApi, setSaveBtnApi] = useState(false);
  
  const navigate = useNavigate();
 
  let entityRefData = useRef()
  let siteRefData = useRef()
  let warehouseRefData = useRef()

  const [entityOptions, setEntityOptions] = useState([])
  const [siteOptions, setSiteOptions] = useState([])
  const [warehouseOptions, setWarehouseOptions] = useState([])
  const [currencyOptions,setCurrencyOptions]=useState([])
  const [selectedEntity, setSelectedEntity] = useState(auth?.user?.Employee?.Entity_id ?? null);
  const [selectedSite, setSelectedSite] = useState(auth?.user?.Employee?.Site_id ?? null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(auth?.user?.Employee?.Warehouse_id ?? null);
  const [selectedCurrency,setSelectedCurrency]=useState(auth?.user?.default_currency_id ?? null)
  const [siteAdditionalParams,setSiteAdditionalParams]=useState(auth?.user?.Employee?.Entity_id?{entity_id:auth?.user?.Employee?.Entity_id}:{})
  const [warehouseAdditionalParams,setWarehouseAdditionalParams]=useState(auth?.user?.Employee?.Site_id?{site_id:auth?.user?.Employee?.Site_id}:{})
  const [numberSequence,setNumberSequence]=useState({})
  

  let validator 

  const api = Api
  const commonApiCalls = new RFQCommonApiCalls()
  
  const validationConfigure = () => {
    let fieldConfig = [ 
      {name:'description', displayName:Helper.getLabel(language,'description','Description'),types:['Required']},  
      {name:'entity_id', displayName:Helper.getLabel(language,'entity_id','Entity id'),types:['Required']},
      {name:'site_id', displayName:Helper.getLabel(language,'site_id','Site id'),types:['Required']},
      {name:'warehouse_id', displayName:Helper.getLabel(language,'warehouse_id','Warehouse id'),types:['Required']},
      {name:'document_date',displayName:Helper.getLabel(language,'document_date','Document date'),types:['Required'] },
      {name:'accounting_date',displayName:Helper.getLabel(language,'accounting_date','Accounting date'),types:['Required'] },
      {name:'currency_id',displayName:Helper.getLabel(language,'currency','Currency'),types:['Required'] },
    ]
    if(numberSequence?.ns_type==="manual"){
      fieldConfig?.push({name:'document_number', displayName:Helper.getLabel(language,'document_no','Document number'),types:['Required']},  )
    }
    if(!validator) {
      validator = new FormValidator(fieldConfig,language)
    } 
  }

  validationConfigure()

  const getAllEntity = () => {
    api
      .axios()
      .get(Settings.loginUrl + '/get_entities')
      .then((res) => {
        if (res.data.status === 'success') {
          setEntityOptions(res?.data?.data?.map((item) => {
            return {
              value: item?.entity_id,
              label: item?.name,
            }
          }))
        }
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, 'error')
      })
  }

  const getSites = async () => {
    const res = await commonApiCalls.getAllSites()
    setSiteOptions(res?.map((item) => {
      return {
        value: item?.site_id,
        label: item?.name,
      }
    }))
  }

  const getWarehouse = async () => {
    const res = await commonApiCalls.getAllWarehouses()
    setWarehouseOptions(res?.map((item) => {
      return {
        value: item?.warehouse_id,
        label: item?.name,
      }
    }))
  }

  const getCurrencies = async () => {
    const res = await commonApiCalls.getAllCurrencies()
    setCurrencyOptions(res?.map((item) => {
      return {
        value: item?.currency_id,
        label: `${item.currency_code} - ${item.currency_name}`,
      }
    }))
  }
 
  useEffect(()=>{
    getAllEntity()
    getSites()
    getWarehouse()
    getCurrencies()
    getNumberSequenceData(auth?.user?.Employee?.Entity_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const onPRPopupClose = () => {
    $('html').removeClass('rs_popup_open')
    setShowCreatePRModal(false)
  }
 
  // it's a entity function, here we apply multiple filter based on condition...
  const handleEntityChange = (e) => {
    setSelectedEntity(e.target.value)
    if (e.target.value) {
      setSiteAdditionalParams({})
      setWarehouseAdditionalParams({})
      setSelectedSite(null)
      setSelectedWarehouse(null)
      setSiteAdditionalParams({
        entity_id:e.target.value
      })
      getNumberSequenceData(e.target.value)
      setWarehouseAdditionalParams({
        entity_id:e.target.value
      })
      getNumberSequenceData(e.target.value)
    } else {
      setSiteAdditionalParams({})
      setWarehouseAdditionalParams({})
    }
  }
  // it's a site function, here we apply multiple filter based on condition...
  const handleSiteChange = (e) => {
    setSelectedSite(e.target.value)
    if (e.target.value) {
      const siteData = siteRefData?.current?.find((item)=>item.site_id === parseInt(e.target.value))
      setSiteAdditionalParams({
        entity_id:siteData?.entity_id
      })
      getNumberSequenceData(siteData?.entity_id)
      setWarehouseAdditionalParams({})
      setWarehouseAdditionalParams({
        site_id:e.target.value
      })
      setSelectedEntity(siteData?.entity_id)
      setSelectedWarehouse(siteData?.warehouse_id)
    } else {
      let resSite = siteOptions.filter((item) => parseInt(item?.entity_id) === parseInt(selectedEntity));
      let resWerehouse = warehouseOptions.filter((item) => parseInt(item?.entity_id) === parseInt(selectedEntity));
      setSiteOptions(resSite.map((item) => {
        return {
          value: item?.site_id,
          label: item?.name,
        }
      }));
      setWarehouseOptions(resWerehouse.map((item) => {
        return {
          value: item?.warehouse_id,
          label: item?.name,
        }
      }));
      setSelectedWarehouse(null);
    }
  }
  // it's a site function, here we apply multiple filter based on condition...
  const handleWarehouseChange = (e) => {
    setSelectedWarehouse(e.target.value)
    if (e.target.value) {
      setSiteAdditionalParams({})
      setWarehouseAdditionalParams({})
      const warehouseData = warehouseRefData?.current?.find((item)=>item.warehouse_id === parseInt(e.target.value))
      getNumberSequenceData(warehouseData?.entity_id)
      setSelectedSite(warehouseData?.site_id)
      setSelectedEntity(warehouseData?.entity_id)
    }
  }

  const mapEntityItems = (item) => {
    return {
        id:item.entity_id,
        text:item.name
    }
  }

  const mapSiteItems = (item) => {
    return {
        id:item.site_id,
        text:item.name
    }
  }

  const mapWarehouseItems = (item) => {
    return {
        id:item.warehouse_id,
        text:item.name
    }
  }
     
  const executePostApiCallforPR = async (payload) => {
    try {
      const api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiPurchaseRequisitionUrl + "/pr_header", payload, { cancelToken: cancelTokenSource.token });
  
      if (res.data.status === "success") {
        Helper.alert(res?.data?.message, "success"); 
        navigate(`/purchase-requisition/shop?pr_view_id=${res?.data?.requisition_header_id}`) 
        setShowCreatePRModal(false);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  };
   
  const handleSubmit = () => {
      const payLoad = {
        ...prHeader,
        document_number:numberSequence?.ns_type==="continuous"?null:prHeader?.document_number,
        entity_id: parseInt(selectedEntity),
        site_id: parseInt(selectedSite),
        warehouse_id: parseInt(selectedWarehouse),
        description: prHeader?.description ?? null,
        accounting_date:DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(prHeader?.accounting_date)),
        document_date:DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(prHeader?.document_date)),
        delivery_date:DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(prHeader?.delivery_date)),
        currency_id:parseInt(selectedCurrency)
      };
      let validData = {
        ...payLoad
      }

      if(!validator.isValid(validData)){
        validator.displayMessage();
        validator.reset() 
        return;
      };

      Helper.createDebouncedAPIFunction([
        async () => await executePostApiCallforPR(payLoad)
      ], setSaveBtnApi, cancelTokenSource)();
  };

  const getNumberSequenceData = async (entity_id) => {
    try{
      let api = Api
      api.setUserToken();
      const numberSequenceRecord = await api.axios().get(Settings.apiUrl + `/ns_status/${entity_id?entity_id:prHeader?.entity_id}/${Settings.source_id.purchase_requisition}`)
      if (numberSequenceRecord?.status===200) {
        setNumberSequence(numberSequenceRecord?.data)
      }
    }catch(error){
      setNumberSequence({})
    }
  }

  const handleInputChange = (e) => {
    setPRHeader({
      ...prHeader,
      [e.target.name]:e.target.value,
    });
  };

  const datePickerHandler = (date,name) => {
    setPRHeader({
      ...prHeader,
      [name]:date,
    });
  };

  const mainTitle = (type) => {
    return Helper.getLabel(
      language,
      "create_purchase_requisition",
      "Create Purchase Requisition"
    );
  };
  return (
    <>
      {saveBtnApi ? <OverlayLoader /> : null}
      <Popup
        autoOpen={true}
        width='900px'
        onClose={onPRPopupClose}
      >
        <>
          <h5 className='create_rfq_modal__title'>{mainTitle()}</h5>

          <Collapse className='mt-4 mb-4 ' open={true} title={Helper.getLabel(language, 'enter_the_following_information', 'Enter the following information')}>
            <Form className='addVendorModalForm' style={{ gap: '20px' }}>
              <Input
                label={`${Helper.getLabel(language, 'document_number', 'Document number')}`}
                disable={objectIsEmpty(numberSequence) || numberSequence?.ns_type === 'continuous'}
                onChange={handleInputChange}
                inputType='text'
                name='document_number'
                value={prHeader?.document_number}
                required={numberSequence?.ns_type === 'manual'}
              />
              <Input label={`${Helper.getLabel(language, 'description', 'Description')}`} onChange={handleInputChange} inputType='text' name='description' value={prHeader?.description} required={true} maxLength={255} />

              <Dropdown
                label={`${Helper.getLabel(language, 'entity', 'Entity')}`}
                options={entityOptions || []}
                apiUrl={Settings.loginUrl + '/get_entities'}
                pagination={true}
                name="entity_id"
                mapFunction={mapEntityItems}
                allDataRef={entityRefData}
                placeHolder={`${Helper.getLabel(language, 'select_entity', 'Select entity')}`}
                id='select_entity_id'
                reRenderRequired={true}
                onChange={(e) => handleEntityChange(e)}
                value={selectedEntity}
                required={true}
              />

              <Dropdown
                label={`${Helper.getLabel(language, 'site', 'Site')}`}
                options={siteOptions ? siteOptions : []}
                name="site_id"
                placeHolder={`${Helper.getLabel(language, 'select_site', 'Select site')}`}
                onChange={(e) => handleSiteChange(e)}
                value={selectedSite}
                id='site'
                reRenderRequired={true}
                apiUrl={Settings.loginUrl + '/get_site'}
                pagination={true}
                mapFunction={mapSiteItems}
                allDataRef={siteRefData}
                additionalParams={siteAdditionalParams}
                required={true}
              />

              <Dropdown
                label={`${Helper.getLabel(language, 'warehouse', 'Warehouse')}`}
                options={warehouseOptions ? warehouseOptions : []}
                placeHolder={`${Helper.getLabel(language, 'select_warehouse', 'Select warehouse')}`}
                apiUrl={Settings.loginUrl + '/get_warehouse'}
                name="warehouse_id"
                pagination={true}
                mapFunction={mapWarehouseItems}
                allDataRef={warehouseRefData}
                reRenderRequired={true}
                onChange={(e) => handleWarehouseChange(e)}
                additionalParams={warehouseAdditionalParams}
                value={selectedWarehouse}
                id='warehouse'
                required={true}
              />

              <Dropdown
                label={`${Helper.getLabel(language, 'currency', 'Currency')}`}
                options={currencyOptions ? currencyOptions : []}
                placeHolder={`${Helper.getLabel(language, 'select_currency', 'Select currency')}`}
                reRenderRequired={true}
                onChange={(e) => {setSelectedCurrency(e.target.value)}}
                value={selectedCurrency}
                name="currency_id"
                required={true}
                id='currency_pr'
              />

              <NewInputDatePicker label={Helper.getLabel(language, 'document_date', 'Document date')} name='document_date' id='document_date_pr_1' value={prHeader.document_date} onChange={datePickerHandler} required={true} />

              <NewInputDatePicker label={Helper.getLabel(language, 'accounting_date', 'Accounting date')} name='accounting_date' id='accounting_date_pr_1' value={prHeader.accounting_date} onChange={datePickerHandler} required={true}/>

              <NewInputDatePicker label={Helper.getLabel(language, 'delivery_date', 'Delivery date')} name='delivery_date' id='delivery_date_pr_1' value={prHeader.delivery_date} onChange={datePickerHandler} />
            </Form>
          </Collapse>
          <div className='addDocumentNumber-body-buttons' style={{ justifyContent: 'flex-end' }}>
              <Button
                isActive={false}
                title={Helper.getLabel(language, 'close', 'Close')}
                onClick={onPRPopupClose}
              />
              <Button
                className='solid_btn'
                isActive={true}
                isDisable={saveBtnApi}
                title={Helper.getLabel(language, 'create', 'Create')}
                onClick={() => {
                  $('html').removeClass('rs_popup_open')
                  handleSubmit()
                }}
              />
          </div>
        </>
      </Popup>
    </>
  )
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  };
};

export default connect(mapStateToProps)(CreatePRModal);