import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';
import FormValidator from '../../../inc/FormValidator';
import Popup from '../../Popup/Popup';
import Collapse from '../../inc/Collapse';
import { Form } from 'react-bootstrap';
import FieldsRenderer from '../../Purchase Orders Internal/Edit PO/FieldsRenderer';
import Button from '../../inc/Button';
import $ from 'jquery';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import axios from 'axios';
import Helper from '../../../inc/Helper';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';

let cancelTokenSource = axios.CancelToken.source();
const PaymentTermsModal = ({payment_term_id, reloadParentGrid, setShowCreateModal, language}) => { 
    const [formData, setFormData] = useState({}); 

    const [apiCallBtn, setApiCallBtn] = useState(false);

    let formValidatory;

    const validationConfigure = () => {
        let formFieldConfig = [  
            {name:'code', displayName:Helper.getLabel(language,'code','Code'), types:['Required']}, 
            {name:'name', displayName:Helper.getLabel(language,' name','Name'), types:['Required']},  
            {name:'days', displayName:Helper.getLabel(language,'days','Days'),types:['Required', 'Number']} 
        ];

        if(!formValidatory) {
            formValidatory = new FormValidator(formFieldConfig, language)
        }; 
    };

    validationConfigure();
 
    let paymentTermObjCalled = false;

    useEffect(() => {
        if(!payment_term_id) {
            setFormData({});
            return;
        }
        getPaymentTermByID(); 
    }, []);
 
    const getPaymentTermByID = () => {
        if (paymentTermObjCalled) return;
        paymentTermObjCalled = true;

        setApiCallBtn(true);
        let api = Api;
        api.setUserToken();
        api
            .axios()
            .get(Settings.apiVendorUrl+`/payment_term/${payment_term_id}`, {})
            .then(function (res) {
                if (res.data.status === "success") { 
                    setFormData(res?.data?.data);
                    setApiCallBtn(false);
                }
            })
            .catch((e) => {
                setApiCallBtn(false);
                setFormData({});
            });
    };

    const fieldsArrayGeneral = [
        {className:'col-lg-4', required: true, maxLength: 10, disable: apiCallBtn, label: Helper.getLabel(language,'code',"Code"), inputType: "text", id:'code', name:'code'},
        {className:'col-lg-4', required: true, maxLength: 100, disable: apiCallBtn, label: Helper.getLabel(language,'name',"Name"), inputType: "text", id:'name', name:'name'}, 
        {className:'col-lg-4', required: true, disable: apiCallBtn, label: Helper.getLabel(language,'days',"Days"), inputType: "number", id:'days', name:'days'},
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'is_active',"isActive"), inputType: "checkbox", id:'is_active', name:'is_active'},
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'is_default',"isDefault"), inputType: "checkbox", id:'is_default', name:'is_default'},
    ];

    const executePOSTAPI = async () => {
        try { 
            let api = Api;
            api.setUserToken();

            const res = await api.axios().post(Settings.apiVendorUrl + '/paymentterm', {
                ...formData,
                entity_id: null,
                is_active: formData.is_active == 'on',
                is_default: formData.is_default == 'on' ? 1 : 0
            },
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res?.data?.message); 
                reloadParentGrid();
                onPopupClose();
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        }
    };

    const executePUTAPI = async () => {
        try { 
            let api = Api;
            api.setUserToken();

            const res = await api.axios().put(Settings.apiVendorUrl + `/payment_term/${payment_term_id}`, {
                ...formData,
                is_active: formData.is_active == 'on',
                is_default: formData.is_default == 'on' ? 1 : 0,
                paymentterm_id: payment_term_id
            },
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res?.data?.message); 
                reloadParentGrid();
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        }
    };

    const onPopupClose = () => {
        $("html").removeClass("rs_popup_open");
        setShowCreateModal(false);
    };

    const handleSubmit = () => { 
        if(!formValidatory.isValid({...formData})){
            formValidatory.displayMessage();
            formValidatory.reset();
            return;
        };
  
        if(!payment_term_id) {
            Helper.createDebouncedAPIFunction( 
                [executePOSTAPI], 
                setApiCallBtn,
                cancelTokenSource
            )();
        } else {
            Helper.createDebouncedAPIFunction( 
                [executePUTAPI], 
                setApiCallBtn,
                cancelTokenSource
            )();
        };
    };

    return (
        <Fragment> 
            { apiCallBtn ? <OverlayLoader /> : null }
            <Popup
                autoOpen={true} 
                width="900px"
                onClose={onPopupClose} 
            >
                <>
                    <h5 className="create_rfq_modal__title">{!payment_term_id?'Create Payment Terms':'Edit Payment Terms'}</h5>

                    <Collapse
                        className="mt-4 mb-4" open={true}
                        title={Helper.getLabel(
                            language,
                            "general_info",
                            "General Information"
                        )}
                    >
                        <Form>
                            <div className='container-fluid'>
                                <FieldsRenderer updateState={setFormData} currentState={formData} fieldsArray={fieldsArrayGeneral} />
                            </div>
                        </Form> 
                    </Collapse>
                </>

                <div className="addDocumentNumber-body-buttons" style={{ justifyContent: "flex-end" }}>
                    <Button
                        isActive={false} 
                        isDisable={apiCallBtn}
                        title={Helper.getLabel(language, "close", "Close")}
                        onClick={onPopupClose}
                    />

                    <Button
                        isDisable={apiCallBtn}
                        className={payment_term_id?"rfq_save_btn":"solid_btn" } 
                        isActive={!payment_term_id}
                        title={!payment_term_id?Helper.getLabel(language, "create", "Create"):Helper.getLabel(language, "save", "Save")}
                        onClick={handleSubmit}
                    />
                </div>
            </Popup>
        </Fragment>
    )
}
 
const mapStateToProps = (state) => {
    return {
        appOptions: state.options,
        auth: state.auth,
        language: state.language,
    };
};

export default connect(mapStateToProps) (PaymentTermsModal);