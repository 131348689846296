import React, { Component } from 'react'
import MasterComonent from '../../components/Backend/MasterComonent'
import PurchaseRequisitionList from '../../components/PurchaseRequisition/PurchaseRequisitionList'

export default class PurchaseRequisitionScreen extends Component {
  render() {
    return (
      <MasterComonent>
        <PurchaseRequisitionList />
      </MasterComonent>
    )
  }
}
