import React, {useState, useEffect,useRef} from 'react';
import $ from 'jquery'
import Popup from '../../Popup/Popup';
import Collapse from '../../inc/Collapse';
import Helper from '../../../inc/Helper';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import { connect } from 'react-redux';
import Gui_id_list from '../../../inc/Gui_id_list';
import DropDownInput from '../../Forms/Dropdown';
import Input from '../../Forms/Input';
import SimpleLoading from '../../Loading/SimpleLoading';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import { ValidateArray, ValidateData, getPopupMessageBasedOnStatus, objectIsEmpty } from '../../../inc/Validation';
import FormValidator from '../../../inc/FormValidator';
import { useNavigate } from 'react-router-dom';
import ActionTypes from '../../../inc/ActionsTypes';
import NewInputDatePicker from '../../Forms/NewInputDatePicker';
import DatepickerFunctions from '../../../inc/DatepickerHelper';
import axios from 'axios';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';
import Button from '../../inc/Button';

let cancelTokenSource = axios.CancelToken.source();
const CreatePOPopup = (props) => {
    //States
    let auth = props.auth.user;
    let headerTypeVal = props.headerType ? props.headerType : "Purchase Order"
    const navigate = useNavigate() 
    const [saveBtnApi, setSaveBtnApi] = useState(false);
    const [allDataLoading, setAllDataLoading] = useState(() => {
      return {
        sites:false,
        vendor_group:false,
        vendor:false,
        entity:false,
        currency:false,
        warehouse:false,
      }
    })
    const [sitesLoading, setSitesLoading] = useState(false)
    const [sitesCurrencyLoading, setSitesCurrencyLoading] = useState(false)
    const [createPO, setCreatePO] = useState({
        "vendor_id": null,
        "vendorsite_id": null,
        "vendorgroup_id": null,
        "purchase_status": null,
        "document_status": null,
        "accounting_date": new Date(),
        "description":null,
        "document_date":new Date(),
        "delivery_date":new Date(), 
        "invoice_date":new Date(),
        "entity_id": auth.Employee ? auth.Employee.Entity_id : '',
        "site_id": auth.Employee ?  auth.Employee.Site_id : '',
        "currency_id": auth.default_currency_id ? auth.default_currency_id : null,
        "document_number": null,
        "warehouse_id": auth.Employee ? auth.Employee.Warehouse_id : '',
        "agreement_id":props?.agreement_id?props?.agreement_id:null
    })

    const [numberSequenceData,setNumberSequenceData] = useState({})

    //Fields Validation
    let validator ; 

    const validationConfigure = () => {
      let fieldConfig = [
          {name:'vendor_id', displayName:Helper.getLabel(props.language,'vendor_id','Vendor'),types:['Required']},
          {name:'warehouse_id', displayName:Helper.getLabel(props.language,'warehouse_id','Warehouse'),types:['Required']},      
          {name:'vendorgroup_id', displayName:Helper.getLabel(props.language,'vendorgroup_id','Vendor group'),types:['Required']},  
          {name:'accounting_date', displayName:Helper.getLabel(props.language,'accounting_date','Accounting Date'),types:['Required'] }, 
          {name:'document_date', displayName:Helper.getLabel(props.language,'document_date','Document Date'),types:['Required'] },
          {name:'delivery_date', displayName:Helper.getLabel(props.language,'delivery_date','Delivery Date'),types:['Required']},   
          {name:'entity_id', displayName:Helper.getLabel(props.language,'entity','Entity'),types:['Required']}, 
          {name:'currency_id', displayName:Helper.getLabel(props.language,'currency_id','Currency '),types:['Required'] }, 
          {name:'site_id', displayName:Helper.getLabel(props.language,'site_id','Site '),types:['Required'] },
      ]
      if(props.type==="purchase agreement"){
        let purchaseAgreementValidations = [
          {name:'pa_classification_id', displayName:Helper.getLabel(props.language,'classification','Classification'),types:['Required']},
          {name:'start_date', displayName:Helper.getLabel(props.language,'start_date','Start date'),types:['Required']},
          {name:'expiry_date', displayName:Helper.getLabel(props.language,'expiry_date','Expiry date'),types:['Required']}
        ]
        fieldConfig = fieldConfig.concat(purchaseAgreementValidations)
      }
      if(props.type==="2"){
        fieldConfig.push({name:'agreement_id', displayName:Helper.getLabel(props.language,'purchase_agreement','Purchase agreement'),types:['Required'] },)
      }
      if(!validator) {
        validator = new FormValidator(fieldConfig,props.language)
      }
    }
  
    validationConfigure()

    //End

    //Dropdowns States
    const [allVendors, setAllVendors] = useState([])
    const [vendor, setVendor] = useState([])
    const [vendorGroup, setVendorGroup] = useState([])
    const [vendorSite, setVendorSite] = useState([])
    const [vendorSiteAllData, setVendorSiteAllData] = useState([])
    const [purchaseStatus, setPurchaseStatus] = useState([])
    const [documentStatus, setDocumentStatus] = useState([]) 
    const [entity, setEntity] = useState([])
    const [sites, setSites] = useState([])
    const [warehouse, setWarehouse] = useState([])
    const [currencyList, setCurrencyList] = useState([])
    const [classificationList,setClassificationList]=useState([])
    const [purchaseAgreementData,setPurchaseAgreementData]=useState([])
    //End

    
    //Calls Reduction
    let typeCalled = false
    let vendorCalled = false
    let vendorGroupCalled = false
    let vendorSiteCalled = false
    let purchaseStatusCalled = false
    let documentStatusCalled = false 
    let entityCalled = false
    let sitesCalled = false
    let currencyCalled = false
    let vendorSiteCurrencyCalled = false
    let warehouseCalled = false
    let isUserDetailsCalled = false;
    //End

    //variable added for dropdown
    let currentSearchTerm = ""
    let allSitesData = useRef()
    let allWarehouseData = useRef()
    let allVendorsData = useRef()
    let allDocumentsData = useRef()
    let allClassificationsData = useRef([])
    let allPurchaseAgreementData = useRef([])

    
    //API RENDERS
    const fetchData = () => {
      SitesAPI()
      TypeAPI()
      DocumentStatusAPI() 
      PurchaseStatusAPI()
      VendorGroupAPI()
      vendorAPI()
      EntityAPI()
      CurrencyAPI()
      WareHouseAPI()
      loadUserDetails()
      classificationsAPI()
      purchaseAgreementsAPI()
      currentPurchaseAgreementsAPI()
    }

    useEffect(() => {
      fetchData()
  }, [])

  useEffect(()=>{
    getNumberSequenceData()
  },[createPO?.entity_id])

  //Common Api Calls 
  const loadUserDetails =() => {
      if(isUserDetailsCalled){
        return;
      }
      isUserDetailsCalled = true;
      let api = Api;
      if(api.getUserToken()){
          api.setJsonHeader();
          api.setUserToken();
          api.axios().get(Settings.apiUrl+'/user_details',{}).then(function({data}){
              if(data && data.Status === "Success"){
                  props.setUser(data.data);
              }
          })
      }
  }

  //End

    // Dropdown API
    const TypeAPI = () => {
        if(typeCalled){
            return;
        }
        if(props.poHeaderTypes.isLoaded){
          return;
        }
        let api = Api
        typeCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/enum/POHeaderType',{}).then(function(res){
          if(res.data.status==="success"){
            props.setPoTypes(res.data.data);
          } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
      })
    }
  
    const vendorAPI = () => {
        if(vendorCalled){
            return;
        }
        let api = Api
        vendorCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiVendorUrl+'/get_active_vendors',{}).then(function(res){
          if(res.data.status==="success"){
            let VENDORARR = []
            res.data.data.forEach(item => {  
            VENDORARR.push({
              value: item.vendor_id,
              label: `${item.code} - ${item.name}`
            })
           }) 
           setAllVendors(res.data.data)
           setVendor(VENDORARR)
           setAllDataLoading((oldData) => {
            return(
              {
                ...oldData,
                vendor:true
               }
            )
           })
           
          } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
      })
    }

    const classificationsAPI = () => {
      if(props.type !== "purchase agreement"){
          return;
      }
      let api = Api
      vendorCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiPurchaseOrderUrl+'/PA_classification').then(function(res){
        if(res.data.status==="success"){
          let CLASSIFICATIONARR = []
          res.data.data.forEach(item => {  
          CLASSIFICATIONARR.push({
            value: item.pa_classification_id,
            label: item.name
          })
         }) 
         setClassificationList(CLASSIFICATIONARR)
         
        } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const purchaseAgreementsAPI = () => {
    if(props.type !== "2"){
        return;
    }
    let api = Api
    api.setUserToken()
    api.axios().get(Settings.apiPurchaseOrderUrl+'/PA_header').then(function(res){
      if(res.data.status==="success"){
        let PURCHASEAGREEMENTSARR = []
        res.data.data.forEach(item => {  
        PURCHASEAGREEMENTSARR.push({
          value: item.agreement_id,
          label: item.document_number
        })
       }) 
       setPurchaseAgreementData(PURCHASEAGREEMENTSARR)
       
      } 
  }).catch((res) => { 
    getPopupMessageBasedOnStatus(res) 
  })
}

    const VendorGroupAPI = () => {
        if(vendorGroupCalled){
            return;
        }
        let api = Api
        vendorGroupCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiVendorUrl+'/vendorgroup',{}).then(function(res){
          if(res.data.status==="success"){
            let GroupARR = []
            res.data.data.forEach(item => {  
            GroupARR.push({
              value: item.vendorgroup_id,
              label: item.name
            })
           }) 
           setVendorGroup(GroupARR)
           setAllDataLoading((oldData) => {
            return(
              {
                ...oldData,
                vendor_group:true
               }
            )
           })
          } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
      })
    }
    const getVendorDefaultSiteFromVendorId = (rsVendorId) =>{
      let rsDefaultSite = '';
      allVendors.forEach(elementVendor => {
        if(parseInt(elementVendor.vendor_id) === parseInt(rsVendorId)){
          rsDefaultSite = elementVendor.defaultsite_id
        }
      });
      return rsDefaultSite;
    }
    const VendorSiteAPI = (id) => {
        if(vendorSiteCalled){
            return;
        }
        let api = Api
        vendorSiteCalled = true
        setSitesLoading(true)
        api.setUserToken()
        let url = Settings.apiVendorUrl+`/get_active_vendor_sites`;
        if(id){
          let rsDefaultSiteId = getVendorDefaultSiteFromVendorId(id)
          setCreatePO((prevState) => {
            return {
              ...prevState,
              vendorsite_id:rsDefaultSiteId
            }
          })
          if(rsDefaultSiteId){
            getVendorCurrentCurrencyAPI(rsDefaultSiteId)
          }
          url = Settings.apiVendorUrl+`/get_active_vendor_sites/${id}`;
        }
        api.axios().get(url,{}).then(function(res){
          if(res.data.status==="success"){
            let vendorSite = []
            res.data.data.forEach(item => {  
              vendorSite.push({
                value: item.vendorsite_id,
                label: item.name
              })
            }) 
            setVendorSiteAllData( res.data.data)
            setVendorSite(vendorSite)
            setSitesLoading(false)
          } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
        setSitesLoading(false)
      })
    }

    const getVendorCurrentCurrencyAPI = (id) => {
      if(vendorSiteCurrencyCalled){
          return;
      }
      let api = Api
      vendorSiteCurrencyCalled = true
      setSitesCurrencyLoading(true)
      api.setUserToken()
      api.axios().get(Settings.apiVendorUrl+`/vendorsite/${id}`,{}).then(function(res){
        if(res.data.status==="success"){
          setSitesCurrencyLoading(false)
            setCreatePO((prevState) => {
              return {
                ...prevState,
                currency_id: res.data.data.currency_id
              }
            })
            setCreatePO((prevState) => {
              return {
                ...prevState,
                vendorgroup_id: res.data.data.vendor_group_id
              }
            })
        } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
      setSitesCurrencyLoading(false)
    })
  }

    const PurchaseStatusAPI = () => {
        if(purchaseStatusCalled){
            return;
        }
        let api = Api
        purchaseStatusCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/enum/POHeaderPurchaseStatus',{}).then(function(res){
          if(res.data.status==="success"){
            let PurchaseStatus = []
            res.data.data.forEach(item => {  
            PurchaseStatus.push({
              value: item.id,
              label: item.name
            })
           }) 
           setPurchaseStatus(PurchaseStatus)
           setAllDataLoading((oldData) => {
            return(
              {
                ...oldData,
                purchase_status:true
               }
            )
           })
          } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
      })
    }

    const DocumentStatusAPI = () => {
        if(documentStatusCalled){
            return;
        }
        let api = Api
        documentStatusCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/enum/POHeaderDocumentStatus',{}).then(function(res){
          if(res.data.status==="success"){
            let docsStatus = []
            res.data.data.forEach(item => {  
            docsStatus.push({
              value: item.id,
              label: item.name
            })
           }) 
           setDocumentStatus(docsStatus)
           setAllDataLoading((oldData) => {
            return(
              {
                ...oldData,
                document_status:true
               }
            )
           })
           
          } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
      })
    }

    const CurrencyAPI = () => {
        if(currencyCalled){
            return;
        }
        let api = Api
        currencyCalled = true 
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/currency',{}).then(function(res){
          if(res.data.status==="success"){ 
            let CurrencyARR = []
            res.data.data.forEach(item => {  
                CurrencyARR.push({
                    value: item.currency_id,
                    label: `${item.currency_code} - ${item.currency_name}`
                })
           })
           setCurrencyList(CurrencyARR)
           setAllDataLoading((oldData) => {
            return(
              {
                ...oldData,
                currency:true
               }
            )
           })
        } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
      })
    }

    const EntityAPI = () => {
        if(entityCalled){
            return;
        }
        let api = Api
        entityCalled = true
        api.setUserToken()
        api.axios().post(Settings.apiUrl+'/search_entity',{
            "code":"",
            "name":""
        }).then(function(res){
          if(res.data.status==="success"){
            let EntityARR = []
            res.data.data.forEach(item => {  
            EntityARR.push({
              value: item.entity_id,
              label: `${item.code} - ${item.name}`
            })
           }) 
           setEntity(EntityARR)
           setAllDataLoading((oldData) => {
            return(
              {
                ...oldData,
                entity:true
               }
            )
           })
          } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
      })
    }

    const SitesAPI = () => {
        if(sitesCalled){
            return;
        }
        let api = Api
        sitesCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/get_site',{
            "code":"",
            "name":""
        }).then(function(res){
          if(res.data.status==="success"){
            let SitesARR = []
            res.data.data.forEach(item => {  
            SitesARR.push({
              value: item.site_id,
              label: `${item.code} - ${item.name}`
            })
           }) 
           setSites(SitesARR)
           setAllDataLoading((oldData) => {
            return(
              {
                ...oldData,
                sites:true
               }
            )
           })
           
          } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
      })
    }

    const WareHouseAPI = () => {
      if(warehouseCalled){
          return;
      }
      let api = Api
      warehouseCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/get_warehouse',{
          "code":"",
          "name":""
      }).then(function(res){
        if(res.data.status==="success"){
          let Warehouse = []
          res.data.data.forEach(item => {  
          Warehouse.push({
            value: item.warehouse_id,
            label: `${item.code} - ${item.name}`
          })
         }) 
         setWarehouse(Warehouse)
        } 
        setAllDataLoading((oldData) => {
          return(
            {
              ...oldData,
              warehouse:true
             }
          )
         })
        
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const currentPurchaseAgreementsAPI = (agreement_id) => {
    let agreementId = agreement_id ? agreement_id : props?.agreement_id
    if(!agreementId){
        return;
    }
    let api = Api
    api.setUserToken()
    let poAditionalParams = {}
    api.axios().get(Settings.apiPurchaseOrderUrl+`/PA_header/${agreementId}`).then(function(res){
      if(res.data.status==="success"){
        VendorSiteAPI(res?.data?.data?.vendor_id)
        poAditionalParams.vendorsite_id = res?.data?.data?.vendorsite_id
        setCreatePO({
          ...createPO,
          ...poAditionalParams,
          vendor_id:res?.data?.data?.vendor_id,
          vendorsite_id:res?.data?.data?.vendorsite_id,
          vendorgroup_id:res?.data?.data?.vendorgroup_id,
          agreement_id:agreementId
        })
      } 
  }).catch((res) => { 
    getPopupMessageBasedOnStatus(res) 
  })
}


  const getNumberSequenceData = async (entity_id) => {
    try{
      let api = Api
      api.setUserToken();
      const numberSequenceRecord = await api.axios().get(Settings.apiUrl + `/ns_status/${entity_id ?? createPO?.entity_id}/${props?.source_id?props?.source_id:Settings.source_id.purchase_order}`)
      if (numberSequenceRecord?.status===200) {
        setNumberSequenceData(numberSequenceRecord?.data)
      }
    }catch(error){
      setNumberSequenceData({})
    }
  }

    //End
  
    //Handlers
    
    const onPopupClose = () => { 
        $('html').removeClass('rs_popup_open');
        props.ClosePopup(false)
    }

    const ClosePopupHandler = () => {
        $('html').removeClass('rs_popup_open');
        props.ClosePopup(false)
    }

    const InputChangeHandler = (e) => {
      let poAditionalParams= {}
        if(e.target.name == "vendor_id"){

            VendorSiteAPI(e.target.value)
            poAditionalParams.vendorsite_id = getVendorDefaultSiteFromVendorId(e.target.value)
        }
        if(e.target.name == "vendorsite_id"){
            let rsVendorSite = null;
            vendorSiteAllData.forEach(venSite => {
              if(e.target.value == venSite.vendorsite_id){
                rsVendorSite = venSite;
              }
            });
            if(rsVendorSite){
              poAditionalParams.vendor_id = rsVendorSite.vendor_id;
            }
            getVendorCurrentCurrencyAPI(e.target.value)

        }

        if(e.target.name==="agreement_id"){
          currentPurchaseAgreementsAPI(e.target.value)
          return
        }

        setCreatePO((prevState) => {
            return {
                ...prevState,
                ...poAditionalParams,
                [e.target.name]:e.target.value
            }
        })
    }

    const entityInputChangeHandler = (e) => {
        setCreatePO((prevState) => {
            return {
              ...prevState,
              [e.target.name]:e.target.value,
              site_id:'',
              warehouse_id:''
            }
        })
        getNumberSequenceData(e.target.value)
    }
    const siteInputChangeHandler = (e) => {
        setCreatePO((prevState) => {
            return {
              ...prevState,
              [e.target.name]:e.target.value,
              warehouse_id:''
            }
        })
    }

    const DocumentDatePickerHandler = (date,name) => {

       setCreatePO((prevState) => {
            return {
                ...prevState,
                document_date: date
            }
       })
    }

    const AccountDatePickerHandler = (value,name) => {
        setCreatePO((prevState) => {
            return {
                ...prevState,
                accounting_date: value
            }
        })
    }

    const DatePickerHandler = (value,name) => {
        setCreatePO((prevState) => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }
    //End
    const getDefaultPaymentTermIdFromSiteId = (siteId) => {
      let paymentterm_id = null;
      vendorSiteAllData.forEach(venSite => {
        if(siteId == venSite.vendorsite_id){
          paymentterm_id = venSite.paymentterm_id;
        }
      });
      return paymentterm_id;
    }
    const handlePayload = () => {
      let rsNewPoData = { 
        delivery_address_id: null,
        delivery_name: null,
        attention_information: null,
        mode_of_delivery_id: null,
        delivery_term_id: null,
        discount_amount: null,
        discount_percentage: 0,
        rounding_amount: null,
        taxgroup_id: null,
        taxcodes_id: null,
        taxgroupitem_id: null,
        priceinclude_tax: null,
        requester_id: null,
        orderer_id: null,
        emailaddress: null,
        buyergroup_id: null,
        paymentterm_id: getDefaultPaymentTermIdFromSiteId(
          createPO.vendorsite_id
        ),
        distributionsource_id: null,
        blindreceivingof_qty: null,
        blindreceivingof_item: null,
        externalreference: null,
        attachmentsource_id: null,
        notesource_id: null,
        contactsource_id: null,
        addresssource_id: null,
        allow_access_only_to_buyer_group: null,
        exchangecombination_id: null,
        exchangerate_id: null,
        type: props.type ? parseInt(props.type) : parseInt(0),
        vendor_id: parseInt(createPO.vendor_id),
        vendorsite_id: parseInt(createPO.vendorsite_id),
        vendorgroup_id: parseInt(createPO.vendorgroup_id),
        purchase_status: !ValidateArray(purchaseStatus)
          ? purchaseStatus.find((id) => id.label == 'Open').value
          : null,
        document_status: !ValidateArray(documentStatus)
          ? documentStatus.find((id) => id.label == 'Draft').value
          : null,
        accounting_date: DatepickerFunctions.convertDateForDataBase(
          createPO.accounting_date
        ),
        description: createPO.description,
        document_date: DatepickerFunctions.convertDateForDataBase(
          createPO.document_date
        ),
        delivery_date: DatepickerFunctions.convertDateForDataBase(
          createPO.delivery_date
        ),
        entity_id: parseInt(createPO.entity_id),
        site_id: parseInt(createPO.site_id),
        currency_id: parseInt(createPO.currency_id),
        warehouse_id: parseInt(createPO.warehouse_id),
        document_number: numberSequenceData?.ns_type==="continuous" ? null : createPO?.document_number
      }
      let rsDataInvoice = {
        reference_number:createPO?.reference_number,
        description:createPO?.description,
        vendor_id: parseInt(createPO.vendor_id),
        vendorsite_id: parseInt(createPO.vendorsite_id),
        vendorgroup_id: parseInt(createPO.vendorgroup_id),
        accounting_date: DatepickerFunctions.convertDateForDataBase(
          createPO.accounting_date
        ),
        document_date: DatepickerFunctions.convertDateForDataBase(
          createPO.document_date
        ),
        invoice_date: DatepickerFunctions.convertDateForDataBase(
          createPO.invoice_date
        ),
        currency_id: parseInt(createPO.currency_id),
        entity_id: parseInt(createPO.entity_id),
        site_id: parseInt(createPO.site_id),
        warehouse_id: parseInt(createPO.warehouse_id),
        invoice_type:0,
      }
      let rsDataCreditNote = {
        source_id: createPO?.shipment_id
          ? Settings?.source_id?.ShipmentGoodReceivingWorkConfirmation
          : null,
        sourcedocument_id:createPO?.shipment_id
          ? parseInt(createPO?.shipment_id)
          : null,
        invoice_type:1,
        on_account:false,
        description:createPO?.description,
        vendor_id: parseInt(createPO.vendor_id),
        vendorsite_id: parseInt(createPO.vendorsite_id),
        vendorgroup_id: parseInt(createPO.vendorgroup_id),
        accounting_date: DatepickerFunctions.convertDateForDataBase(
          createPO.accounting_date
        ),
        document_date: DatepickerFunctions.convertDateForDataBase(
          createPO.document_date
        ),
        invoice_date: DatepickerFunctions.convertDateForDataBase(
          createPO.invoice_date
        ),
        currency_id: parseInt(createPO.currency_id),
        entity_id: parseInt(createPO.entity_id),
        site_id: parseInt(createPO.site_id),
        warehouse_id: parseInt(createPO.warehouse_id),
      }
      if(props.type==="purchase agreement"){
        rsNewPoData.pa_classification_id=parseInt(createPO?.pa_classification_id)
        rsNewPoData.start_date=DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(createPO?.start_date))
        rsNewPoData.expiry_date=DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(createPO?.expiry_date))
      }
      if(props.type==="2"){
        rsNewPoData.agreement_id=createPO?.agreement_id
      }
      let payload 
      if(props.type === "invoice on account"){
        payload = rsDataInvoice
      }else if(props.type==="credit notes"){
        payload = rsDataCreditNote
      }else{
        payload = rsNewPoData
      }
      return payload
    }

    const getApiUrl = () => {
      if ( props.type === 'invoice on account' || props.type === 'credit notes' ) {
        return Settings.apiPurchaseOrderUrl + '/invoice_header'
      }else if(props.type==="purchase agreement"){
        return Settings.apiPurchaseOrderUrl + '/PA_header'
      } else {
        return Settings.apiPurchaseOrderUrl + '/PO_header'
      }
    }

    const getIdByType = (res) => {
      if (props.type === "invoice on account" || props.type === "credit notes") {
        return res.data.invoice_id;
      }else if(props.type==="purchase agreement"){
        return res.data.PA_header_id
      } else {
        return res.data.PO_header_id;
      }
    }

    const executePOSTApiforPO = async () => {
      try {
        let api = Api;
        api.setUserToken();

        let createPayload = handlePayload();
        let url = getApiUrl();
        
        const res = await api.axios().post(url, createPayload, { cancelToken: cancelTokenSource.token });
    
        if (res.data.status === "success") {
          Helper.alert(res.data.message); 
          let editUrl = props.editUrl ? props.editUrl : '/edit-purchase-order';
          navigate(`${editUrl}/${getIdByType(res)}`);
          $('html').removeClass('rs_popup_open');
          props.ClosePopup(false);
        }
      } catch (error) {
        getPopupMessageBasedOnStatus(error);
      }
    };
     
    const CreatePurchaseOrder = () => {
      let validData = {
        ...createPO
      }
      if(!validator.isValid(validData)){
        validator.displayMessage();
        validator.reset() 
        return;
      }  
      if(!ValidateData(vendorSite) && !createPO.vendorsite_id){
        Helper.alert('Vendor Site Field missing!', 'error')
        return;
      } 
      Helper.createDebouncedAPIFunction([
        executePOSTApiforPO
      ], setSaveBtnApi, cancelTokenSource)(); 
    }
  const isAllDataLoaded = () => {
      let isAllDataLoaded = true;
      for (const objectKey in allDataLoading) {
            if(!allDataLoading[objectKey]){
              isAllDataLoaded = false;
            }
      }
      return isAllDataLoaded;
  }
  if(!isAllDataLoaded()){
    return <SimpleLoading/>
  }
  const getTypeLabel = () => {
    let outputLabel = '';
    props.poHeaderTypes.types.forEach( typeObj => {
      if(typeObj.id == props.type){
        outputLabel = typeObj.name
      }
    })
    return outputLabel;
  }

//function to map sites
const mapSitesData = (item) => {
  return {
    text: `${item.code} - ${item.name}`,
    id: item.site_id
  }
}

const mapWarehouseData = (item) => {
  return {
    text: `${item.code} - ${item.name}`,
    id: item.warehouse_id
  };
}

const mapVendorList = (item) => {
  return {
    id: item.vendor_id,
    text: `${item.code} - ${item.name}`
  }
}

const mapClassificationsList = (item) => {
  return {
    id: item.pa_classification_id,
    text: item.name
  }
}

const mapPurchaseAgreementList = (item) => {
  return {
    id: item.agreement_id,
    text: item.document_number
  }
}

const mapSourceDocumentList = (item) => {
  return {
    id: item.purchase_order_id,
    text: `${item.document_number || '-'}`
  }
}

const mapReturnOrderDocumentList = (item) => {
  return {
    id: item.shipment_id,
    text: `${item.receiving_reference_number || '-'}`
  }
}

const additionalParams = {
  entity_id:createPO?.entity_id
}

const additionalSiteParams = {
  site_id:createPO?.site_id
}

  return (
    <div className='close-popup_po'>
        <Popup onClose={ onPopupClose} autoOpen={true} width="752px">
           <div className='container-fluid'>
               <div className='row'>
                   <div className='col-12'>
                       <h2 className='mb-4'>{ props.title ? props.title : Helper.getLabel(null,`create_${headerTypeVal}`,`Create ${headerTypeVal}`)}</h2>
                   </div>
               </div>
           </div>
           
           <Collapse className='mb-4 info_po_tabs' open={true} title={Helper.getLabel(props.language,'etfi',"Enter the following information")}>
              <div className='container-fluid'> 
                <div className='row'>
                    {
                      props.type==="invoice on account" &&
                          <div className='col-lg-6'>
                            <Input label={Helper.getLabel(props.language,'invoice_no',"Invoice No.")} value={createPO?.reference_number} name="reference_number" onChange={InputChangeHandler}/>
                          </div>   
                    }
                    {
                      props.type==="credit notes" && <>
                        <div className='col-lg-6'>
                          <DropDownInput id='source_document_credit_id' label={Helper.getLabel(props.language,'source_document_number',"Source document number")}  value={createPO.sourcedocument_id} name='sourcedocument_id' onChange={InputChangeHandler} apiUrl={Settings.apiPurchaseOrderUrl+'/PO_header?type=0'} pagination={true} allDataRef={allDocumentsData} mapFunction={mapSourceDocumentList} currentSearchTerm={currentSearchTerm}  reRenderRequired={true} />
                        </div>
                        <div className='col-lg-6'>
                          <DropDownInput id='return_order_source_id' label={Helper.getLabel(props.language,'return_order_number',"Return order number")} value={createPO.shipment_id} name='shipment_id' onChange={InputChangeHandler} apiUrl={Settings.apiPurchaseOrderUrl+`/sh_headers/${Settings?.sh_type?.return_order}?purchaseorder_id=${createPO?.sourcedocument_id}`} pagination={true} allDataRef={allDocumentsData} mapFunction={mapReturnOrderDocumentList} currentSearchTerm={currentSearchTerm}  reRenderRequired={true}/>
                        </div>      
                      </> 
                    }
                    { props.type !== "invoice on account" && props.type !== "credit notes" &&
                        <div className='col-lg-6'>
                          <Input label={Helper.getLabel(props.language,'document_no',"Document number")} name="document_number" value={createPO?.document_number} disable={objectIsEmpty(numberSequenceData) || numberSequenceData?.ns_type==="continuous"}  onChange={InputChangeHandler}/>
                        </div>   
                    }
                    <div className='col-lg-6'>
                        <Input label={Helper.getLabel(props.language,'description',"Description")} value={createPO.description} onChange={InputChangeHandler} name='description' maxLength={255} />
                    </div>
                    { props.type==="invoice on account" || props.type==="credit notes" || props.type==="purchase agreement" ? null : <div className='col-lg-6'>
                        <Input label={Helper.getLabel(props.language,'type',"Type")} value={getTypeLabel()} name="type_of_po" disable={true}/>
                    </div> }
                    {
                      props.type==="2" && <div className='col-lg-6'>
                        <DropDownInput id='agreement_id' required={true} label={Helper.getLabel(props.language,'purchase_agreement',"Purchase agreement")} placeHolder={Helper.getLabel(props.language,'select_purchase_agreement',"Select purchase agreement")} options={purchaseAgreementData} value={createPO?.agreement_id} name='agreement_id' onChange={InputChangeHandler} apiUrl={Settings.apiPurchaseOrderUrl+'/PA_header'} pagination={true} allDataRef={allPurchaseAgreementData} mapFunction={mapPurchaseAgreementList} currentSearchTerm={currentSearchTerm}  reRenderRequired={true} isDisable={props.agreement_id}/>
                      </div>
                    }
                    {
                      props.type==="purchase agreement" && <div className='col-lg-6'>
                      <DropDownInput id='pa_classification_id' required={true} label={Helper.getLabel(props.language,'classification',"Classification")} placeHolder={Helper.getLabel(props.language,'select_classification',"Select classification")} options={classificationList} value={createPO.pa_classification_id} name='pa_classification_id' onChange={InputChangeHandler} apiUrl={Settings.apiPurchaseOrderUrl+'/PA_classification'} pagination={true} allDataRef={allClassificationsData} mapFunction={mapClassificationsList} currentSearchTerm={currentSearchTerm}  reRenderRequired={true}/>
                      </div>
                    }
                    <div className='col-lg-6'>
                        <DropDownInput id='vendor_id' required={true} label={Helper.getLabel(props.language,'vendor_code_name',"Vendor")} placeHolder={Helper.getLabel(props.language,'select vendor',"Select vendor")} options={vendor} value={createPO.vendor_id} name='vendor_id' onChange={InputChangeHandler} apiUrl={Settings.apiVendorUrl+'/get_active_vendors'} pagination={true} allDataRef={allVendorsData} mapFunction={mapVendorList} currentSearchTerm={currentSearchTerm} isDisable={props?.type==='2'} reRenderRequired={true}/>
                    </div>
                    <div className='col-lg-6'>
                        {sitesLoading && <SimpleLoading />}
                        <DropDownInput id='vendorsite_id' required={true} label={Helper.getLabel(props.language,'vendor_site',"Vendor site")} placeHolder={Helper.getLabel(props.language,'select vendor site',"Select vendor site")} options={vendorSite} value={createPO.vendorsite_id} name='vendorsite_id' onChange={InputChangeHandler}  reRenderRequired={true} isDisable={props?.type==='2'}/>
                    </div>
                    <div className='col-lg-6'>
                        {sitesCurrencyLoading && <SimpleLoading /> }
                        <DropDownInput id='vendorgroup_id' required={true} label={Helper.getLabel(props.language,'vendor_group',"Vendor group")} placeHolder={Helper.getLabel(props.language,'select vendor',"Select vendor group")} options={vendorGroup} value={createPO.vendorgroup_id} name='vendorgroup_id' onChange={InputChangeHandler} reRenderRequired={true} isDisable={props?.type==='2'}/>
                    </div>
                    <div className='col-lg-6'>
                        {sitesCurrencyLoading && <SimpleLoading /> }
                        <DropDownInput id='currency_id' required={true} label={Helper.getLabel(props.language,'currency_id',"Currency")} placeHolder={Helper.getLabel(props.language,'select currency',"Select currency")} options={currencyList} value={createPO.currency_id} name='currency_id' onChange={InputChangeHandler} reRenderRequired={true} />
                    </div> 
                    <div className='col-lg-6'>
                        <NewInputDatePicker label ={Helper.getLabel(props.language,'document_date',"Document date")} name ="document_date" id ="document_date1" value = {createPO.document_date}  onChange ={DocumentDatePickerHandler} />
                    </div>
                    <div className='col-lg-6'>
                        <NewInputDatePicker label ={Helper.getLabel(props.language,'accounting_date',"Accounting date")} name ="accounting_date" id ="accounting_date" value = {createPO.accounting_date}  onChange ={AccountDatePickerHandler} />
                    </div>
                    {(props.type==="credit notes"|| props.type==="invoice on account")?<div className='col-lg-6'>
                        <NewInputDatePicker label ={props.type==="invoice on account"?Helper.getLabel(props.language,'invoice_date',"Invoice date"):Helper.getLabel(props.language,'credit_note_date',"Credit Note Date")} name ="invoice_date" id ="on_account_date" value = {createPO.invoice_date}  onChange ={DatePickerHandler} />
                    </div>:<div className='col-lg-6'>
                        <NewInputDatePicker label ={Helper.getLabel(props.language,'delivery_date',"Delivery date")} name ="delivery_date" id ="delivery_date" value = {createPO.delivery_date}  onChange ={DatePickerHandler} />
                    </div>}
                    {props.type==="purchase agreement" && <>
                      <div className='col-lg-6'>
                        <NewInputDatePicker label ={Helper.getLabel(props.language,'start_date',"Start date")} name ="start_date" id ="start_date1" value = {createPO?.start_date} empty={true} onChange ={DatePickerHandler} required={true} />
                    </div>
                    <div className='col-lg-6'>
                        <NewInputDatePicker label ={Helper.getLabel(props.language,'expiry_date',"Expiry date")} name ="expiry_date" id ="expiry_date" value = {createPO?.expiry_date} empty={true} onChange ={DatePickerHandler} required={true} />
                    </div></>}
                    <div className='col-lg-6'>
                        <DropDownInput id='entity_id' value={createPO.entity_id} label={Helper.getLabel(props.language,'entity_id',"Entity")} placeHolder={Helper.getLabel(props.language,'select entity',"Select entity")} name='entity_id' required={true} options={entity} onChange={entityInputChangeHandler}  reRenderRequired={true}/>
                    </div>
                    <div className='col-lg-6'>
                        <DropDownInput id='site_id' pagination={true} apiUrl={Settings.apiUrl + `/get_site`} additionalParams={additionalParams}  currentSearchTerm={currentSearchTerm}  mapFunction={mapSitesData} allDataRef={allSitesData}  value={createPO.site_id} label={Helper.getLabel(props.language,'site_id',"Site")} placeHolder={Helper.getLabel(props.language,'select site',"Select site")} name='site_id' required={true} options={sites} onChange={siteInputChangeHandler}  reRenderRequired={true}/>
                    </div>
                    <div className='col-lg-6'>
                        <DropDownInput id='warehouse_id' pagination={true} apiUrl={Settings.apiUrl + `/get_warehouse`} additionalParams={additionalSiteParams} currentSearchTerm={currentSearchTerm}  mapFunction={mapWarehouseData} allDataRef={allWarehouseData} value={createPO.warehouse_id} label={Helper.getLabel(props.language,'warehouse_id',"Warehouse")} placeHolder={Helper.getLabel(props.language,'select warehouse',"Select warehouse")} name='warehouse_id' required={true} options={warehouse} onChange={InputChangeHandler}  reRenderRequired={true} />
                    </div>
                </div>
              </div>
           </Collapse>
           <div className='container-fluid'>
              <div className='row mt-3 mb-3'>
                <div className='col-12'>
                    <div className='addDocumentNumber-body-buttons justify-content-end'>
                      <Button onClick={ClosePopupHandler} title = {Helper.getLabel(null,'close','Close')} />
                      <Button isDisable={saveBtnApi} isActive={true} onClick={CreatePurchaseOrder} title = {Helper.getLabel(null, "create", "Create")} />
                    </div>
                </div>  
              </div>
           </div>
        </Popup>
        {saveBtnApi?<OverlayLoader />:null}
    </div>
  )
}

 
const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language,
      poHeaderTypes:state.poHederTypes
    }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setPoTypes:(types) => { dispatch({type:ActionTypes.SET_PO_HEADER_TYPES,payload:types})},
    setUser:(user) => { dispatch({type:ActionTypes.SET_USER , payload:user}) },
  }
}
const SecurityOptions = {
    gui_id:Gui_id_list.procure.purchase_orders.purchase_orders_create_screen
}


export default connect(mapStateToProps,mapDispatchToProps) (ApplySecurityRoles(CreatePOPopup, SecurityOptions))