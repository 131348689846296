import React, { useState, useMemo, useRef, useEffect, Fragment } from "react";
import JoditEditor from "jodit-react";
import "../Email.css";
import MasterComonent from "../../Backend/MasterComonent";
import NavigationHeder from "../../Navigations/NavigationHeder";
import Input from "../../Forms/Input";
import LinkBtn from "../../inc/LinkBtn";
import Button from "../../inc/Button";
import Helper from "../../../inc/Helper";
import { connect } from "react-redux";
import Api from "../../../inc/Api";
import {
  ValidateData,
  getPopupMessageBasedOnStatus,
} from "../../../inc/Validation";
import DropDownInput from "../../Forms/Dropdown";
import Settings from "../../../inc/Settings";
import { useNavigate } from "react-router-dom";
import ApplySecurityRoles from "../../SecurityRoles/ApplySecurityRoles";
import Gui_id_list from "../../../inc/Gui_id_list";
import Alert from "../../inc/Alert";
import Collapse from "../../inc/Collapse";
import SimpleLoading from "../../Loading/SimpleLoading";
import MasterComponentWraper from "../../Backend/MasterComponentWraper";
import OverlayLoader from "../../PurchaseRequisition/common/OverlayLoader";

const CreateEmail = (props) => {
  const [createEmails, setCreateEmails] = useState({
    subject: "",
  });
  const navigate = useNavigate();
  const editor = useRef(null);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(createEmails.body);
  const [fields, setFields] = useState([]);
  const [sources, setSources] = useState([]);
  const [selectedSource, setSelectedSource] = useState(null);

  let fieldsCalled = false;
  let sourcesCalled = false;

  const wrapperDiv = useRef();
  const [height, setHeight] = useState(0); 

  useEffect(() => {
    get_sources();
  }, []);

  useEffect(() => { 
    Helper.resizeWindowHandle(wrapperDiv,setHeight,window);
  }, [wrapperDiv?.current?.clientHeight, loading]);
  
  const get_fields = (source_id) => {
    if (fieldsCalled || !source_id) {
      return;
    }
    let api = Api;
    fieldsCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl + `/email_template_fields?source_id=${source_id}`)
      .then(function (res) {
        if (res.data.status === "success") {
          let UPDATED_STATE = [];
          res.data.data.forEach((element) => {
            UPDATED_STATE.push({
              value: element.field_id,
              label: element.field_name,
            });
          });
          setFields(UPDATED_STATE);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const get_sources = () => {
    if (sourcesCalled) {
      return;
    }
    let api = Api;
    sourcesCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl + "/source")
      .then(function (res) {
        if (res.data.status === "success") {
          let UPDATED_STATE = [];
          res.data.data.forEach((element) => {
            UPDATED_STATE.push({
              value: element.source_id,
              label: element.source_name,
            });
          });
          setSources(UPDATED_STATE);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const SubjectChangeHandler = (event) => {
    let Subject = event.target.value;
    setCreateEmails((prevState) => {
      return { ...prevState, subject: Subject };
    });
  };

  const SourceChangeHandler = (event) => {
    setSelectedSource(event.target.value);
    get_fields(event.target.value);
  };

  const SaveHandler = () => {
    setLoading(true);
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .post(Settings.apiUrl + `/emaildetails`, {
        subject: createEmails?.subject,
        body: content,
      })
      .then(function (res) {
        if (res.data.status === "success") {
          Helper.alert(res.data.message);
          setCreateEmails({
            subject: "",
          });
          setContent("");
          setLoading(false);
          navigate("/email-templates");
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
        setLoading(false);
      });
  };

  const handleDropdownChange = (event) => {
    const selectedValue = event?.params?.data?.text;
    const currentValue = content;
    const newValue =
      currentValue.replace(/<\/?p>/g, "") +
      (currentValue.length > 0 ? " " : "") +
      `{{${selectedValue}}}`;
    setContent(newValue);
  };

  let security = props.security;
  let frontendIds = Gui_id_list;
  if (
    !security.canView(
      Gui_id_list.setupForms.email_template.email_template_create_screen
    )
  ) {
    return (
      <Fragment>
        <Alert message="You do not have the necessary permissions to access this screen. Please contact your administrator for assistance." type="danger" />
      </Fragment>
    );
  }

  return (
    <Fragment> 
      { loading ? <OverlayLoader /> : null }
      <div className="container-fluid create-emails-section">
        <div ref={wrapperDiv}>
          <NavigationHeder hideMoreBtn={true} title="Create Email Template">
            <LinkBtn
              isActive={false}
              to="/email-templates"
              title={Helper.getLabel(props.language, "close", "Close")}
            />
            <Button
              isDisable={
                !security.canCreate(
                  frontendIds.setupForms.email_template
                    .email_template_create_screen_save_button
                )
              }
              isActive={false}
              className="rfq_save_btn"
              title={Helper.getLabel(props.language, "save", "Save")}
              onClick={SaveHandler}
            />
          </NavigationHeder> 
        </div>
    
        <div className='common_scrollbar_styles' style={{ height: `${height - 30}px`, overflowY: 'auto'}}> 
          <Collapse title="Email Template" open={true}>
            <div className="d-flex gap-4">
              <div className="col-lg-3 mb-4">
                <Input
                  label={Helper.getLabel(props.language, "subject", "Subject")}
                  type="text"
                  onChange={SubjectChangeHandler}
                  value={createEmails.subject}
                />
              </div>
              <div className="col-lg-3 mb-4">
                <DropDownInput
                  id="dynamic-sources"
                  label={Helper.getLabel(props.language, "sources", "Sources")}
                  options={sources}
                  onChange={SourceChangeHandler}
                />
              </div>
              {selectedSource ? (
                <div className="col-lg-3 mb-4">
                  <DropDownInput
                    id="dynamic-fields"
                    label={Helper.getLabel(props.language, "fields", "Fields")}
                    options={fields}
                    onChange={handleDropdownChange}
                    reRenderRequired={true}
                  />
                </div>
              ) : null}
            </div>

            <JoditEditor
              ref={editor}
              value={content}
              tabIndex={1} // tabIndex of textarea
              onChange={(newContent) => setContent(newContent)}
            />
          </Collapse>
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  };
};
const SecurityOptions = {
  gui_id: Gui_id_list.setupForms.email_template.email_template_create_screen,
};

export default connect(mapStateToProps)(
  MasterComponentWraper(ApplySecurityRoles(CreateEmail, SecurityOptions))
);
