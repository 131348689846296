import React, { useEffect, useRef, useState } from 'react'
import '../PO.css'
import { connect } from 'react-redux'
import Gui_id_list from '../../../inc/Gui_id_list'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import NavigationHeder from '../../Navigations/NavigationHeder'
import SegmentsDistribution from '../../Segments/SegmentsDistribution'
import Button from '../../inc/Button'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import Collapse from '../../inc/Collapse'
import Helper from '../../../inc/Helper'
import BlueCallToAction from '../../Widgets/BlueCallToAction/BlueCallToAction'
import MasterSidebar from '../../Sidebars/MasterSidebar'
import NoteAttachmentHistory from '../../Widgets/NoteAttachmentHistory'
import Alert from '../../inc/Alert'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Api from '../../../inc/Api'
import SimpleLoading from '../../Loading/SimpleLoading'
import Settings from '../../../inc/Settings'
import { SourceIdentificationFromDocumentType, ValidateArray,getPopupMessageBasedOnStatus , checkIfArrayIsEmpty, objectIsEmpty} from '../../../inc/Validation'
import FormValidator from '../../../inc/FormValidator'
import HeaderViewGeneral from './HeaderViewGeneral'
import HeaderViewDelivery from './HeaderViewDelivery'
import HeaderViewPriceDiscount from './HeaderViewPriceDiscount'
import LineViewComponent from './ListView/LineViewComponent'
import LoadPurchaseStatus from '../../../actions/LoadPurchaseStatus'
import ActionTypes from '../../../inc/ActionsTypes'
import Team from '../Team/Team'
import PoTeamAccess from '../../SecurityRoles/PoTeamAccess'
import AddressList from '../../Tenant/Address/AddressList'
import ContactAddress from '../../Tenant/Address/ContactAddress'
import TotalWidget from '../../Widgets/TotalWidget/TotalWidget'
import TaxTransactionPopup from './ListView/TaxTransactionPopup'
import CancelDocumentsPopup from '../PoPopups/CancelDocumentsPopup'
import PoHelper from '../PoHelper'
import RsDataLoader from '../../inc/RsDataLoader'
import WorkFlowCommonHeader from '../../Header/WorkFlowCommonHeader'
import WorkFlowCommonHeaderAPICalls from '../../Header/WorkflowCommonHeaderAPICalls'
import DatepickerFunctions from '../../../inc/DatepickerHelper'
import FixedNavigationHeder from '../../Navigations/FixedNavigationHeder'
import TabContentWraper from '../../Widgets/Tabs/TabContentWraper'
import WorkFlowOffCanvas from '../../Header/WorkFlowOffCanvas'
import axios from 'axios'
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader'
import { getNewValue } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import TermConditionsTemplates from '../../External Screens/Request For Quotation/Terms Conditions/TermConditionsTemplates'

let isDisable = true
let defaultGridData = null
let DefStateData = null;
let contactGridObj = null
let cancelTokenSource = axios.CancelToken.source();

const EditPO = (props) => {
  const navigate = useNavigate(); 
  const { id, instance_id } = useParams()
  const hasPoChanged = useRef(false);
  //States Component - Wide
  const [isVisibleCancelDocumentPopup, setIsVisibleCancelDocumentPopup] = useState(false);
  const [isShowTransactionPopup, setIsShowTransactionPopup] = useState(false);
  const [isNoPOFound, setIsNoPOFound] = useState(false);
  const [purchaseStatusLabel, setPurchaseStatusLabel] = useState({});
  const poTeamAccess = props.poAccessForTeam;
  const [isPoEditable, setIsPoEditable] = useState(false)
  const [loading, setLoading] = useState(true)
  const [orderStatusProps, setOrderStatusProps] = useState({})
  const [saveBtnApi, setSaveBtnApi] = useState(false);
  const [fundBtnApi,setFundBtnApi] = useState(false)

  // const [dataFromGridAndInput, setDataFromGridAndInput] = useState([])
  let editPoFromDb = useRef(null);
  const [editPO, setEditPO] = useState({})
  const [editPOLinesGrid, setEditPOLinesGrid] = useState([])
  //const [previousPurchaseOrderLinesData, setPreviousPurchaseOrderLinesData] = useState(Object.freeze([]))
  const [taxCode, setTaxCode] = useState([])
  const [taxGroup, setTaxGroup] = useState([])
  const [requester, setRequester] = useState([])
  const [ordered, setOrdered] = useState([])
  const [vendorsDropdown, setVendorsDropdown] = useState([])
  const [vendors, setVendors] = useState([])
  const [unit, setUnit] = useState([])
  const [type, setType] = useState([])
  const [sites, setSites] = useState([])
  const [entities, setEntities] = useState([])
  const [numberSequence,setNumberSequence]=useState({})

  //workflow states
  const [showCanvas, setShowCanvas] = useState(false)
  const [showCurrentAssignees, setShowCurrentAssignees] = useState(false)
  const [workflowEligible, setWorkflowEligible] = useState(null)
  const [workflowExist, setWorkflowExist] = useState({}) 
  const [workflowActionHistory, setWorkflowActionHistory] = useState([])

  const [documentStatusLabel,setDocumentStatusLabel] = useState('')

  const [selectedSupNav, setSelectedSupNav] = useState('Header View')
  const [scrollDitection, setScrollDitection] = useState('down')
  const [height, setHeight] = useState(0); 
  const myDivRef = useRef(null); 
  const linesDataRef = useRef([])
  let sectionName = 'po-edit'

  useEffect(() => {
    Helper.resizeWindowHandle(myDivRef,setHeight,window)
  },[myDivRef.current?.clientHeight,scrollDitection]);

  let purchase_order_source_id = props.source_id ? props.source_id : Settings.source_id.purchase_order;
  //End

  // for workflow api call
  const workflowCalls = new WorkFlowCommonHeaderAPICalls()

  //Fields Validation

  let validator;

  const validationConfigure = () => {
    let fieldConfig = [
      { name: 'vendor_id', displayName: Helper.getLabel(props.language, 'vendor_id', 'Vendor'), types: ['Required'] },
      { name: 'warehouse_id', displayName: Helper.getLabel(props.language, 'warehouse_id', 'Warehouse'), types: ['Required'] },
      { name: 'accounting_date', displayName: Helper.getLabel(props.language, 'accounting_date', 'Accounting date'), types: ['Required'] },
      { name: 'document_date', displayName: Helper.getLabel(props.language, 'document_date', 'Document date'), types: ['Required'] },
      { name: 'entity_id', displayName: Helper.getLabel(props.language, 'entity', 'Entity'), types: ['Required'] },
      { name: 'currency_id', displayName: Helper.getLabel(props.language, 'currency_id', 'Currency '), types: ['Required'] },
      { name: 'site_id', displayName: Helper.getLabel(props.language, 'site_id', 'Site '), types: ['Required'] },
      { name: 'emailaddress', displayName: Helper.getLabel(props.language, 'email', 'Email'), types: ['Email'] },
      { name: 'discount_amount', displayName: Helper.getLabel(props.language, 'discount_amount', 'Discount amount '), minValue: 0 },
      { name: 'discount_percentage', displayName: Helper.getLabel(props.language, 'discount_percentage', 'Discount percentage '), maxValue: 100, minValue: 0 },
    ]
    if(props.type==="purchase agreement"){
      let datesArr = [
        { name: 'start_date', displayName: Helper.getLabel(props.language, 'start_date', 'Start date'), types: ['Required'] },
        { name: 'end_date', displayName: Helper.getLabel(props.language, 'end_date', 'End date'), types: ['Required'] },
      ]
      fieldConfig.concat(datesArr)
    }
    if (!validator) {
      validator = new FormValidator(fieldConfig, props.language)
    }
  }

  validationConfigure()


  const updateSidebarOrderState = (dataArg, poHeaderData) => {
    let lineNetAmount = 0, lineDiscountAmount = 0, lineDiscount = 0, discount = 0, exchangeRate = Helper.getOnlyNumber(poHeaderData?.exchange_rate), roundOff = Helper.getOnlyNumber(poHeaderData?.rounding_amount);
    dataArg.forEach(lineItem => {
      lineNetAmount += Helper.getOnlyNumber(lineItem?.ordered_qty) * Helper.getOnlyNumber(lineItem?.unit_price);
      lineDiscountAmount += Helper.getOnlyNumber(lineItem?.line_amount)
    });
    let discount_percentage = 0;
    if (Helper.getOnlyNumber(poHeaderData?.discount_percentage) > 0) {
      discount_percentage = (Helper.getOnlyNumber(poHeaderData?.discount_percentage) / 100) * lineDiscountAmount;
    }
    lineDiscount = lineNetAmount - lineDiscountAmount;
    discount = Helper.getOnlyNumber(poHeaderData?.discount_amount) + discount_percentage
    setOrderStatusProps((data) => {
      return (
        {
          ...data,
          //exchange_rate:exchangeRate,
          discount: discount,
          round_off: roundOff,
          line_amount: lineNetAmount,
          line_discount: lineDiscount
        }
      )
    })
  }

  useEffect(()=>{
    getPurchaseOrdersLines()
  },[linesDataRef?.current?.length,selectedSupNav])

  useEffect(function () {
    updateSidebarOrderState(editPOLinesGrid, editPO);
    //Helper.alert(hasPoChanged.current)
    hasPoChanged.current = true;
    if ((props?.type==="purchase agreement"?editPO?.agreement_id:editPO.purchase_order_id) && editPoFromDb.current != null) {
      if (editPoFromDb.current.warehouse_id != editPO.warehouse_id && linesDataRef?.current?.length !== 0) {
        Helper.confirmMe(() => {
          updateLineOrderData('warehouse', Helper.getOnlyInt(editPO.warehouse_id, true))
        }, "Warehouse will be updated", null, "Do you want to update on line view?",420)
      }
      if (editPoFromDb.current.delivery_date != editPO.delivery_date && linesDataRef?.current?.length !== 0) {
        Helper.confirmMe(() => {
          updateLineOrderData('delivery_date', DatepickerFunctions.convertDateForDataBase(editPO.delivery_date))
        }, "Delivery date will be updated", handleChangeHeader('delivery_date', DatepickerFunctions.convertDateForDataBase(editPO.delivery_date)), "Do you want to update on line view?",420)
      }
      if (editPoFromDb.current.taxgroupitem_id != editPO.taxgroupitem_id && linesDataRef?.current?.length !== 0) {
        Helper.confirmMe(() => {
          updateLineOrderData('taxgroupitem_id', Helper.getOnlyInt(editPO.taxgroupitem_id, true))
        }, "Taxgroup item will be updated", null, "Do you want to update on line view?", 420)
      }
      if (editPoFromDb.current.taxgroup_id != editPO.taxgroup_id && linesDataRef?.current?.length !== 0) {
        Helper.confirmMe(() => {
          updateLineOrderData('taxgroup_id', Helper.getOnlyInt(editPO.taxgroup_id, true))
        }, "Tax group will be updated", null, "Do you want to update on line view?", 420)
      }
      if (editPoFromDb.current.requester_id != editPO.requester_id && linesDataRef?.current?.length !== 0) {
        Helper.confirmMe(() => {
          updateLineOrderData('requester_id', Helper.getOnlyInt(editPO.requester_id, true))
        }, "Requester will be updated", null, "Do you want to update on line view?",420)
      }
      if (editPoFromDb.current.orderer_id != editPO.orderer_id && linesDataRef?.current?.length !== 0) {
        Helper.confirmMe(() => {
          updateLineOrderData('orderer_id', Helper.getOnlyInt(editPO.orderer_id, true))
        }, "Orderer will be updated", null, "Do you want to update on line view?",420)
      }
      if (editPoFromDb.current.entity_id != editPO.entity_id && linesDataRef?.current?.length !== 0) {
        Helper.confirmMe(() => {
          updateLineOrderData('entity_id', Helper.getOnlyInt(editPO.entity_id, true))
        }, "Entity will be updated", null, "Do you want to update on line view?",420)
      }
      if (editPoFromDb.current.site_id != editPO.site_id && linesDataRef?.current?.length !== 0) {
        Helper.confirmMe(() => {
          updateLineOrderData('site_id', Helper.getOnlyInt(editPO.site_id, true))
        }, "Site will be updated", null, "Do you want to update on line view?",420)
      }
      editPoFromDb.current = editPO;
    }
  }, [editPO])
  // line update default data
  const updateLineOrderData = (key, value) => {
    let poPutData = {
      [key]: Helper.getNullableValue(value)
    }
    let api = Api;
    api.setUserToken();
    linesDataRef?.current?.forEach(lineObj => {
      api.axios().put(Settings.apiPurchaseOrderUrl + `/line/${lineObj.line_id}/${props.source_id?props.source_id:Settings.source_id.purchase_order}`, poPutData).then(res => {
        //hide for a time being
        // Helper.alert(res.data.message)
      }).catch(error => {
        Helper.alert(error.response.data.message, 'error')
      })
    })
  }
  const handleChangeHeader = (key,value) => {
    let poPutData = {
      [key]: Helper.getNullableValue(value)
    }
    let api = Api;
    api.setUserToken();
    api.axios().put(getApiUrl(), poPutData).then(res => {
      Helper.alert(res.data.message)
    }).catch(error => {
      Helper.alert(error.response.data.message, 'error')
    })
  }
  //End
  let superNav = [
    { title: Helper.getLabel(props.language, 'header_view', 'Header View') },
    { title: Helper.getLabel(props.language, 'line_view', 'Line View') },
  ]
  let navs = [
    { title: Helper.getLabel(props.language, 'general', 'General'), data_section: "one", active_status: 'active_tab' },
    { title: Helper.getLabel(props.language, 'delivery', 'Delivery'), data_section: "two", active_status: '' },
    { title: Helper.getLabel(props.language, 'price_discount', 'Price & Discount'), data_section: "three", active_status: '' },
    { title: Helper.getLabel(props.language, 'segment', 'Segment'), data_section: "four", active_status: '' },
    { title: Helper.getLabel(props.language, 'address', 'Address'), data_section: "five", active_status: '' },
    { title: Helper.getLabel(props.language, 'contact', 'Contact'), data_section: "six", active_status: '' },
    { title: Helper.getLabel(props.language, 'terms_conditions', 'Terms & Conditions'), data_section: "seven", active_status: '' } 
    // { title: Helper.getLabel(props.language, 'team', 'Team'), data_section: "seven", active_status: '' },
  ];
  const getVendorFromId = (vendorId) => {
    let singleVendor = { name: '', vendor_id: '', code: '' };
    vendors.forEach(vn => {
      if (vn.vendor_id == vendorId) {
        singleVendor = vn;
      }
    });
    return singleVendor;
  }

  const addComponentTop = () => { 
    const isInvoiceOrCreditNote = props.type === "invoice on account" || props.type === "credit notes";
    const editPOReferenceNumber = isInvoiceOrCreditNote ? editPO?.reference_number : editPO.document_number || "-";
    const documentDate = isInvoiceOrCreditNote ? DatepickerFunctions.convertDateFromDataBase(editPO?.document_date) : '-';
    const vendorCode = isInvoiceOrCreditNote ? editPO?.vendor_code : editPO?.vendor_code || "-";
    const vendorName = isInvoiceOrCreditNote ? editPO?.vendor_name : editPO?.vendor_name || "-";
    let currentpurchaseStatus
    let items = [
      { title:props.type==="invoice on account"? Helper.getLabel(props.language, 'invoice_number', "Invoice No."): Helper.getLabel(props.language, 'document_number', "Document number"), subtitle:editPOReferenceNumber },
      { title: Helper.getLabel(props.language, 'document_type', "Document Type"), subtitle: props.type==='purchase agreement'?"Purchase Agreement":getDocumentTypeLabel() },
      { title: Helper.getLabel(props.language, 'vendor_id', "Vendor Code"), subtitle:vendorCode },
      { title: Helper.getLabel(props.language, 'vendorname', "Vendor Name"), subtitle:vendorName },
    ]
    if(isInvoiceOrCreditNote) {
      items.push(
     { title: Helper.getLabel(props.language, 'document_date', "Document Date"), subtitle:documentDate},
     )}
    if (!isInvoiceOrCreditNote) {
      currentpurchaseStatus =  props.purchaseStatus.allStatus.find(item => {
        return item.id == editPO.purchase_status 
      })
      setPurchaseStatusLabel(currentpurchaseStatus)
    }
    let statusColor = currentpurchaseStatus ? getPoColorCode(currentpurchaseStatus?.id) : getPoColorCode(purchaseStatusLabel?.id);
    let tagTitle = isInvoiceOrCreditNote ?purchaseStatusLabel?.name:currentpurchaseStatus?.name;
    let tags = [
      { title: tagTitle || null, style: { backgroundColor: statusColor.bgColor, color: statusColor.textColor } },
    ]

    if (isInvoiceOrCreditNote) {
      items = items.filter(item => item.title !==  Helper.getLabel(props.language, 'document_type', "Document Type"));
    }

    return (
      <div className="rfq-internal-blue">
        <BlueCallToAction scrollDitection={scrollDitection} items={items} tags={tags} />
        {/* <SwitchView hasPoChanged={hasPoChanged} poSaveHandler={SaveHandler} setObjectClear={setListViewTabsObj} setViewComponent={setViewComponent} viewComponent={viewComponent} /> */}
      </div>
    )
  }
  
  const addComponentRight = () => {
    return (
      <div className='parent-sidebar-cn'> 
      <MasterSidebar> 
        <NoteAttachmentHistory
          openCurrentAssignees={setShowCurrentAssignees}
          openCanvas={setShowCanvas}
          hideHistory={!workflowActionHistory?.history?.length}
          historyArray={workflowActionHistory}
          scrollDitection={scrollDitection}
          height={height}
          source_id={props.source_id?props.source_id:purchase_order_source_id} 
          notesource_integrator={id} 
          attachmentsource_integrator={id} 
        />
      </MasterSidebar>  
      {showCanvas &&
          <WorkFlowOffCanvas
            isOpen={showCanvas}
            type={{ label: "Action History", value: 9 }}
            closeSidebar={setShowCanvas}
            source_id={SourceIdentificationFromDocumentType(type,editPO?.type,props?.type)}
            source_item_id={id}
          />
        } 
        {showCurrentAssignees && 
          <WorkFlowOffCanvas
            isOpen={showCurrentAssignees}
            type={{ label: "View current assignment", value: 43 }}
            closeSidebar={setShowCurrentAssignees}
            source_id={SourceIdentificationFromDocumentType(type,editPO?.type,props?.type)}
            source_item_id={id}
          />
        }
      </div>
    )
  }

  const LoadPurchaseStatus = () => {
    props.LoadPurchaseStatus(props.purchaseStatus)
  }
  //API Calls Renderer
  useEffect(() => {  
    TaxCodeAPI()
    TaxGroupAPI()
    SitesAPI()
    EntityAPI()
    RequesterAPI()
    OrderedAPI()
    getUnitData()
    LoadPurchaseStatus()
    setOrderStatusProps({
      currency: "",
      exchange_rate: 0,
      discount: 0,
      round_off: 0,
      line_amount: 0,
      line_discount: 0,
      tax: 0
    })

    getWorkFlowCalls();
    document.title = props.title?props.title:"Edit Purchase Order"; 
  }, [])
 
  useEffect(()=>{
    VendorsAPI() 
    getDocumentStatusLabel()
    if(props.type === "invoice on account" || props.type === "credit notes") { getInvoicePurchaseStatus();}
  },[editPO?.document_status,editPO?.vendor_id])

 
  //End

  //API Reduction Calls
  let taxCodeCalled = false
  let taxGroupCalled = false
  let POCalled = false
  let POLinesCalled = false
  let typeCalled = false
  let vendorsCalled = false
  let RequesterCalled = false
  let entityCalled = false
  let sitesCalled = false
  let OrderedCalled = false
  let unitCalled = false
  //End
  const setIsPoEditableConditions = (PoData) => {
    let result = true;
    if (
      PoData.purchase_status == Settings.po_purchase_status.confirmed.id ||
      PoData.purchase_status == Settings.po_purchase_status.close.id ||
      PoData.purchase_status == Settings.po_purchase_status.freeze.id ||
      PoData.purchase_status === Settings.po_purchase_status.hold.id ||
      PoData.purchase_status === Settings.po_purchase_status.canceled.id ||
      PoData.document_status === 1 ||   PoData.document_status === 2 ||
      PoData.purchase_status === Settings.po_purchase_status.open.id && PoData.document_status === 1 ||
      ((props.type==="invoice on account" || props.type==="credit notes") && PoData.document_status == 1)
    ) {
      result = false;
    }


    if (poTeamAccess && poTeamAccess.access_level == 1) {
      result = false;
    }
    setIsPoEditable(result)
  }
  //API Calls

  let url 
  const getApiUrl=()=>{
    if(props.type==="invoice on account" || props.type==="credit notes"){
      url = Settings.apiPurchaseOrderUrl + `/invoice_header/${id}`
    }else if (props.type==="purchase agreement"){
      url = Settings.apiPurchaseOrderUrl + `/PA_header/${id}`
    }
    else{
      url = Settings.apiPurchaseOrderUrl + `/PO_header/${id}`
    }
    return url
  }

  const getPurchaseOrders = async (PoCallBackFn = null) => { 
    let api = Api;
  
    try {
      api.setUserToken();
      const res = await api.axios().get(getApiUrl(), {});
      let numberSequenceData = null;

      try {
        numberSequenceData = await api.axios().get(Settings.apiUrl + `/ns_status/${res?.data?.data?.entity_id}/${props?.source_id ? props?.source_id : Settings.source_id.purchase_order}`);
        setNumberSequence(numberSequenceData?.data)
      } catch (error) {
        console.error('Failed to fetch number sequence data:', error);
      }
      if (res.data.status === "success") {
        let rsPoServerData = {
          ...res.data.data,
          //start_date: DatepickerFunctions.convertDateFromDataBase(res.data.start_date),
          //end_date: DatepickerFunctions.convertDateFromDataBase(res.data.end_date)
        };
        setNumberSequence(numberSequenceData?.data)
        setIsNoPOFound(false);
        editPoFromDb.current = rsPoServerData;
        setIsPoEditableConditions(rsPoServerData);
        if (rsPoServerData.document_number == null) {
          isDisable = false;
        }
        if (PoCallBackFn && typeof PoCallBackFn == 'function') {
          PoCallBackFn(rsPoServerData);
        }
        return res?.data?.data
      }
    } catch (error) { 
      setIsNoPOFound(true);
    }
  };

  const getPurchaseOrdersLines = (argData) => {
    if (POLinesCalled) {
      return;
    }
    let api = Api
    POLinesCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiPurchaseOrderUrl + `/lines/${id}/${props.source_id?props?.source_id:Settings.source_id.purchase_order}`, {}).then(function (res) {
      if (res.data.status === "success") {
        linesDataRef.current = res.data.data
        setEditPOLinesGrid(res.data.data.reverse())
        updateSidebarOrderState(res.data.data.reverse(), argData);
        //setPreviousPurchaseOrderLinesData(Object.freeze(res.data.data))
        if (defaultGridData === null) {
          defaultGridData = res.data.data
        }
        if (!DefStateData) {
          DefStateData = {};
          res.data.data.forEach((element) => {
            DefStateData[element.line_id] = element
          })
        }

      }
    }).catch((res) => {
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const RequesterAPI = () => {
    if (RequesterCalled) {
      return;
    }
    let api = Api
    RequesterCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiOrgUrl + '/employee_dropdown', {}).then(function (res) {
      if (res.data.status === "success") {
        let Requester = []
        res.data.data.forEach(item => {
          Requester.push({
            value: item.employee_id,
            label: `${item.displayname}`
          })
        })
        setRequester(Requester)
      }
    }).catch((res) => {
      // getPopupMessageBasedOnStatus(res) 
    })
  }

  const OrderedAPI = () => {
    if (OrderedCalled) {
      return;
    }
    let api = Api
    OrderedCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiOrgUrl + '/employee_dropdown', {}).then(function (res) {
      if (res.data.status === "success") {
        let OrderedARR = []
        res.data.data.forEach(item => {
          OrderedARR.push({
            value: item.employee_id,
            label: `${item.displayname}`
          })
        })
        setOrdered(OrderedARR)
      }
    }).catch((res) => {
      // getPopupMessageBasedOnStatus(res) 
    })
  }

  const getUnitData = () => {
    if (unitCalled) {
      return;
    }
    let api = Api
    unitCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl + `/unit`, {}).then(function (res) {
      if (res.data.status === "success") {
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
          UPDATED_STATE.push({
            value: element.unit_id,
            label: `${element.code} - ${element.description}`
          })
        })
        setUnit(UPDATED_STATE)
      }
    }).catch((res) => {
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const TaxCodeAPI = () => {
    if (taxCodeCalled) {
      return;
    }
    let api = Api
    taxCodeCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl + '/taxgroupitem', {}).then(function (res) {
      if (res.data.status === "success") {
        let TaxCode = []
        res.data.data.forEach(item => {
          TaxCode.push({
            value: item.taxgroupitem_id,
            label: item.name
          })
        })
        setTaxCode(TaxCode)
      }
    }).catch((res) => {
      // getPopupMessageBasedOnStatus(res) 
    })
  }

  const TaxGroupAPI = () => {
    if (taxGroupCalled) {
      return;
    }
    let api = Api
    taxGroupCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl + '/taxgroup', {}).then(function (res) {
      if (res.data.status === "success") {
        let TaxGroup = []
        res.data.data.forEach(item => {
          TaxGroup.push({
            value: item.taxgroup_id,
            label: item.name
          })
        })
        setTaxGroup(TaxGroup)
      }
    }).catch((res) => {
      // getPopupMessageBasedOnStatus(res) 
    })
  }

  const VendorsAPI = () => {
    if (vendorsCalled) {
      return;
    }
    let api = Api
    vendorsCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiVendorUrl + `/vendor?search=${editPO?.vendor_code}`).then(function (res) {
      if (res.data.status === "success") {
        let VendorsDropdownARR = []
        res.data.data.forEach(item => {
          VendorsDropdownARR.push({
            value: item.vendor_id,
            label: `${item.code} - ${item.name}`
          })
        })
        setVendorsDropdown(VendorsDropdownARR)
        setVendors(res.data.data)
      }
    }).catch((res) => {
      // getPopupMessageBasedOnStatus(res) 
    })
  }

  const EntityAPI = () => {
    if (entityCalled) {
      return;
    }
    let api = Api
    entityCalled = true
    api.setUserToken()
    api.axios().post(Settings.apiUrl + '/search_entity', {
      "code": "",
      "name": ""
    }).then(function (res) {
      if (res.data.status === "success") {
        let EntityARR = []
        res.data.data.forEach(item => {
          EntityARR.push({
            value: item.entity_id,
            label: `${item.code} - ${item.name}`
          })
        })
        setEntities(EntityARR)
      }
    }).catch((res) => {
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const SitesAPI = () => {
    if (sitesCalled) {
      return;
    }
    let api = Api
    sitesCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl + '/get_site').then(function (res) {
      if (res.data.status === "success") {
        let SitesARR = []
        res.data.data.forEach(item => {
          SitesARR.push({
            value: item.site_id,
            label: `${item.code} - ${item.name}`
          })
        })
        setSites(SitesARR)
      }
    }).catch((res) => {
      // getPopupMessageBasedOnStatus(res) 
    })
  }

  const TypeAPI = async () => {
    if (typeCalled) {
      return;
    }
  
    let api = Api;
    typeCalled = true;
  
    try {
      api.setUserToken();
      const res = await api.axios().get(Settings.apiUrl + '/enum/POHeaderType', {});
  
      if (res.data.status === "success") {
        let TypeARR = [];
        res.data.data.forEach(item => {
          TypeARR.push({
            value: item.id,
            label: item.name
          });
        });
        setType(TypeARR);
        return TypeARR
      }
    } catch (error) {
      return []
    }
  };

  //End

  // Header View Tab
  const HeaderViewSegment = () => {
    return (
      <Collapse className='mb-5' open={true} title={Helper.getLabel(props.language, 'segment', 'Segment')}>
        <SegmentsDistribution isEditable={isPoEditable} id="purchase_order_header_segments" disableFinancial={true} onCreateFirstDistribution={onCreateFirstDistribution} distribution_source_id={editPO.distributionsource_id} integrator={id} source_id={props.source_id?props.source_id:purchase_order_source_id} totalAmount={100} accountingDate={editPO?.accounting_date ?? new Date()}/>
      </Collapse>
    )
  }

  let payload =   {
    "type": !ValidateArray(type) ? type.find(id => id.label == "Purchase Order")?.value : null,
    "description": editPO.description !== "" ? editPO.description : null,
    "vendor_id": editPO.vendor_id ? parseInt(editPO.vendor_id) : null,
    "vendorsite_id": editPO.vendorsite_id ? parseInt(editPO.vendorsite_id) : null,
    "vendorgroup_id": editPO.vendorgroup_id ? parseInt(editPO.vendorgroup_id) : null,
    "accounting_date": DatepickerFunctions.convertDateForDataBase(editPO.accounting_date),
    "document_date": DatepickerFunctions.convertDateForDataBase(editPO.document_date),
    "delivery_date": DatepickerFunctions.convertDateForDataBase(editPO.delivery_date),
    "period_id": editPO.period_id ? parseInt(editPO.period_id) : null,
    "delivery_address_id": editPO.delivery_address_id,
    "delivery_name": editPO.delivery_name !== "" ? editPO.delivery_name : null,
    "attention_information": editPO.attention_information !== "" ? editPO.attention_information : null,
    "mode_of_delivery_id": editPO.mode_of_delivery_id ? parseInt(editPO.mode_of_delivery_id) : null,
    "delivery_term_id": editPO.delivery_term_id ? parseInt(editPO.delivery_term_id) : null,
    "discount_amount": editPO.discount_amount !== "" ? editPO.discount_amount : 0,
    "discount_percentage": editPO.discount_percentage !== "" ? editPO.discount_percentage : 0,
    "rounding_amount": editPO.rounding_amount !== "" ? editPO.rounding_amount : 0,
    "taxgroup_id": editPO.taxgroup_id ? parseInt(editPO.taxgroup_id) : null,
    "taxgroupitem_id": editPO.taxgroupitem_id ? parseInt(editPO.taxgroupitem_id) : null,
    "priceinclude_tax": editPO.price_include_tax == "on" || editPO.price_include_tax === true? editPO.price_include_tax ? true : (editPO.price_include_tax == "on" ? true : false) : false,
    "requester_id": editPO.requester_id ? parseInt(editPO.requester_id) : null,
    "orderer_id": editPO.orderer_id ? parseInt(editPO.orderer_id) : null,
    "currency_id": editPO.currency_id ? parseInt(editPO.currency_id) : null,
    "emailaddress": editPO.emailaddress !== "" ? editPO.emailaddress : null,
    "warehouse_id": editPO.warehouse_id ? parseInt(editPO.warehouse_id) : null,
    "entity_id": editPO.entity_id ? parseInt(editPO.entity_id) : null,
    "site_id": editPO.site_id ? parseInt(editPO.site_id) : null,
    "buyergroup_id": editPO.buyer_group_id ? parseInt(editPO.buyer_group_id) : null,
    "paymentterm_id": editPO.payment_term_id ? parseInt(editPO.payment_term_id) : null,
    "distributionsource_id": editPO.distributionsource_id,
    "blindreceivingof_qty": editPO.blindreceivingof_qty  ? (editPO.blindreceivingof_qty) : false,
    "blindreceivingof_item": null,
    "source_id": null,
    "externalreference": editPO.external_reference !== "" ? editPO.external_reference : null,
    "attachmentsource_id": null,
    "notesource_id": null,
    "reference_number":props.type==="credit notes"?editPO?.document_number:null,
    "document_number": numberSequence?.ns_type==="manual" ? editPO.document_number : null,
    "start_date": DatepickerFunctions.convertDateForDataBase(editPO.start_date),
    "end_date": DatepickerFunctions.convertDateForDataBase(editPO.end_date),
    "allow_access_only_to_buyer_group": editPO.allow_access_to_only_buyer_group == "on" ? (editPO.allow_access_to_onlbuyer_group == "on") : false
  }

  //API Executions UPDATE Requests
  const UpdatePurchaseOrderHeader = async (isShowResponseMessage = true) => {
    try {
      // setLoading(true);
      let api = Api;
      api.setUserToken();

      if(props?.type==='purchase agreement'){
        payload.pa_classification_id = editPO?.pa_classification_id ? parseInt(editPO?.pa_classification_id) : ""
        payload.pick_startdate_and_enddate_from_lines = editPO.pick_startdate_and_enddate_from_lines == "on" || editPO.pick_startdate_and_enddate_from_lines === true? editPO.pick_startdate_and_enddate_from_lines ? true : (editPO.pick_startdate_and_enddate_from_lines == "on" ? true : false) : false
      }
  
      const res = await api.axios().put(getApiUrl(), payload,{cancelToken:cancelTokenSource.token});
  
      if (res.data.status === "success") {
        if (isShowResponseMessage) {
          Helper.alert(res.data.message);
        }
        setLoading(false) 
        getWorkFlowCalls()
        if (editPO.document_number !== null) {
          isDisable = true;
        }
      }
    } catch(res){
      getPopupMessageBasedOnStatus(res);
      setLoading(false);
      getWorkFlowCalls();
    } 
  };
  

  const updateOrderStatus = (newOrderStatus) => {
    let api = Api;
    api.setUserToken();
    let newPoData = {
      purchase_status: newOrderStatus
    }
    props.setMasterContentLoader(true)
    api.axios().put(getApiUrl(), newPoData).then(res => {
      Helper.alert(res.data.message)
      POCalled = false;
      props.setMasterContentLoader(false)
      getWorkFlowCalls()
    }).catch(error => {
      Helper.alert(error.response.data.message, 'error');
      props.setMasterContentLoader(false)
    })
  }
  const updateOrderDocumentStatus = (document_status) => {
    let api = Api;
    api.setUserToken();
    let newPoData = {
      document_status: document_status
    }
    props.setMasterContentLoader(true)
    api.axios().put(getApiUrl(), newPoData).then(res => {
      Helper.alert(res.data.message)
      POCalled = false;
      props.setMasterContentLoader(false)
      getWorkFlowCalls()
    }).catch(error => {
      Helper.alert(error.response.data.message, 'error');
      props.setMasterContentLoader(false)
    })
  }
  const onAddressCreateHandler = (addressResponse) => {
    setEditPO({
      ...editPO,
      addresssource_id: addressResponse.address_source_id
    })
  }
  const onContactCreate = (contactResponse) => {
    setEditPO({
      ...editPO,
      contactsource_id: contactResponse.contact_source_id
    })
  }
  //END
  const getPoColorCode = (statusId) => {
    let output = {
      bgColor: '#fff',
      textColor: '#000'
    }
    let poStatusWithColor = Settings.po_purchase_status;
    for (const key in poStatusWithColor) {
      if (Object.hasOwnProperty.call(poStatusWithColor, key)) {
        const poSt = poStatusWithColor[key];
        if (statusId == poSt.id) {
          output = poSt;
        }
      }
    }
    return output;
  }
  const poDeleteHandler = () => {
    Helper.confirmMe(function () {
      setLoading(true);
      let api = Api;
      api.setUserToken();
      api.axios().delete(getApiUrl()).then(res => {
        Helper.alert(res.data.message);
        navigate(-1);
      }).catch(error => {
        getPopupMessageBasedOnStatus(error)
        setLoading(false)
      })
    }, Helper.getLabel(null, 'po_del_msg', `${props.title?.replace(/Edit\s/, '')} will be deleted`))

  }
  const canPoActionButtonActive = (poActionKey, poActionId = null) => {
    let poHelper = new PoHelper(editPO,props.type);
    return poHelper.canPoActionButtonActive(poActionKey, poActionId, poTeamAccess)
  }
  const showTaxTransaction = () => {
    setIsShowTransactionPopup(true)
  }

  const afterCloseTaxPopup = () => {
    setIsShowTransactionPopup(false)
  }

  const afterCloseCancelDoumentPopup = () => {
    setIsVisibleCancelDocumentPopup(false)
  }
  
  const showCancelDocumentPopup = () => {
    Helper.confirmMe(() => {
      updateOrderStatus(Settings?.po_purchase_status?.canceled.id)
    }, "Your document will be canceled.", null, "Do you want to cancel your document?",420)
  }
  const getDocumentStatusLabel = () => {
    if(editPO?.document_status=== null) return
    let api = Api
    POCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl + props.type==="invoice on account" || props.type==="credit notes"?"/enum/invoice_header_documentstatus": "/enum/POHeaderDocumentStatus", {}).then(function (res) {
      if (res.data.status === "success") {
        const documentStatus = res.data.data.find((item)=>item.id===editPO?.document_status)
        setDocumentStatusLabel(documentStatus?.name)
      }})
  }

  const getInvoicePurchaseStatus = () => {
    if(editPO?.purchase_status=== null) return
    let api = Api
    POCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl + "/enum/invoice_header_purchasestatus").then(function (res) {
      if (res.data.status === "success") {
        const purchaseStatus = res.data.data.find((item)=>item.id===editPO?.purchase_status) 
        if(props.type==="invoice on account" || props.type==="credit notes" ){setPurchaseStatusLabel(purchaseStatus)}
      }})
  }

  const getDocumentTypeLabel = () => {
    let outputDTL = '';
    type?.find(tp => {
      if (editPO?.type === tp.value) {
        outputDTL = tp.label
      }
    })
    return outputDTL;
  }

  const navigationMoreOptions = () => {
    let PoPS = Settings.po_purchase_status;
    return (
      props.type==="invoice on account" || props.type==="credit notes"?<ul className='actions_nav'>
         {editPO?.document_status===0 ? <li onClick={handleSubmitInvoice}>{Helper.getLabel(props.language, 'post', "Post")}</li> : <li className='disable'>{Helper.getLabel(props.language, 'post', "Post")}</li>}
         {editPO?.document_status===0 ? <li onClick={handleSubmitInvoice}>{Helper.getLabel(props.language, 'hold', "Hold")}</li> : <li className='disable'>{Helper.getLabel(props.language, 'hold', "Hold")}</li>}
         {editPO?.document_status===0 ? <li onClick={handleSubmitInvoice}>{Helper.getLabel(props.language, 'unhold', "Unhold")}</li> : <li className='disable'>{Helper.getLabel(props.language, 'unhold', "Unhold")}</li>}
      </ul>:<ul className='actions_nav'>
        {canPoActionButtonActive('confirmed') ? <li onClick={e => { updateOrderDocumentStatus(1) }}>{Helper.getLabel(props.language, 'confirmed', "Confirmed")}</li> : <li className='disable'>{Helper.getLabel(props.language, 'confirmed', "Confirmed")}</li>}
        {canPoActionButtonActive('edit') ? <li onClick={e => { updateOrderDocumentStatus(0) }}  >{Helper.getLabel(props.language, 'edit', "Edit")}</li> : <li className='disable'>{Helper.getLabel(props.language, 'edit', "Edit")}</li>}
        {canPoActionButtonActive('close') ? <li onClick={e => { updateOrderStatus(PoPS.close.id) }} >{Helper.getLabel(props.language, 'close', "Close")}</li> : <li className='disable'>{Helper.getLabel(props.language, 'close', "Close")}</li>}
        {canPoActionButtonActive('reopen') ? <li onClick={e => { updateOrderStatus(PoPS.reopen.id) }} >{Helper.getLabel(props.language, 'reopen', "Reopen")}</li> : <li className='disable'>{Helper.getLabel(props.language, 'reopen', "Reopen")}</li>}
        {canPoActionButtonActive('freeze') ? <li onClick={e => { updateOrderStatus(PoPS.freeze.id) }}  >{Helper.getLabel(props.language, 'freeze', "Freeze")}</li> : <li className='disable'>{Helper.getLabel(props.language, 'freeze', "Freeze")}</li>}
        {canPoActionButtonActive('hold') ? <li onClick={e => { updateOrderStatus(PoPS.hold.id) }} >{Helper.getLabel(props.language, 'hold', "Hold")}</li> : <li className='disable'>{Helper.getLabel(props.language, 'hold', "Hold")}</li>}
        {canPoActionButtonActive('withdraw_freeze') ? <li onClick={e => { updateOrderStatus(PoPS.withdrawFreeze.id) }} >{Helper.getLabel(props.language, 'withdraw_freeze', "Withdraw Freeze")}</li> : <li className='disable'>{Helper.getLabel(props.language, 'withdraw_freeze', "Withdraw Freeze")}</li>}
        {props.type==='contract'?canPoActionButtonActive('renew') ? <li><Link to={(props.renewUrl ? props.renewUrl : '/purchase-order/renew') + "/" + id}>{Helper.getLabel(props.language, 'renew', "Renew")}</Link></li> : <li className='disable'>{Helper.getLabel(props.language, 'renew', "Renew")}</li>:null}
        <li className="nav_divider"><Link to={(props.versionUrl ? props.versionUrl : '/purchase-order-version') + "/" + id}>{Helper.getLabel(null, 'view_version', 'View Versions')}</Link></li>
        {canPoActionButtonActive('invoice') ? <li><Link to={(props.invoiceUrl ? '/invoice' :props?.type==="purchase agreement"?`/invoice-list?purchase_agreement_id=${id}`:`/invoice-list?purchase_order_id=${id}`)} state={{purchase_status:purchaseStatusLabel,document_status:documentStatusLabel,document_name:props?.title?.replace(/Edit\s/, '')}}>{Helper.getLabel(null, 'invoice', 'Invoice')}</Link></li> : <li className='disable'>{Helper.getLabel(null, 'invoice', 'Invoice')}</li>}
        <li onClick={e => { showTaxTransaction() }}>{Helper.getLabel(props.language, 'view_tax', "View tax")}</li>
        {canPoActionButtonActive('view_change_logs') ? <li ><Link to={(props.logsUrl ? props.logsUrl : '/po-change-logs') + "/" + id}>{Helper.getLabel(null, 'view_change_logs', 'View Change Logs')}</Link></li> : <li className='disable'>{Helper.getLabel(null, 'view_change_logs', 'View Change Logs')}</li>}
        {props.type==='purchase agreement'?canPoActionButtonActive('view_release_order')?<li><Link to={(props.viewReleaseOrderUrl ? props.viewReleaseOrderUrl : `/release-order/all?agreement_id=${id}`)} state={{}}>{Helper.getLabel(null, 'release_order', 'Release Order')}</Link></li> : <li className='disable'>{Helper.getLabel(null, 'view_release_order', 'View release order')}</li>:null}
        {props.type!=='contract'?canPoActionButtonActive('goods_receiving_note') ? <li><Link to={(props.goodReceivingUrl ? props.goodReceivingUrl : '/good-receiving/notes') + "/" + id}>{Helper.getLabel(null, 'goods_receiving_note', 'Goods Receiving Note')}</Link></li> : <li className='disable'>{Helper.getLabel(null, 'goods_receiving_note', 'Goods Receiving Note')}</li>:null}
        {props.type!=='contract' ? canPoActionButtonActive('return_order') ? <li><Link to={(props.returnOrderUrl ? props.returnOrderUrl : '/return-order/all') + "/" + id}>{Helper.getLabel(null, 'return_order', 'Return Order')}</Link></li> : <li className='disable'>{Helper.getLabel(null, 'return_order', 'Return Order')}</li>:null}
        {props.type==='contract'?canPoActionButtonActive('amendment_request')?<li><Link to={(props.amendmentRequestUrl ? props.amendmentRequestUrl : '/amendment-request/all') + "/" + id} state={{purchase_status:purchaseStatusLabel,document_status:documentStatusLabel,document_name:props?.title?.replace(/Edit\s/, '')}}>{Helper.getLabel(null, 'amendment_request', 'Amendment request')}</Link></li> : <li className='disable'>{Helper.getLabel(null, 'amendment_request', 'Amendment request')}</li>:null}
        {props.type==='contract'?canPoActionButtonActive('goods_receiving_note') ? <li ><Link to={"/work-confirmation-list?&purchaseorder_id=" + id} state={{purchase_status:purchaseStatusLabel,document_status:documentStatusLabel,document_name:props?.title?.replace(/Edit\s/, '')}}>{Helper.getLabel(null, 'work_confirmation', 'Work Confirmation')}</Link></li> : <li className='disable'>{Helper.getLabel(null, 'work_confirmation', 'Work Confirmation')}</li>:null}
        {canPoActionButtonActive('cancel_document') ? <li onClick={e => { showCancelDocumentPopup() }}>{Helper.getLabel(null, 'cancel_document', 'Cancel Document')}</li> : <li className='disable'>{Helper.getLabel(null, 'cancel_document', 'Cancel Document')}</li>}
        {canPoActionButtonActive('delete') ? <li onClick={e => { poDeleteHandler() }} className='nav_divider'>{Helper.getLabel(props.language, 'delete', "Delete")}</li> : <li className='nav_divider disable'>{Helper.getLabel(props.language, 'delete', "Delete")}</li>}
      </ul>
    )
  }
  const SaveHandler = (isShowResponseMessage = true) => {
    if (!isPoEditable) {
      return;
    }

    let validData = {
        ...editPO
    }

      if (!validator.isValid(validData)) {
        validator.displayMessage();
        validator.reset()
        return;
      }

      Helper.createDebouncedAPIFunction([
        async () => await UpdatePurchaseOrderHeader(isShowResponseMessage)
      ], setSaveBtnApi, cancelTokenSource)();
  }
  
  const getPurchaseOrdersAndTypeArr = async () => {
    try {
      const [purchase_orders, typeArr] = await Promise.all([
        getPurchaseOrders((arg) => getPurchaseOrdersLines(arg)),
        TypeAPI(),
      ]);
      return { purchase_orders, typeArr };
    } catch (error) { 
      throw {};
    }
  };
 
  const getWorkFlowCalls = async () => {
    try {
      setLoading(true);
       
      const { purchase_orders, typeArr } = await getPurchaseOrdersAndTypeArr();
       
      setEditPO({ ...purchase_orders });

      if (purchase_orders && typeArr) { 
        const trigger = await workflowCalls.getWorkFlowTriggers();
        const mode = !checkIfArrayIsEmpty(trigger) && trigger.find(id => id.name === "Update")?.id;
        
        const sourceIdentification = SourceIdentificationFromDocumentType(typeArr, purchase_orders?.type, props?.type);
        
        const tenantId = props.auth.user.tenant_id;

        const eligibleWorkflow = await workflowCalls.checkWorkFlowForSpecificModule(sourceIdentification, tenantId, mode);
        const workflowExist = await workflowCalls.getWorkFlowInstanceExist(tenantId, sourceIdentification, id);
     
        setWorkflowExist(workflowExist);
        setWorkflowEligible(eligibleWorkflow);

        const workflowActionHistory = await workflowCalls.getWorkFlowActionHistory(sourceIdentification, id);

        if (workflowActionHistory) {
          setWorkflowActionHistory(workflowActionHistory);
        }
      }

      setLoading(false);

    } catch (error) { 
      setLoading(false);
    } 
  };
 
  const SubmitWorkflow = (comment) => {
    updatePurchaseOrder(comment)
  }
  
  const updatePurchaseOrder = (comment) => {
    let updateData = {
      workflow_initiated: true,
      comment: comment == '' || comment == 'execute_api' ? null : comment,
    }
    if (
      !id ||
      editPO.status === 1 ||
      workflowExist?.instance_exist === true
    ) {
      return
    }
    const payload = updateData

    let api = Api
    api
      .axios()
      .put(Settings.apiPurchaseOrderUrl + '/PO_header/' + id, payload)
      .then(function (res) {
        if (res.data.status === 'success') {
          Helper.alert(res.data.message)
          getWorkFlowCalls()
        } else {
          Helper.alert(res.data.message, 'error')
        }
      })
      .catch((error) => {
        Helper.alert(error.response.data.message, 'error')
      })
  }

  let security = props.security;
  let frontendIds = Gui_id_list;
  if (!security.canView(Gui_id_list.procure.purchase_orders.purchase_orders_edit_screen)) {
    return  <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
  }
  const onCreateFirstDistribution = (newDisSouceId) => {
    let api = Api;
    api.setUserToken();
    api.axios().put(getApiUrl(), { distributionsource_id: newDisSouceId }).then(res => {

    })
    setEditPO({
      ...editPO,
      distributionsource_id: newDisSouceId
    })

  }
  if (isNoPOFound) {
    return (<div className='mt-4'><Alert type="danger" message={Helper.getLabel(null, 'record_not_found', "Record not found")} /></div>)
  }

  const onDeleteInvoice = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .delete(
        Settings.apiPurchaseOrderUrl +
          `/invoice_header/${editPO?.invoice_id}`,
      )
      .then(function (res) {
        if (res.data.status === 'success') {
          Helper.alert(res.data?.message, 'success')
          const timer = setTimeout(() => {
            props.type==="credit notes"?navigate("/credit-notes"):navigate("/invoice-list?on_account=true")
          }, 500)
          return () => clearTimeout(timer)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
      })
  }

  const handleSubmitInvoice = () => {
    let api = Api
    api.setUserToken()
    if (!editPO?.invoice_id) return
    api
      .axios()
      .get(
        Settings.apiPurchaseOrderUrl +
          `/submit_invoice/${editPO?.invoice_id}`,
      )
      .then(function (res) {
        if (res.data.status === 'success') {
          Helper.alert(res.data.message,'success')
          const timer = setTimeout(() => {
             navigate(`/invoice-list?on_account=true`) 
          }, 500)
          return () => clearTimeout(timer)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  } 

  const checkFundHandlerApiCall = async () => {
    let api = Api;
    api.setUserToken();
  
    try {
      const res = await api.axios().get( Settings.apiPurchaseOrderUrl + `/budget_check/${props?.type==="contract"?Settings?.source_id?.contract:Settings?.source_id?.purchase_order}/${editPO?.purchase_order_id}` , {cancelToken:cancelTokenSource.token} );
  
      if (res.data.status === "success") {
        Helper.alert(res.data.message, "success");
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  };
  
  const checkFundHandler = () => {
    Helper.createDebouncedAPIFunction([
      async () => await checkFundHandlerApiCall()
    ], setFundBtnApi, cancelTokenSource, false, 500000)();
    // let api = Api;
    // api.setUserToken();
    // api
    //   .axios()
    //   .get(Settings.apiPurchaseOrderUrl + `/budget_check/${props?.type==="contract"?Settings?.source_id?.contract:Settings?.source_id?.purchase_order}/${editPO?.purchase_order_id}`)
    //   .then(function (res) {
    //     if (res.data.status === "success") {
    //       Helper.alert(res.data.message,'success')
    //     }
    //   })
    //   .catch((error) => {
    //     getPopupMessageBasedOnStatus(error)
    // });
  }

  const getIntegatorId = () => {
    return id
  }

  const getTransactionalDocumentType = () => {
    if(props?.source_id == Settings.source_id.contract) return [Settings.document_types.contract];
    if(props?.source_id == Settings.source_id.release_order) return [Settings.document_types.release_order];
    if(props?.source_id == Settings.source_id.purchase_agreement) return [Settings.document_types.purchase_agreement];
    return [Settings.document_types.purchase_order];
  }

  const handleFetchData = (response) => { 
    return {
        rowData: response?.data?.data?.map((item) => ({
            ...item,
            gridId: `${Date.now()}_${getNewValue()}`,
            max_is_enforced:item.max_is_enforced===null || item.max_is_enforced === undefined ? false : item.max_is_enforced,
            enforce_same_category_item_release:item.enforce_same_category_item_release===null || item.enforce_same_category_item_release=== undefined ? false : item.enforce_same_category_item_release
        })),
        rowCount: response?.data?.data?.length
    }
 } 
  return ( 
    <>
      <div className={props.type==="invoice on account"?'container-fluid vn_edit_screen invoice-on-account-screen scrollBarBlinkControl document-main-screen':'container-fluid vn_edit_screen scrollBarBlinkControl document-main-screen'}>
        <div ref={myDivRef}>

          <NavigationHeder backUrl={props.backUrl ? props.backUrl : null} moreOptions={navigationMoreOptions} title={`<span style="color:#000;">${props.title ? props.title : Helper.getLabel(props.language, 'edit_po', "Edit Purchase Order")} #</span> ${editPO?.document_number ? editPO?.document_number : ''}`}>
            {/* <Button title={"Print"} icon="/images/icons/print.svg" /> */}
            {(props.type==="invoice on account" || props.type==="credit notes") && editPO?.document_status===0 && <Button title="Delete" icon="/images/icons/delete.svg" onClick={onDeleteInvoice} />}
            {((editPO && editPO?.type===0) || (props.type==="contract")) &&  <Button className="orange-btn-style ms-1"  title={<div><span> {Helper.getLabel(props.language, 'check_fund', 'Check fund')} </span> {fundBtnApi ? <output className="spinner-border spinner-border-sm ms-1" aria-hidden="true"></output> : ''}</div>} onClick={checkFundHandler} isDisable={editPO?.document_status!==0 || fundBtnApi} />}
            <TotalWidget source_id={props.source_id?props.source_id:purchase_order_source_id} integrator={getIntegatorId()} />
            <Button id="btn_master_save" className="rfq_save_btn" isDisable={!security.canCreate(frontendIds.procure.purchase_orders.purchase_orders_edit_screen_save_button) || !isPoEditable} isActive={false} title={Helper.getLabel(props.language, 'save', "Save")} onClick={SaveHandler} />
          </NavigationHeder>

          {/* work =flow for invoice will be handle in future */}
          {(props.type==="invoice on account"||props.type==="credit notes"||editPO?.document_status === 1||SourceIdentificationFromDocumentType(type,editPO?.type,props?.type) === null)?null: !loading && <div style={{ display: scrollDitection == 'down' ? "block" : "none" }}>
            <WorkFlowCommonHeader
              submitWorkflowAction={!workflowExist.instance_exist && workflowEligible && SubmitWorkflow}
              instance_task_id={instance_id}
              module_id={!instance_id ? SourceIdentificationFromDocumentType(type,editPO?.type,props?.type) : null}
              source_id={SourceIdentificationFromDocumentType(type,editPO?.type,props?.type)}
              source_item_id={id}
              SubmitWorkflowObjDetails={workflowExist?.workflow_name}
              module_item_id={!instance_id ? id : null}
              workflowInstanceExist={workflowExist?.instance_exist}
              workflowInstanceDetails={workflowExist?.instance_details}
              workflowEligible={workflowEligible}
              workflowName={`${getDocumentTypeLabel() ? getDocumentTypeLabel() : 'Purchase Order'} Workflow`}
              reloadFunction={getWorkFlowCalls}
            />
        </div>}

          {isShowTransactionPopup ? <TaxTransactionPopup onTaxTransactionPopupClose={afterCloseTaxPopup} line_id={null} source_id={purchase_order_source_id} integrator={editPO.purchase_order_id} /> : ''}
          {/* {isVisibleCancelDocumentPopup ? <CancelDocumentsPopup lineGridTitle={props.lineTitle?props.lineTitle:Helper.getLabel(null, 'purchase_order_lines', "Purchase Order Lines")} onPopupClose={afterCloseCancelDoumentPopup} po={editPO} source_id={props.source_id ? props?.source_id : Settings?.source_id?.purchase_order} onSubmitCancelDocumentStatus={(e)=>updateOrderStatus(Settings?.po_purchase_status?.canceled.id)}/> : ''}  */}

          <FixedNavigationHeder 
          addComponentTop={addComponentTop} 
          sectionName={sectionName}  
          navs={navs} 
          scrollDitection={scrollDitection} 
          selectedSupNav={selectedSupNav} 
          setSelectedSupNav={setSelectedSupNav} 
          superNav={superNav} 
          />

        </div>

        {loading ? <OverlayLoader isLogoCenter={true} /> : (
            <TabContentWraper
            height={height}
            removeMargin={true}
            setScrollDitection={setScrollDitection}
            leftSideContent={
              <>
              {selectedSupNav == 'Header View' && (
                <>
                  <div className="lists_contant one" id={`${sectionName}General`} style={{ position: "relative" }}>
                    <HeaderViewGeneral isDocumentNumberDisable={objectIsEmpty(numberSequence) || numberSequence?.ns_type==="continuous" || (numberSequence?.ns_type==="manual" && editPO?.version===1)} purchase_status_label={purchaseStatusLabel?.name} document_status_label={documentStatusLabel} isEditable={isPoEditable} updateOrderStatusWidget={setOrderStatusProps} sites={sites} entities={entities} state={editPO} setState={setEditPO} requesterAPI={requester} orderedAPI={ordered} taxCode={taxCode} isDisable={isDisable} vendorsDropdown={vendorsDropdown} taxGroup={taxGroup} type={props.type} />
                  </div>
                  <div className="lists_contant two" id={`${sectionName}Delivery`} style={{ position: "relative" }}>
                    <HeaderViewDelivery isEditable={isPoEditable} state={editPO} setState={setEditPO} />
                  </div>
                  <div className="lists_contant three" id={`${sectionName}Price & Discount`} style={{ position: "relative" }}>
                    <HeaderViewPriceDiscount isEditable={isPoEditable} sites={sites} entities={entities} state={editPO} setState={setEditPO} />
                  </div>
                  <div className="lists_contant four" id={`${sectionName}Segment`} style={{ position: "relative" }}>
                    {HeaderViewSegment()}
                  </div>
                  <div className="lists_contant five" id={`${sectionName}Address`} style={{ position: "relative" }}>
                    <AddressList disableRecords={!isPoEditable} onAddressCreate={onAddressCreateHandler} addressSourc_id={editPO.addresssource_id} address_source_integrator={getIntegatorId()} source_id={props.source_id?props.source_id:Settings.source_id.purchase_order} />
                  </div>
                  <div className="lists_contant six" id={`${sectionName}Contact`} style={{ position: "relative" }}>
                    <Collapse title={Helper.getLabel(null, 'contact', "Contact")} open={true} className="mt-5 mb-5">
                       <ContactAddress onGridReady={(ctGrid) => { contactGridObj = ctGrid }} canEdit={isPoEditable} onContactCreate={onContactCreate} contactsource_id={editPO.contactsource_id} source_id={props.source_id?props.source_id:Settings.source_id.purchase_order} address_id={null} contactsource_integrator={getIntegatorId()} />
                    </Collapse>
                  </div>
                  <div className="lists_contant seven" id={`${sectionName}Terms & Conditions`} style={{ position: "relative" }}> 
                    <TermConditionsTemplates transactional_document_type={ getTransactionalDocumentType() } disableComponent={!isPoEditable} TransactionalLevel={true} sourceintegration_id={id}  module_source_id={props.source_id?props.source_id:Settings.source_id.purchase_order} /> 
                  </div>
                  {/* <div className="lists_contant seven" id={`${sectionName}Team`} style={{ position: "relative" }}>
                    <Team isEditable={isPoEditable} source_id={props.source_id?props.source_id:purchase_order_source_id} integrator={id} />
                  </div> */}
                </>
              )}
               { selectedSupNav === "Line View" && (
                <div className='mb-4' style={{ position: "relative" }}>
                <LineViewComponent
                  defaultValues={{
                    requester_id: editPO.requester_id,
                    warehouse_id: editPO.warehouse_id,
                    delivery_date: editPO.delivery_date,
                    taxgroupitem_id: editPO.taxgroupitem_id,
                    taxgroup_id: editPO.taxgroup_id,
                    entity_id: editPO.entity_id,
                    site_id: editPO.site_id,
                    orderer_id: editPO.orderer_id
                  }}
                  source_id={props.source_id?props.source_id:Settings.source_id.purchase_order}
                  line_source={props.source_id?props.source_id:Settings.source_id.purchase_order}
                  isEditable={isPoEditable}
                  fetchData={props?.formType==="purchase agreement" && handleFetchData}
                  lineGridTitle={props.lineTitle?props.lineTitle:Helper.getLabel(null, 'purchase_order_lines', "Purchase Order Lines")}
                  header_id={id}
                  purchase_status={editPO.purchase_status}
                  document_status={editPO.document_status}
                  delivery_date={editPO.delivery_date}
                  master_save_button_id="btn_master_save"
                  segmentAccountingDate={editPO?.accounting_date ?? new Date()}
                  onbehalf_request={false}
                  formType={props.formType}
                  vendor_id={editPO.vendor_id}
                  vendorsite_id={editPO.vendorsite_id}
                  agreementId={props?.formType==="release order" ? editPO?.agreement_id : null}
                  setOrderStatusProps={setOrderStatusProps}
                  includeBudgetColumns={editPO?.type===0 || editPO?.type===1}
                  hideBillingRule={props.type!=="contract"}
                  hideDistribution={props.type==="purchase agreement"}
                  viewType={props.type==="invoice on account" && "invoice on account"}
                />
                </div>

               ) 
                }
              </>
            }
            rightSideContent={addComponentRight}
            />
        )}
      </div> 
    {saveBtnApi ? <OverlayLoader isLogoCenter={true} /> : null}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    isMasterContentLoader: state.isMasterContentLoader.isLoading,
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
    purchaseStatus: state.purchaseStatus
  }
}
const SecurityOptions = {
  gui_id: Gui_id_list.procure.purchase_orders.purchase_orders_edit_screen
}
const setDispatchToProps = (dispatch) => {
  return {
    setMasterContentLoader: (value) => { dispatch({ type: ActionTypes.SET_IS_CONTENT_MASTER_LOADER, payload: value }) },
    LoadPurchaseStatus: (oldData) => { LoadPurchaseStatus(dispatch, oldData) }
  }
}
export default connect(mapStateToProps, setDispatchToProps)(MasterComponentWraper(ApplySecurityRoles(PoTeamAccess(RsDataLoader(EditPO, { poDocumentStatus: true })), SecurityOptions)))

