import React, { Component } from 'react';
import AgGrid from '../../grid/ag/ag-grid';
import AgGridMemo from '../../grid/ag/AgGridMemo';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import SimpleLoading from '../../Loading/SimpleLoading';
import Helper from '../../../inc/Helper';
import Tabs from '../../Widgets/Tabs/Tabs';
import HeaderViewDelivery from '../Edit PO/HeaderViewDelivery';
import HeaderViewPriceDiscount from '../Edit PO/HeaderViewPriceDiscount';
import RsDataLoader from '../../inc/RsDataLoader';
import Collapse from '../../inc/Collapse';
import SegmentsDistribution from '../../Segments/SegmentsDistribution';
import HeaderViewGeneral from '../Edit PO/HeaderViewGeneral';

class HeaderViewVersion extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading:false,
            poData:this.props.poData
        }
    }
    componentDidMount(){
        
    }
    updateState(){

    }
    
    render() {
        let language = null;
        let navs = [
            {title:Helper.getLabel(language,'general','General'), isActive: true, },
            {title:Helper.getLabel(language,'delivery','Delivery')},
            {title:Helper.getLabel(language,'price_discount','Price & Discount')},
            {title:Helper.getLabel(language,'segment','Segment')}
        ]
        if(!this.props.poData){
            return <></>
        }
        let poData = this.props.poData;
        let sitesOptions = this.props.dataLoaderProps.sites.sites.map( site => {
            return {
                value: site.site_id,
                label: `${site.code} - ${site.name}`
              }
        })
        let entityOptions = this.props.dataLoaderProps.entity.entity.map( entity => {
            return {
                value: entity.entity_id,
                label: `${entity.code} - ${entity.name}`
              }
        })
        let employeeOptions = this.props.dataLoaderProps.employee.all.map( employee => {
            return {
                value: employee.employee_id,
                label: `${employee.code} - ${employee.first_name} ${employee.last_name}`
              }
        })
        let vendorsOptions = this.props.dataLoaderProps.vendors.map( vendor => {
            return {
                value: vendor.vendor_id,
                label: `${vendor.code} - ${vendor.name}`
              }
        })
        let taxgroupOptions = this.props.dataLoaderProps.taxgroup.taxgroup.map( taxgroup => {
            return {
                value: taxgroup.taxgroup_id,
                label: taxgroup.name
              }
        })
        let taxcodesOptions = this.props.dataLoaderProps.taxcodes.taxcodes.map( taxcodes => {
            return {
                value: taxcodes.taxcodes_id,
                label: `${taxcodes.code} - ${taxcodes.name}`
              }
        })
        console.log('poData',poData)
        console.log('props',this.props)
        return (
            <div className='rs_history_header_view'>
                { this.state.isLoading ? <SimpleLoading /> : ''}
                <Tabs id="po_version_h_tab"  navs={navs} showAll ={true} disableSticky={true}>
                    <HeaderViewGeneral updateState ={this.updateState.bind(this)} purchase_status_label={''} isEditable={false}  sites={sitesOptions} entities={entityOptions} state={poData}   requesterAPI={employeeOptions} orderedAPI={employeeOptions} taxCode={taxcodesOptions}   vendorsDropdown={vendorsOptions} taxGroup={taxgroupOptions} /> 
                    <HeaderViewDelivery  isEditable={false} state={poData} updateState ={this.updateState.bind(this)}  />
                    <HeaderViewPriceDiscount  isEditable={false} sites={sitesOptions} entities={entityOptions} state={poData} updateState ={this.updateState.bind(this)}  />
                    <Collapse className='mb-4' open={true} title={Helper.getLabel(null,'segment','Segment')}>
                        <SegmentsDistribution  isEditable={false} id="purchase_order_header_segments" disableFinancial={true} distribution_source_id={poData.distributionsource_id} integrator={poData.purchase_order_id} source_id ={Settings.source_id.purchase_order} totalAmount = {100} />
                    </Collapse>
                </Tabs>
            </div>
        );
    }
}

export default  RsDataLoader(HeaderViewVersion,{sites:true,entity:true,employee:true,vendors:true,taxgroup:true,taxcodes:true});