import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Carousel, CarouselItem } from "react-bootstrap";

import Helper from "../../../inc/Helper";
import Api from "../../../inc/Api";
import Settings from "../../../inc/Settings";
import OverlayLoader from "./OverlayLoader";
import RsWithRouter from "../../inc/RsWithRouter";

import Rating from "../../../assets/rating.svg";
import Wishlist from "../../../assets/wishlist-icon.svg";
import WishlistActive from "../../../assets/wishlist-icon-active.svg";
import ImageNotFound from "../../../assets/image-not-found.jpg";
import { getPopupMessageBasedOnStatus } from "../../../inc/Validation";

class ShopItemCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      wishlisted: false,
      checked: false,
      quantity: 1,
    };

    this.addToCart = this.addToCart.bind(this);
    this.addToWishlist = this.addToWishlist.bind(this);
    this.toggleChecked = this.toggleChecked.bind(this);
    this.getSearchParam = this.getSearchParam.bind(this)
    this.handleWishlistClick = this.handleWishlistClick.bind(this);
  }

  componentDidMount() {
    if (this.props.wishlistIds?.includes(this.props.item.item_id))
      this.setState({ wishlisted: true });
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.selectedItems) !==
      JSON.stringify(prevProps.selectedItems)
    ) {
      if (this.props.selectedItems.includes(this.props.item.item_id)) {
        this.setState({ checked: true });
      } else {
        this.setState({ checked: false });
      }
    }
  }

  getSearchParam = (name) => {
    const params = new URLSearchParams(this.props.rs_router.location?.search)
    return params.get(name)
  }

  async addToCart(item) {
    const prHeaderId = this.getSearchParam('pr_view_id')
    let itemData = {
      line_type: this.getIdByName("Item"),
      item_id: item.item_id,
      unit_price: parseFloat(item.purchase_price) ?? null,
      ordered_qty: this.state.quantity,
      line_amount: parseFloat(item.purchase_price) * this.state.quantity,
      unit: item.purchaseUOM,
      itemgroup_id:item.group_id,
      source_id:Settings.source_id.purchase_requisition,
      header_id:parseInt(prHeaderId),
    };
    let api = Api;
    api.setUserToken();
    this.setState({ isLoading: true });
    try {
      const cartItemsRes = await api
        .axios()
        .get(Settings.apiPurchaseOrderUrl + `/lines/${prHeaderId}/${Settings.source_id.purchase_requisition}`);
      const cartItems = cartItemsRes.data?.data;
      let existingItem = null;
      if (cartItemsRes?.status===200) {
        for (let i = 0; i < cartItems.length; i++) {
          const cartItem = cartItems[i];
          if (cartItem.item_id === item.item_id) {
            existingItem = cartItem;
            break;
          }
        }

        if (existingItem) {
          itemData.ordered_qty += parseFloat(existingItem.ordered_qty);
          itemData.line_amount += parseFloat(existingItem.line_amount);
        }

        const res = existingItem
          ? await api
              .axios()
              .put(
                Settings.apiPurchaseOrderUrl +
                  "/line/" +
                  existingItem.line_id + "/" + Settings.source_id.purchase_requisition,
                itemData
              )
          : await api
              .axios()
              .post(
                Settings.apiPurchaseOrderUrl + "/lines",
                itemData
              );

        this.setState({ isLoading: false });
        if (res.data?.status === "success") {
          if (!existingItem) this.props.incrementCartCount();
          this.setState({ quantity: 1 });
          if (this.props.showCheckbox) {
            this.props.handleDeleteFromWishlist(item.item_id);
            this.props.decrementWishlistCount();
          }
          Helper.alert(res.data?.message, "success");
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false });
      getPopupMessageBasedOnStatus(error)
    }
  }

  addToWishlist(item) {
    let itemData = {
      item_id: item.item_id,
    };

    let api = Api;
    api.setUserToken();
    let that = this;
    api
      .axios()
      .post(Settings.apiPurchaseRequisitionUrl + "/wishlist", itemData)
      .then(function (res) {
        if (res.data?.status === "success") {
          that.props.incrementWishlistCount();
          Helper.alert(res.data?.message, "success");
        }
      })
      .catch(function (error) {
        that.setState({ wishlisted: !this.state.wishlisted });
        getPopupMessageBasedOnStatus(error);
      });
  }

  deleteFromWishlist = (item) => {
    let api = Api;
    api.setUserToken();
    let that = this;
    api
      .axios()
      .delete(Settings.apiPurchaseRequisitionUrl + "/wishlist/" + item.item_id)
      .then(function (res) {
        if (res.data?.status === "success") {
          Helper.alert(res.data?.message, "success");
          that.props.decrementWishlistCount();
          that.setState({ wishlisted: !this.state.wishlisted });
        }
      })
      .catch(function (error) {
        getPopupMessageBasedOnStatus(error);
      });
  };

  getIdByName(name) {
    for (let i = 0; i < this.props.lineTypes?.length; i++) {
      if (this.props.lineTypes[i]?.name === name) {
        return this.props.lineTypes[i]?.id;
      }
    }
    return 0;
  }

  handleWishlistClick = (item) => {
    this.setState({ wishlisted: !this.state.wishlisted });
    if (this.state.wishlisted) {
      this.deleteFromWishlist(item);
    } else this.addToWishlist(item);
  };

  toggleChecked(id) {
    this.setState({ checked: !this.state.checked });
    this.props.handleSelect(id);
  }

  routeToDetailPage = (id) => {
    this.props.rs_router.navigate("/purchase-requisition/shop/" + id + `?pr_view_id=${this.getSearchParam('pr_view_id')}`);
  };

  handleQuantityChange = (event) => {
    if (event.target.value < 0) return;
    this.setState({ quantity: event.target.value });
  };

  handleQuantityIncrement = () => {
    this.setState({ quantity: parseFloat(this.state.quantity) + 1 });
  };

  handleQuantityDecrement = () => {
    if (this.state.quantity > 1)
      this.setState({ quantity: parseFloat(this.state.quantity) - 1 });
  };

  render() {
    const { showCheckbox, item, showWishlistIcon } = this.props;

    return (
      <div className="prshop-itemcard" key={item?.item_id}>
        <div
          className="prshop-itemcard-carousel"
          onClick={(event) => event.stopPropagation()}
        >
          {item?.images?.length > 0 ? (
            <Carousel controls={false}>
              {item.images.map((image) => {
                return (
                  <CarouselItem
                    className="prshop-itemcard-carousel-slide"
                    key={image?.image_id}
                  >
                    <img
                      src={image?.image ?? ImageNotFound}
                      alt={image?.image_name ?? "carousel slide"}
                    />
                  </CarouselItem>
                );
              })}
            </Carousel>
          ) : (
            <div className="prshop-itemcard-carousel-default-image">
              <img src={ImageNotFound} alt={"carousel slide"} />
            </div>
          )}
        </div>
        <div className="prshop-itemcard-content">
          <div
            className="prshop-itemcard-content-titlerating"
            onClick={() => this.routeToDetailPage(item.item_id)}
          >
            <div className="prshop-itemcard-content-titlerating-title">
              {item?.item_name}
            </div>
            <div className="prshop-itemcard-content-titlerating-rating">
              <img src={Rating} alt="rating" />
              {this.state.rating ?? "-"}
            </div>
          </div>
          <div className="prshop-itemcard-content-price">
            AED {item.purchase_price ? Helper.addThousandSeparator(parseFloat(item.purchase_price)) : "-"}
          </div>
          <div className="prshop-itemcard-content-quantity">
            <div>
              {Helper.getLabel(this.props.language, "quantity", "Quantity")}
            </div>
            <div>
              <span
                className="prshop-itemcard-content-quantity-button"
                onClick={(event) => {
                  event.stopPropagation();
                  this.handleQuantityDecrement();
                }}
              >
                -
              </span>{" "}
              <input
                type="number"
                value={this.state.quantity}
                onChange={(e) => this.handleQuantityChange(e)}
              />{" "}
              <span
                className="prshop-itemcard-content-quantity-button"
                onClick={(event) => {
                  event.stopPropagation();
                  this.handleQuantityIncrement();
                }}
              >
                +
              </span>
            </div>
          </div>
          <div className="prshop-itemcard-content-buttons">
            <Button
              className="prshop-itemcard-content-buttons-addtocart"
              variant="outline-primary"
              onClick={(event) => {
                event.stopPropagation();
                this.addToCart(item);
              }}
              disabled={this.state.quantity === 0}
            >
              {Helper.getLabel(
                this.props.language,
                "add_to_cart",
                "Add to Cart"
              )}
            </Button>
          </div>
        </div>
        {showWishlistIcon ? (
          <div
            className="prshop-itemcard-wishlist"
            onClick={(event) => {
              event.stopPropagation();
              this.handleWishlistClick(item);
            }}
          >
            <img
              src={this.state.wishlisted ? WishlistActive : Wishlist}
              alt="wishlist"
            />
          </div>
        ) : null}
        {showCheckbox ? (
          <div className="prshop-itemcard-checkbox">
            <input
              className="pr-wishlist-checkbox"
              type="checkbox"
              onClick={(event) => event.stopPropagation()}
              onChange={() => this.toggleChecked(item.item_id)}
              checked={this.state.checked}
            />
          </div>
        ) : null}
        {this.state.isLoading ? <OverlayLoader height={30} isHeightPercentage={true} /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    wishlistIds: state.prShop.wishlistIds,
  };
};

export default connect(mapStateToProps)(RsWithRouter(ShopItemCard));
