const navigationListForPendingActions = [
    {
        source_id: 6,
        link: '/edit-vendor/:id/:instance_id'
    },
    {
        source_id: 18,
        link: '/edit-employee/:id/:instance_id'
    },
    {
        source_id: 12,
        link: '/purchase-order/edit/:id/:instance_id'
    },
    {
        source_id: 14,
        link: '/contract-order/edit/:id/:instance_id'
    },
    {
        source_id: 9,
        link: '/purchase-requisition/summary?pr_view_id=:id&return=pr&instance_id=:instance_id'
    },
    {
        source_id: 10,
        link:'/request-for-quotation/rfqdetailform?rfq_view_id=:id&return=rfqheaderlist&instance_id=:instance_id'
    },
    {
        source_id: 11,
        link:'/request-for-quotation/rfqdetailform?rfq_view_id=:id&return=rfqheaderlist&instance_id=:instance_id'
    }, 
    {
        source_id: 16,
        link:'/invoice-details?invoice_id=:id&instance_id=:instance_id'
    },
    {
        source_id: 77,
        link:'/request-for-quotation/rfqdetailform?rfq_view_id=:id&return=dashboard&instance_id=:instance_id'
    },
    {
        source_id: 78,
        link:'/request-for-quotation/rfqdetailform?rfq_view_id=:id&return=dashboard&instance_id=:instance_id'
    },
    {
        source_id: 79,
        link: '/request-for-quotation/rfqdetailform?rfq_view_id=:id&return=dashboard&instance_id=:instance_id'
    },
    {
        source_id: 54,
        link:'/work-confirmation-details?shipment_id=:id&instance_id=:instance_id'
    }
];
export default navigationListForPendingActions;