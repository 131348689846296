import DatepickerFunctions from "../../../../../inc/DatepickerHelper";
import Helper from "../../../../../inc/Helper";
import Settings from "../../../../../inc/Settings";
import AdvancedDropdown from "../../../../grid/ag/cellEditor/AdvancedDropdown";
import SimpleDropdown from "../../../../grid/ag/cellEditor/SimpleDropdown";
import { getActionStatusName, getLabelFromDropdown, getOptions, getStatusNameById, gridLinkValueFormat, handleAction, optionsKeyCreator } from "../../../../grid/ag/commonFunctions/GridCommonFunctions";
import CustomButtonCellRenderer from "../../CustomButtonCellRenderer";

const getVendorColumns = ({vendorList,vendorStatues,language,handleEditable,handleCodeDropDownChange,vendorSites, select2Settings}) => {
  let output = []
    return [
        {
          field: "vendor_code",
          headerName: Helper.getLabel(language, "code", "Code"),
          editable: (params) => handleEditable(params),
          minWidth: 180,
          cellEditor: SimpleDropdown,
          isAllowZero:true,
          onChange:handleCodeDropDownChange,
          select2Settings:select2Settings,
          cellEditorParams: {
              values: vendorList.map((item) => ({
                value: item.vendor_id,
                label: `${item.code} | ${item.name}`,
              })),
          },
          cellDataType:false,
          valueFormatter: (params)=>{return getLabelFromDropdown(params)},
          inputId:'vendor_code'
        },
        {
          field: "vendor_name",
          headerName: Helper.getLabel(language, "name", "Name"),
          minWidth: 150,
          editable: false,
        },
        {
          field: "vendorsite_id",
          headerName: Helper.getLabel(language, "site", "Site"),
          editable: (params) => handleEditable(params),
          minWidth: 150,
          inputId:`vendor-site-name-unique`,
          // cellEditor: SimpleDropdown,
          // cellEditorParams: {
          //   values: vendorSites
          // },
          cellDataType:false,
          cellEditor: AdvancedDropdown,
          cellEditorPopup: false,
          cellEditorParams: {
            lynSettings: {
              dependentSelector: ["vendor_code"],
              apiUrl: Settings.apiVendorUrl + "/get_active_vendor_sites",
              apiRequestMethod: "get",
              options: (apiResponse) => {
                 output = apiResponse.data.data.map((item) => {
                  return {
                    label: item.name,
                    value: item.vendorsite_id,
                    isdefault: item.is_default
                  };
                });
                return output;
              },
            },
            values:vendorSites,
          },
          valueFormatter: (params)=>{return params?.data?.vendorsite_name || "-"},
          defaultSite:true,
        },
        {
          field: "expiry_datetime",
          headerName: Helper.getLabel(
            language,
            "expiration_date_and_time",
            "Expiry date and time"
          ),
          editable: false,
          minWidth: 225,
          valueFormatter:(params)=>{
           return params.value===null || params.value === undefined?null:DatepickerFunctions.convertDateTimeFromDataBase(params?.value)}
        },
        {
          field: "vendor_replystatus",
          headerName: Helper.getLabel(language, "reply_status", "Reply status"),
          cellRenderer: (params) => getStatusNameById(params.value,vendorStatues),
          editable: false,
          minWidth: 180,
          filter:'agSetColumnFilter',
          filterParams: {
            values: (params) => getOptions(params, vendorStatues),
            keyCreator: (params) => optionsKeyCreator(params, 'id'),
            valueFormatter: params => params.value.name?.replace(/_/g," ")
          }
        },
        {
          field: "contact_number",
          headerName: Helper.getLabel(
            language,
            "primary_contact_person",
            "Primary contact person"
          ),
          minWidth: 225,
          editable: false,
        },
        {
          field: "email",
          headerName: Helper.getLabel(language, "email", "Email"),
          minWidth: 200,
          editable: false,
        },
        {
          field: "rank",
          headerName: Helper.getLabel(language, "rank", "Rank"),
          minWidth: 120,
          editable: false,
        },
        {
          field: "reply_id",
          headerName: Helper.getLabel(language, "reply_id", "Reply id"),
          cellRenderer: (params) => gridLinkValueFormat(params),
          minWidth: 220,
          editable: false,
        },
      ];   
}


const getCollaborationTeamColumn = ({language,teamsList,dynamicTeamsList,employeesList,handleEditable,accessLevel}) => {
  return [
    {
      field: "employee_id",
      headerName: Helper.getLabel(language, "name", "Name"),
      editable: false,
      cellEditor:SimpleDropdown,
      cellEditorParams:{
        values: employeesList?.map((item) => {
          return {
            value: item.employee_id,
            label: item.displayname,
          };
        }),
      },
      minWidth: 200,
      cellRenderer:(params)=>{return gridLinkValueFormat(params,params?.valueFormatted)},
      valueFormatter:(params)=>{return params?.data?.employee_name},
      // enableFilter: true,
    },
    {
      field: "teamheader_id",
      headerName: Helper.getLabel(language, "team", "Team"),
      editable:false,
      cellEditor:SimpleDropdown,
      cellEditorParams:{
        values: teamsList?.map((item) => {
          return {
            value: item.teamheader_id,
            label: item.name,
          };
        }),
      },
      valueFormatter:(params)=>getLabelFromDropdown(params),
      minWidth: 200,
    },
    {
      field: "dynamicteamheader_id",
      headerName: Helper.getLabel(language, "dynamic_team", "Dynamic team"),
      cellEditor:SimpleDropdown,
      editable: false,
      cellEditorParams:{
        values: dynamicTeamsList?.map((item) => {
          return {
            value: item.teamheader_id,
            label: item.name,
          };
        }),
      },
      valueFormatter:(params) => {
        const names = params.data.dynamicteamheader_names;
        return Object.values(names).join(' , ');
      },
      minWidth: 200,
    },
    {
      field: "access_level",
      headerName: Helper.getLabel(language, "access", "Access"),
      editable: false,
      cellEditor:SimpleDropdown,
      cellEditorParams:{
        values: accessLevel?.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        }),
      },
      valueFormatter:(params)=>getLabelFromDropdown(params),
      minWidth: 120,
    },
    {
      field: "PR_view_access",
      headerName: Helper.getLabel(language, "view_pr", "View PR"),
      editable: false,
      minWidth: 150,
      cellRenderer: 'agCheckboxCellRenderer',
      cellRendererParams:{disabled:true},
      cellEditor: 'agCheckboxCellEditor',
      filter: 'agSetColumnFilter',
      filterParams: { 
          values: [true, false], 
          keyCreator: (params) => params.value, 
          valueFormatter: (params) => params.value ? 'true' : 'false' 
      }, 
    },
    {
      field: "RFP_view_access",
      headerName: Helper.getLabel(language, "view_rfq", "View RFQ"),
      editable: false,
      minWidth: 150,
      cellRenderer: 'agCheckboxCellRenderer',
      cellRendererParams:{disabled:true},
      cellEditor: 'agCheckboxCellEditor',
      filter: 'agSetColumnFilter',
      filterParams: { 
          values: [true, false], 
          keyCreator: (params) => params.value, 
          valueFormatter: (params) => params.value ? 'true' : 'false' 
      }, 
    },
    {
      field: "perform_RFQ_technical_evaluation",
      headerName: Helper.getLabel(
        language,
        "view_technical_evaluation",
        "View technical evaluation"
      ),
      editable: false,  
      minWidth: 250,
      cellRenderer: 'agCheckboxCellRenderer',
      cellRendererParams:{disabled:true},
      cellEditor: 'agCheckboxCellEditor',
      filter: 'agSetColumnFilter',
      filterParams: { 
          values: [true, false], 
          keyCreator: (params) => params.value, 
          valueFormatter: (params) => params.value ? 'true' : 'false' 
      }, 
    },
    {
      field: "perform_RFQ_financial_evaluation",
      headerName: Helper.getLabel(
        language,
        "view_commercial_evaluation",
        "View commercial evaluation"
      ),
      editable: false,
      minWidth: 250,
      cellRenderer: 'agCheckboxCellRenderer',
      cellRendererParams:{disabled:true},
      cellEditor: 'agCheckboxCellEditor',
      filter: 'agSetColumnFilter',
      filterParams: { 
          values: [true, false], 
          keyCreator: (params) => params.value, 
          valueFormatter: (params) => params.value ? 'true' : 'false' 
      }, 
    },
    {
      field: "allowed_to_award",
      headerName: Helper.getLabel(
        language,
        "allowed_to_award",
        "Allowed to award"
      ),
      editable: false,
      minWidth: 250,
      cellRenderer: 'agCheckboxCellRenderer',
      cellRendererParams:{disabled:true},
      cellEditor: 'agCheckboxCellEditor',
      filter: 'agSetColumnFilter',
      filterParams: { 
          values: [true, false], 
          keyCreator: (params) => params.value, 
          valueFormatter: (params) => params.value ? 'true' : 'false' 
      }, 
    },
    {
      field: "allowed_to_unsealed_technical_proposal",
      headerName: Helper.getLabel(
        language,
        "unseal_technical_proposal",
        "Unseal technical proposal"
      ),
      editable: false,  
      minWidth: 250,
      cellRenderer: 'agCheckboxCellRenderer',
      cellRendererParams:{disabled:true},
      cellEditor: 'agCheckboxCellEditor',
      filter: 'agSetColumnFilter',
      filterParams: { 
          values: [true, false], 
          keyCreator: (params) => params.value, 
          valueFormatter: (params) => params.value ? 'true' : 'false' 
      }, 
    },
    {
      field: "allowed_to_unsealed_commercial_proposal",
      headerName: Helper.getLabel(
        language,
        "unseal_commercial_proposal",
        "Unseal commercial proposal"
      ),
      editable: false,  
      minWidth: 250,
      cellRenderer: 'agCheckboxCellRenderer',
      cellRendererParams:{disabled:true},
      cellEditor: 'agCheckboxCellEditor',
      filter: 'agSetColumnFilter',
      filterParams: { 
          values: [true, false], 
          keyCreator: (params) => params.value, 
          valueFormatter: (params) => params.value ? 'true' : 'false' 
      }, 
    },
  ];
}

const getTeamsColumns = ({language,teamsList,employeesList}) => {
  return [
    {
      field: "employee_id",
      headerName: Helper.getLabel(language, "name", "Name"),
      editable: false,
      cellEditor:SimpleDropdown,
      cellEditorParams:{
        values: employeesList.map((item) => {
          console.log(item)
          return {
            value: item.employee_id,
            label: item.displayname,
          };
        }),
      },
      minWidth: 200,
      valueFormatter:(params)=>getLabelFromDropdown(params),
    },
    {
      field: "teamheader_id",
      headerName: Helper.getLabel(language, "team", "Team"),
      editable: false,
      cellEditor:SimpleDropdown,
      cellEditorParams:{
        values:teamsList?.map((item) => {
          return {
            value: item.teamheader_id,
            label: item.name,
          };
        })
      },
      valueFormatter:(params)=>getLabelFromDropdown(params),
      minWidth: 200,
    },
    {
      field: "PR_view_access",
      headerName: Helper.getLabel(language, "view_pr", "View PR"),
      editable: true,
      minWidth: 125,
      cellRenderer: 'agCheckboxCellRenderer',
      cellRendererParams:{disabled:true},
      cellEditor: 'agCheckboxCellEditor',
      // valueGetter:(params)=> { 
      //   if(!params?.data?.PR_view_access || !params?.data){
      //     return false
      //   }
      //   else {
      //     return params.data?.PR_view_access
      //   }
      // },
      filter: 'agSetColumnFilter',
      filterParams: { 
          values: [true, false], 
      }, 
    },
    {
      field: "RFP_view_access",
      headerName: Helper.getLabel(language, "view_rfq", "View RFQ"),
      editable: true,
      minWidth: 125,
      cellRenderer: 'agCheckboxCellRenderer',
      cellRendererParams:{disabled:true},
      cellEditor: 'agCheckboxCellEditor',
      filter: 'agSetColumnFilter',
      filterParams: { 
          values: [true, false], 
          keyCreator: (params) => params.value, 
          valueFormatter: (params) => params.value ? 'true' : 'false' 
      }, 
    },
    {
      field: "perform_RFQ_technical_evaluation",
      headerName: Helper.getLabel(
        language,
        "perform_technical_evaluation",
        "Perform technical evaluation"
      ),
      editable: true,
      minWidth: 250,
      cellRenderer: 'agCheckboxCellRenderer',
      cellRendererParams:{disabled:true},
      cellEditor: 'agCheckboxCellEditor',
      filter: 'agSetColumnFilter',
      filterParams: { 
          values: [true, false], 
          keyCreator: (params) => params.value, 
          valueFormatter: (params) => params.value ? 'true' : 'false' 
      }, 
    },
    {
      field: "perform_RFQ_financial_evaluation",
      headerName: Helper.getLabel(
        language,
        "perform_commercial_evaluation",
        "Perform commercial evaluation"
      ),
      editable: true,
      minWidth: 250,
      cellRenderer: 'agCheckboxCellRenderer',
      cellRendererParams:{disabled:true},
      cellEditor: 'agCheckboxCellEditor',
      filter: 'agSetColumnFilter',
      filterParams: { 
          values: [true, false], 
          keyCreator: (params) => params.value, 
          valueFormatter: (params) => params.value ? 'true' : 'false' 
      }, 
    },
    {
      field: "allowed_to_award",
      headerName: Helper.getLabel(
        language,
        "allowed_to_award",
        "Allowed to award"
      ),
      minWidth: 250,
      editable: true,
      cellRenderer: 'agCheckboxCellRenderer',
      cellRendererParams:{disabled:true},
      cellEditor: 'agCheckboxCellEditor',
      filter: 'agSetColumnFilter',
      filterParams: { 
          values: [true, false], 
          keyCreator: (params) => params.value, 
          valueFormatter: (params) => params.value ? 'true' : 'false' 
      }, 
    },
    {
      field: "allowed_to_unsealed_technical_proposal",
      headerName: Helper.getLabel(
        language,
        "unseal_technical_proposal",
        "Unseal technical proposal"
      ),
      editable: true,
      minWidth: 250,
      cellRenderer: 'agCheckboxCellRenderer',
      cellRendererParams:{disabled:true},
      cellEditor: 'agCheckboxCellEditor',
      filter: 'agSetColumnFilter',
      filterParams: { 
          values: [true, false], 
          keyCreator: (params) => params.value, 
          valueFormatter: (params) => params.value ? 'true' : 'false' 
      }, 
    },
    {
      field: "allowed_to_unsealed_commercial_proposal",
      headerName: Helper.getLabel(
        language,
        "unseal_commercial_proposal",
        "Unseal commercial proposal"
      ),
      editable: true,
      minWidth: 250,
      cellRenderer: 'agCheckboxCellRenderer',
      cellRendererParams:{disabled:true},
      cellEditor: 'agCheckboxCellEditor',
      filter: 'agSetColumnFilter',
      filterParams: { 
          values: [true, false], 
          keyCreator: (params) => params.value, 
          valueFormatter: (params) => params.value ? 'true' : 'false' 
      }, 
    },
  ];
}

const getVendorInviteModelColumns = ({language}) => {
  return  [
    {
      field: "email",
      headerName: Helper.getLabel(language, "email", "Email"),
      editable: false,
      minWidth:200,
    },
    {
      field: "phone_number",
      headerName: Helper.getLabel(language, "phone_number", "Phone number"),
      editable: false,
      valueFormatter:(params)=>params.value || "-",
      minWidth:160,
    },
    {
      field: "country_code",
      headerName: Helper.getLabel(language, "country_code", "Country code"),
      editable: false,
      valueFormatter:(params)=>params.value || "-",
      minWidth:160,
    },
    {
      field: "user_name",
      headerName: Helper.getLabel(language, "invited_by", "Invited by"),
      editable: false,
      minWidth:160,
    },
    {
      field: "invited_date",
      headerName: Helper.getLabel(language, "invited_date", "Invited date"),
      editable: false,
      valueFormatter:(params)=>DatepickerFunctions.convertDateTimeFromDataBase(params.value),
      minWidth:160,
    },
    {
      field: "status_name",
      headerName: Helper.getLabel(language, "status", "Status"),
      cellRenderer: (params) => {
        return getStatusNameById(null, null, true, params.value)
      },
      editable: false,
      minWidth:160,
    },
  ];

}

const getEvaluationHeaderInnerGridColumns = ({language}) => {
  return [
    {
        field: "evaluator_name",
        headerName: Helper.getLabel(language, "evaluator_name", "Evaluator name"),
        editable: false,
        minWidth:150,
    },
    {
      field: "createddatetime",
      headerName: Helper.getLabel(language, "initiate_date", "Initiate date and time"),
      editable: false,
      minWidth:200,
      valueFormatter:(params)=> DatepickerFunctions.convertDateTimeFromDataBase(params.value)
    },
    {
      field: "modifieddatetime",
      headerName: Helper.getLabel(language, "expiry_date", "Action date and time"),
      editable: false,
      minWidth:200,
      valueFormatter:(params)=> DatepickerFunctions.convertDateTimeFromDataBase(params.value)
    },
    {
      field: "Action",
      headerName: Helper.getLabel(language, "action", "Action"),
      editable: false,
      minWidth:160,
      valueFormatter:(params)=> handleAction(params)
    },
    {
      field: "reason_for_selection",
      headerName: Helper.getLabel(language, "comments", "Comments"),
      editable: false,
      minWidth:190,
      valueFormatter:(params)=>params.value || "-"
    },
  ];
}

const getEvaluationMainGridColumns = ({language}) => {
  return [
    {
        field: "createdby",
        headerName: Helper.getLabel(language, "evaluator_name", "Initiated by"),
        editable: false,
        cellRenderer: 'agGroupCellRenderer',
    },
    {
      field: "createddatetime",
      headerName: Helper.getLabel(language, "initiate_date", "Initiate date and time"),
      editable: false,
      valueFormatter:(params)=> DatepickerFunctions.convertDateTimeFromDataBase(params.value)
    },
    {
      field: "modifieddatetime",
      headerName: Helper.getLabel(language, "expiry_date", "Action Date Time"),
      editable: false,
      valueFormatter:(params)=> DatepickerFunctions.convertDateTimeFromDataBase(params.value)
    },
    {
      field: "Action",
      headerName: Helper.getLabel(language, "action", "Action"),
      editable: false,
      valueFormatter:(params)=> handleAction(params)
    },
  ];
}

const getPublishHistoryColumns = ({language}) => {
  return  [
    {
      field: "publish_datetime",
      headerName: Helper.getLabel(language, "publish_date", "Publish date"),
      editable: false,
      valueFormatter:(params)=> DatepickerFunctions.convertDateTimeFromDataBase(params.value)
    },
    {
      field: "createdby",
      headerName: Helper.getLabel(language, "published_by", "Published by"),
      editable: false,
    },
    {
      field: "expiry_datetime",
      headerName: Helper.getLabel(language, "expiry_date", "Expiry date"),
      editable: false,
      valueFormatter:(params)=> DatepickerFunctions.convertDateTimeFromDataBase(params.value)
    },
    {
      field: "vendorname",
      headerName: Helper.getLabel(language, "vendor_name", "Vendor name"),
      editable: false,
    },
    {
      field: "vendorsitename",
      headerName: Helper.getLabel(language, "vendor_site_name", "Vendor site name"),
      editable: false,
    },
  ];

}

const getExtensionHistoryColumns = ({language}) => {
  return [
    {
      field: "createdby",
      headerName: Helper.getLabel(language, "extended_by", "Extended by"),
      editable: false,
    },
    {
      field: "createddatetime",
      headerName: Helper.getLabel(language, "publish_date", "Old expiry date"),
      editable: false,
      valueFormatter:(params)=> DatepickerFunctions.convertDateTimeFromDataBase(params.value)
    },
    {
      field: "newexpirydatetime",
      headerName: Helper.getLabel(language, "new_expiry_date", "New expiry date"),
      editable: false,
      valueFormatter:(params)=> DatepickerFunctions.convertDateTimeFromDataBase(params.value)
    },
    {
      field: "reason",
      headerName: Helper.getLabel(language, "new_expiry_date", "Reason for extension"),
      editable: false,
      valueFormatter:(params)=> params.value || "-"
    },
    {
      field: "vendorname",
      headerName: Helper.getLabel(language, "vendor_name", "Vendor name"),
      editable: false,
    },
    {
      field: "vendorsitename",
      headerName: Helper.getLabel(language, "vendor_site_name", "Vendor site name"),
      editable: false,
    },
  ]
}


const getExtensionRequestsColumns = ({language,getExpiryDateDifferences,handleAccept,handleDeclined,handleRecalled,usertype, actions}) => {
  return  [
    {
      field: 'createdby',
      headerName: Helper.getLabel(language, 'requested_by', 'Requested by'),
      editable: false,
      minWidth:180,
    },
    {
      field: 'vendorname',
      headerName: Helper.getLabel(language, 'vendor_name', 'Vendor name'),
      editable: false,
      minWidth:160,
    },
    {
      field: 'vendorsitename',
      headerName: Helper.getLabel(language, 'vendor_site_name', 'Vendor site name'),
      editable: false,
      minWidth:180,
    },
    {
      field: 'createddatetime',
      headerName: Helper.getLabel(
        language,
        'current_expiry_date_time',
        'Current expiry date and time'
      ),
      editable: false,
      valueFormatter: (params) =>
        DatepickerFunctions.convertDateTimeFromDataBase(params.value),
      minWidth:240,
    },
    {
      field: 'extended_datetime',
      headerName: Helper.getLabel(
        language,
        'requested_expiry_date_time',
        'Requested expiry date and time'
      ),
      editable: false,
      valueFormatter: (params) =>
        DatepickerFunctions.convertDateTimeFromDataBase(params.value),
      minWidth:280,
    },
    {
      field: 'difference',
      headerName: Helper.getLabel(language, 'difference', 'Difference'),
      editable: false,
      minWidth:180,
      cellRenderer: getExpiryDateDifferences,
    },
    {
      field: 'reason',
      headerName: Helper.getLabel(language, 'reason_for_name', 'Reason for extension'),
      editable: false,
      minWidth:200,
      valueFormatter: (params) => params.value || "-",
    },
    {
      field:'action',
      headerName: Helper.getLabel(language, 'status', 'Status'),
      editable: false,
      minWidth:200,
      valueFormatter: (params)=>{return getActionStatusName(params,actions)},
    },
    {
      field: 'Action',
      headerName: Helper.getLabel(language, 'action', 'Action'),
      editable: false,
      minWidth:230,
      cellRenderer:CustomButtonCellRenderer,
      cellRendererParams: {
        onClickAccept: handleAccept,
        onClickReject: handleDeclined,
        onClickRecalled:handleRecalled,
        usertype:usertype,
      },
    },
  ]
}


export {getVendorColumns,getCollaborationTeamColumn,getTeamsColumns,getVendorInviteModelColumns,getEvaluationHeaderInnerGridColumns,getEvaluationMainGridColumns, getPublishHistoryColumns, getExtensionHistoryColumns,getExtensionRequestsColumns}