import Droppable from 'droppable';
import React, { useEffect, Fragment} from 'react'
import { connect } from 'react-redux'
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import SimpleLoading from '../../Loading/SimpleLoading';

const ProductImg = ({imageFiles, loadImages, editMode=false, setImageFiles, reloadComponent}) => {
    let droppable = null;
    let id = 'file_uploader_canvas';
    
    useEffect(() => { 
        InitializeDropable() 
    }, [loadImages])

    const InitializeDropable = () => {
        let selector = document.getElementById(id);
        if (selector && !droppable) {
            droppable = new Droppable({
                element: document.querySelector('#' + id),
            });
            droppable.setAcceptsMultipleFiles(true); // Allow multiple file uploads
            droppable.onFilesDropped((files) => {
                if (files && files.length > 0) {
                    uploadFiles(files);
                }
            })
        }
    }
    
    const uploadFiles = (files) => {  
        let promises = [];
      
        for (let i = 0; i < files.length; i++) {
          let file = files[i];
          let promise = new Promise((resolve) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              let newImage = {
                image_name: file.name,
                image: reader.result,
                image_type: file.type,
                image_id: null
              };
              resolve(newImage);
            };
          });
          promises.push(promise);
        };
      
        Promise.all(promises).then((newImages) => {
          setImageFiles(prevImageFiles => [...prevImageFiles, ...newImages]);
        });
    }

    const removeImage = (index) => {
        const newFiles = [...imageFiles];
        newFiles.splice(index, 1);
        setImageFiles(newFiles); 
        if(editMode && imageFiles[index].image_id){
            DeleteItemImageAPI(imageFiles[index])
        }
    }

    const DeleteItemImageAPI = (obj) => {
        if(!obj.image_id) {
            return;
        } 
        let api = Api
        api.setUserToken() 
        api.axios().delete(Settings.apiItemUrl+`/itemimage/${obj.image_id}`,{}).then(function(res){
        if(res.data.status==="success"){
            Helper.alert(res.data.message)  
            reloadComponent() 
        }
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res)  
            reloadComponent()
        }) 
    }
  
    const ItemImageComponent = () => {
        return (
        <section className='product_image_section_item_master'> 
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='products_img d-flex justify-content-between align-items-center' style={{borderBottom: '1px solid #DEE8F4'}}>
                        <h2 className='ms-3 mt-2' style={{borderBottom: '3px solid #2D74E0', marginBottom: 0}}>Product Image</h2> 
                    </div>
                </div>
                <div className='row product_images_area'>
                    {imageFiles &&
                        imageFiles.map((file, index) => (
                        <div key={index} className="col-lg-3 mb-3 file_uploader">
                            <button
                                className="cross_btn_product position-absolute"
                                style={{ right: '0px', top: '0px' }}
                                onClick={() => removeImage(index)}
                            >
                            X
                            </button>
                            <img
                                src={file?.image}
                                className="item_product_img"
                                alt="item_image"
                            />
                        </div>
                    ))}
                    <div className='col-lg-3 file_uploader_product' id={id}>
                        <div className='file_uploader_canvas'>
                            <img src="/images/icons/img-uploader-placeholder.svg" className='product_img' alt="Upload File" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        )
    }

  return (
    <Fragment>
        {loadImages ? <SimpleLoading /> : ItemImageComponent()}
    </Fragment>
  )
}

const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        language:state.language
    }
}

export default connect(mapStateToProps)(ProductImg);