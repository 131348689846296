import { getToken } from "@firebase/messaging";

import { messaging } from "./FirebaseSetup";

async function requestPermission(){
    const permission = await Notification.requestPermission();
    console.log('permission',permission)
    if(permission === 'granted'){
      const token = await getToken(messaging, {vapidKey: process.env.REACT_APP_VAPID_KEY})
      return token
    } else {
      return null
    }
}

export {requestPermission}