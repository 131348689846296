import React, { Component } from 'react';
import MasterComonent from '../../Backend/MasterComonent';
import RsWithRouter from '../../inc/RsWithRouter';
import RsDataLoader from '../../inc/RsDataLoader';
import { connect } from 'react-redux';
import Helper from '../../../inc/Helper';
import LineViewVersion from './LineViewVersion';
import Tabs from '../../Widgets/Tabs/Tabs';
import BlueCallToAction from '../../Widgets/BlueCallToAction/BlueCallToAction';
import NavigationHeder from '../../Navigations/NavigationHeder';
import Button from '../../inc/Button';
import SimpleLoading from '../../Loading/SimpleLoading';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import AgGrid from '../../grid/ag/ag-grid';
import AgGridMemo from '../../grid/ag/AgGridMemo';
import HeaderViewVersion from './HeaderViewVersion';
import PoTopBlueShortDetails from '../Widget/PoTopBlueShortDetails';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';

class PoVersion extends Component {
    constructor(props){
        super(props);
        this.versionGrid = null;
        this.state = {
            poTypes:[],
            isLoading:false,
            isHistoryForceRender:1,
            poHeader:{},
            isVersionLoading:false,
            versionsData:[],
            activeVersion:null,
        }
    }
    componentDidMount(){
        this.loadPoHeader()
        this.loadVersions()
    }
    loadPoHeader(){
        this.setState({
            isLoading:true
        })
        let api = Api, that = this;
        let id = this.props.rs_router.params.id;
        api.setUserToken();
        api.axios().get(Settings.apiPurchaseOrderUrl+`/PO_header/${id}`,{}).then(res => {
            that.setState({
                isLoading:false,
                poHeader:res.data.data
            })
        })
    }
    getVendorFromId(vendorId){
        let singleVendor =  {name:'',vendor_id:'',code:''};
        this.props.dataLoaderProps.vendors.forEach(vn => {
            if(vn.vendor_id == vendorId){
            singleVendor = vn;
            }
        });
        return singleVendor;
    }
    addComponentTop(){
        let editPO = this.state.poHeader;
        let poid =editPO.purchase_order_id;
          return(
            <>
              <PoTopBlueShortDetails poid={poid}/> 
            </>
          )
    }
    loadVersions(){
        this.setState({
            isVersionLoading:true
        })
        let id = this.props.rs_router.params.id;
        let that = this;
        let api = Api;
        api.setUserToken();

        api.axios().get(Settings.apiPurchaseOrderUrl+'/header_version/'+id+'/'+Settings.source_id.purchase_order).then(res => {
            that.setState({
                isHistoryForceRender: that.state.isHistoryForceRender + 1,
                isVersionLoading:false,
                versionsData:res.data.data
            }, () => {
                setTimeout(() => {
                    if(!that.versionGrid){
                        return;
                    }
                    var firstRowNode = that.versionGrid.api.getDisplayedRowAtIndex(0);
                    if (firstRowNode) {
                        firstRowNode.setSelected(true);
                    }
                },that.versionGrid ? 100 : 500)
            })
        }).catch(error => {
            getPopupMessageBasedOnStatus(error)
        })
    }
    cellRenderer(params){
        return Helper.formateDate(params.value);
    }
    onSelectionChanged(params){
        let selectedNodes = params.api.getSelectedNodes();
        if(selectedNodes.length <= 0){
            this.setState({
                activeVersion:null
            })
        }
        this.setState({
            activeVersion:selectedNodes[0]
        })
    }
    render() {
        let poId = this.props.rs_router.params.id;
        let language = null;
        let navsParents = [
            {title:Helper.getLabel(language,'versions','Versions'), isActive: true, },
        ]
        let navsChild = [
            {title:Helper.getLabel(language,'header_view','Header View'), isActive:  true, isDisable: !this.state.activeVersion ? true : false},
            {title:Helper.getLabel(language,'line_view','Line view'),  isDisable: !this.state.activeVersion ? true : false}
        ]
        let header = [
            {
                field:'version', headerName:Helper.getLabel(null,'version','Version'),
            },{
                field:'modifieddatetime', headerName:Helper.getLabel(null,'change_date','Change date'),cellRenderer:this.cellRenderer.bind(this),
                
            },{
                field:'purchase_status', headerName:Helper.getLabel(null,'status','Status'),
            }
        ]
        return (
            <MasterComonent>
                
                <div className='container-fluid'>
                    <NavigationHeder backUrl={ (this.props.backUrl ? this.props.backUrl + '/' : '/edit-purchase-order/') +poId} title={ this.props.title ? this.props.title : Helper.getLabel(language,'version','Version')} hideMoreBtn={true}>
                        {/* <Button title={"Print"} icon="/images/icons/print.svg"/> */}
                    </NavigationHeder>
                    { this.state.isLoading ? <SimpleLoading /> : ""}
                    <Tabs id="po_history_tabs_parent" isHideNav={true} tabContantsId="po_history_tabs_contents"  navs={navsParents} viewAll={true} addComponentTop = {this.addComponentTop.bind(this)}>
                        <div className='row'>
                            <div className='col-xs-12 col-md-3 hide-ag-grid-search'>
                                <AgGridMemo onGridReady = { (grid) => { this.versionGrid = grid }} autoSelectFirstItem={true} onSelectionChanged = {this.onSelectionChanged.bind(this)} singleRowSelect={true} header={header} data={this.state.versionsData} forceRender={this.state.isHistoryForceRender} hideFooter={true}  ppp={1000}  allBtnHide={true} viewOnly={true}/>
                            </div>
                            <div className='col-xs-12 col-md-9'>
                                <Tabs id="po_history_hl_view" tabContantsId="po_history_hl_view_contents" navs={navsChild} disableSticky={true}>
                                        <HeaderViewVersion  poData={this.state.activeVersion ? this.state.activeVersion.data : null}  current_po_id = {poId} source_id={Settings.source_id.purchase_order}/>
                                        <LineViewVersion version={this.state.activeVersion ? this.state.activeVersion.data.version : null} purchaseorder_id = {this.state.activeVersion ? this.state.activeVersion.data.purchase_order_id : null} source_id={this.props.source_id} hideBillingRule={this.props.hideBillingRule}/>
                                </Tabs>
                            </div>
                        </div>
                    </Tabs>
                </div>
            </MasterComonent>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        language:state.language
    }
}
export default connect(mapStateToProps) (  RsDataLoader( RsWithRouter(PoVersion),{vendors:true,sites:true}) );