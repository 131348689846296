import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import MasterComonent from '../../components/Backend/MasterComonent';
import InnerScreen from '../../components/Inner Screens/InnerScreen';
import Helper from '../../inc/Helper';

const MasterScreen = ({language}) => {
  return (
    <div className='modules_screen_lynkaz'>
      <MasterComonent>
        <InnerScreen innerTitle='Master Data'>
          <div className='col-lg-3 gy-4'>
            <div className='inner-page-header border-bottom'>
              <h2>{Helper.getLabel(language,'vendor','Vendors')}</h2>
            </div>
            <div className='inner-page-routes'>
              <Link to='/vendors?status=1'>{Helper.getLabel(language,'vendors_registration_approved','Vendors - Approved')}</Link>
              <Link to='/vendors?status=2'>{Helper.getLabel(language,'vendors_not_completed_registration','Vendors - Joined but not completed registration')}</Link>
              <Link to='/vendors?document_status=2'>{Helper.getLabel(language,'vendors_under_review','Vendors - Under review')}</Link>
              <Link to='/vendors?deleted=true'>{Helper.getLabel(language,'vendors_deleted','Vendors - Deleted')}</Link>
              <Link to='/vendors'>{Helper.getLabel(language,'vendor_all','Vendors - All')}</Link>
              <Link to='/vendors-invitation-list'>{Helper.getLabel(language,'vendors_invitation_list','Vendors - Invitation list')}</Link>
              <Link to='/vendors-group'>{Helper.getLabel(language,'vendors_group','Vendors - Group')}</Link>
              <Link to='/vendors-business-classification'>{Helper.getLabel(language,'vendors_business_classification','Vendors - Business classification')}</Link>
              <Link to='/vendors-reason'>{Helper.getLabel(language,'vendors_reason','Vendors - Reason')}</Link>
              <Link to='/designations'>{Helper.getLabel(language,'vendor_designations','Vendors - Designations')}</Link>
              <Link to='/contact-types'>{Helper.getLabel(language,'vendor_contact_types','Vendors - Contact types')}</Link>
            </div>
          </div>

          <div className='col-lg-3 gy-4'>
            <div className='inner-page-header border-bottom'>
              <h2>{Helper.getLabel(language,'item','Item')}</h2>
            </div>
            <div className='inner-page-routes'>
              <Link to='/items'>{Helper.getLabel(language,'items','Items')}</Link>
              <Link to='/dimensions'>{Helper.getLabel(language,'dimensions','Dimensions')}</Link>
              <Link to='/size'>{Helper.getLabel(language,'size','Size')}</Link>
              <Link to='/color'>{Helper.getLabel(language,'color','Color')}</Link>
              <Link to='/configuration'>{Helper.getLabel(language,'configuration','Configuration')}</Link>
              <Link to='/style'>{Helper.getLabel(language,'style','Style')}</Link>
              <Link to='/brand'>{Helper.getLabel(language,'brand','Brand')}</Link>  
            </div>
          </div>  
          
          <div className='col-lg-3 gy-4'>
            <div className='inner-page-header border-bottom'>
              <h2>{Helper.getLabel(language,'team','Team')}</h2>
            </div>
            <div className='inner-page-routes'>
              <Link to='/team-header'>{Helper.getLabel(language,'teams','Teams')}</Link> 
              <Link to='/team-header?dynamic_team_header=true'>{Helper.getLabel(language,'teams-dynamic','Dynamic - Teams')}</Link> 
            </div>
          </div>  
          
          <div className='col-lg-3 gy-4'>
            <div className='inner-page-header border-bottom'>
              <h2>{Helper.getLabel(language,'request_for_quotations','Request for Quotations')}</h2>
            </div>
            <div className='inner-page-routes'>
              <Link to='/scoring-template'>{Helper.getLabel(language,'scoring_templates','Scoring templates')}</Link> 
              <Link to='/terms-conditions-templates'>{Helper.getLabel(language,'terms_conditions_templates','Terms & condition templates')}</Link> 
              <Link to='/compliance-templates/listings'>{Helper.getLabel(language,'compliances_templates','Compliance templates')}</Link> 
            </div>
          </div>  
  
          <div className='col-lg-3 gy-4'>
            <div className='inner-page-header border-bottom'>
              <h2>{Helper.getLabel(language,'employee','Employee')}</h2>
            </div>
            <div className='inner-page-routes'>
              <Link to='/employee'>{Helper.getLabel(language,'employee','Employee')}</Link> 
              <Link to='/position'>{Helper.getLabel(language,'employee_position','Employee position')}</Link> 
            </div>
          </div>  
        </InnerScreen>
      </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth:state.auth,
    language:state.language
  }
}

export default connect(mapStateToProps) (MasterScreen)